import { SvgIcon } from "@mui/material";

export default function DocumentIcon() {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <path d="M0 0h24v24H0V0z" />
                <path
                    fill="#42a5f5"
                    d="M8 16h8v2H8zm0-4h8v2H8zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z"
                />
            </svg>
        </SvgIcon>
    );
}
