import Skeleton from "@mui/material/Skeleton";
import "./NotificationSkeleton.css";

export const NotificationSkeleton = () => {
    return (
        <div>
            {[...Array(8)].map((_, i) => (
                <div key={i} className="skeleton-notification">
                    <Skeleton variant="text" width={300} height={30} />
                    <Skeleton variant="rounded" width={210} height={50} />
                </div>
            ))}
        </div>
    );
};

export default NotificationSkeleton;
