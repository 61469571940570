import { useMemo } from "react";
import { useIntl } from "react-intl";
import { HomePageTemplate } from "../HomePageTemplate/HomePageTemplate";
import {
    CalendarMonthOutlined,
    DirectionsBoatOutlined,
    LibraryBooksOutlined,
    PlaceOutlined,
} from "@mui/icons-material";
import Messages from "../../localization/Messages";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PaymentsIcon from "@mui/icons-material/Payments";
import MapIcon from "@mui/icons-material/Map";
import { isDevelopment } from "../../Keycloak";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import BuildIcon from "@mui/icons-material/Build";

/**
 * Represents the Home page Dispatcher component.
 * This component is used to display the home page for the Dispatcher FGKS app.
 *
 * @component
 * @example
 * ```tsx
 * <HomeDispatcher />
 * ```
 */
export const HomeDispatcher = () => {
    const intl = useIntl();

    const items = useMemo(() => {
        const out = [
            {
                label: intl.formatMessage(Messages.manageDestination),
                icon: <PlaceOutlined />,
                link: "manage-destination",
            },
            {
                label: intl.formatMessage(Messages.manageBookings),
                icon: <LibraryBooksOutlined />,
                link: "manage-bookings",
            },
            {
                label: intl.formatMessage(Messages.manageInternalBookings),
                icon: <LibraryBooksOutlined />,
                link: "manage-internal-bookings",
            },
            {
                label: intl.formatMessage(Messages.manageShips),
                icon: <DirectionsBoatOutlined />,
                link: "manage-ships",
            },
            {
                label: intl.formatMessage(Messages.manageAddresses),
                icon: <ImportContactsOutlinedIcon />,
                link: "manage-addresses",
            },
            {
                label: intl.formatMessage(Messages.viewDockAllocationPlan),
                icon: <CalendarMonthOutlined />,
                link: "dock-allocation-plan",
            },
        ];

        return out;
    }, [intl]);

    const itemsRow2 = useMemo(() => {
        const out = [];

        out.push(
            {
                label: intl.formatMessage(Messages.manageExpenseTypes),
                icon: <ReceiptLongIcon />,
                link: "manage-expense-types",
            },
            {
                label: intl.formatMessage(Messages.manageExpenses),
                icon: <PaymentsIcon />,
                link: "manage-expenses",
            },
            {
                label: intl.formatMessage(Messages.manageInvoices),
                icon: <ReceiptIcon />,
                link: "manage-invoices",
            },
            {
                label: intl.formatMessage(Messages.portMonitor),
                icon: <MapIcon />,
                url: isDevelopment
                    ? "https://gms.stage.rpis.eu/port-administration/port/monitor"
                    : "https://gms.rpis.eu/port-administration/port/monitor",
            },
            {
                label: intl.formatMessage(Messages.manageServices),
                icon: <BuildIcon />,
                link: "manage-services",
            },
        );

        return out;
    }, [intl]);

    return (
        <HomePageTemplate
            title={intl.formatMessage(Messages.fgksDisptacher)}
            items={items}
            itemsRow2={itemsRow2}
        />
    );
};

export default HomeDispatcher;
