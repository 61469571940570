import { Typography } from "@mui/material";
import Messages from "../../../localization/Messages";
import { useIntl } from "react-intl";

export type BerthingDockGroup = {
    destinationId: string;
    bookingId: number;
    dockName: string;
    arrivalTime: number;
    departureTime: number;
};

export type BerthingsDockNameProps = {
    berthingDockGroups?: BerthingDockGroup[];
};

/**
 * Renders the name of the berthing dock.
 *
 * @component
 * @param {BerthingsDockNameProps} props - The component props.
 * @param {BerthingDockGroup[]} props.berthingDockGroups - The array of berthing dock groups.
 * @returns {JSX.Element} The rendered component.
 */
export default function BerthingsDockName({ berthingDockGroups = [] }: BerthingsDockNameProps) {
    const intl = useIntl();
    if (!berthingDockGroups) return <></>;
    return (
        <Typography component="p">
            {berthingDockGroups && berthingDockGroups.length > 1
                ? `${intl.formatMessage(Messages.multiple)} ${intl.formatMessage(Messages.docks)}`
                : berthingDockGroups[0].dockName}
        </Typography>
    );
}
