import { useSelector } from "react-redux";
import { AppState } from "../store/configureStore";
import { useMemo } from "react";

export function useCachedDocks() {
    const byId = useSelector((s: AppState) => s.docks.data);
    const docks = useMemo(
        // Sorts by name.
        // () => Array.from(byId.values()).sort((a, b) => a.name.localeCompare(b.name)),

        // Sorts by order number.
        () => Array.from(byId.values()).sort((a, b) => a.ordnum - b.ordnum),
        [byId],
    );
    return { byId, docks } as const;
}
