import axios, { AxiosResponse } from "axios";
import { IInvoice, InvoiceStatus } from "../models/invoice";
import { ISearchParams, searchParamsInit } from "../models/searchParams";
import { ApiSearchResponse } from "../models/common";

export const addInvoice = async (invoice: IInvoice): Promise<AxiosResponse<IInvoice>> => {
    return await axios.put("/dispatcher/addInvoice", invoice);
};

export const changeInvoice = async (invoice: IInvoice): Promise<AxiosResponse<IInvoice>> => {
    return await axios.patch("/dispatcher/changeInvoice", invoice);
};

export const deleteInvoice = async (id: string): Promise<AxiosResponse<string>> => {
    return await axios.delete(`/dispatcher/deleteInvoice/${id}`);
};

export const getInvoice = async (id: string): Promise<AxiosResponse<IInvoice>> => {
    return await axios.get(`/dispatcher/getInvoice/${id}`);
};

export const searchInvoice = async (
    searchParams: ISearchParams = searchParamsInit,
): ApiSearchResponse<IInvoice> => {
    return await axios.post("/dispatcher/searchInvoice", searchParams);
};

export async function changeInvoiceStatus(invoiceId: number, status: InvoiceStatus) {
    return (await axios.patch<IInvoice>("/dispatcher/changeInvoiceStatus", { status, invoiceId }))
        .data;
}

export async function copyCancelledInvoice(id: string) {
    return (await axios.get<IInvoice>(`/dispatcher/copyCancelledInvoice/${id}`)).data;
}

export async function getInvoiceCsvLink(id: string) {
    return (await axios.get<string>(`/dispatcher/getInvoiceCsvLink/${id}`)).data;
}
