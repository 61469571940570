import { DatatablePaginationFilters } from "../../../hooks/useGuiConfState";
import { SortOrder } from "../../../hooks/useTableSort";
import { ServiceStatus, ServiceType } from "../../../models/service";

export type ServiceFiltersType = DatatablePaginationFilters & {
    dateFrom: Date | null;
    dateTo: Date | null;
    type: ServiceType | undefined;
    status: ServiceStatus[];
    shipId: string;
    order: SortOrder;
};

export function buildServicesSearchPayload(filters: ServiceFiltersType) {
    return {
        textSearch: "",
        offset: filters.page * filters.rowsPerPage,
        limit: filters.rowsPerPage,
        order: filters.order,
        columns: {},
        filter: buildServicesSearchPayloadFilters(filters),
    };
}

function buildServicesSearchPayloadFilters(filters: ServiceFiltersType) {
    const { dateFrom, dateTo, type, status, shipId } = filters;

    const filterArray: object[] = [];

    if (dateFrom) {
        const startDate = dateFrom;
        startDate.setHours(0, 0, 0, 0);
        filterArray.push({ startDateTime: { $gte: startDate.getTime() } });
    }

    if (dateTo) {
        const endDate = dateTo;
        endDate.setHours(23, 59, 59, 59);
        filterArray.push({ startDateTime: { $lte: endDate.getTime() } });
    }

    if (type) {
        filterArray.push({ type: type });
    }

    if (status?.length > 0) {
        const serviceStatusFilterArray: object[] = [];
        status.forEach(st => serviceStatusFilterArray.push({ status: st.toString() }));
        filterArray.push({ $or: serviceStatusFilterArray });
    }

    if (shipId) {
        filterArray.push({ shipId: shipId });
    }

    return { $and: filterArray };
}
