import {
    Box,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useIntl } from "react-intl";
import {
    IDockExpense,
    IExpense,
    getExpensesTotalGrossCost,
    getExpensesTotalNetCost,
    getExpensesTotalVatCost,
    getTotalPrice,
} from "../../../../models/expense";
import { IInvoice } from "../../../../models/invoice";
import Messages from "../../../../localization/Messages";
import "./InvoiceDataContainer.css";
import DataField from "../../../DataField";
import { DateFormat, getDateText } from "../../../../helpers/dateHelper";
import { TableSkeleton } from "../../../TableSkeleton/TableSkeleton";
import { displayAmount } from "../../../../helpers/moneyHelper";
import { IShip } from "../../../../models/ship";
import { expenseCategories } from "../../../../models/expenseType";
import { IShipDispatcher } from "../../../../models/shipDispatcher";
import { formatDecimalNumber } from "../../../../helpers/numberDisplayHelper";
import { BookingDataSource, IBooking } from "../../../../models/booking";
import { useAuth } from "../../../../hooks/useAuth";
import { Roles } from "../../../../models/userData";

/**
 * The props for the InvoiceDataContainer component.
 */
type InvoiceDataContainerProps = {
    selectedInvoice: IInvoice;
    loading: boolean;
    expenses: IExpense[] | IDockExpense[];
    ship: IShip | IShipDispatcher | undefined;
    booking?: IBooking;
};

/**
 * Renders the container for displaying invoice data, including address, date, reference, and expenses.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {IInvoice} props.selectedInvoice - The selected invoice object.
 * @param {boolean} props.loading - Indicates whether the data is still loading.
 * @param {Array<IExpense|IDockExpense>} props.expenses - The array of expenses associated with the invoice.
 * @param {IShip|IShipDispatcher|undefined} props.ship - The ship or ship dispatcher object associated with the invoice.
 * @returns {JSX.Element} The rendered component.
 */
export const InvoiceDataContainer = ({
    selectedInvoice,
    loading,
    expenses,
    ship,
    booking,
}: InvoiceDataContainerProps) => {
    const intl = useIntl();
    const { hasRole } = useAuth();
    const isDispatcher = hasRole(Roles.PASSENGER_CABIN_CRUISES_DISPATCHER);
    const isOnCallUser = hasRole(Roles.ON_CALL_DISPATCHER);

    return (
        <div className="view-invoice-container">
            {loading || !selectedInvoice.id ? (
                <div className="column-form three-column-form invoice-data-skeleton">
                    {[...Array(3).keys()].map(rowIndex => (
                        <div key={rowIndex}>
                            {[...Array(2).keys()].map(columnIndex => (
                                <div key={columnIndex} className="row-skeleton">
                                    <Skeleton key={columnIndex} variant="rectangular" height={50} />
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            ) : (
                selectedInvoice?.id && (
                    <Box className="invoice-data-container column-form three-column-form">
                        <DataField
                            title={intl.formatMessage(Messages.invoiceAddress)}
                            value={selectedInvoice.address.company}
                        />
                        <DataField
                            title={intl.formatMessage(Messages.invoiceDate)}
                            value={getDateText(selectedInvoice.invoiceDate)}
                        />
                        <DataField
                            title={intl.formatMessage(Messages.invoiceReference)}
                            value={selectedInvoice.invoiceReferenceField}
                        />
                        <DataField
                            title={intl.formatMessage(Messages.shipName)}
                            value={ship?.name ?? ""}
                        />
                        <DataField
                            title={intl.formatMessage(Messages.bookingNumber)}
                            value={`#${selectedInvoice.bookingId}`}
                        />
                        {booking && (
                            <>
                                <DataField
                                    className={
                                        booking.ataSource === BookingDataSource.AIS
                                            ? "ais-data-source"
                                            : ""
                                    }
                                    title={intl.formatMessage(Messages.actualArrivalTime)}
                                    value={getDateText(
                                        booking.actualArrivalTime ?? "",
                                        DateFormat.CLIENT_DATE_TIME,
                                    )}
                                />
                                <DataField
                                    className={
                                        booking.atdSource === BookingDataSource.AIS
                                            ? "ais-data-source"
                                            : ""
                                    }
                                    title={intl.formatMessage(Messages.actualDepartureTime)}
                                    value={getDateText(
                                        booking.actualDepartureTime ?? "",
                                        DateFormat.CLIENT_DATE_TIME,
                                    )}
                                />
                            </>
                        )}
                    </Box>
                )
            )}
            <h3>{intl.formatMessage(Messages.expenses)}</h3>
            <TableContainer className="view-invoice-expenses-table-container">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{intl.formatMessage(Messages.expenseType)}</TableCell>
                            {(isDispatcher || isOnCallUser) && (
                                <>
                                    <TableCell>
                                        {intl.formatMessage(Messages.purchaseRemarks)}
                                    </TableCell>
                                    <TableCell>
                                        {intl.formatMessage(Messages.internalRemarks)}
                                    </TableCell>
                                </>
                            )}
                            <TableCell>
                                {intl.formatMessage(Messages.expenseTypeCategory)}
                            </TableCell>
                            <TableCell>{intl.formatMessage(Messages.expenseDate)}</TableCell>
                            <TableCell>{intl.formatMessage(Messages.quantity)}</TableCell>
                            <TableCell>{intl.formatMessage(Messages.netPricePerUnit)}</TableCell>
                            <TableCell>{intl.formatMessage(Messages.totalCostNet)}</TableCell>
                            <TableCell>{intl.formatMessage(Messages.vat)}</TableCell>
                            <TableCell>{intl.formatMessage(Messages.totalCostGross)}</TableCell>
                        </TableRow>
                    </TableHead>

                    {loading && !expenses.length ? (
                        <TableSkeleton rows={5} columns={8} />
                    ) : (
                        <TableBody>
                            {expenses.map(row => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.name ?? ""}</TableCell>
                                    {(isDispatcher || isOnCallUser) && (
                                        <>
                                            <TableCell>
                                                {(row as IExpense).purchaseRemarks ?? ""}
                                            </TableCell>
                                            <TableCell>
                                                {(row as IExpense).internalRemarks ?? ""}
                                            </TableCell>
                                        </>
                                    )}
                                    <TableCell>
                                        {intl.formatMessage(
                                            expenseCategories.find(ec => ec.value === row.category)
                                                ?.label ?? Messages.unknown,
                                        )}
                                    </TableCell>
                                    <TableCell>{getDateText(row.expenseDate)}</TableCell>
                                    <TableCell>{`${formatDecimalNumber(row.quantity)} ${
                                        row.unit ?? ""
                                    }`}</TableCell>
                                    <TableCell>
                                        {displayAmount(row.netPricePerUnit, row.currency)}
                                    </TableCell>
                                    <TableCell>
                                        {displayAmount(row.costNet, row.currency)}
                                    </TableCell>
                                    <TableCell>
                                        {displayAmount(
                                            (row.costNet ?? 0) * ((row.vat ?? 0) / 100),
                                            row.currency,
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {displayAmount(
                                            getTotalPrice(row.costNet ?? 0, row.vat ?? 0),
                                            row.currency,
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow className="total-row">
                                <TableCell>{intl.formatMessage(Messages.total)}</TableCell>
                                {[...Array(4).keys()].map(index => (
                                    <TableCell key={index}></TableCell>
                                ))}
                                <TableCell>
                                    {displayAmount(
                                        getExpensesTotalNetCost(expenses),
                                        selectedInvoice?.currency ?? "",
                                    )}
                                </TableCell>
                                <TableCell>
                                    {displayAmount(
                                        getExpensesTotalVatCost(expenses),
                                        selectedInvoice?.currency ?? "",
                                    )}
                                </TableCell>
                                <TableCell>
                                    {displayAmount(
                                        getExpensesTotalGrossCost(expenses),
                                        selectedInvoice?.currency ?? "",
                                    )}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
        </div>
    );
};

export default InvoiceDataContainer;
