import { HomePageTemplate } from "../HomePageTemplate/HomePageTemplate";

/**
 * Renders a page that indicates the user is unauthorized.
 */
export const Unauthorized = () => {
    return <HomePageTemplate title="UNAUTHORIZED" items={[]} />;
};

export default Unauthorized;
