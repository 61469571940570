import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { IAddEditShip, addEditShipInit } from "../../models/ship";
import Messages from "../../localization/Messages";
import "./ShipDataContainer.css";
import {
    IShipDispatcher,
    IShipDispatcherData,
    shipDispatcherInit,
} from "../../models/shipDispatcher";
import { getUrlForDownload, getUrlForDownloadLD } from "../../api/document";
import { Button, IconButton, Modal, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { IAddress } from "../../models/address";
import { Roles } from "../../models/userData";
import { useAuth } from "../../hooks/useAuth";

export interface IShipDataContainer {
    selectedShip: IAddEditShip | IShipDispatcher;
    owningCompany?: IAddress;
    owningCompanyName?: string;
}

/**
 * Represents a container component for displaying ship data for both the Dispatcher and Locks & Docks .
 * Allows the user to donwload the ship certificate.
 * @param selectedShip - The selected ship data.
 * @param owningCompany - The owning company address.
 * @param owningCompanyName - The name of the owning company.
 */

export const ShipDataContainer = ({
    selectedShip,
    owningCompany,
    owningCompanyName,
}: IShipDataContainer) => {
    const intl = useIntl();
    const [ship, setShip] = useState<IAddEditShip | IShipDispatcher>(shipDispatcherInit);
    const [displayShip, setDisplayShip] = useState<IAddEditShip>(addEditShipInit);
    const [certificateModalOpen, setCertificateModalOpen] = useState(false);
    const { user } = useAuth();
    const dispatch = useDispatch();
    const [certificateDownloadUrl, setCertificateDownloadUrl] = useState<string>("");

    useEffect(() => {
        // convert IShipDispatcher to IAddEditShip
        if (selectedShip && `data` in selectedShip) {
            // remove contactDetails and invoiceAddress from selectedShip
            const { ...cleanShip } = selectedShip.data as IShipDispatcherData;
            setDisplayShip(cleanShip as IAddEditShip);
        } else {
            setDisplayShip(selectedShip as IAddEditShip);
        }

        // set data
        setShip(selectedShip ?? shipDispatcherInit);
    }, [selectedShip]);

    const handleDownloadCertificate = async () => {
        if (!displayShip.shipCertificate) return;
        try {
            let res;
            if (user.roles.includes(Roles.LOCKS_AND_DOCKS_DISPATCHER)) {
                res = await getUrlForDownloadLD(
                    displayShip.shipCertificate.name,
                    displayShip.shipCertificate.path,
                    true,
                );
            } else if (user.roles.includes(Roles.PASSENGER_CABIN_CRUISES_DISPATCHER)) {
                res = await getUrlForDownload(
                    displayShip.shipCertificate.name,
                    displayShip.shipCertificate.path,
                    true,
                );
            }
            if (res) {
                window.open(res.data, "_blank");
            }
        } catch {
            dispatch(showBackendMessage(intl, "error", "downloading", Messages.document));
        }
    };

    const handleGetDocumentUrl = async () => {
        if (!displayShip.shipCertificate) return;
        try {
            let res;
            if (user.roles.includes(Roles.LOCKS_AND_DOCKS_DISPATCHER)) {
                res = await getUrlForDownloadLD(
                    displayShip.shipCertificate.name,
                    displayShip.shipCertificate.path,
                    false,
                );
            } else if (user.roles.includes(Roles.PASSENGER_CABIN_CRUISES_DISPATCHER)) {
                res = await getUrlForDownload(
                    displayShip.shipCertificate.name,
                    displayShip.shipCertificate.path,
                    false,
                );
            }
            if (res) {
                setCertificateDownloadUrl(res.data);
                setCertificateModalOpen(true);
            }
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.document));
        }
    };

    return (
        <div className="ship-data-container">
            <div className="column-form four-column-form">
                <div className="ship-data-field">
                    <h5>{intl.formatMessage(Messages.shipName)}:</h5>
                    <p>{ship.name}</p>
                </div>
                <div className="ship-data-field">
                    <h5>{intl.formatMessage(Messages.eni)}:</h5>
                    <p>{ship.eni}</p>
                </div>
                <div className="ship-data-field">
                    <h5>{intl.formatMessage(Messages.mmsi)}:</h5>
                    <p>{ship.mmsi}</p>
                </div>
                <div className="ship-data-field">
                    <h5>{intl.formatMessage(Messages.flag)}:</h5>
                    <p>{displayShip.flag}</p>
                </div>
                <div className="ship-data-field">
                    <h5>{intl.formatMessage(Messages.regPort)}:</h5>
                    <p>{displayShip.regPort}</p>
                </div>
                <div className="ship-data-field">
                    <h5>{intl.formatMessage(Messages.length)}:</h5>
                    <p>{displayShip.length}</p>
                </div>
                <div className="ship-data-field">
                    <h5>{intl.formatMessage(Messages.width)}:</h5>
                    <p>{displayShip.width}</p>
                </div>
                <div className="ship-data-field">
                    <h5>{intl.formatMessage(Messages.portalWidth)}:</h5>
                    <p>{displayShip.portalWidth}</p>
                </div>
                <div className="ship-data-field">
                    <h5>{intl.formatMessage(Messages.entranceHeight)}:</h5>
                    <p>{displayShip.entranceHeight}</p>
                </div>
                <div className="ship-data-field">
                    <h5>{intl.formatMessage(Messages.entranceHeight2)}:</h5>
                    <p>{displayShip.entranceHeight2}</p>
                </div>
                <div className="ship-data-field">
                    <h5>{intl.formatMessage(Messages.validFrom)}:</h5>
                    <p>{displayShip.validFrom}</p>
                </div>
                <div className="ship-data-field">
                    <h5>{intl.formatMessage(Messages.validTo)}:</h5>
                    <p>{displayShip.validTo}</p>
                </div>
                <div className="ship-data-field">
                    <h5>{intl.formatMessage(Messages.onBoardTelephone1)}:</h5>
                    <p>{displayShip.tel1}</p>
                </div>
                <div className="ship-data-field">
                    <h5>{intl.formatMessage(Messages.onBoardTelephone2)}:</h5>
                    <p>{displayShip.tel2}</p>
                </div>
                <div className="ship-data-field">
                    <h5>{intl.formatMessage(Messages.onBoardEmail)}:</h5>
                    <p>{displayShip.email}</p>
                </div>
                {owningCompanyName && (
                    <div className="ship-data-field">
                        <h5>{intl.formatMessage(Messages.owningCompany)}:</h5>
                        <p>{owningCompanyName}</p>
                    </div>
                )}
                <div className="ship-data-field">
                    <h5>{intl.formatMessage(Messages.onBoardSewage)}:</h5>
                    <p>{displayShip.onBoardSewage}</p>
                </div>
            </div>
            <h3>{intl.formatMessage(Messages.shipCertificate)}</h3>
            <div className="invoice-address-data column-form four-column-form">
                <div className="ship-data-field">
                    <h5>{intl.formatMessage(Messages.certExpireDate)}:</h5>
                    <p>{displayShip.certExpireDate}</p>
                </div>
                <Button
                    className="ship-data-field"
                    disabled={!(displayShip.shipCertificate && displayShip.shipCertificate.path)}
                    variant="outlined"
                    id="upload-certificate-button"
                    onClick={handleDownloadCertificate}
                >
                    {intl.formatMessage(Messages.downloadCertificate)}
                </Button>
                {!displayShip.shipCertificate ||
                displayShip.shipCertificate.mimeType === "application/pdf" ? (
                    <Button
                        className="ship-data-field"
                        id="upload-certificate-button"
                        variant="outlined"
                        onClick={handleGetDocumentUrl}
                        disabled={
                            !(displayShip.shipCertificate && displayShip.shipCertificate.path)
                        }
                    >
                        {intl.formatMessage(Messages.previewCertificate)}
                    </Button>
                ) : (
                    <Tooltip
                        title={intl.formatMessage(Messages.fileFormatNotSupported)}
                        className="ship-data-field"
                        arrow
                    >
                        <span>
                            <Button
                                variant="outlined"
                                color="primary"
                                disabled
                                className="tooltip-button"
                            >
                                {intl.formatMessage(Messages.previewCertificate)}
                            </Button>
                        </span>
                    </Tooltip>
                )}
            </div>
            {owningCompany && (
                <>
                    <h3>{intl.formatMessage(Messages.owningCompany)}</h3>
                    <div className="column-form four-column-form">
                        {owningCompany.company && (
                            <div className="ship-data-field">
                                <h5>{intl.formatMessage(Messages.companyName)}</h5>
                                <p>{owningCompany.company}</p>
                            </div>
                        )}
                        {owningCompany.addition && (
                            <div className="ship-data-field">
                                <h5>{intl.formatMessage(Messages.addition)}</h5>
                                <p>{owningCompany.addition}</p>
                            </div>
                        )}
                        {owningCompany.street && (
                            <div className="ship-data-field">
                                <h5>{intl.formatMessage(Messages.street)}</h5>
                                <p>{owningCompany.street}</p>
                            </div>
                        )}
                        {owningCompany.streetNo && (
                            <div className="ship-data-field">
                                <h5>{intl.formatMessage(Messages.streetNo)}</h5>
                                <p>{owningCompany.streetNo}</p>
                            </div>
                        )}
                        {owningCompany.zip && (
                            <div className="ship-data-field">
                                <h5>{intl.formatMessage(Messages.zip)}</h5>
                                <p>{owningCompany.zip}</p>
                            </div>
                        )}
                        {owningCompany.city && (
                            <div className="ship-data-field">
                                <h5>{intl.formatMessage(Messages.city)}</h5>
                                <p>{owningCompany.city}</p>
                            </div>
                        )}
                        {owningCompany.country && (
                            <div className="ship-data-field">
                                <h5>{intl.formatMessage(Messages.country)}</h5>
                                <p>{owningCompany.country}</p>
                            </div>
                        )}
                        {owningCompany.firstName && (
                            <div className="ship-data-field">
                                <h5>{intl.formatMessage(Messages.firstName)}</h5>
                                <p>{owningCompany.firstName}</p>
                            </div>
                        )}
                        {owningCompany.surname && (
                            <div className="ship-data-field">
                                <h5>{intl.formatMessage(Messages.surname)}</h5>
                                <p>{owningCompany.surname}</p>
                            </div>
                        )}
                        {owningCompany.tel && (
                            <div className="ship-data-field">
                                <h5>{intl.formatMessage(Messages.telephone)}</h5>
                                <p>{owningCompany.tel}</p>
                            </div>
                        )}
                        {owningCompany.email && (
                            <div className="ship-data-field">
                                <h5>{intl.formatMessage(Messages.email)}</h5>
                                <p>{owningCompany.email}</p>
                            </div>
                        )}
                    </div>
                </>
            )}

            <Modal
                open={certificateModalOpen}
                onClose={() => setCertificateModalOpen(false)}
                aria-labelledby="certificate-preview-modal"
                aria-describedby="certificate-preview-description"
            >
                <div className="certificate-modal">
                    <div className="certificate-modal-content">
                        <iframe
                            title={displayShip.shipCertificate?.name}
                            src={certificateDownloadUrl}
                            name={displayShip.shipCertificate?.name}
                            width="100%"
                            height="100%"
                        ></iframe>
                        <div className="certificate-modal-close-button">
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={() => setCertificateModalOpen(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default ShipDataContainer;
