export const SET_SIDEBAR_OWNER = "SET_SIDEBAR_OWNER";

export interface SetSidebarOwner<T = unknown> {
    type: typeof SET_SIDEBAR_OWNER;
    payload: T | undefined;
}

export type SidebarOwnerActionType = SetSidebarOwner;

export function setSidebarOwnerAction<T = unknown>(payload: T | undefined): SidebarOwnerActionType {
    return { type: SET_SIDEBAR_OWNER, payload };
}
