import { TablePagination } from "@mui/material";
import { DatatablePaginationFilters } from "../../../../hooks/useGuiConfState";

export type DatatablePaginationProps = {
    filters: DatatablePaginationFilters;
    onChange: (filters: Partial<DatatablePaginationFilters>) => void;
    count: number;
    rowsPerPageOptions: number[];
};

export default function DatatablePagination({
    count,
    filters,
    onChange,
    rowsPerPageOptions,
}: DatatablePaginationProps) {
    const onPageChange = (_event: unknown, page: number) => {
        onChange({ page });
    };

    const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
        });
    };

    return (
        <TablePagination
            component="div"
            page={count === 0 ? 0 : filters.page}
            count={count}
            rowsPerPage={filters.rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
        />
    );
}
