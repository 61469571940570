import { Button, Select, MenuItem, Box, TextField, Typography } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import {
    DateFormat,
    addDays,
    clampDateWithinRange,
    getDateRangeText,
    subtractDays,
} from "../../../../helpers/dateHelper";
import Messages from "../../../../localization/Messages";
import { getLocaleString } from "../../../../models/userData";
import { Datepicker, locale } from "@mobiscroll/react";
import { useIntl } from "react-intl";
import "./EventCalendarFilters.css";
import { memo, useMemo } from "react";
import { withResponsiveDialog } from "../../../../hoc/withResponsiveDialog";
import { useAuth } from "../../../../hooks/useAuth";

export type IEventCalendarFilters = {
    rangeVal: [Date, Date];
    resolution: number;
    currentDate: Date;
};

export type EventCalendarFiltersProps = {
    value: IEventCalendarFilters;
    onChange: (value: IEventCalendarFilters) => void;
};

/**
 * Represents the Event Calendar Filters component.
 * This component allows users to filter events based on date range and resolution.
 *
 * @component
 * @example
 * ```tsx
 * <EventCalendarFilters
 *    value={filters}
 *    onChange={handleFiltersChange}
 *    prop1={value1}
 *    prop2={value2}
 * />
 * ```
 */
function EventCalendarFilters({ value: filters, onChange }: EventCalendarFiltersProps) {
    const intl = useIntl();
    const { user } = useAuth();
    const { rangeVal, resolution, currentDate: currentDateCopy } = filters;

    const dateRangeInputProps = useMemo(
        () => ({
            children: (
                <Typography variant="h6" className="whitespace-nowrap">
                    {getDateRangeText(rangeVal[0], rangeVal[1])}
                </Typography>
            ),
            className: "mbsc-calendar-button",
            variant: "flat",
        }),
        [rangeVal],
    );

    const emitChange = (partialFilters: Partial<IEventCalendarFilters>) => {
        onChange({ ...filters, ...partialFilters });
    };

    const onDateRangeChange = ({ value }: { value: [Date | null, Date | null] }) => {
        const startDate = value[0] ? value[0] : rangeVal[0];
        const endDate = value[1] ? value[1] : addDays(value[0]!, 7);
        const newCurrentDate = clampDateWithinRange(
            startDate,
            filters.currentDate,
            endDate,
        ).toDate();
        emitChange({
            rangeVal: [startDate, endDate],
            currentDate: newCurrentDate,
        });
    };

    return (
        <Box className="mbsc-filters-group">
            <Datepicker
                locale={locale[getLocaleString(user.locale)]}
                dateFormat={DateFormat.CLIENT_DATE}
                timeFormat={DateFormat.API_TIME}
                select="range"
                display="anchored"
                showOverlay={false}
                buttons={[]}
                inputComponent={Button}
                inputProps={dateRangeInputProps}
                onChange={onDateRangeChange}
                value={rangeVal}
            />
            <Select
                size="small"
                value={resolution}
                defaultValue={resolution}
                onChange={e => emitChange({ resolution: Number(e.target.value) })}
                style={{ marginRight: "10px" }}
            >
                {[1, 2, 3, 4, 6, 8, 12, 24].map(e => (
                    <MenuItem key={e} value={e}>
                        {`${e.toString()} ${intl.formatMessage(
                            e === 1 ? Messages.hour : Messages.hours,
                        )}`}
                    </MenuItem>
                ))}
            </Select>
            <Box display="flex" alignItems="center" gap={1}>
                <DesktopDatePicker
                    minDate={rangeVal[0]}
                    maxDate={rangeVal[1]}
                    InputProps={{ sx: { maxWidth: 200 } }}
                    label={intl.formatMessage(Messages.jumpToSpecificDate)}
                    inputFormat={DateFormat.CLIENT_DATE}
                    value={currentDateCopy}
                    onChange={value => {
                        const isValidDate = value && value.toString() !== "Invalid date";
                        if (!isValidDate) return;
                        emitChange({ currentDate: value });
                    }}
                    renderInput={params => (
                        <TextField variant="outlined" {...params} size="small" />
                    )}
                />
                <Button
                    onClick={() => {
                        const currentDate = new Date();
                        const rangeVal = [...filters.rangeVal] as [Date, Date];
                        if (rangeVal[0].getTime() > currentDate.getTime()) {
                            rangeVal[0] = subtractDays(currentDate, 7);
                        }
                        if (rangeVal[1].getTime() < currentDate.getTime()) {
                            rangeVal[1] = addDays(currentDate, 7);
                        }
                        emitChange({ currentDate, rangeVal });
                    }}
                >
                    Today
                </Button>
            </Box>
        </Box>
    );
}

export default memo(withResponsiveDialog(EventCalendarFilters, "650px"));
