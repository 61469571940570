import { useIntl } from "react-intl";
import RpisPage from "../RpisPage/RpisPage";
import Messages from "../../localization/Messages";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { TableSkeleton } from "../../components/TableSkeleton/TableSkeleton";
import DestinationForm from "./DestinationForm";
import "./ManageDestination.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { searchDestinationDisp } from "../../api/destinations";
import { IDestination } from "../../models/destination";
import { useCachedDocks } from "../../hooks/useCachedDocks";

export interface IManageDestination {
    setIsExistingUser?: React.Dispatch<React.SetStateAction<boolean | null>>;
}

/**
 * Renders the manage destination page where the dispatcher user edits information about their destination.
 * The user can also enter new or manage existing docks for their destination.
 */
export const ManageDestination = ({ setIsExistingUser }: IManageDestination) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const { docks } = useCachedDocks();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [destination, setDestination] = useState<IDestination>();

    useEffect(() => {
        const fetchDestination = async () => {
            try {
                setLoading(true);
                const res = await searchDestinationDisp();
                setDestination(res.data.rows[0]);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.destination));
            } finally {
                setLoading(false);
            }
        };

        fetchDestination();
    }, [dispatch, intl]);

    return (
        <RpisPage
            title={intl.formatMessage(Messages.manageDestination)}
            className="ld-manage-destination-container"
        >
            <DestinationForm setIsExistingUser={setIsExistingUser} />
            {destination && (
                <>
                    <div className="title-and-actions-container">
                        <h2>{intl.formatMessage(Messages.dockManagement)}</h2>
                        <Button variant="contained" onClick={() => navigate(`/dock/`)}>
                            {intl.formatMessage(Messages.addDock)}
                        </Button>
                    </div>
                    <TableContainer className="manage-destination-table-container">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">
                                        {intl.formatMessage(Messages.name)}
                                    </TableCell>
                                    <TableCell align="left">
                                        {intl.formatMessage(Messages.area)}
                                    </TableCell>
                                    <TableCell align="left">
                                        {intl.formatMessage(Messages.remark)}
                                    </TableCell>
                                    <TableCell align="left">
                                        {intl.formatMessage(Messages.ordnum)}
                                    </TableCell>
                                    <TableCell align="left">
                                        {intl.formatMessage(Messages.pubAllocationPlan)}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {loading ? (
                                <TableSkeleton columns={3} rows={10} />
                            ) : (
                                <TableBody>
                                    {docks.map(row => (
                                        <TableRow
                                            key={row.id}
                                            onClick={() => {
                                                navigate(`/dock/${row.id}`);
                                            }}
                                        >
                                            <TableCell align="left" component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="left">{row.area}</TableCell>
                                            <TableCell align="left">{row.remark}</TableCell>
                                            <TableCell align="left">{row.ordnum}</TableCell>
                                            <TableCell align="left">
                                                {intl.formatMessage(
                                                    row.pubAllocationPlan
                                                        ? Messages.yes
                                                        : Messages.no,
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </>
            )}
        </RpisPage>
    );
};

export default ManageDestination;
