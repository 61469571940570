import { useIntl } from "react-intl";
import RpisPage from "../RpisPage/RpisPage";
import SidebarLayout from "../SidebarLayout/SidebarLayout";
import { useCallback, useMemo, useState } from "react";
import {
    IService,
    getServiceStartDateTime,
    getServiceTypeLabel,
    serviceStatusOptions,
} from "../../models/service";
import { useServiceRenderAction } from "./hooks/useServiceRenderAction";
import Messages from "../../localization/Messages";
import { useGuiConfState } from "../../hooks/useGuiConfState";
import { useFetchServices } from "../../hooks/useFetchServices";
import { buildServicesSearchPayload } from "./shared/filters";
import { Datatable, DtColumnDef } from "../../components/Datatable/Datatable";
import { availableServices } from "../../models/dock";
import { DateFormat, getDateText } from "../../helpers/dateHelper";
import { ServiceForm } from "./components/ServiceForm/ServiceForm";
import { changeServiceInvoice, deleteService } from "../../api/service";
import { useDispatch } from "react-redux";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { ServiceFilters } from "./components/ServiceFilters/ServiceFilters";
import useFetchShips from "../ManageBookings/hooks/useFetchShips";
import { Box, Button, ButtonGroup, TablePagination, Tooltip } from "@mui/material";
import { Clear, Tune } from "@mui/icons-material";
import { BOOKINGS_ROWS_PER_PAGE_OPTS } from "../ManageBookings/shared/constants";
import RpisDialog from "../RpisDialog/RpisDialog";
import { IInvoice, InvoiceStatus } from "../../models/invoice";
import ViewInvoice from "../../components/ViewInvoice/ViewInvoice";
import { getInvoice } from "../../api/invoice";
import { useFetchInvoices } from "../../hooks/useFetchInvoices";
import { buildInvoicesSearchPayload } from "../ManageInvoices/shared/filters";

export const DispManageServices = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedService, setSelectedService] = useState<IService>();
    const [filters, setFilters] = useGuiConfState("dispManageServices");
    const [showFilters, setShowFilters] = useState(false);
    const { services, loading, totalServices, fetchServices } = useFetchServices(
        buildServicesSearchPayload(filters),
    );

    const [ships] = useFetchShips();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [displayedInvoice, setDisplayedInvoice] = useState<IInvoice>();
    const { data } = useFetchInvoices({
        payload: buildInvoicesSearchPayload({
            internalInvoiceNumber: "",
            dateFrom: null,
            dateTo: null,
            shipId: null,
            bookingDateFrom: null,
            bookingDateTo: null,
            invoiceStatus: [InvoiceStatus.OPEN],
            invoiceDate: null,
            order: ["internalInvoiceNumber asc"],
            rowsPerPage: 0,
            page: 0,
        }),
    });
    const invoices = useMemo(() => data?.invoices ?? [], [data]);

    const handleSelectService = (row: IService | undefined, sidebarOpen: boolean) => {
        setSelectedService(row);
        setSidebarOpen(sidebarOpen);
    };

    const handleViewInvoice = useCallback(
        async (invoiceId: number) => {
            try {
                const res = await getInvoice(String(invoiceId));
                setDisplayedInvoice(res.data ?? undefined);
                setDialogOpen(true);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.invoice));
            }
        },
        [dispatch, intl],
    );

    const handleDeleteService = useCallback(
        async (serviceId: string) => {
            try {
                await deleteService(serviceId);
                dispatch(showBackendMessage(intl, "success", "deleting", Messages.service));
                fetchServices();
                setSelectedService(undefined);
                setSidebarOpen(false);
            } catch {
                dispatch(showBackendMessage(intl, "error", "deleting", Messages.service));
            }
        },
        [dispatch, fetchServices, intl],
    );

    const handleAttachService = useCallback(
        async (serviceId: string, invoiceId: number | null) => {
            try {
                await changeServiceInvoice(serviceId, invoiceId);
                dispatch(showBackendMessage(intl, "success", "updating", Messages.service));
                fetchServices();
                setSelectedService(undefined);
                setSidebarOpen(false);
            } catch {
                dispatch(showBackendMessage(intl, "error", "updating", Messages.service));
            }
        },
        [dispatch, fetchServices, intl],
    );

    const clearFilters = () => {
        setFilters({
            dateFrom: null,
            dateTo: null,
            type: undefined,
            status: [],
            shipId: "",
            page: 0,
        });
    };

    const serviceTitleMapper = (row: IService) => (
        <strong>{`${getServiceTypeLabel(intl, row.type)} #${row.bookingId ?? ""} ${
            row.shipName
        } ${getDateText(
            getServiceStartDateTime(row.data.servicePersonnelData),
            DateFormat.CLIENT_DATE_TIME,
        )}
        `}</strong>
    );

    const serviceActions = useMemo(
        () => ({
            deleteService: (serviceId: string) => {
                handleDeleteService(serviceId);
            },
            viewInvoice: (invoiceId: number) => {
                handleViewInvoice(invoiceId);
            },
            attachInvoice: (serviceId: string, invoiceId: number) => {
                handleAttachService(serviceId, invoiceId);
            },
            detachInvoice: (serviceId: string) => {
                handleAttachService(serviceId, null);
            },
        }),
        [handleAttachService, handleDeleteService, handleViewInvoice],
    );

    const renderActions = useServiceRenderAction(
        row => handleSelectService(row, true),
        serviceActions,
        invoices,
    );

    const serviceColumnDefs: DtColumnDef<IService>[] = useMemo(
        () => [
            {
                key: "type",
                label: intl.formatMessage(Messages.type),
                sortable: true,
                content: row =>
                    intl.formatMessage(
                        availableServices.find(s => s.value === row.type)?.label ??
                            Messages.unknown,
                    ),
            },
            {
                key: "startDateTime",
                label: intl.formatMessage(Messages.startDate),
                sortable: true,
                content: row =>
                    getDateText(
                        getServiceStartDateTime(row.data.servicePersonnelData),
                        DateFormat.CLIENT_DATE_TIME,
                    ),
            },
            {
                key: "shipName",
                label: intl.formatMessage(Messages.shipName),
                sortable: true,
                content: row => row.shipName,
            },
            {
                key: "lastModifyBy",
                label: intl.formatMessage(Messages.lastModifiedBy),
                sortable: true,
                content: row => row.lastModifyBy ?? "",
            },
            {
                key: "serviceStatus",
                label: intl.formatMessage(Messages.serviceStatus),
                sortable: false,
                content: row =>
                    intl.formatMessage(
                        serviceStatusOptions.find(s => s.value === row.status)?.label ??
                            Messages.unknown,
                    ),
            },
        ],
        [intl],
    );

    const handleChangePage = useCallback(
        (_event: unknown, page: number) => {
            setFilters({ page });
        },
        [setFilters],
    );

    const handleChangeRowsPerPage = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setFilters({
                rowsPerPage: parseInt(event.target.value, 10),
                page: 0,
            });
        },
        [setFilters],
    );

    return (
        <RpisPage
            title={intl.formatMessage(Messages.manageServices)}
            className="manage-services-container"
        >
            {displayedInvoice && (
                <RpisDialog
                    className="view-invoice-dialog"
                    title={`${intl.formatMessage(Messages.invoice)} ${
                        displayedInvoice.internalInvoiceNumber
                    }`}
                    fullWidth={true}
                    size="xl"
                    dialogOpen={dialogOpen}
                    onClose={() => {
                        setDisplayedInvoice(undefined);
                        setDialogOpen(false);
                    }}
                    content={<ViewInvoice selectedInvoice={displayedInvoice} />}
                />
            )}
            <SidebarLayout
                sidebarTitle={intl.formatMessage(
                    selectedService?.id ? Messages.editService : Messages.addService,
                )}
                open={sidebarOpen}
                onClose={() => setSidebarOpen(false)}
                sidebarContent={
                    sidebarOpen ? (
                        <ServiceForm
                            onClose={() => setSidebarOpen(false)}
                            onDelete={handleDeleteService}
                            selectedService={selectedService}
                            onAddService={fetchServices}
                        />
                    ) : undefined
                }
                leftSidebar={{
                    title: intl.formatMessage(Messages.filters),
                    open: showFilters,
                    onClose: () => setShowFilters(false),
                    children: (
                        <ServiceFilters filters={filters} setFilters={setFilters} ships={ships} />
                    ),
                }}
            >
                <Box display="flex" padding={1} gap={1} justifyContent="space-between">
                    <ButtonGroup color="primary" variant="outlined">
                        <Button
                            variant="contained"
                            startIcon={<Tune />}
                            onClick={() => setShowFilters(true)}
                        >
                            {intl.formatMessage(Messages.filters)}
                        </Button>
                        <Tooltip title={intl.formatMessage(Messages.clearFilters)}>
                            <Button variant="contained" size="small" onClick={clearFilters}>
                                <Clear />
                            </Button>
                        </Tooltip>
                    </ButtonGroup>
                </Box>
                <Datatable
                    className="oc-manage-services-table-container"
                    items={services}
                    titleMapper={serviceTitleMapper}
                    columnDefs={serviceColumnDefs}
                    isActiveRow={row => selectedService?.id === row.id && sidebarOpen}
                    sortOrder={filters.order}
                    onSortChange={order => setFilters({ order })}
                    loading={loading}
                    idMapper={row => row.id ?? ""}
                    renderActions={renderActions}
                    renderPagination={() => (
                        <TablePagination
                            component="div"
                            page={totalServices === 0 ? 0 : filters.page}
                            count={totalServices}
                            rowsPerPage={filters.rowsPerPage}
                            rowsPerPageOptions={BOOKINGS_ROWS_PER_PAGE_OPTS}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                />
            </SidebarLayout>
        </RpisPage>
    );
};
