import { useCallback, useEffect, useState } from "react";
import UserPanel from "./components/UserPanel/UserPanel";
import CompanyActionsPanel from "./components/CompanyActionsPanel/CompanyActionsPanel";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import NotificationsIcon from "@mui/icons-material/Notifications";
import BusinessIcon from "@mui/icons-material/Business";
import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";
import { getCompanyName } from "../../api/companyName";
import { useIntl } from "react-intl";
import Messages from "../../localization/Messages";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { Roles } from "../../models/userData";
import NotificationsPanel from "./components/NotificationsPanel/NotificationsPanel";
import { Update } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Badge } from "@mui/material";
import { useNotifications } from "../../hooks/useNotifications";
import { useAuth } from "../../hooks/useAuth";
import { ButtonPanel } from "../ButtonPanel/ButtonPanel";
import "./UserCorner.css";

/**
 * Represents the user corner component. Part of the header component.
 * This component displays various panels and buttons related to the user's profile and actions.
 */

export const UserCorner = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const intl = useIntl();
    const { user, hasRole } = useAuth();
    const isOnCall = hasRole(Roles.ON_CALL_DISPATCHER);
    const [companyName, setCompanyName] = useState("");
    const { unread: unreadNotificationsCount } = useNotifications();
    const fetchCompanyName = useCallback(async () => {
        if (!user.companyId) return;
        try {
            const res = await getCompanyName(user.companyId);
            setCompanyName(res.data);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.companyName));
        }
    }, [dispatch, intl, user.companyId]);

    useEffect(() => {
        fetchCompanyName();
    }, [fetchCompanyName]);

    return (
        <div className="user-corner-container">
            <span className="profile mat-body-strong">
                {user.firstName} {user.lastName}
            </span>

            <ButtonPanel
                secure={Roles.PASSENGER_CABIN_CRUISES_DISPATCHER_ADMIN}
                ButtonRender={
                    <IconButton className="user-icon" onClick={() => navigate("/change-log")}>
                        <Update />
                    </IconButton>
                }
            />

            <ButtonPanel
                secure={Roles.COMPANY_ADMIN}
                ButtonRender={
                    <IconButton className="user-icon">
                        <BusinessIcon />
                    </IconButton>
                }
            >
                <CompanyActionsPanel />
            </ButtonPanel>

            {!isOnCall && (
                <ButtonPanel
                    ButtonRender={
                        <Badge
                            badgeContent={unreadNotificationsCount}
                            overlap="circular"
                            color="warning"
                        >
                            <IconButton className="notifications-icon">
                                <NotificationsIcon />
                            </IconButton>
                        </Badge>
                    }
                >
                    <NotificationsPanel />
                </ButtonPanel>
            )}

            <ButtonPanel
                ButtonRender={
                    <IconButton className="user-icon">
                        <AccountCircleRoundedIcon />
                    </IconButton>
                }
            >
                <UserPanel companyName={companyName} />
            </ButtonPanel>
        </div>
    );
};
export default UserCorner;
