import { ReactNode, memo, useRef } from "react";
import "./SidebarLayout.css";
import { Box } from "@mui/material";
import { SidebarDrawer, SidebarDrawerProps } from "../../components/SidebarDrawer/SidebarDrawer";

export interface ISidebarLayout {
    open: boolean;
    onClose?: () => void;
    sidebarTitle?: ReactNode;
    children: ReactNode;
    sidebarVariant?: "temporary" | "persistent";
    sidebarContent: ReactNode;
    hideRightSidebar?: boolean;

    leftSidebar?: Omit<SidebarDrawerProps, "anchor">;
    rightSidebar?: Omit<SidebarDrawerProps, "anchor">;
}

/**
 * Renders the sidebar layout for a page.
 *
 * @component
 * @example
 * ```tsx
 * <SidebarLayout
 *   open={true}
 *   sidebarContent={<SidebarContent />}
 *   onClose={handleClose}
 *   sidebarTitle="Sidebar"
 *   sidebarVariant="temporary"
 *   hideRightSidebar={false}
 *   leftSidebar={leftSidebarProps}
 *   rightSidebar={rightSidebarProps}
 * >
 *   <PageContent />
 * </SidebarLayout>
 * ```
 */
export const SidebarLayout = ({
    children,

    open,
    sidebarContent,
    onClose,
    sidebarTitle,
    sidebarVariant,
    hideRightSidebar = false,

    leftSidebar,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    rightSidebar: _rightSidebar,
}: ISidebarLayout) => {
    const ref = useRef<any>();

    const leftSidebarPropsVariant = leftSidebar?.variant ?? "temporary";
    const rightSidebarPropsVariant = sidebarVariant ?? "persistent";
    const leftSidebarWidth = 500;
    const rightSidebarWidth = 550;
    const rightSidebar = {
        open: open,
        title: sidebarTitle,
        variant: rightSidebarPropsVariant,
        onClose: onClose,
    };

    const isLeftPersistentOpen = leftSidebar?.open && leftSidebarPropsVariant === "persistent";
    const marginLeft = isLeftPersistentOpen ? `${leftSidebarWidth}px` : "0";
    const isRightPersistentOpen = rightSidebar?.open && rightSidebarPropsVariant === "persistent";
    const marginRight = isRightPersistentOpen ? `${rightSidebarWidth}px` : "0";
    const transitionLeft = "margin-left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms";
    const transitionRight = "margin-right 225ms cubic-bezier(0, 0, 0.2, 1) 0ms";
    const transition = `${transitionLeft}, ${transitionRight}`;
    const childrenStyles = {
        marginLeft,
        marginRight,
        transition,
        width: "100%",
        height: "calc(100vh - var(--header-height))",
        overflow: "auto",
    };

    return (
        <Box display="flex" ref={ref}>
            {leftSidebar && (
                <SidebarDrawer width={leftSidebarWidth} {...leftSidebar} anchor="left" />
            )}

            <Box sx={childrenStyles}>{children}</Box>

            {!hideRightSidebar && rightSidebar && (
                <SidebarDrawer width={rightSidebarWidth} {...rightSidebar} anchor="right">
                    {sidebarContent}
                </SidebarDrawer>
            )}
        </Box>
    );
};

export default memo(SidebarLayout);
