import {
    Autocomplete,
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Menu,
    MenuItem,
    TextField,
    Tooltip,
} from "@mui/material";
import { IService, ServiceStatus } from "../../../../models/service";
import { useIntl } from "react-intl";
import Messages from "../../../../localization/Messages";
import { Fragment, useCallback, useRef, useState } from "react";
import { ArrowDropDown } from "@mui/icons-material";
import { useConfirmDialog } from "../../../../hooks/useConfirmDialog";
import { IInvoice } from "../../../../models/invoice";

export type ServiceMenuButtonGroupProps = {
    row: IService;
    onClick: () => void;
    menuActions: {
        deleteService: (serviceid: string) => void;
        viewInvoice: (invoiceId: number) => void;
        attachInvoice: (serviceId: string, invoiceId: number) => void;
        detachInvoice: (serviceId: string) => void;
    };
    invoices: IInvoice[];
};

export const ServiceButtonMenuGroup = ({
    row,
    onClick,
    menuActions,
    invoices,
}: ServiceMenuButtonGroupProps) => {
    const intl = useIntl();
    const [open, setOpen] = useState(false);
    const menuButtonRef = useRef<HTMLButtonElement>(null);
    const withConfirm = useConfirmDialog();
    const [invoice, setInvoice] = useState<IInvoice>();
    const [attachServiceDialog, setAttachServiceDialog] = useState(false);
    const [detachServiceDialog, setDetachServiceDialog] = useState(false);

    const serviceNotOpen = row.status !== ServiceStatus.OPEN;
    const serviceAttached = !!row.invoiceId;

    const handleCloseDialog = () => {
        setAttachServiceDialog(false);
        setDetachServiceDialog(false);
    };

    const handleAttachService = () => {
        invoice?.id && menuActions.attachInvoice(row.id ?? "", invoice?.id);
    };

    const handleDetachService = () => {
        menuActions.detachInvoice(row.id ?? "");
    };

    const handleToggle = useCallback(() => {
        setOpen(prev => !prev);
    }, []);

    return (
        <>
            <ButtonGroup variant="contained">
                <Tooltip
                    sx={{ pointer: "auto" }}
                    title={
                        row.finalized
                            ? intl.formatMessage(Messages.serviceEditUnavailableMessage)
                            : serviceNotOpen
                            ? intl.formatMessage(Messages.serviceEditUnavailableMessage2)
                            : ""
                    }
                >
                    <span>
                        <Button
                            variant="contained"
                            onClick={onClick}
                            disabled={Boolean(row.finalized) || serviceNotOpen}
                        >
                            {intl.formatMessage(Messages.edit)}
                        </Button>
                    </span>
                </Tooltip>
                <Button size="small" ref={menuButtonRef} onClick={handleToggle}>
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>
            <Menu anchorEl={menuButtonRef?.current} open={open} onClose={handleToggle}>
                <MenuItem
                    onClick={() => row.invoiceId && menuActions.viewInvoice(row.invoiceId)}
                    disabled={!row.invoiceId}
                >
                    {intl.formatMessage(Messages.viewInvoice)}
                </MenuItem>

                <Tooltip
                    title={
                        row.finalized
                            ? intl.formatMessage(Messages.serviceEditUnavailableMessage)
                            : serviceNotOpen
                            ? intl.formatMessage(Messages.serviceEditUnavailableMessage2)
                            : ""
                    }
                >
                    <span>
                        <MenuItem
                            disabled={!!row.finalized || serviceNotOpen}
                            onClick={() =>
                                row.id &&
                                !row.finalized &&
                                withConfirm({
                                    message: intl.formatMessage(Messages.confirmServiceDeleteText),
                                    onConfirm: () => menuActions.deleteService(row.id ?? ""),
                                })
                            }
                        >
                            {intl.formatMessage(Messages.delete)}
                        </MenuItem>
                    </span>
                </Tooltip>

                <Tooltip
                    title={
                        serviceAttached
                            ? intl.formatMessage(Messages.attachServiceDisabledMessage)
                            : ""
                    }
                >
                    <span>
                        <MenuItem
                            onClick={() => !serviceAttached && setAttachServiceDialog(true)}
                            disabled={serviceAttached}
                        >
                            {intl.formatMessage(Messages.attachToInvoice)}
                        </MenuItem>
                    </span>
                </Tooltip>

                <Tooltip
                    title={
                        !serviceAttached
                            ? intl.formatMessage(Messages.serviceNotAttachedMessage)
                            : serviceNotOpen
                            ? intl.formatMessage(Messages.detachServiceDisabledMessage)
                            : ""
                    }
                >
                    <span>
                        <MenuItem
                            onClick={() =>
                                !serviceNotOpen && serviceAttached && setDetachServiceDialog(true)
                            }
                            disabled={serviceNotOpen || !serviceAttached}
                        >
                            {intl.formatMessage(Messages.detachFromInvoice)}
                        </MenuItem>
                    </span>
                </Tooltip>
            </Menu>
            <Dialog
                open={attachServiceDialog || detachServiceDialog}
                className="expense-actions-dialog"
                onClose={() => {
                    handleCloseDialog();
                    setInvoice(undefined);
                }}
            >
                <DialogTitle>
                    {attachServiceDialog
                        ? intl.formatMessage(Messages.attachToInvoice)
                        : detachServiceDialog && intl.formatMessage(Messages.detachFromInvoice)}
                </DialogTitle>
                <DialogContent>
                    {attachServiceDialog ? (
                        <>
                            <span>{intl.formatMessage(Messages.attachToInvoiceMessage)}</span>
                            <Autocomplete
                                id="invoice-company-name"
                                options={invoices}
                                value={invoices.find(inv => inv.id === invoice?.id) ?? null}
                                onChange={(_, value) => setInvoice(value ?? undefined)}
                                getOptionLabel={o =>
                                    `${o.internalInvoiceNumber} - ${o.invoiceCompanyName}`
                                }
                                isOptionEqualToValue={(a, b) => a.id === b.id}
                                renderInput={params => (
                                    <TextField
                                        variant="filled"
                                        {...params}
                                        label={intl.formatMessage(Messages.invoice)}
                                    />
                                )}
                                renderOption={(props, o) => (
                                    <li {...props} key={o.id}>
                                        {o.internalInvoiceNumber} {o.invoiceCompanyName}
                                    </li>
                                )}
                            />
                        </>
                    ) : (
                        detachServiceDialog && (
                            <span>{intl.formatMessage(Messages.detachFromInvoiceMessage)}</span>
                        )
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            handleCloseDialog();
                        }}
                    >
                        {intl.formatMessage(Messages.cancel)}
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            attachServiceDialog
                                ? handleAttachService()
                                : detachServiceDialog && handleDetachService();
                            handleCloseDialog();
                        }}
                    >
                        {intl.formatMessage(Messages.confirm)}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
