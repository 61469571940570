import { getDateMoment } from "../../../helpers/dateHelper";
import { SortOrder } from "../../../hooks/useTableSort";
import { InvoiceStatus } from "../../../models/invoice";
import { ISearchParams, searchParamsInit } from "../../../models/searchParams";

export type InvoiceFilters = {
    internalInvoiceNumber: string;
    dateTo: Date | null;
    dateFrom: Date | null;
    bookingDateTo: Date | null;
    bookingDateFrom: Date | null;
    invoiceStatus: InvoiceStatus[];
    rowsPerPage: number;
    page: number;
    order: SortOrder;
    invoiceDate: Date | null;
    shipId: string | null;
};

export type InvoicesSearchPayload = ISearchParams;

export function buildInvoicesSearchPayload(filters: InvoiceFilters): InvoicesSearchPayload {
    return {
        ...searchParamsInit,
        textSearch: filters.internalInvoiceNumber,
        offset: filters.page * filters.rowsPerPage,
        limit: filters.rowsPerPage,
        order: filters.order,
        columns: {},
        filter: buildInvoicesSearchPayloadFilters(filters),
    };
}

function buildInvoicesSearchPayloadFilters(filters: InvoiceFilters) {
    const { dateFrom, dateTo, invoiceStatus, bookingDateFrom, bookingDateTo, shipId } = filters;

    const filterArray: object[] = [];

    if (invoiceStatus.length > 0) {
        const iStatusFilterArray: object[] = [];
        invoiceStatus.forEach(st => {
            iStatusFilterArray.push({ invoiceStatus: st.toString() });
        });
        filterArray.push({ $or: iStatusFilterArray });
    }

    if (shipId) {
        filterArray.push({ shipId: shipId });
    }

    if (dateFrom && dateTo) {
        const startDate = getDateMoment(dateFrom).toDate();
        const endDate = getDateMoment(dateTo).toDate();
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 59);
        filterArray.push(
            { invoiceDate: { $gte: startDate.getTime() } },
            { invoiceDate: { $lte: endDate.getTime() } },
        );
    }

    if (dateFrom && !dateTo) {
        const startDate = getDateMoment(dateFrom).toDate();
        startDate.setHours(0, 0, 0, 0);
        filterArray.push({ invoiceDate: { $gte: startDate.getTime() } });
    }

    if (!dateFrom && dateTo) {
        const endDate = getDateMoment(dateTo).toDate();
        endDate.setHours(23, 59, 59, 59);
        filterArray.push({ invoiceDate: { $lte: endDate.getTime() } });
    }

    if (bookingDateFrom && bookingDateTo) {
        const startDate = getDateMoment(bookingDateFrom).toDate();
        const endDate = getDateMoment(bookingDateTo).toDate();
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 59);
        filterArray.push(
            { bookingDate: { $gte: startDate.getTime() } },
            { bookingDate: { $lte: endDate.getTime() } },
        );
    }

    if (bookingDateFrom && !bookingDateTo) {
        const startDate = getDateMoment(bookingDateFrom).toDate();
        startDate.setHours(0, 0, 0, 0);

        filterArray.push({ bookingDate: { $gte: startDate.getTime() } });
    }

    if (!bookingDateFrom && bookingDateTo) {
        const endDate = getDateMoment(bookingDateTo).toDate();
        endDate.setHours(23, 59, 59, 59);
        filterArray.push({ bookingDate: { $lte: endDate.getTime() } });
    }

    return { $and: filterArray };
}
