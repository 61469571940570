import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TextField,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import Messages from "../../localization/Messages";
import { addressInit, AddressType, AddressTypes, IAddress } from "../../models/address";
import { addAddress, changeAddress } from "../../api/address";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { useDispatch } from "react-redux";
import { getCountryNames, getCountryDialCode } from "../../localization/country-names/countryNames";
import { useAuth } from "../../hooks/useAuth";

interface IAddressForm {
    selectedAddress: IAddress | undefined;
    callback: () => void;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
type AddressErrors = {
    [Key in keyof Partial<IAddress>]: string;
};

/**
 * This component represents an Address form used in the sidebar of the Locks & Docks Manage addresses page.
 * It allows the user to add or edit an address.
 *
 * @component
 * @example
 * ```tsx
 * <AddressForm
 *   selectedAddress={selectedAddress}
 *   callback={handleCallback}
 *   setSidebarOpen={setSidebarOpen}
 * />
 * ```
 */
export const AddressForm = ({ selectedAddress, callback, setSidebarOpen }: IAddressForm) => {
    const intl = useIntl();
    const [address, setAddress] = useState(addressInit);
    const [errors, setErrors] = useState<AddressErrors>({});
    const [isDisabled, setIsDisabled] = useState(false);
    const [saveInvoiceDialogOpen, setSaveInvoiceDialogOpen] = useState(false);
    const dispatch = useDispatch();
    const { user } = useAuth();
    const countries = getCountryNames(user.locale);

    const onChange = (diff: Partial<IAddress>) => {
        setAddress(prev => ({ ...prev, ...diff }));
        const clearErrors = Object.fromEntries(Object.entries(diff).map(([k]) => [k, undefined]));
        setErrors({ ...errors, ...clearErrors });
    };

    const validationRegex = {
        email: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
        tel: /^\+?[0-9-/ ]*$/,
    };

    useEffect(() => {
        setIsDisabled(selectedAddress?.type === AddressType.InvoiceAddress);
    }, [selectedAddress]);

    useEffect(() => {
        if (address.country) {
            const preCode = getCountryDialCode(user.locale, address.country);
            const dialCode = preCode ? (preCode.startsWith("+") ? preCode : `+${preCode}`) : "";
            dialCode &&
                !address.tel?.startsWith(dialCode) &&
                setAddress({
                    ...address,
                    tel: dialCode ?? "",
                });
        }
    }, [address, user.locale]);

    const validateAddress = () => {
        const newErrors: AddressErrors = {};
        if (!address.type) {
            newErrors.type = intl.formatMessage(Messages.addressTypeIsRequired);
        }
        if (
            (address.type === AddressType.InvoiceAddress ||
                address.type === AddressType.CharterCompany ||
                address.type === AddressType.OwningCompany) &&
            !address.company
        ) {
            newErrors.company = intl.formatMessage(Messages.companyNameIsRequired);
        }
        if (address.type === AddressType.InvoiceAddress && !address.street) {
            newErrors.street = intl.formatMessage(Messages.streetIsRequired);
        }
        if (address.type === AddressType.InvoiceAddress && !address.streetNo) {
            newErrors.streetNo = intl.formatMessage(Messages.streetNoIsRequired);
        }
        if (address.type === AddressType.InvoiceAddress && !address.zip) {
            newErrors.zip = intl.formatMessage(Messages.zipIsRequired);
        }
        if (address.type === AddressType.InvoiceAddress && !address.city) {
            newErrors.city = intl.formatMessage(Messages.cityIsRequired);
        }
        if (address.type === AddressType.InvoiceAddress && !address.country) {
            newErrors.country = intl.formatMessage(Messages.countryIsRequired);
        }
        if (address.type === AddressType.ContactDetails && !address.firstName) {
            newErrors.firstName = intl.formatMessage(Messages.firstNameIsRequired);
        }
        if (address.type === AddressType.ContactDetails && !address.surname) {
            newErrors.surname = intl.formatMessage(Messages.lastNameIsRequired);
        }
        if (address.type === AddressType.ContactDetails && !address.tel) {
            newErrors.tel = intl.formatMessage(Messages.telIsRequired);
        }
        if (address.type === AddressType.ContactDetails && !address.email) {
            newErrors.email = intl.formatMessage(Messages.emailIsRequired);
        }
        if (address.email && !validationRegex.email.test(address.email))
            newErrors.email = intl.formatMessage(Messages.emailInvalid);

        return newErrors;
    };

    const handleSubmit = () => {
        const newErrors = validateAddress();
        if (Object.keys(newErrors).length) {
            setErrors(newErrors);
            return;
        }
        if (address.type === AddressType.InvoiceAddress && !selectedAddress) {
            setSaveInvoiceDialogOpen(true);
            return;
        }
        if (selectedAddress?.type !== AddressType.InvoiceAddress) {
            handleAddAddress();
        }
    };

    const handleAddAddress = async () => {
        try {
            if (selectedAddress) {
                await changeAddress(address);
                dispatch(showBackendMessage(intl, "success", "updating", Messages.address));
            } else {
                await addAddress(address);
                dispatch(showBackendMessage(intl, "success", "adding", Messages.address));
            }
            callback();
            onChange(addressInit);
        } catch {
            dispatch(
                showBackendMessage(
                    intl,
                    "error",
                    selectedAddress ? "updating" : "adding",
                    Messages.address,
                ),
            );
        }
    };

    useEffect(() => {
        setAddress(selectedAddress ? { ...selectedAddress } : addressInit);
    }, [selectedAddress]);

    return (
        <div className="address-form">
            <h3>{intl.formatMessage(Messages.address)}</h3>
            <Grid container alignItems="center" spacing={2} marginBottom={2}>
                <Grid item sm={6}>
                    <Autocomplete
                        options={AddressTypes}
                        getOptionLabel={o => intl.formatMessage(o.label)}
                        renderInput={params => (
                            <TextField
                                required
                                variant="filled"
                                {...params}
                                label={intl.formatMessage(Messages.addressType)}
                                error={Boolean(errors.type)}
                                helperText={errors.type}
                            />
                        )}
                        renderOption={(props, option) => (
                            <li {...props} key={option.id}>
                                {intl.formatMessage(option.label)}
                            </li>
                        )}
                        onChange={(_, value) => onChange({ type: value?.type })}
                        value={AddressTypes.find(add => add.type === address.type) ?? null}
                    />
                </Grid>
                <Grid item sm={6}>
                    <TextField
                        required={
                            address.type === AddressType.InvoiceAddress ||
                            address.type === AddressType.CharterCompany
                        }
                        error={Boolean(errors.company)}
                        value={address.company ?? ""}
                        label={intl.formatMessage(Messages.companyName)}
                        onChange={e => onChange({ company: e.target.value })}
                        helperText={errors.company}
                        variant="filled"
                        InputProps={{ readOnly: isDisabled }}
                    />
                </Grid>
                <Grid item sm={6}>
                    <TextField
                        value={address.addition ?? ""}
                        label={intl.formatMessage(Messages.addition)}
                        onChange={e => onChange({ addition: e.target.value })}
                        variant="filled"
                        InputProps={{ readOnly: isDisabled }}
                    />
                </Grid>
                <Grid item sm={6}>
                    <TextField
                        required={address.type === AddressType.InvoiceAddress}
                        error={Boolean(errors.street)}
                        value={address.street ?? ""}
                        label={intl.formatMessage(Messages.street)}
                        onChange={e => onChange({ street: e.target.value })}
                        helperText={errors.street}
                        variant="filled"
                        InputProps={{ readOnly: isDisabled }}
                    />
                </Grid>
                <Grid item sm={6}>
                    <TextField
                        required={address.type === AddressType.InvoiceAddress}
                        error={Boolean(errors.streetNo)}
                        value={address.streetNo ?? ""}
                        label={intl.formatMessage(Messages.streetNo)}
                        onChange={e => onChange({ streetNo: e.target.value })}
                        helperText={errors.streetNo}
                        variant="filled"
                        InputProps={{ readOnly: isDisabled }}
                    />
                </Grid>
                <Grid item sm={6}>
                    <TextField
                        required={address.type === AddressType.InvoiceAddress}
                        error={Boolean(errors.zip)}
                        value={address.zip ?? ""}
                        label={intl.formatMessage(Messages.zip)}
                        onChange={e => onChange({ zip: e.target.value })}
                        helperText={errors.zip}
                        variant="filled"
                    />
                </Grid>
                <Grid item sm={6}>
                    <TextField
                        required={address.type === AddressType.InvoiceAddress}
                        error={Boolean(errors.city)}
                        value={address.city ?? ""}
                        label={intl.formatMessage(Messages.city)}
                        onChange={e => onChange({ city: e.target.value })}
                        helperText={errors.city}
                        variant="filled"
                    />
                </Grid>
                <Grid item sm={6}>
                    <Autocomplete
                        id="select-country-field"
                        options={countries.sort((a, b) => {
                            return a.localeCompare(b);
                        })}
                        renderInput={params => (
                            <TextField
                                {...params}
                                required={address.type === AddressType.InvoiceAddress}
                                variant="filled"
                                label={intl.formatMessage(Messages.country)}
                                error={Boolean(errors.country)}
                                helperText={errors.country}
                            />
                        )}
                        renderOption={(props, option) => <li {...props}>{option}</li>}
                        onChange={(_, value: string | null) =>
                            onChange({ country: value ?? undefined })
                        }
                        value={countries.find(co => co === address.country) ?? null}
                    />
                </Grid>
                <Grid item sm={6}>
                    <TextField
                        required={address.type === AddressType.ContactDetails}
                        error={Boolean(errors.firstName)}
                        value={address.firstName ?? ""}
                        label={intl.formatMessage(Messages.firstName)}
                        onChange={e => onChange({ firstName: e.target.value })}
                        helperText={errors.firstName}
                        variant="filled"
                    />
                </Grid>
                <Grid item sm={6}>
                    <TextField
                        required={address.type === AddressType.ContactDetails}
                        error={Boolean(errors.surname)}
                        value={address.surname ?? ""}
                        label={intl.formatMessage(Messages.surname)}
                        onChange={e => onChange({ surname: e.target.value })}
                        helperText={errors.surname}
                        variant="filled"
                    />
                </Grid>
                <Grid item sm={6}>
                    <TextField
                        required={address.type === AddressType.ContactDetails}
                        error={Boolean(errors.tel)}
                        value={address.tel ?? ""}
                        label={intl.formatMessage(Messages.telephone)}
                        onChange={e => {
                            validationRegex.tel.test(e.target.value) &&
                                onChange({ tel: e.target.value });
                        }}
                        helperText={errors.tel}
                        variant="filled"
                    />
                </Grid>
                <Grid item sm={6}>
                    <TextField
                        required={address.type === AddressType.ContactDetails}
                        error={Boolean(errors.email)}
                        value={address.email ?? ""}
                        label={intl.formatMessage(Messages.email)}
                        onChange={e => onChange({ email: e.target.value })}
                        helperText={errors.email}
                        variant="filled"
                    />
                </Grid>
            </Grid>

            <Button
                disabled={isDisabled}
                variant="contained"
                className="submit-address-form-button button-primary"
                onClick={() => handleSubmit()}
            >
                {selectedAddress
                    ? intl.formatMessage(Messages.save)
                    : intl.formatMessage(Messages.addAddress)}
            </Button>
            <Button
                variant="outlined"
                onClick={() => setSidebarOpen(false)}
                className="cancel-button"
            >
                {intl.formatMessage(Messages.cancel)}
            </Button>
            <Dialog open={saveInvoiceDialogOpen} onClose={() => setSaveInvoiceDialogOpen(false)}>
                <DialogTitle>{intl.formatMessage(Messages.saveInvoiceAddressTitle)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {intl.formatMessage(Messages.saveInvoiceAddressmessage)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setSaveInvoiceDialogOpen(false)}>
                        {intl.formatMessage(Messages.cancel)}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            handleAddAddress();
                            setSaveInvoiceDialogOpen(false);
                        }}
                        color="primary"
                    >
                        {intl.formatMessage(Messages.confirm)}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AddressForm;
