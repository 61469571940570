import { IDispatcherAddress } from "../models/address";

export const SET_DISP_ADDRESSES = "SET_DISP_ADDRESSES";

export interface SetDispAddresses {
    type: typeof SET_DISP_ADDRESSES;
    payload: IDispatcherAddress[];
}

export type DispAddressesActionType = SetDispAddresses;

/**
 * Sets the dispatcher addresses from the request.
 * @param payload - The dispatcher addresses to set.
 * @returns The action to execute.
 */
export function setDispAddressesFromRequest(
    payload: IDispatcherAddress[],
): DispAddressesActionType {
    return { type: SET_DISP_ADDRESSES, payload };
}
