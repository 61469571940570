import { AxiosStatic } from "axios";

export const configureAxiosClient = (axios: AxiosStatic) => {
    axios.interceptors.request.use(config => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers!.Authorization = `Bearer ${token}`;
        }
        return config;
    });
    axios.interceptors.request.use(config => {
        config.baseURL = config.baseURL ?? `/api/`;
        return config;
    });
    axios.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            console.error(error);
            return Promise.reject(error);
        },
    );
};
