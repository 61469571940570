import { changeVoyageAvailable } from "../../../api/voyage";
import { FetchFunctionResult, useFetch } from "../../../hooks/useFetch";

export function useFetchChangeVoyageAvailable(
    voyageId: number | null,
): FetchFunctionResult<boolean | null> {
    const { data, loading, refetch } = useFetch<boolean | null>({
        fetchFn: async () => {
            try {
                if (!voyageId) return null;
                const res = await changeVoyageAvailable(voyageId);
                return res.data;
            } catch {
                return null;
            } finally {
                // NOOP
            }
        },
        fetchDeps: [voyageId],
    });
    return { data, loading, refetch };
}
