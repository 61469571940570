import { useContext, useEffect, useState } from "react";
import { SocketContext } from "../contexts/SocketContext";
import { INotification } from "../models/notification";
import { IMessage } from "../models/message";
import { WsMessage } from "../models/common";

type SocketMapper = {
    Notification: INotification;
    Message: IMessage;
};

export type SocketEntity = keyof SocketMapper;

/**
 * Custom hook that provides access to the socket context.
 * @param entity - The entity to listen for.
 * @returns The last message for the given entity.
 * @throws {Error} If used outside of the SocketProvider.
 */
export function useSocket<TEntity extends SocketEntity, TData extends SocketMapper[TEntity]>(
    entity: TEntity,
): {
    isOpen: boolean;
    lastMessage: TData | undefined;
} {
    const socketContext = useContext(SocketContext);
    if (socketContext === undefined) throw new Error("useSocket must be used within BrowserRouter");
    const { lastMessage: lastSocketMessage } = socketContext;
    const [lastMessage, setLastMessage] = useState<TData>();

    useEffect(() => {
        if (lastSocketMessage == null) return;
        const socketMessage: WsMessage<TData> = JSON.parse(lastSocketMessage.data);
        if (socketMessage.entity !== entity) return;
        const meta: TData = socketMessage.data;
        setLastMessage(meta);
    }, [lastSocketMessage, entity]);

    return { lastMessage, isOpen: socketContext.isOpen };
}
