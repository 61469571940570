import { defineMessages } from "react-intl";

export default defineMessages({
    choseApplication: {
        id: "choseApplication",
        defaultMessage: "Chose application",
    },
    manageBooking: { id: "manageBooking", defaultMessage: "Manage booking" },
    createInternalBooking: {
        id: "createInternalBooking",
        defaultMessage: "Create internal booking",
    },
    editInternalBooking: {
        id: "editInternalBooking",
        defaultMessage: "Edit internal booking",
    },
    manageBookings: { id: "manageBookings", defaultMessage: "Manage bookings" },
    manageDestination: {
        id: "manageDestination",
        defaultMessage: "Manage destination",
    },

    manageVoyages: { id: "manageVoyages", defaultMessage: "Manage voyages" },
    manageFleet: { id: "manageFleet", defaultMessage: "Manage fleet" },
    manageShips: { id: "manageShips", defaultMessage: "Manage ships" },
    viewDockAllocationPlan: {
        id: "viewDockAllocationPlan",
        defaultMessage: "View dock allocation plan",
    },
    companyName: { id: "companyName", defaultMessage: "Company name" },
    addition: { id: "addition", defaultMessage: "Addition" },
    companyNameIsRequired: {
        id: "companyNameIsRequired",
        defaultMessage: "Company name is required",
    },
    street: { id: "street", defaultMessage: "Street" },
    streetNo: { id: "streetNo", defaultMessage: "Street number" },
    zip: { id: "zip", defaultMessage: "Postal code" },
    city: { id: "city", defaultMessage: "City" },
    country: { id: "country", defaultMessage: "Country" },
    firstName: { id: "firstName", defaultMessage: "First name" },
    surname: { id: "surname", defaultMessage: "Surname" },
    streetIsRequired: {
        id: "streetIsRequired",
        defaultMessage: "Street name is required",
    },
    streetNoIsRequired: {
        id: "streetNoIsRequired",
        defaultMessage: "Street number is required",
    },
    zipIsRequired: {
        id: "zipIsRequired",
        defaultMessage: "Postal code is required",
    },
    cityIsRequired: {
        id: "cityIsRequired",
        defaultMessage: "City is required",
    },
    countryIsRequired: {
        id: "countryIsRequired",
        defaultMessage: "Country is required",
    },
    firstNameIsRequired: {
        id: "firstNameIsRequired",
        defaultMessage: "User's first name is required",
    },
    lastNameIsRequired: {
        id: "lastNameIsRequired",
        defaultMessage: "User's surname is required",
    },
    telIsRequired: {
        id: "telIsRequired",
        defaultMessage: "Telephone number is required",
    },
    invoiceAddress: { id: "invoiceAddress", defaultMessage: "Invoice Address" },
    contactDetails: { id: "contactDetails", defaultMessage: "Contact Details" },
    contactDetailsAreRequired: {
        id: "contactDetailsAreRequired",
        defaultMessage: "Contact details are required",
    },
    activeRequestWarning: {
        id: "activeRequestWarning",
        defaultMessage: "Cannot discard a voyage containing a stop with an active booking request",
    },
    addVessel: { id: "addVessel", defaultMessage: "Add Vessel" },
    saveNewVessel: { id: "saveNewVessel", defaultMessage: "Save New Vessel" },
    editVesel: { id: "editVesel", defaultMessage: "Edit Vessel" },
    shipName: { id: "shipName", defaultMessage: "Ship Name" },
    flag: { id: "flag", defaultMessage: "Flag" },
    contactPerson: { id: "contactPerson", defaultMessage: "Contact Person" },
    onBoardTelephone1: {
        id: "On Board Telephone 1",
        defaultMessage: "On Board Telephone 1",
    },
    onBoardTelephone2: {
        id: "On Board Telephone 2",
        defaultMessage: "On Board Telephone 2",
    },
    active: { id: "active", defaultMessage: "Active" },
    inactive: { id: "inactive", defaultMessage: "Inactive" },
    actions: { id: "actions", defaultMessage: "Actions" },
    action: { id: "action", defaultMessage: "Action" },
    success: { id: "success", defaultMessage: "Success" },
    error: { id: "error", defaultMessage: "Error" },
    edit: { id: "edit", defaultMessage: "Edit" },
    newVoyage: { id: "newVoyage", defaultMessage: "New voyage" },
    delete: { id: "delete", defaultMessage: "Delete" },
    discard: { id: "discard", defaultMessage: "Discard" },
    search: { id: "search", defaultMessage: "Search" },
    cancel: { id: "cancel", defaultMessage: "Cancel" },
    save: { id: "save", defaultMessage: "Save" },
    yes: { id: "yes", defaultMessage: "Yes" },
    no: { id: "no", defaultMessage: "No" },
    ok: { id: "ok", defaultMessage: "OK" },
    confirmDelete: { id: "confirmDelete", defaultMessage: "Confirm delete" },
    confirmShipDeleteText: {
        id: "confirmShipDeleteText",
        defaultMessage:
            "This ship will be deleted. It will no longer be available for future bookings, but the data will be safely stored so historical bookings can be viewed. Do you still want to delete it?",
    },
    confirmDockDeleteText: {
        id: "confirmDockDeleteText",
        defaultMessage:
            "This dock will be deleted. It will no longer be available for future bookings, but the data will be safely stored so historical bookings can be viewed. Do you still want to delete it?",
    },
    eni: { id: "eni", defaultMessage: "ENI-number" },
    mmsi: { id: "mmsi", defaultMessage: "MMSI-Number" },
    regPort: { id: "regPort", defaultMessage: "Port of Registration" },
    numberOfPassengerBeds: {
        id: "numberOfPassengerBeds",
        defaultMessage: "Number of passenger beds according to ship certificate",
    },
    passengerBeds: { id: "passengerBeds", defaultMessage: "Passenger beds" },
    length: { id: "length", defaultMessage: "Length" },
    width: { id: "width", defaultMessage: "Width" },
    portalWidth: { id: "portalWidth", defaultMessage: "Portal Width" },
    entranceHeight: {
        id: "entranceHeight",
        defaultMessage: "Height of Entrance",
    },
    entranceHeight2: {
        id: "entranceHeight2",
        defaultMessage: "Height of Enttrance 2",
    },
    onBoardSewage: {
        id: "onBoardSewage",
        defaultMessage: "On-board sewage treatment plant",
    },
    certExpireDate: {
        id: "certExpireDate",
        defaultMessage: "Expiry date of certificate",
    },
    lastInspection: {
        id: "lastInspection",
        defaultMessage: "Last technical inspection",
    },
    inspectionCount: {
        id: "inspectionCount",
        defaultMessage: "Number of technical inspections",
    },
    currentAmps: { id: "currentAmps", defaultMessage: "Current drawn" },
    validFrom: { id: "validFrom", defaultMessage: "Valid from" },
    validTo: { id: "validTo", defaultMessage: "Valid to" },
    telephone: { id: "telephone", defaultMessage: "Telephone" },
    email: { id: "email", defaultMessage: "Email" },
    onBoardEmail: { id: "onBoardEmail", defaultMessage: "On Board Email" },
    shipNameIsRequired: {
        id: "shipNameIsRequired",
        defaultMessage: "Ship name is required",
    },
    eniIsIncorrect: {
        id: "eniIsIncorrect",
        defaultMessage: "ENI must be a 8-digit number",
    },
    mmsiIsIncorrect: {
        id: "mmsiIsIncorrect",
        defaultMessage: "MMSI must be a 9-digit number",
    },
    emailIsRequired: {
        id: "emailIsRequired",
        defaultMessage: "Email address is required",
    },
    emailInvalid: { id: "emailInvalid", defaultMessage: "Email is invalid" },
    invoiceAddressIdIsRequired: {
        id: "invoiceAddressIdIsRequired",
        defaultMessage: "Invoice address is required",
    },

    passengerBedsIncorrect: {
        id: "passengerBedsIncorrect",
        defaultMessage: "Number of passenger beds must be between 10 and 999",
    },
    lengthIsRequired: {
        id: "lengthIsRequired",
        defaultMessage: "Ship length is required",
    },
    validFromIsRequired: {
        id: "validFromIsRequired",
        defaultMessage: "Valid from is required",
    },
    validaToIsRequired: {
        id: "validaToIsRequired",
        defaultMessage: "Valid to is required",
    },
    shipAdded: { id: "shipAdded", defaultMessage: "Ship added successfully" },
    shipAddingError: {
        id: "shipAddingError",
        defaultMessage: "Error adding ship",
    },
    shipEdited: {
        id: "shipEdited",
        defaultMessage: "Ship edited successfully",
    },
    shipEditError: {
        id: "shipEditError",
        defaultMessage: "Error editing ship",
    },
    name: { id: "name", defaultMessage: "Name" },
    area: { id: "area", defaultMessage: "Area" },
    ares: { id: "areas", defaultMessage: "Areas" },
    areaPreferrence: {
        id: "areaPreferrence",
        defaultMessage: "Area preference",
    },
    remark: { id: "remark", defaultMessage: "Remark" },
    addDock: { id: "addDock", defaultMessage: "Add dock" },
    editDestination: {
        id: "editDestination",
        defaultMessage: "Edit destination",
    },
    address: { id: "address", defaultMessage: "Address" },
    community: { id: "community", defaultMessage: "Port community" },
    contactTelAdministration: {
        id: "contactTelAdministration",
        defaultMessage: "Contact Tel. Administration",
    },
    contactMail: { id: "contactMail", defaultMessage: "Contact mail" },
    accountingEmail: { id: "accountingEmail", defaultMessage: "Accounting email" },
    contactOnSite: { id: "contactOnSite", defaultMessage: "Contact on site" },
    saveChanges: { id: "saveChanges", defaultMessage: "Save changes" },
    destinationEdited: {
        id: "destinationEdited",
        defaultMessage: "Destination edited successfully",
    },
    destinationEditError: {
        id: "destinationEditError",
        defaultMessage: "Error editing destination",
    },
    dock: { id: "dock", defaultMessage: "Dock" },
    dockIsRequired: {
        id: "dockIsRequired",
        defaultMessage: "Dock is required",
    },
    docks: { id: "docks", defaultMessage: "Docks" },
    adapterFaecesPowerlock: {
        id: "adapterFaecesPowerlock",
        defaultMessage: "Adapter (Faeces/Powerlock)",
    },
    internalBooking: {
        id: "internalBooking",
        defaultMessage: "Internal booking",
    },
    latitude: { id: "latitude", defaultMessage: "Latitude" },
    longitude: { id: "longitude", defaultMessage: "Longitude" },
    maximalLength: { id: "maximalLength", defaultMessage: "Maximal Length" },
    maximalVessels: {
        id: "maximalVessels",
        defaultMessage: "Maximal number of vessels",
    },
    dockNameIsRequired: {
        id: "dockNameIsRequired",
        defaultMessage: "Dock name is required",
    },
    pubAllocationPlan: {
        id: "pubAllocationPlan",
        defaultMessage: "Publication of dock on dock allocation plan?",
    },
    dockAdd: { id: "dockAdd", defaultMessage: "Add dock" },
    dockEdit: { id: "dockEdit", defaultMessage: "Edit dock" },
    isRequiredTemplate: {
        id: "isRequiredTemplate",
        defaultMessage: "ITEM is required",
    },
    errorBackendActionTemplate: {
        id: "errorBackendActionTemplate",
        defaultMessage: "Error ACTION ITEM",
    },
    errorDeletingDeclinedStop: {
        id: "errorDeletingDeclinedStop",
        defaultMessage: "The stop cannot be deleted when the booking request has been declined",
    },
    successBackendActionTemplate: {
        id: "successBackendActionTemplate",
        defaultMessage: "Success ACTION ITEM",
    },
    fetching: {
        id: "fetching",
        defaultMessage: "fetching",
    },
    creating: {
        id: "creating",
        defaultMessage: "creating",
    },
    updating: {
        id: "updating",
        defaultMessage: "updating",
    },
    deleting: {
        id: "deleting",
        defaultMessage: "deleting",
    },
    adding: {
        id: "adding",
        defaultMessage: "adding",
    },
    downloading: {
        id: "downloading",
        defaultMessage: "downloading",
    },
    dockTimeAvailability: {
        id: "dockTimeAvailability",
        defaultMessage: "Dock time availability",
    },
    monday: { id: "monday", defaultMessage: "Monday" },
    tuesday: { id: "tuesday", defaultMessage: "Tuesday" },
    wednesday: { id: "wednesday", defaultMessage: "Wednesday" },
    thursday: { id: "thursday", defaultMessage: "Thursday" },
    friday: { id: "friday", defaultMessage: "Friday" },
    saturday: { id: "saturday", defaultMessage: "Saturday" },
    sunday: { id: "sunday", defaultMessage: "Sunday" },
    submit: { id: "submit", defaultMessage: "Submit" },
    powerlock: { id: "powerlock", defaultMessage: "Powerlock" },
    electricitySupply: {
        id: "electricitySupply",
        defaultMessage: "Electricity supply",
    },
    drinkingWaterAutomatic: {
        id: "drinkingWaterAutomatic",
        defaultMessage: "Drinking water supply via water meter",
    },
    drinkingWaterStandpipe: {
        id: "drinkingWaterStandpipe",
        defaultMessage: "Drinking water supply via standpipe",
    },
    drinkingWaterWaterMeter: {
        id: "drinkingWaterWaterMeter",
        defaultMessage: "Drinking water supply automatically",
    },
    wasteDisposal: { id: "wasteDisposal", defaultMessage: "Waste disposal" },
    faecesDisposal: { id: "faecesDisposal", defaultMessage: "Faeces disposal" },
    existingInfrastructure: {
        id: "existingInfrastructure",
        defaultMessage: "Existing infrastructure",
    },
    waterMeter: { id: "waterMeter", defaultMessage: "Standpipe/water meter" },
    improperDisposal: {
        id: "improperDisposal",
        defaultMessage: "Improper disposal",
    },
    adapteFaecesPowerlock: {
        id: "adapteFaecesPowerlock",
        defaultMessage: "Adapter (faeces/powerlock)",
    },
    extensionCable: { id: "extensionCable", defaultMessage: "Extension cable" },
    otherExpenditure: {
        id: "otherExpenditure",
        defaultMessage: "Other expenditure",
    },
    availableServices: {
        id: "availableServices",
        defaultMessage: "Available services by port staff (On-Call Service)",
    },
    areaIsRequired: {
        id: "areaIsRequired",
        defaultMessage: "Area is required",
    },
    numberIncorrect: {
        id: "numberIncorrect",
        defaultMessage: "Must be greater than 0",
    },
    vessel: {
        id: "vessel",
        defaultMessage: "Vessel",
    },
    arrivalTime: {
        id: "arrivalTime",
        defaultMessage: "Arrival time",
    },
    arrivalTimeIsRequired: {
        id: "arrivalTimeIsRequired",
        defaultMessage: "Arrival time is required",
    },
    departureTime: {
        id: "departureTime",
        defaultMessage: "Departure time",
    },
    departureTimeIsRequired: {
        id: "departureTimeIsRequired",
        defaultMessage: "Departure time is required",
    },
    status: {
        id: "status",
        defaultMessage: "Status",
    },
    startDate: {
        id: "startDate",
        defaultMessage: "Start date",
    },
    endDate: {
        id: "endDate",
        defaultMessage: "End date",
    },
    addVoyage: {
        id: "addVoyage",
        defaultMessage: "Add voyage",
    },
    editVoyage: {
        id: "editVoyage",
        defaultMessage: "Edit voyage",
    },
    addDockTimeAvailability: {
        id: "addDockTimeAvailability",
        defaultMessage: "Add dock time availabile",
    },
    add: { id: "add", defaultMessage: "Add" },
    voyage: { id: "voyage", defaultMessage: "Voyage" },
    voyages: { id: "voyages", defaultMessage: "Voyages" },
    voyageNo: { id: "voyageNo", defaultMessage: "Voyage No." },
    ship: { id: "ship", defaultMessage: "Ship" },
    ships: { id: "ships", defaultMessage: "Ships" },
    destination: { id: "destination", defaultMessage: "Destination" },
    destinationIsRequired: {
        id: "destinationIsRequired",
        defaultMessage: "Destination is required",
    },
    requested: { id: "requested", defaultMessage: "Requested" },
    changeRequested: {
        id: "changeRequested",
        defaultMessage: "Change requested",
    },
    pending: { id: "pending", defaultMessage: "Pending" },
    provisionallyPlanned: {
        id: "provisionallyPlanned",
        defaultMessage: "Provisionally planned",
    },
    provisionallyDeclined: {
        id: "provisionallyDeclined",
        defaultMessage: "Provisionally declined",
    },
    confirmed: { id: "confirmed", defaultMessage: "Confirmed" },
    canceled: { id: "canceled", defaultMessage: "Canceled" },
    unavailable: { id: "unavailable", defaultMessage: "Unavailable" },
    destinations: { id: "destinations", defaultMessage: "Destinations" },
    planVoyage: { id: "planVoyage", defaultMessage: "Plan voyage" },
    stop: { id: "stop", defaultMessage: "Stop" },
    stops: { id: "stops", defaultMessage: "Stops" },
    addStop: { id: "addStop", defaultMessage: "Add Stop" },
    updateStop: { id: "updateStop", defaultMessage: "Update Stop" },
    personsOnBoard: {
        id: "personsOnBoard",
        defaultMessage: "Number of persons on board",
    },
    crewOnBoard: {
        id: "crewOnBoard",
        defaultMessage: "Number of crew members on board",
    },
    occupiedBeds: {
        id: "occupiedBeds",
        defaultMessage: "Number of occupied beds",
    },
    sendBookingRequest: {
        id: "sendBookingRequest",
        defaultMessage: "Send booking request",
    },
    sendBookingResponse: {
        id: "sendBookingResponse",
        defaultMessage: "Send booking response",
    },
    sendBookingResponses: {
        id: "sendBookingResponses",
        defaultMessage: "Send booking responses",
    },
    cancelBookingRequest: {
        id: "cancelBookingRequest",
        defaultMessage: "Cancel booking request",
    },
    voyageStartTime: { id: "start", defaultMessage: "Start" },
    voyageEndTime: { id: "end", defaultMessage: "End" },
    bookingStatus: { id: "bookingStatus", defaultMessage: "Booking status" },
    voyageStatus: { id: "voyageStatus", defaultMessage: "Voyage status" },
    statusOfBookings: {
        id: "statusOfBookings",
        defaultMessage: "Status of bookings in voyage",
    },
    apply: { id: "apply", defaultMessage: "Apply" },
    clear: { id: "clear", defaultMessage: "Clear" },
    from: { id: "from", defaultMessage: "From" },
    to: { id: "to", defaultMessage: "To" },
    booking: { id: "booking", defaultMessage: "Booking" },
    blocking: { id: "blocking", defaultMessage: "Blocking" },
    bookings: { id: "bookings", defaultMessage: "Bookings" },
    bookingNumber: { id: "bookingNumber", defaultMessage: "Booking number" },
    viewShip: { id: "viewShip", defaultMessage: "View ship" },
    viewShipData: { id: "viewShipData", defaultMessage: "View ship data" },
    updateShipData: {
        id: "updateShipData",
        defaultMessage: "Update ship data",
    },
    message: { id: "message", defaultMessage: "message" },
    messages: { id: "messages", defaultMessage: "messages" },
    request_booking: {
        id: "request_booking",
        defaultMessage: "Booking requested",
    },
    request_cancel_booking: {
        id: "request_cancel_booking",
        defaultMessage: "Booking request canceled",
    },
    cancelBooking: {
        id: "cancelBooking",
        defaultMessage: "Cancel Booking",
    },
    confirmCancelStop: {
        id: "confirmCancelStop",
        defaultMessage: "Are You sure You want to cancel the booking?",
    },
    bookingId: { id: "bookingId", defaultMessage: "Booking ID" },
    editDockAccess: {
        id: "editDockAccess",
        defaultMessage: "Edit dock access",
    },
    manageDocuments: {
        id: "manageDocuments",
        defaultMessage: "Manage documents",
    },
    keyNumber: { id: "keyNumber", defaultMessage: "Key number" },
    electricityCardNumber: {
        id: "electricityCardNumber",
        defaultMessage: "Electricity card number",
    },
    keyDeliveryDate: {
        id: "keyDeliveryDate",
        defaultMessage: "Key delivery date",
    },
    dateOfIssueCurrentPlugInCard: {
        id: "dateOfIssueCurrentPlugInCard",
        defaultMessage: "Date of issue Current plug-in card",
    },
    updateBookingsQuestion: {
        id: "updateBookingsQuestion",
        defaultMessage: "Are you sure you want to update selected bookings?",
    },
    internalDeleteConfirmation: {
        id: "internalDeleteConfirmation",
        defaultMessage: "Are you sure you want to delete selected internal",
    },
    voyageDiscardConfirmation: {
        id: "voyageDiscardConfirmation",
        defaultMessage: "Are you sure you want to discard this voyage?",
    },
    remarksFgksDispatcher: {
        id: "remarksFgksDispatcher",
        defaultMessage: "Remarks FGKS Dispatcher",
    },
    fgksDisptacher: {
        id: "fgksDisptacher",
        defaultMessage: "FGKS Dispatcher",
    },
    fgksLocksAndDocks: {
        id: "fgksLocksAndDocks",
        defaultMessage: "FGKS Locks & Docks",
    },
    remarksTechnicalInformation: {
        id: "remarksTechnicalInformation",
        defaultMessage: "Remarks technical information",
    },
    portRelatedCustomerNumber: {
        id: "portRelatedCustomerNumber",
        defaultMessage: "(port-related) customer number",
    },
    complete: { id: "complete", defaultMessage: "Complete" },
    numberIncorrect3Digit: {
        id: "numberIncorrect3Digit",
        defaultMessage: "Must be a 3 digit number",
    },
    numberIncorrect6Digit: {
        id: "numberIncorrect6Digit",
        defaultMessage: "Must be a 6 digit number",
    },

    managingCompany: {
        id: "managingCompany",
        defaultMessage: "Managing company",
    },
    requestTime: { id: "requestTime", defaultMessage: "Request time" },
    bookingConfirmationTime: {
        id: "bookingConfirmationTime",
        defaultMessage: "Booking confirmation time",
    },
    assignedDock: { id: "assignedDock", defaultMessage: "Assigned dock" },
    comment: { id: "comment", defaultMessage: "Comment" },
    reason: { id: "reason", defaultMessage: "Reason" },
    completeness: { id: "completeness", defaultMessage: "Completeness" },
    respondAction: { id: "respondAction", defaultMessage: "Respond action" },
    confirm: { id: "confirm", defaultMessage: "Confirm" },
    decline: { id: "decline", defaultMessage: "Decline" },
    documents: { id: "documents", defaultMessage: "Documents" },
    document: { id: "document", defaultMessage: "Document" },
    size: { id: "size", defaultMessage: "Size" },
    type: { id: "type", defaultMessage: "Type" },
    lastModifyDate: {
        id: "lastModifiedDate",
        defaultMessage: "Last modified date",
    },
    download: { id: "download", defaultMessage: "Download" },
    downloadCertificate: {
        id: "downloadCertificate",
        defaultMessage: "Download certificate",
    },
    upload: { id: "upload", defaultMessage: "Upload" },
    shipDocumentsUnavailable: {
        id: "shipDocumentsUnavailable",
        defaultMessage: "Selected ship has no available documents.",
    },
    uploadDocuments: {
        id: "uploadDocuments",
        defaultMessage: "Upload documents",
    },
    dropFileHere: {
        id: "dropFileHere",
        defaultMessage: "Drop here or click to select file",
    },
    confirmDocumentDeleteText: {
        id: "confirmDocumentDeleteText",
        defaultMessage:
            "This document will be deleted. It will no longer be available. Do you still want to delete it?",
    },
    bookingsUnavailable: {
        id: "bookingsUnavailable",
        defaultMessage: "Bookings unavailable",
    },
    respond: { id: "respond", defaultMessage: "Respond" },
    respondToBookigRequest: {
        id: "respondToBookigRequest",
        defaultMessage: "Respond to booking request",
    },
    change: { id: "change", defaultMessage: "Change" },
    plausability: { id: "plausability", defaultMessage: "Plausability" },
    createSecondBerthing: {
        id: "createSecondBerthing",
        defaultMessage: "Create second berthing",
    },
    createAdditionalBerthing: {
        id: "createAdditionalBerthing",
        defaultMessage: "Create additional berthing",
    },
    confirmBooking: {
        id: "confirmBooking",
        defaultMessage:
            "Are you sure you want to confirm this booking, even though the plausibility check has failed with the following errors:",
    },
    select: { id: "select", defaultMessage: "Select" },
    bookingResponses: {
        id: "bookingResponses",
        defaultMessage: "Booking responses",
    },
    arrivalTimeMustBeBeforeDepartureTime: {
        id: "arrivalTimeMustBeBeforeDepartureTime",
        defaultMessage: "Arrival time must be before departure time",
    },
    showInPlan: { id: "showInPlan", defaultMessage: "Show in plan" },
    selectAll: { id: "selectAll", defaultMessage: "Select all" },
    deselectAll: { id: "deselectAll", defaultMessage: "Deselect all" },
    noFileSelected: {
        id: "noFileSelected",
        defaultMessage: "No file is selected",
    },
    fileToBig: { id: "fileToLarger", defaultMessage: "File size is to big" },
    invalidFileType: {
        id: "invalidFileType",
        defaultMessage: "Invalid file type",
    },
    dockAllocationPlan: {
        id: "dockAllocationPlan",
        defaultMessage: "Dock allocation plan",
    },
    uploadCertificate: {
        id: "uploadCertificate",
        defaultMessage: "Upload certificate",
    },
    previewCertificate: {
        id: "previewCertificate",
        defaultMessage: "Preview certificate",
    },
    previewDocument: {
        id: "previewDocument",
        defaultMessage: "Preview document",
    },
    preview: { id: "preview", defaultMessage: "Preview" },
    fileFormatNotSupported: {
        id: "fileFormatNotSupported",
        defaultMessage: "File format not supported",
    },
    data: { id: "data", defaultMessage: "Data" },
    changed: { id: "changed", defaultMessage: "Changed" },
    setUnsetPending: {
        id: "setUnsetPending",
        defaultMessage: "Set/Unset pending",
    },
    bookingConfirmed: {
        id: "bookingConfirmed",
        defaultMessage: "Booking confirmed",
    },
    bookingDeclined: {
        id: "bookingDeclined",
        defaultMessage: "Booking declined",
    },
    changeBooking: { id: "changeBooking", defaultMessage: "Change requested" },
    bookingCanceled: {
        id: "bookingCanceled",
        defaultMessage: "Booking canceled",
    },
    bookingChanged: { id: "bookingChanged", defaultMessage: "Booking changed" },
    bookingRequested: {
        id: "bookingRequested",
        defaultMessage: "Booking requested",
    },
    editCompanny: { id: "editCompanny", defaultMessage: "Edit Companny" },
    berthing: { id: "berthing", defaultMessage: "Berthing" },
    berthings: { id: "berthings", defaultMessage: "Berthings" },
    manageTeam: { id: "manageTeam", defaultMessage: "Manage Team" },
    editBerthing: { id: "editBerthing", defaultMessage: "Edit berthing" },
    addBerthing: { id: "addBerthing", defaultMessage: "Add berthing" },
    changeBerthing: { id: "changeBerthing", defaultMessage: "Change berthing" },
    deleteBerthing: { id: "deleteBerthing", defaultMessage: "Delete berthing" },
    deleteBerthingText: {
        id: "deleteBerthingText",
        defaultMessage:
            "Are you sure you want to delete selected berthing? This action cannot be undone.",
    },
    selectedTimeMustBeInTheFuture: {
        id: "selectedTimeMustBeInTheFuture",
        defaultMessage: "Selected time must be in the future",
    },
    fillOutMissingFields: {
        id: "fillOutMissingFields",
        defaultMessage: "Please fill out the missing fields",
    },
    berthingsMustBeContinuous: {
        id: "berthingsMustBeContinuous",
        defaultMessage:
            "The berthing periods need to be continuous. Please make sure the start time of the later berthing is equal to the end time of the previous berthing",
    },
    berthignsCannotOverlap: {
        id: "berthignsCannotOverlap",
        defaultMessage: "Berthing periods cannot overlap.",
    },
    multiple: { id: "multiple", defaultMessage: "Multiple" },
    certExpireDateIsRequired: {
        id: "certExpireDateIsRequired",
        defaultMessage: "Certificate expire date is required",
    },
    certificateNotUploaded: {
        id: "certificateNotUploaded",
        defaultMessage: "Certificate is not uploaded",
    },
    manageAddresses: {
        id: "manageAddresses",
        defaultMessage: "Manage addresses",
    },
    addresses: { id: "addresses", defaultMessage: "Addresses" },
    addAddress: { id: "addAddress", defaultMessage: "Add address" },
    contactName: { id: "contactName", defaultMessage: "Contact name" },
    addressType: { id: "addressType", defaultMessage: "Address type" },
    charterCompany: { id: "charterCompany", defaultMessage: "Charter company" },
    owningCompany: { id: "owningCompany", defaultMessage: "Owning company" },
    owningCompanies: {
        id: "owningCompanies",
        defaultMessage: "Owning companies",
    },
    owningCompanyIsRequired: {
        id: "owningCompanyIsRequired",
        defaultMessage: "Owning company is required",
    },
    addressTypeIsRequired: {
        id: "addressTypeIsRequired",
        defaultMessage: "Address type is required",
    },
    saveInvoiceAddressmessage: {
        id: "saveInvoiceAddressMessage",
        defaultMessage:
            "Please check that all the data entered is complete and correct. Invoice addresses cannot be modified after they’re created, they can only be deleted.",
    },
    saveInvoiceAddressTitle: {
        id: "saveInvoiceAddressTitle",
        defaultMessage: "Save Invoice address",
    },
    searchBy: { id: "searchBy", defaultMessage: "Search by " },
    fields: { id: "fields", defaultMessage: "Fields" },
    confirmAddressDelete: {
        id: "confirmAddressDelete",
        defaultMessage:
            "This address will be deleted. It will no longer be available for future booking requests. Do you still want to delete it?",
    },
    accountsReceivableNumber: {
        id: "accountsReceivableNumber",
        defaultMessage: "Accounts receivable number",
    },
    vatNumber: { id: "vatNumber", defaultMessage: "VAT Number" },
    accountingInformation: {
        id: "accountingInformation",
        defaultMessage: "Accounting information",
    },
    additionalInformation: {
        id: "additionalInformation",
        defaultMessage: "Additional information",
    },
    deleted: { id: "deleted", defaultMessage: "Deleted" },
    editAdditionalInformation: {
        id: "editAdditionalInformation",
        defaultMessage: "Edit additional information",
    },
    internal: { id: "internal", defaultMessage: "Internal" },
    external: { id: "external", defaultMessage: "External" },
    invoiceAddressSource: {
        id: "invoiceAddressSource",
        defaultMessage: "Invoice address source",
    },
    provisionallyPlan: {
        id: "provisionallyPlan",
        defaultMessage: "Prov. plan",
    },
    provisionallyDecline: {
        id: "provisionallyDecline",
        defaultMessage: "Prov. decline",
    },
    wrongDateFormat: {
        id: "wrongDateFormat",
        defaultMessage: "Wrong date format, cannot save changes",
    },
    requestedOnLastChange: {
        id: "requestedOnLastChange",
        defaultMessage: "Requested on / Last change",
    },
    electricityCardDeliveryDate: {
        id: "electricityCardDeliveryDate",
        defaultMessage: "Electricity card delivery date",
    },
    details: { id: "details", defaultMessage: "Details" },
    viewDockAccess: {
        id: "viewDockAccess",
        defaultMessage: "View dock access",
    },
    shipCertificate: {
        id: "shipCertificate",
        defaultMessage: "Ship certificate",
    },
    dockManagement: { id: "dockManagement", defaultMessage: "Dock management" },
    support: { id: "support", defaultMessage: "Support" },
    manageUserAccount: {
        id: "manageUserAccount",
        defaultMessage: "Manage user account",
    },
    logout: { id: "logout", defaultMessage: "Logout" },
    blockedBy: { id: "blockedBy", defaultMessage: "Blocked by" },
    notificationSettings: {
        id: "notificationSettings",
        defaultMessage: "Notification settings",
    },
    recieveSMS: { id: "recieveSMS", defaultMessage: "Recieve SMS" },
    recieveEmail: { id: "recieveEmail", defaultMessage: "Recieve E-mail" },
    newBookingRequest: {
        id: "newBookingRequest",
        defaultMessage: "New booking request",
    },
    chatMessage: { id: "chatMessage", defaultMessage: "Chat message" },
    newShipAdded: { id: "newShipAdded", defaultMessage: "New ship added" },
    bookingShipDuplicateEni: {
        id: "bookingShipDuplicateEni",
        defaultMessage: "Duplicate ENI number",
    },
    updatedShipData: {
        id: "updatedShipData",
        defaultMessage: "Updated ship data",
    },
    shipCertExpired: {
        id: "shpCertExpired",
        defaultMessage: "Ship certificate expired",
    },
    addressInUseChanged: {
        id: "addressInUseChanged",
        defaultMessage: "Address in use changed",
    },
    user: { id: "user", defaultMessage: "User" },
    bookingReqConfirmed: {
        id: "bookingReqConfirmed",
        defaultMessage: "Booking request confirmed",
    },
    bookingReqChanged: {
        id: "bookingReqChanged",
        defaultMessage: "Booking request changed",
    },
    bookingReqDeclined: {
        id: "bookingReqDeclined",
        defaultMessage: "Booking request declined",
    },
    bookingPreeCheck: {
        id: "bookingPreecheck",
        defaultMessage: "Upcoming booking reminder",
    },
    bookingChangeRequested: {
        id: "bookingChangeRequested",
        defaultMessage: "Booking change requested",
    },
    sms: { id: "sms", defaultMessage: "SMS" },
    notifications: { id: "notifications", defaultMessage: "Notifications" },
    onlyShowUnread: {
        id: "onlyShowUnread",
        defaultMessage: "Only show unread",
    },
    markAsRead: { id: "markAsRead", defaultMessage: "Mark as read" },
    markAsUnread: { id: "markAsUnread", defaultMessage: "Mark as unread" },
    markAllAsRead: { id: "markAllAsRead", defaultMessage: "Mark all as read" },
    openInNewTab: { id: "openInNewTab", defaultMessage: "Open in a new tab" },
    notificationBookingShipNew: {
        id: "notificationBookingShipNew",
        defaultMessage: "A booking request was sent with a newe ship",
    },
    notificationShipDataUpdated: {
        id: "notificationShipDataUpdated",
        defaultMessage: "The ship data was updated by Locks & Docks",
    },
    notificationShipCertExpired: {
        id: "notificationShipCertExpired",
        defaultMessage:
            "The ship certificate for ship has expired. Locks & Docks from managing company has recieved a notification to update the ship certificate.",
    },
    notificationAddressInUseChanged: {
        id: "notificationAddressInUseChanged",
        defaultMessage: "The following invoice addres has been updated by Locks & Docks",
    },
    shipValidToError: {
        id: "shipValidToError",
        defaultMessage: "Valid to date has to be after valid from date",
    },
    currency: { id: "currency", defaultMessage: "Currency" },
    currencyIsRequired: {
        id: "currencyIsRequired",
        defaultMessage: "Currency is required",
    },
    portCommunities: {
        id: "portCommunities",
        defaultMessage: "Port communities",
    },
    notificationBookingRequested: {
        id: "notificationBookingRequested",
        defaultMessage: "The following booking has been requested",
    },
    notificationBookingChanged: {
        id: "notificationBookingChanged",
        defaultMessage: "The following booking has been modified",
    },
    notificationBookingCanceled: {
        id: "notificationBookingCanceled",
        defaultMessage: "The following booking has been canceled",
    },
    notificationNewChatMessage: {
        id: "notificationNewChatMessage",
        defaultMessage: "New chat message for the following booking request",
    },
    notificationDuplicateEniNumber: {
        id: "notifcationDuplicateEniNumber",
        defaultMessage: "Duplicate ENI number - A booking request was sent with a new ship",
    },
    notificationThisEniIsUsed: {
        id: "notificationThisEniIsUsed",
        defaultMessage: "This ENI number is already used for another ship",
    },
    notificationShipCertificateExpired1: {
        id: "notificationShipCertificateExpired1",
        defaultMessage: "The ship certificate for ship",
    },
    notificationShipCertificateExpired2: {
        id: "notificationShipCertificateExpired2",
        defaultMessage: "has Expired. Locks&Docks from",
    },
    notificationShipCertificateExpired3: {
        id: "notificationShipCertificateExpired3",
        defaultMessage: "has received a notification to update the ship certificate",
    },
    notificationChanged: {
        id: "notificationChanged",
        defaultMessage: "has been changed",
    },
    notificationConfirmed: {
        id: "notificationConfirmed",
        defaultMessage: "has been confirmed",
    },
    notificationDeclined: {
        id: "notificationDeclined",
        defaultMessage: "has been declined",
    },
    newChatMessageForBooking: {
        id: "newChatMessageForBooking",
        defaultMessage: "New chat message for booking",
    },
    notificationShipCertExpired1: {
        id: "notificationShipCertExpired1",
        defaultMessage: "The ship certificate for ship",
    },
    notificationShipCertExpired2: {
        id: "notificationShipCertExpired2",
        defaultMessage: "has expired, please upload the current or new ship certificate",
    },
    notificationBookingPrecheck1: {
        id: "notificationBookingPrecheck1",
        defaultMessage: "There is a booking for ship",
    },
    notificationBookingPrecheck2: {
        id: "notificationBookingPrecheck2",
        defaultMessage:
            "starting in four weeks, please check the ship ENI and MMSI numbers. These fields are mandatory and need to be up to date.",
    },
    changeLog: { id: "changeLog", defaultMessage: "Change Log" },
    entityType: { id: "entityType", defaultMessage: "Entity type" },
    actionType: { id: "actionType", defaultMessage: "Action type" },
    dateAndTimeOfAction: {
        id: "dateAndTimeOfAction",
        defaultMessage: "Date and time of action",
    },
    viewChange: { id: "viewChange", defaultMessage: "View change" },
    create: { id: "create", defaultMessage: "Create" },
    users: { id: "users", defaultMessage: "Users" },
    inputValidData: {
        id: "inputValidData",
        defaultMessage: "Please, input valid data.",
    },
    berthingDepartureMinError: {
        id: "berthingDepartureMinError",
        defaultMessage: "Departure time may not be before {minDeparture}!",
    },
    berthingDepartureMaxError: {
        id: "berthingDepartureMaxError",
        defaultMessage: "Departure time may not be after {maxDeparture}!",
    },
    berthingArrivalMinError: {
        id: "berthingArrivalMinError",
        defaultMessage: "Arrival time may not be before {minArrival}!",
    },
    berthingArrivalMaxError: {
        id: "berthingArrivalMaxError",
        defaultMessage: "Arrival time may not be after {maxArrival}!",
    },
    berthingDockIdError: {
        id: "berthingDockIdError",
        defaultMessage:
            "This berthing cannot have the same dock as the previous nor next berthing!",
    },
    dataInvalid: {
        id: "dataInvalid",
        defaultMessage: "Please correct the invalid input data",
    },
    guiConf: {
        id: "guiConf",
        defaultMessage: "User settings",
    },
    manageExpenseTypes: {
        id: "manageExpenseTypes",
        defaultMessage: "Manage expense types",
    },
    addExpenseType: {
        id: "addExpenseType",
        defaultMessage: "Add expense type",
    },
    nameOfExpense: { id: "nameOfExpense", defaultMessage: "Name of expense" },
    category: { id: "category", defaultMessage: "Category" },
    SAPNumber: { id: "SAPNumber", defaultMessage: "SAP number" },
    unit: { id: "unit", defaultMessage: "Unit" },
    netPricePerUnit: {
        id: "netPricePerUnit",
        defaultMessage: "Net price per unit",
    },
    expenseType: { id: "expenseType", defaultMessage: "Expense type" },
    expenseTypes: { id: "expenseTypes", defaultMessage: "Expense types" },
    expensePeriods: { id: "expensePeriods", defaultMessage: "Expense periods" },
    nameOfExpenseIsRequired: {
        id: "nameOfExpenseIsRequired",
        defaultMessage: "Name of expense is required",
    },
    categoryIsRequired: {
        id: "categoryIsRequired",
        defaultMessage: "Category is required",
    },
    SAPIsRequired: {
        id: "SAPIsRequired",
        defaultMessage: "SAP number is required",
    },
    unitIsRequired: {
        id: "unitIsRequired",
        defaultMessage: "Unit is required",
    },
    netPricePerUnitIsRequired: {
        id: "netPricePerUnitIsRequired",
        defaultMessage: "Net price per unit is required",
    },
    vatIsrequired: {
        id: "vatIsRequired",
        defaultMessage: "VAT number is required",
    },
    validToMustBeAfterValidFrom: {
        id: "validToMustBeAfterValidFrom",
        defaultMessage: "Valid to date must be after valid from date",
    },
    unknown: { id: "unknown", defaultMessage: "Unknown" },
    addExpensePeriod: {
        id: "addExpensePeriod",
        defaultMessage: "Add expense period",
    },
    archiveExpenseType: {
        id: "archiveExpenseType",
        defaultMessage: "Archive expense type",
    },
    validFromDateInvalid: {
        id: "validFromDateInvalid",
        defaultMessage: "Valid from date can not be before previous period valid from dates",
    },
    saveExpensePeriod: {
        id: "saveExpensePeriod",
        defaultMessage: "Save expense period",
    },
    saveExpensePeriodMessage: {
        id: "saveExpensePeriodMessage",
        defaultMessage:
            "Validity of the previous Expense period entry for this Expense type will be modified to be valid until a day prior to the new valid from date",
    },
    archiveExpenseTypeMessage: {
        id: "archiveExpenseTypeMessage",
        defaultMessage:
            "Are you sure you want to archive this expense type? If you proceed this expense type will not be usable anymore.",
    },
    dockId: {
        id: "dockId",
        defaultMessage: "Dock name",
    },
    owningCompanyAddressId: {
        id: "owningCompanyAddressId",
        defaultMessage: "Owning company",
    },
    tel1: {
        id: "tel1",
        defaultMessage: "Telephone 1",
    },
    tel: {
        id: "tel",
        defaultMessage: "Telephone",
    },
    tel2: {
        id: "tel2",
        defaultMessage: "Telephone 2",
    },
    areaPreference: {
        id: "areaPreference",
        defaultMessage: "Preference area",
    },
    bookingDate: {
        id: "bookingDate",
        defaultMessage: "Booking date",
    },
    confirmTime: {
        id: "confirmTime",
        defaultMessage: "Confirm time",
    },
    requestDate: {
        id: "requestDate",
        defaultMessage: "Request date",
    },
    company: {
        id: "company",
        defaultMessage: "Company name",
    },
    accountRecNumber: {
        id: "accountRecNumber",
        defaultMessage: "Account recovery number",
    },
    accountingInfo: {
        id: "accountingInfo",
        defaultMessage: "Accounting information",
    },
    archiving: { id: "archiving", defaultMessage: "Archiving" },
    copyAllocationPlanLink: {
        id: "copyAllocationPlanLink",
        defaultMessage: "Copy allocation plan link",
    },
    copyAllocationPlanPlusLink: {
        id: "copyAllocationPlanPlusLink",
        defaultMessage: "Copy allocation plan PLUS link",
    },
    copyAllocationPlanLinkSuccess: {
        id: "copyAllocationPlanLinkSuccess",
        defaultMessage: "Link copied!",
    },
    hour: {
        id: "hour",
        defaultMessage: "hour",
    },
    hours: {
        id: "hours",
        defaultMessage: "hours",
    },
    resolution: {
        id: "resolution",
        defaultMessage: "Resolution",
    },
    noData: {
        id: "noData",
        defaultMessage: "No data available",
    },
    timeZone: {
        id: "timeZone",
        defaultMessage: "Time zone",
    },
    timeZoneIsRequired: {
        id: "timeZoneIsRequired",
        defaultMessage: "Time zone is required",
    },
    declined: {
        id: "declined",
        defaultMessage: "Declined",
    },
    unallocated: {
        id: "unallocated",
        defaultMessage: "Unallocated",
    },
    plausabilityLengthOkError: {
        id: "plausabilityLengthOkError",
        defaultMessage:
            "The ship's length {shipLength} m exceeds the maximum allowed size for the selected dock {dockLength} m",
    },
    plausabilityArrivingInsideWorkingHoursError: {
        id: "plausabilityArrivingInsideWorkingHoursError",
        defaultMessage:
            "The ship is arriving outside of working hours for the dock. Consider adding a second berthing.",
    },
    plausabilityAvailableInThePeriodError: {
        id: "plausabilityAvailableInThePeriodError",
        defaultMessage: "The selected dock is already occupied in the requested booking period.",
    },
    fgks: {
        id: "fgks",
        defaultMessage: "FGKS",
    },
    BOOKING_STATUS_CANCELLED: {
        id: "BOOKING_STATUS_CANCELLED",
        defaultMessage: "Cancelled",
    },
    BOOKING_STATUS_CHANGE_REQUESTED: {
        id: "BOOKING_STATUS_CHANGE_REQUESTED",
        defaultMessage: "Change requested",
    },
    BOOKING_STATUS_CHANGED: {
        id: "BOOKING_STATUS_CHANGED",
        defaultMessage: "Changed",
    },
    BOOKING_STATUS_CONFIRMED: {
        id: "BOOKING_STATUS_CONFIRMED",
        defaultMessage: "Confirmed",
    },
    BOOKING_STATUS_DECLINED: {
        id: "BOOKING_STATUS_DECLINED",
        defaultMessage: "Declined",
    },
    BOOKING_STATUS_PENDING: {
        id: "BOOKING_STATUS_PENDING",
        defaultMessage: "Pending",
    },
    BOOKING_STATUS_PROVISIONALLY_DECLINED: {
        id: "BOOKING_STATUS_PROVISIONALLY_DECLINED",
        defaultMessage: "Provisionally declined",
    },
    BOOKING_STATUS_PROVISIONALLY_PLANNED: {
        id: "BOOKING_STATUS_PROVISIONALLY_PLANNED",
        defaultMessage: "Provisionally planned",
    },
    BOOKING_STATUS_REQUESTED: {
        id: "BOOKING_STATUS_REQUESTED",
        defaultMessage: "Requested",
    },
    BOOKING_STATUS_UNKNOWN: {
        id: "BOOKING_STATUS_UNKNOWN",
        defaultMessage: "Unknown",
    },
    VOYAGE_STATUS_UNAVAILABLE: {
        id: "VOYAGE_STATUS_UNAVAILABLE",
        defaultMessage: "Unavailable",
    },
    VOYAGE_STATUS_PENDING: {
        id: "VOYAGE_STATUS_PENDING",
        defaultMessage: "Pending",
    },
    VOYAGE_STATUS_CONFIRMED: {
        id: "VOYAGE_STATUS_CONFIRMED",
        defaultMessage: "Confirmed",
    },
    VOYAGE_STATUS_CHANGED: {
        id: "VOYAGE_STATUS_CHANGED",
        defaultMessage: "Changed",
    },
    VOYAGE_STATUS_DECLINED: {
        id: "VOYAGE_STATUS_DECLINED",
        defaultMessage: "Declined",
    },
    ordnum: {
        id: "ordnum",
        defaultMessage: "Order number",
    },
    jumpToSpecificDate: {
        id: "jumpToSpecificDate",
        defaultMessage: "Jump to specific date",
    },
    editComment: {
        id: "editComment",
        defaultMessage: "Edit comment",
    },
    expense: { id: "expense", defaultMessage: "Expense" },
    expenses: { id: "expenses", defaultMessage: "Expenses" },
    overviewOfExpenses: {
        id: "overviewOfExpenses",
        defaultMessage: "Overview of expenses",
    },
    createExpense: { id: "createExpense", defaultMessage: "Create expense" },
    editExpense: { id: "editExpense", defaultMessage: "Edit expense" },
    newExpense: { id: "newExpense", defaultMessage: "New expense" },
    linkToInvoice: { id: "linkToInvoice", defaultMessage: "Link to invoice" },
    purchaseRemarks: {
        id: "purchaseRemarks",
        defaultMessage: "Purchase remarks",
    },
    expensDate: { id: "expensDate", defaultMessage: "Expense date" },
    quantity: { id: "quantity", defaultMessage: "Quantity" },
    manageExpenses: { id: "manageExpenses", defaultMessage: "Manage expenses" },
    expenseDate: { id: "expenseDate", defaultMessage: "Expense date" },
    customExpenseType: {
        id: "customExpenseType",
        defaultMessage: "Custom expense type",
    },
    expenseTypeIsRequired: {
        id: "expenseTypeIsRequired",
        defaultMessage: "Expense type is required",
    },
    quantityIsRequired: {
        id: "quantityIsRequired",
        defaultMessage: "Quantity is required",
    },
    expenseDateIsRequired: {
        id: "expenseDateIsRequired",
        defaultMessage: "Expense date is required",
    },
    totalCostNet: { id: "totalCostNet", defaultMessage: "Total cost net" },
    totalCostGross: { id: "totalCostGross", defaultMessage: "Total cost gross" },
    invoiceNumber: { id: "invoiceNumber", defaultMessage: "Invoice number" },
    berthingFees: { id: "berthingFees", defaultMessage: "Berthing fees" },
    electricity: { id: "electricity", defaultMessage: "Electricity" },
    water: { id: "water", defaultMessage: "Water" },
    recycling: { id: "recycling", defaultMessage: "Recycling" },
    services: { id: "services", defaultMessage: "Services" },
    administration: { id: "administration", defaultMessage: "Administration" },

    actualArrivalTime: {
        id: "actualArrivalTime",
        defaultMessage: "Actual arrival time",
    },
    updateActualTimes: {
        id: "updateActualTimes",
        defaultMessage: "Update actual times",
    },
    actualDepartureTime: {
        id: "actualDepartureTime",
        defaultMessage: "Actual departure time",
    },
    manageInvoices: {
        id: "managedInvoices",
        defaultMessage: "Manage invoices",
    },
    addInvoice: { id: "addInvoice", defaultMessage: "Add invoice" },
    linkToBooking: { id: "linkToBooking", defaultMessage: "Link to booking" },
    invoiceReference: {
        id: "invoiceReference",
        defaultMessage: "Invoice reference",
    },
    newAddress: { id: "newAddress", defaultMessage: "New address" },
    bookingDoesntExist: {
        id: "bookingDoesntExist",
        defaultMessage: "Booking doesn't exist",
    },
    addressIsRequred: {
        id: "addressIsRequred",
        defaultMessage: "Address is required",
    },
    shipIsRequired: {
        id: "shipIsRequired",
        defaultMessage: "Ship is required",
    },
    bookingIdIsRequired: {
        id: "bookingIdIsRequired",
        defaultMessage: "Booking ID is required",
    },
    invoice: { id: "invoice", defaultMessage: "Invoice" },
    referencedIdDoesNotExist: {
        id: "referencedIdDoesNotExist",
        defaultMessage: "Referenced ID does not exist",
    },
    invoiceDate: { id: "invoiceDate", defaultMessage: "Invoice date" },
    invoiceCompanyName: { id: "invoiceCompanyName", defaultMessage: "Invoice company name" },
    bookingInformation: { id: "bookingInformation", defaultMessage: "Booking information" },
    invoices: { id: "invoices", defaultMessage: "Invoices" },
    sendToAccounting: { id: "sendToAccounting", defaultMessage: "Send to accounting" },
    bookingManagement: { id: "bookingManagement", defaultMessage: "Booking management" },
    portMonitor: {
        id: "portMonitor",
        defaultMessage: "Port monitor",
    },
    invoiceNoLength: {
        id: "invoiceNoLength",
        defaultMessage: "Invoice length",
    },
    invoiceNoPrefix: {
        id: "invoiceNoPrefix",
        defaultMessage: "Invoice prefix",
    },
    invoiceNoPrefixTooLong: {
        id: "invoiceNoPrefixTooLong",
        defaultMessage: "Invoice prefix is too long (max 12 characters)",
    },
    invoiceNoLengthInvalid: {
        id: "invoiceNoLengthInvalid",
        defaultMessage: "Invoice length is invalid (min 4, max 9 digits)",
    },
    open: { id: "open", defaultMessage: "Open" },
    readyForBilling: { id: "readyForBilling", defaultMessage: "Ready for billing" },
    invoiced: { id: "invoiced", defaultMessage: "Invoiced" },
    notAssigned: { id: "notAssigned", defaultMessage: "Not assigned" },
    assignToInvoice: { id: "assignToInvoice", defaultMessage: "Assign to invoice" },
    detatchFromInvoice: { id: "detatchFromInvoice", defaultMessage: "Detatch from invoice" },
    deleteExpense: { id: "deleteExpense", defaultMessage: "Delete expense" },
    assignToInvoiceMessage: {
        id: "assignToInvoiceMessage",
        defaultMessage:
            "Please select the number of invoice you would like to assign this expense to : ",
    },
    bookingDetails: { id: "bookingDetails", defaultMessage: "Booking details" },
    deleteExpenseMessage: {
        id: "deleteExpenseMessage",
        defaultMessage: "Are you sure you want to delete this expense?",
    },
    estimatedArrivalTime: {
        id: "estimatedArrivalTime",
        defaultMessage: "Estimated time of arrival",
    },
    estimatedDepartureTime: {
        id: "estimatedDepartureTime",
        defaultMessage: "Estimated time of departure",
    },
    saveActualTimes: { id: "saveActualTimes", defaultMessage: "Save actual times" },
    editInvoice: { id: "editInvoice", defaultMessage: "Edit invoice" },
    createInvoice: { id: "createInvoice", defaultMessage: "Create invoice" },
    addExpense: { id: "addExpense", defaultMessage: "Add expense" },
    invoiceStatus: { id: "invoiceStatus", defaultMessage: "Invoice status" },
    viewInvoice: { id: "viewInvoice", defaultMessage: "View invoice" },
    manageInternalBookings: {
        id: "manageInternalBookings",
        defaultMessage: "Manage internal bookings",
    },
    internalBookings: {
        id: "internalBookings",
        defaultMessage: "Internal bookings",
    },
    INTERNAL_BOOKING_TYPE_BOOKING: {
        id: "INTERNAL_BOOKING_TYPE_BOOKING",
        defaultMessage: "Internal",
    },
    INTERNAL_BOOKING_TYPE_BLOCKING: {
        id: "INTERNAL_BOOKING_TYPE_BLOCKING",
        defaultMessage: "Blocking",
    },
    showInternalBookings: {
        id: "showInternalBookings",
        defaultMessage: "Show internal bookings",
    },
    regularBookings: {
        id: "regularBookings",
        defaultMessage: "Regular bookings",
    },
    viewAddress: { id: "viewAddress", defaultMessage: "View address" },
    viewAddresses: { id: "viewAddresses", defaultMessage: "View addresses" },
    addressSource: { id: "addressSource", defaultMessage: "Address source" },
    confirmAction: { id: "confirmAction", defaultMessage: "Confirm action" },
    confirmVoyageData: {
        id: "confirmVoyageData",
        defaultMessage:
            "By saving the changes, all stops will be updated and the information sent to the Port Dispatchers. Are you sure you want to save?",
    },
    cancelInvoice: { id: "cancelInvoice", defaultMessage: "Cancel invoice" },
    cancelInvoiceMessage: {
        id: "cancelInvoiceMessage",
        defaultMessage:
            "Are you sure you want to cancel this invoice? This action can not be undone.",
    },
    deleteInvoice: { id: "deleteInvoice", defaultMessage: "Delete invoice" },
    deleteInvoiceTooltip: {
        id: "deleteInvoiceTooltip",
        defaultMessage:
            "Only invoices with the status OPEN can be deleted. In case the invoice is linked to a booking, the booking also needs to be DECLINED or CANCELLED.",
    },
    deleteInvoiceMessage: {
        id: "deleteInvoiceMessage",
        defaultMessage:
            "Are you sure you want to delete this invoice? This action can not be undone.",
    },
    copyInvoice: { id: "copyInvoice", defaultMessage: "Copy invoice" },
    copying: { id: "copying", defaultMessage: "Copying" },
    exportCsv: { id: "exportCsv", defaultMessage: "Export CSV" },
    confirmBookingChange: { id: "confirmBookingChange", defaultMessage: "Confirm booking change" },
    voyageDataUpdatedMessage: {
        id: "voyageDataUpdatedMessage",
        defaultMessage: "The following voyage data has been modified by Locks & Docks",
    },
    addressInUseChangedMessage: {
        id: "addressInUseChanged",
        defaultMessage: "“The following invoice address has been updated by Locks & Docks",
    },
    addressInUseChangedMessage2: {
        id: "addressInUseChanged2",
        defaultMessage:
            "This invoice address is being used in the following invoice/s with status open or ready for billing",
    },
    voyageDataUpdated: { id: "voyageDataUpdated", defaultMessage: "Voyage data updated" },
    companyActions: { id: "companyActions", defaultMessage: "Company actions" },
    bookingChangeConfirmed: {
        id: "bookingChangeConfirmed",
        defaultMessage: "Booking change confirmed",
    },
    bookingChangeConfirmedNotificationMessage: {
        id: "bookingChangeConfirmedNotificationMessage",
        defaultMessage: "The change for the following booking has been confirmed",
    },
    expenseNotOpenOrUnassigned: {
        id: "expenseNotOpenOrUnassigned",
        defaultMessage: "Expense is not in open or unassigned status or cannot be directly edited",
    },
    shipUpdated: { id: "shipUpdated", defaultMessage: "Ship updated" },
    fgksOnCall: {
        id: "fgksOnCall",
        defaultMessage: "FGKS On-Call",
    },
    filters: {
        id: "filters",
        defaultMessage: "Filters",
    },
    clearFilters: {
        id: "clearFilters",
        defaultMessage: "Clear filters",
    },
    viewBooking: { id: "viewBooking", defaultMessage: "View booking" },
    invoiceData: { id: "invoiceData", defaultMessage: "Invoice data" },
    expenseTypeCategory: { id: "expenseTypeCategory", defaultMessage: "Expense type category" },
    totalGrossPrice: { id: "totalGrossPrice", defaultMessage: "Total gross price" },
    vat: { id: "vat", defaultMessage: "VAT" },
    total: { id: "total", defaultMessage: "Total" },
    expenseInvoiced: { id: "expenseIvoiced", defaultMessage: "Expense invoiced" },
    expenseInvoicedNotificationMessage: {
        id: "expenseIvoicedNotificationMessage",
        defaultMessage: "New expenses have been invoiced",
    },
    expenseInvoiceNotificationmessage2: {
        id: "expenseInvoiceNotificationMessage2",
        defaultMessage: "For details go to",
    },
    autoCalculationType: {
        id: "autoCalculationType",
        defaultMessage: "Automatic calculation of expenses",
    },
    AUTO_CALCULATION_TYPE_NOT_APPLICABLE: {
        id: "AUTO_CALCULATION_TYPE_NOT_APPLICABLE",
        defaultMessage: "Not applicable",
    },
    AUTO_CALCULATION_TYPE_FIRST_24H_PER_BED: {
        id: "AUTO_CALCULATION_TYPE_FIRST_24H_PER_BED",
        defaultMessage: "First 24h per bed",
    },
    AUTO_CALCULATION_TYPE_REST_OF_STAY_PER_24H_PER_BED: {
        id: "AUTO_CALCULATION_TYPE_REST_OF_STAY_PER_24H_PER_BED",
        defaultMessage: "Rest of stay per 24h per bed",
    },
    AUTO_CALCULATION_TYPE_PER_24H_PER_BED: {
        id: "AUTO_CALCULATION_TYPE_PER_24H_PER_BED",
        defaultMessage: "Per 24h per bed",
    },
    AUTO_CALCULATION_TYPE_PER_BOOKING: {
        id: "AUTO_CALCULATION_TYPE_PER_BOOKING",
        defaultMessage: "Per booking",
    },
    AUTO_CALCULATION_TYPE_PER_INVOICE: {
        id: "AUTO_CALCULATION_TYPE_PER_INVOICE",
        defaultMessage: "Per invoice",
    },
    previewOfExpensesForBooking: {
        id: "previewOfExpensesForBooking",
        defaultMessage: "Preview of expenses for booking",
    },
    invoicePreviewDisclaimer: {
        id: "invoicePreviewDisclaimer",
        defaultMessage:
            "This is only a preview of the expenses and can change if changes to the booking are made (date / time). Any supply fees (electricity, water, on-call-services, etc.) will be added to the invoice after purchase.",
    },
    newConsumptionExpense: {
        id: "newConsumptionExpense",
        defaultMessage: "New consumption expense",
    },
    declineComment: { id: "declineComment", defaultMessage: "Decline comment" },
    declineBookingModalMessage1: {
        id: "declineBookingModalMessage1",
        defaultMessage: " If you wish you can add a comment for the declined booking.",
    },
    declineBookingModalMessage2: {
        id: "declineBookingModalMessage2",
        defaultMessage: "It will not be shared with the user that requested the booking.",
    },
    invoiceIsNotEditable: {
        id: "invoiceIsNotEditable",
        defaultMessage: "Invoice is not editable (not in OPEN or READY_FOR_BILLING status)",
    },
    invoiceAddressInUseVoyages: {
        id: "invoiceAddressInUseVoyages",
        defaultMessage:
            "This invoice address is being used for voyages and bookings and can not be deleted.",
    },
    errorDeletingAddress: { id: "errorDeletingAddress", defaultMessage: "Error deleting address" },
    planned: { id: "planned", defaultMessage: "Planned" },
    actual: { id: "actual", defaultMessage: "Actual" },
    userName: { id: "userName", defaultMessage: "User name" },
    internalRemarks: { id: "internalRemarks", defaultMessage: "Internal remarks" },
    calculationType: { id: "calculationType", defaultMessage: "Calculation type" },
    ordNumIsRequired: {
        id: "ordNumIsRequired",
        defaultMessage: "Order number is required and must be a positive integer",
    },
    fromInvoiceDate: {
        id: "fromInvoiceDate",
        defaultMessage: "From invoice date",
    },
    toInvoiceDate: {
        id: "toInvoiceDate",
        defaultMessage: "To invoice date",
    },
    fromBookingDate: {
        id: "fromBookingDate",
        defaultMessage: "From booking date",
    },
    toBookingDate: {
        id: "toBookingDate",
        defaultMessage: "To booking date",
    },
    invoiceAddressInUseActiveBooking: {
        id: "invoiceAddressInUseActiveBooking",
        defaultMessage: "Cannot delete address because it is used in an active booking",
    },
    importVoyageAndStopsCsv: {
        id: "importVoyageAndStops",
        defaultMessage: "Import CSV - Voyage & Stops",
    },
    errorParsingCsv: {
        id: "errorParsingCsv",
        defaultMessage: "Error parsing CSV",
    },
    faecesAdapter: { id: "faecesAdapter", defaultMessage: "Faeces adapter" },
    powerlockAdapter: { id: "powerlockAdapter", defaultMessage: "Powerlock adapter" },
    remarks: { id: "remarks", defaultMessage: "Remarks" },
    onCallStatus: { id: "onCallStatus", defaultMessage: "On-Call Status" },
    bookingCompleted: { id: "bookingCompleted", defaultMessage: "Booking completed" },
    bookingNotCompleted: { id: "bookingNotCompleted", defaultMessage: "Booking not completed" },
    dispatcherRemark: { id: "dispatcherRemark", defaultMessage: "Dispatcher remark" },
    sharedRemark: { id: "sharedRemark", defaultMessage: "Shared remark" },
    editDispatcherRemark: { id: "editDispatcherRemark", defaultMessage: "Edit dispatcher remark" },
    editSharedRemark: { id: "editSharedRemark", defaultMessage: "Edit shared remark" },
    waterMeterStartValue: { id: "waterMeterStartValue", defaultMessage: "Water meter start value" },
    waterMeterEndValue: { id: "waterMeterEndValue", defaultMessage: "Water meter end value" },
    startTime: { id: "startTime", defaultMessage: "Start time" },
    endTime: { id: "endTime", defaultMessage: "End time" },
    deployments: { id: "deployments", defaultMessage: "Deployments" },
    totalTime: { id: "totalTime", defaultMessage: "Total time" },
    addDeployment: { id: "addDeployment", defaultMessage: "Add deployment" },
    start: { id: "start", defaultMessage: "Start" },
    end: { id: "end", defaultMessage: "End" },
    addService: { id: "addService", defaultMessage: "Add service" },
    editService: { id: "editService", defaultMessage: "Edit service" },
    serviceTypeIsRequired: {
        id: "serviceTypeIsRequired",
        defaultMessage: "Service type is required",
    },
    waterMeterStartValueIsRequired: {
        id: "waterMeterStartValueIsRequired",
        defaultMessage: "Water meter start value is required",
    },
    waterMeterEndValueIsRequired: {
        id: "waterMeterEndValueIsRequired",
        defaultMessage: "Water meter end value is required",
    },
    endValueCantBeLowerThanStartValue: {
        id: "endValueCantBeLowerThanStartValue",
        defaultMessage: "End value can't be lower than start value",
    },
    startTimeIsRequired: { id: "startTimeIsRequired", defaultMessage: "Start time is required" },
    endTimeIsRequired: { id: "endTimeIsRequired", defaultMessage: "End time is required" },
    endTimeCanNotBeBeforeStartTime: {
        id: "endTimeCanNotBeBeforeStartTime",
        defaultMessage: "End time can not be before start time",
    },
    serviceNeedsToHaveAtLeastTwoDeployments: {
        id: "serviceNeedsToHaveAtLeastTwoDeployments",
        defaultMessage: "Service needs to have at least two deployments",
    },
    service: { id: "service", defaultMessage: "Service" },
    dateOfService: { id: "dateOfService", defaultMessage: "Date of service" },
    lastModifiedBy: { id: "lastModifiedBy", defaultMessage: "Last modified by" },
    manageServices: { id: "manageServices", defaultMessage: "Manage services" },
    serviceStatus: { id: "serviceStatus", defaultMessage: "Service status" },
    finalized: { id: "finalized", defaultMessage: "Finalized" },
    notFinalized: { id: "notFinalized", defaultMessage: "Not finalized" },
    serviceSection: { id: "serviceSection", defaultMessage: "Service section" },
    bookingComplete: { id: "bookingComplete", defaultMessage: "Booking complete" },
    confirmServiceDeleteText: {
        id: "confirmServiceDeleteText",
        defaultMessage: "Are you sure you want to delete this service?",
    },
    startValue: { id: "startValue", defaultMessage: "Start value" },
    endValue: { id: "endValue", defaultMessage: "End value" },
    finalizedBy: { id: "finalizedBy", defaultMessage: "Finalized by" },
    timeTable: { id: "timeTable", defaultMessage: "Time table" },
    image: { id: "image", defaultMessage: "Image" },
    other: { id: "other", defaultMessage: "Other" },
    pdf: { id: "pdf", defaultMessage: "PDF" },
    word: { id: "word", defaultMessage: "Word" },
    text: { id: "text", defaultMessage: "Text" },
    video: { id: "video", defaultMessage: "Video" },
    audio: { id: "audio", defaultMessage: "Audio" },
    excel: { id: "excel", defaultMessage: "Excel" },
    csv: { id: "csv", defaultMessage: "CSV" },
    noBeds: { id: "noBeds", defaultMessage: "No. beds" },
    finalizeBooking: { id: "finalizeBooking", defaultMessage: "Finalize booking" },
    unfinalizeBooking: { id: "unfinalizeBooking", defaultMessage: "Unfinalize booking" },
    disabledFinalizeMessage: {
        id: "disabledFinalizeMessage",
        defaultMessage:
            "Booking cannot be unfinalized because it's linked to invoices that are not in the open status",
    },
    disableFinalizeMessage2: {
        id: "disableFinalizeMessage2",
        defaultMessage:
            "Disabled because the booking is linked to invoice/s that are in the invoiced / ready for billing status",
    },
    disableFinalizeMessage3: {
        id: "disableFinalizeMessage3",
        defaultMessage:
            "Booking cannot be finalized because it's linked to services that are still not completed",
    },
    disableFinalizeMessage4: {
        id: "disableFinalizeMessage4",
        defaultMessage:
            "Booking cannot be finalized because Actual arrival time / Actual departure time is not entered",
    },
    disableFinalizeMessage5: {
        id: "disableFinalizeMessage5",
        defaultMessage:
            "Booking cannot be finalized because it's not in the status Confirmed / Changed",
    },
    serviceEditUnavailableMessage: {
        id: "serviceEditUnavailableMessage",
        defaultMessage:
            "Service can not be edited / deleted because it is linked to a finalized booking",
    },
    serviceEditUnavailableMessage2: {
        id: "serviceEditUnavailableMessage2",
        defaultMessage:
            'Service can not be edited / deleted because it is not in the status "open"',
    },
    addServiceUnavailableMessage: {
        id: "addServiceUnavailableMessage",
        defaultMessage: "Services can not be added because the booking is finalized",
    },
    serverSettings: {
        id: "serverSettings",
        defaultMessage: "Server settings",
    },
    overviewOfBookings: { id: "overviewOfBookings", defaultMessage: "Overview of bookings" },
    bookingCommentChanged: { id: "bookingCommentChanged", defaultMessage: "Shared remark added" },
    bookingCommentChangedNotificationMessage: {
        id: "bookingCommentChangedNotificationMessage",
        defaultMessage: "Shared remark has been updated for the following booking",
    },
    any: { id: "any", defaultMessage: "Any" },
    doNotShowInPublicPlan: {
        id: "doNotShowInPublicPlan",
        defaultMessage: "Do not show in public plan",
    },
    orderNumberMessage: {
        id: "orderNumberMessage",
        defaultMessage:
            'Order number can be changed trough the "Order number" action in the Expense types table',
    },
    attachToInvoice: { id: "attachToInvoice", defaultMessage: "Attach to invoice" },
    detachFromInvoice: { id: "detachFromInvoice", defaultMessage: "Detach from invoice" },
    attachToInvoiceMessage: {
        id: "attachToInvoiceMessage",
        defaultMessage: "Please select the invoice you you would like to attach this service to.",
    },
    detachFromInvoiceMessage: {
        id: "detachFromInvoiceMessage",
        defaultMessage: "Are you sure you want to detach this service from its current invoice?",
    },
    attachServiceDisabledMessage: {
        id: "attachServiceDisabledMessage",
        defaultMessage: "Service is already attached to an existing invoice",
    },
    detachServiceDisabledMessage: {
        id: "detachServiceDisabledMessage",
        defaultMessage:
            "Service can not be detached from the invoice because it is not in the open status",
    },
    serviceNotAttachedMessage: {
        id: "serviceNotAttachedMessage",
        defaultMessage: "Service is not attached to any invoice",
    },
    lastModShipData: { id: "lastModShipData", defaultMessage: "Last modification ship data" },
    chatNotConnected: {
        id: "chatNotConnected",
        defaultMessage: "Chat is not connected",
    },
});
