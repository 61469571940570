import { useContext } from "react";
import { TooltipContext, TooltipContextProps } from "../contexts/TooltipContext";

/**
 * Custom hook that provides access to the tooltip context.
 * Throws an error if used outside of the TooltipProvider.
 * @returns The tooltip context object.
 */
export function useTooltipContext(): TooltipContextProps {
    const ctx = useContext(TooltipContext);
    if (!ctx) throw new Error("useTooltip must be used within TooltipProvider");
    return ctx;
}
