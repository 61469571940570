import { ArrowDropDown } from "@mui/icons-material";
import {
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Menu,
    MenuItem,
    TextField,
    Tooltip,
} from "@mui/material";
import { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";
import { useIntl } from "react-intl";
import Messages from "../../../localization/Messages";
import { IBerthing } from "../../../models/berthing";
import { BookingStatus } from "../../../models/booking";
import { useDispatch } from "react-redux";
import { changeBerthings } from "../../../api/berthing";
import { showBackendMessage } from "../../../helpers/messagesHelper";
import { isBookingStatusProvisional } from "../../../containers/ManageBookings/shared/utils";
import { getBerthingsButtonColor } from "../shared/utils";

export type BerthingButtonMenuGroupProps = {
    isFormInvalid: boolean;
    loading?: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
    getBookings: (bookingId: number) => Promise<void>;
    berthings: IBerthing[];
    bookingId: number;
    bookingStatus: BookingStatus;
    sendBookingResponses: (id?: number) => Promise<void>;
    onDeclineEnd?: () => void;
};

/**
 * Part of the Berthing respond form. Replaces the Provisionaly Plan and Provisionaly decline Buttons with the addition of Respond to Booking request action.
 *
 * @component
 * @param {object} props - The component props.
 * @param {boolean} props.isFormInvalid - Indicates whether the form is invalid.
 * @param {boolean} [props.loading] - Indicates whether the component is in a loading state.
 * @param {function} props.setLoading - A function to set the loading state of the component.
 * @param {function} props.getBookings - A function to retrieve bookings for a specific booking ID.
 * @param {Array<IBerthing>} props.berthings - An array of berthings.
 * @param {number} props.bookingId - The ID of the booking.
 * @param {BookingStatus} props.bookingStatus - The status of the booking.
 * @param {function} props.sendBookingResponses - A function to send booking responses.
 * @param {function} [props.onDeclineEnd] - A function to be called when the decline process ends.
 * @returns {JSX.Element} The rendered component.
 */
export const BerthingButtonMenuGroup = ({
    isFormInvalid,
    loading,
    setLoading,
    getBookings,
    berthings,
    bookingId,
    bookingStatus,
    sendBookingResponses,
    onDeclineEnd,
}: BerthingButtonMenuGroupProps) => {
    const intl = useIntl();
    const [open, setOpen] = useState(false);
    const menuButtonRef = useRef<HTMLButtonElement>(null);
    const [declineModalOpen, setDeclineModalOpen] = useState(false);
    const [comment, setComment] = useState("");
    const dispatch = useDispatch();
    const globalErrorMessage = intl.formatMessage(Messages.dataInvalid);

    const disableClear = bookingStatus !== BookingStatus.PROVISIONALLY_PLANNED && !berthings.length;

    const handleToggle = useCallback(() => {
        setOpen(prev => !prev);
    }, []);

    const bookingStatusProvisional = isBookingStatusProvisional(bookingStatus);
    const buttonColor = getBerthingsButtonColor(bookingStatus);

    const handleDeclineBooking = async () => {
        setLoading(true);
        try {
            await changeBerthings(bookingId, berthings, false);
            onDeclineEnd?.();
            dispatch(showBackendMessage(intl, "success", "updating", Messages.booking));
        } catch {
            dispatch(showBackendMessage(intl, "error", "updating", Messages.booking));
        } finally {
            setLoading(false);
            setDeclineModalOpen(false);
            getBookings(bookingId);
        }
    };

    const handleSave = async () => {
        try {
            await changeBerthings(bookingId, berthings, true);
            dispatch(showBackendMessage(intl, "success", "updating", Messages.berthings));
        } catch {
            dispatch(showBackendMessage(intl, "error", "updating", Messages.berthings));
        } finally {
            getBookings(bookingId);
        }
    };

    return (
        <>
            <ButtonGroup variant="contained" color={buttonColor}>
                {!bookingStatusProvisional ? (
                    <Tooltip title={isFormInvalid ? globalErrorMessage : undefined}>
                        <span>
                            <Button
                                variant="outlined"
                                color={buttonColor}
                                disabled={loading || isFormInvalid || disableClear}
                                onClick={() => handleSave()}
                            >
                                {berthings.length
                                    ? intl.formatMessage(Messages.provisionallyPlan)
                                    : intl.formatMessage(Messages.clear)}
                            </Button>
                        </span>
                    </Tooltip>
                ) : (
                    <Button
                        variant="outlined"
                        color={buttonColor}
                        onClick={() => sendBookingResponses(bookingId)}
                    >
                        {intl.formatMessage(Messages.sendBookingResponse)}
                    </Button>
                )}

                <Button
                    size="small"
                    variant="outlined"
                    ref={menuButtonRef}
                    onClick={handleToggle}
                    color={buttonColor}
                >
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>

            <Menu anchorEl={menuButtonRef?.current} open={open} onClose={handleToggle}>
                {bookingStatusProvisional && (
                    <MenuItem
                        disabled={loading || isFormInvalid || disableClear}
                        onClick={() => handleSave()}
                    >
                        {intl.formatMessage(Messages.provisionallyPlan)}
                    </MenuItem>
                )}
                <MenuItem disabled={loading} onClick={() => setDeclineModalOpen(true)}>
                    {intl.formatMessage(Messages.provisionallyDecline)}
                </MenuItem>
            </Menu>

            <Dialog open={declineModalOpen}>
                <DialogTitle>{intl.formatMessage(Messages.declineComment)}</DialogTitle>
                <DialogContent>
                    <FormControl className="decline-modal-content">
                        <span>
                            {intl.formatMessage(Messages.declineBookingModalMessage1)}
                            <br />
                            {intl.formatMessage(Messages.declineBookingModalMessage2)}
                        </span>
                        <TextField
                            value={comment}
                            label={intl.formatMessage(Messages.comment)}
                            onChange={e => setComment(e.target.value)}
                            variant="filled"
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDeclineModalOpen(false);
                            setComment("");
                        }}
                    >
                        {intl.formatMessage(Messages.cancel)}
                    </Button>
                    <Button variant="outlined" onClick={handleDeclineBooking}>
                        {intl.formatMessage(Messages.confirm)}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
