import { useKeycloak } from "@react-keycloak/web";
import { useIntl } from "react-intl";
import { keycloakConfig } from "../../../../Keycloak";
import { useNavigate } from "react-router-dom";
import { Roles, UserRoles } from "../../../../models/userData";
import { Cloud, Support, SettingsRounded, EditNotifications } from "@mui/icons-material";
import {
    Card,
    CardContent,
    Typography,
    Divider,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
} from "@mui/material";
import "./UserPanel.css";
import { useAuth } from "../../../../hooks/useAuth";
import { displayEnumTextFromSnakeCase } from "../../../../helpers/enumHelpers";
import Messages from "../../../../localization/Messages";

export type UserPanelProps = {
    companyName?: string;
};

/**
 * UserPanel component displays the user information and provides various actions for the user.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.companyName - The name of the company.
 * @returns {JSX.Element} The rendered UserPanel component.
 */
export function UserPanel({ companyName }: UserPanelProps) {
    const navigate = useNavigate();
    const { user, hasRole } = useAuth();
    const intl = useIntl();
    const { keycloak } = useKeycloak();

    return (
        <Card sx={{ maxWidth: "100%", width: 300 }}>
            <CardContent sx={{ padding: "1rem !important" }}>
                <Typography variant="h5" color="text.primary">
                    {user.firstName} {user.lastName}
                </Typography>

                <Typography
                    sx={{ fontSize: "12px", paddingBottom: 1 }}
                    component="div"
                    gutterBottom
                >
                    {companyName}
                </Typography>

                {Object.entries(UserRoles)
                    .filter(([roleKey]) => user.roles.includes(roleKey))
                    .map(([, roleValue], key) => (
                        <Typography sx={{ fontSize: "14px" }} key={key} color="text.secondary">
                            • {displayEnumTextFromSnakeCase(roleValue)}
                        </Typography>
                    ))}

                <MenuList disablePadding sx={{ paddingTop: 2 }}>
                    <MenuItem
                        component="a"
                        href="mailto:support@rheinports.eu"
                        sx={{ paddingInline: 1 }}
                    >
                        <ListItemIcon>
                            <Support />
                        </ListItemIcon>
                        <ListItemText>{intl.formatMessage(Messages.support)}</ListItemText>
                    </MenuItem>
                    <MenuItem
                        sx={{ paddingInline: 1 }}
                        component="a"
                        href={`${keycloakConfig.url}/realms/${keycloak.realm}/account/?referrer=${keycloak.realm}`}
                    >
                        <ListItemIcon>
                            <SettingsRounded />
                        </ListItemIcon>
                        <ListItemText>
                            {intl.formatMessage(Messages.manageUserAccount)}
                        </ListItemText>
                    </MenuItem>
                    {!hasRole(Roles.ON_CALL_DISPATCHER) && (
                        <MenuItem
                            sx={{ paddingInline: 1 }}
                            onClick={() => navigate("/notification-settings")}
                        >
                            <ListItemIcon>
                                <EditNotifications />
                            </ListItemIcon>
                            <ListItemText>
                                {intl.formatMessage(Messages.notificationSettings)}
                            </ListItemText>
                        </MenuItem>
                    )}
                    <Divider />
                    <MenuItem sx={{ paddingInline: 1 }} onClick={() => keycloak.logout()}>
                        <ListItemIcon>
                            <Cloud />
                        </ListItemIcon>
                        <ListItemText>{intl.formatMessage(Messages.logout)}</ListItemText>
                    </MenuItem>
                </MenuList>
            </CardContent>
        </Card>
    );
}
export default UserPanel;
