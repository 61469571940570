import { getDateMoment } from "../../../helpers/dateHelper";
import { SortOrder } from "../../../hooks/useTableSort";
import { ExpenseStatus } from "../../../models/expense";

export type ExpenseFiltersType = {
    dateFrom: Date | null;
    dateTo: Date | null;
    internalInvoiceNumber: string | undefined;
    bookingId: number | null;
    shipName: string;
    owningCompanyDocksId: string | undefined;
    managingCompany: string | undefined;
    expenseTypeId: string | undefined;
    status: ExpenseStatus | undefined;
    rowsPerPage: number;
    page: number;
    order: SortOrder;
};

export function buildExpensesSearchPayload(filters: ExpenseFiltersType) {
    return {
        textSearch: filters.shipName,
        offset: filters.page * filters.rowsPerPage,
        limit: filters.rowsPerPage,
        order: filters.order,
        columns: {},
        filter: buildExpensesSearchPayloadFilters(filters),
        showDeleted: false,
    };
}

function buildExpensesSearchPayloadFilters(filters: ExpenseFiltersType) {
    const {
        dateFrom,
        dateTo,
        internalInvoiceNumber,
        bookingId,
        owningCompanyDocksId,
        managingCompany,
        expenseTypeId,
        status,
    } = filters;
    const filterArray: object[] = [];

    if (dateFrom) {
        const startDate = getDateMoment(dateFrom).startOf("day").toDate();
        filterArray.push({ expenseDate: { $gte: startDate.getTime() } });
    }

    if (dateTo) {
        const endDate = getDateMoment(dateTo).endOf("day").toDate();
        filterArray.push({ expenseDate: { $lte: endDate.getTime() } });
    }

    if (internalInvoiceNumber) {
        filterArray.push({ internalInvoiceNumber: internalInvoiceNumber });
    }

    if (bookingId) {
        filterArray.push({ bookingId: bookingId });
    }

    if (owningCompanyDocksId) {
        filterArray.push({ owningCompanyDocksId: owningCompanyDocksId });
    }

    if (managingCompany) {
        filterArray.push({ managingCompany: managingCompany });
    }

    if (expenseTypeId) {
        filterArray.push({ expenseTypeId: expenseTypeId });
    }

    if (status) {
        filterArray.push({ status: status });
    }

    return { $and: filterArray };
}
