import React, { useState, ReactNode, useCallback } from "react";
import RpisDialog from "../containers/RpisDialog/RpisDialog";
import { useIntl } from "react-intl";
import Messages from "../localization/Messages";
import { Button, DialogActions, DialogContent } from "@mui/material";

export type ConfirmDialogProps = {
    onConfirm: () => Promise<void> | void;
    message: string;
    severity?: "success" | "error" | "primary";
    title?: string;
};

export const ConfirmDialogContext = React.createContext<{
    dialogProps: ConfirmDialogProps;
    openDialog: (props: ConfirmDialogProps) => void;
    closeDialog: () => void;
}>(null as any);

/**
 * Provides a context for managing a confirm dialog.
 *
 * @component
 * @example
 * ```tsx
 * <ConfirmDialogProvider>
 *   {/* Your components here * /}
 * </ConfirmDialogProvider>
 * ```
 */
export function ConfirmDialogProvider({ children }: { children: ReactNode }) {
    const intl = useIntl();
    const [isOpen, setIsOpen] = useState(false);
    const [dialogProps, setDialogProps] = useState<ConfirmDialogProps>({
        onConfirm: () => {},
        message: "",
        severity: "error",
        title: undefined,
    });

    const openDialog = useCallback((props: ConfirmDialogProps) => {
        setIsOpen(true);
        setDialogProps({ ...props });
    }, []);

    const closeDialog = useCallback(() => {
        setIsOpen(false);
    }, []);

    const {
        message = "",
        severity = "error",
        title = intl.formatMessage(Messages.confirmAction),
        onConfirm = () => closeDialog(),
    } = dialogProps;

    const handleConfirm = async () => {
        await onConfirm();
        closeDialog();
    };

    return (
        <ConfirmDialogContext.Provider value={{ dialogProps, openDialog, closeDialog }}>
            {children}
            <RpisDialog
                size="sm"
                fullWidth={false}
                dialogOpen={isOpen}
                title={title}
                onClose={closeDialog}
                content={
                    <>
                        <DialogContent>{message}</DialogContent>
                        <DialogActions>
                            <Button variant="contained" color={severity} onClick={handleConfirm}>
                                {intl.formatMessage(Messages.ok)}
                            </Button>
                            <Button variant="outlined" color="primary" onClick={closeDialog}>
                                {intl.formatMessage(Messages.cancel)}
                            </Button>
                        </DialogActions>
                    </>
                }
            />
        </ConfirmDialogContext.Provider>
    );
}
