import { ServerSettings } from "../models/serverSettings";

export const SET_SERVER_SETTINGS = "SET_SERVER_SETTINGS";

export interface SetServerSettings {
    type: typeof SET_SERVER_SETTINGS;
    payload: ServerSettings;
}

export type ServerSettingsActionType = SetServerSettings;

export function setServerSettingsFromRequest(payload: ServerSettings): ServerSettingsActionType {
    return { type: SET_SERVER_SETTINGS, payload };
}
