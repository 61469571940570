import cn from "classnames";
import { INotification, INotificationContent } from "../../../../models/notification";
import Messages from "../../../../localization/Messages";
import { Tooltip } from "@mui/material";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { getDateMoment } from "../../../../helpers/dateHelper";
import { Roles } from "../../../../models/userData";
import {
    getNotificationLinksDispatcher,
    getNotificationTitleDispatcher,
} from "../../../../helpers/dispNotificationHelper";
import {
    getNotificationLinksDocks,
    getNotificationTitlesDocks,
} from "../../../../helpers/docksNotificationHelper";
import { useAuth } from "../../../../hooks/useAuth";
import { useNotifications } from "../../../../hooks/useNotifications";
import "./Notification.css";

type NotificationProps = {
    notification: INotification;
};

export const Notification = ({ notification }: NotificationProps) => {
    const { markAsRead } = useNotifications();
    const intl = useIntl();
    const navigate = useNavigate();
    const { hasRole } = useAuth();
    const isDispatcher = hasRole(Roles.PASSENGER_CABIN_CRUISES_DISPATCHER);
    const markReadTitle = notification.read
        ? // intl.formatMessage(Messages.markAsUnread) TODO: Add set as unread functionality
          ""
        : intl.formatMessage(Messages.markAsRead);

    const getNotificationLinks = isDispatcher
        ? getNotificationLinksDispatcher
        : getNotificationLinksDocks;
    const getNotificationTitles = isDispatcher
        ? getNotificationTitleDispatcher
        : getNotificationTitlesDocks;

    const notificationDataList: INotificationContent[] = useMemo(() => {
        const titles = getNotificationTitles(intl, notification);
        const links = getNotificationLinks(intl, notification);
        return titles.map((title, i) => ({ title, link: links[i] }));
    }, [getNotificationLinks, getNotificationTitles, notification, intl]);

    return (
        <div
            key={notification.id}
            className={cn("notification", {
                unread: !notification.read,
            })}
        >
            <div
                className="notification-body-container"
                onClick={() => {
                    markAsRead(notification.id);
                    navigate(`/${notificationDataList[0].link.ref}`);
                }}
            >
                <div className="note-body">
                    {notificationDataList.map((notificationData, index) => (
                        <div key={index}>
                            <p className="note-content">{notificationData.title}</p>
                            <a
                                className="note-link"
                                onClick={e => {
                                    e.stopPropagation();
                                    navigate(`/${notificationData.link.ref}`);
                                }}
                            >
                                {notificationData.link.title}
                            </a>
                        </div>
                    ))}
                </div>
            </div>

            <span className="timestamp">{getDateMoment(notification.createTs).fromNow()}</span>

            <Tooltip title={markReadTitle} placement="left">
                <div className="read-mark-container">
                    <button onClick={() => markAsRead(notification.id)}>
                        <div className="read-mark"></div>
                    </button>
                </div>
            </Tooltip>
        </div>
    );
};
