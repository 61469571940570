import { DateFormat, getDateText } from "../../helpers/dateHelper";
import { ReactNode } from "react";
import "./ChatMessage.css";
import { Box } from "@mui/material";
import classNames from "classnames";

/**
 * Represents the data structure for a chat message.
 */
export interface IChatMessage {
    /**
     * The name of the message sender.
     */
    senderName: string;
    /**
     * The content of the message.
     */
    text: ReactNode;
    /**
     * The direction of the message (IN for incoming, OUT for outgoing).
     */
    direction: "IN" | "OUT";
    /**
     * The type of the message (STATUS for automated status messages, TEXT for regular text messages).
     */
    type: "STATUS" | "TEXT";
    /**
     * The timestamp of the message.
     */
    time?: number;
    /**
     * The estimated time of arrival.
     */
    eta?: number;
    /**
     * The estimated time of departure.
     */
    etd?: number;
    /**
     * Indicates whether the estimated time of arrival has changed.
     */
    etaChanged?: boolean;
    /**
     * Indicates whether the estimated time of departure has changed.
     */
    etdChanged?: boolean;
    /**
     * The action associated with the message.
     */
    action: string;
}

/**
 * Represents a chat message component.
 *
 * @component
 * @example
 * // Usage:
 * <ChatMessage
 *   senderName={senderName}
 *   text={text}
 *   direction={"OUT" | "IN" }
 *   type={"STATUS" | "TEXT}
 *   time={1635871200000}
 *   eta={1635874800000}
 *   etd={1635878400000}
 * />
 *
 * @param {string} senderName - The name of the message sender.
 * @param {ReactNode} text - The content of the message.
 * @param {("IN" | "OUT")} direction - The direction of the message (IN for incoming, OUT for outgoing).
 * @param {("STATUS" | "TEXT")} type - The type of the message (STATUS for automated status messages, TEXT for regular text messages).
 * @param {number} [time] - The timestamp of the message.
 * @param {number} [eta] - The estimated time of arrival.
 * @param {number} [etd] - The estimated time of departure.
 * @param {boolean} [etaChanged] - Indicates whether the estimated time of arrival has changed.
 * @param {boolean} [etdChanged] - Indicates whether the estimated time of departure has changed.
 * @param {string} action - The action associated with the message.
 *
 * @returns {JSX.Element} The rendered chat message component.
 */
function ChatMessage({
    text,
    direction,
    time,
    type,
    eta,
    etd,
    senderName,
    etaChanged,
    etdChanged,
    action,
}: IChatMessage) {
    const isAutomatedMessage = type === "STATUS" || undefined;
    const statusClass = isAutomatedMessage ? "automated-message" : "regular-message";
    const directionClass = direction === "OUT" ? "sending" : "receiving";
    const wrapperClass = `chat-message-wrapper ${directionClass}`;
    const messageClass = `chat-message ${statusClass}`;
    const senderClass = "chat-message-sender";
    const dateRangeClass = "chat-message-time-span";
    const etaText = getDateText(eta, DateFormat.CLIENT_DATE_TIME);
    const etdText = getDateText(etd, DateFormat.CLIENT_DATE_TIME);
    const timeText = getDateText(time, DateFormat.CLIENT_DATE_TIME);

    return (
        <div className={wrapperClass}>
            <div className={messageClass}>
                {typeof text === "string" ? <p>{text}</p> : text}
                {etaText && etdText && (
                    <Box>
                        <p
                            className={classNames(dateRangeClass, {
                                "change-requested-style": etaChanged || etdChanged,
                                "booking-requested-style": action === "booking_requested",
                            })}
                        >
                            {etaText} - {etdText}
                        </p>
                    </Box>
                )}
            </div>
            <small>
                <div>{timeText}</div>
                <div className={senderClass}>{senderName}</div>
            </small>
        </div>
    );
}

export default ChatMessage;
