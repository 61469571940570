import { IntlShape } from "react-intl";
import { getDateText } from "../helpers/dateHelper";
import { displayAmount } from "../helpers/moneyHelper";
import Messages from "../localization/Messages";

export interface IExpense {
    id: string;
    expenseTypeId: string;
    companyId?: string;
    owningCompanyDocksId?: string;
    invoiceId?: number | null;
    purchaseRemarks: string;
    quantity: number | null;
    expenseDate: number;
    name: string;
    unit: string;
    netPricePerUnit: number | null;
    accRef: string;
    vat: number | null;
    costNet?: number | null;
    owningCompany?: string | null;
    shipName?: string | null;
    managingCompany?: string | null;
    bookingId?: number | null;
    deleted?: number;
    status?: ExpenseStatus;
    internalInvoiceNumber?: string;
    currency?: string;
    custom?: boolean;
    ordnum: number;
    category: string;
    createByName?: string;
    lastModifyBy?: string;
    internalRemarks: string;
    serviceId?: string;
}

export interface IDockExpense {
    id: string;
    bookingId: number | null;
    companyId: string;
    costNet: number | null;
    quantity: number | null;
    currency: string;
    deleted: number;
    expenseDate: number;
    category: string;
    name: string;
    internalInvoiceNumber: string;
    invoiceId: number;
    owningCompany: string;
    owningCompanyId: string;
    shipId: string;
    shipName: string;
    unit: string;
    netPricePerUnit: number;
    vat: number;
    createByName?: string;
}

export const expenseInit: IExpense = {
    id: "",
    expenseTypeId: "",
    purchaseRemarks: "",
    quantity: 0,
    expenseDate: new Date().getTime(),
    name: "",
    unit: "",
    accRef: "",
    netPricePerUnit: 0,
    vat: 0,
    ordnum: 0,
    category: "",
    internalRemarks: "",
};

export enum ExpenseStatus {
    NOT_ASSIGNED = "NOT_ASSIGNED",
    OPEN = "OPEN",
    READY_FOR_BILLING = "READY_FOR_BILLING",
    INVOICED = "INVOICED",
    CANCELLED = "CANCELLED",
    DELETED = "DELETED",
}

export const expenseStatuses = [
    { id: 0, value: ExpenseStatus.NOT_ASSIGNED, label: Messages.notAssigned },
    { id: 1, value: ExpenseStatus.OPEN, label: Messages.open },
    { id: 2, value: ExpenseStatus.READY_FOR_BILLING, label: Messages.readyForBilling },
    { id: 3, value: ExpenseStatus.INVOICED, label: Messages.invoiced },
    { id: 4, value: ExpenseStatus.CANCELLED, label: Messages.canceled },
    { id: 5, value: ExpenseStatus.DELETED, label: Messages.deleted },
];

export const displayExpenseStatus = (intl: IntlShape, status: ExpenseStatus | undefined) => {
    const statusText = expenseStatuses.find(s => s.value === status)?.label;
    return statusText ? intl.formatMessage(statusText) : "";
};

export const getLDExpenseCsvMapper = () => {
    return (e: IDockExpense) => ({
        expenseType: e.name,
        quantity: e.quantity ?? 0,
        totalCostNet: displayAmount(e.costNet, e.currency),
        expenseDate: getDateText(e.expenseDate),
        owningCompany: e?.owningCompany ?? "",
        ship: e.shipName ?? "",
        invoiceNumber: e.internalInvoiceNumber ?? "",
    });
};

export const getExpenseCsvMapper = (intl: IntlShape, managingCompanies: Map<any, any>) => {
    return (e: IExpense) => ({
        expenseType: e.name,
        quantity: e.quantity ?? 0,
        totalCostNet: displayAmount(e.costNet ?? 0),
        expenseDate: getDateText(e.expenseDate),
        status: displayExpenseStatus(intl, e.status),
        owningCompany: e?.owningCompany ?? "",
        managingCompany: managingCompanies.get(e.managingCompany) ?? "",
        ship: e.shipName ?? "",
        invoiceNumber: e.invoiceId ?? "",
    });
};

/*get VAT percentage and net price and return total price */
export const getTotalPrice = (constNet: number, vat: number): number => {
    return (vat / 100) * constNet + constNet;
};

export const getExpensesTotalNetCost = (expenses: IExpense[] | IDockExpense[]) => {
    return expenses.reduce((acc, expense) => acc + (expense.costNet ?? 0), 0);
};

export const getExpensesTotalVatCost = (expenses: IExpense[] | IDockExpense[]) => {
    return expenses.reduce(
        (acc, expense) => acc + (expense.costNet ?? 0) * ((expense.vat ?? 0) / 100),
        0,
    );
};

export const getExpensesTotalGrossCost = (expenses: IExpense[] | IDockExpense[]) => {
    return expenses.reduce(
        (acc, expense) => acc + getTotalPrice(expense.costNet ?? 0, expense.vat ?? 0),
        0,
    );
};
