import axios, { AxiosResponse } from "axios";
import { GuiConfDispatcher, GuiConfDocks } from "../store/guiConfsReducer";
import { getDateMoment } from "../helpers/dateHelper";

export const getDispatcherGuiConf = async (): Promise<GuiConfDispatcher> => {
    const dispGuiConf: GuiConfDispatcher = (await axios.get("/dispatcher/getGuiConf")).data;
    if (
        dispGuiConf &&
        "dispDockAllocationPlan" in dispGuiConf &&
        dispGuiConf["dispDockAllocationPlan"] &&
        "rangeVal" in dispGuiConf["dispDockAllocationPlan"]
    ) {
        dispGuiConf.dispDockAllocationPlan.rangeVal =
            dispGuiConf.dispDockAllocationPlan?.rangeVal?.map((dateString: any) =>
                getDateMoment(dateString).toDate(),
            ) as [Date, Date];
    }
    return dispGuiConf;
};

export const setDispatcherGuiConf = async (conf: any): Promise<AxiosResponse<any>> => {
    return await axios.patch("/dispatcher/setGuiConf", conf);
};

export const getDocksGuiConf = async (): Promise<GuiConfDocks> => {
    return (await axios.get("/docks/getGuiConf")).data;
};

export const setDocksGuiConf = async (conf: any): Promise<AxiosResponse<any>> => {
    return await axios.patch("/docks/setGuiConf", conf);
};
