import { Button, Dialog, IconButton, Tooltip } from "@mui/material";
import { useMimeTypeMetadataRender } from "../../hooks/useMimeTypeMetadataRender";
import { useIntl } from "react-intl";
import Messages from "../../../../localization/Messages";
import { getUrlForDownload } from "../../../../api/document";
import { useDispatch } from "react-redux";
import { useCallback, useState } from "react";
import { showBackendMessage } from "../../../../helpers/messagesHelper";
import { DocumentPreview } from "../DocumentPreview";
import { Close, Preview } from "@mui/icons-material";
import "./index.css";

export type DocumentPreviewButtonProps = {
    name: string;
    path: string;
    mimeType: string;
};

export function DocumentPreviewButton({ name, path, mimeType }: DocumentPreviewButtonProps) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { preview } = useMimeTypeMetadataRender(mimeType);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState("");

    const handleFetchDocumentUrl = useCallback(async () => {
        try {
            const res = await getUrlForDownload(name, path, false);
            setDownloadUrl(res.data);
            setDialogOpen(true);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.document));
        }
    }, [name, path, dispatch, intl]);

    if (!preview) return <UnsupportedPreviewButton />;

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={handleFetchDocumentUrl}
                disabled={!path}
                startIcon={<Preview />}
            >
                {intl.formatMessage(Messages.preview)}
            </Button>
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <div className="certificate-modal">
                    <div className="certificate-modal-content">
                        <DocumentPreview src={downloadUrl} mimeType={mimeType} />
                        <div className="certificate-modal-close-button">
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={() => setDialogOpen(false)}
                                aria-label="close"
                            >
                                <Close />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

const UnsupportedPreviewButton = () => {
    const intl = useIntl();
    return (
        <Tooltip title={intl.formatMessage(Messages.fileFormatNotSupported)} arrow>
            <span>
                <Button variant="outlined" color="primary" disabled>
                    {intl.formatMessage(Messages.preview)}
                </Button>
            </span>
        </Tooltip>
    );
};
