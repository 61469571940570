import moment from "moment";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { AppState } from "../store/configureStore";
import { Locale } from "../models/userData";
import de from "../localization/compiled-lang/de.json";
import en from "../localization/compiled-lang/en.json";
import fr from "../localization/compiled-lang/fr.json";

/**
 * The default locale.
 */
const DEFAULT_LOCALE = Locale.de;

/**
 * A mapping of locales to Moment.js locale strings.
 */
const MOMENT_LOCALE_MAPPER: Record<Locale, string> = {
    [Locale.de]: "de",
    [Locale.en]: "en-gb",
    [Locale.fr]: "fr",
};

/**
 * A mapping of locales to message objects.
 */
const MESSAGES_LOCALE_MAPPER: Record<Locale, Record<string, string>> = {
    [Locale.de]: de,
    [Locale.en]: en,
    [Locale.fr]: fr,
};

/**
 * Props for the LanguageChange component.
 */
type LanguageChangeProps = {
    locale: string;
    messages: Record<string, string>;
};

/**
 * Sets the Moment.js locale based on the given locale.
 * @param locale - The locale to set.
 */
function setMomentLocale(locale: Locale) {
    moment.locale(MOMENT_LOCALE_MAPPER[locale]);
}

/**
 * The LanguageChange component.
 * @param user - The user state from the Redux store.
 * @returns The locale and messages for the IntlProvider.
 */
const LanguageChange = ({ user }: AppState): LanguageChangeProps => {
    const localeIndex = user.locale ?? DEFAULT_LOCALE;
    const locale = Locale[localeIndex];
    const messages = MESSAGES_LOCALE_MAPPER[localeIndex];
    setMomentLocale(localeIndex);
    return { locale, messages };
};

/**
 * Provides a connected IntlProvider that changes the language based on the user's locale.
 */
export const ConnectedIntlProvider = connect(LanguageChange)(IntlProvider);
