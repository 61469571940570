import { Box } from "@mui/material";
import BookingDetails from "../../../../ManageExpenses/components/BookingDetails/BookingDetails";
import ExpensesInput from "../../../../ManageInvoices/components/ExpensesInput/ExpensesInput";
import { IBooking } from "../../../../../models/booking";
import { isInvoiceEditable } from "../../../../../models/invoice";
import { useIntl } from "react-intl";
import Messages from "../../../../../localization/Messages";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { ServiceSection } from "../../../../DispManageServices/components/ServiceSection/ServiceSection";
import { AppState } from "../../../../../store/configureStore";
import { useSidebarOwner } from "../../../../../contexts/SidebarOwnerContext";

type OCBookingMenuProps = {
    onAfterFinalize: () => void;
    onUpdateActualTimes?: () => void;
};

export const OCBookingMenu = ({ onAfterFinalize, onUpdateActualTimes }: OCBookingMenuProps) => {
    const [selectedBooking] = useSidebarOwner<IBooking>();
    const intl = useIntl();

    const serverSettings = useSelector((s: AppState) => s.serverSettings.data);
    const isFromBasel = serverSettings.userFromBasel;

    const selectedInvoiceId = selectedBooking?.invoices?.length
        ? selectedBooking?.invoices[0]?.id
        : null;

    const disabledAddTooltip = isInvoiceEditable(selectedBooking?.invoices?.[0]?.invoiceStatus)
        ? ""
        : intl.formatMessage(Messages.invoiceIsNotEditable);

    // A hacky way to force refetch of Expenses inside ExpensesInput component.
    // This is needed because ExpensesInput component only fetches the expenses upon mount.
    // In this case, we need to refetch the expenses when the booking is finalized (or de-finalized).
    // This event triggers the creation and deletion of expenses on backend side.
    const [counter, setCounter] = useState(0);

    const forceExpensesInputRerender = useCallback(() => setCounter(prev => prev + 1), []);

    const onFinalizeComplete = () => {
        forceExpensesInputRerender();
        onAfterFinalize();
    };

    return selectedBooking ? (
        <Box display={"flex"} flexDirection={"column"}>
            <BookingDetails
                booking={selectedBooking}
                onUpdateActualTimes={onUpdateActualTimes}
                onFinalizeComplete={onFinalizeComplete}
            />
            {selectedInvoiceId && (
                <ExpensesInput
                    // This is a hack to force rerender of ExpensesInput component manually.
                    // The trick is to create a unique identification key from the component's dependencies
                    // and then append a custom counter to it. Whenever the counter variable changes
                    // the key changes and the component is rerendered.
                    key={`${disabledAddTooltip}-${selectedInvoiceId + counter}`}
                    disabledAddTooltip={disabledAddTooltip}
                    invoiceId={selectedInvoiceId}
                />
            )}
            {isFromBasel && <ServiceSection />}
        </Box>
    ) : (
        <></>
    );
};
