import { SvgIcon } from "@mui/material";

export default function AudioIcon() {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <path
                    fill="#ef5350"
                    d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2Zm6 10h-4v8a4 4 0 1 1-4-4 3.959 3.959 0 0 1 2 .555V8h6Z"
                />
            </svg>
        </SvgIcon>
    );
}
