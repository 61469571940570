import { ReactNode } from "react";
import IconButton from "@mui/material/IconButton";
import { Box, Drawer, Typography } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import "./SidebarDrawer.css";

export type SidebarDrawerProps = {
    title: ReactNode;
    open: boolean;
    anchor: "left" | "right";
    children: ReactNode;
    width?: string | number;
    marginTop?: string | number;
    variant?: "temporary" | "persistent";
    onClose?: () => void;
};

/**
 * SidebarDrawer component
 *
 * This component is used for pages with a sidebar layout. It provides a sidebar that can be opened and closed, typically used for displaying form components.
 *
 * Props:
 * - `title`: A `ReactNode` that represents the title of the sidebar.
 * - `open`: A boolean that determines whether the sidebar is open or not.
 * - `anchor`: A string that specifies the anchor of the sidebar. It can be either "left" or "right".
 * - `children`: The `ReactNode` elements to be displayed within the sidebar.
 * - `width`: The width of the sidebar. It can be either a string or a number. Defaults to 450.
 * - `marginTop`: The top margin of the sidebar. It can be either a string or a number. Defaults to "var(--header-height)".
 * - `variant`: The variant of the sidebar. It can be either "temporary" or "persistent". Defaults to "temporary".
 * - `onClose`: A function that is called when the sidebar is closed.
 *
 * The sidebar includes a title and a close button at the top. The `children` prop is used to render the content of the sidebar.
 *
 * The `open` prop controls whether the sidebar is open or not. When `open` is `true`, the sidebar is visible. When `open` is `false`, the sidebar is hidden.
 *
 * The `onClose` prop is a function that is called when the close button is clicked. This function should set the `open` prop to `false` to close the sidebar.
 */

export function SidebarDrawer({
    title,
    anchor,
    open,
    onClose = () => {},
    width = 450,
    marginTop = "var(--header-height)",
    variant = "temporary",
    children,
}: SidebarDrawerProps) {
    const computedWidth = typeof width === "number" ? `${width}px` : width;
    const computedMarginTop = typeof marginTop === "number" ? `${marginTop}px` : marginTop;

    return (
        <Drawer
            PaperProps={{
                style: {
                    marginTop: computedMarginTop,
                    maxWidth: computedWidth,
                    width: "100%",
                    maxHeight: "calc(100vh - var(--header-height))",
                },
            }}
            BackdropProps={{
                style: { marginTop: computedMarginTop },
            }}
            anchor={anchor}
            open={open}
            onClose={onClose}
            variant={variant}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap={2}
                paddingRight={2}
                paddingLeft={3}
                paddingTop={2}
            >
                <Typography width="100%" variant="h5">
                    {title}
                </Typography>
                <IconButton onClick={onClose}>
                    <Cancel />
                </IconButton>
            </Box>
            <Box display="flex" flex={1} flexDirection="column" padding={2} overflow="auto">
                {children}
            </Box>
        </Drawer>
    );
}
