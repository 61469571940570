import axios, { AxiosResponse } from "axios";
import { ApiSearchResponse } from "../models/common";
import { IAddEditMessage, IMessage } from "../models/message";
import { ISearchParams, searchParamsInit } from "../models/searchParams";

export const addMessage = async (dto: IAddEditMessage): Promise<AxiosResponse<IMessage>> => {
    return await axios.put("/docks/addMessage", dto);
};

export const addMessageDisp = async (dto: IAddEditMessage): Promise<AxiosResponse<IMessage>> => {
    return await axios.put("/dispatcher/addMessage", dto);
};

export const searchMessage = async (
    searchParams: ISearchParams = searchParamsInit,
): ApiSearchResponse<IMessage> => {
    return await axios.post("/docks/searchMessage", searchParams);
};

export const searchMessageDisp = async (
    searchParams: ISearchParams = searchParamsInit,
): ApiSearchResponse<IMessage> => {
    return await axios.post("/dispatcher/searchMessage", searchParams);
};
