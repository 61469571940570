import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { IAddEditShip, addEditShipInit } from "../../models/ship";

import { Moment } from "moment";

import { useDispatch } from "react-redux";
import { addShip, changeShip, deleteShip } from "../../api/ship";
import { showBackendMessage } from "../../helpers/messagesHelper";
import Messages from "../../localization/Messages";

import RpisDialog from "../RpisDialog/RpisDialog";
import UploadCertificate from "./UploadCertificate";

import "./LDManageFleet.css";

import { getUrlForDownloadLD } from "../../api/document";
import { DateFormat, getDateText } from "../../helpers/dateHelper";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import { countryCodes } from "../../localization/country-names/countryNames";
import { IAddress } from "../../models/address";
import {
    TextField,
    Autocomplete,
    InputAdornment,
    FormControlLabel,
    Checkbox,
    Divider,
    Button,
    Tooltip,
    Alert,
    Modal,
    IconButton,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Close, Delete } from "@mui/icons-material";

type ShipErrors = {
    [Key in keyof Partial<IAddEditShip>]: string;
};

const validationRegex = {
    eni: /^\d{8}$/,
    mmsi: /^\d{9}$/,
    email: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
    tel: /^\+?[0-9-/ ]*$/,
};

export interface IVesselForm {
    selectedShip: IAddEditShip | undefined;
    callback: () => void;
    isDisabled: boolean;
    owningCompanies: IAddress[];
}

/**
 * Represents a form for adding, editing or deleting vessels in LD Manage Fleet page.
 *
 * @component
 * @param {IAddEditShip | undefined} selectedShip - The selected ship object to edit, or undefined for creating a new ship.
 * @param {() => void} callback - A callback function to be called after successful submission or deletion of the ship.
 * @param {boolean} isDisabled - Indicates whether the form fields should be disabled.
 * @param {IAddress[]} owningCompanies - An array of owning company addresses for the ship.
 * @returns {JSX.Element} The rendered VesselForm component.
 */
export const VesselForm = ({
    selectedShip,
    callback,
    isDisabled,
    owningCompanies,
}: IVesselForm) => {
    const withConfirm = useConfirmDialog();
    const intl = useIntl();
    const dispatch = useDispatch();
    const [ship, setShip] = useState(addEditShipInit);
    const [errors, setErrors] = useState<ShipErrors>({});
    const [certificateDialogOpen, setCertificateDialogOpen] = useState(false);
    const [certificateModalOpen, setCertificateModalOpen] = useState(false);
    const [certificateDownloadUrl, setCertificateDownloadUrl] = useState<string>("");

    const onChange = (diff: Partial<IAddEditShip>) => {
        setShip({ ...ship, ...diff });
        const clearErros = Object.fromEntries(Object.entries(diff).map(([k]) => [k, undefined]));
        setErrors({ ...errors, ...clearErros });
    };

    const parseNumericOrNull = (input: string, decimal: boolean) => {
        const value = decimal ? Number.parseFloat(input) : Number.parseInt(input);
        return isNaN(value) ? null : decimal ? parseFloat(value.toFixed(2)) : value;
    };

    const validateShip = () => {
        const newErrors: ShipErrors = {};
        if (!ship.name) {
            newErrors.name = intl.formatMessage(Messages.shipNameIsRequired);
        }

        if (ship.eni && !validationRegex.eni.test(ship.eni)) {
            newErrors.eni = intl.formatMessage(Messages.eniIsIncorrect);
        }
        if (ship.mmsi && !validationRegex.mmsi.test(ship.mmsi)) {
            newErrors.mmsi = intl.formatMessage(Messages.mmsiIsIncorrect);
        }
        if (!ship.length) {
            newErrors.length = intl.formatMessage(Messages.lengthIsRequired);
        }
        if (!ship.validFrom) {
            newErrors.validFrom = intl.formatMessage(Messages.validFromIsRequired);
        }
        if (!ship.validTo) {
            newErrors.validTo = intl.formatMessage(Messages.validaToIsRequired);
        }
        if (
            ship.validTo &&
            ship.validFrom &&
            getDateText(ship.validFrom, DateFormat.API_DATE) >=
                getDateText(ship.validTo, DateFormat.API_DATE)
        ) {
            newErrors.validTo = intl.formatMessage(Messages.shipValidToError);
        }
        if (!ship.email) {
            newErrors.email = intl.formatMessage(Messages.emailIsRequired);
        } else if (!validationRegex.email.test(ship.email)) {
            newErrors.email = intl.formatMessage(Messages.emailInvalid);
        }
        if (!ship.owningCompanyAddressId) {
            newErrors.owningCompanyAddressId = intl.formatMessage(Messages.owningCompanyIsRequired);
        }
        if (ship.validFrom && isNaN(parseInt(ship.validFrom))) {
            newErrors.validFrom = intl.formatMessage(Messages.wrongDateFormat);
        }
        if (ship.validTo && isNaN(parseInt(ship.validTo))) {
            newErrors.validTo = intl.formatMessage(Messages.wrongDateFormat);
        }
        if (ship.certExpireDate && isNaN(parseInt(ship.certExpireDate))) {
            newErrors.certExpireDate = intl.formatMessage(Messages.wrongDateFormat);
        }

        return newErrors;
    };

    const handleSubmit = async () => {
        const newErrors = validateShip();
        if (Object.keys(newErrors).length) {
            setErrors(newErrors);
            return;
        }
        try {
            if (selectedShip) {
                await changeShip(ship);
                dispatch(showBackendMessage(intl, "success", "updating", Messages.ship));
            } else {
                await addShip(ship);
                dispatch(showBackendMessage(intl, "success", "creating", Messages.ship));
            }
            callback();
            onChange(addEditShipInit);
        } catch {
            dispatch(
                showBackendMessage(
                    intl,
                    "error",
                    selectedShip ? "updating" : "creating",
                    Messages.ship,
                ),
            );
        }
    };

    useEffect(() => {
        setShip(selectedShip ? { ...selectedShip } : addEditShipInit);
    }, [selectedShip]);

    const handleGetDocumentUrl = async () => {
        if (!ship.shipCertificate) return;
        try {
            const res = await getUrlForDownloadLD(
                ship.shipCertificate.name,
                ship.shipCertificate.path,
                false,
            );
            setCertificateDownloadUrl(res.data);
            setCertificateModalOpen(true);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.document));
        }
    };

    const handleDeleteShip = async () => {
        try {
            await deleteShip(selectedShip!.id);
            dispatch(showBackendMessage(intl, "success", "deleting", Messages.ship));
            callback();
        } catch {
            dispatch(showBackendMessage(intl, "error", "deleting", Messages.ship));
        }
    };

    return (
        <div className="vessel-form">
            <form className="column-form two-column-form">
                <TextField
                    required
                    error={Boolean(errors.name)}
                    value={ship.name}
                    label={intl.formatMessage(Messages.shipName)}
                    onChange={e => onChange({ name: e.target.value.toUpperCase() })}
                    helperText={errors.name}
                    variant="filled"
                    InputProps={{ readOnly: isDisabled }}
                />
                <TextField
                    error={Boolean(errors.eni)}
                    value={ship.eni ?? ""}
                    label={intl.formatMessage(Messages.eni)}
                    onChange={e => onChange({ eni: e.target.value })}
                    variant="filled"
                    helperText={errors.eni}
                    inputProps={{
                        inputMode: "numeric",
                        maxLength: 8,
                        pattern: "[0-8]*",
                    }}
                    InputProps={{ readOnly: isDisabled }}
                />
                <TextField
                    error={Boolean(errors.mmsi)}
                    value={ship.mmsi ?? ""}
                    label={intl.formatMessage(Messages.mmsi)}
                    onChange={e => onChange({ mmsi: e.target.value })}
                    variant="filled"
                    helperText={errors.mmsi}
                    inputProps={{
                        inputMode: "numeric",
                        maxLength: 9,
                        pattern: "[0-9]*",
                    }}
                    InputProps={{ readOnly: isDisabled }}
                />
                <Autocomplete
                    id="select-country-field"
                    options={countryCodes}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="filled"
                            label={intl.formatMessage(Messages.flag)}
                        />
                    )}
                    renderOption={(props, option) => <li {...props}>{option}</li>}
                    onChange={(_, value: string | null) => onChange({ flag: value ?? undefined })}
                    value={countryCodes.find(code => code === ship.flag) ?? null}
                />
                <TextField
                    error={Boolean(errors.regPort)}
                    value={ship.regPort ?? ""}
                    label={intl.formatMessage(Messages.regPort)}
                    onChange={e => onChange({ regPort: e.target.value })}
                    variant="filled"
                    helperText={errors.regPort}
                    InputProps={{ readOnly: isDisabled }}
                />
                <TextField
                    required
                    type="number"
                    error={Boolean(errors.length)}
                    value={ship.length ?? ""}
                    label={intl.formatMessage(Messages.length)}
                    onChange={e => onChange({ length: parseFloat(e.target.value) })}
                    onBlur={e =>
                        onChange({
                            length: parseNumericOrNull(e.target.value, true),
                        })
                    }
                    variant="filled"
                    helperText={errors.length}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">m</InputAdornment>,
                        readOnly: isDisabled,
                    }}
                />
                <TextField
                    type="number"
                    value={ship.width ?? ""}
                    label={intl.formatMessage(Messages.width)}
                    onChange={e => onChange({ width: parseFloat(e.target.value) })}
                    onBlur={e =>
                        onChange({
                            width: parseNumericOrNull(e.target.value, true),
                        })
                    }
                    variant="filled"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">m</InputAdornment>,
                        readOnly: isDisabled,
                    }}
                />
                <TextField
                    type="number"
                    value={ship.portalWidth ?? ""}
                    label={intl.formatMessage(Messages.portalWidth)}
                    onChange={e => onChange({ portalWidth: parseFloat(e.target.value) })}
                    onBlur={e =>
                        onChange({
                            portalWidth: parseNumericOrNull(e.target.value, true),
                        })
                    }
                    variant="filled"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">m</InputAdornment>,
                        readOnly: isDisabled,
                    }}
                />
                <TextField
                    type="number"
                    value={ship.entranceHeight ?? ""}
                    label={intl.formatMessage(Messages.entranceHeight)}
                    onChange={e => onChange({ entranceHeight: parseFloat(e.target.value) })}
                    onBlur={e =>
                        onChange({
                            entranceHeight: parseNumericOrNull(e.target.value, true),
                        })
                    }
                    variant="filled"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">m</InputAdornment>,
                        readOnly: isDisabled,
                    }}
                />
                <TextField
                    type="number"
                    value={ship.entranceHeight2 ?? ""}
                    label={intl.formatMessage(Messages.entranceHeight2)}
                    onChange={e =>
                        onChange({
                            entranceHeight2: parseFloat(e.target.value),
                        })
                    }
                    onBlur={e =>
                        onChange({
                            entranceHeight2: parseNumericOrNull(e.target.value, true),
                        })
                    }
                    variant="filled"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">m</InputAdornment>,
                        readOnly: isDisabled,
                    }}
                />
                <DesktopDatePicker
                    label={intl.formatMessage(Messages.validFrom)}
                    inputFormat="DD/MM/YYYY"
                    value={ship.validFrom ? getDateText(ship.validFrom, DateFormat.API_DATE) : null}
                    onChange={(value: Moment | null) => {
                        onChange({
                            validFrom: value?.format(DateFormat.API_DATE) ?? null,
                        });
                    }}
                    renderInput={params => (
                        <TextField
                            required
                            variant="filled"
                            helperText={errors.validFrom}
                            {...params}
                            error={Boolean(errors.validFrom)}
                        />
                    )}
                    InputProps={{ readOnly: isDisabled }}
                />
                <DesktopDatePicker
                    label={intl.formatMessage(Messages.validTo)}
                    inputFormat="DD/MM/YYYY"
                    value={ship.validTo ? getDateText(ship.validTo, DateFormat.API_DATE) : null}
                    onChange={(value: Moment | null) => {
                        onChange({
                            validTo: value?.format(DateFormat.API_DATE) ?? null,
                        });
                    }}
                    renderInput={params => (
                        <TextField
                            required
                            variant="filled"
                            helperText={errors.validTo}
                            {...params}
                            error={Boolean(errors.validTo)}
                        />
                    )}
                    InputProps={{ readOnly: isDisabled }}
                />
                <TextField
                    value={ship.tel1}
                    label={intl.formatMessage(Messages.onBoardTelephone1)}
                    onChange={e => {
                        validationRegex.tel.test(e.target.value) &&
                            onChange({ tel1: e.target.value });
                    }}
                    variant="filled"
                    InputProps={{ readOnly: isDisabled }}
                />
                <TextField
                    value={ship.tel2}
                    label={intl.formatMessage(Messages.onBoardTelephone2)}
                    onChange={e => {
                        validationRegex.tel.test(e.target.value) &&
                            onChange({ tel2: e.target.value });
                    }}
                    variant="filled"
                    InputProps={{ readOnly: isDisabled }}
                />
                <TextField
                    type="email"
                    required
                    error={Boolean(errors.email)}
                    value={ship.email}
                    label={intl.formatMessage(Messages.onBoardEmail)}
                    onChange={e => onChange({ email: e.target.value })}
                    variant="filled"
                    helperText={errors.email}
                    InputProps={{ readOnly: isDisabled }}
                />
                <FormControlLabel
                    label={intl.formatMessage(Messages.onBoardSewage)}
                    control={
                        <Checkbox
                            checked={ship.onBoardSewage?.toUpperCase() === "YES"}
                            onChange={e =>
                                onChange({
                                    onBoardSewage: e.target.checked ? "YES" : "NO",
                                })
                            }
                            disabled={isDisabled}
                        />
                    }
                />
                <Autocomplete
                    id="select-owning-company"
                    options={owningCompanies}
                    getOptionLabel={o => o.company ?? ""}
                    isOptionEqualToValue={(a, b) => a.id === b.id}
                    renderInput={params => (
                        <TextField
                            required
                            variant="filled"
                            {...params}
                            label={intl.formatMessage(Messages.owningCompany)}
                            error={Boolean(errors.owningCompanyAddressId)}
                            helperText={errors.owningCompanyAddressId}
                        />
                    )}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.company}
                            </li>
                        );
                    }}
                    onChange={(_, value: IAddress | null) =>
                        onChange({ owningCompanyAddressId: value?.id })
                    }
                    value={
                        owningCompanies.find(add => add.id === ship.owningCompanyAddressId) ?? null
                    }
                />
            </form>
            <Divider />
            <br />
            <p>{intl.formatMessage(Messages.shipCertificate)}</p>
            <form className="column-form">
                <DesktopDatePicker
                    label={intl.formatMessage(Messages.certExpireDate)}
                    inputFormat={DateFormat.CLIENT_DATE}
                    value={
                        ship.certExpireDate
                            ? getDateText(ship.certExpireDate, DateFormat.API_DATE)
                            : null
                    }
                    onChange={(value: Moment | null) => {
                        onChange({
                            certExpireDate: value?.format(DateFormat.API_DATE) ?? null,
                        });
                    }}
                    renderInput={params => (
                        <TextField
                            variant="filled"
                            helperText={errors.certExpireDate}
                            {...params}
                        />
                    )}
                    InputProps={{ readOnly: isDisabled }}
                />
                <div className="form-certificate-buttons">
                    <Button
                        variant="outlined"
                        id="upload-certificate-button"
                        onClick={() => setCertificateDialogOpen(true)}
                    >
                        {intl.formatMessage(Messages.uploadCertificate)}
                    </Button>
                    {!ship.shipCertificate ||
                    ship.shipCertificate.mimeType === "application/pdf" ? (
                        <Button
                            id="upload-certificate-button"
                            variant="outlined"
                            onClick={handleGetDocumentUrl}
                            disabled={!(ship.shipCertificate && ship.shipCertificate.path)}
                        >
                            {intl.formatMessage(Messages.previewCertificate)}
                        </Button>
                    ) : (
                        <Tooltip title={intl.formatMessage(Messages.fileFormatNotSupported)} arrow>
                            <span>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    disabled
                                    className="preview-button"
                                >
                                    {intl.formatMessage(Messages.previewCertificate)}
                                </Button>
                            </span>
                        </Tooltip>
                    )}
                </div>
                {errors.shipCertificate && (
                    <Alert severity="error">
                        {intl.formatMessage(Messages.certificateNotUploaded)}
                    </Alert>
                )}
                <Modal
                    open={certificateModalOpen}
                    onClose={() => setCertificateModalOpen(false)}
                    aria-labelledby="certificate-preview-modal"
                    aria-describedby="certificate-preview-description"
                >
                    <div className="certificate-modal">
                        <div className="certificate-modal-content">
                            <iframe
                                title={ship.shipCertificate?.name}
                                src={certificateDownloadUrl}
                                width="100%"
                                height="100%"
                                name={ship.shipCertificate?.name}
                            ></iframe>
                            <div className="certificate-modal-close-button">
                                <IconButton
                                    edge="end"
                                    color="inherit"
                                    onClick={() => setCertificateModalOpen(false)}
                                    aria-label="close"
                                >
                                    <Close />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </Modal>
            </form>
            {!isDisabled && (
                <Button
                    variant="contained"
                    className="submit-vessel-form-button button-primary"
                    onClick={handleSubmit}
                >
                    {selectedShip
                        ? intl.formatMessage(Messages.save)
                        : intl.formatMessage(Messages.saveNewVessel)}
                </Button>
            )}
            {selectedShip && (
                <Button
                    className="delete-ship-button"
                    variant="contained"
                    color="error"
                    startIcon={<Delete />}
                    onClick={() =>
                        withConfirm({
                            message: intl.formatMessage(Messages.confirmShipDeleteText),
                            onConfirm: handleDeleteShip,
                        })
                    }
                >
                    {intl.formatMessage(Messages.delete)}
                </Button>
            )}
            <RpisDialog
                dialogOpen={certificateDialogOpen}
                title={intl.formatMessage(Messages.uploadCertificate)}
                onClose={() => setCertificateDialogOpen(false)}
                size="md"
                fullWidth={false}
                content={<UploadCertificate ship={ship} setShipDocument={setShip} />}
            />
        </div>
    );
};

export default VesselForm;
