import { useIntl } from "react-intl";
import RpisPage from "../RpisPage/RpisPage";
import ChangeLogElem from "./../../components/ChangeLog";
import Messages from "../../localization/Messages";
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { searchParamsInit } from "../../models/searchParams";
import { useCallback, useEffect, useState } from "react";
import { ChangeLogAction, ChangeLogEntityOptions, IChangeLog } from "../../models/changeLog";
import { getChangeLogUsers, searchChangeLog } from "../../api/changeLog";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { TableSkeleton } from "../../components/TableSkeleton/TableSkeleton";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { CalendarMonthOutlined, Key, PersonOutline } from "@mui/icons-material";
import { AppState } from "../../store/configureStore";
import { DateFormat, getDateMoment, getDateText } from "../../helpers/dateHelper";
import TableCellSorted from "../../components/TableCellSorted";
import { SortCriteria, SortDirection, SortOrder, useTableSort } from "../../hooks/useTableSort";
import { DatatablePaginationFilters, useGuiConfState } from "../../hooks/useGuiConfState";
import {
    Autocomplete,
    Box,
    Button,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    TablePagination,
    TextField,
} from "@mui/material";
import "./ChangeLog.css";
import { useCachedDocks } from "../../hooks/useCachedDocks";

export type ChangeLogFilters = DatatablePaginationFilters & {
    user: string;
    dateFrom: Date | null;
    dateTo: Date | null;
    entity: string;
    order: SortOrder;
};

function buildFilters(filters: ChangeLogFilters) {
    const filterArray: object[] = [];

    if (filters.dateFrom) {
        const startDate = getDateMoment(filters.dateFrom).toDate();
        startDate.setHours(0, 0, 0, 0);
        filterArray.push({ timestamp: { $gte: startDate.getTime() } });
    }
    if (filters.dateTo) {
        const endDate = getDateMoment(filters.dateTo).toDate();
        endDate.setHours(23, 59, 59, 59);
        filterArray.push({ timestamp: { $lte: endDate.getTime() } });
    }
    if (filters.user) {
        filterArray.push({ userId: filters.user });
    }
    if (filters.entity) {
        // TODO - Refactor this later
        const buildEntityValue = () =>
            filters.entity
                .split(" ")
                .map(str => `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`)
                .join("");
        filterArray.push({ entity: buildEntityValue() });
    }

    return { $and: filterArray };
}

function buildPayload(filters: ChangeLogFilters) {
    return {
        ...searchParamsInit,
        offset: filters.page * filters.rowsPerPage,
        limit: filters.rowsPerPage,
        order: filters.order,
        columns: {},
        filter: buildFilters(filters),
    };
}

const CHANGE_LOG_SORTABLE_COLUMNS = ["entity", "timestamp", "userId"] as const;

/**
 * The component represents the Change Log page.
 * Change log page displays all the changed entities in a table format.
 * Each row has an action "view change" which opens a dialog that displays changes in a tree view.
 */

export const ChangeLog = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [changeLog, setChangeLog] = useState<IChangeLog[]>([]);
    const [totalChangeLogEntries, setTotalChangeLogEntries] = useState(0);
    const [users, setUsers] = useState<string[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [selectedChangeLog, setSelectedChangeLog] = useState<IChangeLog | undefined>();
    const [filters, setFilters] = useGuiConfState("dispChangeLog");
    const registerSort = useTableSort<typeof CHANGE_LOG_SORTABLE_COLUMNS>({
        columns: CHANGE_LOG_SORTABLE_COLUMNS,
        value: filters.order as SortCriteria<string, SortDirection>[],
        onChange: order => setFilters({ order }),
    });

    const fetchChangeLogs = useCallback(async () => {
        try {
            const res = await searchChangeLog(buildPayload(filters));
            setChangeLog(res.data.rows ?? []);
            setTotalChangeLogEntries(res.data.total ?? 0);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.changeLog));
        }
    }, [filters, dispatch, intl]);

    useEffect(() => {
        fetchChangeLogs();
    }, [fetchChangeLogs]);

    useEffect(() => {
        const fetchChangeLogUsers = async () => {
            try {
                const res = await getChangeLogUsers();
                setUsers(res.data ?? []);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.users));
            }
        };
        fetchChangeLogUsers();
    }, [dispatch, intl]);

    const handleChangePage = (_event: unknown, newPage: number) => {
        setFilters({ page: newPage });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilters({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
        });
    };

    const clearFilters = () => {
        setFilters({
            dateFrom: null,
            dateTo: null,
            user: undefined,
            entity: undefined,
        });
    };

    return (
        <RpisPage title={intl.formatMessage(Messages.changeLog)} className="change-log-container">
            <div className="change-log-actions-container">
                <Autocomplete
                    id="ship-autocomplete"
                    options={users}
                    isOptionEqualToValue={(a, b) => a === b}
                    renderInput={params => (
                        <TextField
                            variant="filled"
                            {...params}
                            label={intl.formatMessage(Messages.user)}
                        />
                    )}
                    renderOption={(props, option) => <li {...props}>{option}</li>}
                    onChange={(_, value: string | null) => setFilters({ user: value ?? undefined })}
                    value={users.find(u => u === filters.user) ?? null}
                />
                <DesktopDatePicker
                    InputProps={{ sx: { maxWidth: 200 } }}
                    label={intl.formatMessage(Messages.from)}
                    inputFormat={DateFormat.CLIENT_DATE}
                    value={filters.dateFrom}
                    onChange={value => setFilters({ dateFrom: value })}
                    renderInput={params => <TextField variant="filled" {...params} />}
                />
                <DesktopDatePicker
                    InputProps={{ sx: { maxWidth: 200 } }}
                    label={intl.formatMessage(Messages.to)}
                    inputFormat={DateFormat.CLIENT_DATE}
                    value={filters.dateTo}
                    onChange={value => setFilters({ dateTo: value })}
                    renderInput={params => <TextField variant="filled" {...params} />}
                />
                <Autocomplete
                    id="ship-autocomplete"
                    options={ChangeLogEntityOptions}
                    isOptionEqualToValue={(a, b) => a === b}
                    renderInput={params => (
                        <TextField
                            variant="filled"
                            {...params}
                            label={intl.formatMessage(Messages.entityType)}
                        />
                    )}
                    renderOption={(props, option) => <li {...props}>{option}</li>}
                    onChange={(_, value: string | null) =>
                        setFilters({ entity: value! ?? undefined })
                    }
                    value={ChangeLogEntityOptions.find(e => e === filters.entity) ?? null}
                />
                <Box display="flex" gap={2} justifyContent="space-between" flex="1">
                    <Box display="flex" gap={2} flex="1">
                        {(filters.dateFrom || filters.dateTo || filters.user || filters.entity) && (
                            <Button variant="outlined" onClick={clearFilters}>
                                {intl.formatMessage(Messages.clear)}
                            </Button>
                        )}
                    </Box>
                </Box>
            </div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCellSorted
                                label={intl.formatMessage(Messages.entityType)}
                                {...registerSort("entity")}
                            />
                            <TableCell>{intl.formatMessage(Messages.actionType)}</TableCell>
                            <TableCellSorted
                                label={intl.formatMessage(Messages.dateAndTimeOfAction)}
                                {...registerSort("timestamp")}
                            />
                            <TableCellSorted
                                label={intl.formatMessage(Messages.user)}
                                {...registerSort("userId")}
                            />
                            <TableCell align="center">
                                {intl.formatMessage(Messages.action)}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {changeLog.length ? (
                        <TableBody>
                            {changeLog.map(row => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.entity}</TableCell>
                                    <TableCell>
                                        {row.action
                                            ? intl.formatMessage(ChangeLogAction[row.action])
                                            : ""}
                                    </TableCell>
                                    <TableCell>
                                        {getDateText(row.timestamp, DateFormat.CLIENT_DATE_TIME)}
                                    </TableCell>
                                    <TableCell>{row.userId}</TableCell>
                                    <TableCell align="center">
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                setSelectedChangeLog(row);
                                                setOpen(true);
                                            }}
                                        >
                                            {intl.formatMessage(Messages.viewChange)}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    ) : (
                        <TableSkeleton columns={5} rows={filters.rowsPerPage} />
                    )}
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalChangeLogEntries}
                rowsPerPage={filters.rowsPerPage}
                page={filters.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog fullWidth maxWidth="lg" open={open} onClose={() => setOpen(false)}>
                <DialogTitle>
                    <Box display="flex" alignItems="center" gap="0.5rem">
                        <Chip
                            label={selectedChangeLog?.action}
                            color={
                                selectedChangeLog?.action === "ADD"
                                    ? "success"
                                    : selectedChangeLog?.action === "DELETE"
                                    ? "error"
                                    : "warning"
                            }
                            variant="filled"
                            size="small"
                        />
                        <Chip
                            label={selectedChangeLog?.strId}
                            color={
                                selectedChangeLog?.action === "ADD"
                                    ? "success"
                                    : selectedChangeLog?.action === "DELETE"
                                    ? "error"
                                    : "warning"
                            }
                            variant="outlined"
                            icon={<Key />}
                            size="small"
                        />
                        <Chip
                            icon={<PersonOutline />}
                            label={selectedChangeLog?.userId}
                            color={undefined}
                            variant={undefined}
                            size="small"
                        />
                        <Chip
                            icon={<CalendarMonthOutlined />}
                            label={getDateText(
                                selectedChangeLog?.timestamp,
                                DateFormat.CLIENT_DATE_TIME,
                            )}
                            color={undefined}
                            variant={undefined}
                            size="small"
                        />
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {selectedChangeLog && (
                        <ChangeLogElem
                            name={selectedChangeLog.entity}
                            previous={selectedChangeLog.old}
                            current={selectedChangeLog.value}
                            render={{
                                dockId: dockId => <DockIdRender dockId={dockId} />,
                                owningCompanyAddressId: compId => (
                                    <OwningCompanyAddressIdRender compId={compId} />
                                ),
                                managingCompany: compId => (
                                    <ManagingCompanyIdRender compId={compId} />
                                ),
                            }}
                            exclude={[
                                "bookingId",
                                "companyId",
                                "deleted",
                                "destinationId",
                                "id",
                                "lastModifyBy",
                                "lastModifyTs",
                                "reqArrivalTime",
                                "reqDepartureTime",
                                "reqDockId",
                                "shipName",
                                "shipLength",
                                "shipId",
                                "confirmCount",
                                "createTs",
                                "createBy",
                                "comment",
                                "invoiceAddressId",
                                "contactId",
                            ]}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </RpisPage>
    );
};

const OwningCompanyAddressIdRender = ({ compId }: { compId: string }) => {
    const addresses = useSelector((s: AppState) => s.dispAddresses).data;
    const companyName: string = addresses.get(compId) ?? "";
    return <span className="clog-text">{companyName}</span>;
};

const ManagingCompanyIdRender = ({ compId }: { compId: string }) => {
    const managingCompanies = useSelector((s: AppState) => s.managingCompanies).data;
    const managingCompanyName: string = managingCompanies.get(compId);
    return <span className="clog-text">{managingCompanyName}</span>;
};

const DockIdRender = ({ dockId }: { dockId: string }) => {
    const { byId } = useCachedDocks();
    const dockName: string = byId.get(dockId)?.name ?? "";
    return <span className="clog-text">{dockName}</span>;
};

export default ChangeLog;
