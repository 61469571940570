import { Switch } from "@mui/material";
import "./NotificationsPage.css";
import Messages from "../../localization/Messages";
import { useIntl } from "react-intl";
import RpisPage from "../RpisPage/RpisPage";
import NotificationsContainer from "../../components/NotificationsContainer/NotificationsContainer";
import { useNotifications } from "../../hooks/useNotifications";
import { useLocalStorage } from "../../hooks/useLocalStorage";

/**
 * Represents the Notifications page.
 * This page displays notifications to the user.
 *
 * @component
 * @example
 * return <NotificationsPage />;
 */
export const NotificationsPage = () => {
    const intl = useIntl();
    const { notifications } = useNotifications();
    const [showOnlyUnread, setShowOnlyUnread] = useLocalStorage(
        "showOnlyUnreadNotifications",
        true,
    );

    return (
        <RpisPage title={intl.formatMessage(Messages.notifications)}>
            <div className="notifications-page-container">
                <div className="notifications-wrapper">
                    <div className="title-and-actions">
                        <div className="title-section">
                            <h3>{intl.formatMessage(Messages.notifications)}</h3>
                        </div>
                        <div className="actions-section">
                            <span className="switch-label">
                                {intl.formatMessage(Messages.onlyShowUnread)}
                            </span>
                            <Switch
                                size="small"
                                checked={showOnlyUnread}
                                onChange={() => setShowOnlyUnread(current => !current)}
                            />
                        </div>
                    </div>
                    <NotificationsContainer
                        notifications={
                            showOnlyUnread
                                ? notifications.filter(({ read }) => !read)
                                : notifications
                        }
                    />
                </div>
            </div>
        </RpisPage>
    );
};

export default NotificationsPage;
