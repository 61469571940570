import { Dispatch, SetStateAction, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { IDocument } from "../../models/document";
import { getUrlForUpload, uploadFileToStorage } from "../../api/document";
import Messages from "../../localization/Messages";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { IAddEditShip } from "../../models/ship";
import DocumentsTable from "../../components/DocumentsTable/DocumentsTable";

export interface IUploadCertificate {
    ship: IAddEditShip;
    setShipDocument: Dispatch<SetStateAction<IAddEditShip>>;
}

/**
 * Component for uploading a ship certificate and displaying uploaded documents in a table with a preview feature.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {IAddEditShip} props.ship - The ship object.
 * @param {Dispatch<SetStateAction<IAddEditShip>>} props.setShipDocument - The function to update the ship document.
 * @returns {JSX.Element} The rendered UploadCertificate component.
 */
export const UploadCertificate = ({ ship, setShipDocument }: IUploadCertificate) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const uploadDocument = async (file: File) => {
        if (!file) {
            return;
        }
        try {
            setLoading(true);
            const res = await getUrlForUpload(file);

            await uploadFileToStorage(file, res.data.uploadUrl);
            const document: IDocument = {
                mimeType: file.type,
                lastModify: file.lastModified,
                name: file.name,
                size: file.size,
                path: res.data.path,
            };
            setShipDocument({ ...ship, shipCertificate: document });
        } catch {
            dispatch(showBackendMessage(intl, "error", "adding", Messages.document));
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteCertificate = async () => {
        setShipDocument({ ...ship, shipCertificate: null });
    };

    return (
        <DocumentsTable
            documents={ship.shipCertificate ? [ship.shipCertificate] : []}
            onDelete={handleDeleteCertificate}
            onUpload={uploadDocument}
            loading={loading}
        />
    );
};
export default UploadCertificate;
