import { useMemo } from "react";
import { useIntl } from "react-intl";
import {
    ObjectEnumHolder,
    SelectOption,
    displayEnumValueFromCamelCase,
    displayEnumTextFromSnakeCase,
} from "../helpers/enumHelpers";
import Messages from "../localization/Messages";

/**
 * Builds the default message ID for an enum key.
 * @param enumName - The name of the enum.
 * @param enumKey - The key of the enum.
 * @returns The default message ID.
 */
function buildDefaultId(enumName: string, enumKey: string): keyof typeof Messages {
    return `${displayEnumValueFromCamelCase(enumName)}_${enumKey}` as keyof typeof Messages;
}

/**
 * Builds the default message for an enum key.
 * @param enumRegularKeys - The regular keys of the enum.
 * @param index - The index of the enum key.
 * @returns The default message.
 */
function buildDefaultMessage(enumRegularKeys: string[], index: number) {
    return displayEnumTextFromSnakeCase(enumRegularKeys[index]);
}

/**
 * Custom hook that generates select options for a given enum data.
 * @param singleEnumData - The enum data.
 * @returns An array of select options.
 */
export function useEnumOptions<TEnumData extends ObjectEnumHolder<string>>(
    singleEnumData: TEnumData,
): SelectOption[] {
    const intl = useIntl();

    const selectOptions = useMemo(() => {
        const enumName = Object.keys(singleEnumData)[0];
        const enumObject = (singleEnumData as any)[enumName];
        const enumRegularKeys = Object.keys(enumObject);
        return enumRegularKeys
            .map((enumKey, index) => {
                const id = buildDefaultId(enumName, enumKey);
                const defaultMessage = buildDefaultMessage(enumRegularKeys, index);
                return {
                    value: enumKey,
                    label: intl.formatMessage(Messages[id] ?? { id, defaultMessage }),
                };
            })
            .sort((a, b) => a.label.localeCompare(b.label));
        // singleEnumData is a constant, so it's safe to ignore it in the dependency array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    return selectOptions;
}
