import { Tooltip, Typography } from "@mui/material";
import "./index.css";

export type LineClampProps = {
    text: string;
    clamp: number;
    maxWidth?: string | number;
    hideTooltip?: boolean;
};

export function LineClamp({ text, clamp, maxWidth = "100%", hideTooltip = false }: LineClampProps) {
    const computedLineClamp = clamp < 1 ? 1 : clamp;
    const computedMaxWidth = typeof maxWidth === "number" ? `${maxWidth}px` : (maxWidth ?? "100%");

    return (
        <div className={`clamp clamp--${computedLineClamp}`} style={{ maxWidth: computedMaxWidth }}>
            <Tooltip title={hideTooltip ? undefined : text} enterDelay={500} enterNextDelay={500}>
                <Typography component="p" variant="body2">
                    {text}
                </Typography>
            </Tooltip>
        </div>
    );
}
