import { useCallback, useContext } from "react";
import { ConfirmDialogContext, ConfirmDialogProps } from "../contexts/ConfirmDialogContext";

/**
 * Provides a hook for opening a confirm dialog.
 *
 * @returns A function that opens a confirm dialog.
 */
export function useConfirmDialog(): (props: ConfirmDialogProps) => void {
    const { openDialog } = useContext(ConfirmDialogContext);

    const withConfirm = useCallback((props: ConfirmDialogProps) => openDialog(props), [openDialog]);

    return withConfirm;
}
