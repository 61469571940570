import { combineReducers, compose, createStore } from "redux";
import { UserData } from "../models/userData";
import { userReducer as user } from "./userReducer";
import { toastMessageReducer as toast, ToastMessageState } from "./toastMessageReducer";
import { GuiConfState, guiConfsReducer as guiConfs } from "./guiConfsReducer";
import { SidebarOwnerState, sidebarOwnerReducer as sidebarOwner } from "./sidebarOwnerReducer";
import {
    managingCompaniesReducer as managingCompanies,
    ManagingCompaniesState,
} from "./manageCompaniesReducer";
import { DocksState, docksReducer as docks } from "./docksReducer";
import { DispAddressesState, dispAddressesReducer as dispAddresses } from "./dispAddressReducer";
import {
    DestinationsState,
    destinationZonesReducer as destinationZones,
} from "./destinationReducer";
import {
    ServerSettingsState,
    serverSettingsReducer as serverSettings,
} from "./serverSettingsReducer";

/**
 * Represents the state of the application.
 */
export interface AppState {
    user: UserData;
    toast: ToastMessageState;
    managingCompanies: ManagingCompaniesState;
    guiConfs: GuiConfState;
    docks: DocksState;
    dispAddresses: DispAddressesState;
    destinationZones: DestinationsState;
    sidebarOwner: SidebarOwnerState;
    serverSettings: ServerSettingsState;
}

/**
 * Configures the Redux store with the specified initial state.
 * @param initialState - The initial state of the application.
 * @returns The configured Redux store.
 */
const configureStore = (initialState?: AppState) => {
    const rootReducer = combineReducers<AppState>({
        user,
        toast,
        managingCompanies,
        docks,
        dispAddresses,
        guiConfs,
        destinationZones,
        sidebarOwner,
        serverSettings,
    });

    const enhancers = [];
    const windowIfDefined = typeof window === "undefined" ? null : (window as any);
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const result = createStore(rootReducer, initialState, compose<any>(...enhancers));

    return result;
};

export const setupStore = (initialState?: AppState | undefined) => configureStore(initialState);

export type AppStore = ReturnType<typeof setupStore>;

export const store = configureStore();

export type AppDispatch = typeof store.dispatch;
