export interface IBerthing {
    id: string;
    arrivalTime: number;
    bookingId: number;
    companyId?: string;
    departureTime: number;
    dockId: string;
    regArrivalTime?: number;
    regDepartureTime?: number;
    reqDockId?: string;
}

export interface IAddBerthingParams {
    bookingId: number;
    arrivalTime: number;
    departureTime: number;
    dockId: string;
}

export const berthingInit: IBerthing = {
    id: "",
    arrivalTime: 0,
    bookingId: 0,
    departureTime: 0,
    dockId: "",
};
