import { useIntl } from "react-intl";
import { IInvoice, InvoiceStatus } from "../../../../models/invoice";
import { useCallback, useRef, useState } from "react";
import {
    Button,
    ButtonGroup,
    Menu,
    MenuItem,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import Messages from "../../../../localization/Messages";
import { Tooltip } from "@mui/material";

export type InvoiceMenuButtonGroupProps = {
    row: IInvoice;
    onClick: () => void;
    menuActions: {
        sendToAccounting: (row: IInvoice) => void;
        bookingManagement: (row: IInvoice) => void;
        cancelInvoice: (row: IInvoice) => void;
        deleteInvoice: (row: IInvoice) => void;
        copyCancelledInvoice: (row: IInvoice) => void;
        viewInvoice: (row: IInvoice) => void;
    };
};

/**
 * Represents the button group in the Invoices table of the Manage Invoices page.
 * This component is a part of every row in the table and provides actions for the user.
 *
 * @class InvoiceMenuButtonGroup
 * @constructor {InvoiceMenuButtonGroupProps} props - The component props.
 * @param {IInvoice} props.row - The invoice object.
 * @param {() => void} props.onClick - The function to call when the user clicks the edit button.
 * @param {InvoiceMenuButtonGroupProps["menuActions"]} props.menuActions - The actions to perform when the user clicks a menu item.
 * @returns {JSX.Element} The rendered InvoiceMenuButtonGroup component.
 * @example
 * <InvoiceMenuButtonGroup
 *      row={invoiceRow}
 *      onClick={handleClick}
 *      menuActions={menuActions}
 *      />
 */
export const InvoiceMenuButtonGroup = ({
    onClick,
    row,
    menuActions,
}: InvoiceMenuButtonGroupProps) => {
    const intl = useIntl();
    const [open, setOpen] = useState(false);
    const menuButtonRef = useRef<HTMLButtonElement>(null);
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const handleToggle = useCallback(() => {
        setOpen(prev => !prev);
    }, []);

    const isEditEnabled =
        row.invoiceStatus &&
        [InvoiceStatus.OPEN, InvoiceStatus.READY_FOR_BILLING].includes(row.invoiceStatus);
    const handleCloseDialog = () => {
        cancelDialogOpen && setCancelDialogOpen(false);
        deleteDialogOpen && setDeleteDialogOpen(false);
    };

    return (
        <>
            <ButtonGroup variant="contained">
                <Button
                    variant={isEditEnabled ? "contained" : "outlined"}
                    disabled={!isEditEnabled}
                    onClick={onClick}
                >
                    {intl.formatMessage(Messages.edit)}
                </Button>

                <Button size="small" ref={menuButtonRef} onClick={handleToggle}>
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>

            <Menu anchorEl={menuButtonRef?.current} open={open} onClose={handleToggle}>
                <MenuItem
                    onClick={() => menuActions.sendToAccounting(row)}
                    disabled={row.invoiceStatus !== InvoiceStatus.READY_FOR_BILLING}
                >
                    {intl.formatMessage(Messages.sendToAccounting)}
                </MenuItem>

                <MenuItem
                    onClick={() => setCancelDialogOpen(true)}
                    disabled={row.invoiceStatus !== InvoiceStatus.INVOICED}
                >
                    {intl.formatMessage(Messages.cancelInvoice)}
                </MenuItem>

                <MenuItem
                    onClick={() => menuActions.bookingManagement(row)}
                    disabled={!row.bookingId}
                >
                    {intl.formatMessage(Messages.bookingManagement)}
                </MenuItem>

                <Tooltip
                    title={row.deletable ? "" : intl.formatMessage(Messages.deleteInvoiceTooltip)}
                >
                    <span>
                        <MenuItem
                            onClick={() => setDeleteDialogOpen(true)}
                            disabled={!row.deletable}
                        >
                            {intl.formatMessage(Messages.deleteInvoice)}
                        </MenuItem>
                    </span>
                </Tooltip>

                <MenuItem
                    onClick={() => menuActions.copyCancelledInvoice(row)}
                    disabled={row.invoiceStatus !== InvoiceStatus.CANCELLED}
                >
                    {intl.formatMessage(Messages.copyInvoice)}
                </MenuItem>
                <MenuItem onClick={() => menuActions.viewInvoice(row)}>
                    {intl.formatMessage(Messages.viewInvoice)}
                </MenuItem>
            </Menu>

            <Dialog open={cancelDialogOpen || deleteDialogOpen}>
                <DialogTitle>
                    {cancelDialogOpen
                        ? intl.formatMessage(Messages.cancelInvoice)
                        : deleteDialogOpen && intl.formatMessage(Messages.deleteInvoice)}
                </DialogTitle>
                <DialogContent>
                    {cancelDialogOpen
                        ? intl.formatMessage(Messages.cancelInvoiceMessage)
                        : deleteDialogOpen && intl.formatMessage(Messages.deleteInvoiceMessage)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseDialog()}>
                        {intl.formatMessage(Messages.cancel)}
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            cancelDialogOpen
                                ? menuActions.cancelInvoice(row)
                                : deleteDialogOpen && menuActions.deleteInvoice(row);
                            handleCloseDialog();
                        }}
                    >
                        {intl.formatMessage(Messages.confirm)}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default InvoiceMenuButtonGroup;
