import { ReactNode } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import "./RpisDialog.css";
import { Breakpoint } from "@mui/material";

export interface IRpisDialog {
    dialogOpen: boolean;
    onClose?: () => void;
    title?: string;
    content: ReactNode;
    size: false | Breakpoint;
    fullWidth: boolean;
    className?: string;
}

/**
 * A dialog component that can be used to display content in a dialog.
 * @param dialogOpen Whether the dialog should be open.
 * @param onClose A function that is called when the dialog is closed.
 * @param title The title of the dialog.
 * @param content The content of the dialog.
 * @param size The size of the dialog.
 * @param fullWidth Whether the dialog should be full width.
 * @param className The class name of the dialog.
 */
export const RpisDialog = ({
    content,
    onClose,
    title,
    dialogOpen,
    size,
    fullWidth,
    className,
}: IRpisDialog) => {
    return (
        <Dialog
            open={dialogOpen}
            onClose={onClose}
            fullWidth={fullWidth}
            maxWidth={size}
            className={className}
        >
            <div className="dialog-layout-container">
                <aside className="rpis-dialog">
                    {(onClose || title) && (
                        <div className="rpis-dialog-header">
                            {title && <h2>{title}</h2>}
                            {onClose && (
                                <IconButton onClick={onClose}>
                                    <CloseIcon />
                                </IconButton>
                            )}
                        </div>
                    )}
                    {content}
                </aside>
            </div>
        </Dialog>
    );
};

export default RpisDialog;
