import Messages from "../localization/Messages";
import { ServiceType } from "./service";
import { ITimetable, timetableInit } from "./timetable";

export interface IAddEditDock {
    id: string;
    adapteFaecesPowerlock: boolean;
    address: string;
    area: string;
    city: string;
    companyId: string;
    country: string;
    deleted: 0 | 1;
    destinationId: string;
    drinkingWaterAutomatic: boolean;
    drinkingWaterStandpipe: boolean;
    drinkingWaterWaterMeter: boolean;
    electricitySupply: boolean;
    extensionCable: boolean;
    faecesDisposal: boolean;
    improperDisposal: boolean;
    lat: number;
    lng: number;
    maxLength: number;
    maxVessels: number;
    name: string;
    ordnum: number;
    otherExpenditure: boolean;
    powerlock: boolean;
    pubAllocationPlan: boolean;
    remark: string;
    timeTable: ITimetable;
    wasteDisposal: boolean;
    waterMeter: boolean;
    zip: string;
    timeZone: string;
}

export interface IDock extends IAddEditDock {
    createBy: string;
    createTs: number;
    lastModifyBy: string;
    lastModifyTs: number;
}

export const addEditDockInit: IAddEditDock = {
    id: "",
    adapteFaecesPowerlock: false,
    address: "",
    area: "",
    city: "",
    country: "",
    deleted: 0,
    destinationId: "",
    drinkingWaterAutomatic: false,
    drinkingWaterStandpipe: false,
    drinkingWaterWaterMeter: false,
    electricitySupply: false,
    extensionCable: false,
    faecesDisposal: false,
    improperDisposal: false,
    lat: 0,
    lng: 0,
    maxLength: 0,
    maxVessels: 0,
    name: "",
    companyId: "",
    ordnum: 0,
    otherExpenditure: false,
    powerlock: false,
    pubAllocationPlan: false,
    remark: "",
    timeTable: timetableInit,
    wasteDisposal: false,
    waterMeter: false,
    zip: "",
    timeZone: "",
};

export const dockInit: IDock = {
    ...addEditDockInit,
    createBy: "",
    createTs: 0,
    lastModifyBy: "",
    lastModifyTs: 0,
};

export const getDockGoogleMapsLink = (latitude: number, longitude: number): string => {
    return `https://www.google.com/maps?q=${latitude},${longitude}`;
};

export const existingInfrastructure = [
    { value: "powerlock", label: Messages.powerlock },
    { value: "electricitySupply", label: Messages.electricitySupply },
    { value: "drinkingWaterAutomatic", label: Messages.drinkingWaterAutomatic },
    { value: "drinkingWaterStandpipe", label: Messages.drinkingWaterStandpipe },
    { value: "drinkingWaterWaterMeter", label: Messages.drinkingWaterWaterMeter },
    { value: "wasteDisposal", label: Messages.wasteDisposal },
    { value: "faecesDisposal", label: Messages.faecesDisposal },
];

export const availableServices = [
    { value: ServiceType.WATER_METER, label: Messages.waterMeter },
    { value: ServiceType.POWERLOCK_ADAPTER, label: Messages.powerlockAdapter },
    { value: ServiceType.FAECES_ADAPTER, label: Messages.faecesAdapter },
    { value: ServiceType.EXTENSION_CABLE, label: Messages.extensionCable },
    { value: ServiceType.IMPROPER_DISPOSAL, label: Messages.improperDisposal },
    { value: ServiceType.OTHER_EXPENDITURE, label: Messages.otherExpenditure },
];
