import { Box } from "@mui/material";
import React, { ReactNode, useCallback, useState } from "react";

/**
 * Props for the TooltipContext.
 */
export type TooltipContextProps = {
    /**
     * Shows a tooltip with the specified title at the given position.
     * @param title - The title of the tooltip.
     * @param posX - The X position of the tooltip.
     * @param posY - The Y position of the tooltip.
     */
    show: (title: string, posX: string, posY: string) => void;
    /**
     * Hides the currently displayed tooltip.
     */
    hide: () => void;
};

/**
 * Context for managing tooltips.
 */
export const TooltipContext = React.createContext<TooltipContextProps | undefined>(undefined);

/**
 * Provides the TooltipContext to its children.
 * @param children - The children components.
 */
export function TooltipProvider({ children }: { children: ReactNode }) {
    const [title, setTitle] = useState("");
    const [posX, setPosX] = useState("");
    const [posY, setPosY] = useState("");
    const [open, setOpen] = useState(false);
    const cssDisplayProp = open ? "block" : "none";

    const show = useCallback((title: string, posX: string, posY: string) => {
        setTitle(title);
        setOpen(true);
        setPosX(posX);
        setPosY(posY);

        setTimeout(() => {
            setOpen(false);
        }, 5000);
    }, []);

    const hide = useCallback(() => {
        setTitle("");
        setOpen(false);
    }, []);

    return (
        <TooltipContext.Provider value={{ show, hide }}>
            {children}
            <Box
                left={posX}
                top={posY}
                display={cssDisplayProp}
                sx={{
                    position: "fixed",
                    padding: "0.25rem 0.5rem",
                    color: "white",
                    backgroundColor: "gray",
                    borderRadius: "0.25rem",
                    fontSize: "0.8em",
                }}
            >
                {title}
            </Box>
        </TooltipContext.Provider>
    );
}
