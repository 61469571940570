import { IntlShape } from "react-intl";
import Messages from "../localization/Messages";
import { InvoiceStatus } from "./invoice";

export interface IService {
    id?: string;
    destinationId?: string;
    shipId?: string;
    shipName?: string;
    companyId?: string;
    bookingId?: number;
    startDateTime?: number | null;
    endDateTime?: number | null;
    type?: ServiceType;
    status?: ServiceStatus;
    data: IServiceData;
    defaultExpenseTypeId?: string;
    saturdayExpenseTypeId?: string;
    holydayExpenseTypeId?: string;
    lastModifyBy?: string;
    invoiceId?: number | null;
    invoiceStatus?: InvoiceStatus | null;
    finalized?: boolean | null;
}

export interface IServiceData {
    waterMeterStartValue: number | null;
    waterMeterEndValue: number | null;
    servicePersonnelData: IServicePersonnelData[];
}

export interface IServicePersonnelData {
    startDateTime: number | null;
    endDateTime: number | null;
}

export const servicePersonnelDataInit = {
    startDateTime: null,
    endDateTime: null,
};

export const serviceDataInit: IServiceData = {
    waterMeterStartValue: null,
    waterMeterEndValue: null,
    servicePersonnelData: [servicePersonnelDataInit],
};

export const serviceInit: IService = {
    data: serviceDataInit,
};

export enum ServiceType {
    WATER_METER = "WATER_METER",
    POWERLOCK_ADAPTER = "POWERLOCK_ADAPTER",
    FAECES_ADAPTER = "FAECES_ADAPTER",
    EXTENSION_CABLE = "EXTENSION_CABLE",
    IMPROPER_DISPOSAL = "IMPROPER_DISPOSAL",
    OTHER_EXPENDITURE = "OTHER_EXPENDITURE",
}

export enum ServiceStatus {
    OPEN = "OPEN",
    FINALIZED = "FINALIZED",
    READY_FOR_BILLING = "READY_FOR_BILLING",
    INVOICED = "INVOICED",
    DELETED = "DELETED",
}

const availableServices = [
    { value: ServiceType.WATER_METER, label: Messages.waterMeter },
    { value: ServiceType.POWERLOCK_ADAPTER, label: Messages.powerlockAdapter },
    { value: ServiceType.FAECES_ADAPTER, label: Messages.faecesAdapter },
    { value: ServiceType.EXTENSION_CABLE, label: Messages.extensionCable },
    { value: ServiceType.IMPROPER_DISPOSAL, label: Messages.improperDisposal },
    { value: ServiceType.OTHER_EXPENDITURE, label: Messages.otherExpenditure },
];

export const serviceStatusOptions = [
    { value: ServiceStatus.OPEN, label: Messages.open },
    { value: ServiceStatus.FINALIZED, label: Messages.finalized },
    { value: ServiceStatus.READY_FOR_BILLING, label: Messages.readyForBilling },
    { value: ServiceStatus.INVOICED, label: Messages.invoiced },
    { value: ServiceStatus.DELETED, label: Messages.deleted },
];

export const getServiceTypeLabel = (intl: IntlShape, type: ServiceType | undefined) => {
    return intl.formatMessage(
        availableServices.find(s => s.value === type)?.label ?? Messages.unknown,
    );
};

export const disabledInvoiceStatuses = [InvoiceStatus.INVOICED, InvoiceStatus.READY_FOR_BILLING];

export function getServiceStartDateTime(personnelDataList: IServicePersonnelData[]): number | undefined {
    return personnelDataList
        .map(data => data.startDateTime)
        .filter((start): start is number => start !== undefined)
        .reduce<number | undefined>((min, start) => min !== undefined ? Math.min(min, start) : start, undefined);
}

export function getServiceEndDateTime(personnelDataList: IServicePersonnelData[]): number | undefined {
    return personnelDataList
        .map(data => data.endDateTime)
        .filter((end): end is number => end !== undefined)  
        .reduce<number | undefined>((max, end) => max !== undefined ? Math.max(max, end) : end, undefined);
}