import { useIntl } from "react-intl";
import Messages from "../../localization/Messages";
import { ITimetable, TimeInterval, weekdays, WeekdayType } from "../../models/timetable";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Chip from "@mui/material/Chip";
import { Fragment, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Moment } from "moment";
import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    IconButton,
} from "@mui/material";
import "./TimetableEditor.css";
import { DateFormat, getDateMoment } from "../../helpers/dateHelper";

export interface ITimetableRow {
    intervals: TimeInterval[];
    onAdd: () => void;
    onDelete: (index: number) => void;
}

export const TimetableRow = ({ intervals, onAdd, onDelete }: ITimetableRow) => {
    return (
        <div className="timetable-row-container">
            {intervals.map((i, j) => (
                // eslint-disable-next-line react/jsx-key
                <Chip label={`${i.start} - ${i.end}`} onDelete={() => onDelete(j)} />
            ))}
            <IconButton color="primary" onClick={onAdd}>
                <AddCircleOutlineIcon />
            </IconButton>
        </div>
    );
};

export interface ITimetableEditor {
    title: string;
    timetable: ITimetable;
    setTimetable: (timetable: ITimetable) => void;
}

/**
 * Represents a component that allows editing and displaying a timetable.
 *
 * @example
 * ```tsx
 * import TimetableEditor from "./TimetableEditor";
 *
 * const MyComponent = () => {
 *   const timetable = {
 *     Monday: [
 *       { start: "09:00", end: "12:00" },
 *       { start: "13:00", end: "17:00" },
 *     ],
 *     Tuesday: [
 *       { start: "10:00", end: "12:00" },
 *       { start: "14:00", end: "16:00" },
 *     ],
 *     // ... other weekdays
 *   };
 *
 *   const setTimetable = (newTimetable) => {
 *     // update the timetable state
 *   };
 *
 *   return (
 *     <TimetableEditor
 *       title="My Timetable"
 *       timetable={timetable}
 *       setTimetable={setTimetable}
 *     />
 *   );
 * };
 * ```
 */

export const TimetableEditor = ({ title, timetable, setTimetable }: ITimetableEditor) => {
    const intl = useIntl();
    const [slectedDay, setSlectedDay] = useState<WeekdayType>();
    const [newInterval, setNewInterval] = useState<TimeInterval | null>(null);

    const onAddInterval = () => {
        const newRow = [...timetable[slectedDay!], newInterval];
        setTimetable({ ...timetable, [slectedDay!]: newRow });
        setSlectedDay(undefined);
        setNewInterval(null);
    };

    const onRemoveInterval = (wd: WeekdayType, i: number) => {
        const newRow = [...timetable[wd]];
        newRow.splice(i, 1);
        setTimetable({ ...timetable, [wd]: newRow });
    };

    return (
        <>
            <div className="timetable-editor-container">
                <h3>{title}</h3>
                <div className="editor">
                    {weekdays.map(wd => (
                        <Fragment key={wd}>
                            <h4 className="label">{intl.formatMessage(Messages[wd])}</h4>
                            <TimetableRow
                                intervals={timetable[wd]}
                                onAdd={setSlectedDay.bind(null, wd)}
                                onDelete={onRemoveInterval.bind(null, wd)}
                            />
                        </Fragment>
                    ))}
                </div>
            </div>

            <Dialog open={Boolean(slectedDay)} onClose={() => setSlectedDay(undefined)}>
                <DialogTitle>{intl.formatMessage(Messages.addDockTimeAvailability)}</DialogTitle>
                <DialogContent className="interval-form">
                    <TimePicker
                        label="Start"
                        value={
                            newInterval?.start
                                ? getDateMoment(newInterval.start, {
                                      format: DateFormat.API_TIME,
                                  })
                                : null
                        }
                        onChange={(value: Moment | null) => {
                            setNewInterval({
                                start: value?.format(DateFormat.API_TIME) ?? "",
                                end: newInterval?.end ?? "",
                            });
                        }}
                        renderInput={params => <TextField variant="filled" {...params} />}
                    />
                    <TimePicker
                        label="End"
                        value={
                            newInterval?.end
                                ? getDateMoment(newInterval.end, {
                                      format: DateFormat.API_TIME,
                                  })
                                : null
                        }
                        onChange={(value: Moment | null) => {
                            setNewInterval({
                                end: value?.format(DateFormat.API_TIME) ?? "",
                                start: newInterval?.start ?? "",
                            });
                        }}
                        renderInput={params => <TextField variant="filled" {...params} />}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setSlectedDay(undefined)}>
                        {intl.formatMessage(Messages.cancel)}
                    </Button>
                    <Button variant="contained" onClick={onAddInterval} color="primary">
                        {intl.formatMessage(Messages.add)}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TimetableEditor;
