import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { showBackendMessage } from "../../../helpers/messagesHelper";
import Messages from "../../../localization/Messages";
import { searchParamsInit } from "../../../models/searchParams";
import { searchShipDisp } from "../../../api/ship";
import { IShipDispatcher } from "../../../models/shipDispatcher";

/**
 * Custom hook for fetching ships.
 * This hook fetches ships from the backend and handles loading state and error handling.
 *
 * @returns An array containing the fetched ships, a function to update the ships, and an object with the loading state.
 */
function useFetchShips() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const [ships, setShips] = useState<IShipDispatcher[]>([]);

    useEffect(() => {
        const fetchShips = async () => {
            setLoading(true);
            try {
                const res = await searchShipDisp(searchParamsInit);
                setShips(res.data.rows ?? []);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.ships));
            } finally {
                setLoading(false);
            }
        };

        fetchShips();
    }, [dispatch, intl]);

    return [ships, setShips, { loading }] as const;
}

export default useFetchShips;
