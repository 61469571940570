import { getDateMoment } from "../../../helpers/dateHelper";
import { SortOrder } from "../../../hooks/useTableSort";
import { Type as InternalBookingType } from "../../../models/internalBooking";

export type InternalBookingsFilters = {
    search: string;
    dockId: string | undefined;
    arrivalTime: number | null;
    departureTime: number | null;
    types: InternalBookingType[];
    rowsPerPage: number;
    page: number;
    order: SortOrder;
};

export const DISP_MANAGE_INTERNAL_BOOKINGS_FILTERS_INIT: InternalBookingsFilters = {
    search: "",
    dockId: undefined,
    departureTime: null,
    arrivalTime: null,
    types: [],
    page: 0,
    rowsPerPage: 100,
    order: ["arrivalTime desc"],
};

export function buildInternalBookingsSearchPayload(filters: InternalBookingsFilters) {
    return {
        offset: filters.page * filters.rowsPerPage,
        limit: filters.rowsPerPage,
        textSearch: filters.search,
        order: filters.order,
        columns: {},
        filter: buildInternalBookingsSearchPayloadFilters(filters),
    };
}

function buildInternalBookingsSearchPayloadFilters(filters: InternalBookingsFilters) {
    const { dockId, types, arrivalTime, departureTime } = filters;

    const filterArray: object[] = [];

    if (dockId) {
        filterArray.push({ dockId: { $eq: dockId } });
    }

    if (types.length > 0) {
        const bsFilterArray: object[] = [];
        types.forEach(st => bsFilterArray.push({ type: st.toString() }));
        filterArray.push({ $or: bsFilterArray });
    }

    if (arrivalTime && !departureTime) {
        const startDate = getDateMoment(arrivalTime).toDate();
        const endDate = getDateMoment(arrivalTime).toDate();
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 59);
        filterArray.push(
            { arrivalTime: { $gte: startDate.getTime() } },
            { arrivalTime: { $lte: endDate.getTime() } },
        );
    }

    if (departureTime && !arrivalTime) {
        const startDate = getDateMoment(departureTime).toDate();
        const endDate = getDateMoment(departureTime).toDate();
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 59);
        filterArray.push(
            { departureTime: { $gte: startDate.getTime() } },
            { departureTime: { $lte: endDate.getTime() } },
        );
    }

    if (departureTime && arrivalTime) {
        const startDate = getDateMoment(arrivalTime).toDate();
        const endDate = getDateMoment(departureTime).toDate();
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 59);
        filterArray.push(
            { arrivalTime: { $gte: startDate.getTime() } },
            { departureTime: { $lte: endDate.getTime() } },
        );
    }

    return { $and: filterArray };
}
