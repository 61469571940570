/**
 * Formats the given amount and currency into a display string.
 * If the amount is not provided, it defaults to 0.
 * If the currency is not provided, only the formatted amount is returned.
 *
 * @param amount - The amount to be formatted.
 * @param currency - The currency symbol to be appended to the formatted amount.
 * @returns The formatted amount with the currency symbol (if provided).
 */
export function displayAmount(amount?: number | null, currency?: string): string {
    const amountDisplay = (amount ?? 0).toFixed(2);
    if (!currency) return amountDisplay;
    return `${amountDisplay} ${currency}`;
}
