import axios, { AxiosResponse } from "axios";
import { IDocksUser } from "../models/user";

export const getUser = async (id: string): Promise<AxiosResponse<IDocksUser>> => {
    return axios.get(`/docks/getUser/${id}`);
};

export const changeUser = async (user: IDocksUser): Promise<AxiosResponse<IDocksUser>> => {
    return axios.patch("/docks/changeUser", user);
};
