import { IDocument } from "./document";

export interface IShipDispatcher {
    id: string;
    companyId: string;
    complete: boolean;
    createBy: string;
    createTs: number;
    data?: IShipDispatcherData;
    dateIssueCurPluginCard?: string | null;
    deleted: number;
    docksId: string;
    docksOrganizationId: string;
    docksCompanyId: string;
    documents: IDocument[];
    electricityCardNumber?: string;
    electricityCardNumberActive?: boolean;
    electricityCardDeliveryDate?: string | null;
    eni?: string;
    keyDeliveryDate?: string | null;
    keyNumber?: string;
    keyNumberActive?: boolean;
    lastModifyBy: string;
    lastModifyTs: number;
    mmsi?: string;
    name: string;
    organizationId: string;
    portRelCustomerNumber?: string;
    remarks?: string;
    remarksTechnical?: string;
    textSearch?: string;
    inspectionCount?: number | null;
    lastInspection?: string | null;
    currentAmps?: number | null;
    passengerBeds?: number | null;
    lastModShipData?: string | null;
}

export interface IShipDispatcherData {
    id: string;
    name: string;
    eni?: string;
    mmsi?: string;
    flag?: string;
    regPort?: string;
    passengerBeds?: number | null;
    length: number | null;
    width?: number | null;
    portalWidth?: number | null;
    entranceHeight?: number | null;
    onBoardSewage?: string;
    certExpireDate?: string | null;
    lastInspection?: string | null;
    inspectionCount?: number | null;
    currentAmps?: number | null;
    inactive: 0 | 1;
    validFrom?: string | null;
    validTo?: string | null;
    tel1?: string;
    tel2?: string;
    email: string;
    owningCompanyAddressId?: string;
}

export const shipDispatcherInit: IShipDispatcher = {
    id: "",
    companyId: "",
    complete: false,
    createBy: "",
    createTs: 0,
    deleted: 0,
    docksId: "",
    docksCompanyId: "",
    docksOrganizationId: "",
    lastModifyBy: "",
    lastModifyTs: 0,
    name: "",
    organizationId: "",
    documents: [],
    inspectionCount: null,
    lastInspection: null,
    currentAmps: null,
};

export const shipDispatcherDataInit = {
    id: "",
    name: "",
    length: null,
    inactive: 0,
    email: "",
};
