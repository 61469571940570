export const INTERNAL_BOOKINGS_SORTABLE_COLUMNS = [
    "type",
    "dockId",
    "arrivalTime",
    "departureTime",
    "shipName",
    "eni",
    "reason",
] as const;

export const INTERNAL_BOOKINGS_ROWS_PER_PAGE_OPTS = [50, 100, 250, 500, 2000];

export const SEARCH_FIELD_MIN_LENGTH = 3;
