import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { searchAddress } from "../api/address";
import { searchParamsInit } from "../models/searchParams";
import { IAddress } from "../models/address";
import { showBackendMessage } from "../helpers/messagesHelper";
import Messages from "../localization/Messages";

/**
 * Custom hook for fetching addresses.
 *
 * @returns An array of addresses.
 */
export function useFetchAddresses() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [addresses, setAddresses] = useState<IAddress[]>([]);

    useEffect(() => {
        const fetchAddresses = async () => {
            try {
                const apiResponse = await searchAddress(searchParamsInit);
                const resultSet = apiResponse.data.rows ?? [];
                setAddresses(resultSet);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.addresses));
            } finally {
                // NOOP
            }
        };

        fetchAddresses();
    }, [intl, dispatch]);

    return addresses;
}
