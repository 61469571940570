import { useState, useEffect, useCallback } from "react";
import RpisPage from "../RpisPage/RpisPage";
import Messages from "../../localization/Messages";
import { useIntl } from "react-intl";
import "./DispManageAddresses.css";
import SidebarLayout from "../SidebarLayout/SidebarLayout";
import { TableSkeleton } from "../../components/TableSkeleton/TableSkeleton";
import { AddressType, IAddress, IDispatcherAddress } from "../../models/address";
import { deleteAddress, getAddress } from "../../api/dispAddress";
import { ISearchParams, searchParamsInit } from "../../models/searchParams";
import { useDispatch, useSelector } from "react-redux";
import { showBackendMessage } from "../../helpers/messagesHelper";
import EditIcon from "@mui/icons-material/Edit";
import classNames from "classnames";
import SearchIcon from "@mui/icons-material/Search";
import { InternalAddressForm } from "./components/InternalAddressForm/InternalAddressForm";
import { SortOrder, useTableSort } from "../../hooks/useTableSort";
import { DatatablePaginationFilters, useGuiConfState } from "../../hooks/useGuiConfState";
import TableCellSorted from "../../components/TableCellSorted";
import {
    Autocomplete,
    Button,
    FilledInput,
    FormControl,
    InputAdornment,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
} from "@mui/material";
import { Serializers, useUrlState } from "../../hooks/useUrlState";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import { errorMessages } from "../LDManageAddresses/LDManageAddresses";
import { showToastMessage } from "../../actions/toastMessageActions";
import { AppState } from "../../store/configureStore";
import { useFetchDispAddresses } from "../../hooks/useFetchDispAddresses";

/**
 * address source filtering property options
 */
const invoiceAddressSource = [
    { id: 0, label: Messages.internal, value: true },
    { id: 1, label: Messages.external, value: false },
];
/**
 * address deleted filtering property options
 */
const addressStatus = [
    { id: 0, label: Messages.active, value: false },
    { id: 1, label: Messages.deleted, value: true },
];

/**
 * Filter and sort options for the DispManageAddresses component
 */
export type DispManageAddressesFilters = DatatablePaginationFilters & {
    addressSource: boolean | undefined;
    addressType: AddressType | undefined;
    status: boolean | undefined;
    textSearch: string;
    order: SortOrder;
};

function buildFilters(filters: DispManageAddressesFilters) {
    const filterArray: object[] = [{ type: AddressType.InvoiceAddress }];
    if (filters.addressSource === true || filters.addressSource === false) {
        filterArray.push({ internal: filters.addressSource });
    }
    if (filters.status === true || filters.status === false) {
        filterArray.push({ deleted: filters.status });
    }
    return { $and: filterArray };
}

export type DispAddressesPayload = ISearchParams & {
    showDeleted: boolean;
};

export function buildDispAddressesPayload(
    filters: DispManageAddressesFilters,
): DispAddressesPayload {
    return {
        ...searchParamsInit,
        offset: filters.page * filters.rowsPerPage,
        limit: filters.rowsPerPage,
        textSearch: filters.textSearch,
        order: filters.order,
        showDeleted: true,
        filter: buildFilters(filters),
    };
}

const DISP_ADDRESSES_SORTABLE_COLUMNS = [
    "company",
    "street",
    "accountRecNumber",
    "firstName",
    "tel",
    "email",
] as const;

/**
 * `DispManageAddresses` represents a component that renders the `Manage addresses` page for the dispatcher.
 * It displays invoice addresses from Locks & Docks users that made a Booking request as well as internal invoice addresses that were made by the dispatcher.
 * Addresses are displayed in a table with sorting and filtering options.
 * The component also provides the option to add new internal addresses and edit existing ones.
 */

export const DispManageAddresses = () => {
    const withConfirm = useConfirmDialog();
    const intl = useIntl();
    const dispatch = useDispatch();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState<IDispatcherAddress>();

    const [filters, setFilters] = useGuiConfState("dispManageAddresses");
    const registerSort = useTableSort<typeof DISP_ADDRESSES_SORTABLE_COLUMNS>({
        columns: DISP_ADDRESSES_SORTABLE_COLUMNS,
        value: filters.order,
        onChange: order => setFilters({ order }),
    });
    const [selectedAddressId, setSelectedAddressId] = useUrlState(
        "selectedAddressId",
        "",
        Serializers.string,
    );
    const managingCompanies = useSelector((s: AppState) => s.managingCompanies).data;

    const {
        data,
        refetch: getAddresses,
        loading,
    } = useFetchDispAddresses(buildDispAddressesPayload(filters));

    const addresses = data?.addresses ?? [];
    const totalAddresses = data?.total ?? 0;

    useEffect(() => {
        const persistInitialAddress = async () => {
            try {
                const initialAddress = await getAddress(selectedAddressId);
                setSelectedAddress(initialAddress.data);
                setSidebarOpen(true);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.address));
            }
        };
        if (selectedAddressId) {
            persistInitialAddress();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onAddAddress = () => {
        setSidebarOpen(false);
        getAddresses();
    };

    const handleDeleteInternalAddress = async (id: string) => {
        try {
            await deleteAddress(id);
            dispatch(showBackendMessage(intl, "success", "deleting", Messages.address));
        } catch (err: any) {
            const msg = err.response.data.message as string;
            if (msg) {
                const message = errorMessages.find(message => message.messageCode === msg)?.message;
                dispatch(
                    showToastMessage(
                        intl.formatMessage(message ?? Messages.errorDeletingAddress),
                        "error",
                        10000,
                    ),
                );
            } else {
                dispatch(showBackendMessage(intl, "error", "deleting", Messages.address));
            }
        } finally {
            setSelectedAddress(undefined);
            getAddresses();
        }
    };

    const handleChangePage = (_event: unknown, newPage: number) => {
        setFilters({ page: newPage });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilters({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
        });
    };

    const clearFilters = () => {
        setFilters({
            addressType: undefined,
            addressSource: undefined,
            status: undefined,
            textSearch: "",
        });
    };

    const handleSelectedAddressIdChange = useCallback((id: string) => {
        setSelectedAddressId(id);
        setSidebarOpen(id.length > 0);
        if (id.length === 0) {
            setSelectedAddress(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAddressDetails = useCallback(
        (row: IAddress) => {
            setSelectedAddress(row);
            handleSelectedAddressIdChange(String(row.id));
        },
        [handleSelectedAddressIdChange],
    );

    const onRespondToAddressClose = useCallback(() => {
        handleSelectedAddressIdChange("");
    }, [handleSelectedAddressIdChange]);

    return (
        <RpisPage
            title={intl.formatMessage(Messages.manageAddresses)}
            className="disp-manage-addresses-container"
        >
            <SidebarLayout
                sidebarTitle={intl.formatMessage(Messages.manageAddresses)}
                open={sidebarOpen}
                onClose={() => onRespondToAddressClose()}
                sidebarContent={
                    <InternalAddressForm
                        setSidebarOpen={setSidebarOpen}
                        selectedAddress={selectedAddress}
                        callback={onAddAddress}
                    />
                }
            >
                <div className="title-and-actions-container">
                    <form id="search-form">
                        <Autocomplete
                            id="invoice-address-source"
                            options={invoiceAddressSource}
                            getOptionLabel={o => intl.formatMessage(o.label)}
                            renderInput={params => (
                                <TextField
                                    variant="filled"
                                    {...params}
                                    label={intl.formatMessage(Messages.invoiceAddressSource)}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option.id}>
                                    {intl.formatMessage(option.label)}
                                </li>
                            )}
                            onChange={(_, value) => setFilters({ addressSource: value?.value })}
                            value={
                                invoiceAddressSource.find(
                                    sc => sc.value === filters.addressSource,
                                ) ?? null
                            }
                        />
                        <Autocomplete
                            id="address-status"
                            options={addressStatus}
                            getOptionLabel={o => intl.formatMessage(o.label)}
                            renderInput={params => (
                                <TextField
                                    variant="filled"
                                    {...params}
                                    label={intl.formatMessage(Messages.status)}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option.id}>
                                    {intl.formatMessage(option.label)}
                                </li>
                            )}
                            onChange={(_, value) => setFilters({ status: value?.value })}
                            value={addressStatus.find(s => s.value === filters.status) ?? null}
                        />
                        <FormControl variant="filled">
                            <FilledInput
                                onChange={e => setFilters({ textSearch: e.target.value })}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                                value={filters.textSearch}
                                autoFocus={true}
                            />
                            <InputLabel>{intl.formatMessage(Messages.search)}</InputLabel>
                        </FormControl>
                        {(filters.addressType ||
                            filters.textSearch ||
                            filters.status !== undefined ||
                            filters.addressSource !== undefined) && (
                            <Button variant="outlined" onClick={clearFilters}>
                                {intl.formatMessage(Messages.clear)}
                            </Button>
                        )}
                    </form>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setSidebarOpen(true);
                            setSelectedAddress(undefined);
                        }}
                    >
                        {intl.formatMessage(Messages.addAddress)}
                    </Button>
                </div>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCellSorted
                                    align="left"
                                    label={intl.formatMessage(Messages.company)}
                                    {...registerSort("company")}
                                />
                                <TableCellSorted
                                    align="right"
                                    label={intl.formatMessage(Messages.address)}
                                    {...registerSort("street")}
                                />
                                <TableCellSorted
                                    align="right"
                                    label={intl.formatMessage(Messages.accountsReceivableNumber)}
                                    {...registerSort("accountRecNumber")}
                                />
                                <TableCellSorted
                                    align="right"
                                    label={intl.formatMessage(Messages.contactName)}
                                    {...registerSort("firstName")}
                                />
                                <TableCellSorted
                                    align="right"
                                    label={intl.formatMessage(Messages.telephone)}
                                    {...registerSort("tel")}
                                />
                                <TableCell align="right">
                                    {intl.formatMessage(Messages.managingCompany)}
                                </TableCell>
                                <TableCellSorted
                                    align="right"
                                    label={intl.formatMessage(Messages.email)}
                                    {...registerSort("email")}
                                />
                                <TableCell align="right">
                                    {intl.formatMessage(Messages.status)}
                                </TableCell>
                                <TableCell align="right">
                                    {intl.formatMessage(Messages.addressSource)}
                                </TableCell>
                                <TableCell align="center">
                                    {intl.formatMessage(Messages.action)}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {loading ? (
                            <TableSkeleton columns={9} rows={10} />
                        ) : (
                            <TableBody>
                                {addresses?.map(row => (
                                    <TableRow
                                        key={row.id}
                                        className={classNames({
                                            "active-table-row":
                                                selectedAddress?.id === row.id && sidebarOpen,
                                        })}
                                    >
                                        <TableCell align="left">{row.company}</TableCell>
                                        <TableCell align="right">{`${row.street ?? ""} ${
                                            row.streetNo ?? ""
                                        }, ${row.zip ?? ""} ${row.city ?? ""}`}</TableCell>
                                        <TableCell align="right">
                                            {row.accountRecNumber ?? ""}
                                        </TableCell>
                                        <TableCell align="right">{`${row.firstName ?? ""} ${
                                            row.surname ?? ""
                                        }`}</TableCell>
                                        <TableCell align="right">{row.tel ?? ""}</TableCell>
                                        <TableCell align="right">
                                            {managingCompanies.get(row.managingCompany)}
                                        </TableCell>
                                        <TableCell align="right">{row.email ?? ""}</TableCell>
                                        <TableCell align="right">
                                            {row.deleted === 1
                                                ? intl.formatMessage(Messages.deleted)
                                                : intl.formatMessage(Messages.active)}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.internal
                                                ? intl.formatMessage(Messages.internal)
                                                : intl.formatMessage(Messages.external)}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button
                                                startIcon={<EditIcon />}
                                                variant="outlined"
                                                onClick={() => handleAddressDetails(row)}
                                            >
                                                {row.deleted === 1
                                                    ? intl.formatMessage(Messages.viewAddress)
                                                    : row.internal === true
                                                    ? intl.formatMessage(Messages.edit)
                                                    : intl.formatMessage(
                                                          Messages.editAdditionalInformation,
                                                      )}
                                            </Button>
                                            {row.internal && !row.deleted && (
                                                <Button
                                                    variant="outlined"
                                                    color="error"
                                                    onClick={() => {
                                                        withConfirm({
                                                            message: intl.formatMessage(
                                                                Messages.confirmAddressDelete,
                                                            ),
                                                            onConfirm: async () =>
                                                                await handleDeleteInternalAddress(
                                                                    row.id,
                                                                ),
                                                        });
                                                    }}
                                                >
                                                    {intl.formatMessage(Messages.delete)}
                                                </Button>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[50, 100, 250, 500]}
                    component="div"
                    count={totalAddresses ?? 0}
                    rowsPerPage={filters.rowsPerPage}
                    page={filters.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </SidebarLayout>
        </RpisPage>
    );
};

export default DispManageAddresses;
