import { ClickAwayListener, Fade, Popper, PopperPlacementType } from "@mui/material";
import { ReactNode, useState } from "react";
import { Roles } from "../../models/userData";
import { useAuth } from "../../hooks/useAuth";

export type ButtonPanelProps = {
    ButtonRender: ReactNode;
    secure?: Roles | Roles[];
    children?: ReactNode;
    placement?: PopperPlacementType;
};

/**
 * Renders a button panel component with optional security roles.
 *
 * @component
 * @example
 * // Example usage of ButtonPanel component
 * <ButtonPanel
 *    ButtonRender={<Button>Click me</Button>}
 *    secure={['admin', 'manager']}
 *    placement="bottom-start"
 * >
 *    <div>Content inside the panel</div>
 * </ButtonPanel>
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.ButtonRender - The button element to render.
 * @param {Roles | Roles[]} [props.secure] - The security roles required to display the panel. Can be a single role or an array of roles.
 * @param {ReactNode} [props.children] - The content to display inside the panel.
 * @param {PopperPlacementType} [props.placement="bottom-start"] - The placement of the panel relative to the button.
 * @returns {JSX.Element} The rendered ButtonPanel component.
 */

export function ButtonPanel({
    ButtonRender,
    secure,
    children,
    placement = "bottom-start",
}: ButtonPanelProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { hasRole } = useAuth();
    const localSecure = secure ? (Array.isArray(secure) ? secure : [secure]) : [];

    const onButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const onContentClickAway = () => {
        setAnchorEl(null);
    };

    if (localSecure.length > 0 && !hasRole(...localSecure)) {
        return <></>;
    }

    return (
        <>
            <div onClick={onButtonClick}>{ButtonRender}</div>

            <Popper
                open={open}
                placement={placement}
                anchorEl={anchorEl}
                transition
                className="z-1202"
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={onContentClickAway}>
                        <Fade {...TransitionProps}>
                            <div>{children}</div>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
}
