import { IntlShape, MessageDescriptor } from "react-intl";
import { showToastMessage } from "../actions/toastMessageActions";
import Messages from "../localization/Messages";

/**
 * Returns a formatted message for a required field.
 * @param intl - The `IntlShape` object from react-intl.
 * @param field - The message descriptor for the field.
 * @returns The formatted message for the required field.
 */
export const getFieldRequiredMessage = (intl: IntlShape, field: MessageDescriptor) => {
    const localTemplate = intl.formatMessage(Messages.isRequiredTemplate);
    const localField = intl.formatMessage(field);
    return localTemplate.replace("ITEM", localField);
};

/**
 * Shows a backend message as a toast.
 * @param intl - The `IntlShape` object from react-intl.
 * @param severity - The severity of the message (success, error, or undefined).
 * @param action - The action being performed (fetching, creating, updating, etc.).
 * @param field - The message descriptor for the field.
 * @returns The result of the `showToastMessage` function.
 */
export const showBackendMessage = (
    intl: IntlShape,
    severity: "success" | "error" | undefined,
    action:
        | "fetching"
        | "creating"
        | "updating"
        | "deleting"
        | "booking"
        | "adding"
        | "downloading"
        | "archiving"
        | "copying",
    field: MessageDescriptor,
) => {
    const localTemplate =
        severity === "error"
            ? intl.formatMessage(Messages.errorBackendActionTemplate)
            : intl.formatMessage(Messages.successBackendActionTemplate);
    const localField = intl.formatMessage(field);
    const localAction = intl.formatMessage(Messages[action]);
    return showToastMessage(
        localTemplate.replace("ACTION", localAction).replace("ITEM", localField),
        severity,
    );
};
