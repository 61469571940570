import { RemoveCircleOutline } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { IBerthing } from "../../../models/berthing";
import { IBooking } from "../../../models/booking";
import DateTimePickerInline from "../../DateTimePickerInline";
import { BerthingConstraint, BerthingErrors } from "../hooks/useBerthingsState";
import DockInput from "./DockInput";

/**
 * Props for the BerthingInputRow component.
 */
export type BerthingInputRowProps = {
    index: number;
    berthing: IBerthing;
    booking: IBooking;
    onRemove: (index: number) => void;
    loading?: boolean;
    setBerthings: Dispatch<SetStateAction<IBerthing[]>>;
    constraint: BerthingConstraint;
    errors: BerthingErrors;
    hideDeparture?: boolean;
    plausabilityErrors?: string[];
};

/**
 * Mutates the berthings data by applying a patch to the berthing at the specified index.
 * @param data - The original berthings data.
 * @param index - The index of the berthing to mutate.
 * @param patch - The patch to apply to the berthing.
 * @returns The mutated berthings data.
 */
function mutateBerthings(data: IBerthing[], index: number, patch: Partial<IBerthing>) {
    return [...data].map((v, i) =>
        i === index
            ? { ...v, ...patch }
            : {
                  ...v,
                  arrivalTime:
                      "departureTime" in patch && i === index + 1
                          ? patch.departureTime!
                          : v.arrivalTime!,
                  departureTime:
                      "arrivalTime" in patch && i === index - 1
                          ? patch.arrivalTime!
                          : v.departureTime!,
              },
    );
}

const MIN_HEIGHT = "34px";

/**
 * A row component for entering berthing information.
 * @param berthing - The berthing object.
 * @param onRemove - Callback function for removing the berthing row.
 * @param index - The index of the berthing row.
 * @param booking - The booking object.
 * @param loading - Indicates if the component is in a loading state.
 * @param constraint - The berthing constraint object.
 * @param errors - The berthing errors object.
 * @param setBerthings - Callback function for updating the berthings data.
 * @param hideDeparture - Indicates if the departure time should be hidden.
 * @param plausabilityErrors - Array of plausibility errors.
 * @returns The BerthingInputRow component.
 */
export default function BerthingInputRow({
    berthing,
    onRemove,
    index,
    booking,
    loading = false,
    constraint,
    errors,
    setBerthings,
    hideDeparture = false,
    plausabilityErrors = [],
}: BerthingInputRowProps) {
    const dockId = berthing.dockId;
    const setDockId = (dockId: string) =>
        setBerthings(data => mutateBerthings(data, index, { dockId }));
    const arrivalTime = berthing.arrivalTime;
    const setArrivalTime = (arrivalTime: number) =>
        setBerthings(data => mutateBerthings(data, index, { arrivalTime }));
    const departureTime = berthing.departureTime;
    const setDepartureTime = (departureTime: number) =>
        setBerthings(data => mutateBerthings(data, index, { departureTime }));
    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                minHeight={MIN_HEIGHT}
                className="berthing-row"
            >
                <DateTimePickerInline
                    error={errors.arrivalTime}
                    value={arrivalTime}
                    onChange={v => setArrivalTime(v?.getTime())}
                    minDate={constraint.minArrival}
                    maxDate={constraint.maxArrival}
                />
                <DockInput
                    constraint={constraint}
                    dockId={dockId}
                    onChange={v => setDockId(v)}
                    booking={booking}
                    loading={loading}
                    error={errors.dockId}
                    plausabilityErrors={plausabilityErrors}
                />
                <IconButton
                    className="timeline-gutter btn-delete"
                    size="small"
                    onClick={() => onRemove(index)}
                >
                    <RemoveCircleOutline color="error" />
                </IconButton>
            </Box>
            {!hideDeparture && (
                <Box display="flex" alignItems="center" minHeight={MIN_HEIGHT}>
                    <DateTimePickerInline
                        error={errors.departureTime}
                        value={departureTime}
                        onChange={v => setDepartureTime(v?.getTime())}
                        minDate={constraint.minDeparture}
                        maxDate={constraint.maxDeparture}
                    />
                </Box>
            )}
        </>
    );
}
