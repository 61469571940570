import React, { useState, useCallback } from "react";
import { IStop } from "../../models/stop";
import { searchStops } from "../../api/stop";
import { searchParamsInit } from "../../models/searchParams";
import { useDispatch } from "react-redux";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { useIntl } from "react-intl";
import Messages from "../../localization/Messages";
import StatusChip from "../StatusChip/StatusChip";
import Popover from "@mui/material/Popover";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { IVoyage } from "../../models/voyage";
import { AppState } from "../../store/configureStore";
import { useSelector } from "react-redux";
import "./StatusDisplay.css";

export interface IStatusDisplay {
    voyage: IVoyage;
}

/**
 * Renders the status of a voyage using the StatusChip component.
 * On StatusDisplay hover, a popup appears that lists the statuses of all stops related to that voyage.
 *
 * @component
 * @example
 * ```tsx
 * <StatusDisplay voyage={myVoyage} />
 * ```
 */

export const StatusDisplay = ({ voyage }: IStatusDisplay) => {
    const names = useSelector((s: AppState) => s.destinationZones).names;
    const dispatch = useDispatch();
    const intl = useIntl();
    const [stops, setStops] = useState<IStop[]>([]);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const fetchStops = useCallback(async () => {
        try {
            setLoading(true);
            const res = await searchStops({
                ...searchParamsInit,
                filter: { voyageId: voyage.id },
                order: ["eta asc"],
            });
            setStops(res.data.rows ?? []);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.stops));
        } finally {
            setLoading(false);
        }
    }, [voyage, dispatch, intl]);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        if (stops.length) return;
        fetchStops();
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div className="status-display-container">
            <span
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                <StatusChip status={voyage.status} />
            </span>
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: "none",
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <div className="popover-container">
                    <h3>{intl.formatMessage(Messages.bookingStatus)}</h3>
                    {stops.length ? (
                        <Table>
                            <TableBody>
                                {stops.map(stop => (
                                    <TableRow key={stop.id}>
                                        <TableCell>
                                            {names.get(stop.destinationId) ?? "N/A"}
                                        </TableCell>
                                        <TableCell>
                                            <StatusChip status={stop.bookingStatus ?? ""} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : loading ? (
                        <div className="popover-loading">
                            <CircularProgress />
                        </div>
                    ) : (
                        <h5>{intl.formatMessage(Messages.bookingsUnavailable)}</h5>
                    )}
                </div>
            </Popover>
        </div>
    );
};

export default StatusDisplay;
