import { InvoiceStatus, InvoiceStatuses } from "../models/invoice";
import { IntlShape } from "react-intl";

/**
 * Function that returns the display text for the invoice status.
 * @param status - The invoice status.
 * @param intl - The internationalization object.
 */
export const displayStatus = (status: InvoiceStatus | undefined, intl: IntlShape) => {
    const statusText = InvoiceStatuses.find(s => s.value === status)?.label;
    return statusText ? intl.formatMessage(statusText) : "";
};
