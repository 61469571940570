import { Tooltip } from "@mui/material";
import "./index.css";
import { ReactNode } from "react";

export type LineClampRows = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export type LineClampedTextProps = {
    text: ReactNode;
    rows?: LineClampRows;
    className?: string;
    delay?: number;
};

/**
 * Renders a text component with line clamping and tooltip functionality.
 *
 * @component
 * @example
 * ```tsx
 * import LineClampedText from './components/LineClampedText';
 *
 * function App() {
 *   return (
 *     <div>
 *       <LineClampedText text="Lorem ipsum dolor sit amet, consectetur adipiscing elit." rows={2} />
 *     </div>
 *   );
 * }
 * ```
 */

function LineClampedText({ text, rows = 2, className = "", delay = 500 }: LineClampedTextProps) {
    const textSanitized = text ?? "";
    return (
        <Tooltip title={textSanitized} enterDelay={delay} enterNextDelay={delay}>
            <div className={`line-clamp line-clamp-${rows} ${className}`}>{textSanitized}</div>
        </Tooltip>
    );
}

export default LineClampedText;
