import UploadFileIcon from "@mui/icons-material/UploadFile";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import { FinalizedTooltip as BookingDocumentsTooltip } from "../FinalizeBookingSection/FinalizeBookingSection";
import Messages from "../../../../../localization/Messages";
import RpisDialog from "../../../../RpisDialog/RpisDialog";
import ManageBookingDocuments from "../ManageBookingDocuments/ManageBookingDocuments";
import "./UploadBookingDocument.css";
import { Description } from "@mui/icons-material";
import { useSidebarOwner } from "../../../../../contexts/SidebarOwnerContext";
import { IBooking } from "../../../../../models/booking";

export const UploadBookingDocuments = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const intl = useIntl();
    const [booking] = useSidebarOwner<IBooking>();
    const hasDocuments = Boolean(booking?.documents?.length);

    return (
        <>
            <BookingDocumentsTooltip title={intl.formatMessage(Messages.uploadDocuments)}>
                <IconButton className="upload-icon" onClick={() => setDialogOpen(true)}>
                    {hasDocuments ? <Description /> : <UploadFileIcon />}
                </IconButton>
            </BookingDocumentsTooltip>
            <RpisDialog
                dialogOpen={dialogOpen}
                onClose={() => setDialogOpen(false)}
                title={intl.formatMessage(Messages.uploadDocuments)}
                content={<ManageBookingDocuments />}
                size="md"
                fullWidth={false}
            />
        </>
    );
};
