import { getDateMoment } from "../../../helpers/dateHelper";
import { SortOrder } from "../../../hooks/useTableSort";
import { BookingStatus } from "../../../models/booking";

export type BookingsFilters = {
    dateFrom: Date | null;
    dateTo: Date | null;
    shipName: string;
    docks: string[];
    bookingStatuses: BookingStatus[];
    rowsPerPage: number;
    page: number;
    order: SortOrder;
    finalized: boolean | null;
};

export function buildBookingsSearchPayload(filters: BookingsFilters) {
    return {
        docks: filters.docks,
        offset: filters.page * filters.rowsPerPage,
        limit: filters.rowsPerPage,
        textSearch: filters.shipName,
        order: filters.order,
        columns: {},
        filter: buildBookingsSearchPayloadFilters(filters),
    };
}

function buildBookingsSearchPayloadFilters(filters: BookingsFilters) {
    const { bookingStatuses, dateFrom, dateTo, finalized } = filters;

    const filterArray: object[] = [];

    if (bookingStatuses.length > 0) {
        const bsFilterArray: object[] = [];
        bookingStatuses.forEach(st => bsFilterArray.push({ status: st.toString() }));
        filterArray.push({ $or: bsFilterArray });
    }

    if (dateFrom && !dateTo) {
        const startDate = getDateMoment(dateFrom).toDate();
        const endDate = getDateMoment(dateFrom).toDate();
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 59);
        filterArray.push(
            { arrivalTime: { $gte: startDate.getTime() } },
            { arrivalTime: { $lte: endDate.getTime() } },
        );
    }

    if (dateTo && !dateFrom) {
        const startDate = getDateMoment(dateTo).toDate();
        const endDate = getDateMoment(dateTo).toDate();
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 59);
        filterArray.push(
            { departureTime: { $gte: startDate.getTime() } },
            { departureTime: { $lte: endDate.getTime() } },
        );
    }

    if (dateTo && dateFrom) {
        const startDate = getDateMoment(dateFrom).toDate();
        const endDate = getDateMoment(dateTo).toDate();
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 59);
        filterArray.push(
            { arrivalTime: { $gte: startDate.getTime() } },
            { departureTime: { $lte: endDate.getTime() } },
        );
    }

    if (finalized === true || finalized === false) {
        filterArray.push({ finalized: finalized });
    }

    return { $and: filterArray };
}
