import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { keycloakClient } from "./Keycloak";
import { setUserFromToken } from "./actions/authentificationActions";
import { configureAxiosClient } from "./api/axiosClient";
import SnackbarMessage from "./components/SnackbarMessage/SnackbarMessage";
import DispManageAddresses from "./containers/DispManageAddresses/DispManageAddresses";
import DockAllocationPlan from "./containers/DockAllocationPlan/DockAllocationPlan";
import DockAllocationView from "./containers/DockAllocationPlan/DockAllocationView";
import HomeDispatcher from "./containers/HomeDispatcher/HomeDispatcher";
import HomeLocks from "./containers/HomeLocks/HomeLocks";
import ManageAddresses from "./containers/LDManageAddresses/LDManageAddresses";
import LDManageFleet from "./containers/LDManageFleet/LDManageFleet";
import LDManageVoyages from "./containers/LDManageVoyages/LDManageVoyages";
import PlanVoyage from "./containers/LDManageVoyages/PlanVoyage";
import ManageBookings from "./containers/ManageBookings/ManageBookings";
import DockForm from "./containers/ManageDestination/DockForm";
import ManageDestination from "./containers/ManageDestination/ManageDestination";
import ManageShips from "./containers/ManageShips/ManageShips";
import Progress from "./containers/Progress/Progress";
import Unauthorized from "./containers/Unauthorized/Unauthorized";
import { Roles, UserData } from "./models/userData";
import { AppState, store } from "./store/configureStore";
import DispNotificationSettings from "./containers/DispNotificationSettings/DispNotificationSettings";
import LDNotificationSettings from "./containers/LDNotificationSettings/LDNotificationSettings";
import NotificationsPage from "./containers/NotificationsPage/NotificationsPage";
import ChangeLog from "./containers/ChangeLog/ChangeLog";
import ManageExpenseTypes from "./containers/ManageExpenseTypes/ManageExpenseTypes";
import useAppStateLoad from "./hooks/useAppStateLoad";
import { ManageExpenses } from "./containers/ManageExpenses/ManageExpenses";
import ManageInvoices from "./containers/ManageInvoices/ManageInvoices";
import { ManageInternalBookings } from "./containers/ManageInternalBookings/ManageInternalBookings";
import { HomeOnCall } from "./containers/HomeOnCall/HomeOnCall";
import { LDManageExpenses } from "./containers/LDManageExpenses/LDManageExpenses";
import { useAuth } from "./hooks/useAuth";
import { OCManageBookings } from "./containers/OnCallContainers/OCManageBookings/OCManageBookings";
import { DispManageServices } from "./containers/DispManageServices/DispManageServices";
import { ReactKeycloakProvider } from "@react-keycloak/web";

configureAxiosClient(axios);

const getAppRoutes = (user: UserData) => {
    let routes: React.ReactElement[] = [];

    if (user.roles.includes(Roles.ON_CALL_DISPATCHER)) {
        // prettier-ignore
        routes = [
            <Route id ="home-on-call" key="/*" path="/*" element={<HomeOnCall />} />,
            <Route key="dock-allocation-plan/" path="dock-allocation-plan/" element={<DockAllocationPlan />} />,
            <Route key = "manage-bookings" path = "manage-bookings" element={<OCManageBookings />} />,
            <Route key = "manage-services" path ="manage-services" element = {<DispManageServices />} />,
        ];
    } else if (user.roles.includes(Roles.LOCKS_AND_DOCKS_DISPATCHER)) {
        routes = [
            <Route key="/*" path="/*" element={<HomeLocks />} />,
            <Route key="manage-fleet" path="manage-fleet" element={<LDManageFleet />} />,
            <Route key="manage-voyages" path="manage-voyages" element={<LDManageVoyages />} />,
            <Route key="plan-voyage/:guid" path="plan-voyage/:guid" element={<PlanVoyage />} />,
            <Route key="plan-voyage/" path="plan-voyage/" element={<PlanVoyage />} />,
            <Route key="manage-addresses" path="manage-addresses" element={<ManageAddresses />} />,
            <Route
                key="plan/:destinationId"
                path="plan/:destinationId"
                element={<DockAllocationView />}
            />,
            <Route
                key="notification-settings"
                path="notification-settings"
                element={<LDNotificationSettings />}
            />,
            <Route key="notifications" path="notifications" element={<NotificationsPage />} />,
            <Route key="manage-expenses" path="manage-expenses" element={<LDManageExpenses />} />,
        ];
    } else if (user.roles.includes(Roles.PASSENGER_CABIN_CRUISES_DISPATCHER)) {
        routes = [
            <Route key="/*" path="/*" element={<HomeDispatcher />} />,
            <Route
                key="manage-destination"
                path="manage-destination"
                element={<ManageDestination />}
            />,
            <Route key="manage-ships" path="manage-ships" element={<ManageShips />} />,
            <Route key="dock/:guid" path="dock/:guid" element={<DockForm />} />,
            <Route key="dock/" path="dock/" element={<DockForm />} />,
            <Route key="manage-bookings" path="manage-bookings" element={<ManageBookings />} />,
            <Route
                key="manage-internal-bookings"
                path="manage-internal-bookings"
                element={<ManageInternalBookings />}
            />,
            <Route
                key="manage-addresses"
                path="manage-addresses"
                element={<DispManageAddresses />}
            />,
            <Route
                key="dock-allocation-plan/"
                path="dock-allocation-plan/"
                element={<DockAllocationPlan />}
            />,
            <Route
                key="notification-settings"
                path="notification-settings"
                element={<DispNotificationSettings />}
            />,
            <Route key="notifications" path="notifications" element={<NotificationsPage />} />,
            <Route
                key="manage-expense-types"
                path="manage-expense-types"
                element={<ManageExpenseTypes />}
            />,
            <Route key="manage-invoices" path="manage-invoices" element={<ManageInvoices />} />,
            <Route key="manage-expenses" path="manage-expenses" element={<ManageExpenses />} />,
            <Route key="change-log" path="change-log" element={<ChangeLog />} />,
            <Route key="manage-services" path="manage-services" element={<DispManageServices />} />,
            //
        ];
    } else {
        routes = [
            <Route key="/*" path="/*" element={<Unauthorized />} />,
            <Route
                key="plan/:destinationId"
                path="plan/:destinationId"
                element={<DockAllocationView />}
            />,
        ];
    }

    return routes.map((r, i) => {
        return { ...r, key: i };
    });
};

export const App = () => {
    const toast = useSelector((s: AppState) => s.toast);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const location = useLocation();
    const { user } = useAuth();
    const [isExistingUser, setIsExistingUser] = useState<boolean | null>(null);
    const [loaded] = useAppStateLoad({ setIsExistingUser });

    useEffect(() => {
        setOpenSnackbar(toast.timestamp !== 0);
    }, [toast.timestamp, setOpenSnackbar]);

    return (
        <ReactKeycloakProvider
            initOptions={{ onLoad: "login-required" }}
            authClient={keycloakClient}
            onTokens={e => e.token && store.dispatch(setUserFromToken(e.token))}
        >
            {user.token && isExistingUser !== null && loaded ? (
                <>
                    {isExistingUser ? (
                        <Routes location={location}>
                            <>{getAppRoutes(user)}</>
                        </Routes>
                    ) : (
                        <ManageDestination setIsExistingUser={setIsExistingUser} />
                    )}

                    <SnackbarMessage
                        autoHideDuration={toast.life}
                        title={toast.text}
                        open={openSnackbar}
                        setOpen={setOpenSnackbar}
                        severity={toast.severity}
                    />
                </>
            ) : (
                <Progress />
            )}
        </ReactKeycloakProvider>
    );
};

export default App;
