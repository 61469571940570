import { useIntl } from "react-intl";
import { IBooking } from "../../../models/booking";
import AddressContainer from "./components/AddressContainer/AddressContainer";
import { useEffect, useState } from "react";
import { IAddress } from "../../../models/address";
import { searchAddress } from "../../../api/dispAddress";
import { searchParamsInit } from "../../../models/searchParams";
import { useDispatch } from "react-redux";
import { showBackendMessage } from "../../../helpers/messagesHelper";
import Messages from "../../../localization/Messages";
import "./ViewAddress.css";
import { Skeleton } from "@mui/material";

export type ViewAddressesProps = {
    row: IBooking | undefined;
};

/**
 * Renders the address information for a particular booking in the Manage Bookings page when the "View Address" action is pressed.
 *
 * @component
 * @param {ViewAddressesProps} props - The component props.
 * @param {IBooking | undefined} props.row - The booking data for which the address information is to be displayed.
 * @returns {JSX.Element} The rendered component.
 */
export const ViewAddresses = ({ row }: ViewAddressesProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [invoiceAddress, setInvoiceAddress] = useState<IAddress | undefined>();
    const [contactDetails, setContactDetails] = useState<IAddress | undefined>();
    const [charterCompany, setCharterCompany] = useState<IAddress | undefined>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const res = await searchAddress({
                    ...searchParamsInit,
                    filter: {
                        docksId: {
                            $in: [
                                row?.invoiceAddressDocksId ?? "",
                                row?.contactDetailsDocksId ?? "",
                                row?.charterCompanyDocksId ?? "",
                            ].filter(x => x),
                        },
                    },
                });
                setInvoiceAddress(
                    res.data.rows.find(add => add.docksId === row?.invoiceAddressDocksId),
                );
                setCharterCompany(
                    res.data.rows.find(add => add.docksId === row?.charterCompanyDocksId),
                );
                setContactDetails(
                    res.data.rows.find(add => add.docksId === row?.contactDetailsDocksId),
                );
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.addresses));
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [row, dispatch, intl]);

    return (
        <div className="addresses-data-container">
            {loading ? (
                <div className="column-form four-column-form">
                    {[...Array(4).keys()].map(rowIndex => (
                        <div key={rowIndex}>
                            {[...Array(3).keys()].map(columnIndex => (
                                <div key={columnIndex} className="row-skeleton">
                                    <Skeleton key={columnIndex} variant="rectangular" height={40} />
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            ) : (
                <>
                    {invoiceAddress && (
                        <>
                            <h3>{intl.formatMessage(Messages.invoiceAddress)}</h3>
                            <AddressContainer address={invoiceAddress} />
                        </>
                    )}
                    {contactDetails && (
                        <>
                            <h3>{intl.formatMessage(Messages.contactDetails)}</h3>
                            <AddressContainer address={contactDetails} />
                        </>
                    )}
                    {charterCompany && (
                        <>
                            <h3>{intl.formatMessage(Messages.charterCompany)}</h3>
                            <AddressContainer address={charterCompany} />
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default ViewAddresses;
