import { RemoveCircleOutline } from "@mui/icons-material";
import {
    Card,
    CardActions,
    Button,
    TextField,
    IconButton,
    Dialog,
    Box,
    Typography,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useState } from "react";
import { Datatable } from "../../../../components/Datatable/Datatable";
import { DateFormat, getDateText } from "../../../../helpers/dateHelper";
import "./EditHolidaysButton.css";
import { Holiday, IAddEditDestination } from "../../../../models/destination";
import { changeDestination } from "../../../../api/destinations";
import { showBackendMessage } from "../../../../helpers/messagesHelper";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import Messages from "../../../../localization/Messages";

export type EditHolidaysButtonProps = {
    destination: IAddEditDestination;
    holidays?: Holiday[];
    onSaveSuccess?: (holidays: Holiday[]) => void;
};

export function EditHolidaysButton({
    holidays: localHolidays = [],
    onSaveSuccess,
    destination,
}: EditHolidaysButtonProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [holidays, setHolidays] = useState(localHolidays);
    const dispatch = useDispatch();
    const intl = useIntl();

    const handleSubmit = async () => {
        try {
            await changeDestination({ ...destination, holidays });
            dispatch(showBackendMessage(intl, "success", "updating", Messages.destination));
            setIsOpen(false);
            onSaveSuccess?.(holidays);
        } catch {
            dispatch(showBackendMessage(intl, "error", "updating", Messages.destination));
        }
    };

    return (
        <>
            <Button variant="contained" onClick={() => setIsOpen(true)}>
                Edit holidays
            </Button>
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <Box
                    p={1.5}
                    justifyContent="space-between"
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap"
                >
                    <Typography variant="h5">Holidays</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                            setHolidays([
                                ...(holidays ?? []),
                                { name: "", date: getDateText(Date.now(), DateFormat.API_DATE) },
                            ])
                        }
                    >
                        Add new entry
                    </Button>
                </Box>
                <Card sx={{ overflow: "auto" }}>
                    <Datatable
                        className="holiday-datatable"
                        size="small"
                        items={holidays}
                        onSortChange={() => {}}
                        titleMapper={({ name }) => name}
                        sortOrder={[]}
                        columnDefs={[
                            {
                                key: "name",
                                label: "Name",
                                sortable: false,
                                content: (row, index) => (
                                    <TextField
                                        sx={{ m: "0 !important" }}
                                        value={row.name}
                                        placeholder="Name"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        onChange={e =>
                                            setHolidays(
                                                holidays.map((holiday, i) =>
                                                    i === index
                                                        ? { ...holiday, name: e.target.value }
                                                        : holiday,
                                                ),
                                            )
                                        }
                                    />
                                ),
                            },
                            {
                                key: "date",
                                label: "Date",
                                sortable: false,
                                content: ({ date }, index) => (
                                    <DesktopDatePicker
                                        value={getDateText(date, DateFormat.API_DATE)}
                                        onChange={v => {
                                            if (!v) return;
                                            setHolidays(
                                                holidays.map((h, i) => ({
                                                    ...h,
                                                    date:
                                                        index === i
                                                            ? getDateText(v, DateFormat.API_DATE)
                                                            : h.date,
                                                })),
                                            );
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                sx={{ m: "0 !important" }}
                                                placeholder="Date"
                                                variant="outlined"
                                                size="small"
                                            />
                                        )}
                                    />
                                ),
                            },
                            {
                                key: "actions",
                                label: "Actions",
                                sortable: false,
                                content: (_, index) => (
                                    <IconButton
                                        color="error"
                                        onClick={() =>
                                            setHolidays(holidays.filter((_, i) => i !== index))
                                        }
                                    >
                                        <RemoveCircleOutline />
                                    </IconButton>
                                ),
                            },
                        ]}
                    />

                    <CardActions sx={{ justifyContent: "flex-end", p: 1.5 }}>
                        <Button variant="contained" color="success" onClick={handleSubmit}>
                            Save
                        </Button>
                        <Button variant="outlined" onClick={() => setIsOpen(false)}>
                            Close
                        </Button>
                    </CardActions>
                </Card>
            </Dialog>
        </>
    );
}
