import { useIntl } from "react-intl";
import { IShipDispatcher } from "../../../../models/shipDispatcher";
import "./index.css";
import Messages from "../../../../localization/Messages";
import DataField from "../../../../components/DataField";
import { memo } from "react";

export interface IViewDockAccessData {
    selectedShip: IShipDispatcher;
}

/**
 * Represents a modal dialog that displays dock access data for a ship used in a booking.
 * This component is used to view various details related to dock access for a selected ship.
 * It renders a set of DataField components to display the specific details.
 */
function ViewDockAccessData({ selectedShip }: IViewDockAccessData) {
    const intl = useIntl();

    return (
        <div className="dock-access-data-container">
            <div className="column-form four-column-form">
                <DataField
                    title={intl.formatMessage(Messages.keyNumber)}
                    value={selectedShip.keyNumber}
                />
                <DataField
                    title={intl.formatMessage(Messages.keyDeliveryDate)}
                    value={selectedShip.keyDeliveryDate}
                />
                <DataField
                    title={intl.formatMessage(Messages.electricityCardNumber)}
                    value={selectedShip.electricityCardNumber}
                />
                <DataField
                    title={intl.formatMessage(Messages.electricityCardDeliveryDate)}
                    value={selectedShip.electricityCardDeliveryDate}
                />
                <DataField
                    title={intl.formatMessage(Messages.dateOfIssueCurrentPlugInCard)}
                    value={selectedShip.dateIssueCurPluginCard}
                />
                <DataField
                    title={intl.formatMessage(Messages.portRelatedCustomerNumber)}
                    value={selectedShip.portRelCustomerNumber}
                />
                <DataField
                    title={intl.formatMessage(Messages.lastInspection)}
                    value={selectedShip.lastInspection}
                />
                <DataField
                    title={intl.formatMessage(Messages.inspectionCount)}
                    value={selectedShip.inspectionCount}
                />
                <DataField
                    title={intl.formatMessage(Messages.currentAmps)}
                    value={`${selectedShip.currentAmps ?? 0} A`}
                />
                <DataField
                    title={intl.formatMessage(Messages.passengerBeds)}
                    value={selectedShip.passengerBeds}
                />
            </div>
            <div className="column-form two-column-form">
                <DataField
                    title={intl.formatMessage(Messages.remarksFgksDispatcher)}
                    value={selectedShip.remarks}
                />
                <DataField
                    title={intl.formatMessage(Messages.remarksTechnicalInformation)}
                    value={selectedShip.remarksTechnical}
                />
            </div>
        </div>
    );
}

export default memo(ViewDockAccessData);
