import { useEffect, useState } from "react";
import { checkPlausability } from "../../../api/dock";
import { IBerthing } from "../../../models/berthing";
import { IPlausabilityResponse } from "../../../models/plausability";
import { useIntl } from "react-intl";
import Messages from "../../../localization/Messages";
import { IBooking } from "../../../models/booking";

/**
 * Props for the `useBerthingsPlausabilityChecks` hook.
 */
export type UseBerthingsPlausabilityChecksProps = {
    berthings: IBerthing[];
    booking: IBooking;
};

/**
 * Props for the `fetchPlausabilityCheck` function.
 */
type CheckPlausabilityProps = {
    dockId?: string;
    departureTime?: number;
    arrivalTime?: number;
    berthingId?: string | null;
    shipLength?: number | null;
};

/**
 * Fetches the plausability check for a given set of properties.
 * @param props - The plausability check properties.
 * @returns The plausability response or `null` if the required properties are missing.
 */
async function fetchPlausabilityCheck(
    props: CheckPlausabilityProps,
): Promise<IPlausabilityResponse | null> {
    if (!props.dockId || !props.arrivalTime || !props.departureTime) return null;
    const dockId = props.dockId;
    const eta = props.arrivalTime;
    const etd = props.departureTime;
    const shipLength = props.shipLength ?? 0;
    const berthingId = props.berthingId ?? null;
    const plausabilityParam = { dockId, etd, eta, shipLength, berthingId };
    const apiResponse = await checkPlausability(plausabilityParam);
    const plausability: IPlausabilityResponse = apiResponse.data;
    return plausability;
}

/**
 * Custom hook for performing plausability checks on a list of berthings.
 * @param param0 - The hook parameters.
 * @returns A list of plausability errors for each berthing.
 */
function useBerthingsPlausabilityChecks({
    berthings,
    booking,
}: UseBerthingsPlausabilityChecksProps) {
    const intl = useIntl();
    const bookingShipLength = booking?.ship?.length ?? 0;
    const [plausabilityErrorsList, setPlausabilityErrorsList] = useState<string[][]>(() =>
        Array.from({ length: berthings.length }, () => []),
    );

    useEffect(() => {
        const fetchPlausability = async () => {
            const plausabilityChecks = await Promise.all(
                berthings.map(async berthing => {
                    const plausability = await fetchPlausabilityCheck({
                        dockId: berthing.dockId,
                        arrivalTime: berthing.arrivalTime,
                        departureTime: berthing.departureTime,
                        berthingId: berthing.id,
                        shipLength: bookingShipLength,
                    });
                    return plausability;
                }),
            );
            setPlausabilityErrorsList(
                plausabilityChecks.map(plausability => {
                    if (!plausability) return [];
                    const errorsList: string[] = [];
                    if (!plausability.lengthOk) {
                        errorsList.push(
                            intl.formatMessage(Messages.plausabilityLengthOkError, {
                                shipLength: bookingShipLength,
                                dockLength: plausability.dockLength,
                            }),
                        );
                    }
                    if (!plausability.arrivingInsideWorkingHours) {
                        errorsList.push(
                            intl.formatMessage(
                                Messages.plausabilityArrivingInsideWorkingHoursError,
                            ),
                        );
                    }
                    if (!plausability.availableInThePeriod) {
                        errorsList.push(
                            intl.formatMessage(Messages.plausabilityAvailableInThePeriodError),
                        );
                    }
                    return errorsList;
                }),
            );
        };
        fetchPlausability();
    }, [berthings, intl, bookingShipLength]);

    return plausabilityErrorsList;
}

export default useBerthingsPlausabilityChecks;
