import {
    TableRow,
    TableCell,
    Collapse,
    Box,
    Table,
    TableHead,
    TableBody,
    Typography,
} from "@mui/material";
import classNames from "classnames";
import { getDateText, DateFormat } from "../../../../helpers/dateHelper";
import Messages from "../../../../localization/Messages";
import "./index.css";
import { useIntl } from "react-intl";
import { IBerthing } from "../../../../models/berthing";
import NoDataRow from "../../../../components/NoDataRow";
import { memo } from "react";
import { useCachedDocks } from "../../../../hooks/useCachedDocks";

export type BookingAccordionProps = {
    open: boolean;
    colSpan: number;
    berthings?: IBerthing[];
    active?: boolean;
};

/**
 * Represents the extendable part of a table row in the Manage Bookings page.
 * It displays the berthings of a booking in a table format.
 */
function BookingAccordion({
    open,
    berthings = [],
    active = false,
    colSpan,
}: BookingAccordionProps) {
    const intl = useIntl();
    const { byId } = useCachedDocks();

    return (
        <TableRow
            className={classNames("expanded-table-row", {
                "active-table-row": active,
            })}
        >
            <TableCell colSpan={colSpan} scope="row">
                <Collapse timeout="auto" unmountOnExit in={open}>
                    <Box className="berthings-wrapper">
                        <Typography variant="h6">
                            {intl.formatMessage(Messages.berthings)}
                        </Typography>
                        <Table className="accordion-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        {intl.formatMessage(Messages.assignedDock)}
                                    </TableCell>
                                    <TableCell>
                                        {intl.formatMessage(Messages.arrivalTime)}
                                    </TableCell>
                                    <TableCell>
                                        {intl.formatMessage(Messages.departureTime)}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {berthings.length === 0 ? (
                                    <NoDataRow colSpan={3} />
                                ) : (
                                    berthings.map(berthing => (
                                        <TableRow key={berthing.id}>
                                            <TableCell>{byId.get(berthing.dockId)?.name}</TableCell>
                                            <TableCell>
                                                {getDateText(
                                                    berthing.arrivalTime,
                                                    DateFormat.CLIENT_DATE_TIME,
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {getDateText(
                                                    berthing.departureTime,
                                                    DateFormat.CLIENT_DATE_TIME,
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    );
}

export default memo(BookingAccordion);
