import { IManagingCompany } from "../models/managingCompany";

export const SET_MANAGING_COMPANIES = "SET_MANAGING_COMPANIES";

export interface SetManagingCompanies {
    type: typeof SET_MANAGING_COMPANIES;
    payload: IManagingCompany[];
}

export type ManagingCompaniesActionType = SetManagingCompanies;

/**
 * Sets the managing companies from the request.
 * @param payload - The managing companies to set.
 * @returns The action to execute.
 */
export function setManagingCompaniesFromRequest(
    payload: IManagingCompany[],
): ManagingCompaniesActionType {
    return { type: SET_MANAGING_COMPANIES, payload };
}
