import { useEffect, useState } from "react";
import {
    AutoCalculationType,
    ExpenseCategory,
    IExpensePeriod,
    IExpenseType,
    expenseCategories,
    expensePeriodInit,
    expenseTypeInit,
    getActivePeriod,
} from "../../models/expenseType";
import {
    TextField,
    Button,
    Autocomplete,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    InputAdornment,
    FormControl,
    InputLabel,
    Select,
    SelectChangeEvent,
    MenuItem,
    Grid,
    Box,
    Tooltip,
} from "@mui/material";
import { useIntl } from "react-intl";
import Messages from "../../localization/Messages";
import moment, { Moment } from "moment";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { IDock } from "../../models/dock";
import { addExpenseType } from "../../api/expenseType";
import { addExpensePeriod } from "../../api/expensePeriod";
import { useDispatch } from "react-redux";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { DateFormat, getDateText } from "../../helpers/dateHelper";
import { useEnumOptions } from "../../hooks/useEnumOptions";
import TimetableEditor from "../../components/TimetableEditor/TimetableEditor";
import { timetableInit } from "../../models/timetable";
import { useCachedDocks } from "../../hooks/useCachedDocks";

type ExpenseTypeErrors = { [Key in keyof Partial<IExpenseType>]: string };
type ExpensePeriodErrors = { [Key in keyof Partial<IExpensePeriod>]: string };

interface IExpenseTypeForm {
    selectedExpenseType?: IExpenseType;
    callback: () => void;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Renders the Expense Type form inside the sidebar of the Manage Expense Types page.
 * The Expense Type form is coupled with the Expense Period form.
 * When adding a new Expense Type, the user enters the Expense Period as well.
 * However, when adding a new Period, the Expense Type fields are disabled.
 *
 * @component
 * @example
 * return (
 *   <ExpenseTypeForm
 *     selectedExpenseType={selectedExpenseType}
 *     callback={callback}
 *     setSidebarOpen={setSidebarOpen}
 *   />
 * )
 */
export const ExpenseTypeForm = ({
    selectedExpenseType,
    callback,
    setSidebarOpen,
}: IExpenseTypeForm) => {
    const { docks } = useCachedDocks();
    const autoCalculationTypeOptions = useEnumOptions({ AutoCalculationType });
    const [expenseType, setExpenseType] = useState<IExpenseType>(expenseTypeInit);
    const [expensePeriod, setExpensePeriod] = useState<IExpensePeriod>(expensePeriodInit);
    const intl = useIntl();
    const [typeErrors, setTypeErrors] = useState<ExpenseTypeErrors>({});
    const [periodErrors, setPeriodErrors] = useState<ExpensePeriodErrors>({});
    const [typeFormDisabled, setTypeFormDisabled] = useState(false);
    const dispatch = useDispatch();
    const [dialogOpen, setDialogOpen] = useState(false);

    const onChangeType = (diff: Partial<IExpenseType>) => {
        setExpenseType(prev => ({ ...prev, ...diff }));
        const clearErrors = Object.fromEntries(Object.entries(diff).map(([k]) => [k, undefined]));
        setTypeErrors({ ...typeErrors, ...clearErrors });
    };

    useEffect(() => {
        setExpenseType(selectedExpenseType ? { ...selectedExpenseType } : { ...expenseTypeInit });
        setTypeFormDisabled(Boolean(selectedExpenseType));
        if (selectedExpenseType?.periods) {
            const activePeriod = getActivePeriod(selectedExpenseType.periods);
            activePeriod && setExpensePeriod(activePeriod);
        } else {
            setExpensePeriod(expensePeriodInit);
        }
    }, [selectedExpenseType]);

    const onChangePeriod = (diff: Partial<IExpensePeriod>) => {
        setExpensePeriod(prev => ({ ...prev, ...diff }));
        const clearErrors = Object.fromEntries(Object.entries(diff).map(([k]) => [k, undefined]));
        setPeriodErrors({ ...periodErrors, ...clearErrors });
    };

    const parseNumericOrNull = (input: string, decimal: boolean) => {
        const value = decimal ? Number.parseFloat(input) : Number.parseInt(input);
        return isNaN(value) ? null : decimal ? parseFloat(value.toFixed(2)) : value;
    };

    const validateValidFromDate = (validFromDate: string) => {
        const periods = selectedExpenseType?.periods;
        return periods?.some(period => moment(validFromDate) <= moment(period.validFrom)) || false;
    };

    const validateExpenseTypeForm = () => {
        const newPeriodErrors: ExpensePeriodErrors = {};
        const newTypeErrors: ExpenseTypeErrors = {};

        if (!selectedExpenseType) {
            if (!expenseType.category) {
                newTypeErrors.category = intl.formatMessage(Messages.categoryIsRequired);
            }
            if (!expenseType.accRef) {
                newTypeErrors.accRef = intl.formatMessage(Messages.SAPIsRequired);
            }
            if (!expenseType.unit) {
                newTypeErrors.unit = intl.formatMessage(Messages.unitIsRequired);
            }
        }
        if (!expensePeriod.name) {
            newPeriodErrors.name = intl.formatMessage(Messages.nameOfExpenseIsRequired);
        }

        if (!expensePeriod.netPricePerUnit) {
            newPeriodErrors.netPricePerUnit = intl.formatMessage(
                Messages.netPricePerUnitIsRequired,
            );
        }
        if (!expensePeriod.vat && expensePeriod.vat !== 0) {
            newPeriodErrors.vat = intl.formatMessage(Messages.vatIsrequired);
        }
        if (!expensePeriod.validFrom) {
            newPeriodErrors.validFrom = intl.formatMessage(Messages.validFromIsRequired);
        }
        if (
            expensePeriod.validTo &&
            expensePeriod.validFrom &&
            expensePeriod.validTo <= expensePeriod.validFrom
        ) {
            newPeriodErrors.validTo = intl.formatMessage(Messages.validToMustBeAfterValidFrom);
        }
        if (expensePeriod.validFrom && isNaN(parseInt(expensePeriod.validFrom))) {
            newPeriodErrors.validFrom = intl.formatMessage(Messages.wrongDateFormat);
        }

        if (expensePeriod.validFrom && validateValidFromDate(expensePeriod.validFrom)) {
            newPeriodErrors.validFrom = intl.formatMessage(Messages.validFromDateInvalid);
        }
        if (expensePeriod.validTo && isNaN(parseInt(expensePeriod.validTo))) {
            newPeriodErrors.validTo = intl.formatMessage(Messages.wrongDateFormat);
        }
        if (expenseType.ordNum === undefined || expenseType.ordNum < 0) {
            newTypeErrors.ordNum = intl.formatMessage(Messages.ordNumIsRequired);
        }

        return { period: newPeriodErrors, type: newTypeErrors };
    };

    const handleSubmit = () => {
        const newErrors = validateExpenseTypeForm();
        if (Object.keys(newErrors.period).length || Object.keys(newErrors.type).length) {
            setPeriodErrors(newErrors.period);
            setTypeErrors(newErrors.type);
            return;
        }
        if (selectedExpenseType) {
            setDialogOpen(true);
        } else {
            handleSave();
        }
    };

    const handleSave = async () => {
        try {
            if (selectedExpenseType) {
                await addExpensePeriod({
                    ...expensePeriod,
                    expenseTypeId: selectedExpenseType.id,
                });
            } else {
                const res = await addExpenseType(expenseType);
                await addExpensePeriod({
                    ...expensePeriod,
                    expenseTypeId: res.data.id,
                });
            }
            dispatch(showBackendMessage(intl, "success", "creating", Messages.expenseTypes));
            callback();
            setExpenseType(expenseTypeInit);
            setExpensePeriod(expensePeriodInit);
            setSidebarOpen(false);
        } catch {
            dispatch(showBackendMessage(intl, "error", "creating", Messages.expenseTypes));
        } finally {
            setDialogOpen(false);
        }
    };

    return (
        <div className="expense-type-form">
            <Grid alignItems="center" container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Tooltip
                        title={
                            typeFormDisabled ? intl.formatMessage(Messages.orderNumberMessage) : ""
                        }
                    >
                        <TextField
                            required
                            value={expenseType.ordNum ?? ""}
                            label={intl.formatMessage(Messages.ordnum)}
                            error={Boolean(typeErrors.ordNum)}
                            helperText={typeErrors.ordNum}
                            variant="filled"
                            onChange={e => {
                                const text = e.target.value;
                                const isPositiveInteger =
                                    Number.isInteger(Number(text)) && Number(text) >= 0;
                                const ordNum: number | undefined = isPositiveInteger
                                    ? Number(text)
                                    : undefined;
                                onChangeType({ ordNum: text === "" ? undefined : ordNum });
                            }}
                            InputProps={{ readOnly: typeFormDisabled }}
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        value={expensePeriod.name ?? expenseType.name ?? ""}
                        label={intl.formatMessage(Messages.nameOfExpense)}
                        error={Boolean(periodErrors.name)}
                        helperText={periodErrors.name}
                        variant="filled"
                        onChange={e => {
                            onChangeType({ name: e.target.value });
                            onChangePeriod({ name: e.target.value });
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        id="expense-category"
                        options={expenseCategories}
                        getOptionLabel={option => intl.formatMessage(option.label)}
                        isOptionEqualToValue={(a, b) => a.id === b.id}
                        disabled={typeFormDisabled}
                        renderInput={props => (
                            <TextField
                                required
                                {...props}
                                label={intl.formatMessage(Messages.category)}
                                error={Boolean(typeErrors.category)}
                                helperText={typeErrors.category}
                                variant="filled"
                            />
                        )}
                        renderOption={(props, option) => (
                            <li {...props}>{intl.formatMessage(option.label)}</li>
                        )}
                        onChange={(_, value) => {
                            onChangeType({ category: value?.value });
                        }}
                        value={
                            expenseCategories.find(cat => cat.value === expenseType.category) ??
                            null
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        value={expenseType.accRef}
                        label={intl.formatMessage(Messages.SAPNumber)}
                        error={Boolean(typeErrors.accRef)}
                        helperText={typeErrors.accRef}
                        variant="filled"
                        onChange={e => onChangeType({ accRef: e.target.value })}
                        InputProps={{ readOnly: typeFormDisabled }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        value={expenseType.unit}
                        label={intl.formatMessage(Messages.unit)}
                        error={Boolean(typeErrors.unit)}
                        helperText={typeErrors.unit}
                        variant="filled"
                        onChange={e => onChangeType({ unit: e.target.value })}
                        InputProps={{ readOnly: typeFormDisabled }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        type="number"
                        value={expensePeriod.netPricePerUnit ?? ""}
                        label={intl.formatMessage(Messages.netPricePerUnit)}
                        error={Boolean(periodErrors.netPricePerUnit)}
                        helperText={periodErrors.netPricePerUnit}
                        variant="filled"
                        onChange={e =>
                            onChangePeriod({
                                netPricePerUnit: parseNumericOrNull(e.target.value, true),
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        type="number"
                        value={expensePeriod.vat ?? ""}
                        label={intl.formatMessage(Messages.vatNumber)}
                        error={Boolean(periodErrors.vat)}
                        helperText={periodErrors.vat}
                        variant="filled"
                        onChange={e =>
                            onChangePeriod({
                                vat: parseNumericOrNull(e.target.value, true),
                            })
                        }
                        InputProps={{
                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DesktopDatePicker
                        label={intl.formatMessage(Messages.validFrom)}
                        inputFormat={DateFormat.CLIENT_DATE}
                        value={
                            expensePeriod.validFrom
                                ? getDateText(expensePeriod.validFrom, DateFormat.API_DATE)
                                : null
                        }
                        onChange={(value: Moment | null) => {
                            onChangePeriod({
                                validFrom: value ? value?.format(DateFormat.API_DATE) : null,
                            });
                        }}
                        renderInput={params => (
                            <TextField
                                variant="filled"
                                {...params}
                                helperText={periodErrors.validFrom}
                                error={Boolean(periodErrors.validFrom)}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DesktopDatePicker
                        label={intl.formatMessage(Messages.validTo)}
                        inputFormat={DateFormat.CLIENT_DATE}
                        value={
                            expensePeriod.validTo
                                ? getDateText(expensePeriod.validTo, DateFormat.API_DATE)
                                : null
                        }
                        onChange={(value: Moment | null) => {
                            onChangePeriod({
                                validTo: value ? value?.format(DateFormat.API_DATE) : null,
                            });
                        }}
                        renderInput={params => (
                            <TextField
                                variant="filled"
                                {...params}
                                helperText={periodErrors.validTo}
                                error={Boolean(periodErrors.validTo)}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        options={docks}
                        getOptionLabel={(option: IDock) => option.name}
                        isOptionEqualToValue={(a, b) => a.id === b.id}
                        renderInput={params => (
                            <TextField
                                {...params}
                                required
                                variant="filled"
                                label={intl.formatMessage(Messages.docks)}
                            />
                        )}
                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        onChange={(_, value: IDock[] | null) => {
                            onChangePeriod({
                                docks: value?.map(dock => dock.id) ?? [],
                            });
                        }}
                        value={docks.filter(dock => expensePeriod.docks.includes(dock.id)) ?? null}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl variant="filled" sx={{ minWidth: 200 }}>
                        <InputLabel>{intl.formatMessage(Messages.calculationType)}</InputLabel>
                        <Select
                            value={
                                expenseType.autoCalculationType ??
                                AutoCalculationType.NOT_APPLICABLE
                            }
                            label={intl.formatMessage(Messages.autoCalculationType)}
                            variant="filled"
                            onChange={(event: SelectChangeEvent<AutoCalculationType>) =>
                                onChangeType({
                                    autoCalculationType: event.target.value as AutoCalculationType,
                                })
                            }
                            disabled={typeFormDisabled}
                        >
                            {autoCalculationTypeOptions.map(({ value, label }) => (
                                <MenuItem key={value} value={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Box className="expense-type-form-actions" marginTop={3}>
                <Button
                    disabled={false}
                    variant="contained"
                    className="submit-expense-type-form-button button-primary"
                    onClick={() => handleSubmit()}
                >
                    {intl.formatMessage(Messages.save)}
                </Button>
                <Button
                    className="cancel-button"
                    variant="outlined"
                    color="primary"
                    onClick={() => setSidebarOpen(false)}
                >
                    {intl.formatMessage(Messages.cancel)}
                </Button>
            </Box>

            {expenseType.category === ExpenseCategory.SERVICES && (
                <TimetableEditor
                    title={intl.formatMessage(Messages.timeTable)}
                    setTimetable={timeTable => onChangePeriod({ timeTable })}
                    timetable={
                        expensePeriod.timeTable && Object.keys(expensePeriod.timeTable).length
                            ? expensePeriod.timeTable
                            : timetableInit
                    }
                />
            )}

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>{intl.formatMessage(Messages.saveExpensePeriod)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {intl.formatMessage(Messages.saveExpensePeriodMessage)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} color="primary">
                        {intl.formatMessage(Messages.cancel)}
                    </Button>
                    <Button variant="outlined" onClick={handleSave} color="primary" autoFocus>
                        {intl.formatMessage(Messages.save)}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default ExpenseTypeForm;
