import Messages from "../localization/Messages";

export interface IChangeLog {
    action: ActionType;
    companyId: string;
    entity: string;
    id: string;
    numId: number | null;
    old: any;
    value: any;
    timestamp: number;
    userId: string;
    strId: string;
}

export const ChangeLogAction = {
    CHANGE: Messages.change,
    ADD: Messages.add,
    DELETE: Messages.delete,
};

export const ChangeLogEntityOptions = [
    "Address",
    "Booking",
    "Invoice",
    "Expense type",
    "Expense period",
];

export type ActionType = "CHANGE" | "ADD" | "DELETE";
