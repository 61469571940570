import { IDock } from "../models/dock";

export const SET_DOCKS = "SET_DOCKS";

export interface SetDocks {
    type: typeof SET_DOCKS;
    payload: IDock[];
}

export type DocksActionType = SetDocks;

/**
 * Sets the docks from the request.
 * @param payload - The docks to set.
 * @returns The action to execute.
 */
export function setDocksFromRequest(payload: IDock[]): DocksActionType {
    return { type: SET_DOCKS, payload };
}
