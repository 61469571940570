/**
 * Get key by value from map
 * @param map
 * @param searchKeyByValue
 * @returns
 */
export function getKeyByValue<K, V>(map: Map<K, V>, searchKeyByValue: V): K {
    const myKey = [...map.entries()].filter(([, v]) => v === searchKeyByValue).map(([k]) => k)[0];
    return myKey;
}
