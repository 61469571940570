import { MouseEvent, useCallback, useMemo } from "react";
import { TableCellSortedMetadata } from "../components/TableCellSorted";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import TableCellSorted from "../components/TableCellSorted/index";

export type SortDirectionKey = "asc" | "desc";

export type SortDirection<TDir extends SortDirectionKey | undefined = undefined> =
    TDir extends undefined ? SortDirectionKey : TDir;

export type SortCriteria<
    TCol extends string,
    TDir extends SortDirection = "asc",
> = `${TCol} ${TDir}`;

export type SortOrder = SortCriteria<string, SortDirection>[];

export type SortRegister<TCols extends readonly string[]> = (
    column: TCols[number],
) => TableCellSortedMetadata;

/**
 * Returns the inverted sort direction based on the given direction.
 * If the direction is undefined, it returns the default value.
 * @param direction The current sort direction.
 * @param defaultValue The default sort direction.
 * @returns The inverted sort direction.
 */
function getInvertedSortDirection(
    direction: SortDirectionKey | undefined,
    defaultValue: SortDirectionKey = "asc",
) {
    return direction ? (direction === "asc" ? "desc" : "asc") : defaultValue;
}

/**
 * Retrieves the sorting data for a specific column.
 * @param order - The array of sorting criteria.
 * @param column - The column to retrieve the sorting data for.
 * @returns An object containing the priority and direction of the sorting for the specified column, or null if no sorting data is found.
 */
function getColumnData(order: SortOrder, column: string) {
    const data = order.find(criteria => criteria.split(" ")[0] === column);
    if (!data) return null;
    const priority = order.indexOf(data) + 1;
    const direction = data.split(" ")[1] as SortDirectionKey;
    return { priority, direction };
}

export type UseTableSortProps<TCols extends readonly string[]> = {
    value: SortOrder;
    onChange: (order: SortOrder) => void;
    columns: TCols;
    defaultDirection?: SortDirectionKey;
};

/**
 * A custom table sorting hook which exposes a function that returns mandatory props of {@link TableCellSorted} React component.
 * @param options - The sorting options.
 * @param options.columns - The columns to register sorting for.
 * @param options.value - The current sort order.
 * @param options.onChange - The callback function to handle sort order changes.
 * @param options.defaultDirection - The default sort direction.
 * @returns A function to register a sortable column.
 *
 * @example
 * 1: Usage with `useState` React hook as the state manager:
 * ```tsx
 * import * as React from "react";
 * import { SortOrder } from "./hooks/useTableSort";
 * import TableCellSorted from "./components/TableCellSorted";
 *
 * const DemoComponent = () => {
 *   const [order, setOrder] = React.useState<SortOrder>([]);
 *   const registerSort = useTableSort<["shipName", "shipClass"]>({
 *     columns: ["shipName", "shipClass"],
 *     value: order,
 *     onChange: o => setOrder(o)
 *   });
 *
 *   return (
 *     <TableCellSorted
 *       label="Ship name"
 *       {...registerSort("shipName")}
 *     />
 *   );
 * }
 * ```
 *
 * @example
 * 2: Usage with `useGuiConfState` custom hook as the state manager:
 * ```tsx
 * import { SortOrder } from "./hooks/useTableSort";
 * import { useGuiConfState } from "./hooks/useGuiConfState";
 * import TableCellSorted from "./components/TableCellSorted";
 *
 * const DemoComponent = () => {
 *   const [filters, setFilters] = useGuiConfState("dispManageShips");
 *   const registerSort = useTableSort<["shipName", "shipClass"]>({
 *     columns: ["shipName", "shipClass"],
 *     value: filters.order,
 *     onChange: o => setFilters({ order: o })
 *   });
 *
 *   return (
 *     <TableCellSorted
 *       label="Ship name"
 *       {...registerSort("shipName")}
 *     />
 *   );
 * }
 * ```
 */
export function useTableSort<const TCols extends readonly string[]>({
    value: order,
    onChange,
    defaultDirection = "asc",
    columns,
}: UseTableSortProps<TCols>): SortRegister<TCols> {
    const setOrder = (data: SortOrder) => {
        onChange(data);
    };

    /**
     * Handles the `Left` click event for adding a single column to the sort order
     * @param column - The name of the column to add to the sort order.
     */
    const handleSingleAddClick = (column: string) => {
        const columnData = getColumnData(order, column);
        const invertedDirection = getInvertedSortDirection(columnData?.direction);
        const columnDirection = columnData ? invertedDirection : defaultDirection;
        setOrder([`${column} ${columnDirection}`]);
    };

    /**
     * Handles the `Ctrl+Left` click event for removing multiple sort criteria for a specific column.
     * If there is only one sort criteria, it does nothing.
     * @param column - The column to remove sort criteria for.
     */
    const handleMultiRemoveClick = (column: string) => {
        if (order.length === 1) return;
        setOrder(order.filter(criteria => !criteria.startsWith(column)));
    };

    /**
     * Handles the `Shift+Left` click event for multi-add functionality.
     * If the column is already present in the sorting order, it toggles the sort direction.
     * If the column is not present, it adds the column to the sorting order with the default sort direction.
     * @param column - The column to be added or toggled in the sorting order.
     */
    const handleMultiAddClick = (column: string) => {
        const columnData = getColumnData(order, column);
        let newOrder = order;

        if (columnData) {
            const columnDirection = getInvertedSortDirection(columnData.direction);
            newOrder = order.map(criteria =>
                criteria.startsWith(column)
                    ? (`${column} ${columnDirection}` as SortCriteria<typeof column>)
                    : criteria,
            );
        } else {
            newOrder = [...order, `${column} ${defaultDirection}`];
        }

        setOrder(newOrder);
    };

    /**
     * Handles the sorting logic when a table column is clicked.
     * If the key combination is `Ctrl+Left` click, it calls the {@link handleMultiRemoveClick} function.
     * If the key combination is `Shift+Left` click, it removes all text selections and calls the {@link handleMultiAddClick} function.
     * If the key combination is standard `Left` click, it calls the {@link handleSingleAddClick} function.
     * @param column - The column identifier.
     * @param event - The mouse event triggered by the click.
     */
    const handleSort = (column: string, event: MouseEvent<HTMLTableCellElement>) => {
        if (event.ctrlKey) {
            handleMultiRemoveClick(column);
        } else if (event.shiftKey) {
            window.getSelection()?.removeAllRanges();
            handleMultiAddClick(column);
        } else {
            handleSingleAddClick(column);
        }
    };

    /**
     * Object containing sort handlers for each column.
     * The keys are the column names and the values are functions that handle the sorting logic.
     */
    const sortHandlers: Record<TCols[number], (e: MouseEvent<HTMLTableCellElement>) => void> =
        useMemo(
            () =>
                columns.reduce(
                    (prev: any, column: any) => ({
                        ...prev,
                        [column]: (event: MouseEvent<HTMLTableCellElement>) =>
                            handleSort(column, event),
                    }),
                    {},
                ),
            // Columns and handleSort are not expected to change
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [order],
        ) as any;

    /**
     * Registers a sort for the specified column.
     * @param column - The column to register the sort for.
     * @returns Mandatory props for {@link TableCellSorted} React component.
     */
    const registerSort: SortRegister<TCols> = useCallback(
        (column: string) => {
            const columnData = getColumnData(order, column);
            return {
                column,
                active: columnData !== null,
                direction: columnData?.direction ?? defaultDirection,
                priority: order.length > 1 ? columnData?.priority ?? -1 : -1,
                onClick: (sortHandlers as any)[column],
            };
        },
        [order, defaultDirection, sortHandlers],
    ) as SortRegister<TCols>;

    return registerSort;
}
