import { Reducer } from "redux";
import { ServerSettings } from "../models/serverSettings";
import { ServerSettingsActionType, SET_SERVER_SETTINGS } from "../actions/serverSettingsAction";

export type ServerSettingsState = {
    data: ServerSettings;
};

export const initialState: ServerSettingsState = {
    data: { userFromBasel: false },
};

function setServerSettings(payload: ServerSettings): ServerSettingsState {
    return { data: { ...payload } };
}

export const serverSettingsReducer: Reducer<ServerSettingsState, ServerSettingsActionType> = (
    state: ServerSettingsState = initialState,
    action: ServerSettingsActionType,
): ServerSettingsState => {
    switch (action.type) {
        case SET_SERVER_SETTINGS:
            return { ...setServerSettings(action.payload) };
        default:
            return state;
    }
};

export default serverSettingsReducer;
