import Messages from "../../localization/Messages";
import { useState, useEffect, useCallback } from "react";
import RpisPage from "../RpisPage/RpisPage";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Checkbox,
} from "@mui/material";
import { DocksNotifications, INotificationSettingsDocks } from "../../models/notification";
import { IDocksUser, docksUserInit } from "../../models/user";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { changeUser, getUser } from "../../api/docksUser";
import { useAuth } from "../../hooks/useAuth";

/**
 * Renders a page where the Locks & Docks user can manage their notification settings
 * and choose the type of notification they want to receive for  each topic.
 */
export const LDNotificationSettings = () => {
    const intl = useIntl();
    const [smsTopics, setSmsTopics] = useState<DocksNotifications[]>([]);
    const [emailTopics, setEmailTopics] = useState<DocksNotifications[]>([]);
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState<IDocksUser>(docksUserInit);
    const dispatch = useDispatch();

    const fetchUser = useCallback(async () => {
        if (!user.userName) return;
        try {
            setLoading(true);
            const res = await getUser(user.userName);
            setUserData(res.data);
            setSmsTopics(res.data.notifications?.smsTopics ?? []);
            setEmailTopics(res.data.notifications?.emailTopics ?? []);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.notificationSettings));
        } finally {
            setLoading(false);
        }
    }, [dispatch, intl, user.userName]);

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    const handleChangeUser = async () => {
        try {
            const notifications = {
                ...userData.notifications,
                smsTopics,
                emailTopics,
            } as INotificationSettingsDocks;
            await changeUser({ ...userData, notifications: notifications });
            dispatch(
                showBackendMessage(intl, "success", "updating", Messages.notificationSettings),
            );
        } catch {
            dispatch(showBackendMessage(intl, "error", "updating", Messages.user));
        }
    };

    /*
    const handleChangeSmsTopics = (topic: DocksNotifications) => {
        setSmsTopics(prevValue =>
            !prevValue.includes(topic)
                ? [...prevValue, topic]
                : [...prevValue.filter(nt => nt !== topic)],
        );
    };*/

    const handleChangeEmailTopics = (topic: DocksNotifications) => {
        setEmailTopics(prevValue =>
            !prevValue.includes(topic)
                ? [...prevValue, topic]
                : [...prevValue.filter(nt => nt !== topic)],
        );
    };

    const getMessageForTopic = (topic: DocksNotifications) => {
        switch (topic) {
            case DocksNotifications.bookingConfirmed:
                return Messages.bookingReqConfirmed;

            case DocksNotifications.chatText:
                return Messages.chatMessage;

            case DocksNotifications.bookingChanged:
                return Messages.bookingReqChanged;

            case DocksNotifications.bookingDeclined:
                return Messages.bookingReqDeclined;

            case DocksNotifications.shipCertExpired:
                return Messages.shipCertExpired;

            case DocksNotifications.bookingPrecheck:
                return Messages.bookingPreeCheck;

            case DocksNotifications.expensesInvoiced:
                return Messages.expenseInvoiced;

            case DocksNotifications.bookingConsumptionNew:
                return Messages.newConsumptionExpense;
        }
    };

    return (
        <RpisPage
            title={intl.formatMessage(Messages.notificationSettings)}
            className="notification-settings-container"
        >
            <div className="edit-settings-container">
                <h2>
                    {`${intl.formatMessage(Messages.edit)} ${intl.formatMessage(
                        Messages.notificationSettings,
                    )}`}
                </h2>
                <form className="column-form">
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    {/*
                                    <TableCell align="right">
                                        {intl.formatMessage(Messages.sms)}
                                    </TableCell>
                                    */}
                                    <TableCell>{intl.formatMessage(Messages.email)}</TableCell>
                                </TableRow>
                            </TableHead>
                            {!loading && (
                                <TableBody>
                                    {Object.values(DocksNotifications).map((topic, key) => (
                                        <TableRow key={key}>
                                            <TableCell>
                                                {intl.formatMessage(
                                                    getMessageForTopic(topic) ?? Messages.unknown,
                                                )}
                                            </TableCell>

                                            {/*
                                            SMS topic temporarily unavailable
                                            <TableCell>
                                                <Checkbox
                                                    checked={
                                                        smsTopics.find(
                                                            (t: DocksNotifications) => t === topic,
                                                        )
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={() => handleChangeSmsTopics(topic)}
                                                />
                                            </TableCell>
                                                */}

                                            <TableCell>
                                                <Checkbox
                                                    checked={
                                                        emailTopics.find(
                                                            (t: DocksNotifications) => t === topic,
                                                        )
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={() => handleChangeEmailTopics(topic)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <Button variant="contained" onClick={handleChangeUser}>
                        {intl.formatMessage(Messages.save)}
                    </Button>
                </form>
            </div>
        </RpisPage>
    );
};

export default LDNotificationSettings;
