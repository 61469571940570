import { useState, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import { HomePageTemplate } from "../HomePageTemplate/HomePageTemplate";
import { RouteOutlined, DirectionsBoatOutlined, CalendarMonthOutlined } from "@mui/icons-material";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    MenuItem,
    FormControl,
} from "@mui/material";
import Messages from "../../localization/Messages";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import { searchDestinations } from "../../api/destinations";
import { IDestination } from "../../models/destination";
import { searchParamsInit } from "../../models/searchParams";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PaymentsIcon from "@mui/icons-material/Payments";

/**
 * Represents the Home page for Locks & Docks.
 * This component is used to display the home page for the Locks & Docks FGKS app.
 *
 * @component
 * @example
 * ```tsx
 * <HomeLocks />
 * ```
 */
export const HomeLocks = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [selectedDestination, setSelectedDestination] = useState("");
    const [loading, setLoading] = useState(false);
    const [destinations, setDestinations] = useState<IDestination[]>([]);
    const dispatch = useDispatch();

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedDestination("");
    };

    const handleContinue = () => {
        if (selectedDestination) {
            navigate(`/plan/${selectedDestination}`);
            closeModal();
        }
    };

    const fetchDestinations = useCallback(async () => {
        try {
            setLoading(true);
            const res = await searchDestinations(searchParamsInit);
            setDestinations(res.data.rows ?? []);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.destinations));
        } finally {
            setLoading(false);
        }
    }, [intl, dispatch]);

    useEffect(() => {
        fetchDestinations();
    }, [fetchDestinations]);

    const items = [
        {
            label: intl.formatMessage(Messages.manageVoyages),
            icon: <RouteOutlined />,
            link: "manage-voyages",
        },
        {
            label: intl.formatMessage(Messages.manageFleet),
            icon: <DirectionsBoatOutlined />,
            link: "manage-fleet",
        },
        {
            label: intl.formatMessage(Messages.viewDockAllocationPlan),
            icon: <CalendarMonthOutlined />,
            action: openModal,
        },
        {
            label: intl.formatMessage(Messages.manageAddresses),
            icon: <ImportContactsOutlinedIcon />,
            link: "manage-addresses",
        },
        {
            label: intl.formatMessage(Messages.manageExpenses),
            icon: <PaymentsIcon />,
            link: "manage-expenses",
        },
    ];

    return (
        <>
            <HomePageTemplate
                title={intl.formatMessage(Messages.fgksLocksAndDocks)}
                items={items}
            />
            <Dialog open={showModal} onClose={closeModal}>
                <DialogTitle>Choose destination</DialogTitle>
                <DialogContent>
                    <FormControl className="plan-destinations-dropdown">
                        <TextField
                            label={intl.formatMessage(Messages.destination)}
                            select
                            disabled={loading}
                            onChange={event => {
                                setSelectedDestination(event.target.value);
                            }}
                            value={selectedDestination}
                            variant="filled"
                        >
                            {destinations.map(destination => (
                                <MenuItem key={destination.id} value={destination.id}>
                                    {destination.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModal}>Cancel</Button>
                    <Button onClick={handleContinue} disabled={!selectedDestination}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default HomeLocks;
