import { Box, Tooltip } from "@mui/material";
import { ReactNode } from "react";
import "./index.css";

export type TextActionProps = {
    open: boolean;
    children: ReactNode;
    inputRef?: any;
    onClick?: () => void;
    error?: string;
};

/**
 * Wraps elements to add a tooltip that displays an error message.
 *
 * @component
 * @example
 * ```tsx
 * <TextAction open={true} error="Invalid input">
 *   <input type="text" />
 * </TextAction>
 * ```
 */

export default function TextAction({
    open,
    children,
    inputRef = undefined,
    error = undefined,
    onClick = () => {},
}: TextActionProps) {
    const hasErrors = (error?.length ?? 0) > 0;
    const handleOnClick = () => {
        const isTextSelected = (window?.getSelection()?.toString() || "").length > 0;
        if (isTextSelected) return;
        onClick?.();
    };
    return (
        <Tooltip title={error} placement="left">
            <Box
                ref={inputRef}
                onClick={handleOnClick}
                data-open={open}
                data-has-error={hasErrors}
                data-error-message={error}
                className="editable-text"
            >
                {children}
            </Box>
        </Tooltip>
    );
}
