import Messages from "../localization/Messages";
import { IntlShape } from "react-intl";
import {
    DispatcherNotifications,
    INotification,
    INotificationData,
    INotificationLink,
} from "../models/notification";
import { DateFormat, getDateText } from "./dateHelper";

/**
 * Returns the notification title based on the notification type for the Dispatcher user.
 * @param intl - The internationalization object.
 * @param notification - The notification object.
 * @returns An array of strings representing the notification title.
 */
export const getNotificationTitleDispatcher = (
    intl: IntlShape,
    notification: INotification,
): string[] => {
    switch (notification.type) {
        case DispatcherNotifications.bookingRequested:
            return [getBookingRequestedNotification(intl, notification)];

        case DispatcherNotifications.booking_change_requested:
            return [getBookingChangeRequestedNotification(intl, notification)];

        case DispatcherNotifications.chatText:
            return [getChatMessageNotification(intl, notification)];

        case DispatcherNotifications.booking_canceled:
            return [getBookingCanceledNotification(intl, notification)];

        case DispatcherNotifications.bookingShipDuplicateEni:
            return [getBookingShipDuplicateEniNotification(intl, notification)];

        case DispatcherNotifications.bookingShipNew:
            return [getBookingNewShipNotification(intl, notification)];

        case DispatcherNotifications.bookingShipUpdate:
            return [getBookingShipChangedNotification(intl, notification)];

        case DispatcherNotifications.shipCertExpired:
            return [getShipCertificateExpiredNotification(intl, notification)];

        case DispatcherNotifications.voyageDataUpdated:
            return [getVoyageDataUpdatedNotification(intl, notification)];

        case DispatcherNotifications.addressInUseChanged:
            return getAddressUpdatedNotification(intl, notification);

        case DispatcherNotifications.bookingChangeConfirmed:
            return [getBookingChangeConfirmedNotification(intl, notification)];

        case DispatcherNotifications.bookingCommentChanged:
            return [getBookingCommentAddedNotification(intl, notification)];

        default:
            return ["New notification"];
    }
};

/**
 * Returns the notification links based on the notification type for the Dispatcher user.
 * @param intl - The internationalization object.
 * @param notification - The notification object.
 * @returns An array of notification links.
 */
export const getNotificationLinksDispatcher = (
    intl: IntlShape,
    notification: INotification,
): INotificationLink[] => {
    switch (notification.type) {
        case DispatcherNotifications.bookingRequested:
        case DispatcherNotifications.booking_canceled:
        case DispatcherNotifications.booking_change_requested:
        case DispatcherNotifications.chatText:
        case DispatcherNotifications.voyageDataUpdated:
        case DispatcherNotifications.bookingChangeConfirmed:
        case DispatcherNotifications.bookingCommentChanged:
            return [
                {
                    title: `${intl.formatMessage(Messages.manageBookings)}`,
                    ref: `manage-bookings?selectedBookingId=${notification.data?.bookingId ?? ""}`,
                },
            ];

        case DispatcherNotifications.bookingShipNew:
        case DispatcherNotifications.bookingShipDuplicateEni:
        case DispatcherNotifications.bookingShipUpdate:
        case DispatcherNotifications.shipCertExpired:
            return [
                {
                    title: intl.formatMessage(Messages.manageShips),
                    ref: `manage-ships?selectedShipId=${notification.data?.shipId ?? ""}`,
                },
            ];
        case DispatcherNotifications.addressInUseChanged:
            return [
                {
                    title: intl.formatMessage(Messages.manageAddresses),
                    ref: `manage-addresses?selectedAddressId=${notification.data?.addressId ?? ""}`,
                },

                {
                    title: intl.formatMessage(Messages.manageInvoices),
                    ref: `manage-invoices?companyName=${notification.data?.companyName ?? ""}`,
                },
            ];
        default:
            return [{ title: "", ref: "" }];
    }
};

/**
 * Formats the date and time.
 * @param date - The date to format.
 * @returns The formatted date and time.
 */
const formatDateTime = (date?: number) => {
    return getDateText(date, DateFormat.CLIENT_DATE_TIME);
};

/**
 * Returns the booking details.
 * @param data - The notification data.
 * @returns The booking details string.
 */
const getBookingDetails = (data: INotificationData | null) => {
    if (!data) return "";
    return `${data.managingCompany ?? ""}, ${data.shipName ?? ""}, 
    ${formatDateTime(data.eta)} - ${formatDateTime(data.etd)} `;
};

/**
 * Returns the ship details.
 * @param data - The notification data.
 * @returns The ship details string.
 */
const getShipDetails = (data: INotificationData | null) => {
    if (!data) return "";
    return `${data.managingCompany ?? ""}, ${data.shipName ?? ""}, ${
        data.eni ?? ""
    }, ${formatDateTime(data.eta)} - ${formatDateTime(data.etd)}`;
};

/**
 * Returns the address details.
 * @param data - The notification data.
 * @returns The address details string.
 */
const getAddressDetails = (data: INotificationData | null) => {
    if (!data) return "";
    return `${data.managingCompany ?? ""}: ${data.companyName ?? ""}, ${data.address ?? ""}`;
};

/**
 * Returns the booking requested notification.
 * @param intl - The internationalization object.
 * @param notification - The notification object.
 * @returns The booking requested notification string.
 */
const getBookingRequestedNotification = (intl: IntlShape, notification: INotification) => {
    return `${intl.formatMessage(Messages.notificationBookingRequested)}: ${getBookingDetails(
        notification.data,
    )}`;
};

/**
 * Returns the chat message notification.
 * @param intl - The internationalization object.
 * @param notification - The notification object.
 * @returns The chat message notification string.
 */
const getChatMessageNotification = (intl: IntlShape, notification: INotification) => {
    return `${intl.formatMessage(Messages.notificationNewChatMessage)}: ${getBookingDetails(
        notification.data,
    )}`;
};

/**
 * Returns the booking change requested notification.
 * @param intl - The internationalization object.
 * @param notification - The notification object.
 * @returns The booking change requested notification string.
 */
const getBookingChangeRequestedNotification = (intl: IntlShape, notification: INotification) => {
    return `${intl.formatMessage(Messages.notificationBookingChanged)}: ${getBookingDetails(
        notification.data,
    )}`;
};

/**
 * Returns the booking canceled notification.
 * @param intl - The internationalization object.
 * @param notification - The notification object.
 * @returns The booking canceled notification string.
 */
const getBookingCanceledNotification = (intl: IntlShape, notification: INotification) => {
    return `${intl.formatMessage(Messages.notificationBookingCanceled)}: ${getBookingDetails(
        notification.data,
    )}`;
};

/**
 * Returns the booking ship duplicate ENI notification.
 * @param intl - The internationalization object.
 * @param notification - The notification object.
 * @returns The booking ship duplicate ENI notification string.
 */
const getBookingShipDuplicateEniNotification = (intl: IntlShape, notification: INotification) => {
    return `${intl.formatMessage(Messages.notificationDuplicateEniNumber)}: ${getShipDetails(
        notification.data,
    )} ${intl.formatMessage(Messages.notificationThisEniIsUsed)}`;
};

/**
 * Returns the booking new ship notification.
 * @param intl - The internationalization object.
 * @param notification - The notification object.
 * @returns The booking new ship notification string.
 */
const getBookingNewShipNotification = (intl: IntlShape, notification: INotification) => {
    return `${intl.formatMessage(Messages.notificationBookingShipNew)} ${getBookingDetails(
        notification.data,
    )}`;
};

/**
 * Returns the booking ship changed notification.
 * @param intl - The internationalization object.
 * @param notification - The notification object.
 * @returns The booking ship changed notification string.
 */
const getBookingShipChangedNotification = (intl: IntlShape, notification: INotification) => {
    return `${intl.formatMessage(Messages.notificationShipDataUpdated)} ${getBookingDetails(
        notification.data,
    )}`;
};

/**
 * Returns the ship certificate expired notification.
 * @param intl - The internationalization object.
 * @param notification - The notification object.
 * @returns The ship certificate expired notification string.
 */
const getShipCertificateExpiredNotification = (intl: IntlShape, notification: INotification) => {
    return `${intl.formatMessage(Messages.notificationShipCertificateExpired1)} ${
        notification.data?.shipName ?? ""
    } ${intl.formatMessage(Messages.notificationShipCertificateExpired2)} ${
        notification.data?.managingCompany ?? ""
    } ${intl.formatMessage(Messages.notificationShipCertificateExpired3)}`;
};

/**
 * Returns the address updated notification.
 * @param intl - The internationalization object.
 * @param notification - The notification object.
 * @returns An array of strings representing the address updated notification.
 */
const getAddressUpdatedNotification = (intl: IntlShape, notification: INotification): string[] => {
    return [
        `${intl.formatMessage(Messages.addressInUseChangedMessage)} ${getAddressDetails(
            notification.data,
        )}`,
        `${intl.formatMessage(Messages.addressInUseChangedMessage2)}: `,
    ];
};

/**
 * Returns the voyage data updated notification.
 * @param intl - The internationalization object.
 * @param notification - The notification object.
 * @returns The voyage data updated notification string.
 */
const getVoyageDataUpdatedNotification = (intl: IntlShape, notification: INotification) => {
    return `${intl.formatMessage(Messages.voyageDataUpdatedMessage)}: ${getBookingDetails(
        notification.data,
    )}`;
};

/**
 * Returns the booking change confirmed notification.
 * @param intl - The internationalization object.
 * @param notification - The notification object.
 * @returns The booking change confirmed notification string.
 */
const getBookingChangeConfirmedNotification = (intl: IntlShape, notification: INotification) => {
    return `${intl.formatMessage(
        Messages.bookingChangeConfirmedNotificationMessage,
    )}: ${getBookingDetails(notification.data)}`;
};

/**
 * Returns the booking comment added notification.
 * @param intl - The internationalization object.
 * @param notification - The notification object.
 * @returns The booking comment added notification string.
 */
const getBookingCommentAddedNotification = (intl: IntlShape, notification: INotification) => {
    return `${intl.formatMessage(
        Messages.bookingCommentChangedNotificationMessage,
    )}: ${getBookingDetails(notification.data)}`;
};
