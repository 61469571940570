import { getDateMoment } from "../helpers/dateHelper";
import { VoyageStatus } from "./booking";

export interface IAddEditVoyage {
    id: string;
    deleted: 0 | 1;
    voyageStart: number;
    voyageEnd: number;
    companyId: string;
    shipId: string;
    name: string;
    invoiceAddress: string;
    contactDetails: string;
    charterCompany?: string;
}

export interface IVoyage extends IAddEditVoyage {
    createBy: string;
    createTs: number;
    lastModifyBy: string;
    lastModifyTs: number;
    status: VoyageStatus;
}

export const addEditVoyageInit: IAddEditVoyage = {
    id: "",
    deleted: 0,
    voyageStart: getDateMoment().toDate().getTime(),
    voyageEnd: getDateMoment().toDate().getTime(),
    companyId: "",
    shipId: "",
    name: "",
    invoiceAddress: "",
    contactDetails: "",
};
