import { useMediaQuery, Box } from "@mui/material";
import { SortOrder } from "../../hooks/useTableSort";
import React, { ReactNode } from "react";
import "./Datatable.css";
import { DatatableDesktop } from "./layout/DatatableDesktop";
import { DatatableMobile } from "./layout/DatatableMobile";

export type DtColumnDef<T> = {
    key: string;
    label: ReactNode;
    sortable: boolean;
    align?: "left" | "right" | "center";
    content: (row: T, index: number) => ReactNode;
};

export type DatatableProps<T> = {
    items: T[];
    size?: "small" | "medium";
    columnDefs: DtColumnDef<T>[];
    sortOrder: SortOrder;
    className?: string;
    skeletonRows?: number;
    loading?: boolean;
    onSortChange: (order: SortOrder) => void;
    renderPagination?: () => ReactNode;
    renderActions?: (row: T) => ReactNode;
    isActiveRow?: (row: T) => boolean;
    idMapper?: (row: T) => React.Key;
    titleMapper: (row: T) => ReactNode;
};

export function getSortableColumns(columnDefs: DtColumnDef<any>[]): string[] {
    return columnDefs.reduce((acc, columnDef) => {
        if (columnDef.sortable) acc.push(columnDef.key);
        return acc;
    }, [] as string[]);
}

/**
 * A customizable table component for displaying data for a desktop and mobile view.
 *
 * @template T - The type of the data items in the table.
 *
 * @component
 * @param {DatatableProps<T>} props - The props for the Datatable component.
 * @param {T[]} props.items - The array of data items to be displayed in the table.
 * @param {DtColumnDef<T>[]} props.columnDefs - The array of column definitions for the table.
 * @param {SortOrder} props.sortOrder - The current sort order of the table.
 * @param {string} [props.className] - The optional CSS class name for the table.
 * @param {number} [props.skeletonRows] - The number of skeleton rows to be displayed while loading data.
 * @param {boolean} [props.loading] - Indicates whether the table is currently loading data.
 * @param {(order: SortOrder) => void} props.onSortChange - The callback function to handle sort order changes.
 * @param {() => ReactNode} [props.renderPagination] - The optional function to render pagination controls.
 * @param {(row: T) => ReactNode} [props.renderActions] - The optional function to render actions for each row.
 * @param {(row: T) => boolean} [props.isActiveRow] - The optional function to determine if a row is active.
 * @param {(row: T) => React.Key} [props.idMapper] - The optional function to map a unique key for each row.
 * @param {(row: T) => ReactNode} props.titleMapper - The function to map the title for each row.
 * @returns {JSX.Element} The rendered Datatable component.
 */
export function Datatable<const T>(props: DatatableProps<T>) {
    const matchesMobile = useMediaQuery("(max-width: 500px)");
    return (
        <Box className="datatable" data-loading={props.loading}>
            {matchesMobile ? <DatatableMobile {...props} /> : <DatatableDesktop {...props} />}
        </Box>
    );
}
