import { useState } from "react";
import { useIntl } from "react-intl";
import Messages from "../../../../localization/Messages";
import { Box, List, ListItem, Popper, Typography } from "@mui/material";
import { IExpensePeriod } from "../../../../models/expenseType";
import "./DockPopper.css";
import { InfoOutlined } from "@mui/icons-material";
import { useCachedDocks } from "../../../../hooks/useCachedDocks";

interface IDockPopper {
    period: IExpensePeriod;
}

/**
 * Renders the dock information.
 *
 * This component displays the name of the dock if there is only one dock available.
 * If there are multiple docks, it shows a "multiple" title with a popper that appears on mouse hover
 * and displays the names of the docks.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {IExpensePeriod} props.period - The expense period object.
 * @returns {JSX.Element} The rendered component.
 */
export const DockPopper = ({ period }: IDockPopper) => {
    const { docks } = useCachedDocks();
    const intl = useIntl();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div
            aria-owns={open ? "mouse-over-popover-docks" : undefined}
            aria-haspopup="true"
            onMouseEnter={e => handlePopoverOpen(e)}
            onMouseLeave={handlePopoverClose}
        >
            <Box display={"flex"} alignItems={"center"} gap={"5px"}>
                {intl.formatMessage(Messages.multiple)} <InfoOutlined color="primary" />
            </Box>

            <Popper id="mouse-over-popover-docks" open={open} anchorEl={anchorEl}>
                <List>
                    {docks
                        .filter(dock => period.docks.includes(dock.id))
                        .map(dock => (
                            <ListItem key={dock.id}>
                                <Typography>{dock.name}</Typography>
                            </ListItem>
                        ))}
                </List>
            </Popper>
        </div>
    );
};

export default DockPopper;
