import { useRef, useState, useEffect } from "react";
import { debounce } from "lodash";
import { getDateMoment } from "../helpers/dateHelper";

/**
 * Selector for the month and year element in the event calendar.
 */
const EVENT_CALENDAR_MONTH_YEAR_DIV_SELECTOR = ".mbsc-timeline-header-month";

/**
 * Selector for the day element in the event calendar.
 */
const EVENT_CALENDAR_DAY_DIV_SELECTOR = ".mbsc-timeline-header-sticky .mbsc-timeline-header-date";

/**
 * Retrieves the current date position in the calendar.
 * @param monthNames - An array of month names.
 * @returns The current date position.
 */
function getCurrentCalendarDatePosition(monthNames: string[]): Date {
    const monthYearProps = getCurrentMonthYearProps(monthNames);
    const dayProps = getCurrentDayProps();
    const currentDate = getDateMoment()
        .year(monthYearProps.year)
        .month(monthYearProps.month - 1)
        .date(dayProps.day);
    return currentDate.toDate();
}

/**
 * Retrieves the current month and year properties from the event calendar.
 * @param monthNames - An array of month names.
 * @returns The current month and year properties.
 */
function getCurrentMonthYearProps(monthNames: string[]) {
    const monthYearDiv: HTMLDivElement = document.querySelector(
        EVENT_CALENDAR_MONTH_YEAR_DIV_SELECTOR,
    )!;
    const currentMonthYearString = monthYearDiv.innerText;
    const [monthString, yearString] = currentMonthYearString.split(" ");
    const month = monthNames.indexOf(monthString) + 1;
    const year = parseInt(yearString);
    return { month, year };
}

/**
 * Retrieves the current day property from the event calendar.
 * @returns The current day property.
 */
function getCurrentDayProps() {
    const dateDiv: HTMLDivElement = document.querySelector(EVENT_CALENDAR_DAY_DIV_SELECTOR)!;
    const currentDayString = dateDiv.innerText;
    return { day: parseInt(currentDayString.split(" ")[0]) };
}

/**
 * Custom hook to track the current calendar position and invoke a handler function.
 * @param handler - The handler function to be invoked with the current position.
 * @param debounceMs - Optional. The debounce time in milliseconds. Default is 400ms.
 * @returns An array containing the event calendar ref, initialization function, and event calendar props.
 */
export function useTrackCurrentCalendarPosition(
    handler: (pos: Date) => void,
    debounceMs: number = 400,
) {
    const eventCalendarRef = useRef<any>();
    const [eventCalendarProps, setEventcalendarProps] = useState<any>();

    /**
     * Initialization function to set the event calendar props after a delay.
     */
    const initFn = () => setTimeout(() => setEventcalendarProps(eventCalendarRef.current));

    useEffect(() => {
        const calendarDivContainer = eventCalendarProps?._el;
        const scrollableCalendarDiv = calendarDivContainer?.querySelector(
            ".mbsc-timeline-grid-scroll",
        );

        /**
         * Debounced scroll event handler to track the current calendar position.
         */
        const debouncedHandleScroll = debounce(() => {
            try {
                const monthNames = eventCalendarProps?.s.monthNames;
                const currentPosition = getCurrentCalendarDatePosition(monthNames);
                handler(currentPosition);
            } catch (err) {
                console.warn(err);
            }
        }, debounceMs);

        if (scrollableCalendarDiv) {
            scrollableCalendarDiv.addEventListener("scroll", debouncedHandleScroll);
        }

        return () => {
            if (scrollableCalendarDiv) {
                scrollableCalendarDiv.removeEventListener("scroll", debouncedHandleScroll);
            }
            debouncedHandleScroll.cancel();
        };
    }, [eventCalendarProps, debounceMs, handler]);

    return [eventCalendarRef, initFn, eventCalendarProps] as const;
}
