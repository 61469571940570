import { Box, TableCell, TableCellProps, TableSortLabel } from "@mui/material";
import { MouseEvent, ReactNode, memo, useCallback, useState } from "react";
import { SortDirectionKey } from "../../hooks/useTableSort";
import LineClampedText from "../LineClampedText";
import "./index.css";

export type TableCellSortedMetadata = {
    onClick: (event: MouseEvent<HTMLTableCellElement>) => void;
    direction: SortDirectionKey;
    active: boolean;
    priority?: number;
};

export type TableCellSortedProps = Omit<TableCellProps, "onClick"> &
    TableCellSortedMetadata & {
        label: ReactNode;
    };

/**
 *  Renders a customized version of a MUI TableCell component that is used in tables that feature data sorting.
 *
 * @component
 * @example
 * ```tsx
 * import TableCellSorted from './TableCellSorted';
 *
 * function MyComponent() {
 *   return (
 *     <TableCellSorted
 *       column="name"
 *       label="Name"
 *       active={true}
 *       direction="asc"
 *       onClick={(event) => {
 *         // Handle click event
 *       }}
 *     />
 *   );
 * }
 * ```
 */

function TableCellSorted({
    className = "",
    label,
    active,
    direction,
    priority,
    onClick,
    ...props
}: TableCellSortedProps) {
    const [hovered, setHovered] = useState(false);
    const onMouseEnter = useCallback(() => setHovered(true), []);
    const onMouseLeave = useCallback(() => setHovered(false), []);

    return (
        <TableCell
            className={`${className} sort-cell sort-cell-${
                props.align === "right" ? "right" : "left"
            }`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            {...props}
        >
            <Box display="flex" alignItems="center">
                <LineClampedText text={label} rows={2} />
                <TableSortLabel active={hovered || active} direction={direction}>
                    {priority === -1 ? undefined : String(priority)}
                </TableSortLabel>
            </Box>
        </TableCell>
    );
}

export default memo(TableCellSorted);
