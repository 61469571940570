import Keycloak, { KeycloakConfig } from "keycloak-js";

const DEVELOPMENT_URLS = ["staging-fgks.rpis.eu", "dev-fgks.rpis.eu", "localhost", "127.0.0.1"];

type BuildMode = "development" | "production";

type KeycloakConfigEntry = {
    keycloakBase: string;
    keycloakConfig: KeycloakConfig;
};

const configurations: Record<BuildMode, KeycloakConfigEntry> = {
    production: {
        keycloakBase: "https://rpis.eu",
        keycloakConfig: {
            url: "https://rpis.eu/auth",
            realm: "rpis",
            clientId: "fgks-web",
        },
    },
    development: {
        keycloakBase: "https://test.rpis.eu",
        keycloakConfig: {
            url: "https://test.rpis.eu/auth",
            realm: "rpis",
            clientId: "fgks-web",
        },
    },
};

export const isDevelopment = DEVELOPMENT_URLS.includes(document.location.hostname);
export const environment: BuildMode = isDevelopment ? "development" : "production";
export const keycloakConfig = configurations[environment].keycloakConfig;
export const keycloakBase = configurations[environment].keycloakBase;
export const keycloakClient = new Keycloak(keycloakConfig);
