export interface IAllocationPlanParams {
    from: number;
    destinationId: string;
    to: number;
}

export const allocationPlanParamsInit: IAllocationPlanParams = {
    from: 0,
    destinationId: "",
    to: 0,
};
