import { useCallback } from "react";
import { useIntl } from "react-intl";
import { IInvoice } from "../../../models/invoice";
import InvoiceMenuButtonGroup, {
    InvoiceMenuButtonGroupProps,
} from "../components/InvoiceMenuButtonGroup/InvoiceMenuButtonGroup";

/**
 * Hook that returns a function that renders the action buttons for the invoice table.
 * @param {function} handler - The function to call when the user clicks a button.
 * @param {InvoiceMenuButtonGroupProps["menuActions"]} menuActions - The actions to perform when the user clicks a menu item.
 * @returns {function} The renderer function.
 */
export function useInvoiceRenderAction(
    handler: (row: IInvoice) => void,
    menuActions: InvoiceMenuButtonGroupProps["menuActions"],
) {
    const intl = useIntl();

    const renderer = useCallback(
        (row: IInvoice) => (
            <InvoiceMenuButtonGroup
                row={row}
                onClick={() => handler(row)}
                menuActions={menuActions}
            />
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [intl],
    );

    return renderer;
}
