import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    Button,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import LineClampedText from "../../components/LineClampedText";
import NoDataRow from "../../components/NoDataRow";
import TableCellSorted from "../../components/TableCellSorted";
import { TableSkeleton } from "../../components/TableSkeleton/TableSkeleton";
import Messages from "../../localization/Messages";
import RpisPage from "../RpisPage/RpisPage";
import SidebarLayout from "../SidebarLayout/SidebarLayout";
import "./ManageInternalBookings.css";
import { useIntl } from "react-intl";
import { searchInternalBooking } from "../../api/internalBooking";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { useDispatch } from "react-redux";
import { IInternalBooking } from "../../models/internalBooking";
import { useTableSort } from "../../hooks/useTableSort";
import classNames from "classnames";
import {
    INTERNAL_BOOKINGS_SORTABLE_COLUMNS,
    INTERNAL_BOOKINGS_ROWS_PER_PAGE_OPTS,
} from "./shared/constants";
import { buildInternalBookingsSearchPayload } from "./shared/filters";
import { InternalBookingFilters } from "./components/InternalBookingFilters/InternalBookingFilters";
import { Type as InternalBookingType } from "../../models/internalBooking";
import { useNavigate } from "react-router-dom";
import { useGuiConfState } from "../../hooks/useGuiConfState";
import DateTimeTwoRowDisplay from "../ManageBookings/components/DateTimeTwoRowDisplay";
import InternalBookingForm from "../ManageBookings/components/InternalBookingForm";
import useCallbackDebounced from "../ManageBookings/hooks/useCallbackDebounced";
import { useCachedDocks } from "../../hooks/useCachedDocks";

/**
 * ManageInternalBookings component is responsible for managing internal bookings.
 * It is rendered as a page in the dispatcher's dashboard.
 * This component allows users to view, edit, and delete internal bookings.
 *
 * @component
 * @example
 * return (
 *   <ManageInternalBookings />
 * )
 */
export function ManageInternalBookings() {
    const [counter, setCounter] = useState(0);
    const forceInternalBookingFormRerender = useCallback(() => setCounter(prev => prev + 1), []);

    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { byId } = useCachedDocks();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedRow, setSelectedRowInternal] = useState<IInternalBooking | undefined>();
    const setSelectedRow = useCallback(
        (row: IInternalBooking | undefined) => {
            setSelectedRowInternal(row);
            forceInternalBookingFormRerender();
        },
        [forceInternalBookingFormRerender],
    );
    const [rows, setRows] = useState<IInternalBooking[]>([]);
    const [rowCount, setRowCount] = useState(0);
    const [filters, setFilters] = useGuiConfState("dispInternalBookings");

    const registerSort = useTableSort({
        columns: INTERNAL_BOOKINGS_SORTABLE_COLUMNS,
        value: filters.order,
        onChange: order => setFilters({ order }),
    });

    const fetchInternalBookings = useCallbackDebounced(
        async () => {
            try {
                setLoading(true);
                const searchParams = buildInternalBookingsSearchPayload(filters);
                const response = await searchInternalBooking(searchParams);
                const data = response.data;
                setRows(data.rows);
                setRowCount(data.total);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.internalBookings));
            } finally {
                setLoading(false);
            }
        },
        [JSON.stringify(filters)],
        0,
    );

    useEffect(() => {
        fetchInternalBookings();
    }, [fetchInternalBookings]);

    const onSidebarClose = () => {
        setSidebarOpen(false);
    };

    return (
        <RpisPage title={intl.formatMessage(Messages.manageInternalBookings)}>
            <SidebarLayout
                sidebarTitle={intl.formatMessage(Messages.editInternalBooking)}
                open={sidebarOpen}
                onClose={() => {
                    onSidebarClose();
                    setSelectedRow(undefined);
                }}
                sidebarContent={
                    selectedRow ? (
                        <InternalBookingForm
                            key={counter}
                            selectedBooking={selectedRow}
                            internalEditMode={true}
                            cancelBookingCreating={() => {}}
                            handleDeleteInternalBooking={() => {}}
                            getBookings={fetchInternalBookings}
                        />
                    ) : undefined
                }
            >
                <InternalBookingFilters value={filters} onChange={setFilters} />

                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCellSorted
                                    label={intl.formatMessage(Messages.type)}
                                    {...registerSort("type")}
                                />
                                <TableCellSorted
                                    label={intl.formatMessage(Messages.assignedDock)}
                                    {...registerSort("dockId")}
                                />
                                <TableCellSorted
                                    label={intl.formatMessage(Messages.arrivalTime)}
                                    {...registerSort("arrivalTime")}
                                />
                                <TableCellSorted
                                    label={intl.formatMessage(Messages.departureTime)}
                                    {...registerSort("departureTime")}
                                />
                                <TableCellSorted
                                    label={intl.formatMessage(Messages.shipName)}
                                    {...registerSort("shipName")}
                                />
                                <TableCellSorted
                                    label={intl.formatMessage(Messages.eni)}
                                    {...registerSort("eni")}
                                />
                                <TableCellSorted
                                    label={intl.formatMessage(Messages.reason)}
                                    {...registerSort("reason")}
                                />

                                <TableCell align="center">
                                    <LineClampedText text={intl.formatMessage(Messages.actions)} />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {loading ? (
                            <TableSkeleton
                                columns={8}
                                rows={filters.rowsPerPage}
                                skeletonHeight={30}
                                cellHeight={38}
                                paddingY="1rem"
                            />
                        ) : (
                            <TableBody>
                                {rows.length > 0 ? (
                                    rows.map(row => (
                                        <TableRow
                                            key={row.id}
                                            className={classNames({
                                                "active-table-row": selectedRow?.id === row.id,
                                            })}
                                        >
                                            <TableCell>
                                                {intl.formatMessage(
                                                    Messages[
                                                        `INTERNAL_BOOKING_TYPE_${
                                                            row.type as InternalBookingType
                                                        }`
                                                    ],
                                                )}
                                            </TableCell>

                                            <TableCell>
                                                <LineClampedText
                                                    text={byId.get(row.dockId)?.name}
                                                />
                                            </TableCell>

                                            <TableCell>
                                                <DateTimeTwoRowDisplay date={row.arrivalTime} />
                                            </TableCell>

                                            <TableCell>
                                                <DateTimeTwoRowDisplay date={row.departureTime} />
                                            </TableCell>

                                            <TableCell>{row.shipName}</TableCell>

                                            <TableCell>{row.eni}</TableCell>

                                            <TableCell>{row.reason}</TableCell>

                                            <TableCell align="center">
                                                <Button
                                                    variant="contained"
                                                    onClick={() => {
                                                        setSidebarOpen(true);
                                                        setSelectedRow(row);
                                                    }}
                                                >
                                                    {intl.formatMessage(Messages.details)}
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    sx={{ marginLeft: "1rem" }}
                                                    onClick={() =>
                                                        navigate(
                                                            `/dock-allocation-plan?selectedBookingId=${row.id}`,
                                                        )
                                                    }
                                                >
                                                    {intl.formatMessage(Messages.showInPlan)}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <NoDataRow colSpan={8} />
                                )}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    page={rowCount === 0 ? 0 : filters.page}
                    count={rowCount}
                    rowsPerPage={filters.rowsPerPage}
                    rowsPerPageOptions={INTERNAL_BOOKINGS_ROWS_PER_PAGE_OPTS}
                    onPageChange={(_, page) => setFilters({ page })}
                    onRowsPerPageChange={e =>
                        setFilters({ rowsPerPage: parseInt(e.target.value, 10), page: 0 })
                    }
                />
            </SidebarLayout>
        </RpisPage>
    );
}
