import { ConnectedIntlProvider } from "./contexts/ConnectedIntlContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/configureStore";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import DockAllocationView from "./containers/DockAllocationPlan/DockAllocationView";
import { ConfirmDialogProvider } from "./contexts/ConfirmDialogContext";
import { SocketProvider } from "./contexts/SocketContext";
import { NotificationsProvider } from "./contexts/NotificationsContext";
import "./index.css";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import { TooltipProvider } from "./contexts/TooltipContext";
import { ReactNode } from "react";

type AppLayoutProps = {
    children: ReactNode;
};
export const AppLayout = ({ children }: AppLayoutProps) => {
    return (
        <Provider store={store}>
            <TooltipProvider>
                <ThemeProvider theme={theme}>
                    <ConnectedIntlProvider>
                        <ConfirmDialogProvider>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <BrowserRouter>
                                    <SocketProvider>
                                        <NotificationsProvider>
                                            <Routes>
                                                <Route path="/*" element={children} />
                                                <Route
                                                    key="plan/public/:destinationId"
                                                    path="plan/public/:destinationId"
                                                    element={
                                                        <DockAllocationView hideRightSidebar />
                                                    }
                                                />
                                            </Routes>
                                        </NotificationsProvider>
                                    </SocketProvider>
                                </BrowserRouter>
                            </LocalizationProvider>
                        </ConfirmDialogProvider>
                    </ConnectedIntlProvider>
                </ThemeProvider>
            </TooltipProvider>
        </Provider>
    );
};
