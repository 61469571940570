import { useIntl } from "react-intl";
import { IService, ServiceType } from "../../../../models/service";
import Messages from "../../../../localization/Messages";
import {
    ServiceErrors,
    serviceHasErrors,
} from "../../../DispManageServices/components/ServiceForm/ServiceForm";
import { useCallback, useEffect, useState } from "react";
import { searchService } from "../../../../api/service";
import { searchParamsInit } from "../../../../models/searchParams";
import { showBackendMessage } from "../../../../helpers/messagesHelper";
import { useDispatch } from "react-redux";

export function useValidateServices(bookingId: number) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [services, setServices] = useState<IService[]>();
    const [servicesIncomplete, setServicesIncomplete] = useState(false);

    const validateService = useCallback(
        (service: IService) => {
            const newErrors: ServiceErrors = {
                type: undefined,
                data: {
                    waterMeterStartValue: undefined,
                    waterMeterEndValue: "",
                    servicePersonnelData: [],
                },
                deployments: undefined,
            };

            if (!service.type) {
                newErrors.type = intl.formatMessage(Messages.serviceTypeIsRequired);
            }
            if (service.type === ServiceType.WATER_METER) {
                newErrors.data = {};

                if (!service.data.waterMeterStartValue) {
                    newErrors.data = {
                        ...newErrors.data,
                        waterMeterStartValue: intl.formatMessage(
                            Messages.waterMeterStartValueIsRequired,
                        ),
                    };
                }
                if (!service.data.waterMeterEndValue) {
                    newErrors.data = {
                        ...newErrors.data,
                        waterMeterEndValue: intl.formatMessage(
                            Messages.waterMeterEndValueIsRequired,
                        ),
                    };
                }

                if (
                    service.data.waterMeterStartValue &&
                    service.data.waterMeterEndValue &&
                    service.data.waterMeterStartValue > service.data.waterMeterEndValue
                ) {
                    newErrors.data = {
                        ...newErrors.data,
                        waterMeterEndValue: intl.formatMessage(
                            Messages.endValueCantBeLowerThanStartValue,
                        ),
                    };
                }
            }
            if (service.data.servicePersonnelData.length) {
                newErrors.data = newErrors.data ? { ...newErrors.data } : {};
                newErrors.data.servicePersonnelData = service.data.servicePersonnelData.map(() => ({
                    startDateTime: undefined,
                    endDateTime: undefined,
                }));

                if (newErrors.data.servicePersonnelData.length) {
                    service.data.servicePersonnelData.forEach((entry, index) => {
                        if (newErrors?.data?.servicePersonnelData) {
                            if (!entry.startDateTime) {
                                newErrors.data.servicePersonnelData[index].startDateTime =
                                    intl.formatMessage(Messages.startTimeIsRequired);
                            }

                            if (!entry.endDateTime) {
                                newErrors.data.servicePersonnelData[index].endDateTime =
                                    intl.formatMessage(Messages.endTimeIsRequired);
                            }

                            if (entry.startDateTime && isNaN(entry.startDateTime)) {
                                newErrors.data.servicePersonnelData[index].startDateTime =
                                    intl.formatMessage(Messages.wrongDateFormat);
                            }

                            if (entry.endDateTime && isNaN(entry.endDateTime)) {
                                newErrors.data.servicePersonnelData[index].endDateTime =
                                    intl.formatMessage(Messages.wrongDateFormat);
                            }

                            if (
                                entry.startDateTime &&
                                entry.endDateTime &&
                                entry.endDateTime <= entry.startDateTime
                            ) {
                                newErrors.data.servicePersonnelData[index].endDateTime =
                                    intl.formatMessage(Messages.endTimeCanNotBeBeforeStartTime);
                            }
                        }
                    });
                }
            }

            /*if (service.data.servicePersonnelData.length < 2) {
                newErrors.deployments = intl.formatMessage(
                    Messages.serviceNeedsToHaveAtLeastTwoDeployments,
                );
            }*/

            return newErrors;
        },
        [intl],
    );
    useEffect(() => {
        const getServices = async () => {
            try {
                const res = await searchService({
                    ...searchParamsInit,
                    filter: { $and: [{ bookingId: bookingId }] },
                });
                setServices(res.data.rows ?? []);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.services));
            }
        };
        getServices();
    }, [bookingId, dispatch, intl]);

    useEffect(() => {
        if (services && services?.length > 0) {
            let hasErrors = false;
            services.forEach(service => {
                const errors = validateService(service);
                if (serviceHasErrors(errors)) {
                    hasErrors = true;
                }
            });
            setServicesIncomplete(hasErrors);
        }
    }, [services, validateService]);
    return servicesIncomplete;
}
