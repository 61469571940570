import { useCallback, useMemo, useState } from "react";

export type BatchToggleControlHandler<TId extends string | number = number> = {
    isActive: (id: TId) => boolean;
    toggle: (id: TId) => void;
    clearAll: () => void;
    setValue: (ids: TId[]) => void;
    count: number;
};

/**
 * Custom hook for managing batch toggle control.
 *
 * @template TId - The type of the id parameter.
 * @returns An array containing the active ids and a handler object with various control functions.
 */
function useBatchToggleControl<TId extends string | number = number>(): [
    TId[],
    BatchToggleControlHandler<TId>,
] {
    const [activeIds, setActiveIds] = useState<TId[]>([]);
    const count = activeIds.length;

    const isActive = useCallback(
        (id: TId) => {
            return !!activeIds.find(expId => expId === id);
        },
        [activeIds],
    );

    const toggle = useCallback(
        (id: TId) => {
            const isActiveId = isActive(id);
            setActiveIds(prev => (isActiveId ? prev.filter(expId => expId !== id) : [...prev, id]));
        },
        [isActive],
    );

    const clearAll = useCallback(() => {
        setActiveIds([]);
    }, []);

    const handler = useMemo(
        () => ({
            isActive,
            toggle,
            clearAll,
            setValue: setActiveIds,
            count,
        }),
        [isActive, toggle, clearAll, count],
    );

    return [activeIds, handler];
}

export default useBatchToggleControl;
