import { useMemo } from "react";
import { useIntl } from "react-intl";
import { HomePageTemplate } from "../HomePageTemplate/HomePageTemplate";
import Messages from "../../localization/Messages";
import MapIcon from "@mui/icons-material/Map";
import LibraryBooksOutlined from "@mui/icons-material/LibraryBooksOutlined";
import { isDevelopment } from "../../Keycloak";
import { CalendarMonthOutlined } from "@mui/icons-material";
import BuildIcon from "@mui/icons-material/Build";

/**
 * This component is used to display the home page for the On Call user of FGKS.
 *
 * @component
 * @example
 * ```tsx
 * <HomeOnCall />
 * ```
 */
export function HomeOnCall() {
    const intl = useIntl();

    const items = useMemo(
        () => [
            {
                label: intl.formatMessage(Messages.manageServices),
                icon: <BuildIcon />,
                link: "manage-services",
            },
            {
                label: intl.formatMessage(Messages.overviewOfBookings),
                icon: <LibraryBooksOutlined />,
                link: "manage-bookings",
            },
            {
                label: intl.formatMessage(Messages.viewDockAllocationPlan),
                icon: <CalendarMonthOutlined />,
                link: "dock-allocation-plan",
            },
            {
                label: intl.formatMessage(Messages.portMonitor),
                icon: <MapIcon />,
                url: isDevelopment
                    ? "https://gms.stage.rpis.eu/port-administration/port/monitor"
                    : "https://gms.rpis.eu/port-administration/port/monitor ",
            },
        ],
        [intl],
    );

    return <HomePageTemplate title={intl.formatMessage(Messages.fgksOnCall)} items={items} />;
}

export default HomeOnCall;
