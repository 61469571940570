import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import classNames from "classnames";
import React, { useMemo } from "react";
import Messages from "../../../localization/Messages";
import TableCellSorted from "../../TableCellSorted";
import { TableSkeleton } from "../../TableSkeleton/TableSkeleton";
import { DatatableProps, getSortableColumns } from "../Datatable";
import { useIntl } from "react-intl";
import { useTableSort } from "../../../hooks/useTableSort";

/**
 * Renders a desktop version of the Datatable component.
 *
 * @template T - The type of the items in the datatable.
 *
 * @param {DatatableProps<T>} props - The props for the datatable component.
 * @param {T[]} props.items - The array of items to be displayed in the datatable.
 * @param {ColumnDef[]} props.columnDefs - The array of column definitions for the datatable.
 * @param {SortOrder} props.sortOrder - The current sort order of the datatable.
 * @param {Function} props.onSortChange - The callback function to handle sort order changes.
 * @param {Function} props.renderActions - The function to render custom actions for each row.
 * @param {number} [props.skeletonRows=10] - The number of skeleton rows to display when loading.
 * @param {boolean} [props.loading=false] - Indicates whether the datatable is in a loading state.
 * @param {string} [props.className=""] - The additional CSS class name for the datatable container.
 * @param {Function} [props.isActiveRow=() => false] - The function to determine if a row is active.
 * @param {Function} [props.idMapper] - The function to map an item to a unique identifier.
 * @param {Function} [props.renderPagination] - The function to render pagination for the datatable.
 *
 * @returns {JSX.Element} The rendered datatable component.
 */
export function DatatableDesktop<const T>({
    items,
    columnDefs,
    sortOrder,
    onSortChange,
    renderActions,
    skeletonRows = 10,
    loading = false,
    className = "",
    isActiveRow = () => false,
    idMapper,
    renderPagination,
    size = "medium",
}: DatatableProps<T>) {
    const intl = useIntl();
    const sortableColumns = useMemo(() => getSortableColumns(columnDefs), [columnDefs]);
    const colspan = renderActions ? columnDefs.length + 1 : columnDefs.length;
    const registerSort = useTableSort({
        columns: sortableColumns,
        value: sortOrder,
        onChange: onSortChange,
    });

    return (
        <>
            <TableContainer className={`${className} datatable-content`}>
                <Table size={size}>
                    <TableHead>
                        <TableRow>
                            {columnDefs.map(columnDef => (
                                <React.Fragment key={columnDef.key}>
                                    {columnDef.sortable ? (
                                        <TableCellSorted
                                            label={columnDef.label}
                                            {...registerSort(columnDef.key)}
                                            align={columnDef.align}
                                        />
                                    ) : (
                                        <TableCell align={columnDef.align}>
                                            {columnDef.label}
                                        </TableCell>
                                    )}
                                </React.Fragment>
                            ))}
                            {renderActions && (
                                <TableCell>{intl.formatMessage(Messages.actions)}</TableCell>
                            )}
                        </TableRow>
                    </TableHead>

                    {loading ? (
                        <TableSkeleton rows={skeletonRows} columns={colspan} />
                    ) : !loading && items.length === 0 ? (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={colspan}>
                                    {intl.formatMessage(Messages.noData)}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ) : (
                        <TableBody>
                            {items.map((row, index) => (
                                <TableRow
                                    key={idMapper ? idMapper(row) : index}
                                    className={classNames({
                                        "active-table-row": isActiveRow(row),
                                    })}
                                >
                                    {columnDefs.map(columnDef => (
                                        <TableCell key={columnDef.key} align={columnDef.align}>
                                            {columnDef.content(row, index)}
                                        </TableCell>
                                    ))}
                                    {renderActions && <TableCell>{renderActions(row)}</TableCell>}
                                </TableRow>
                            ))}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            {renderPagination && <>{renderPagination()}</>}
        </>
    );
}
