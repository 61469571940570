import CircularProgress from "@mui/material/CircularProgress";
import "../HomePageTemplate/HomePageTemplate.css";

/**
 * Renders a circular progress bar.
 */
export const Progress = () => {
    return (
        <main className="home-page-container">
            <CircularProgress />
        </main>
    );
};

export default Progress;
