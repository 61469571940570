import { Button } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import RpisPage from "../RpisPage/RpisPage";
import SidebarLayout from "../SidebarLayout/SidebarLayout";
import VesselForm from "./VesselForm";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SearchIcon from "@mui/icons-material/Search";
import { IAddEditShip, IShip } from "../../models/ship";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { getShip, searchShip } from "../../api/ship";
import { TableSkeleton } from "../../components/TableSkeleton/TableSkeleton";
import TablePagination from "@mui/material/TablePagination";
import { searchParamsInit } from "../../models/searchParams";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import Messages from "../../localization/Messages";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { AddressType, IAddress } from "../../models/address";
import { searchAddress } from "../../api/address";
import { Serializers, useUrlState } from "../../hooks/useUrlState";
import { SortCriteria, SortDirection, SortOrder, useTableSort } from "../../hooks/useTableSort";
import { DatatablePaginationFilters, useGuiConfState } from "../../hooks/useGuiConfState";
import TableCellSorted from "../../components/TableCellSorted";
import "./LDManageFleet.css";

export type LDManageFleetFilters = DatatablePaginationFilters & {
    textSearch: string;
    order: SortOrder;
};

function buildPayload(filters: LDManageFleetFilters) {
    return {
        ...searchParamsInit,
        offset: filters.page * filters.rowsPerPage,
        limit: filters.rowsPerPage,
        textSearch: filters.textSearch,
        columns: {},
        order: filters.order,
        filter: {},
    };
}

const LD_FLEET_SORTABLE_COLUMNS = ["name", "eni", "flag", "tel1", "inactive"] as const;

/**
 * This page displays a table view of the user's ships. It allows the user to add new ships,
 * edit or delete existing ones, and start a voyage with a particular ship.
 * Features a Vessel Form in a sidebar.
 *
 * @returns The LDManageFleet component.
 */
export const LDManageFleet = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const intl = useIntl();

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [ships, setShips] = useState<IShip[]>([]);
    const [totalShips, setTotalShips] = useState(0);
    const [selectedShipId, setSelectedShipId] = useUrlState(
        "selectedShipId",
        "",
        Serializers.string,
    );
    const [selectedShip, setSelectedShip] = useState<IAddEditShip>();
    const [loading, setLoading] = useState(false);
    const [owningCompanies, setOwningCompanies] = useState<IAddress[]>([]);

    const [filters, setFilters] = useGuiConfState("ldManageFleet");
    const registerSort = useTableSort<typeof LD_FLEET_SORTABLE_COLUMNS>({
        columns: LD_FLEET_SORTABLE_COLUMNS,
        value: filters.order as SortCriteria<string, SortDirection>[],
        onChange: order => setFilters({ order }),
    });

    const handleSelectShip = useCallback(
        (row: IShip) => {
            setSelectedShip({
                ...row,
                lastUpdated: undefined,
            } as IAddEditShip);
            setSelectedShipId(row.id);
            setSidebarOpen(true);
        },
        [setSelectedShipId],
    );

    useEffect(() => {
        const persistInitialShip = async () => {
            try {
                const initialShip = await getShip(selectedShipId);
                handleSelectShip(initialShip.data);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.ship));
            }
        };
        if (selectedShipId) {
            persistInitialShip();
        }
    }, [selectedShipId, dispatch, intl, handleSelectShip]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedGetFleet = useCallback(
        debounce(() => {
            getShips();
        }, 300),
        [filters],
    );

    useEffect(() => {
        debouncedGetFleet();
        return () => debouncedGetFleet.cancel();
    }, [debouncedGetFleet]);

    const onAddShip = async () => {
        setSidebarOpen(false);
        debouncedGetFleet();
    };

    const getShips = useCallback(async () => {
        try {
            setLoading(true);
            const res = await searchShip(buildPayload(filters));
            setShips(res.data.rows ?? []);
            setTotalShips(res.data.total ?? 0);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.ships));
        } finally {
            setLoading(false);
        }
    }, [intl, filters, dispatch]);

    const handleChangePage = (_event: unknown, newPage: number) => {
        setFilters({ page: newPage });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilters({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
        });
    };

    const openNewVoyageForm = (ship: IShip) => {
        navigate("/plan-voyage/", { state: ship.id });
    };

    const getOwningCompanies = useCallback(async () => {
        try {
            setLoading(true);
            const res = await searchAddress({
                ...searchParamsInit,
                filter: { $and: [{ type: AddressType.OwningCompany }] },
            });
            setOwningCompanies(res.data.rows ?? []);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.owningCompanies));
        } finally {
            setLoading(false);
        }
    }, [dispatch, intl]);

    useEffect(() => {
        getOwningCompanies();
    }, [getOwningCompanies]);

    return (
        <RpisPage
            title={intl.formatMessage(Messages.manageFleet)}
            className="ld-manage-fleet-container"
        >
            <SidebarLayout
                sidebarTitle={
                    selectedShip
                        ? intl.formatMessage(Messages.editVesel)
                        : intl.formatMessage(Messages.addVessel)
                }
                open={sidebarOpen}
                onClose={() => {
                    setSidebarOpen(false);
                    setSelectedShipId("");
                }}
                sidebarContent={
                    <VesselForm
                        selectedShip={selectedShip}
                        callback={onAddShip}
                        isDisabled={false}
                        owningCompanies={owningCompanies}
                    />
                }
            >
                <div className="title-and-actions-container">
                    <div id="search-bar-form">
                        <FormControl variant="filled">
                            <FilledInput
                                onChange={e => setFilters({ textSearch: e.target.value })}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                                value={filters.textSearch}
                                autoFocus={true}
                            />
                            <InputLabel>{intl.formatMessage(Messages.search)}</InputLabel>
                        </FormControl>
                    </div>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setSelectedShip(undefined);
                            setSidebarOpen(true);
                        }}
                    >
                        {intl.formatMessage(Messages.addVessel)}
                    </Button>
                </div>
                <TableContainer className="manage-fleet-table-container">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCellSorted
                                    label={intl.formatMessage(Messages.shipName)}
                                    {...registerSort("name")}
                                />
                                <TableCellSorted
                                    align="right"
                                    label="ENI"
                                    {...registerSort("eni")}
                                />
                                <TableCellSorted
                                    align="right"
                                    label={intl.formatMessage(Messages.flag)}
                                    {...registerSort("flag")}
                                />
                                <TableCellSorted
                                    align="right"
                                    label={intl.formatMessage(Messages.onBoardTelephone1)}
                                    {...registerSort("tel1")}
                                />
                                <TableCellSorted
                                    align="right"
                                    label={`${intl.formatMessage(
                                        Messages.active,
                                    )} / ${intl.formatMessage(Messages.inactive)}`}
                                    {...registerSort("inactive")}
                                />
                                <TableCell align="center">
                                    {intl.formatMessage(Messages.actions)}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {loading ? (
                            <TableSkeleton columns={6} rows={filters.rowsPerPage} />
                        ) : (
                            <TableBody>
                                {ships.map(row => (
                                    <TableRow
                                        key={row.id}
                                        className={classNames({
                                            "active-table-row":
                                                selectedShip?.id === row.id && sidebarOpen,
                                        })}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">{row.eni}</TableCell>
                                        <TableCell align="right">{row.flag}</TableCell>
                                        <TableCell align="right">{row.tel1}</TableCell>
                                        <TableCell align="right">
                                            {row.inactive
                                                ? intl.formatMessage(Messages.inactive)
                                                : intl.formatMessage(Messages.active)}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button
                                                variant="outlined"
                                                startIcon={<EditIcon />}
                                                onClick={() => handleSelectShip(row)}
                                            >
                                                {intl.formatMessage(Messages.edit)}
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                startIcon={<OpenInNewIcon />}
                                                onClick={() => openNewVoyageForm(row)}
                                            >
                                                {intl.formatMessage(Messages.newVoyage)}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[50, 100, 250, 500]}
                    component="div"
                    count={totalShips}
                    rowsPerPage={filters.rowsPerPage}
                    page={filters.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </SidebarLayout>
        </RpisPage>
    );
};

export default LDManageFleet;
