import axios, { AxiosResponse } from "axios";
import { IDispUser } from "../models/user";

export const getUser = async (id: string): Promise<AxiosResponse<IDispUser>> => {
    return axios.get(`/dispatcher/getUser/${id}`);
};

export const changeUser = async (user: IDispUser): Promise<AxiosResponse<IDispUser>> => {
    return axios.patch("/dispatcher/changeUser", user);
};
