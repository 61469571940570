import { TableRow, TableCell, IconButton, Switch } from "@mui/material";
import classNames from "classnames";
import StatusChip from "../../../../components/StatusChip/StatusChip";
import Messages from "../../../../localization/Messages";
import { BookingStatus, IBooking } from "../../../../models/booking";
import { isBookingStatusProvisional } from "../../shared/utils";
import BookingMenuButtonGroup, { BookingMenuButtonGroupProps } from "../BookingMenuButtonGroup";
import { useIntl } from "react-intl";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/configureStore";
import { BatchToggleControlHandler } from "../../hooks/useBatchToggleControl";
import { memo } from "react";
import LineClampedText from "../../../../components/LineClampedText";
import DateTimeTwoRowDisplay from "../DateTimeTwoRowDisplay";
import "./index.css";
import OnCallStatus from "../../../OnCallContainers/OCManageBookings/components/OnCallStatus/OnCallStatus";
import { useCachedDocks } from "../../../../hooks/useCachedDocks";

export type BookingRowProps = {
    row: IBooking;
    active?: boolean;
    actions: BookingMenuButtonGroupProps["menuActions"];
    onRowClick: (row: IBooking) => void;
    expandedRowsHandler: BatchToggleControlHandler;
    selectedBookingsHandler: BatchToggleControlHandler;
};

/**
 * Renders the Row of the Bookings table in Manage bookings.
 */
function BookingRow({
    row,
    actions,
    active = false,
    onRowClick,
    expandedRowsHandler,
    selectedBookingsHandler,
}: BookingRowProps) {
    const { byId } = useCachedDocks();
    const timeZones = useSelector((s: AppState) => s.destinationZones).data;
    const intl = useIntl();

    return (
        <TableRow
            className={classNames("main-row", {
                expanded: expandedRowsHandler.isActive(row.id),
                "active-table-row": active,
            })}
        >
            <TableCell scope="row">
                <Switch
                    onChange={() => selectedBookingsHandler.toggle(row.id)}
                    checked={selectedBookingsHandler.isActive(row.id)}
                    disabled={!isBookingStatusProvisional(row.status)}
                />
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => expandedRowsHandler.toggle(row.id)}
                >
                    {/** TODO: Optimize performance when handlers change. */}
                    {expandedRowsHandler.isActive(row.id) ? (
                        <KeyboardArrowUp />
                    ) : (
                        <KeyboardArrowDown />
                    )}
                </IconButton>
            </TableCell>

            <TableCell>
                <LineClampedText text={row.shipName} />
            </TableCell>

            <TableCell className="whitespace-nowrap">{row.ship?.eni}</TableCell>

            <TableCell>
                <LineClampedText
                    text={
                        row.berthings && row.berthings.length > 1
                            ? intl.formatMessage(Messages.multiple)
                            : byId.get(row.berthings?.[0]?.dockId ?? "")?.name
                    }
                />
            </TableCell>

            <TableCell align="right">
                <DateTimeTwoRowDisplay
                    date={row.arrivalTime}
                    timeZone={timeZones.get(row.destinationId)}
                />
            </TableCell>

            <TableCell align="right">
                <DateTimeTwoRowDisplay
                    date={row.departureTime}
                    timeZone={timeZones.get(row.destinationId)}
                />
            </TableCell>

            <TableCell>
                <LineClampedText text={row.managingCompanyName} />
            </TableCell>

            <TableCell>
                <LineClampedText text={row.owningCompany} />
            </TableCell>

            <TableCell align="right">
                <DateTimeTwoRowDisplay
                    date={row.changeRequestDate ? row.changeRequestDate : row.requestDate}
                />
            </TableCell>

            <TableCell align="right">
                {row.confirmTime &&
                [BookingStatus.CONFIRMED, BookingStatus.CHANGED].includes(row.status) ? (
                    <DateTimeTwoRowDisplay date={row.confirmTime} />
                ) : (
                    ""
                )}
            </TableCell>

            <TableCell align="right">
                <StatusChip status={row.status?.toString()} />
            </TableCell>

            <TableCell align="center">
                <OnCallStatus booking={row} />
            </TableCell>

            <TableCell align="right">
                {row.complete ? intl.formatMessage(Messages.yes) : intl.formatMessage(Messages.no)}
            </TableCell>

            <TableCell align="center">
                <BookingMenuButtonGroup
                    row={row}
                    onClick={() => onRowClick(row)}
                    menuActions={actions}
                />
            </TableCell>
        </TableRow>
    );
}

export default memo(BookingRow);
