import { BerthingDockGroup } from "../containers/LDManageVoyages/components/BerthingsDockName";
import { getDateMoment } from "../helpers/dateHelper";
import { BookingStatus } from "./booking";
import { IShip, shipInit } from "./ship";

export interface IAddEditStop {
    id: number;
    areaPreference: string;
    crewOnBoard: number;
    deleted: 0 | 1;
    destinationId: string;
    eta: number;
    etd: number;
    occupiedBeds: number;
    companyId: string;
    personsOnBoard: number;
    voyageId: string;
    bookingId?: number | null;
    bookingStatus?: BookingStatus | null;
    ship: IShip;
}

export interface IStop extends IAddEditStop {
    createBy: string;
    createTs: number;
    lastModifyBy: string;
    lastModifyTs: number;
    berthings?: BerthingDockGroup[];
}

export const addEditStopInit: IAddEditStop = {
    id: 0,
    areaPreference: "",
    crewOnBoard: 0,
    deleted: 0,
    destinationId: "",
    eta: getDateMoment().toDate().getTime(),
    etd: getDateMoment().toDate().getTime(),
    occupiedBeds: 0,
    companyId: "",
    personsOnBoard: 0,
    voyageId: "",
    ship: shipInit,
};

export const stopInit: IStop = {
    ...addEditStopInit,
    createBy: "",
    createTs: 0,
    lastModifyBy: "",
    lastModifyTs: 0,
};

export const disabledStopStatuses = [BookingStatus.CANCELLED, BookingStatus.DECLINED];
