import { useIntl } from "react-intl";
import { IShipDispatcher } from "../../../../models/shipDispatcher";
import { ServiceFiltersType } from "../../shared/filters";
import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import Messages from "../../../../localization/Messages";
import { getDateNumber } from "../../../../helpers/dateHelper";
import { ServiceStatus, serviceStatusOptions } from "../../../../models/service";
import { Clear, Close } from "@mui/icons-material";

export const SERVICES_SORTABLE_COLUMNS = [
    "shipname",
    "startDateTime",
    "endDateTime",
    "type",
    "status",
] as const;

export type ServiceFiltersProps = {
    filters: ServiceFiltersType;
    ships: IShipDispatcher[];
    setFilters: (filters: Partial<ServiceFiltersType>) => void;
};

export const ServiceFilters = ({
    filters,
    ships,
    setFilters: setFiltersNaitive,
}: ServiceFiltersProps) => {
    const intl = useIntl();

    const setFilters = (filters: Partial<ServiceFiltersType>) => {
        setFiltersNaitive({ ...filters, page: 0 });
    };

    const clearFilters = () => {
        setFilters({
            dateFrom: null,
            dateTo: null,
            type: undefined,
            status: [],
            shipId: "",
            page: 0,
        });
    };

    return (
        <Grid container maxWidth={400} spacing={1} padding={1}>
            <Grid item xs={12} sm={6}>
                <DesktopDatePicker
                    label={intl.formatMessage(Messages.startDate)}
                    inputFormat="DD/MM/YYYY"
                    value={filters.dateFrom}
                    onChange={value =>
                        (!isNaN(getDateNumber(value)) || value === null) &&
                        setFilters({ dateFrom: value })
                    }
                    renderInput={params => <TextField fullWidth variant="filled" {...params} />}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DesktopDatePicker
                    label={intl.formatMessage(Messages.endDate)}
                    inputFormat="DD/MM/YYYY"
                    value={filters.dateTo}
                    onChange={value =>
                        (!isNaN(getDateNumber(value)) || value === null) &&
                        setFilters({ dateTo: value })
                    }
                    renderInput={params => <TextField fullWidth variant="filled" {...params} />}
                />
            </Grid>

            <Grid item xs={12}>
                <Autocomplete
                    id="expense-owning-company"
                    fullWidth
                    options={ships}
                    getOptionLabel={option => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={props => (
                        <TextField
                            {...props}
                            label={intl.formatMessage(Messages.ship)}
                            variant="filled"
                        />
                    )}
                    renderOption={(props, option) => <li {...props}>{option.name}</li>}
                    onChange={(_, value) => {
                        setFilters({ shipId: value?.id });
                    }}
                    value={ships.find(ship => ship.id === filters.shipId) ?? null}
                />
            </Grid>

            <Grid item xs={12}>
                <FormControl className="form-control" variant="filled" fullWidth>
                    <InputLabel>{intl.formatMessage(Messages.serviceStatus)}</InputLabel>
                    <Select
                        multiple
                        id="booking-status"
                        value={filters.status}
                        label={intl.formatMessage(Messages.serviceStatus)}
                        placeholder={intl.formatMessage(Messages.serviceStatus)}
                        variant="filled"
                        onChange={(event: SelectChangeEvent<ServiceStatus[]>) =>
                            setFilters({
                                status: event.target.value as ServiceStatus[],
                            })
                        }
                        endAdornment={
                            filters.status.length > 0 && (
                                <IconButton onClick={() => setFilters({ status: [] })}>
                                    <Close fontSize="small" />
                                </IconButton>
                            )
                        }
                    >
                        {serviceStatusOptions.map(({ value, label }) => (
                            <MenuItem key={value} value={value}>
                                {intl.formatMessage(label)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Box display="flex" gap={2} justifyContent="space-between" flex="1">
                <Box display="flex" gap={2} flex="1" paddingY={2} paddingX={1}>
                    {(filters.dateTo || filters.dateFrom || filters.shipId || filters.status) && (
                        <Button startIcon={<Clear />} variant="outlined" onClick={clearFilters}>
                            {intl.formatMessage(Messages.clear)}
                        </Button>
                    )}
                </Box>
            </Box>
        </Grid>
    );
};
