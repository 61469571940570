import { useIntl } from "react-intl";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store/configureStore";
import { getDispatcherGuiConf, getDocksGuiConf } from "../api/guiConf";
import { showBackendMessage } from "../helpers/messagesHelper";
import { Roles } from "../models/userData";
import { fetchInitialFiltersAction, updateFiltersAction } from "../actions/guiConfsActions";
import Messages from "../localization/Messages";
import {
    GuiConfDispatcherState,
    GuiConfDocksState,
    GuiConfsFiltersState,
    GuiConfKey,
    getAppName,
    GuiConfDispatcherKey,
    GuiConfDocksKey,
} from "../store/guiConfsReducer";
import { useAuth } from "./useAuth";

function guiConfSelector(guiConfKey: GuiConfKey) {
    const isDispatcher = getAppName(guiConfKey) === "dispatcher";
    return (s: AppState) =>
        isDispatcher
            ? s.guiConfs.dispatcher[guiConfKey as GuiConfDispatcherKey]
            : s.guiConfs.docks[guiConfKey as GuiConfDocksKey];
}

export type DatatablePaginationFilters = {
    rowsPerPage: number;
    page: number;
};

/**
 * Custom hook that provides the state and a function to update the state of a specific GUI configuration.
 *
 * @template TKey - The type of the GUI configuration key.
 * @template TState - The type of the GUI configuration state.
 * @param {TKey} guiConfKey - The key of the GUI configuration.
 * @returns {[TState, (patchedFilters: Partial<TState>) => void]} - An array containing the current state and a function to update the state.
 */
export function useGuiConfState<TKey extends GuiConfKey, TState extends GuiConfsFiltersState<TKey>>(
    guiConfKey: TKey,
): [TState, (patchedFilters: Partial<TState>) => void] {
    const dispatch = useDispatch();

    const filters: TState = useSelector(guiConfSelector(guiConfKey)) as TState;

    const setFilters = useCallback(
        (patchedFilters: Partial<TState>) => {
            dispatch(updateFiltersAction(guiConfKey, patchedFilters));
        },
        [dispatch, guiConfKey],
    );

    return [filters, setFilters];
}

/**
 * Custom hook that loads the initial GUI configurations based on the user's roles.
 */
export function useLoadInitialGuiConfs(): void {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { user } = useAuth();

    useEffect(() => {
        if (user.roles.length === 0) return;
        const isDispatcher = user.roles.includes(Roles.PASSENGER_CABIN_CRUISES_DISPATCHER);
        const isDocks = user.roles.includes(Roles.LOCKS_AND_DOCKS_DISPATCHER);

        const fetchGuiConfs = async () => {
            try {
                dispatch(
                    fetchInitialFiltersAction({
                        dispatcher: (isDispatcher
                            ? await getDispatcherGuiConf()
                            : null) as typeof GuiConfDispatcherState,
                        docks: (isDocks
                            ? await getDocksGuiConf()
                            : null) as typeof GuiConfDocksState,
                    }),
                );
            } catch (error) {
                console.error(error);
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.guiConf));
            }
        };

        fetchGuiConfs();
    }, [user, dispatch, intl]);
}
