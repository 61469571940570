import axios, { AxiosResponse } from "axios";
import { ApiSearchResponse } from "../models/common";
import { ISearchParams, searchParamsInit } from "../models/searchParams";
import { IAddEditDock, IDock } from "../models/dock";
import { IPlausabilityParam, IPlausabilityResponse } from "../models/plausability";

export const addDock = async (dto: IAddEditDock): Promise<AxiosResponse<IDock>> => {
    return await axios.put("/dispatcher/addDock", dto);
};

export const changeDock = async (dto: IAddEditDock): Promise<AxiosResponse<IDock>> => {
    return await axios.patch("/dispatcher/changeDock", dto);
};

export const deleteDock = async (id: string): Promise<AxiosResponse<string>> => {
    return await axios.delete(`/dispatcher/deleteDock/${id}`);
};

export const getDock = async (id: string): Promise<AxiosResponse<IDock>> => {
    return await axios.get(`/dispatcher/getDock/${id}`);
};

export const searchDocks = async (
    searchParams: ISearchParams = searchParamsInit,
): ApiSearchResponse<IDock> => {
    return await axios.post("/dispatcher/searchDock", searchParams);
};

export const checkPlausability = async (
    dto: IPlausabilityParam,
): Promise<AxiosResponse<IPlausabilityResponse>> => {
    return await axios.post("/dispatcher/checkPlausability", dto);
};

export const getAreas = async (destinationId: string): Promise<AxiosResponse<string[]>> => {
    return await axios.get(`/docks/getAreas/${destinationId}`);
};

export const getAreasDisp = async (): Promise<AxiosResponse<string[]>> => {
    return await axios.get("/dispatcher/getAreas");
};
