import { DesktopDatePicker } from "@mui/x-date-pickers";
import Messages from "../../../../localization/Messages";
import { useIntl } from "react-intl";
import { ExpenseFiltersType } from "../../shared/filters";
import { IAddress } from "../../../../models/address";
import { IExpenseType } from "../../../../models/expenseType";
import { IShipDispatcher } from "../../../../models/shipDispatcher";
import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import { expenseStatuses } from "../../../../models/expense";
import { memo } from "react";
import "./ExpenseFilters.css";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/configureStore";
import { Clear } from "@mui/icons-material";
import { getKeyByValue } from "../../../../helpers/mapHelper";
import { IInvoice } from "../../../../models/invoice";
import { getDateNumber } from "../../../../helpers/dateHelper";

export const EXPENSES_SORTABLE_COLUMNS = [
    "expenseDate",
    "expenseTypeId",
    "shipName",
    "costNet",
    "owningCompany",
    "managingCompanyName",
    "invoiceId",
    "name",
] as const;

export type ExpenseFiltersProps = {
    filters: ExpenseFiltersType;
    ships: IShipDispatcher[];
    owningCompanies: IAddress[];
    expenseTypes: IExpenseType[];
    setFilters: (filters: Partial<ExpenseFiltersType>) => void;
    invoices: IInvoice[];
};

/**
 * Represents a component that displays filters for Manage expenses table.
 *
 * @component
 * @example
 * ```tsx
 * <ExpenseFilters
 *    filters={filters}
 *    ships={ships}
 *    owningCompanies={owningCompanies}
 *    expenseTypes={expenseTypes}
 *    setFilters={setFilters}
 *    invoices={invoices}
 * />
 * ```
 */
export const ExpenseFilters = ({
    filters,
    ships,
    owningCompanies,
    expenseTypes,
    setFilters: setFiltersNaitive,
    invoices,
}: ExpenseFiltersProps) => {
    const intl = useIntl();
    const managingCompanies = useSelector((s: AppState) => s.managingCompanies).data;

    const setFilters = (filters: Partial<ExpenseFiltersType>) => {
        setFiltersNaitive({ ...filters, page: 0 });
    };

    const clearFilters = () => {
        setFilters({
            dateFrom: null,
            dateTo: null,
            owningCompanyDocksId: undefined,
            managingCompany: undefined,
            expenseTypeId: undefined,
            bookingId: undefined,
            internalInvoiceNumber: undefined,
            status: undefined,
            shipName: "",
            page: 0,
        });
    };

    return (
        <Grid container maxWidth={400} spacing={1} padding={1}>
            <Grid item xs={12} sm={6}>
                <DesktopDatePicker
                    label={intl.formatMessage(Messages.from)}
                    inputFormat="DD/MM/YYYY"
                    value={filters.dateFrom}
                    onChange={value =>
                        (!isNaN(getDateNumber(value)) || value === null) &&
                        setFilters({ dateFrom: value })
                    }
                    renderInput={params => <TextField fullWidth variant="filled" {...params} />}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DesktopDatePicker
                    label={intl.formatMessage(Messages.to)}
                    inputFormat="DD/MM/YYYY"
                    value={filters.dateTo}
                    onChange={value =>
                        (!isNaN(getDateNumber(value)) || value === null) &&
                        setFilters({ dateTo: value })
                    }
                    renderInput={params => <TextField fullWidth variant="filled" {...params} />}
                />
            </Grid>

            <Grid item xs={12}>
                <Autocomplete
                    id="expense-owning-company"
                    fullWidth
                    options={owningCompanies}
                    getOptionLabel={option => option.company ?? ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={props => (
                        <TextField
                            {...props}
                            label={intl.formatMessage(Messages.owningCompany)}
                            variant="filled"
                        />
                    )}
                    renderOption={(props, option) => <li {...props}>{option.company}</li>}
                    onChange={(_, value) => {
                        setFilters({ owningCompanyDocksId: value?.id });
                    }}
                    value={
                        owningCompanies.find(
                            company => company.id === filters.owningCompanyDocksId,
                        ) ?? null
                    }
                />
            </Grid>

            <Grid item xs={12}>
                <Autocomplete
                    id="expense-managing-company"
                    fullWidth
                    options={[...managingCompanies.values()]}
                    getOptionLabel={option => option ?? ""}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderInput={props => (
                        <TextField
                            {...props}
                            label={intl.formatMessage(Messages.managingCompany)}
                            variant="filled"
                        />
                    )}
                    renderOption={(props, option) => <li {...props}>{option}</li>}
                    onChange={(_, value) => {
                        const id = getKeyByValue(managingCompanies, value);

                        setFilters({ managingCompany: id });
                    }}
                    value={managingCompanies.get(filters.managingCompany) ?? null}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    type="number"
                    variant="filled"
                    label={intl.formatMessage(Messages.bookingId)}
                    onKeyDown={e => {
                        ["e", "E", "+"].includes(e.key) && e.preventDefault();
                    }}
                    onChange={e => setFilters({ bookingId: parseInt(e.target.value) })}
                    value={filters.bookingId ?? ""}
                />
            </Grid>

            <Grid item xs={12}>
                <Autocomplete
                    id="expense-managing-company"
                    fullWidth
                    options={invoices}
                    getOptionLabel={option => option.internalInvoiceNumber ?? ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={props => (
                        <TextField
                            {...props}
                            label={intl.formatMessage(Messages.invoiceNumber)}
                            variant="filled"
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props}>{option.internalInvoiceNumber}</li>
                    )}
                    onChange={(_, value) => {
                        setFilters({ internalInvoiceNumber: value?.internalInvoiceNumber ?? "" });
                    }}
                    value={
                        invoices.find(
                            inv => inv.internalInvoiceNumber === filters.internalInvoiceNumber,
                        ) ?? null
                    }
                />
            </Grid>

            <Grid item xs={12}>
                <Autocomplete
                    fullWidth
                    id="expense-type"
                    options={expenseTypes}
                    getOptionLabel={option => option.name ?? ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={props => (
                        <TextField
                            {...props}
                            label={intl.formatMessage(Messages.expenseType)}
                            variant="filled"
                        />
                    )}
                    renderOption={(props, option) => <li {...props}>{option.name}</li>}
                    onChange={(_, value) => {
                        setFilters({ expenseTypeId: value?.id });
                    }}
                    value={
                        expenseTypes.find(
                            expenseType => expenseType.id === filters.expenseTypeId,
                        ) ?? null
                    }
                />
            </Grid>

            <Grid item xs={12}>
                <Autocomplete
                    fullWidth
                    id="expense-status"
                    options={expenseStatuses}
                    getOptionLabel={option => intl.formatMessage(option.label)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={props => (
                        <TextField
                            {...props}
                            label={intl.formatMessage(Messages.status)}
                            variant="filled"
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props}>{intl.formatMessage(option.label)}</li>
                    )}
                    onChange={(_, value) => {
                        setFilters({ status: value?.value });
                    }}
                    value={
                        expenseStatuses.find(
                            expenseStatus => expenseStatus.value === filters.status,
                        ) ?? null
                    }
                />
            </Grid>

            <Grid item xs={12}>
                <Autocomplete
                    freeSolo
                    fullWidth
                    id="ship-autocomplete"
                    options={ships}
                    value={filters.shipName}
                    onInputChange={(_, value: string) => setFilters({ shipName: value })}
                    getOptionLabel={o => (o && typeof o === "object" && "name" in o ? o.name : o)}
                    isOptionEqualToValue={(a, b) => a.id === b.id}
                    renderInput={params => (
                        <TextField
                            variant="filled"
                            {...params}
                            label={intl.formatMessage(Messages.ship)}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    )}
                />
            </Grid>
            <Box display="flex" gap={2} justifyContent="space-between" flex="1">
                <Box display="flex" gap={2} flex="1" paddingY={2} paddingX={1}>
                    {(filters.dateTo ||
                        filters.dateFrom ||
                        filters.expenseTypeId ||
                        filters.bookingId ||
                        filters.internalInvoiceNumber ||
                        filters.status ||
                        filters.shipName ||
                        filters.owningCompanyDocksId ||
                        filters.managingCompany) && (
                        <Button startIcon={<Clear />} variant="outlined" onClick={clearFilters}>
                            {intl.formatMessage(Messages.clear)}
                        </Button>
                    )}
                </Box>
            </Box>
        </Grid>
    );
};

export default memo(ExpenseFilters);
