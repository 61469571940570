import { useIntl } from "react-intl";
import { DateFormat, getDateText } from "../helpers/dateHelper";
import Messages from "../localization/Messages";
import { useCallback } from "react";

/**
 * Type for mapping function that converts an item of type T to a CSV row.
 */
export type CsvMapper<T> = (
    item: T,
) => Partial<Record<keyof typeof Messages, string | number | boolean>>;

/**
 * Custom hook that provides a function for downloading data as a CSV file.
 * @returns The download function.
 */
export function useCsvDownloader() {
    const intl = useIntl();

    /**
     * Downloads the provided data as a CSV file.
     * @param data - The data to be downloaded.
     * @param mapper - The mapping function that converts each item of type T to a CSV row.
     * @param fileName - Optional. The name of the downloaded file. If not provided, a default name will be used.
     */
    const downloadFn = useCallback(
        <T>(data: T[], mapper: CsvMapper<T>, fileName?: string) => {
            const localFileName =
                fileName ?? `export-${getDateText(Date.now(), DateFormat.API_DATE)}.csv`;
            let csvContent = "data:text/csv;charset=utf-8,";
            if (data && data.length > 0) {
                const keys = Object.keys(mapper(data[0]));
                const header = keys
                    .map(intlKey => intl.formatMessage(Messages[intlKey as keyof typeof Messages]))
                    .join(";");
                const content = data
                    .map(item => {
                        const row = mapper(item);
                        return keys.map(key => row[key as keyof typeof row]).join(";");
                    })
                    .join("\n");
                csvContent += `${header}\n${content}`;
            }
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", localFileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        [intl],
    );

    return downloadFn;
}
