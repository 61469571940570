import { DateFormat } from "../helpers/dateHelper";
import Messages from "../localization/Messages";
import moment from "moment";
import { ITimetable, timetableInit } from "./timetable";

export interface IExpenseType {
    id: string;
    accRef: string;
    category: string;
    companyId?: string;
    createBy?: string;
    deleted?: number;
    name: string;
    unit: string;
    validFrom?: string | null;
    validTo?: string | null;
    periods?: IExpensePeriod[];
    custom?: boolean;
    autoCalculationType?: AutoCalculationType;
    ordNum?: number;
}

export interface IExpensePeriod {
    id: string;
    companyId?: string;
    expenseTypeId?: string;
    name: string;
    netPricePerUnit: number | null;
    validFrom: string | null;
    validTo: string | null;
    vat: number | null;
    docks: string[];
    deleted?: number;
    timeTable: ITimetable;
}

export enum AutoCalculationType {
    NOT_APPLICABLE = "NOT_APPLICABLE",
    FIRST_24H_PER_BED = "FIRST_24H_PER_BED",
    REST_OF_STAY_PER_24H_PER_BED = "REST_OF_STAY_PER_24H_PER_BED",
    PER_24H_PER_BED = "PER_24H_PER_BED",
    PER_BOOKING = "PER_BOOKING",
    PER_INVOICE = "PER_INVOICE",
}

export const expenseTypeInit: IExpenseType = {
    id: "",
    name: "",
    accRef: "",
    category: "",
    unit: "",
};

export const expensePeriodInit: IExpensePeriod = {
    id: "",
    name: "",
    netPricePerUnit: 0,
    validFrom: "",
    validTo: "",
    vat: 0,
    docks: [],
    timeTable: timetableInit,
};

export enum ExpenseCategory {
    UNKNOWN = "UNKNOWN",
    BERTHING_FEES = "BERTHING_FEES",
    ELECTRICITY = "ELECTRICITY",
    WATER = "WATER",
    RECYCLING = "RECYCLING",
    SERVICES = "SERVICES",
    ADMINISTRATION = "ADMINISTRATION",
}

export const expenseCategories = [
    { id: 0, label: Messages.unknown, value: ExpenseCategory.UNKNOWN },
    { id: 1, label: Messages.berthingFees, value: ExpenseCategory.BERTHING_FEES },
    { id: 2, label: Messages.electricity, value: ExpenseCategory.ELECTRICITY },
    { id: 3, label: Messages.water, value: ExpenseCategory.WATER },
    { id: 4, label: Messages.recycling, value: ExpenseCategory.RECYCLING },
    { id: 5, label: Messages.services, value: ExpenseCategory.SERVICES },
    { id: 6, label: Messages.administration, value: ExpenseCategory.ADMINISTRATION },
];

export const getActivePeriod = (periods: IExpensePeriod[] | undefined) => {
    const currentTime = moment(new Date()).format(DateFormat.API_DATE);
    if (periods) {
        const sortedPeriods = periods.sort((a, b) => moment(b.validFrom).diff(moment(a.validFrom)));
        for (const period of sortedPeriods) {
            const validFrom = period.validFrom;
            const validTo = period.validTo;
            if (
                moment(currentTime) >= moment(validFrom) &&
                (!validTo || moment(currentTime) <= moment(validTo))
            ) {
                return period;
            }
        }
        return sortedPeriods[0];
    }
    return null;
};
