import React, { Dispatch, ReactNode, SetStateAction, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store/configureStore";
import { setSidebarOwnerAction } from "../actions/sidebarOwnerAction";

export function useSidebarOwner<T = unknown>() {
    const dispatch = useDispatch();

    const sidebarOwner = useSelector<AppState, T | undefined>(
        s => s.sidebarOwner.data as T | undefined,
    );

    const setSidebarOwner = useCallback(
        (value: T | undefined) => {
            dispatch(setSidebarOwnerAction(value));
        },
        [dispatch],
    );

    return [sidebarOwner, setSidebarOwner] as const;
}

export type SidebarOwnerContextProps<T> = {
    sidebarOwner: T | undefined;
    setSidebarOwner: Dispatch<SetStateAction<T>>;
};

export const SidebarOwnerContext = React.createContext<SidebarOwnerContextProps<any> | undefined>(
    undefined,
);

// TODO!

// TODO: Instead of this, use Redux to manage the state of the sidebar owner.
// This means that from every page you could access the sidebar owner state (if it exists) or update it.)

export function SidebarOwnerProvider<T>({
    children,
    sidebarOwner,
    setSidebarOwner,
}: {
    children: ReactNode;
    sidebarOwner: T;
    setSidebarOwner: Dispatch<SetStateAction<T>>;
}) {
    return (
        <SidebarOwnerContext.Provider value={{ sidebarOwner, setSidebarOwner }}>
            {children}
        </SidebarOwnerContext.Provider>
    );
}
