import { IShipDispatcher } from "../../../../models/shipDispatcher";
import { useIntl } from "react-intl";
import Messages from "../../../../localization/Messages";
import DataField from "../../../../components/DataField";
import { Grid, Box } from "@mui/material";

type ViewShipContainerProps = {
    ship: IShipDispatcher;
};

export const ViewShipContainer = ({ ship }: ViewShipContainerProps) => {
    const intl = useIntl();
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item sm={4}>
                    <DataField title={intl.formatMessage(Messages.shipName)} value={ship.name} />
                </Grid>

                <Grid item sm={4}>
                    <DataField title={intl.formatMessage(Messages.eni)} value={ship.eni} />
                </Grid>

                <Grid item sm={4}>
                    <DataField title={intl.formatMessage(Messages.mmsi)} value={ship.mmsi} />
                </Grid>

                <Grid item sm={4}>
                    <DataField
                        title={intl.formatMessage(Messages.onBoardTelephone1)}
                        value={ship.data?.tel1}
                    />
                </Grid>

                <Grid item sm={4}>
                    <DataField
                        title={intl.formatMessage(Messages.onBoardTelephone2)}
                        value={ship.data?.tel2}
                    />
                </Grid>

                <Grid item sm={4}>
                    <DataField
                        title={intl.formatMessage(Messages.onBoardEmail)}
                        value={ship.data?.email}
                    />
                </Grid>
            </Grid>
            <h3>{intl.formatMessage(Messages.viewDockAccess)}</h3>
            <Grid container spacing={2}>
                <Grid item sm={4}>
                    <DataField
                        title={intl.formatMessage(Messages.keyNumber)}
                        value={ship.keyNumber}
                    />
                </Grid>

                <Grid item sm={4}>
                    <DataField
                        title={intl.formatMessage(Messages.keyDeliveryDate)}
                        value={ship.keyDeliveryDate}
                    />
                </Grid>

                <Grid item sm={4}>
                    <DataField
                        title={intl.formatMessage(Messages.electricityCardNumber)}
                        value={ship.electricityCardNumber}
                    />
                </Grid>

                <Grid item sm={4}>
                    <DataField
                        title={intl.formatMessage(Messages.electricityCardDeliveryDate)}
                        value={ship.electricityCardDeliveryDate}
                    />
                </Grid>

                <Grid item sm={4}>
                    <DataField
                        title={intl.formatMessage(Messages.dateOfIssueCurrentPlugInCard)}
                        value={ship.dateIssueCurPluginCard}
                    />
                </Grid>

                <Grid item sm={6}>
                    <DataField
                        title={intl.formatMessage(Messages.remarksFgksDispatcher)}
                        value={ship.remarks}
                    />
                </Grid>

                <Grid item sm={6}>
                    <DataField
                        title={intl.formatMessage(Messages.remarksTechnicalInformation)}
                        value={ship.remarksTechnical}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};
