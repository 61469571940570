import "./LDManageExpenses.css";
import Messages from "../../localization/Messages";
import RpisPage from "../RpisPage/RpisPage";
import { useIntl } from "react-intl";
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useCsvDownloader } from "../../hooks/useCsvDownloader";
import { IDockExpense, getLDExpenseCsvMapper } from "../../models/expense";
import { useCallback, useEffect, useState } from "react";
import { useGuiConfState } from "../../hooks/useGuiConfState";
import { buildExpensesSearchPayload } from "./shared/filters";
import { getInternalInvoiceNumbers, searchExpense } from "../../api/dockExpense";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { BOOKINGS_ROWS_PER_PAGE_OPTS } from "../ManageBookings/shared/constants";
import { displayAmount } from "../../helpers/moneyHelper";
import { getDateText } from "../../helpers/dateHelper";
import TableCellSorted from "../../components/TableCellSorted";
import { TableSkeleton } from "../../components/TableSkeleton/TableSkeleton";
import { useTableSort } from "../../hooks/useTableSort";
import { getShipNames } from "../../api/ship";
import { getOwningCompanies } from "../../api/address";
import { getExpenseTypeNames } from "../../api/dockExpenseType";
import ExpenseFilters, {
    DOCK_EXPENSES_SORTABLE_COLUMNS,
} from "./components/ExpenseFilters/ExpenseFilters";
import RpisDialog from "../RpisDialog/RpisDialog";
import { searchParamsInit } from "../../models/searchParams";
import { searchStops } from "../../api/stop";
import { IStop } from "../../models/stop";
import { useNavigate } from "react-router-dom";
import { getInvoice } from "../../api/dockInvoice";
import { IInvoice, InvoiceStatus } from "../../models/invoice";
import { Serializers, useUrlState } from "../../hooks/useUrlState";
import ViewInvoice from "../../components/ViewInvoice/ViewInvoice";
import { formatDecimalNumber } from "../../helpers/numberDisplayHelper";

/**
 * Represents the page where the user can view their invoiced expenses in a table view format.
 * This component fetches and displays a list of expenses, allowing the user to filter and sort them.
 * It also provides options to export the expenses as a CSV file and view detailed information about each expense.
 *
 * @returns The LDManageExpenses component.
 */
export const LDManageExpenses = () => {
    const intl = useIntl();
    const exportCsv = useCsvDownloader();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [expenseTypeNames, setExpenseTypeNames] = useState<string[]>([]);
    const [expenses, setExpenses] = useState<IDockExpense[]>([]);
    const [loading, setLoading] = useState(false);
    const [owningCompanies, setOwningCompanies] = useState<string[]>([]);
    const [ships, setShips] = useState<string[]>([]);
    const [filters, setFilters] = useGuiConfState("ldManageExpenses");
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [internalInvoiceNumbers, setInternalInvoiceNumbers] = useState<string[]>([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState<IInvoice>();
    const [selectedInvoiceId, setSelectedInvoiceId] = useUrlState(
        "selectedInvoiceId",
        "",
        Serializers.string,
    );

    useEffect(() => {
        const persistInitialInvoice = async () => {
            if (selectedInvoiceId) {
                try {
                    const res = await getInvoice(selectedInvoiceId);
                    setSelectedInvoice(res);
                    setDialogOpen(true);
                } catch {
                    dispatch(showBackendMessage(intl, "error", "fetching", Messages.invoice));
                }
            }
        };
        if (selectedInvoiceId) {
            persistInitialInvoice();
        }
    }, [selectedInvoiceId, dispatch, intl]);

    const registerSort = useTableSort<typeof DOCK_EXPENSES_SORTABLE_COLUMNS>({
        columns: DOCK_EXPENSES_SORTABLE_COLUMNS,
        value: filters.order,
        onChange: order => setFilters({ order }),
    });

    const getExpenses = useCallback(async () => {
        try {
            setLoading(true);
            const searchParams = buildExpensesSearchPayload(filters);
            const apiResponse = await searchExpense(searchParams);
            setExpenses(apiResponse.data.rows ?? []);
            setTotalExpenses(apiResponse.data.total ?? 0);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.expenses));
        } finally {
            setLoading(false);
        }
    }, [dispatch, filters, intl]);

    useEffect(() => {
        getExpenses();
    }, [getExpenses]);

    useEffect(() => {
        const getShips = async () => {
            try {
                const res = await getShipNames();
                setShips(res ?? []);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.ships));
            }
        };
        getShips();
    }, [dispatch, intl]);

    useEffect(() => {
        const searchExpenseTypeNames = async () => {
            try {
                const res = await getExpenseTypeNames();
                setExpenseTypeNames(res ?? []);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.expenseTypes));
            }
        };
        searchExpenseTypeNames();
    }, [dispatch, intl]);

    useEffect(() => {
        const searchOwningCompanies = async () => {
            try {
                const res = await getOwningCompanies();
                setOwningCompanies(res ?? []);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.owningCompanies));
            }
        };
        searchOwningCompanies();
    }, [dispatch, intl]);

    useEffect(() => {
        const searchInvoices = async () => {
            try {
                const res = await getInternalInvoiceNumbers({
                    ...searchParamsInit,
                    order: ["internalInvoiceNumber desc"],
                    filter: { $and: [{ status: InvoiceStatus.INVOICED }] },
                });
                setInternalInvoiceNumbers(res ?? []);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.invoices));
            }
        };
        searchInvoices();
    }, [dispatch, intl]);

    const handleLinkToBooking = useCallback(
        async (expense: IDockExpense) => {
            let link = "";
            try {
                const res = await searchStops({
                    ...searchParamsInit,
                    filter: { $and: [{ bookingId: expense.bookingId }] },
                });
                if (res.data.rows.length) {
                    const stop: IStop = res.data.rows[0];
                    link = `/plan-voyage/${stop.voyageId}?selectedStopId=${stop.id}`;
                }
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.booking));
            }
            if (!link.length) {
                return;
            }
            navigate(link);
        },
        [dispatch, intl, navigate],
    );

    const handleOpenInvoiceDialog = useCallback(
        async (expense: IDockExpense) => {
            try {
                const res = await getInvoice(String(expense.invoiceId));
                setSelectedInvoice(res);
                setSelectedInvoiceId(String(res.id));
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.invoice));
            }

            if (selectedInvoice?.id) {
                setDialogOpen(true);
            }
        },
        [dispatch, intl, selectedInvoice, setSelectedInvoiceId],
    );

    const handleChangePage = useCallback(
        (_event: unknown, page: number) => {
            setFilters({ page });
        },
        [setFilters],
    );

    const handleChangeRowsPerPage = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setFilters({
                rowsPerPage: parseInt(event.target.value, 10),
                page: 0,
            });
        },
        [setFilters],
    );

    return (
        <RpisPage
            title={intl.formatMessage(Messages.manageExpenses)}
            className="ld-manage-expenses-container"
        >
            {selectedInvoice && (
                <RpisDialog
                    className="view-invoice-dialog"
                    title={`${intl.formatMessage(Messages.invoice)} ${
                        selectedInvoice.internalInvoiceNumber
                    }`}
                    fullWidth={true}
                    size="xl"
                    dialogOpen={dialogOpen}
                    onClose={() => {
                        setDialogOpen(false);
                        setSelectedInvoiceId("");
                    }}
                    content={<ViewInvoice selectedInvoice={selectedInvoice} />}
                />
            )}

            <div className="ld-manage-expenses-actions-container">
                <ExpenseFilters
                    internalInvoiceNumbers={internalInvoiceNumbers}
                    expenseTypeNames={expenseTypeNames}
                    owningCompanies={owningCompanies}
                    setFilters={setFilters}
                    filters={filters}
                    ships={ships}
                />
                <Box
                    display="flex"
                    alignItems="center"
                    gap={2}
                    marginLeft={"auto"}
                    marginRight={"20px"}
                >
                    <Button
                        className="whitespace-nowrap"
                        variant="contained"
                        color="success"
                        onClick={() => exportCsv(expenses, getLDExpenseCsvMapper())}
                    >
                        {intl.formatMessage(Messages.exportCsv)}
                    </Button>
                </Box>
            </div>
            <TableContainer className="ld-manage-expenses-table-container">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCellSorted
                                label={intl.formatMessage(Messages.expenseType)}
                                {...registerSort("name")}
                            />
                            <TableCell>{intl.formatMessage(Messages.quantity)}</TableCell>
                            <TableCellSorted
                                label={intl.formatMessage(Messages.totalCostNet)}
                                {...registerSort("costNet")}
                            />
                            <TableCellSorted
                                label={intl.formatMessage(Messages.expenseDate)}
                                {...registerSort("expenseDate")}
                            />

                            <TableCellSorted
                                label={intl.formatMessage(Messages.owningCompany)}
                                {...registerSort("owningCompany")}
                            />

                            <TableCellSorted
                                label={intl.formatMessage(Messages.ship)}
                                {...registerSort("shipName")}
                            />
                            <TableCellSorted
                                label={intl.formatMessage(Messages.invoiceNumber)}
                                {...registerSort("invoiceId")}
                            />
                            <TableCell>{intl.formatMessage(Messages.actions)}</TableCell>
                        </TableRow>
                    </TableHead>

                    {!expenses.length && loading ? (
                        <TableSkeleton rows={10} columns={10} />
                    ) : (
                        <TableBody>
                            {expenses.map(row => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.name ?? ""}</TableCell>
                                    <TableCell>{formatDecimalNumber(row.quantity)}</TableCell>
                                    <TableCell>
                                        {displayAmount(row.costNet, row.currency)}
                                    </TableCell>
                                    <TableCell>{getDateText(row.expenseDate)}</TableCell>

                                    <TableCell>{row?.owningCompany ?? ""}</TableCell>

                                    <TableCell>{row.shipName ?? ""}</TableCell>
                                    <TableCell>
                                        {row.internalInvoiceNumber ?? row.invoiceId ?? ""}
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            onClick={() => handleLinkToBooking(row)}
                                        >
                                            {intl.formatMessage(Messages.viewBooking)}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            onClick={() => {
                                                handleOpenInvoiceDialog(row);
                                            }}
                                        >
                                            {intl.formatMessage(Messages.viewInvoice)}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                page={totalExpenses === 0 ? 0 : filters.page}
                count={totalExpenses}
                rowsPerPage={filters.rowsPerPage}
                rowsPerPageOptions={BOOKINGS_ROWS_PER_PAGE_OPTS}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </RpisPage>
    );
};
