import { Box, Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
import InfoSharp from "@mui/icons-material/InfoSharp";
import Cancel from "@mui/icons-material/Cancel";
import CheckBox from "@mui/icons-material/CheckBox";
import ErrorIcon from "@mui/icons-material/Error";
import { useIntl } from "react-intl";
import Messages from "../../../../../localization/Messages";
import { IBooking } from "../../../../../models/booking";

type OnCallStatusProps = {
    booking: IBooking | undefined;
};

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        minHeight: 30,
        display: "flex",
        alignItems: "center",
        fontSize: "15px",
    },
});

export const OnCallStatus = ({ booking }: OnCallStatusProps) => {
    const intl = useIntl();

    return (
        <>
            {booking?.id && (
                <Box display={"flex"} gap={0.5}>
                    <CustomTooltip
                        title={
                            booking.finalized
                                ? intl.formatMessage(Messages.bookingCompleted)
                                : intl.formatMessage(Messages.bookingNotCompleted)
                        }
                    >
                        {booking.finalized ? (
                            <CheckBox color="success" />
                        ) : (
                            <Cancel color="warning" />
                        )}
                    </CustomTooltip>
                    {booking.sharedRemark && (
                        <CustomTooltip
                            title={
                                <span style={{ whiteSpace: "pre-line" }}>
                                    {booking.sharedRemark}
                                </span>
                            }
                        >
                            <ErrorIcon color="error" />
                        </CustomTooltip>
                    )}
                    {booking.comment && (
                        <CustomTooltip
                            title={
                                <span style={{ whiteSpace: "pre-line" }}>{booking.comment}</span>
                            }
                        >
                            <InfoSharp color="primary" />
                        </CustomTooltip>
                    )}
                </Box>
            )}
        </>
    );
};

export default OnCallStatus;
