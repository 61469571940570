import Messages from "../../../localization/Messages";
import RpisPage from "../../RpisPage/RpisPage";
import { useIntl } from "react-intl";
import SidebarLayout from "../../SidebarLayout/SidebarLayout";
import { Datatable, DtColumnDef } from "../../../components/Datatable/Datatable";
import { useState, useMemo } from "react";
import { useFetchBookings } from "../../../hooks/useFetchBookings";
import { buildBookingsSearchPayload } from "../../ManageBookings/shared/filters";
import { useGuiConfState } from "../../../hooks/useGuiConfState";
import { IBooking } from "../../../models/booking";
import { DateFormat, getDateRangeText } from "../../../helpers/dateHelper";
import LineClampedText from "../../../components/LineClampedText";
import DateTimeTwoRowDisplay from "../../ManageBookings/components/DateTimeTwoRowDisplay";
import { AppState } from "../../../store/configureStore";
import { BOOKINGS_ROWS_PER_PAGE_OPTS } from "../../ManageBookings/shared/constants";
import StatusChip from "../../../components/StatusChip/StatusChip";
import { useBookingRenderAction } from "./hooks/useBookingRenderActions";
import { useSelector } from "react-redux";
import BookingFilters from "../../ManageBookings/components/BookingFilters";
import useFetchShips from "../../ManageBookings/hooks/useFetchShips";
import { Box, Button, ButtonGroup, Tooltip } from "@mui/material";
import { Clear, Tune } from "@mui/icons-material";
import { OnCallStatus } from "./components/OnCallStatus/OnCallStatus";
import { OCBookingMenu } from "./components/OCBookingMenu/OCBookingMenu";
import BookingSidebarTitle from "../../ManageBookings/components/BookingSidebarTitle";
import DatatablePagination from "../../../components/Datatable/components/DatatablePagination";
import { useSidebarOwner } from "../../../contexts/SidebarOwnerContext";
import "./OCManageBookings.css";
import { useCachedDocks } from "../../../hooks/useCachedDocks";

export const OCManageBookings = () => {
    const intl = useIntl();

    // TODO
    // ", setSelected.*\] = useState"
    // This is VSCode regex (without enclosing quotes) to search files which need to be refactored.

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [filters, setFilters] = useGuiConfState("dispBookings");
    const { bookings, loading, totalBookings, fetchBookings } = useFetchBookings(
        buildBookingsSearchPayload(filters),
    );
    const timeZones = useSelector((s: AppState) => s.destinationZones).data;
    const { byId } = useCachedDocks();
    const renderActions = useBookingRenderAction(row => handleSelectBooking(row, true));
    const [selectedBooking, setSelectedBooking] = useSidebarOwner<IBooking>();

    const handleSelectBooking = (row: IBooking | undefined, sidebarOpen: boolean) => {
        setSelectedBooking(row);
        setSidebarOpen(sidebarOpen);
    };
    const [showFilters, setShowFilters] = useState(false);
    const [ships] = useFetchShips();

    const bookingTitleMapper = (row: IBooking) => (
        <strong>{`${row.shipName} ${getDateRangeText(
            row.arrivalTime,
            row.departureTime,
            DateFormat.CLIENT_DATE_TIME,
        )}
            
        `}</strong>
    );

    const clearFilters = () => {
        setFilters({
            shipName: undefined,
            docks: [],
            bookingStatuses: [],
            dateFrom: null,
            dateTo: null,
        });
    };

    const bookingColumnDefs: DtColumnDef<IBooking>[] = useMemo(
        () => [
            {
                key: "shipName",
                label: intl.formatMessage(Messages.shipName),
                sortable: true,
                content: row => row.shipName,
            },
            {
                key: "eni",
                label: intl.formatMessage(Messages.eni),
                sortable: false,
                content: row => row.ship.eni ?? "",
            },
            {
                key: "assignedDock",
                label: intl.formatMessage(Messages.dock),
                sortable: false,
                content: row => (
                    <LineClampedText
                        text={
                            row.berthings && row.berthings.length > 1
                                ? intl.formatMessage(Messages.multiple)
                                : byId.get(row.berthings?.[0]?.dockId ?? "")?.name
                        }
                    />
                ),
            },
            {
                key: "arrivalTime",
                label: intl.formatMessage(Messages.arrivalTime),
                sortable: true,
                content: row => (
                    <DateTimeTwoRowDisplay
                        date={row.arrivalTime}
                        timeZone={timeZones.get(row.destinationId)}
                    />
                ),
            },
            {
                key: "departureTime",
                label: intl.formatMessage(Messages.departureTime),
                sortable: true,
                content: row => (
                    <DateTimeTwoRowDisplay
                        date={row.departureTime}
                        timeZone={timeZones.get(row.destinationId)}
                    />
                ),
            },

            {
                key: "status",
                label: intl.formatMessage(Messages.status),
                sortable: true,
                content: row => <StatusChip status={row.status?.toString()} />,
            },
            {
                key: "onCallStatus",
                label: `${intl.formatMessage(Messages.remarks)} / ${intl.formatMessage(
                    Messages.onCallStatus,
                )}`,
                sortable: false,
                content: row => <OnCallStatus booking={row} />,
            },
        ],
        [intl, timeZones, byId],
    );

    const onAfterFinalize = () => {
        fetchBookings();
    };

    const onUpdateActualTimes = () => {
        fetchBookings(selectedBooking?.id);
    };

    return (
        <RpisPage
            title={intl.formatMessage(Messages.overviewOfBookings)}
            className="manage-bookings-container"
        >
            <SidebarLayout
                sidebarTitle={<BookingSidebarTitle reloadBookings={fetchBookings} />}
                open={sidebarOpen}
                sidebarContent={
                    <OCBookingMenu
                        onUpdateActualTimes={onUpdateActualTimes}
                        onAfterFinalize={onAfterFinalize}
                    />
                }
                onClose={() => setSidebarOpen(false)}
                leftSidebar={{
                    title: intl.formatMessage(Messages.filters),
                    open: showFilters,
                    onClose: () => setShowFilters(false),
                    children: (
                        <BookingFilters filters={filters} ships={ships} setFilters={setFilters} />
                    ),
                }}
            >
                <Box display="flex" padding={1} gap={1} justifyContent="space-between">
                    <ButtonGroup color="primary" variant="outlined">
                        <Button
                            variant="contained"
                            startIcon={<Tune />}
                            onClick={() => setShowFilters(true)}
                        >
                            {intl.formatMessage(Messages.filters)}
                        </Button>
                        <Tooltip title={intl.formatMessage(Messages.clearFilters)}>
                            <Button variant="contained" size="small" onClick={clearFilters}>
                                <Clear />
                            </Button>
                        </Tooltip>
                    </ButtonGroup>
                </Box>
                <Datatable
                    className="oc-manage-bookings-table-container"
                    items={bookings}
                    titleMapper={bookingTitleMapper}
                    columnDefs={bookingColumnDefs}
                    isActiveRow={row => selectedBooking?.id === row.id && sidebarOpen}
                    sortOrder={filters.order}
                    onSortChange={order => setFilters({ order })}
                    loading={loading}
                    idMapper={row => row.id}
                    renderActions={renderActions}
                    renderPagination={() => (
                        <DatatablePagination
                            filters={filters}
                            onChange={setFilters}
                            count={totalBookings}
                            rowsPerPageOptions={BOOKINGS_ROWS_PER_PAGE_OPTS}
                        />
                    )}
                />
            </SidebarLayout>
        </RpisPage>
    );
};
