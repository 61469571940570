import { Reducer } from "redux";
import { IDispatcherAddress } from "../models/address";
import { DispAddressesActionType, SET_DISP_ADDRESSES } from "../actions/dispAddressesAction";

/**
 * Represents the state of the dispatch addresses.
 */
export interface DispAddressesState {
    data: Map<string, string>;
}

/**
 * The initial state of the dispatch addresses.
 */
export const initialState: DispAddressesState = { data: new Map() };

/**
 * Sets the dispatch addresses based on the payload.
 * @param payload - The array of dispatcher addresses.
 * @returns The updated dispatch addresses state.
 */
const setDispAddresses = (payload: IDispatcherAddress[]) => {
    const data = new Map();
    payload.forEach(({ id, company }) => data.set(id, company));
    return { data };
};

/**
 * The reducer function for the dispatch addresses.
 * @param state - The current state of the dispatch addresses.
 * @param action - The dispatched action.
 * @returns The updated state of the dispatch addresses.
 */
export const dispAddressesReducer: Reducer<DispAddressesState, DispAddressesActionType> = (
    state: DispAddressesState = initialState,
    action: DispAddressesActionType,
): DispAddressesState => {
    switch (action.type) {
        case SET_DISP_ADDRESSES:
            return { ...setDispAddresses(action.payload) };

        default:
            return state;
    }
};
