import axios, { AxiosResponse } from "axios";
import { IDocument } from "../models/document";
import { IShipDispatcher } from "../models/shipDispatcher";
import { IBooking } from "../models/booking";

export const addShipDocument = async (
    shipId: string,
    dto: IDocument,
): Promise<AxiosResponse<IShipDispatcher>> => {
    return await axios.put("/dispatcher/addShipDocument", { shipId: shipId, document: dto });
};

export const deleteShipDocument = async (
    shipId: string,
    path: string,
): Promise<AxiosResponse<IShipDispatcher>> => {
    return await axios.patch("/dispatcher/deleteShipDocument", {
        shipId: shipId,
        path: path,
    });
};

export const addBookingDocument = async (
    bookingId: number,
    document: IDocument,
): Promise<AxiosResponse<IBooking>> => {
    return await axios.post("/dispatcher/addBookingDocument", { bookingId, document });
};

export const deleteBookingDocument = async (
    bookingId: number,
    path: string,
): Promise<AxiosResponse<IBooking>> => {
    return await axios.post("/dispatcher/deleteBookingDocument", { bookingId, path });
};

export const getUrlForUpload = async (file: File): Promise<AxiosResponse> => {
    return await axios.post("/dispatcher/urlForUpload", {
        mimeType: file?.type,
        fileName: file?.name,
    });
};
export const getUrlForDownload = async (
    name: string,
    path: string,
    download: boolean,
): Promise<AxiosResponse> => {
    return await axios.post("/dispatcher/urlForDownload", { name, path, download });
};

export const getUrlForUploadLD = async (file: File): Promise<AxiosResponse> => {
    return await axios.post("/docks/urlForUpload", {
        mimeType: file?.type,
        fileName: file?.name,
    });
};
export const getUrlForDownloadLD = async (
    name: string,
    path: string,
    download: boolean,
): Promise<AxiosResponse> => {
    return await axios.post("/docks/urlForDownload", { name, path, download });
};

export const uploadFileToStorage = async (file: File, url: string) => {
    return await fetch(url, { method: "PUT", body: file });
};
