import countries from "i18n-iso-countries";
import * as localeEn from "i18n-iso-countries/langs/en.json";
import * as localeFr from "i18n-iso-countries/langs/fr.json";
import * as localeDe from "i18n-iso-countries/langs/de.json";
import countryDialCodes from "./countryDialCodes";
import { Locale } from "../../models/userData";

countries.registerLocale(localeFr);
countries.registerLocale(localeEn);
countries.registerLocale(localeDe);

const localeMap = {
    0: "de",
    1: "en",
    2: "fr",
};

export const getCountryCodeMap = (locale: Locale | undefined) => {
    const localeSanitized = locale ?? 0;
    const names = Object.entries(countries.getNames(localeMap[localeSanitized]));
    return names.reduce(
        (prev, [countryCode, countryName]) => ({
            ...prev,
            [countryCode]: countryName,
        }),
        {},
    ) as Record<string, string>;
};

export const getCountryCode = (locale: Locale | undefined, localizedCountryName: string) => {
    const countryNamesMap: Record<string, string> = Object.entries(
        getCountryCodeMap(locale),
    ).reduce(
        (prev, [k, v]) => ({
            ...prev,
            [v]: k,
        }),
        {},
    );

    return countryNamesMap[localizedCountryName];
};

export const getCountryNames = (locale: Locale | undefined) => {
    return Object.values(getCountryCodeMap(locale));
};

export const countryCodes = Object.keys(getCountryCodeMap(undefined));

export const getCountryDialCode = (locale: Locale | undefined, localizedCountryName: string) => {
    return countryDialCodes[getCountryCode(locale, localizedCountryName)];
};
