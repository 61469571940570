import axios, { AxiosResponse } from "axios";
import { IAddBerthingParams, IBerthing } from "../models/berthing";
import { ApiSearchResponse } from "../models/common";
import { ISearchParams, searchParamsInit } from "../models/searchParams";

export const addBerthing = async (
    berthing: IAddBerthingParams,
): Promise<AxiosResponse<IBerthing>> => {
    return await axios.put("/dispatcher/addBerthing", berthing);
};

export const searchBerthings = async (
    searchParams: ISearchParams = searchParamsInit,
): ApiSearchResponse<IBerthing> => {
    return await axios.post("/dispatcher/searchBerthing", searchParams);
};

export const changeBerthing = async (berthing: IBerthing): Promise<IBerthing> => {
    const { bookingId, id: berthingId } = berthing;
    const oldBerthings = (
        await searchBerthings({
            ...searchParamsInit,
            filter: { bookingId },
            order: ["arrivalTime asc"],
        })
    ).data.rows;
    const newBerthings = oldBerthings.map(b => (b.id === berthingId ? berthing : b));
    await changeBerthings(bookingId, newBerthings);
    return berthing;
};

export const changeBerthings = async (
    bookingId: number,
    berthings: IBerthing[],
    approved: boolean = true,
    comment: string = "",
): Promise<AxiosResponse<IBerthing[]>> => {
    return await axios.patch("/dispatcher/changeBerthings", {
        bookingId,
        berthings,
        approved,
        comment,
    });
};

export const deleteBerthing = async (id: string): Promise<AxiosResponse<string>> => {
    return await axios.delete(`/dispatcher/deleteBerthing/${id}`);
};
