import { Reducer } from "redux";
import jwt_decode from "jwt-decode";
import {
    AuthentificationActionType,
    SET_USER_FROM_TOKEN,
    SET_USER_ROLES,
} from "../actions/authentificationActions";
import { UserData, Locale } from "../models/userData";
import { KeycloakTokenParsed } from "keycloak-js";

/**
 * Represents the initial state of the user data.
 */
export const initialState: UserData = {
    locale: Locale.de,
    roles: [],
};

/**
 * Converts a locale string to the corresponding Locale enum value.
 * @param localeString - The locale string to convert.
 * @returns The corresponding Locale enum value.
 */
const getLocale = (localeString: string | undefined): Locale => {
    if (localeString === "en") return Locale.en;
    else if (localeString === "de") return Locale.de;
    return Locale.fr;
};

/**
 * Sets the user data from the provided token.
 * @param token - The token to decode and extract user data from.
 * @returns The user data extracted from the token.
 */
const setUserFromToken = (token: string): UserData => {
    const decoded = jwt_decode<KeycloakTokenParsed>(token);
    const fromToken: UserData = {
        userName: decoded["preferred_username"],
        email: decoded["email"],
        firstName: decoded["given_name"],
        lastName: decoded["family_name"],
        companyId: decoded["companyId"],
        locale: getLocale(decoded["locale"]),
        token: token,
        roles: decoded["realm_access"]?.["roles"] ?? [],
    };
    localStorage.setItem("token", token);
    return { ...initialState, ...fromToken };
};

/**
 * The reducer function for the user data.
 * @param state - The current state of the user data.
 * @param action - The action to be performed on the user data.
 * @returns The new state of the user data.
 */
export const userReducer: Reducer<UserData, AuthentificationActionType> = (
    state: UserData = initialState,
    action: AuthentificationActionType,
): UserData => {
    switch (action.type) {
        case SET_USER_FROM_TOKEN:
            return { ...setUserFromToken(action.token) };
        case SET_USER_ROLES:
            return { ...state, roles: action.roles };
        default:
            return state;
    }
};

export default userReducer;
