import { ReactNode } from "react";
import RpisHeader from "../../components/RpisHeader/RpisHeader";
import "./RpisPage.css";

export interface IRpisHeader {
    title?: string;
    children: ReactNode;
    className?: string;
    [x: string]: any;
}

/**
 * A page layout component.
 * @param title The title of the page.
 * @param children The content of the page.
 * @param className The class name of the page.
 */
export const RpisPage = ({ title, children, className, ...props }: IRpisHeader) => {
    return (
        <div className="rpis-page-container">
            <RpisHeader title={title} />
            <main className={`rpis-page-content-container ${className}`} {...props}>
                {children}
            </main>
        </div>
    );
};

export default RpisPage;
