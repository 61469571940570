import Messages from "../localization/Messages";

export enum AddressType {
    InvoiceAddress = "InvoiceAddress",
    CharterCompany = "CharterCompany",
    ContactDetails = "ContactDetails",
    OwningCompany = "OwningCompany",
}

export const AddressTypes = [
    { id: 0, label: Messages.invoiceAddress, type: AddressType.InvoiceAddress },
    { id: 1, label: Messages.contactDetails, type: AddressType.ContactDetails },
    { id: 2, label: Messages.charterCompany, type: AddressType.CharterCompany },
    { id: 3, label: Messages.owningCompany, type: AddressType.OwningCompany },
];
export interface IAddress {
    id: string;
    docksId?: string;
    addition?: string;
    city?: string;
    company?: string;
    companyId?: string;
    country?: string;
    email?: string;
    firstName?: string;
    surname?: string;
    street?: string;
    streetNo?: string;
    tel?: string;
    type?: AddressType | null;
    zip?: string;
}

export interface IDispatcherAddress extends IAddress {
    internal?: boolean;
    accountRecNumber?: string;
    vatNumber?: string;
    accountingInfo?: string;
    deleted?: number;
    managingCompany?: string;
}

export const addressInit: IAddress = {
    id: "",
    addition: "",
    city: "",
    company: "",
    companyId: "",
    country: "",
    email: "",
    firstName: "",
    surname: "",
    street: "",
    streetNo: "",
    tel: "",
    type: null,
    zip: "",
};

export const dispatcherAddressInit: IDispatcherAddress = {
    ...addressInit,
    accountRecNumber: "",
    vatNumber: "",
    accountingInfo: "",
};
