import { getInvoice } from "../api/invoice";
import { IInvoice } from "../models/invoice";
import { FetchFunctionResult, useFetch } from "./useFetch";

export function useFetchInitialInvoice(
    selectedInvoiceId: string,
): FetchFunctionResult<IInvoice | null> {
    const { data, loading, refetch } = useFetch<IInvoice | null>({
        fetchFn: async () => {
            try {
                if (!selectedInvoiceId) return null;
                const res = await getInvoice(selectedInvoiceId);
                return res.data ?? null;
            } catch {
                return null;
            } finally {
                // NOOP
            }
        },
        fetchDeps: [selectedInvoiceId],
    });
    return { data: data, loading, refetch };
}
