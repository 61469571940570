import { INotification } from "../../models/notification";
import NotificationSkeleton from "./components/NotificationSkeleton/NotificationSkeleton";
import { Notification } from "./components/Notification/Notification";
import { Roles } from "../../models/userData";
import { useAuth } from "../../hooks/useAuth";
import "./NotificationsContainer.css";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import Messages from "../../localization/Messages";
import { useNotifications } from "../../hooks/useNotifications";

interface INotificationsContainer {
    notifications: INotification[];
    loading?: boolean;
}

/**
 * Renders a container for displaying notifications.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {INotification[]} props.notifications - The array of notifications to display.
 * @param {boolean} [props.loading=false] - Indicates whether the notifications are still loading.
 * @returns {JSX.Element} The rendered NotificationsContainer component.
 */

export const NotificationsContainer = ({
    notifications,
    loading = false,
}: INotificationsContainer) => {
    const { hasRole } = useAuth();
    const isDispatcher = hasRole(Roles.PASSENGER_CABIN_CRUISES_DISPATCHER);
    const isDocks = hasRole(Roles.LOCKS_AND_DOCKS_DISPATCHER);
    const intl = useIntl();
    const { markAllAsRead } = useNotifications();
    const displayMarkAllAsRead = notifications.some(n => !n.read);

    if (!isDispatcher && !isDocks) {
        return <></>;
    }

    //

    return (
        <div className="notifications-container-wrapper">
            {!notifications.length && loading ? (
                <NotificationSkeleton />
            ) : (
                <Box display="flex" flexDirection={"column"}>
                    {displayMarkAllAsRead && (
                        <Box alignSelf={"flex-end"} marginTop={1} marginRight={1}>
                            <button
                                onClick={() => markAllAsRead()}
                                type="button"
                                className="mark-all-as-read-button"
                            >
                                <span>{intl.formatMessage(Messages.markAllAsRead)}</span>
                            </button>
                        </Box>
                    )}
                    <Box className="notifications-container">
                        {notifications.map(n => (
                            <Notification key={n.id} notification={n} />
                        ))}
                    </Box>
                </Box>
            )}
        </div>
    );
};

export default NotificationsContainer;
