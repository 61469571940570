import { Box, TextField, Button, DialogActions } from "@mui/material";
import Messages from "../../../../localization/Messages";
import { useIntl } from "react-intl";
import { Save } from "@mui/icons-material";
import { IBooking } from "../../../../models/booking";
import { ChangeEvent, useState } from "react";
import "./index.css";
import { showBackendMessage } from "../../../../helpers/messagesHelper";
import { useDispatch } from "react-redux";
import { changeBookingComment, updateSharedRemark } from "../../../../api/booking";
import { useAuth } from "../../../../hooks/useAuth";
import { Roles } from "../../../../models/userData";
import { FinalizeBookingSection } from "../../../OnCallContainers/OCManageBookings/components/FinalizeBookingSection/FinalizeBookingSection";
import { isBookingCompletable } from "../../shared/utils";
import { useSidebarOwner } from "../../../../contexts/SidebarOwnerContext";
import { UploadBookingDocuments } from "../../../OnCallContainers/OCManageBookings/components/UploadBookingDocuments/UploadBookingDocuments";

export type BookingSidebarTitleProps = {
    reloadBookings: () => Promise<void>;
    onFinalizeBooking?: () => void;
};

type BookingRemarkDialogProps = {
    reloadBookings: () => Promise<void>;
    onClose: () => void;
    remarkType?: BOOKING_REMARK_TYPE;
};

export enum BOOKING_REMARK_TYPE {
    COMMENT = "COMMENT",
    SHARED_REMARK = "SHARED_REMARK",
}

/**
 * Renders a dialog for adding or editing a shared remark or a Dispatchern remark of a booking.
 *
 * @component
 * @example
 * ```tsx
 * <BookingRemarkDialog
 *    onClose={handleClose}
 *    reloadBookings={reloadBookings}
 * />
 * ```
 */
export function BookingRemarkDialog({
    reloadBookings,
    onClose,
    remarkType,
}: BookingRemarkDialogProps) {
    const [booking, setBooking] = useSidebarOwner<IBooking>();
    const dispatch = useDispatch();
    const intl = useIntl();
    const [remark, setRemark] = useState(
        remarkType === BOOKING_REMARK_TYPE.COMMENT ? booking?.comment : booking?.sharedRemark,
    );
    const [loading, setLoading] = useState(false);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setRemark(event.target.value ?? "");
    };

    const onRemarkSave = async () => {
        setLoading(true);
        try {
            const bookingId = booking?.id;
            if (bookingId) {
                const updatedBookingRes =
                    remarkType === BOOKING_REMARK_TYPE.COMMENT
                        ? await changeBookingComment(bookingId, remark ?? null)
                        : await updateSharedRemark(bookingId, remark ?? null);

                setBooking(updatedBookingRes.data);

                dispatch(showBackendMessage(intl, "success", "updating", Messages.remark));
            }
            onClose();
            await reloadBookings();
        } catch (error) {
            dispatch(showBackendMessage(intl, "error", "updating", Messages.comment));
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Box display="flex" marginY={1} marginRight={1}>
                <TextField
                    multiline
                    rows={10}
                    autoFocus
                    fullWidth
                    value={remark}
                    label={
                        remarkType === BOOKING_REMARK_TYPE.COMMENT
                            ? intl.formatMessage(Messages.dispatcherRemark)
                            : intl.formatMessage(Messages.sharedRemark)
                    }
                    onChange={onChange}
                    variant="outlined"
                />
            </Box>

            <DialogActions>
                <Button variant="outlined" onClick={onClose}>
                    {intl.formatMessage(Messages.cancel)}
                </Button>
                <Button
                    variant="contained"
                    onClick={onRemarkSave}
                    disabled={loading}
                    color="success"
                    startIcon={<Save />}
                >
                    {intl.formatMessage(Messages.save)}
                </Button>
            </DialogActions>
        </>
    );
}

/**
 * Renders the sidebar title area for Manage bookings.
 *
 * This component displays the title of the sidebar, along with an action icon button for adding a comment.
 *
 * @component
 * @example
 * ```tsx
 * <BookingSidebarTitle
 *    booking={booking}
 *    reloadBookings={reloadBookings}
 * />
 * ```
 *
 * @param {BookingSidebarTitleProps} props - The component props.
 * @param {() => Promise<void>} props.reloadBookings - A function to reload the bookings.
 *
 * @returns {JSX.Element} The rendered component.
 */
function BookingSidebarTitle({ onFinalizeBooking }: BookingSidebarTitleProps) {
    const [booking] = useSidebarOwner<IBooking>();
    const intl = useIntl();
    const bookingId = booking?.id;
    const { hasRole } = useAuth();
    const isOnCall = hasRole(Roles.ON_CALL_DISPATCHER);

    return (
        <>
            {booking && (
                <>
                    <Box
                        display="flex"
                        gap={1}
                        alignItems="center"
                        justifyContent={"space-between"}
                    >
                        <Box>
                            <span>{`${intl.formatMessage(
                                Messages.respondToBookigRequest,
                            )} #${bookingId} (${booking.shipName})`}</span>
                        </Box>

                        <Box display={"inline-flex"}>
                            {!isOnCall && (
                                <>
                                    {booking.actualArrivalTime &&
                                        booking.actualDepartureTime &&
                                        isBookingCompletable(booking.status) &&
                                        onFinalizeBooking && (
                                            <Box justifyContent={"center"} marginRight={"10px"}>
                                                <FinalizeBookingSection
                                                    onFinalizeComplete={onFinalizeBooking}
                                                    label={false}
                                                />
                                            </Box>
                                        )}
                                    <UploadBookingDocuments />
                                </>
                            )}
                        </Box>
                    </Box>
                </>
            )}
        </>
    );
}

export default BookingSidebarTitle;
