import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import RpisPage from "../RpisPage/RpisPage";
import { useDispatch } from "react-redux";
import Messages from "../../localization/Messages";
import { Eventcalendar, MbscCalendarEvent, MbscResource, locale } from "@mobiscroll/react";
import "@mobiscroll/react/css/mobiscroll.react.min.css";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { getAllocationPlan } from "../../api/booking";
import Progress from "../Progress/Progress";
import "./DockAllocationPlan.css";
import { useParams } from "react-router-dom";
import { Roles, getLocaleString } from "../../models/userData";
import EventCalendarFilters from "./components/EventCalendarFilters/EventCalendarFilters";
import { DockAllocationPlanFilters } from "./DockAllocationPlan";
import { Serializers, useUrlState } from "../../hooks/useUrlState";
import { Box, Link, Typography } from "@mui/material";
import {
    DateFormat,
    addDays,
    getDateMoment,
    getDateRangeText,
    getDateText,
    getEventCalendarViewProps,
} from "../../helpers/dateHelper";
import SidebarLayout from "../SidebarLayout/SidebarLayout";
import StopForm from "../LDManageVoyages/StopForm";
import Chat from "../../components/Chat/Chat";
import * as mbsc from "@mobiscroll/react";
import { searchStops } from "../../api/stop";
import { searchParamsInit } from "../../models/searchParams";
import { IStop } from "../../models/stop";
import { getDockGoogleMapsLink } from "../../models/dock";
import { useAuth } from "../../hooks/useAuth";
import { useTooltipContext } from "../../hooks/useTooltipContext";

export type DockAllocationViewProps = {
    hideRightSidebar?: boolean;
};

/**
 * Dock Allocation View component represents the Public dock allocation plan page accessible to Locks and Docks and public users.
 * This component displays a calendar view of bookings and their allocated docks.
 *
 * @component
 * @example
 * return (
 *   <DockAllocationView hideRightSidebar={true} />
 * );
 */
export const DockAllocationView = ({ hideRightSidebar = false }: DockAllocationViewProps) => {
    const tooltipContext = useTooltipContext();
    const intl = useIntl();
    const dispatch = useDispatch();
    const { user } = useAuth();
    const { destinationId: destId } = useParams();
    const destinationId = destId ?? "";

    const [loading, setLoading] = useState<boolean | undefined>(undefined);
    const [destinationName, setDestinationName] = useState<string>("");
    const [docks, setDocks] = useState<MbscResource[]>([]);
    const [bookingEvents, setBookingEvents] = useState<MbscCalendarEvent[]>([]);
    const [isPlus] = useUrlState("plus", false, Serializers.boolean);
    const [filters, setFilters] = useState<DockAllocationPlanFilters>({
        rangeVal: [
            new Date(),
            addDays(Date.now(), user.roles.includes(Roles.LOCKS_AND_DOCKS_DISPATCHER) ? 40 : 14),
        ],
        currentDate: getDateMoment().toDate(),
        resolution: 2,
    });

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkMobile = () => {
            const maxDimension = 768; // Define mobile height
            setIsMobile(window.innerHeight < maxDimension || window.innerWidth < maxDimension);
        };

        // Check on mount and window resize
        checkMobile();
        window.addEventListener("resize", checkMobile);

        // Cleanup
        return () => window.removeEventListener("resize", checkMobile);
    }, []);

    const fetchData = useCallback(async () => {
        try {
            if (loading === undefined) setLoading(true);

            const from = isPlus ? filters.rangeVal[0].getTime() : 0;
            const to = isPlus ? filters.rangeVal[1].getTime() : 0;
            const result = await getAllocationPlan({ from, destinationId, to }, user);

            const events: MbscCalendarEvent[] = result.berthings.flatMap(berthing => {
                let cssClass = "";
                let eventTitle = berthing.name;
                let eventColor = `var(--event-blue)`;
                let eventTooltip = `${berthing.name}, ${getDateText(
                    berthing.arrivalTime,
                    DateFormat.CLIENT_DATE_TIME,
                )} - ${getDateText(berthing.departureTime, DateFormat.CLIENT_DATE_TIME)}`;
                if (berthing.internal) {
                    cssClass = "event-internal";
                    eventTitle = `${intl.formatMessage(Messages.blockedBy)} ${berthing.name}`;
                    eventColor = `var(--stripe-pattern)`;
                    eventTooltip = `${intl.formatMessage(Messages.blockedBy)} ${
                        berthing.name
                    }, ${getDateText(
                        berthing.arrivalTime,
                        DateFormat.CLIENT_DATE_TIME,
                    )} - ${getDateText(berthing.departureTime, DateFormat.CLIENT_DATE_TIME)}`;
                }

                return {
                    start: getDateText(berthing.arrivalTime, DateFormat.API_DATE_TIME),
                    end: getDateText(berthing.departureTime, DateFormat.API_DATE_TIME),
                    range: getDateRangeText(
                        berthing.arrivalTime,
                        berthing.departureTime,
                        DateFormat.CLIENT_DATE_TIME,
                        true,
                    ),
                    title: eventTitle,
                    resource: berthing.dockId,
                    bookingId: berthing.bookingId,
                    color: eventColor,
                    tooltip: eventTooltip,
                    cssClass,
                };
            });

            setDestinationName(result.destinationName);
            setDocks(result.docks.slice().sort((a, b) => a.ordnum - b.ordnum));
            setBookingEvents([...events]);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.data));
        } finally {
            setLoading(false);
        }
    }, [dispatch, intl, destinationId, isPlus, filters, loading, user]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const calView = getEventCalendarViewProps(
        filters.rangeVal[0],
        filters.rangeVal[1],
        filters.resolution,
        false,
    );

    const resourceTemplate = (item: MbscResource) => {
        const { name, area } = item;
        const lat: number | undefined = item.lat;
        const lng: number | undefined = item.lng;
        const hasCoordinates = lat && lng && typeof lat === "number" && typeof lng === "number";
        return (
            <>
                {hasCoordinates ? (
                    <Link target="_blank" href={getDockGoogleMapsLink(lat, lng)}>
                        <div className="row-label">
                            <p>{name}</p>
                            <p>{`(${area})`}</p>
                        </div>
                    </Link>
                ) : (
                    <div className="row-label">
                        <p>{name}</p>
                        <p>{`(${area})`}</p>
                    </div>
                )}
            </>
        );
    };

    const [selectedBookingId, setSelectedBookingId] = useState<number | undefined>();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedStop, setSelectedStop] = useState<IStop | undefined>();

    const canUserOpenSidebar = (event: mbsc.MbscEventClickEvent): boolean => {
        return event.event.bookingId != null;
    };

    const onEventClick = async (event: mbsc.MbscEventClickEvent) => {
        const domEvent: any = event.domEvent;
        const tooltipTitle = event.event.tooltip as string;
        const { top, height } = domEvent.currentTarget.getBoundingClientRect();
        const posY = top + height + 8;
        if (isMobile) {
            tooltipContext.show(tooltipTitle, `${domEvent.clientX}px`, `${posY}px`);
        }
        if (!canUserOpenSidebar(event)) return;
        const bookingId: number = event.event.bookingId;
        setSelectedBookingId(bookingId);
        setSidebarOpen(true);
        const res = await searchStops({
            ...searchParamsInit,
            filter: { bookingId: bookingId },
            order: ["eta asc"],
        });
        const newSelectedStop: IStop = res.data.rows[0];
        setSelectedStop(newSelectedStop);
    };

    const sidebarContent = (
        <Box flexBasis="100%" display="flex" flexDirection="column" gap={2}>
            <StopForm selectedStop={selectedStop} hideViewDockButton />
            <Box display="flex" flexDirection="column" flex={1}>
                <Chat bookingId={selectedBookingId} />
            </Box>
        </Box>
    );

    return (
        <RpisPage
            title={`${intl.formatMessage(Messages.fgks)} ${intl.formatMessage(
                Messages.dockAllocationPlan,
            )}${destinationName ? " - " + destinationName : ""}`}
            className="dock-allocation-plan-container"
        >
            {loading ? (
                <Progress />
            ) : (
                <SidebarLayout
                    hideRightSidebar={hideRightSidebar}
                    open={sidebarOpen}
                    onClose={() => setSidebarOpen(false)}
                    sidebarContent={sidebarContent}
                    sidebarTitle={intl.formatMessage(Messages.editBerthing)}
                >
                    <div className="title-and-actions-container"></div>
                    <Eventcalendar
                        refDate={filters.rangeVal[0]}
                        selectedDate={filters.currentDate}
                        locale={locale[getLocaleString(user.locale)]}
                        dateFormat={DateFormat.CLIENT_DATE}
                        timeFormat={DateFormat.API_TIME}
                        theme="material"
                        themeVariant="light"
                        view={calView}
                        data={bookingEvents}
                        resources={docks}
                        showControls={!isMobile}
                        renderHeader={() => (
                            <>
                                {isPlus ? (
                                    <EventCalendarFilters
                                        dialogTitle={intl.formatMessage(Messages.filters)}
                                        value={filters}
                                        onChange={setFilters}
                                    />
                                ) : (
                                    <Box display="flex" alignItems="center" gap={1.25} marginX={1}>
                                        <Typography variant="h6">
                                            {getDateRangeText(
                                                filters.rangeVal[0],
                                                filters.rangeVal[1],
                                            )}
                                        </Typography>
                                    </Box>
                                )}
                            </>
                        )}
                        cssClass="md-event-listing"
                        dragTimeStep={60}
                        renderResource={resourceTemplate}
                        onEventClick={onEventClick}
                        renderScheduleEventContent={({ title }) => (
                            <div translate="no">{title}</div>
                        )}
                    />
                </SidebarLayout>
            )}
        </RpisPage>
    );
};

export default DockAllocationView;
