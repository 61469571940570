export interface INotificationSettingsDispatcher {
    notifyTopics: Array<DispatcherNotifications>;
    smsTopics: Array<DispatcherNotifications>;
    emailTopics: Array<DispatcherNotifications>;
}

export interface INotificationSettingsDocks {
    notifyTopics: Array<DocksNotifications>;
    smsTopics: Array<DocksNotifications>;
    emailTopics: Array<DocksNotifications>;
}

export enum DispatcherNotifications {
    bookingRequested = "booking_requested",
    booking_change_requested = "booking_change_requested",
    booking_canceled = "booking_canceled",
    chatText = "chat_text",
    bookingShipNew = "booking_ship_new",
    bookingShipUpdate = "booking_ship_update",
    bookingShipDuplicateEni = "booking_ship_duplicate_eni",
    shipCertExpired = "ship_cert_expired",
    addressInUseChanged = "address_in_use_changed",
    voyageDataUpdated = "voyage_data_updated",
    bookingChangeConfirmed = "booking_change_confirmed",
    bookingConsumptionNew = "booking_consumption_new",
    bookingCommentChanged = "booking_remark_changed",
}

export enum DocksNotifications {
    bookingConfirmed = "booking_confirmed",
    bookingChanged = "booking_changed",
    bookingDeclined = "booking_declined",
    chatText = "chat_text",
    shipCertExpired = "ship_cert_expired",
    bookingPrecheck = "booking_precheck",
    expensesInvoiced = "expenses_invoiced",
    bookingConsumptionNew = "booking_consumption_new",
}

export interface INotification {
    id: string;
    bookingId?: number;
    companyId: string;
    createBy?: string;
    data: INotificationData | null;
    deleted: number;
    interna: boolean;
    read: boolean;
    type: DispatcherNotifications | DocksNotifications;
    userId: string;
    createTs: number;
    voyageId?: number;
    stopId?: number;
}

export interface INotificationData {
    bookingId?: number;
    direction?: string;
    eta?: number;
    etd?: number;
    managingCompany?: string;
    sender?: string;
    senderName?: string;
    shipName?: string;
    shipId?: string;
    eni: number;
    text: string;
    type: string;
    stopId?: number;
    voyageId?: number;
    address?: string;
    companyName?: string;
    addressId?: string;
    managingCompanyId?: string;
    dateOfBooking?: number;
    ATD?: number;
    ATA?: number;
    departureTime?: number;
    arrivalTime?: number;
    internalInvoiceNumber?: string;
    invoiceDate?: number;
    invoiceId?: string;
}

export interface INotificationLink {
    title?: string;
    ref: string;
}

export interface INotificationContent {
    title: string;
    link: INotificationLink;
}
