import Messages from "../localization/Messages";
import { IntlShape } from "react-intl";
import {
    DocksNotifications,
    INotification,
    INotificationData,
    INotificationLink,
} from "../models/notification";
import { DateFormat, getDateText } from "./dateHelper";

/**
 * Returns an array of notification titles for the Locks & Docks user.
 * @param intl - The internationalization object.
 * @param notification - The notification object.
 * @returns An array of notification titles.
 */
export const getNotificationTitlesDocks = (
    intl: IntlShape,
    notification: INotification,
): string[] => {
    switch (notification.type) {
        case DocksNotifications.bookingConfirmed:
            return [getBookingConfirmedNotification(intl, notification)];

        case DocksNotifications.bookingChanged:
            return [getBookingChangedNotification(intl, notification)];

        case DocksNotifications.bookingDeclined:
            return [getBookingDeclinedNotification(intl, notification)];

        case DocksNotifications.chatText:
            return [getChatMessageNotification(intl, notification)];

        case DocksNotifications.shipCertExpired:
            return [getShipCertificateExpiredNotification(intl, notification)];

        case DocksNotifications.bookingPrecheck:
            return [getBookingPrecheckNotification(intl, notification)];

        case DocksNotifications.expensesInvoiced:
            return getExpenseInvoicedNotification(intl, notification);

        default:
            return ["You have a new notification"];
    }
};

/**
 * Returns an array of notification links for the Locks & Docks.
 * @param intl - The internationalization object.
 * @param notification - The notification object.
 * @returns An array of notification links.
 */
export const getNotificationLinksDocks = (
    intl: IntlShape,
    notification: INotification,
): INotificationLink[] => {
    switch (notification.type) {
        case DocksNotifications.bookingConfirmed:
        case DocksNotifications.bookingChanged:
        case DocksNotifications.bookingDeclined:
        case DocksNotifications.chatText:
            return [
                {
                    title: intl.formatMessage(Messages.manageVoyages),
                    ref: `plan-voyage/${notification.data?.voyageId}?selectedStopId=${
                        notification.data?.stopId ?? ""
                    }`,
                },
            ];

        case DocksNotifications.shipCertExpired:
        case DocksNotifications.bookingPrecheck:
            return [
                {
                    title: intl.formatMessage(Messages.manageFleet),
                    ref: `manage-fleet?selectedShipId=${notification.data?.shipId ?? ""}`,
                },
            ];
        case DocksNotifications.expensesInvoiced:
            return [
                {
                    title: "",
                    ref: `manage-expenses?selectedInvoiceId=${notification.data?.invoiceId ?? ""}`,
                },
                {
                    title: intl.formatMessage(Messages.manageExpenses),
                    ref: `manage-expenses?selectedInvoiceId=${notification.data?.invoiceId ?? ""}`,
                },
            ];
        default:
            return [{ ref: "", title: "" }];
    }
};

/**
 * Formats the date and time.
 * @param date - The date to format.
 * @returns The formatted date and time.
 */
const formatDateTime = (date?: number) => {
    return getDateText(date, DateFormat.CLIENT_DATE_TIME);
};

/**
 * Returns the booking details.
 * @param data - The notification data.
 * @returns The booking details.
 */
const getBookingDetails = (data: INotificationData | null) => {
    if (!data) return "";
    return `${data.shipName ?? ""}, ${formatDateTime(data.eta)} - ${formatDateTime(data.etd)}`;
};

/**
 * Returns the booking range.
 * @param data - The notification data.
 * @returns The booking range.
 */
const getBookingRange = (data: INotificationData | null) => {
    if (!data) return "";

    if (!(data.ATA && data.ATD)) {
        return `${formatDateTime(data.arrivalTime)} - ${formatDateTime(data.departureTime)}`;
    }

    return `${formatDateTime(data.ATA)} - ${formatDateTime(data.ATD)}`;
};

/**
 * Returns the chat message notification.
 * @param intl - The internationalization object.
 * @param notification - The notification object.
 * @returns The chat message notification.
 */
const getChatMessageNotification = (intl: IntlShape, notification: INotification) => {
    return `${intl.formatMessage(Messages.newChatMessageForBooking)} #${
        notification.bookingId ?? ""
    } ${getBookingDetails(notification.data)}`;
};

/**
 * Returns the booking confirmed notification.
 * @param intl - The internationalization object.
 * @param notification - The notification object.
 * @returns The booking confirmed notification.
 */
const getBookingConfirmedNotification = (intl: IntlShape, notification: INotification) => {
    return `${intl.formatMessage(Messages.booking)} #${
        notification.bookingId ?? ""
    } ${intl.formatMessage(Messages.notificationConfirmed)}: ${getBookingDetails(
        notification.data,
    )}`;
};

/**
 * Returns the booking changed notification.
 * @param intl - The internationalization object.
 * @param notification - The notification object.
 * @returns The booking changed notification.
 */
const getBookingChangedNotification = (intl: IntlShape, notification: INotification) => {
    return `${intl.formatMessage(Messages.booking)} #${
        notification.bookingId ?? ""
    } ${intl.formatMessage(Messages.notificationChanged)}: ${getBookingDetails(notification.data)}`;
};

/**
 * Returns the booking declined notification.
 * @param intl - The internationalization object.
 * @param notification - The notification object.
 * @returns The booking declined notification.
 */
const getBookingDeclinedNotification = (intl: IntlShape, notification: INotification) => {
    return `${intl.formatMessage(Messages.booking)} #${
        notification.bookingId ?? ""
    } ${intl.formatMessage(Messages.notificationDeclined)}: ${getBookingDetails(
        notification.data,
    )}`;
};

/**
 * Returns the ship certificate expired notification.
 * @param intl - The internationalization object.
 * @param notification - The notification object.
 * @returns The ship certificate expired notification.
 */
const getShipCertificateExpiredNotification = (intl: IntlShape, notification: INotification) => {
    return `${intl.formatMessage(Messages.notificationShipCertExpired1)} ${
        notification.data?.shipName ?? ""
    } ${intl.formatMessage(Messages.notificationShipCertExpired2)}.`;
};

/**
 * Returns the booking precheck notification.
 * @param intl - The internationalization object.
 * @param notification - The notification object.
 * @returns The booking precheck notification.
 */
const getBookingPrecheckNotification = (intl: IntlShape, notification: INotification) => {
    return `${intl.formatMessage(Messages.notificationBookingPrecheck1)} ${
        notification.data?.shipName ?? ""
    } ${intl.formatMessage(Messages.notificationBookingPrecheck2)}`;
};

/**
 * Returns the expense invoiced notification.
 * @param intl - The internationalization object.
 * @param notification - The notification object.
 * @returns The expense invoiced notification.
 */
const getExpenseInvoicedNotification = (intl: IntlShape, notification: INotification) => {
    return [
        `${intl.formatMessage(Messages.expenseInvoicedNotificationMessage)}: ${
            notification.data?.internalInvoiceNumber ?? ""
        }, ${getBookingRange(notification.data)}, ${formatDateTime(
            notification.data?.invoiceDate,
        )} ${notification.data?.shipName ?? ""}`,
        `${intl.formatMessage(Messages.expenseInvoiceNotificationmessage2)}:`,
    ];
};
