import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { IInvoice, InvoiceStatus } from "../../models/invoice";
import { IDockExpense, IExpense } from "../../models/expense";
import { useDispatch } from "react-redux";
import { IShipDispatcher } from "../../models/shipDispatcher";
import { searchExpense as searchExpenseDisp } from "../../api/expense";
import { searchExpense as searchExpenseDocks } from "../../api/dockExpense";
import { Roles } from "../../models/userData";
import { searchParamsInit } from "../../models/searchParams";
import { showBackendMessage } from "../../helpers/messagesHelper";
import Messages from "../../localization/Messages";
import InvoiceDataContainer from "./components/InvoiceDataContainer/InvoiceDataContainer";
import { IShip } from "../../models/ship";
import { getShip, getShipDisp } from "../../api/ship";
import { Typography } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import { IBooking } from "../../models/booking";
import { getBooking } from "../../api/booking";

type ViewInvoiceProps = {
    selectedInvoice: IInvoice;
    showDisclaimerMessage?: boolean;
};

/**
 * Component that displays the data of an Invoice and lists all expenses linked to that invoice in a table.
 *
 * Disclaimer message is shown in case the invoice is not yet invoiced and serves as a preview of expenses for the Locks & Docks user.
 *
 * @component
 * @example
 * // Usage
 * const selectedInvoice = {selectedInvoice};
 * const showDisclaimerMessage = true;
 *
 * return (
 *   <ViewInvoice selectedInvoice={selectedInvoice} showDisclaimerMessage={showDisclaimerMessage} />
 * )
 */

export const ViewInvoice = ({ selectedInvoice, showDisclaimerMessage }: ViewInvoiceProps) => {
    const [loading, setLoading] = useState(true);
    const intl = useIntl();
    const dispatch = useDispatch();
    const [expenses, setExpenses] = useState<IExpense[] | IDockExpense[]>([]);
    const [booking, setBooking] = useState<IBooking>();
    const [ship, setShip] = useState<IShipDispatcher | IShip>();
    const { user } = useAuth();

    useEffect(() => {
        const fetchExpenses = async () => {
            try {
                setLoading(true);
                if (user.roles.includes(Roles.PASSENGER_CABIN_CRUISES_DISPATCHER)) {
                    const apiResponse = await searchExpenseDisp({
                        ...searchParamsInit,
                        filter: { $and: [{ invoiceId: selectedInvoice.id }] },
                    });
                    const expenses = apiResponse.data.rows ?? [];
                    setExpenses(expenses.sort((a: IExpense, b: IExpense) => a.ordnum - b.ordnum));
                } else if (user.roles.includes(Roles.LOCKS_AND_DOCKS_DISPATCHER)) {
                    const apiResponse = await searchExpenseDocks({
                        ...searchParamsInit,
                        filter: { $and: [{ invoiceId: selectedInvoice.id }] },
                    });
                    const expenses = apiResponse.data.rows ?? [];
                    setExpenses(expenses);
                }
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.expenses));
            } finally {
                setLoading(false);
            }
        };
        fetchExpenses();
    }, [dispatch, intl, selectedInvoice, user]);

    useEffect(() => {
        const fetchShip = async () => {
            try {
                setLoading(true);
                if (user.roles.includes(Roles.PASSENGER_CABIN_CRUISES_DISPATCHER)) {
                    const apiResponse = await getShipDisp(selectedInvoice.shipId ?? "");
                    setShip(apiResponse.data);
                } else if (user.roles.includes(Roles.LOCKS_AND_DOCKS_DISPATCHER)) {
                    const apiResponse = await getShip(selectedInvoice.shipId ?? "");
                    setShip(apiResponse.data);
                }
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.ship));
            } finally {
                setLoading(false);
            }
        };
        fetchShip();
    }, [dispatch, intl, selectedInvoice, user]);

    useEffect(() => {
        const fetchBooking = async () => {
            try {
                if (
                    !user.roles.includes(Roles.PASSENGER_CABIN_CRUISES_DISPATCHER) ||
                    !selectedInvoice.bookingId
                )
                    return;
                setLoading(true);
                const apiResponse = await getBooking(String(selectedInvoice.bookingId));
                setBooking(apiResponse.data);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.booking));
            } finally {
                setLoading(false);
            }
        };
        fetchBooking();
    }, [dispatch, intl, selectedInvoice.bookingId, user.roles]);

    return (
        <>
            <InvoiceDataContainer
                loading={loading}
                expenses={expenses}
                selectedInvoice={selectedInvoice}
                ship={ship}
                booking={booking}
            />
            {showDisclaimerMessage && selectedInvoice.invoiceStatus !== InvoiceStatus.INVOICED && (
                <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    my={"1.5rem"}
                    fontSize={"13px"}
                >
                    *{intl.formatMessage(Messages.invoicePreviewDisclaimer)}
                </Typography>
            )}
        </>
    );
};

export default ViewInvoice;
