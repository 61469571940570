import { getDateMoment } from "../../../helpers/dateHelper";
import { DatatablePaginationFilters } from "../../../hooks/useGuiConfState";
import { SortOrder } from "../../../hooks/useTableSort";
import { ExpenseStatus } from "../../../models/expense";

export type LDExpenseFiltersType = DatatablePaginationFilters & {
    dateFrom: Date | null;
    dateTo: Date | null;
    internalInvoiceNumber: string;
    shipName: string;
    owningCompany: string;
    name: string;
    order: SortOrder;
};

export function buildExpensesSearchPayload(filters: LDExpenseFiltersType) {
    return {
        textSearch: filters.shipName,
        offset: filters.page * filters.rowsPerPage,
        limit: filters.rowsPerPage,
        order: filters.order,
        columns: {},
        filter: buildExpensesSearchPayloadFilters(filters),
    };
}

function buildExpensesSearchPayloadFilters(filters: LDExpenseFiltersType) {
    const { dateFrom, dateTo, internalInvoiceNumber, owningCompany, name } = filters;
    const filterArray: object[] = [{ status: ExpenseStatus.INVOICED }];

    if (dateFrom && dateTo) {
        const startDate = getDateMoment(dateFrom).toDate();
        const endDate = getDateMoment(dateTo).toDate();
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 59);
        filterArray.push(
            { expenseDate: { $gte: startDate.getTime() } },
            { expenseDate: { $lte: endDate.getTime() } },
        );
    }

    if (dateFrom && !dateTo) {
        const startDate = getDateMoment(dateFrom).toDate();
        startDate.setHours(0, 0, 0, 0);
        filterArray.push({ expenseDate: { $gte: startDate.getTime() } });
    }

    if (!dateFrom && dateTo) {
        const endDate = getDateMoment(dateTo).toDate();
        endDate.setHours(23, 59, 59, 59);
        filterArray.push({ expenseDate: { $lte: endDate.getTime() } });
    }

    if (internalInvoiceNumber) {
        filterArray.push({ internalInvoiceNumber: internalInvoiceNumber });
    }

    if (owningCompany) {
        filterArray.push({ owningCompany: owningCompany });
    }

    if (name) {
        filterArray.push({ name: name });
    }

    return { $and: filterArray };
}
