import { GuiConfsFiltersState, GuiConfKey, GuiConfState } from "../store/guiConfsReducer";

export const FETCH_INITIAL_FILTERS = "FETCH_INITIAL_FILTERS";
export const LOAD_FILTERS = "LOAD_FILTERS";
export const UPDATE_FILTERS = "UPDATE_FILTERS";

export type GuiConfsActionType<TKey extends GuiConfKey> =
    | FetchInitialFiltersAction
    | UpdateFiltersAction<TKey>;

export type FetchInitialFiltersAction = {
    type: typeof FETCH_INITIAL_FILTERS;
    payload: GuiConfState;
};

export interface UpdateFiltersAction<TKey extends GuiConfKey> {
    type: typeof UPDATE_FILTERS;
    guiConfsKey: TKey;
    filters: Partial<GuiConfsFiltersState<TKey>>;
}

export function fetchInitialFiltersAction(payload: GuiConfState): FetchInitialFiltersAction {
    return { type: FETCH_INITIAL_FILTERS, payload };
}

/**
 * Updates the filters for the given GUI configuration key. This action is used to update the filters.
 * @param guiConfsKey
 * @param filters
 * @returns
 */
export function updateFiltersAction<TKey extends GuiConfKey>(
    guiConfsKey: TKey,
    filters: Partial<GuiConfsFiltersState<TKey>>,
): UpdateFiltersAction<TKey> {
    return {
        type: UPDATE_FILTERS,
        guiConfsKey,
        filters,
    };
}
