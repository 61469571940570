import axios, { AxiosResponse } from "axios";
import { IAddress, IDispatcherAddress } from "../models/address";
import { ApiSearchResponse } from "../models/common";
import { ISearchParams, searchParamsInit } from "../models/searchParams";

export const addAddress = async (dto: IAddress): Promise<AxiosResponse<IDispatcherAddress>> => {
    return await axios.put("/dispatcher/addAddress", dto);
};

export const changeAddress = async (dto: IAddress): Promise<AxiosResponse<IDispatcherAddress>> => {
    return await axios.patch("/dispatcher/changeAddress", dto);
};

export const deleteAddress = async (id: string): Promise<AxiosResponse<string>> => {
    return await axios.delete(`/dispatcher/deleteAddress/${id}`);
};

export const getAddress = async (id: string): Promise<AxiosResponse<IDispatcherAddress>> => {
    return await axios.get(`/dispatcher/getAddress/${id}`);
};

export const searchAddress = async (
    searchParams: ISearchParams = searchParamsInit,
): ApiSearchResponse<IDispatcherAddress> => {
    return await axios.post("/dispatcher/searchAddress", searchParams);
};
