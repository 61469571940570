import Chip from "@mui/material/Chip";
import Messages from "../../localization/Messages";
import { MessageDescriptor, useIntl } from "react-intl";
import { BookingStatus } from "../../models/booking";
import { PendingActions, Approval, Pending, Info, CheckBox, Close } from "@mui/icons-material";

export type StatusChipType = {
    label: MessageDescriptor;
    color: "success" | "error" | "warning" | "primary" | "secondary" | "info";
    variant: "filled" | "outlined";
    icon: JSX.Element;
};

const DEFAULT_STATUS_CHIP: StatusChipType = {
    label: Messages.unavailable,
    color: "warning",
    icon: <PendingActions />,
    variant: "outlined",
};

export function getStatusChip(status: string | null | undefined): StatusChipType | null {
    return status ? STATUS_CHIPS[status] : null;
}

export const STATUS_CHIPS: Record<string, StatusChipType> = {
    [BookingStatus.REQUESTED]: {
        label: Messages.requested,
        color: "warning",
        icon: <PendingActions />,
        variant: "outlined",
    },
    [BookingStatus.MULTIPLE]: {
        label: Messages.multiple,
        color: "warning",
        icon: <PendingActions />,
        variant: "outlined",
    },
    [BookingStatus.CHANGE_REQUESTED]: {
        label: Messages.changeRequested,
        color: "warning",
        icon: <Approval />,
        variant: "outlined",
    },
    [BookingStatus.PENDING]: {
        label: Messages.pending,
        color: "primary",
        icon: <Pending />,
        variant: "outlined",
    },
    [BookingStatus.PROVISIONALLY_PLANNED]: {
        label: Messages.provisionallyPlanned,
        color: "success",
        icon: <Info />,
        variant: "outlined",
    },
    [BookingStatus.PROVISIONALLY_DECLINED]: {
        label: Messages.provisionallyDeclined,
        color: "error",
        icon: <Info />,
        variant: "outlined",
    },
    [BookingStatus.CHANGED]: {
        label: Messages.changed,
        color: "success",
        icon: <Info />,
        variant: "filled",
    },
    [BookingStatus.CONFIRMED]: {
        label: Messages.confirmed,
        color: "success",
        icon: <CheckBox />,
        variant: "filled",
    },
    [BookingStatus.DECLINED]: {
        label: Messages.declined,
        color: "error",
        icon: <Close />,
        variant: "filled",
    },
    [BookingStatus.CANCELLED]: {
        label: Messages.canceled,
        color: "error",
        icon: <Close />,
        variant: "outlined",
    },
};

export type StatusChipProps = {
    status: string;
};

/**
 * Renders the status of a Booking or a stop in the form of a MUI Chip component.
 *
 * @component
 * @example
 * ```tsx
 * <StatusChip status={BookingStatus.CONFIRMED} />
 * ```
 *
 * @param {string} status - The status of the Booking or stop.
 * @returns {JSX.Element} - The rendered StatusChip component.
 */

const StatusChip = ({ status }: StatusChipProps) => {
    const intl = useIntl();
    const { label, color, variant, icon } = getStatusChip(status) ?? DEFAULT_STATUS_CHIP;
    return <Chip label={intl.formatMessage(label)} color={color} variant={variant} icon={icon} />;
};

export default StatusChip;
