import Messages from "../localization/Messages";
import { IAddress, addressInit } from "./address";
import { IBooking } from "./booking";

export interface IInvoice {
    id: number;
    addressId: string;
    address: IAddress;
    bookingId?: number;
    companyId: string;
    deleted: number;
    internalInvoiceNumber?: string;
    invoiceDate?: number | null;
    invoiceReferenceField?: string;
    invoiceStatus?: InvoiceStatus;
    invoicedExpenses?: string[];
    shipId?: string;
    totalCostGross: number;
    totalCostNet: number;
    invoiceCompanyName?: string;
    companyName?: string;
    currency?: string;
    booking?: IBooking;
    deletable?: boolean;
    createTs?: number;
    shipName?: string;
    bookingFinalized?: boolean;
}

export type IEditInvoice = Omit<IInvoice, "booking">;

export const invoiceInit: IInvoice = {
    id: 0,
    addressId: "",
    address: addressInit,
    companyId: "",
    deleted: 0,
    totalCostGross: 0,
    totalCostNet: 0,
};

export enum InvoiceStatus {
    OPEN = "OPEN",
    READY_FOR_BILLING = "READY_FOR_BILLING",
    INVOICED = "INVOICED",
    CANCELLED = "CANCELLED",
    DELETED = "DELETED",
    NOT_ASSIGNED = "NOT_ASSIGNED",
}

export function isInvoiceEditable(invoiceStatus?: InvoiceStatus): boolean {
    return (
        !!invoiceStatus &&
        (invoiceStatus === InvoiceStatus.OPEN || invoiceStatus === InvoiceStatus.READY_FOR_BILLING)
    );
}

export const InvoiceStatuses = [
    { id: 0, value: InvoiceStatus.OPEN, label: Messages.open },
    { id: 1, value: InvoiceStatus.READY_FOR_BILLING, label: Messages.readyForBilling },
    { id: 2, value: InvoiceStatus.INVOICED, label: Messages.invoiced },
    { id: 3, value: InvoiceStatus.CANCELLED, label: Messages.canceled },
    { id: 4, value: InvoiceStatus.DELETED, label: Messages.deleted },
    { id: 5, value: InvoiceStatus.NOT_ASSIGNED, label: Messages.notAssigned },
];
