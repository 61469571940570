/**
 * Represents a type that defines an enumeration.
 */
export type EnumType = Record<string, string | number>;

/**
 * Represents an object that holds multiple enumeration types.
 * @typeparam TEnumName - The name of the enumeration type.
 */
export type ObjectEnumHolder<TEnumName extends string> = Record<TEnumName, EnumType>;

/**
 * Represents a select option with a value and label.
 */
export type SelectOption = { value: string; label: string };

/**
 * Converts a string-based enumeration to an array of keys.
 * @param en - The string-based enumeration.
 * @returns An array of keys from the enumeration.
 */
export function stringEnumToArray<TEnum extends EnumType>(en: TEnum): (keyof typeof en)[] {
    return Object.keys(en) as any;
}

/**
 * Converts a numeric-based enumeration to an array of values.
 * @param en - The numeric-based enumeration.
 * @returns An array of values from the enumeration.
 */
export function numericEnumToArray(en: any) {
    return Object.keys(en)
        .filter(x => !isNaN(Number(x)))
        .map(key => en[key]);
}

/**
 * Converts a snake_case string to a display-friendly text.
 * @param text - The snake_case string.
 * @returns The display-friendly text.
 */
export function displayEnumTextFromSnakeCase(text: string) {
    const temp = text.toLowerCase().replaceAll("_", " ");
    return temp.charAt(0).toUpperCase() + temp.slice(1);
}

/**
 * Converts a camelCase string to a display-friendly value.
 * @param input - The camelCase string.
 * @returns The display-friendly value.
 */
export function displayEnumValueFromCamelCase(input: string) {
    return input.replace(/(.)([A-Z])/g, "$1_$2").toUpperCase();
}
