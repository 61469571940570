import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";
import Messages from "../../../../localization/Messages";
import { useIntl } from "react-intl";
import "./OrdNumDialog.css";
import { changeExpenseType } from "../../../../api/expenseType";
import { useState } from "react";
import { IExpenseType } from "../../../../models/expenseType";
import { useDispatch } from "react-redux";
import { showBackendMessage } from "../../../../helpers/messagesHelper";

export type OrdNumDialogProps = {
    open: boolean;
    onClose: () => void;
    expenseType: IExpenseType;
};

/**
 * Represents a dialog component for managing the order number of an expense type.
 *
 * @component
 * @param {OrdNumDialogProps} props - The component props.
 * @param {boolean} props.open - Determines whether the dialog is open or not.
 * @param {() => void} props.onClose - Callback function to close the dialog.
 * @param {IExpenseType} props.expenseType - The expense type object to be edited.
 * @returns {JSX.Element} The rendered OrdNumDialog component.
 */
export function OrdNumDialog({ open, onClose, expenseType: expenseTypeParam }: OrdNumDialogProps) {
    const intl = useIntl();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { periods, ...expenseType } = expenseTypeParam;
    const [ordNum, setOrdNum] = useState(expenseType.ordNum);
    const dispatch = useDispatch();

    const handleSave = async () => {
        try {
            await changeExpenseType({
                ...expenseType,
                ordNum,
            });
            onClose();
            dispatch(showBackendMessage(intl, "success", "updating", Messages.ordnum));
        } catch (err) {
            dispatch(showBackendMessage(intl, "error", "updating", Messages.ordnum));
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{intl.formatMessage(Messages.ordnum)}</DialogTitle>
            <DialogContent>
                <TextField
                    required
                    value={ordNum ?? ""}
                    label={intl.formatMessage(Messages.ordnum)}
                    variant="filled"
                    onChange={e => {
                        const text = e.target.value;
                        const isPositiveInteger =
                            Number.isInteger(Number(text)) && Number(text) >= 0;
                        const ordNum: number | undefined = isPositiveInteger
                            ? Number(text)
                            : undefined;
                        setOrdNum(text === "" ? undefined : ordNum);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {intl.formatMessage(Messages.cancel)}
                </Button>
                <Button variant="contained" color="primary" onClick={handleSave}>
                    {intl.formatMessage(Messages.save)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
