import {
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Box,
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
} from "@mui/material";
import { DatatableProps } from "../Datatable";
import { ExpandMore } from "@mui/icons-material";

/**
 * Renders a mobile-friendly version of the Datatable component.
 *
 * @template T - The type of data in the datatable.
 *
 * @param {DatatableProps<T>} props - The props for the `DatatableMobile` component.
 * @param {T[]} props.items - The array of items to display in the datatable.
 * @param {ColumnDef[]} props.columnDefs - The array of column definitions for the datatable.
 * @param {(row: T) => React.ReactNode} [props.renderActions] - Optional. A function that renders custom actions for each row.
 * @param {string} [props.className] - Optional. Additional CSS class name(s) for the datatable container.
 * @param {(row: T) => string | number} [props.idMapper] - Optional. A function that mPaps a unique identifier for each row.
 * @param {() => React.ReactNode} [props.renderPagination] - Optional. A function that renders pagination controls.
 * @param {(row: T) => React.ReactNode} [props.titleMapper] - Optional. A function that maps the title for each row.
 *
 * @returns {React.ReactNode} The rendered `DatatableMobile` component.
 */
export function DatatableMobile<const T>({
    items,
    columnDefs,
    renderActions,
    className = "",
    idMapper,
    renderPagination,
    titleMapper,
    size = "small",
}: DatatableProps<T>) {
    return (
        <>
            <Box className={`${className} datatable-content`}>
                {items.map((row, index) => (
                    <Accordion key={idMapper?.(row) ?? index} disableGutters defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            {titleMapper(row)}
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer>
                                <Table size={size}>
                                    <TableBody>
                                        {columnDefs.map(columnDef => (
                                            <TableRow key={columnDef.key}>
                                                <TableCell>{columnDef.label}</TableCell>
                                                <TableCell>
                                                    {columnDef.content(row, index)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                        {renderActions && <AccordionActions>{renderActions(row)}</AccordionActions>}
                    </Accordion>
                ))}
            </Box>
            {renderPagination && <>{renderPagination()}</>}
        </>
    );
}
