import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { useState, useCallback, useEffect } from "react";
import { IBooking } from "../models/booking";
import { searchBookings } from "../api/booking";
import { ISearchParams, searchParamsInit } from "../models/searchParams";
import { showBackendMessage } from "../helpers/messagesHelper";
import Messages from "../localization/Messages";
import { useSidebarOwner } from "../contexts/SidebarOwnerContext";

/**
 * Custom hook for fetching bookings.
 * @param searchParams - The search parameters.
 * @returns An object containing the loading state, bookings, total bookings, and the fetchBookings function.
 */
export function useFetchBookings(searchParams?: Partial<ISearchParams>) {
    const dispatch = useDispatch();
    const [, setSelectedBooking] = useSidebarOwner<IBooking>();
    const intl = useIntl();
    const searchParamsString = JSON.stringify(searchParams);
    const [loading, setLoading] = useState(false);
    const [bookings, setBookings] = useState<IBooking[]>([]);
    const [totalBookings, setTotalBookings] = useState(0);

    const fetchBookings = useCallback(
        async (bookingId?: number) => {
            try {
                setLoading(true);
                const res = await searchBookings({
                    ...searchParamsInit,
                    ...JSON.parse(searchParamsString),
                });
                if (bookingId) {
                    setSelectedBooking(res.data.rows.find(booking => booking.id === bookingId));
                }
                setBookings(res.data.rows ?? []);
                setTotalBookings(res.data.total);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.bookings));
            } finally {
                setLoading(false);
            }
        },
        [dispatch, intl, searchParamsString, setSelectedBooking],
    );

    useEffect(() => {
        fetchBookings();
    }, [fetchBookings]);

    return { loading, bookings, totalBookings, fetchBookings } as const;
}
