import { useDispatch } from "react-redux";
import { ISearchParams, searchParamsInit } from "../models/searchParams";
import { useIntl } from "react-intl";
import { useCallback, useEffect, useState } from "react";
import { IService } from "../models/service";
import { searchService } from "../api/service";
import { showBackendMessage } from "../helpers/messagesHelper";
import Messages from "../localization/Messages";

/**
 * Custom hook for fetching services.
 * @param searchParams - The search parameters.
 * @returns An object containing the loading state, services, total services, and the setServices function.
 */
export function useFetchServices(searchParams?: Partial<ISearchParams>) {
    const dispatch = useDispatch();
    const intl = useIntl();
    const searchParamsString = JSON.stringify(searchParams);
    const [loading, setLoading] = useState(false);
    const [services, setServices] = useState<IService[]>([]);
    const [totalServices, setTotalServices] = useState(0);

    const fetchServices = useCallback(async () => {
        try {
            setLoading(true);
            const res = await searchService({
                ...searchParamsInit,
                ...JSON.parse(searchParamsString),
            });
            setServices(res.data.rows ?? []);
            setTotalServices(res.data.total);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.bookings));
        } finally {
            setLoading(false);
        }
    }, [dispatch, intl, searchParamsString]);

    useEffect(() => {
        fetchServices();
    }, [fetchServices]);

    return { loading, services, totalServices, setServices, fetchServices } as const;
}
