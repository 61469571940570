import { Reducer } from "redux";
import { IDock } from "../models/dock";
import { DocksActionType, SET_DOCKS } from "../actions/docksAction";

/**
 * Represents the state of the docks in the application.
 */
export interface DocksState {
    data: Map<string, IDock>;
}

/**
 * The initial state of the docks.
 */
export const initialState: DocksState = { data: new Map() };

/**
 * Sets the docks in the state.
 * @param payload - The array of docks to set.
 * @returns The updated state with the docks set.
 */
const setDocks = (payload: IDock[]) => {
    const data = new Map();
    payload.forEach(v => data.set(v.id, v));
    return { data } as DocksState;
};

/**
 * The reducer function for the docks state.
 * @param state - The current state of the docks.
 * @param action - The action to be performed on the docks state.
 * @returns The updated state after applying the action.
 */
export const docksReducer: Reducer<DocksState, DocksActionType> = (
    state: DocksState = initialState,
    action: DocksActionType,
): DocksState => {
    switch (action.type) {
        case SET_DOCKS:
            return { ...setDocks(action.payload) };

        default:
            return state;
    }
};
