import { ButtonGroup, Button, Menu, MenuItem } from "@mui/material";
import Messages from "../../../../localization/Messages";
import { isBookingStatusPending, isBookingStatusProvisional } from "../../shared/utils";
import { useIntl } from "react-intl";
import { useCallback, useRef, useState } from "react";
import { ArrowDropDown } from "@mui/icons-material";
import { IBooking } from "../../../../models/booking";

export type BookingMenuButtonGroupProps = {
    row: IBooking;
    onClick: () => void;
    menuActions: {
        handleShowInPlan: (row: IBooking) => void;
        handleBookingViewDockAccess: (row: IBooking) => void;
        handleBookingViewShip: (row: IBooking) => void;
        handlePendingBooking: (row: IBooking) => void;
        handleSendBookingResponses: (bookingId: number) => void;
        handleEditInvoice: (bookingId: number) => void;
        handleBookingViewAddresses: (row: IBooking) => void;
        handleViewInvoice: (row: IBooking) => void;
    };
};

/**
 * Represents the button menu group for the Manage bookings table row,
 * where the user can select various actions associated with bookings.
 *
 * @component
 * @param {BookingMenuButtonGroupProps} props - The component props.
 * @param {() => void} props.onClick - The callback function to handle the click event on the main button.
 * @param {IBooking} props.row - The booking object associated with the row.
 * @param {Object} props.menuActions - The object containing various menu action handlers.
 * @param {(row: IBooking) => void} props.menuActions.handleShowInPlan - The handler for showing the booking in the plan.
 * @param {(row: IBooking) => void} props.menuActions.handleBookingViewDockAccess - The handler for viewing the dock access of the booking.
 * @param {(row: IBooking) => void} props.menuActions.handleBookingViewShip - The handler for viewing the ship of the booking.
 * @param {(row: IBooking) => void} props.menuActions.handlePendingBooking - The handler for setting/unsetting the booking as pending.
 * @param {(bookingId: number) => void} props.menuActions.handleSendBookingResponses - The handler for sending booking responses.
 * @param {(bookingId: number) => void} props.menuActions.handleEditInvoice - The handler for editing the invoice.
 * @param {(row: IBooking) => void} props.menuActions.handleBookingViewAddresses - The handler for viewing the addresses of the booking.
 * @param {(row: IBooking) => void} props.menuActions.handleViewInvoice - The handler for viewing the invoice.
 * @returns {JSX.Element} The rendered BookingMenuButtonGroup component.
 */
function BookingMenuButtonGroup({ onClick, row, menuActions }: BookingMenuButtonGroupProps) {
    const intl = useIntl();
    const [open, setOpen] = useState(false);
    const menuButtonRef = useRef<HTMLButtonElement>(null);

    const handleToggle = useCallback(() => {
        setOpen(prev => !prev);
    }, []);

    return (
        <>
            <ButtonGroup variant="contained">
                <Button onClick={onClick}>{intl.formatMessage(Messages.details)}</Button>

                <Button size="small" ref={menuButtonRef} onClick={handleToggle}>
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>

            <Menu anchorEl={menuButtonRef?.current} open={open} onClose={handleToggle}>
                <MenuItem onClick={() => menuActions.handleShowInPlan(row)}>
                    {intl.formatMessage(Messages.showInPlan)}
                </MenuItem>

                <MenuItem onClick={() => menuActions.handleBookingViewDockAccess(row)}>
                    {intl.formatMessage(Messages.viewDockAccess)}
                </MenuItem>

                <MenuItem onClick={() => menuActions.handleBookingViewShip(row)}>
                    {intl.formatMessage(Messages.viewShip)}
                </MenuItem>

                <MenuItem
                    disabled={!isBookingStatusPending(row.status)}
                    onClick={() => menuActions.handlePendingBooking(row)}
                >
                    {intl.formatMessage(Messages.setUnsetPending)}
                </MenuItem>

                <MenuItem onClick={() => menuActions.handleBookingViewAddresses(row)}>
                    {intl.formatMessage(Messages.viewAddresses)}{" "}
                </MenuItem>

                <MenuItem
                    onClick={() => menuActions.handleSendBookingResponses(row.id)}
                    disabled={!isBookingStatusProvisional(row.status)}
                >
                    {intl.formatMessage(Messages.sendBookingResponse)}
                </MenuItem>

                <MenuItem
                    disabled={!row.invoices?.length}
                    onClick={() => {
                        menuActions.handleEditInvoice(row.id);
                    }}
                >
                    {intl.formatMessage(Messages.editInvoice)}
                </MenuItem>
                <MenuItem
                    disabled={!row.invoices?.length}
                    onClick={() => menuActions.handleViewInvoice(row)}
                >
                    {intl.formatMessage(Messages.viewInvoice)}
                </MenuItem>
            </Menu>
        </>
    );
}

export default BookingMenuButtonGroup;
