import { ReactNode } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { Box } from "@mui/material";
import "./index.css";
import Messages from "../../localization/Messages";
import { useIntl } from "react-intl";

export type DragMoveComponentProps<T> = {
    title: ReactNode;
    items: T[];
    onChange: (items: T[]) => void;
    rowMapper: (item: T, index: number) => ReactNode;
    keyMapper: (item: T) => string;
    disabled?: boolean;
};

/**
 * Renders a list of items in rows and allows rearranging them by drag and drop.
 * It utilizes the `react-beautiful-dnd` npm package.
 *
 * @template T - The type of items in the list.
 *
 * @param {DragMoveComponentProps<T>} props - The component props.
 * @param {ReactNode} props.title - The title of the component.
 * @param {T[]} props.items - The list of items to render.
 * @param {(items: T[]) => void} props.onChange - The callback function triggered when the order of items changes.
 * @param {(item: T, index: number) => ReactNode} props.rowMapper - The function that maps each item to a ReactNode to render in a row.
 * @param {(item: T) => string} props.keyMapper - The function that maps each item to a unique key.
 * @param {boolean} [props.disabled=false] - Whether the drag and drop functionality is disabled.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * ```tsx
 * const items = [
 *   { id: 1, name: "Item 1" },
 *   { id: 2, name: "Item 2" },
 *   { id: 3, name: "Item 3" },
 * ];
 *
 * const handleChange = (newItems) => {
 *   console.log(newItems);
 * };
 *
 * const rowMapper = (item, index) => {
 *   return <div>{item.name}</div>;
 * };
 *
 * const keyMapper = (item) => {
 *   return item.id.toString();
 * };
 *
 * <DragMoveComponent
 *   title="Drag and Drop List"
 *   items={items}
 *   onChange={handleChange}
 *   rowMapper={rowMapper}
 *   keyMapper={keyMapper}
 * />
 * ```
 */

export default function DragMoveComponent<T>({
    title,
    items,
    rowMapper,
    keyMapper,
    onChange,
    disabled = false,
}: DragMoveComponentProps<T>) {
    const intl = useIntl();

    const handleDrop = (droppedItem: DropResult) => {
        if (!droppedItem.destination) return;
        const updatedList = [...items];
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        onChange(updatedList);
    };

    return (
        <Box component="fieldset" className="fieldsetBox">
            <legend>{title}</legend>
            <div className="list-container-outer">
                <DragDropContext onDragEnd={handleDrop}>
                    <Droppable droppableId="list-container">
                        {provided => (
                            <div
                                className="list-container"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {items.length > 0 ? (
                                    items.map((item, index) =>
                                        disabled ? (
                                            <div key={keyMapper(item)} className="item-container">
                                                {rowMapper(item, index)}
                                            </div>
                                        ) : (
                                            <Draggable
                                                key={keyMapper(item)}
                                                draggableId={keyMapper(item)}
                                                index={index}
                                            >
                                                {provided => (
                                                    <div
                                                        className="item-container"
                                                        ref={provided.innerRef}
                                                        {...provided.dragHandleProps}
                                                        {...provided.draggableProps}
                                                    >
                                                        {rowMapper(item, index)}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ),
                                    )
                                ) : (
                                    <div className="item-container">
                                        {intl.formatMessage(Messages.noData)}
                                    </div>
                                )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </Box>
    );
}
