import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Messages from "../../localization/Messages";
import RpisDialog from "../RpisDialog/RpisDialog";
import RpisPage from "../RpisPage/RpisPage";
import ShipDataContainer from "../ShipDataContainer/ShipDataContainer";
import SidebarLayout from "../SidebarLayout/SidebarLayout";
import ViewDockAccessData from "./components/ViewDockAccessData";
import BookingRespondForm from "./components/BookingRespondForm";
import TableCellSorted from "../../components/TableCellSorted";
import useFetchOwningCompanies from "./hooks/useFetchOwningCompanies";
import useFetchShips from "./hooks/useFetchShips";
import useBatchToggleControl from "./hooks/useBatchToggleControl";
import "./ManageBookings.css";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBooking, pendingBooking, searchBookings, sendBookingResponse } from "../../api/booking";
import { Button, ButtonGroup, TableRow, Tooltip } from "@mui/material";
import { TableSkeleton } from "../../components/TableSkeleton/TableSkeleton";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { BookingStatus, IBooking } from "../../models/booking";
import { shipDispatcherInit } from "../../models/shipDispatcher";
import { useUrlState, Serializers } from "../../hooks/useUrlState";
import { useGuiConfState } from "../../hooks/useGuiConfState";
import { useTableSort } from "../../hooks/useTableSort";
import { buildBookingsSearchPayload } from "./shared/filters";
import { BOOKINGS_ROWS_PER_PAGE_OPTS } from "./shared/constants";
import { getLatestInvoiceId, getProvisionalBookingIds } from "./shared/utils";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import BookingAccordion from "./components/BookingAccordion";
import BookingRow from "./components/BookingRow";
import BookingFilters, { BOOKINGS_SORTABLE_COLUMNS } from "./components/BookingFilters";
import useCallbackDebounced from "./hooks/useCallbackDebounced";
import LineClampedText from "../../components/LineClampedText";
import NoDataRow from "../../components/NoDataRow";
import ViewAddresses from "./ViewAddresses/ViewAddresses";
import ViewInvoice from "../../components/ViewInvoice/ViewInvoice";
import { IInvoice } from "../../models/invoice";
import { Box } from "@mui/material";
import { Clear, Tune } from "@mui/icons-material";
import { useSidebarOwner } from "../../contexts/SidebarOwnerContext";

/**
 * Renders the Dispatcher Manage Bookings page.
 * On this page, bookings are listed in a table with important booking data displayed, including all berthings associated with.
 * The table also has filtering options.
 *
 * User Actions:
 * - Details button action: Opens the Booking Respond Form in a sidebar.
 * - Show in Plan: Opens the Dock Allocation Plan page with the selected booking highlighted.
 * - View Dock Access: Displays dock access data in a dialog.
 * - View Ship: Displays ship data in a dialog.
 * - Set/Unset Pending: Changes the booking status to pending/unpending.
 * - View Addresses: Displays address info of the ship associated with the booking.
 * - Send Booking Response: Confirms changes the user made for a booking.
 * - Edit Invoice: Opens the invoice form in the Manage Invoices page, allowing the user to make changes to that booking's invoice.
 * - View Invoice: Opens the booking-related invoice data in a dialog.
 *
 * This page also features the batch toggle control for all bookings, allowing the user to select multiple bookings to send booking response for.
 */

const ManageBookings = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const intl = useIntl();

    const [, , { findById: findOwningCompanyById }] = useFetchOwningCompanies();
    const [ships] = useFetchShips();

    const [bookings, setBookings] = useState<IBooking[]>([]);
    const provisionalBookingIds = useMemo(() => getProvisionalBookingIds(bookings), [bookings]);

    const [, expandedRowsHandler] = useBatchToggleControl();
    const [selectedBookingIds, selectedBookingsHandler] = useBatchToggleControl();

    const allProvisionalBookingsSelected =
        selectedBookingsHandler.count > 0 &&
        selectedBookingsHandler.count === provisionalBookingIds.length;

    const [totalBookings, setTotalBookings] = useState(0);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedBookingId, setSelectedBookingId] = useUrlState(
        "selectedBookingId",
        "",
        Serializers.string,
    );
    const [selectedInvoice, setSelectedInvoice] = useState<IInvoice>();
    const [selectedBooking, setSelectedBooking] = useSidebarOwner<IBooking>();
    //const [selectedBooking, setSelectedBooking] = useState<IBooking | undefined>();
    const [viewShipData, setViewShipData] = useState(false);
    const [viewDockAccessData, setViewDockAccessData] = useState(false);
    const [viewAddresses, setViewAddresses] = useState(false);
    const [loadingBookings, setLoadingBookings] = useState(true);
    const [showFilters, setShowFilters] = useState(false);

    const [filters, setFilters] = useGuiConfState("dispBookings");

    const registerSort = useTableSort<typeof BOOKINGS_SORTABLE_COLUMNS>({
        columns: BOOKINGS_SORTABLE_COLUMNS,
        value: filters.order,
        onChange: order => setFilters({ order }),
    });

    const [, setLoading] = useState(true);

    useEffect(() => {
        const persistInitialBooking = async () => {
            try {
                const initialBooking = await getBooking(selectedBookingId);
                setSelectedBooking(initialBooking.data);
                setSidebarOpen(true);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.booking));
            }
        };
        if (selectedBookingId) {
            persistInitialBooking();
        }
    }, [selectedBookingId, dispatch, intl, setSelectedBooking]);

    const selectedBookingIdRef = useRef(selectedBookingId);
    useEffect(() => {
        selectedBookingIdRef.current = selectedBookingId;
    }, [selectedBookingId]);

    const getBookings = useCallbackDebounced(
        async () => {
            setLoadingBookings(true);
            try {
                const searchParams = buildBookingsSearchPayload(filters);
                const apiResponse = await searchBookings(searchParams);
                const newBookings = apiResponse.data.rows ?? [];
                setBookings(newBookings);
                const currentSelectedBookingId = selectedBookingIdRef.current;
                if (currentSelectedBookingId) {
                    const newSelectedBooking = newBookings.find(
                        b => b.id === Number(currentSelectedBookingId),
                    );
                    if (newSelectedBooking) {
                        setSelectedBooking(newSelectedBooking);
                    }
                }
                setTotalBookings(apiResponse.data.total ?? 0);
                expandedRowsHandler.clearAll();
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.bookings));
            } finally {
                setLoadingBookings(false);
            }
        },
        [JSON.stringify(filters)],
        500,
    );

    useEffect(() => {
        getBookings();
    }, [getBookings]);

    const clearFilters = () => {
        setFilters({
            shipName: undefined,
            docks: [],
            bookingStatuses: [],
            dateFrom: null,
            dateTo: null,
            finalized: null,
        });
    };

    const handleChangePage = useCallback(
        (_event: unknown, page: number) => {
            setFilters({ page });
        },
        [setFilters],
    );

    const handleChangeRowsPerPage = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setFilters({
                rowsPerPage: parseInt(event.target.value, 10),
                page: 0,
            });
        },
        [setFilters],
    );

    const handleSelectedBookingIdChange = useCallback(
        (id: string) => {
            setSelectedBookingId(id);
            setSidebarOpen(id.length > 0);
            if (id.length === 0) {
                setSelectedBooking(undefined);
            }
        },
        [setSelectedBooking, setSelectedBookingId],
    );

    const handleBookingDetails = useCallback(
        (row: IBooking) => {
            setSelectedBooking(row);
            handleSelectedBookingIdChange(String(row.id));
        },
        [handleSelectedBookingIdChange, setSelectedBooking],
    );

    const handleBookingViewDockAccess = (row: IBooking) => {
        setSelectedBooking(row);
        setViewDockAccessData(true);
    };

    const handleBookingViewShip = (row: IBooking) => {
        setSelectedBooking(row);
        setViewShipData(true);
    };

    const handleBookingViewAddresses = (row: IBooking) => {
        setSelectedBooking(row);
        setViewAddresses(true);
    };

    const handleEditInvoice = (row: IBooking) => {
        if (!row.invoices?.length) return;
        const latestInvoiceId = getLatestInvoiceId(row.invoices);
        const url = `/manage-invoices?selectedInvoiceId=${latestInvoiceId}&fromBooking=true`;
        navigate(url);
    };

    const handleViewInvoice = (row: IBooking) => {
        if (!row.invoices?.length) return;
        const latestInvoiceId = getLatestInvoiceId(row.invoices);
        setSelectedInvoice(row.invoices.find(invoice => invoice.id === latestInvoiceId));
    };

    const sendBookingResponses = useCallback(
        async (id?: number) => {
            try {
                setLoading(true);
                await sendBookingResponse(id ? [id] : selectedBookingIds);
                dispatch(
                    showBackendMessage(intl, "success", "updating", Messages.bookingResponses),
                );
                getBookings();
            } catch {
                dispatch(showBackendMessage(intl, "error", "updating", Messages.bookingResponses));
            } finally {
                setLoading(false);
                selectedBookingsHandler.clearAll();
            }
        },
        [selectedBookingIds, getBookings, dispatch, intl, selectedBookingsHandler],
    );

    const handlePendingBooking = async ({ id: bookingId, status }: IBooking) => {
        if (
            status !== BookingStatus.PENDING &&
            status !== BookingStatus.REQUESTED &&
            status !== BookingStatus.CHANGE_REQUESTED
        ) {
            // TODO: dispatch error message
            return;
        }
        try {
            setLoading(true);
            await pendingBooking({
                bookingId,
                pending: status !== BookingStatus.PENDING,
            });
            dispatch(showBackendMessage(intl, "success", "updating", Messages.booking));
            getBookings();
        } catch {
            dispatch(showBackendMessage(intl, "error", "updating", Messages.booking));
        } finally {
            setLoading(false);
        }
    };

    const toggleSelectedProvisionalBookings = () => {
        if (allProvisionalBookingsSelected) {
            selectedBookingsHandler.clearAll();
            return;
        }
        selectedBookingsHandler.setValue(provisionalBookingIds);
    };

    const bookingActions = useMemo(
        () =>
            bookings
                .map(({ id }) => id)
                .reduce(
                    (prev: any, id: number) => ({
                        ...prev,
                        [id]: {
                            handleShowInPlan: () => {
                                const booking = bookings.find(b => b.id === id)!;
                                // const berthOrBookingId = booking.berthings?.[0]?.id ?? id;
                                const url = `/dock-allocation-plan?selectedBookingId=${booking.id}`;
                                navigate(url);
                            },
                            handleBookingViewDockAccess: () =>
                                handleBookingViewDockAccess(bookings.find(b => b.id === id)!),
                            handleSendBookingResponses: () => sendBookingResponses(id),
                            handlePendingBooking: () =>
                                handlePendingBooking(bookings.find(b => b.id === id)!),
                            handleBookingViewShip: () =>
                                handleBookingViewShip(bookings.find(b => b.id === id)!),
                            handleEditInvoice: () =>
                                handleEditInvoice(bookings.find(b => b.id === id)!),
                            handleBookingViewAddresses: () =>
                                handleBookingViewAddresses(bookings.find(b => b.id === id)!),
                            handleViewInvoice: () =>
                                handleViewInvoice(bookings.find(b => b.id === id)!),
                        },
                    }),
                    {},
                ),
        // TODO: Reconsider enabling eslint rule and fixing the issue
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [bookings, navigate],
    );

    const onRespondToBookingClose = useCallback(() => {
        handleSelectedBookingIdChange("");
    }, [handleSelectedBookingIdChange]);

    return (
        <RpisPage
            title={intl.formatMessage(Messages.manageBookings)}
            className="disp-manage-bookings-container"
        >
            <RpisDialog
                size="md"
                dialogOpen={viewShipData}
                title={intl.formatMessage(Messages.viewShipData)}
                onClose={() => setViewShipData(false)}
                fullWidth={true}
                content={
                    <ShipDataContainer
                        selectedShip={
                            ships.find(s => s.id === selectedBooking?.shipId) ?? shipDispatcherInit
                        }
                        owningCompany={
                            findOwningCompanyById(selectedBooking?.ship?.owningCompanyAddressId) ??
                            undefined
                        }
                        owningCompanyName={
                            findOwningCompanyById(selectedBooking?.ship?.owningCompanyAddressId)
                                ?.company
                        }
                    />
                }
            />

            <RpisDialog
                fullWidth
                title={intl.formatMessage(Messages.viewDockAccess)}
                dialogOpen={viewDockAccessData}
                onClose={() => setViewDockAccessData(false)}
                size="md"
                content={
                    <ViewDockAccessData
                        selectedShip={
                            ships.find(s => s.id === selectedBooking?.shipId) ?? shipDispatcherInit
                        }
                    />
                }
            />

            {selectedInvoice && (
                <RpisDialog
                    className="view-invoice-dialog"
                    title={`${intl.formatMessage(Messages.invoice)} ${
                        selectedInvoice.internalInvoiceNumber
                    }`}
                    fullWidth={true}
                    size="xl"
                    dialogOpen={!!selectedInvoice}
                    onClose={() => {
                        setSelectedInvoice(undefined);
                    }}
                    content={<ViewInvoice selectedInvoice={selectedInvoice} />}
                />
            )}

            <RpisDialog
                fullWidth
                title={intl.formatMessage(Messages.viewAddresses)}
                dialogOpen={viewAddresses}
                onClose={() => setViewAddresses(false)}
                size="md"
                content={<ViewAddresses row={selectedBooking} />}
            />

            <SidebarLayout
                sidebarTitle={undefined}
                open={sidebarOpen}
                onClose={onRespondToBookingClose}
                sidebarContent={
                    selectedBooking ? (
                        <BookingRespondForm
                            getBookings={getBookings}
                            selectedBooking={selectedBooking!}
                            setSidebarOpen={setSidebarOpen}
                        />
                    ) : undefined
                }
                leftSidebar={{
                    title: intl.formatMessage(Messages.filters),
                    open: showFilters,
                    onClose: () => setShowFilters(false),
                    children: (
                        <BookingFilters filters={filters} ships={ships} setFilters={setFilters} />
                    ),
                }}
            >
                <Box display="flex" padding={1} gap={1} justifyContent="space-between">
                    <ButtonGroup color="primary" variant="outlined">
                        <Button
                            variant="contained"
                            startIcon={<Tune />}
                            onClick={() => setShowFilters(true)}
                        >
                            {intl.formatMessage(Messages.filters)}
                        </Button>
                        <Tooltip title={intl.formatMessage(Messages.clearFilters)}>
                            <Button variant="contained" size="small" onClick={clearFilters}>
                                <Clear />
                            </Button>
                        </Tooltip>
                    </ButtonGroup>
                    {selectedBookingIds &&
                        sendBookingResponses &&
                        selectedBookingIds.length > 0 && (
                            <Button
                                sx={{ marginRight: "2rem" }}
                                variant="contained"
                                onClick={() => sendBookingResponses()}
                            >
                                {intl.formatMessage(Messages.sendBookingResponses)}
                            </Button>
                        )}
                </Box>
                <TableContainer className="manage-bookings-table-container">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <FormControlLabel
                                        label=""
                                        disabled={loadingBookings || !provisionalBookingIds.length}
                                        labelPlacement="top"
                                        control={
                                            <Switch
                                                onChange={toggleSelectedProvisionalBookings}
                                                checked={allProvisionalBookingsSelected}
                                            />
                                        }
                                    />
                                </TableCell>
                                <TableCellSorted
                                    label={intl.formatMessage(Messages.shipName)}
                                    {...registerSort("shipName")}
                                />
                                <TableCell align="right">
                                    <LineClampedText text={intl.formatMessage(Messages.eni)} />
                                </TableCell>
                                <TableCell>
                                    <LineClampedText
                                        text={intl.formatMessage(Messages.assignedDock)}
                                    />
                                </TableCell>
                                <TableCellSorted
                                    align="right"
                                    label={intl.formatMessage(Messages.arrivalTime)}
                                    {...registerSort("arrivalTime")}
                                />
                                <TableCellSorted
                                    align="right"
                                    label={intl.formatMessage(Messages.departureTime)}
                                    {...registerSort("departureTime")}
                                />
                                <TableCellSorted
                                    label={intl.formatMessage(Messages.managingCompany)}
                                    {...registerSort("managingCompanyName")}
                                />
                                <TableCellSorted
                                    label={intl.formatMessage(Messages.owningCompany)}
                                    {...registerSort("owningCompany")}
                                />
                                <TableCellSorted
                                    align="right"
                                    label={intl.formatMessage(Messages.requestedOnLastChange)}
                                    {...registerSort("requestDate")}
                                />
                                <TableCellSorted
                                    align="right"
                                    label={intl.formatMessage(Messages.bookingConfirmationTime)}
                                    {...registerSort("confirmTime")}
                                />
                                <TableCellSorted
                                    align="right"
                                    label={intl.formatMessage(Messages.bookingStatus)}
                                    {...registerSort("status")}
                                />
                                <TableCell align="left">
                                    <LineClampedText
                                        text={`${intl.formatMessage(
                                            Messages.remarks,
                                        )}/ ${intl.formatMessage(Messages.onCallStatus)}`}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <LineClampedText
                                        text={intl.formatMessage(Messages.completeness)}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <LineClampedText text={intl.formatMessage(Messages.actions)} />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {loadingBookings ? (
                            <TableSkeleton
                                columns={14}
                                rows={filters.rowsPerPage}
                                skeletonHeight={30}
                                cellHeight={38}
                                paddingY="1rem"
                            />
                        ) : (
                            <TableBody>
                                {bookings.length > 0 ? (
                                    bookings.map(row => (
                                        <React.Fragment key={row.id}>
                                            <BookingRow
                                                actions={bookingActions[row.id]}
                                                row={row}
                                                active={Number(selectedBookingId) === row.id}
                                                onRowClick={handleBookingDetails}
                                                expandedRowsHandler={expandedRowsHandler}
                                                selectedBookingsHandler={selectedBookingsHandler}
                                            />
                                            {/* Expanded part of the table row*/}
                                            <BookingAccordion
                                                open={expandedRowsHandler.isActive(row.id)}
                                                colSpan={14}
                                                berthings={row.berthings}
                                                active={Number(selectedBookingId) === row.id}
                                            />
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <NoDataRow colSpan={14} />
                                )}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    page={totalBookings === 0 ? 0 : filters.page}
                    count={totalBookings}
                    rowsPerPage={filters.rowsPerPage}
                    rowsPerPageOptions={BOOKINGS_ROWS_PER_PAGE_OPTS}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </SidebarLayout>
        </RpisPage>
    );
};

export default memo(ManageBookings);
