import "./ManageExpenseTypes.css";
import { useIntl } from "react-intl";
import RpisPage from "../RpisPage/RpisPage";
import Messages from "../../localization/Messages";
import SidebarLayout from "../SidebarLayout/SidebarLayout";
import { useCallback, useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Autocomplete,
    FormControl,
    FilledInput,
    InputAdornment,
    InputLabel,
    TablePagination,
    TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import { IDock } from "../../models/dock";
import { ISearchParams, searchParamsInit } from "../../models/searchParams";
import { useDispatch } from "react-redux";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { deleteExpenseType, searchExpenseType } from "../../api/expenseType";
import { IExpenseType, getActivePeriod } from "../../models/expenseType";

import ExpenseTypeForm from "./ExpenseTypeForm";
import { TableSkeleton } from "../../components/TableSkeleton/TableSkeleton";

import ExpenseRow from "./components/ExpenseRow";
import { useCachedDocks } from "../../hooks/useCachedDocks";

type ExpandableRowState = {
    rowId: string;
    expanded: boolean;
};

/**
 * Renders the Manage Expense Types page.
 *
 * This component allows the user to manage expense types and expense periods associated with them.
 * Expense types are displayed in a table that supports filtering by dock name and generic search.
 * Each row represents a single expense type and can be expanded to display the expense periods linked to it.
 *
 * The user can perform the following actions:
 * - Add new expense types
 * - Add new expense periods for each expense type
 * - Archive expense types
 *
 * The page also features an Expense Type form in the sidebar.
 */
export const ManageExpenseTypes = () => {
    const intl = useIntl();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedExpenseType, setSelectedExpenseType] = useState<IExpenseType>();
    const [expenseTypes, setExpenseTypes] = useState<IExpenseType[]>([]);
    const [totalExpenseTypes, setTotalExpenseTypes] = useState(0);
    const [loading, setLoading] = useState(false);
    const { docks, byId } = useCachedDocks();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useState<ISearchParams>({
        ...searchParamsInit,
        limit: 10,
        order: ["name asc"],
        filter: { $and: [{ custom: false }] },
    });
    const [textSearch, setTextSearch] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [dockId, setDockId] = useState<string>("");
    const [expandableRowStates, setExpandableRowStates] = useState<ExpandableRowState[]>([]);

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const res = await searchExpenseType(searchParams);
            setExpenseTypes(res.data.rows ?? []);
            setTotalExpenseTypes(res.data.total);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.expenseTypes));
        } finally {
            setLoading(false);
        }
    }, [dispatch, intl, searchParams]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleArchiveExpenseType = async (expenseType: IExpenseType) => {
        try {
            setLoading(true);
            await deleteExpenseType(expenseType.id);
            dispatch(showBackendMessage(intl, "success", "archiving", Messages.expenseType));
            fetchData();
        } catch {
            dispatch(showBackendMessage(intl, "error", "archiving", Messages.expenseType));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        expenseTypes.forEach(type => {
            const rowState: ExpandableRowState = { rowId: type.id, expanded: false };
            setExpandableRowStates(expandableRowStates => [...expandableRowStates, rowState]);
        });
    }, [expenseTypes]);

    const handleSetExpandableRowState = (id: string) => {
        setExpandableRowStates(prevStates =>
            prevStates.map(expRow =>
                expRow.rowId === id ? { ...expRow, expanded: !expRow.expanded } : expRow,
            ),
        );
    };

    const applyFilters = () => {
        setSearchParams(sp => {
            return {
                ...sp,
                dockId: dockId,
                textSearch: textSearch,
            };
        });
    };

    const clearFilters = () => {
        setTextSearch("");
        setSearchParams(sp => {
            return {
                ...sp,
                filter: {},
                textSearch: "",
                dockId: "",
            };
        });
    };

    const handleKeyDown = (key: string) => {
        if (key === "Enter") {
            applyFilters();
        }
        return;
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setSearchParams({
            ...searchParams,
            offset: newPage * rowsPerPage,
            limit: rowsPerPage,
        });
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setSearchParams({
            ...searchParams,
            offset: 0,
            limit: parseInt(event.target.value, 10),
        });
    };

    const handleClickAddExpensePeriod = (expenseType: IExpenseType) => {
        setSelectedExpenseType(expenseType);
        setSidebarOpen(true);
    };

    return (
        <RpisPage
            title={intl.formatMessage(Messages.manageExpenseTypes)}
            className="manage-expense-types-container"
        >
            <SidebarLayout
                sidebarTitle={
                    selectedExpenseType
                        ? intl.formatMessage(Messages.addExpensePeriod)
                        : intl.formatMessage(Messages.addExpenseType)
                }
                open={sidebarOpen}
                onClose={() => {
                    setSelectedExpenseType(undefined);
                    setSidebarOpen(false);
                }}
                sidebarContent={
                    sidebarOpen ? (
                        <ExpenseTypeForm
                            selectedExpenseType={selectedExpenseType}
                            setSidebarOpen={setSidebarOpen}
                            callback={fetchData}
                        />
                    ) : undefined
                }
            >
                <div className="manage-expenses-actions-container">
                    <Autocomplete
                        id="tags-standard"
                        options={docks}
                        getOptionLabel={(option: IDock) => option.name}
                        isOptionEqualToValue={(a, b) => a.id === b.id}
                        renderInput={params => (
                            <TextField
                                {...params}
                                required
                                variant="filled"
                                label={intl.formatMessage(Messages.dock)}
                            />
                        )}
                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        onChange={(_, value: IDock | null) => {
                            setDockId(value?.id ?? "");
                        }}
                        value={byId.get(dockId) ?? null}
                    />

                    <FormControl variant="filled">
                        <FilledInput
                            onChange={e => setTextSearch(e.target.value)}
                            onKeyDown={e => {
                                handleKeyDown(e.key);
                            }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                            value={textSearch}
                            autoFocus={true}
                        />
                        <InputLabel>{intl.formatMessage(Messages.search)}</InputLabel>
                    </FormControl>
                    <Button variant="contained" onClick={applyFilters}>
                        {intl.formatMessage(Messages.search)}
                    </Button>
                    {(textSearch || dockId) && (
                        <Button variant="outlined" onClick={clearFilters}>
                            {intl.formatMessage(Messages.clear)}
                        </Button>
                    )}
                    <Button
                        className="add-expense-type-button"
                        variant="contained"
                        onClick={() => {
                            setSidebarOpen(true);
                            setSelectedExpenseType(undefined);
                        }}
                    >
                        {intl.formatMessage(Messages.addExpenseType)}
                    </Button>
                </div>
                <TableContainer className="manage-expense-types-table-container">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{intl.formatMessage(Messages.select)}</TableCell>
                                <TableCell>{intl.formatMessage(Messages.ordnum)}</TableCell>
                                <TableCell>{intl.formatMessage(Messages.name)}</TableCell>
                                <TableCell>{intl.formatMessage(Messages.category)}</TableCell>
                                <TableCell>{intl.formatMessage(Messages.SAPNumber)}</TableCell>
                                <TableCell>{intl.formatMessage(Messages.unit)}</TableCell>
                                <TableCell>
                                    {intl.formatMessage(Messages.netPricePerUnit)}
                                </TableCell>
                                <TableCell>{intl.formatMessage(Messages.vatNumber)}</TableCell>
                                <TableCell>{intl.formatMessage(Messages.validFrom)}</TableCell>
                                <TableCell>{intl.formatMessage(Messages.validTo)}</TableCell>
                                <TableCell>
                                    {intl.formatMessage(Messages.calculationType)}
                                </TableCell>
                                <TableCell align="center">
                                    {intl.formatMessage(Messages.actions)}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {!expenseTypes.length && loading ? (
                            <TableSkeleton rows={10} columns={8} />
                        ) : (
                            <TableBody>
                                {expenseTypes.map(row => {
                                    const activePeriod = getActivePeriod(row.periods);
                                    return (
                                        <ExpenseRow
                                            refreshTable={fetchData}
                                            active={selectedExpenseType?.id === row.id}
                                            key={row.id}
                                            row={row}
                                            activePeriod={activePeriod}
                                            handleClickAddExpensePeriod={
                                                handleClickAddExpensePeriod
                                            }
                                            expanded={
                                                expandableRowStates.find(
                                                    expRow => expRow.rowId === row.id,
                                                )?.expanded ?? false
                                            }
                                            handleSetExpandableRowState={
                                                handleSetExpandableRowState
                                            }
                                            handleArchivePeriod={handleArchiveExpenseType}
                                        />
                                    );
                                })}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalExpenseTypes}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </SidebarLayout>
        </RpisPage>
    );
};

export default ManageExpenseTypes;
