import { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import Messages from "../../localization/Messages";
import { addEditStopInit, disabledStopStatuses, IAddEditStop } from "../../models/stop";
import * as stopApi from "../../api/stop";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { showBackendMessage } from "../../helpers/messagesHelper";
import StatusChip from "../../components/StatusChip/StatusChip";
import { getShip } from "../../api/ship";
import { getVoyage } from "../../api/voyage";
import { getAreas } from "../../api/dock";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, ButtonGroup, Menu, Tooltip } from "@mui/material";
import { ArrowDropDown, HelpOutline } from "@mui/icons-material";
import { BookingStatus } from "../../models/booking";
import { showToastMessage } from "../../actions/toastMessageActions";
import { DateFormat, getDateMoment } from "../../helpers/dateHelper";
import { AppState } from "../../store/configureStore";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import { Errors } from "../../models/errors";
import { useFetchDestinations } from "../../hooks/useFetchDestinations";
import "./StopForm.css";
import RpisDialog from "../RpisDialog/RpisDialog";
import ShipDataContainer from "../ShipDataContainer/ShipDataContainer";
import { AddressType } from "../../models/address";
import { useFetchAddresses } from "../../hooks/useFetchAddresses";
import { useNavigate } from "react-router-dom";
import { IInvoice, InvoiceStatus } from "../../models/invoice";
import { searchInvoice } from "../../api/dockInvoice";
import { searchParamsInit } from "../../models/searchParams";
import ViewInvoice from "../../components/ViewInvoice/ViewInvoice";

export interface IStopForm {
    selectedStop?: IAddEditStop;
    voyageId?: string;
    hideViewDockButton?: boolean;
    onApiOperationEnd?: () => void;
    onApiDeleteSuccess?: (stopId: number) => void;
    onApiCreateSuccess?: (stop: IAddEditStop) => void;
    onApiUpdateSuccess?: (stop: IAddEditStop) => void;
}

/**
 * Renders the StopForm component which is a part of the Plan Voyage page.
 *
 * The StopForm component allows users to create, edit, or delete stops.
 * It also provides actions such as sending a booking request for the stop, viewing ship data, updating ship data, and viewing the invoice for the booking with all expenses listed.
 *
 * @component
 * @example
 * return (
 *   <StopForm
 *     selectedStop={selectedStop}
 *     voyageId={voyageId}
 *     hideViewDockButton={false}
 *     onApiCreateSuccess={() => {}}
 *     onApiDeleteSuccess={() => {}}
 *     onApiUpdateSuccess={() => {}}
 *     onApiOperationEnd={() => {}}
 *   />
 * )
 */
export const StopForm = ({
    selectedStop,
    voyageId,
    hideViewDockButton = false,
    onApiCreateSuccess = () => {},
    onApiDeleteSuccess = () => {},
    onApiUpdateSuccess = () => {},
    onApiOperationEnd = () => {},
}: IStopForm) => {
    const withConfirm = useConfirmDialog();
    const intl = useIntl();
    const dispatch = useDispatch();
    const [stop, setStop] = useState(addEditStopInit);
    const [errors, setErrors] = useState<Errors<IAddEditStop>>({});
    const [loading, setLoading] = useState(false);
    const [areas, setAreas] = useState<string[]>([]);
    const [viewAllocationPlanTooltip, setViewAllocationPlanTooltip] = useState<string>("");
    const timeZones = useSelector((s: AppState) => s.destinationZones).data;
    const destinations = useFetchDestinations();
    const [dialogOpen, setDialogOpen] = useState(false);
    const addresses = useFetchAddresses();
    const selectedShip = stop.ship;
    const [open, setOpen] = useState(false);
    const menuButtonRef = useRef<HTMLButtonElement>(null);
    const handleToggle = useCallback(() => {
        setOpen(prev => !prev);
    }, []);
    const [invoice, setInvoice] = useState<IInvoice>();
    const [viewInvoice, setViewInvoice] = useState(false);

    const disabled = disabledStopStatuses.includes(selectedStop?.bookingStatus as BookingStatus);

    const onChange = (diff: Partial<IAddEditStop>) => {
        setStop({ ...stop, ...diff });
        const clearErros = Object.fromEntries(Object.entries(diff).map(([k]) => [k, undefined]));
        setErrors({ ...errors, ...clearErros });
    };

    const validateStop = () => {
        const newErrors: Errors<IAddEditStop> = {};
        if (!stop.destinationId) {
            newErrors.destinationId = intl.formatMessage(Messages.destinationIsRequired);
        }
        if (stop.eta >= stop.etd) {
            newErrors.etd = intl.formatMessage(Messages.arrivalTimeMustBeBeforeDepartureTime);
        }
        if (isNaN(stop.eta)) {
            newErrors.eta = intl.formatMessage(Messages.wrongDateFormat);
        }
        if (isNaN(stop.etd)) {
            newErrors.etd = intl.formatMessage(Messages.wrongDateFormat);
        }
        return newErrors;
    };

    const validateAllocationPlanView = useCallback(() => {
        setViewAllocationPlanTooltip("");
        if (!stop.destinationId) {
            setViewAllocationPlanTooltip(
                "Destination has to be selected in order to view Dock allocation plan",
            );
        }
        if (stop.eta && stop.eta - Date.now() > 40 * 24 * 60 * 60 * 1000) {
            setViewAllocationPlanTooltip(
                "This booking will be available in the Dock allocation plan at the earliest 40 days before the estimated arrival time",
            );
        }
    }, [stop]);

    useEffect(() => {
        validateAllocationPlanView();
    }, [validateAllocationPlanView]);

    const handleConfirmBookingChange = async () => {
        const stopId = stop.id;
        if (!stopId) return;
        setLoading(true);
        try {
            await stopApi.confirmChangedBooking(stopId);
            dispatch(showBackendMessage(intl, "success", "updating", Messages.bookingStatus));
            onApiOperationEnd();
        } catch {
            dispatch(showBackendMessage(intl, "error", "updating", Messages.bookingStatus));
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        const newErrors = validateStop();
        if (Object.keys(newErrors).length) {
            setErrors(newErrors);
            return;
        }
        setLoading(true);
        try {
            if (selectedStop) {
                await stopApi.changeStop(stop);
                dispatch(showBackendMessage(intl, "success", "updating", Messages.stop));
            } else {
                const voyage = await getVoyage(voyageId ?? "");
                const ship = await getShip(voyage.data.shipId);
                const timeZone = timeZones.get(stop.destinationId);
                const zonedEta = getDateMoment(stop.eta, {
                    timeZone,
                }).valueOf();
                const zonedEtd = getDateMoment(stop.etd, {
                    timeZone,
                }).valueOf();
                const response = await stopApi.addStop({
                    ...stop,
                    eta: zonedEta,
                    etd: zonedEtd,
                    voyageId: voyageId ?? "",
                    ship: ship.data,
                });
                dispatch(showBackendMessage(intl, "success", "creating", Messages.stop));
                if (response.data) {
                    onApiCreateSuccess(response.data);
                }
            }
            onApiOperationEnd();
        } catch {
            dispatch(
                showBackendMessage(
                    intl,
                    "error",
                    selectedStop ? "updating" : "creating",
                    Messages.stop,
                ),
            );
        } finally {
            setLoading(false);
        }
    };

    const fetchAreas = useCallback(
        async (destinationId: string) => {
            setLoading(true);
            try {
                const res = await getAreas(destinationId);
                setAreas(res.data);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.area));
            } finally {
                setLoading(false);
            }
        },
        [dispatch, intl],
    );

    useEffect(() => {
        setStop(
            selectedStop ? { ...selectedStop } : { ...addEditStopInit, voyageId: voyageId ?? "" },
        );
        selectedStop && fetchAreas(selectedStop.destinationId);
    }, [selectedStop, voyageId, fetchAreas]);

    useEffect(() => {
        const fetchInvoice = async () => {
            try {
                const res = await searchInvoice({
                    ...searchParamsInit,
                    filter: { $and: [{ bookingId: selectedStop?.bookingId }] },
                });
                setInvoice(res.data.rows[0]);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.invoices));
            }
        };
        if (selectedStop?.bookingId) {
            fetchInvoice();
        }
    }, [dispatch, intl, selectedStop]);

    const handleDelete = async () => {
        setLoading(true);
        try {
            if (!stop.voyageId) return;
            await stopApi.deleteStop(stop.id);
            onApiOperationEnd();
            onApiDeleteSuccess(stop.id);
            dispatch(showBackendMessage(intl, "success", "deleting", Messages.stop));
        } catch {
            dispatch(showBackendMessage(intl, "error", "deleting", Messages.stop));
        } finally {
            setLoading(false);
        }
    };

    const handleSendBookingRequest = async () => {
        setLoading(true);
        try {
            if (!stop.id) {
                return;
            }
            const res = await stopApi.sendBookingRequest(stop.id);
            setStop(res.data);
            dispatch(showBackendMessage(intl, "success", "booking", Messages.stop));
            onApiOperationEnd();
        } catch {
            dispatch(showBackendMessage(intl, "error", "booking", Messages.stop));
        } finally {
            setLoading(false);
            onApiUpdateSuccess(stop);
        }
    };

    const handleCancelBooking = async () => {
        setLoading(true);
        try {
            if (!stop.id) {
                return;
            }
            if (stop.bookingId) {
                await stopApi.cancelBooking(stop.id);
            }
            onApiOperationEnd();
            dispatch(showBackendMessage(intl, "success", "updating", Messages.stop));
        } catch {
            if (stop.bookingStatus === BookingStatus.DECLINED) {
                dispatch(
                    showToastMessage(
                        intl.formatMessage(Messages.errorDeletingDeclinedStop),
                        "error",
                    ),
                );
            } else {
                dispatch(showBackendMessage(intl, "error", "updating", Messages.stop));
            }
        } finally {
            setLoading(false);
        }
    };

    const handleUpdateShipData = async () => {
        setLoading(true);
        try {
            if (!stop.id) {
                return;
            }
            const res = await stopApi.updateShipData(stop.id);
            setStop(res.data);
            dispatch(showBackendMessage(intl, "success", "updating", Messages.ship));
            onApiOperationEnd();
        } catch {
            dispatch(showBackendMessage(intl, "error", "updating", Messages.ship));
        } finally {
            setLoading(false);
            onApiUpdateSuccess(stop);
        }
    };

    const navigate = useNavigate();

    return (
        <>
            <RpisDialog
                dialogOpen={dialogOpen}
                title={intl.formatMessage(Messages.viewShipData)}
                onClose={() => setDialogOpen(false)}
                size="md"
                fullWidth={true}
                content={
                    <ShipDataContainer
                        selectedShip={selectedShip}
                        owningCompanyName={
                            addresses
                                .filter(add => add.type === AddressType.OwningCompany)
                                .find(add => add.id === selectedShip.owningCompanyAddressId)
                                ?.company
                        }
                    />
                }
            />
            {invoice && (
                <RpisDialog
                    className="view-invoice-dialog"
                    title={
                        invoice.invoiceStatus === InvoiceStatus.INVOICED
                            ? `${intl.formatMessage(Messages.invoice)} ${
                                  invoice.internalInvoiceNumber
                              }`
                            : `${intl.formatMessage(Messages.previewOfExpensesForBooking)} #${
                                  selectedStop?.bookingId ?? ""
                              }:`
                    }
                    fullWidth={true}
                    size="xl"
                    dialogOpen={viewInvoice}
                    onClose={() => {
                        setViewInvoice(false);
                    }}
                    content={<ViewInvoice selectedInvoice={invoice} showDisclaimerMessage={true} />}
                />
            )}
            {!!stop.id && (
                <form className="ld-booking-form">
                    <h2>{intl.formatMessage(Messages.manageBooking)}</h2>
                    {!stop.bookingId && (
                        <div className="ld-stop-form-button-group-no-booking">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSendBookingRequest}
                            >
                                {intl.formatMessage(Messages.sendBookingRequest)}
                            </Button>
                        </div>
                    )}
                    {stop.bookingId && (
                        <>
                            <div className="ld-stop-form-button-group-booking">
                                <div className="call-id-container">
                                    <p>{intl.formatMessage(Messages.bookingId)}</p>
                                    <h2>#{stop.bookingId}</h2>
                                </div>
                                {stop.bookingStatus && <StatusChip status={stop.bookingStatus} />}
                                <ButtonGroup
                                    sx={{ marginLeft: "auto", marginRight: 1.5 }}
                                    variant="contained"
                                    color={
                                        stop.bookingStatus === BookingStatus.CHANGED
                                            ? "success"
                                            : "primary"
                                    }
                                >
                                    {stop.bookingStatus === BookingStatus.CHANGED ? (
                                        <Button
                                            variant="contained"
                                            color="success"
                                            onClick={handleConfirmBookingChange}
                                        >
                                            {intl.formatMessage(Messages.confirmBookingChange)}
                                        </Button>
                                    ) : (
                                        <>
                                            {viewAllocationPlanTooltip !== "" && (
                                                <Tooltip
                                                    title={viewAllocationPlanTooltip}
                                                    arrow
                                                    placement="top"
                                                >
                                                    <HelpOutline className="tooltip-icon colored-tooltip" />
                                                </Tooltip>
                                            )}
                                            {!hideViewDockButton && (
                                                <Button
                                                    href={`/plan/${stop.destinationId}`}
                                                    variant="contained"
                                                    target="_blank"
                                                    sx={{ textTransform: "none" }}
                                                    disabled={viewAllocationPlanTooltip !== ""}
                                                >
                                                    {intl.formatMessage(
                                                        Messages.viewDockAllocationPlan,
                                                    )}
                                                </Button>
                                            )}
                                        </>
                                    )}

                                    <Button
                                        size="small"
                                        color={
                                            stop.bookingStatus === BookingStatus.CHANGED
                                                ? "success"
                                                : "primary"
                                        }
                                        ref={menuButtonRef}
                                        onClick={handleToggle}
                                    >
                                        <ArrowDropDown />
                                    </Button>
                                </ButtonGroup>
                                <Menu
                                    anchorEl={menuButtonRef?.current}
                                    open={open}
                                    onClose={handleToggle}
                                >
                                    {stop.bookingStatus === BookingStatus.CHANGED && (
                                        <MenuItem
                                            onClick={() => navigate(`/plan/${stop.destinationId}`)}
                                        >
                                            {intl.formatMessage(Messages.viewDockAllocationPlan)}
                                        </MenuItem>
                                    )}

                                    <MenuItem onClick={() => setDialogOpen(s => !s)}>
                                        {intl.formatMessage(Messages.viewShipData)}
                                    </MenuItem>

                                    <MenuItem onClick={() => handleUpdateShipData()}>
                                        {intl.formatMessage(Messages.updateShipData)}
                                    </MenuItem>
                                    <MenuItem
                                        disabled={!invoice?.id}
                                        onClick={() => setViewInvoice(true)}
                                    >
                                        {intl.formatMessage(Messages.viewInvoice)}
                                    </MenuItem>
                                </Menu>
                            </div>
                        </>
                    )}
                </form>
            )}

            <form className="ld-stop-form">
                <h2 style={{ marginTop: "1rem", marginBottom: "0.5rem" }}>
                    {selectedStop
                        ? intl.formatMessage(Messages.updateStop)
                        : intl.formatMessage(Messages.addStop)}
                </h2>
                <div className="two-column-form">
                    <FormControl className="ld-stop-dropdown">
                        <TextField
                            label={intl.formatMessage(Messages.destination)}
                            select
                            error={Boolean(errors.destinationId)}
                            disabled={loading || disabled}
                            value={stop.destinationId}
                            onChange={e => {
                                onChange({ destinationId: e.target.value });
                                fetchAreas(e.target.value);
                            }}
                            helperText={
                                errors.destinationId
                                    ? intl.formatMessage(Messages.destinationIsRequired)
                                    : ""
                            }
                            variant="filled"
                        >
                            {destinations.map(destination => (
                                <MenuItem key={destination.id} value={destination.id}>
                                    {destination.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                    <Autocomplete
                        className="ld-stop-dropdown"
                        disabled={loading || disabled}
                        value={stop.areaPreference}
                        onChange={(_, value) => onChange({ areaPreference: value ?? undefined })}
                        options={areas}
                        renderInput={params => (
                            <TextField
                                variant="filled"
                                {...params}
                                label={intl.formatMessage(Messages.areaPreferrence)}
                            />
                        )}
                    />
                </div>
                <Box display="flex" gap={2}>
                    <DesktopDateTimePicker
                        disabled={disabled}
                        label={intl.formatMessage(Messages.arrivalTime)}
                        inputFormat={DateFormat.CLIENT_DATE_TIME}
                        value={stop.eta}
                        disablePast
                        onChange={value => {
                            const newEta = getDateMoment(value).valueOf();
                            const newEtd = newEta >= stop.etd ? newEta : stop.etd;
                            onChange({
                                eta: newEta,
                                etd: newEtd,
                            });
                        }}
                        renderInput={params => (
                            <TextField
                                variant="filled"
                                {...params}
                                fullWidth
                                helperText={errors.eta}
                                error={Boolean(errors.eta)}
                            />
                        )}
                    />
                    <DesktopDateTimePicker
                        disabled={disabled}
                        label={intl.formatMessage(Messages.departureTime)}
                        inputFormat={DateFormat.CLIENT_DATE_TIME}
                        value={stop.etd}
                        disablePast
                        minDateTime={getDateMoment(stop.eta)}
                        onChange={value => onChange({ etd: getDateMoment(value).valueOf() })}
                        renderInput={params => (
                            <TextField
                                variant="filled"
                                {...params}
                                fullWidth
                                helperText={errors.etd}
                                error={Boolean(errors.etd)}
                            />
                        )}
                    />
                </Box>
                <div className="three-column-form">
                    <TextField
                        disabled={disabled}
                        className="number-input"
                        type="number"
                        error={Boolean(errors.personsOnBoard)}
                        value={stop.personsOnBoard}
                        label={intl.formatMessage(Messages.personsOnBoard)}
                        onChange={e =>
                            onChange({
                                personsOnBoard: Number.parseInt(e.target.value),
                            })
                        }
                        variant="filled"
                        helperText={errors.personsOnBoard}
                        InputProps={{ inputProps: { min: 0, max: 99999 } }}
                    />
                    <TextField
                        disabled={disabled}
                        className="number-input"
                        type="number"
                        error={Boolean(errors.crewOnBoard)}
                        value={stop.crewOnBoard}
                        label={intl.formatMessage(Messages.crewOnBoard)}
                        onChange={e =>
                            onChange({
                                crewOnBoard: Number.parseInt(e.target.value),
                            })
                        }
                        variant="filled"
                        helperText={errors.crewOnBoard}
                        InputProps={{ inputProps: { min: 0, max: 99999 } }}
                    />
                    <TextField
                        disabled={disabled}
                        className="number-input"
                        type="number"
                        error={Boolean(errors.occupiedBeds)}
                        value={stop.occupiedBeds}
                        label={intl.formatMessage(Messages.occupiedBeds)}
                        onChange={e =>
                            onChange({
                                occupiedBeds: Number.parseInt(e.target.value),
                            })
                        }
                        variant="filled"
                        helperText={errors.occupiedBeds}
                        InputProps={{ inputProps: { min: 0, max: 99999 } }}
                    />
                </div>
            </form>
            <div className="ld-stop-form-button-group-actions">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={disabled}
                >
                    {selectedStop
                        ? intl.formatMessage(Messages.updateStop)
                        : intl.formatMessage(Messages.addStop)}
                </Button>
                {selectedStop && selectedStop.bookingId && (
                    <Button
                        disabled={disabled}
                        variant="contained"
                        color="error"
                        onClick={() =>
                            withConfirm({
                                message: intl.formatMessage(Messages.confirmCancelStop),
                                onConfirm: handleCancelBooking,
                            })
                        }
                    >
                        {intl.formatMessage(Messages.cancelBooking)}
                    </Button>
                )}
                {selectedStop && !selectedStop.bookingId && (
                    <Button variant="contained" color="error" onClick={handleDelete}>
                        {intl.formatMessage(Messages.delete)} {intl.formatMessage(Messages.stop)}
                    </Button>
                )}
            </div>
        </>
    );
};

export default StopForm;
