import { RemoveCircleOutline } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Autocomplete,
    Box,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { addService, changeService, deleteService } from "../../../../api/service";
import { Datatable } from "../../../../components/Datatable/Datatable";
import { DateFormat, getDateMoment } from "../../../../helpers/dateHelper";
import { showBackendMessage } from "../../../../helpers/messagesHelper";
import { useConfirmDialog } from "../../../../hooks/useConfirmDialog";
import Messages from "../../../../localization/Messages";
import { availableServices } from "../../../../models/dock";
import {
    IService,
    IServicePersonnelData,
    ServiceType,
    serviceInit,
} from "../../../../models/service";
import "./ServiceForm.css";

export type ServiceErrors = {
    type?: string | undefined;
    data?: {
        waterMeterStartValue?: string | undefined;
        waterMeterEndValue?: string | undefined;
        servicePersonnelData?: {
            startDateTime?: string | undefined;
            endDateTime?: string | undefined;
        }[];
    };
    deployments?: string | undefined;
};

export const serviceHasErrors = (errors: ServiceErrors) => {
    const hasError = (obj: any) => {
        if (typeof obj === "object") {
            return Object.values(obj).some(hasError);
        }
        return Boolean(obj);
    };

    return hasError(errors);
};

type ServiceFormProps = {
    bookingId?: number;
    selectedService?: IService | undefined;
    onClose: () => void;
    onDelete: (id: string) => void;
    onAddService: () => void;
};

export const ServiceForm = ({
    bookingId,
    selectedService,
    onClose,
    onDelete,
    onAddService,
}: ServiceFormProps) => {
    const intl = useIntl();
    const [service, setService] = useState<IService>(serviceInit);
    const [errors, setErrors] = useState<ServiceErrors>({});
    const dispatch = useDispatch();
    const withConfirm = useConfirmDialog();
    //const [services, setServices] = useState<IService[]>([]);

    useEffect(() => {
        setService(selectedService ? { ...selectedService } : serviceInit);
    }, [selectedService]);

    const onChange = useCallback((diff: Partial<IService>) => {
        setService(prev => ({ ...prev, ...diff }));
        const clearErrors = Object.fromEntries(Object.entries(diff).map(([k]) => [k, undefined]));
        setErrors(prev => ({ ...prev, ...clearErrors }));
    }, []);

    const parseNumericOrNull = (input: string, decimal: boolean) => {
        const value = decimal ? Number.parseFloat(input) : Number.parseInt(input);
        return isNaN(value) ? null : decimal ? parseFloat(value.toFixed(2)) : value;
    };

    const displayTime = (timeInMinutes: number) => {
        // I need the same output format if the time difference is negative
        let timeNegative = false;
        if (timeInMinutes < 0) {
            timeInMinutes = timeInMinutes * -1;
            timeNegative = true;
        }

        const hours = Math.floor(timeInMinutes / 60);
        const minutes = timeInMinutes % 60;

        return `${timeNegative ? "-" : ""}${hours.toString().padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}h`;
    };

    const getWaterMeterValue = () => {
        const start = service.data.waterMeterStartValue;
        const end = service.data.waterMeterEndValue;
        if (start && end) {
            return (end - start).toFixed(2);
        }
        return 0;
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const validateService = () => {
        const newErrors: ServiceErrors = {
            type: undefined,
            data: {
                waterMeterStartValue: undefined,
                waterMeterEndValue: "",
                servicePersonnelData: [],
            },
            deployments: undefined,
        };

        if (!service.type) {
            newErrors.type = intl.formatMessage(Messages.serviceTypeIsRequired);
        }
        if (service.type === ServiceType.WATER_METER) {
            newErrors.data = {};

            /* if (!service.data.waterMeterStartValue) {
                newErrors.data = {
                    ...newErrors.data,
                    waterMeterStartValue: intl.formatMessage(
                        Messages.waterMeterStartValueIsRequired,
                    ),
                };
            }
            if (!service.data.waterMeterEndValue) {
                newErrors.data = {
                    ...newErrors.data,
                    waterMeterEndValue: intl.formatMessage(Messages.waterMeterEndValueIsRequired),
                };
            }*/

            if (
                service.data.waterMeterStartValue &&
                service.data.waterMeterEndValue &&
                service.data.waterMeterStartValue > service.data.waterMeterEndValue
            ) {
                newErrors.data = {
                    ...newErrors.data,
                    waterMeterEndValue: intl.formatMessage(
                        Messages.endValueCantBeLowerThanStartValue,
                    ),
                };
            }
        }
        if (service.data.servicePersonnelData.length) {
            newErrors.data = newErrors.data ? { ...newErrors.data } : {};
            newErrors.data.servicePersonnelData = service.data.servicePersonnelData.map(() => ({
                startDateTime: undefined,
                endDateTime: undefined,
            }));

            if (newErrors.data.servicePersonnelData.length) {
                service.data.servicePersonnelData.forEach((entry, index) => {
                    if (newErrors?.data?.servicePersonnelData) {
                        /*if (!entry.startDateTime) {
                            newErrors.data.servicePersonnelData[index].startDateTime =
                                intl.formatMessage(Messages.startTimeIsRequired);
                        }

                        if (!entry.endDateTime) {
                            newErrors.data.servicePersonnelData[index].endDateTime =
                                intl.formatMessage(Messages.endTimeIsRequired);
                        } */

                        if (entry.startDateTime && isNaN(entry.startDateTime)) {
                            newErrors.data.servicePersonnelData[index].startDateTime =
                                intl.formatMessage(Messages.wrongDateFormat);
                        }

                        if (entry.endDateTime && isNaN(entry.endDateTime)) {
                            newErrors.data.servicePersonnelData[index].endDateTime =
                                intl.formatMessage(Messages.wrongDateFormat);
                        }

                        if (
                            entry.startDateTime &&
                            entry.endDateTime &&
                            entry.endDateTime <= entry.startDateTime
                        ) {
                            newErrors.data.servicePersonnelData[index].endDateTime =
                                intl.formatMessage(Messages.endTimeCanNotBeBeforeStartTime);
                        }
                    }
                });
            }
        }

        /*if (service.data.servicePersonnelData.length < 2) {
            newErrors.deployments = intl.formatMessage(
                Messages.serviceNeedsToHaveAtLeastTwoDeployments,
            );
        } */

        return newErrors;
    };

    const addDeployment = () => {
        setService(prev => ({
            ...prev,
            data: {
                ...prev.data,
                servicePersonnelData: [
                    ...prev.data.servicePersonnelData,
                    {
                        startDateTime: null,
                        endDateTime: null,
                    },
                ],
            },
        }));
    };

    const handleCloseServiceForm = () => {
        onAddService();
        setService(serviceInit);
        onClose();
    };

    const handleSubmit = async () => {
        const newErrors = validateService();
        if (serviceHasErrors(newErrors)) {
            setErrors(newErrors);
            return;
        } else {
            try {
                const servicePayload = {
                    ...service,
                    bookingId: bookingId ?? undefined,
                };
                if (selectedService) {
                    await changeService(servicePayload);
                    dispatch(showBackendMessage(intl, "success", "updating", Messages.service));

                    handleCloseServiceForm();
                } else {
                    await addService(servicePayload);
                    dispatch(showBackendMessage(intl, "success", "adding", Messages.service));
                    handleCloseServiceForm();
                }
            } catch {
                dispatch(showBackendMessage(intl, "error", "adding", Messages.service));
            }
        }
    };

    const onChangeServiceTime = (index: number, value: IServicePersonnelData) => {
        const newServicePersonelData = service.data.servicePersonnelData.map((entry, i) => {
            if (index !== i) return { ...entry };
            return value;
        });

        const newServiceData = {
            ...service.data,
            servicePersonnelData: newServicePersonelData,
        };

        onChange({
            data: newServiceData,
        });
    };

    return (
        <Box
            className="service-form-container"
            display="flex"
            justifyContent="center"
            gap={2}
            padding={1}
            flexDirection={"column"}
        >
            <Box maxWidth={330}>
                <Autocomplete
                    id="service-type-autocomplete"
                    size="small"
                    options={availableServices}
                    value={availableServices.find(s => s.value === service.type) ?? null}
                    onChange={(_, value) => {
                        onChange({ type: (value?.value as ServiceType) ?? null });
                    }}
                    getOptionLabel={option => intl.formatMessage(option.label ?? Messages.unknown)}
                    isOptionEqualToValue={(a, b) => a === b}
                    renderInput={params => (
                        <TextField
                            variant="outlined"
                            {...params}
                            label={intl.formatMessage(Messages.type)}
                            error={Boolean(errors.type)}
                            helperText={errors.type}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} key={option.value}>
                            {intl.formatMessage(option.label ?? Messages.unknown)}
                        </li>
                    )}
                />
            </Box>

            {service.type === ServiceType.WATER_METER && (
                <Grid container className="water-meter-value" gap={1}>
                    <Grid item sm={4}>
                        <TextField
                            variant="outlined"
                            type="number"
                            size="small"
                            label={intl.formatMessage(Messages.startValue)}
                            value={service.data?.waterMeterStartValue ?? ""}
                            onKeyDown={e => {
                                ["e", "E", "+"].includes(e.key) && e.preventDefault();
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">m3</InputAdornment>,
                            }}
                            onChange={e =>
                                onChange({
                                    data: {
                                        ...service.data,
                                        waterMeterStartValue: parseNumericOrNull(
                                            e.target.value,
                                            true,
                                        ),
                                    },
                                })
                            }
                            error={Boolean(errors?.data?.waterMeterStartValue)}
                            helperText={errors?.data?.waterMeterStartValue}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <TextField
                            variant="outlined"
                            type="number"
                            size="small"
                            label={intl.formatMessage(Messages.endValue)}
                            value={service.data?.waterMeterEndValue ?? ""}
                            onKeyDown={e => {
                                ["e", "E", "+"].includes(e.key) && e.preventDefault();
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">m3</InputAdornment>,
                            }}
                            onChange={e =>
                                onChange({
                                    data: {
                                        ...service.data,
                                        waterMeterEndValue: parseNumericOrNull(
                                            e.target.value,
                                            true,
                                        ),
                                    },
                                })
                            }
                            error={Boolean(errors?.data?.waterMeterEndValue)}
                            helperText={errors?.data?.waterMeterEndValue}
                        />
                    </Grid>
                    <Grid item sm={2} alignSelf={"center"}>
                        {`${getWaterMeterValue()} m3`}
                    </Grid>
                </Grid>
            )}

            <Box display="flex" justifyContent={"space-between"}>
                <Typography variant="h6" mb={"0"} display={"inline-block"}>
                    {intl.formatMessage(Messages.deployments)}
                </Typography>
                <Button onClick={() => addDeployment()} variant="contained" size="small">
                    {intl.formatMessage(Messages.addDeployment)}
                </Button>
            </Box>

            <Datatable
                size="small"
                className="deployment-table"
                items={service.data.servicePersonnelData}
                titleMapper={() => ""}
                onSortChange={() => {}}
                sortOrder={[]}
                columnDefs={[
                    {
                        key: "startDateTime",
                        label: intl.formatMessage(Messages.startTime),
                        sortable: false,

                        content: ({ startDateTime }, index) => {
                            const computedValue = startDateTime
                                ? getDateMoment(startDateTime)
                                : null;

                            return (
                                <DesktopDateTimePicker
                                    inputFormat={DateFormat.CLIENT_DATE_TIME}
                                    value={computedValue}
                                    onChange={value => {
                                        if (!value || !value.isValid()) return;
                                        onChangeServiceTime(index, {
                                            startDateTime: getDateMoment(value).valueOf(),
                                            endDateTime: value
                                                ? value.toDate().getTime() + 30 * 60 * 1000
                                                : null,
                                        });
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            sx={{ m: "0 !important" }}
                                            variant="outlined"
                                            size="small"
                                            {...params}
                                            required
                                            error={Boolean(
                                                errors?.data?.servicePersonnelData &&
                                                    errors.data.servicePersonnelData[index]
                                                        ?.startDateTime,
                                            )}
                                            helperText={
                                                errors.data?.servicePersonnelData &&
                                                errors?.data?.servicePersonnelData[index]
                                                    ?.startDateTime
                                            }
                                        />
                                    )}
                                />
                            );
                        },
                    },
                    {
                        key: "endDateTime",
                        label: intl.formatMessage(Messages.endTime),
                        sortable: false,
                        content: ({ endDateTime }, index) => {
                            const computedValue = endDateTime ? getDateMoment(endDateTime) : null;
                            return (
                                <DesktopDateTimePicker
                                    inputFormat={DateFormat.CLIENT_DATE_TIME}
                                    value={computedValue}
                                    onChange={value => {
                                        if (!value || !value.isValid()) return;
                                        onChangeServiceTime(index, {
                                            startDateTime:
                                                service.data.servicePersonnelData[index]
                                                    .startDateTime,
                                            endDateTime: value.valueOf(),
                                        });
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            sx={{ m: "0 !important" }}
                                            variant="outlined"
                                            size="small"
                                            {...params}
                                            required
                                            error={Boolean(
                                                errors.data?.servicePersonnelData &&
                                                    errors?.data?.servicePersonnelData[index]
                                                        ?.endDateTime,
                                            )}
                                            helperText={
                                                errors.data?.servicePersonnelData &&
                                                errors?.data?.servicePersonnelData[index]
                                                    ?.endDateTime
                                            }
                                        />
                                    )}
                                />
                            );
                        },
                    },
                    {
                        align: "center",
                        key: "totalTime",
                        label: "",

                        sortable: false,
                        content: (_row, index) => {
                            const startDateTime = moment(
                                service.data.servicePersonnelData[index].startDateTime,
                            ).seconds(0);
                            const endDateTime = moment(
                                service.data.servicePersonnelData[index].endDateTime,
                            ).seconds(1);
                            const totalTime = endDateTime.diff(startDateTime, "minutes");

                            return (
                                <Box
                                    justifyContent={"flex-end"}
                                    display={"flex"}
                                    alignItems={"center"}
                                >
                                    <span>{totalTime ? displayTime(totalTime) : ""}</span>

                                    <IconButton
                                        color="error"
                                        onClick={() =>
                                            onChange({
                                                data: {
                                                    ...service.data,
                                                    servicePersonnelData:
                                                        service.data.servicePersonnelData.filter(
                                                            (_, i) => i !== index,
                                                        ),
                                                },
                                            })
                                        }
                                    >
                                        <RemoveCircleOutline />
                                    </IconButton>
                                </Box>
                            );
                        },
                    },
                ]}
            />
            {errors.deployments && (
                <Typography color="error" variant="body2">
                    {errors.deployments}
                </Typography>
            )}

            <Box
                display={"flex"}
                className="form-actions"
                justifyContent={"flex-end"}
                gap={2}
                marginTop={"2rem"}
            >
                <Button variant="outlined" onClick={() => onClose()}>
                    {intl.formatMessage(Messages.cancel)}
                </Button>
                {!!selectedService?.id && (
                    <Button
                        variant="outlined"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={() =>
                            withConfirm({
                                message: intl.formatMessage(Messages.confirmServiceDeleteText),
                                onConfirm: async () => {
                                    try {
                                        const selectedServiceId = selectedService.id!;
                                        await deleteService(selectedServiceId);
                                        dispatch(
                                            showBackendMessage(
                                                intl,
                                                "success",
                                                "deleting",
                                                Messages.service,
                                            ),
                                        );
                                        onDelete(selectedServiceId);
                                    } catch {
                                        dispatch(
                                            showBackendMessage(
                                                intl,
                                                "error",
                                                "deleting",
                                                Messages.service,
                                            ),
                                        );
                                    }
                                },
                            })
                        }
                    >
                        {intl.formatMessage(Messages.delete)}
                    </Button>
                )}
                <Button variant="contained" onClick={() => handleSubmit()}>
                    {intl.formatMessage(Messages.save)}
                </Button>
            </Box>
        </Box>
    );
};
