import { Tooltip, Typography } from "@mui/material";

type props = {
    title: string;
    length?: number;
};

/**
 * A component that displays a tooltip for a given title.
 * If the title is longer than 17 characters, it will be truncated and displayed with an ellipsis and the full title will have the full title displayed.
 * @param title - The title to display in the tooltip.
 */
export const TooltipField = ({ title, length = 17 }: props) => {
    return title.length > 17 ? (
        <Tooltip title={title}>
            <Typography display={"inline-block"} fontSize={"13px"}>
                {title.trim().substring(0, length).trim() + "..."}
            </Typography>
        </Tooltip>
    ) : (
        <Typography display={"inline-block"} fontSize={"13px"}>
            {title}
        </Typography>
    );
};
