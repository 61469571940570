import { Box } from "@mui/material";
import { memo, useMemo } from "react";
import ChangeLogTreeView, { ChangeLogRender } from "./TreeView";
import buildTree from "./utils";

export type ChangeLogProps = {
    name: string;
    previous: any;
    current: any;
    exclude?: string[];
    render?: ChangeLogRender;
};

/**
 * Renders a change log component that displays changes in a tree-like structure using Material UI TreeView.
 *
 * @component
 * @example
 * // Example usage of ChangeLog component
 * <ChangeLog
 *    name="Entity Name"
 *    previous={previousData}
 *    current={currentData}
 *    exclude={["field1", "field2"]}
 *    render={customRenderFunction}
 * />
 *
 * @param {string} name - The name of the entity being displayed in the change log.
 * @param {any} previous - The previous data of the entity.
 * @param {any} current - The current data of the entity.
 * @param {string[]} [exclude=[]] - An optional array of field names to exclude from the change log.
 * @param {ChangeLogRender} [render={}] - An optional object containing custom render functions for specific fields.
 *
 * @returns {JSX.Element} The rendered ChangeLog component.
 */

const ChangeLog = ({ name, previous, current, exclude = [], render = {} }: ChangeLogProps) => {
    const tree = useMemo(
        () => buildTree(name, name, previous, current, exclude),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [name, previous, current],
    );

    return (
        <Box>
            <ChangeLogTreeView node={tree} render={render} />
        </Box>
    );
};

export default memo(ChangeLog);
