import { Close } from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { showBackendMessage } from "../../../helpers/messagesHelper";
import Messages from "../../../localization/Messages";
import { changeBerthings } from "../../../api/berthing";
import { IBerthing } from "../../../models/berthing";

/**
 * Props for the ProvDeclineButton component.
 */
export type ProvDeclineButtonProps = {
    loading?: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
    bookingId: number;
    getBookings: (bookingId: number) => Promise<void>;
    berthings: IBerthing[];
    onDeclineEnd?: () => void;
};

/**
 * A button component used to provisionally decline a booking.
 *
 * @param loading - Indicates whether the button is in a loading state.
 * @param setLoading - A function to set the loading state of the button.
 * @param bookingId - The ID of the booking to decline.
 * @param getBookings - A function to fetch updated bookings after declining.
 * @param berthings - An array of berthings associated with the booking.
 * @param onDeclineEnd - An optional callback function to be called after declining the booking.
 * @returns The ProvDeclineButton component.
 */
export default function ProvDeclineButton({
    loading = false,
    setLoading,
    bookingId,
    getBookings,
    berthings,
    onDeclineEnd,
}: ProvDeclineButtonProps) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [declineModalOpen, setDeclineModalOpen] = useState(false);
    const [comment, setComment] = useState("");

    const handleDeclineBooking = async () => {
        setLoading(true);
        try {
            await changeBerthings(bookingId, berthings, false);
            onDeclineEnd?.();
            dispatch(showBackendMessage(intl, "success", "updating", Messages.booking));
        } catch {
            dispatch(showBackendMessage(intl, "error", "updating", Messages.booking));
        } finally {
            setLoading(false);
            setDeclineModalOpen(false);
            getBookings(bookingId);
        }
    };

    return (
        <>
            <Button
                variant="outlined"
                color="error"
                endIcon={<Close />}
                onClick={() => setDeclineModalOpen(true)}
                disabled={loading}
            >
                {intl.formatMessage(Messages.provisionallyDecline)}
            </Button>
            <Dialog open={declineModalOpen}>
                <DialogTitle>Decline comment</DialogTitle>
                <DialogContent>
                    <FormControl className="decline-modal-content">
                        <span>
                            If you wish you can add a comment for the declined booking.
                            <br />
                            It will not be shared with the user that requested the booking.
                        </span>
                        <TextField
                            value={comment}
                            label={intl.formatMessage(Messages.comment)}
                            onChange={e => setComment(e.target.value)}
                            variant="filled"
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDeclineModalOpen(false);
                            setComment("");
                        }}
                    >
                        Cancel
                    </Button>
                    <Button variant="outlined" onClick={handleDeclineBooking}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
