import { Moment } from "moment";
import { useEffect, useMemo, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { searchBerthings } from "../../../api/berthing";
import { showBackendMessage } from "../../../helpers/messagesHelper";
import Messages from "../../../localization/Messages";
import { IBerthing, berthingInit } from "../../../models/berthing";
import { IBooking } from "../../../models/booking";
import { searchParamsInit } from "../../../models/searchParams";
import { getDateMoment } from "../../../helpers/dateHelper";
import useBerthingsPlausabilityChecks from "./useBerthingsPlausabilityChecks";

export type BerthingConstraint = {
    minArrival?: Moment;
    maxArrival?: Moment;
    minDeparture?: Moment;
    maxDeparture?: Moment;
    predecessorDockId?: string;
    successorDockId?: string;
};
export type BerthingErrors = {
    arrivalTime?: string;
    departureTime?: string;
    dockId?: string;
};
export type BerthingErrorsList = BerthingErrors[];
export type BerthingConstraintList = BerthingConstraint[];

function getBerthingErrors(
    berthing: IBerthing,
    constraint: BerthingConstraint,
    intl: IntlShape,
): BerthingErrors {
    const {
        minArrival,
        maxArrival,
        minDeparture,
        maxDeparture,
        successorDockId,
        predecessorDockId,
    } = constraint;

    let arrivalTimeError: string | undefined = "";
    if (!berthing.arrivalTime) {
        arrivalTimeError = intl.formatMessage(Messages.inputValidData);
    } else if (minArrival && berthing.arrivalTime < minArrival.toDate().getTime()) {
        arrivalTimeError = intl.formatMessage(Messages.berthingArrivalMinError, {
            minArrival: minArrival.toString(),
        });
    } else if (maxArrival && berthing.arrivalTime > maxArrival.toDate().getTime()) {
        arrivalTimeError = intl.formatMessage(Messages.berthingArrivalMaxError, {
            maxArrival: maxArrival.toString(),
        });
    }

    let departureTimeError: string | undefined = "";
    if (!berthing.departureTime) {
        departureTimeError = intl.formatMessage(Messages.inputValidData);
    } else if (minDeparture && berthing.departureTime < minDeparture.toDate().getTime()) {
        departureTimeError = intl.formatMessage(Messages.berthingDepartureMinError, {
            minDeparture: minDeparture.toString(),
        });
    } else if (maxDeparture && berthing.departureTime > maxDeparture.toDate().getTime()) {
        departureTimeError = intl.formatMessage(Messages.berthingDepartureMaxError, {
            maxDeparture: maxDeparture.toString(),
        });
    }

    let dockIdError: string | undefined = "";
    if (!berthing.dockId) {
        dockIdError = intl.formatMessage(Messages.inputValidData);
    } else if ([successorDockId, predecessorDockId].includes(berthing.dockId)) {
        dockIdError = intl.formatMessage(Messages.berthingDockIdError);
    }

    return {
        arrivalTime: arrivalTimeError,
        departureTime: departureTimeError,
        dockId: dockIdError,
    };
}

function getBerthingConstraint(
    berthing: IBerthing | undefined,
    key: "arrivalTime" | "departureTime",
) {
    return typeof berthing?.[key] === "number" ? getDateMoment(berthing[key]) : undefined;
}

/**
 * Custom React hook for managing the state of berthings.
 *
 * @param booking - The booking object.
 * @returns A tuple containing the berthings state, setBerthings function, constraints, errors, isFormInvalid flag, and plausabilityErrorsList.
 */
export default function useBerthingsState(booking: IBooking) {
    const bookingId = booking.id;
    const dispatch = useDispatch();
    const intl = useIntl();
    const [berthings, setBerthings] = useState<IBerthing[]>([]);
    const eta = booking.reqArrivalTime;
    const etd = booking.reqDepartureTime;
    const plausabilityErrorsList = useBerthingsPlausabilityChecks({
        berthings,
        booking,
    });

    useEffect(() => {
        const fetchBerthings = async () => {
            try {
                const apiResult = await searchBerthings({
                    ...searchParamsInit,
                    filter: { bookingId },
                    order: ["arrivalTime asc"],
                });
                const data = apiResult.data.rows ?? [];
                if (data.length === 0) {
                    data.push({
                        ...berthingInit,
                        bookingId,
                        arrivalTime: eta,
                        departureTime: etd,
                    });
                }
                setBerthings(data);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.berthings));
            }
        };

        fetchBerthings();
    }, [bookingId, dispatch, intl, eta, etd]);

    const constraints: BerthingConstraintList = useMemo(
        () =>
            berthings.map((berthing, i, arr) => ({
                minArrival: getBerthingConstraint(arr[i - 1], "arrivalTime"),
                maxArrival: getBerthingConstraint(berthing, "departureTime"),
                minDeparture: getBerthingConstraint(berthing, "arrivalTime"),
                maxDeparture: getBerthingConstraint(arr[i + 1], "departureTime"),
                predecessorDockId: arr[i - 1]?.dockId,
                successorDockId: arr[i + 1]?.dockId,
            })),
        [berthings],
    );

    const errors: BerthingErrorsList = useMemo(
        () => constraints.map((constraint, i) => getBerthingErrors(berthings[i], constraint, intl)),
        [constraints, intl, berthings],
    );

    const isFormInvalid = errors.some(
        ({ arrivalTime, departureTime, dockId }) => arrivalTime || departureTime || dockId,
    );

    return [
        berthings,
        setBerthings,
        constraints,
        errors,
        isFormInvalid,
        plausabilityErrorsList,
    ] as const;
}
