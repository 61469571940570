import { useState, useEffect } from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { setDispAddressesFromRequest } from "../actions/dispAddressesAction";
import { setDocksFromRequest } from "../actions/docksAction";
import { fetchInitialFiltersAction } from "../actions/guiConfsActions";
import { setManagingCompaniesFromRequest } from "../actions/managingCompaniesActon";
import {
    getManagingCompanies,
    searchDestinationDisp,
    searchDestinations,
} from "../api/destinations";
import { searchAddress } from "../api/dispAddress";
import { searchDocks } from "../api/dock";
import { getDispatcherGuiConf, getDocksGuiConf } from "../api/guiConf";
import { showBackendMessage } from "../helpers/messagesHelper";
import Messages from "../localization/Messages";
import { searchParamsInit } from "../models/searchParams";
import { Roles } from "../models/userData";
import { GuiConfDispatcherState, GuiConfDocksState } from "../store/guiConfsReducer";
import { setDestinationZonesFromRequest } from "../actions/destinationsAction";
import { useAuth } from "./useAuth";
import { getServerSettings } from "../api/serverSettings";
import { setServerSettingsFromRequest } from "../actions/serverSettingsAction";
import { setUserRoles } from "../actions/authentificationActions";

export type UseAppStateLoadProps = {
    setIsExistingUser: (isExistingUser: boolean) => void;
};

/**
 * Custom React hook for loading the application state.
 *
 * @param {UseAppStateLoadProps} props - The hook props.
 * @returns {[boolean]} - A tuple containing a boolean value indicating whether the data is loaded.
 */
function useAppStateLoad({ setIsExistingUser }: UseAppStateLoadProps) {
    const { user, hasRole } = useAuth();

    const intl = useIntl();
    const dispatch = useDispatch();
    const [userLoaded, setUserLoaded] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        user.roles.length > 0 && setUserLoaded(true);
    }, [user]);

    const userRoleSatisfied = (role: Roles) => {
        if (user.roles.length === 0) {
            return false;
        }
        if (!user.roles.includes(role)) {
            setIsExistingUser(true);
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (user.roles.length === 0 || dataLoaded || !userLoaded) return;

        // prettier-ignore
        const loadData = async () => {
            try {
                const isOnCall = userRoleSatisfied(Roles.ON_CALL_DISPATCHER);
                const isDispatcher = userRoleSatisfied(Roles.PASSENGER_CABIN_CRUISES_DISPATCHER);
                const isDocks = userRoleSatisfied(Roles.LOCKS_AND_DOCKS_DISPATCHER);
                let managingCompaniesPromise = isDispatcher ? getManagingCompanies() : Promise.resolve(null);
                let docksPromise = isDispatcher ? searchDocks() : Promise.resolve(null);
                let addressesPromise = isDispatcher ? searchAddress() : Promise.resolve(null);
                const destinationParams = { ...searchParamsInit, filter: { companyId: user.companyId } };
                let destinationsPromise = isDispatcher ? searchDestinationDisp(destinationParams) : isDocks ? searchDestinations() : Promise.resolve(null);
                let guiConfsPromise = isDispatcher ? getDispatcherGuiConf() : isDocks ? getDocksGuiConf() : Promise.resolve(null);
                let serverSettingsPromise = (isDispatcher || isOnCall) ? getServerSettings() : Promise.resolve(null);

                const errorHandler = (message: MessageDescriptor) => 
                    () => dispatch(showBackendMessage(intl, "error", "fetching", message));

                managingCompaniesPromise = managingCompaniesPromise.catch(errorHandler(Messages.managingCompany)) as any;
                docksPromise = docksPromise.catch(errorHandler(Messages.docks)) as any;
                addressesPromise = addressesPromise.catch(errorHandler(Messages.address)) as any;
                destinationsPromise = destinationsPromise.catch(errorHandler(Messages.destinations)) as any;
                guiConfsPromise = guiConfsPromise.catch(errorHandler(Messages.guiConf)) as any;
                serverSettingsPromise = serverSettingsPromise.catch(errorHandler(Messages.serverSettings)) as any;

                const [
                    managingCompanies, 
                    docks, 
                    addresses, 
                    destinationsRes,
                    guiConfs,
                    serverSettings
                ] = await Promise.all([
                    managingCompaniesPromise, 
                    docksPromise, 
                    addressesPromise, 
                    destinationsPromise,
                    guiConfsPromise,
                    serverSettingsPromise
                ]);

                if (!hasRole(Roles.ON_CALL_DISPATCHER) && hasRole(Roles.PASSENGER_CABIN_CRUISES_DISPATCHER)){
                    dispatch(setUserRoles([...user.roles, Roles.PASSENGER_CABIN_CRUISES_DISPATCHER_ADMIN]));
                }

                const destinations = destinationsRes!.data.rows;
                setIsExistingUser(destinations.length !== 0);
                if (isDispatcher) {
                    dispatch(setDocksFromRequest(docks!.data.rows));
                    dispatch(setDispAddressesFromRequest(addresses!.data.rows));
                    dispatch(fetchInitialFiltersAction({ dispatcher: guiConfs as typeof GuiConfDispatcherState, docks: null as any }));
                    dispatch(setDestinationZonesFromRequest(destinations));
                    dispatch(setManagingCompaniesFromRequest(managingCompanies!.data));
                    dispatch(setServerSettingsFromRequest(serverSettings!.data));
                } else if (isDocks) {
                    dispatch(fetchInitialFiltersAction({ docks: guiConfs as typeof GuiConfDocksState, dispatcher: null as any }));
                    dispatch(setDestinationZonesFromRequest(destinations));
                }
            } catch (error) {
                showBackendMessage(intl, "error", "fetching", Messages.data);
            } finally {
                setDataLoaded(true);
            }
        };

        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, userLoaded, dataLoaded, dispatch, intl]);

    return [dataLoaded] as const;
}

export default useAppStateLoad;
