import Messages from "../../localization/Messages";
import { useState, useEffect, useCallback } from "react";
import RpisPage from "../RpisPage/RpisPage";
import { useIntl } from "react-intl";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
} from "@mui/material";
import {
    DispatcherNotifications,
    INotificationSettingsDispatcher,
} from "../../models/notification";
import Checkbox from "@mui/material/Checkbox";
import { changeUser, getUser } from "../../api/dispUser";
import { useDispatch } from "react-redux";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { IDispUser, dispUserInit } from "../../models/user";
import { useAuth } from "../../hooks/useAuth";
import "./DispNotificationSettings.css";

/**
 * Represents the Notification Settings page for Dispatcher.
 * The user can select how or if they want to be notified for particular topics.
 */

export const DispNotificationSettings = () => {
    const intl = useIntl();
    const [smsTopics, setSmsTopics] = useState<DispatcherNotifications[]>([]);
    const [emailTopics, setEmailTopics] = useState<DispatcherNotifications[]>([]);
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState<IDispUser>(dispUserInit);
    const dispatch = useDispatch();

    const fetchUser = useCallback(async () => {
        if (!user.userName) return;
        try {
            setLoading(true);
            const res = await getUser(user.userName);
            setUserData(res.data);
            setSmsTopics(res.data.notifications?.smsTopics ?? []);
            setEmailTopics(res.data.notifications?.emailTopics ?? []);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.notificationSettings));
        } finally {
            setLoading(false);
        }
    }, [dispatch, intl, user.userName]);

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    const handleChangeUser = async () => {
        try {
            const notifications = {
                ...userData.notifications,
                smsTopics,
                emailTopics,
            } as INotificationSettingsDispatcher;
            await changeUser({ ...userData, notifications: notifications });
            dispatch(
                showBackendMessage(intl, "success", "updating", Messages.notificationSettings),
            );
        } catch {
            dispatch(showBackendMessage(intl, "error", "updating", Messages.user));
        }
    };

    /*
    const handleChangeSmsTopics = (topic: DispatcherNotifications) => {
        setSmsTopics(prevValue =>
            !prevValue.includes(topic)
                ? [...prevValue, topic]
                : [...prevValue.filter(nt => nt !== topic)],
        );
    };
    */

    const handleChangeEmailTopics = (topic: DispatcherNotifications) => {
        setEmailTopics(prevValue =>
            !prevValue.includes(topic)
                ? [...prevValue, topic]
                : [...prevValue.filter(nt => nt !== topic)],
        );
    };

    const getMessageForTopic = (topic: DispatcherNotifications) => {
        switch (topic) {
            case DispatcherNotifications.bookingRequested:
                return Messages.newBookingRequest;

            case DispatcherNotifications.booking_canceled:
                return Messages.bookingCanceled;

            case DispatcherNotifications.booking_change_requested:
                return Messages.bookingChangeRequested;

            case DispatcherNotifications.chatText:
                return Messages.chatMessage;

            case DispatcherNotifications.bookingShipNew:
                return Messages.newShipAdded;

            case DispatcherNotifications.bookingShipDuplicateEni:
                return Messages.bookingShipDuplicateEni;

            case DispatcherNotifications.bookingShipUpdate:
                return Messages.updatedShipData;

            case DispatcherNotifications.shipCertExpired:
                return Messages.shipCertExpired;

            case DispatcherNotifications.addressInUseChanged:
                return Messages.addressInUseChanged;

            case DispatcherNotifications.voyageDataUpdated:
                return Messages.voyageDataUpdated;

            case DispatcherNotifications.bookingChangeConfirmed:
                return Messages.bookingChangeConfirmed;

            case DispatcherNotifications.bookingConsumptionNew:
                return Messages.newConsumptionExpense;

            case DispatcherNotifications.bookingCommentChanged:
                return Messages.bookingCommentChanged;
        }
    };

    return (
        <RpisPage
            title={intl.formatMessage(Messages.notificationSettings)}
            className="notification-settings-container"
        >
            <div className="edit-settings-container">
                <h2>
                    {`${intl.formatMessage(Messages.edit)} 
                    ${intl.formatMessage(Messages.notificationSettings)}`}
                </h2>
                <form className="column-form">
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    {/*}
                                    <TableCell>{intl.formatMessage(Messages.sms)}</TableCell>
                                    */}
                                    <TableCell>{intl.formatMessage(Messages.email)}</TableCell>
                                </TableRow>
                            </TableHead>
                            {!loading && (
                                <TableBody>
                                    {Object.values(DispatcherNotifications).map((topic, key) => (
                                        <TableRow key={key}>
                                            <TableCell>
                                                {intl.formatMessage(
                                                    getMessageForTopic(topic) ?? Messages.unknown,
                                                )}
                                            </TableCell>
                                            {/* 
                                              SMS topics temporarily unavailable
                                              <TableCell>
                                                <Checkbox
                                                    checked={
                                                        smsTopics.find(
                                                            (t: DispatcherNotifications) =>
                                                                t === topic,
                                                        )
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={() => handleChangeSmsTopics(topic)}
                                                />
                                                </TableCell> */}
                                            <TableCell>
                                                <Checkbox
                                                    checked={
                                                        emailTopics.find(
                                                            (t: DispatcherNotifications) =>
                                                                t === topic,
                                                        )
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={() => handleChangeEmailTopics(topic)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <Button variant="contained" onClick={handleChangeUser}>
                        {intl.formatMessage(Messages.save)}
                    </Button>
                </form>
            </div>
        </RpisPage>
    );
};

export default DispNotificationSettings;
