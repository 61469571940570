import { Autocomplete, Box, Tooltip } from "@mui/material";
import { useState } from "react";
import { IBerthing } from "../../../models/berthing";
import { IBooking } from "../../../models/booking";
import { IDock } from "../../../models/dock";
import TextAction from "../../TextAction";
import { BerthingConstraint } from "../hooks/useBerthingsState";
import { WarningAmberRounded } from "@mui/icons-material";
import { useCachedDocks } from "../../../hooks/useCachedDocks";

/**
 * Props for the DockInput component.
 */
export interface DockInputProps {
    dockId: string; // ID of the selected dock
    loading: boolean; // Indicates if the component is in a loading state
    booking: IBooking; // The booking associated with the dock input
    error?: string; // Error message to display
    onChange: (dockId: string) => void; // Callback function when the dock selection changes
    constraint: BerthingConstraint; // Berthing constraint for the dock input
    plausabilityErrors?: string[]; // Array of plausibility errors
}

/**
 * Errors for the Berthing object.
 */
export type BerthingErrors = {
    [Key in keyof Partial<IBerthing>]: string;
};

/**
 * DockInput component.
 * Allows the user to select a dock from a list of available docks.
 * Displays plausibility errors and provides a callback when the dock selection changes.
 */
export default function DockInput({
    dockId,
    loading,
    booking,
    error = "",
    onChange,
    constraint,
    plausabilityErrors = [],
}: DockInputProps) {
    const { docks, byId } = useCachedDocks();
    const [open, setOpen] = useState(false);
    const dockName = byId.get(dockId)?.name || "";

    return (
        <Autocomplete
            className="dock-menu-input timeline-gutter branch-left"
            id="dock-autocomplete-respond-form"
            options={docks}
            getOptionLabel={o => o.name}
            isOptionEqualToValue={(a, b) => a.id === b.id}
            value={byId.get(dockId) ?? null}
            disabled={loading}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            renderInput={params => (
                <>
                    <TextAction inputRef={params.InputProps.ref} open={open} error={error}>
                        {plausabilityErrors.length > 0 && (
                            <Tooltip
                                slotProps={{ tooltip: { sx: { fontSize: "0.9em" } } }}
                                title={
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        gap={0.75}
                                        padding={0.75}
                                    >
                                        {plausabilityErrors.map(error => (
                                            <span key={error}>{error}</span>
                                        ))}
                                    </Box>
                                }
                            >
                                <WarningAmberRounded color="warning" />
                            </Tooltip>
                        )}
                        <input
                            {...params.inputProps}
                            type="text"
                            placeholder="Select dock"
                            value={dockName}
                            onChange={() => {}}
                            className={`${params.inputProps.className} berthing-dock-input`}
                        />
                    </TextAction>
                </>
            )}
            onChange={(_, value: IDock | null) => onChange(value?.id ?? "")}
            renderOption={({ onClick, className, ...props }, option) => {
                const optId = option.id;
                const optArea = option.area;
                const areaPreference = booking?.areaPreference;
                const predecessorDockId = constraint.predecessorDockId;
                const successorDockId = constraint.successorDockId;
                const disabled = [predecessorDockId, successorDockId].includes(optId);
                const disabledClass = disabled ? "dock-menu-item-disabled" : "";
                const preferenceClass =
                    optArea === areaPreference ? "dock-menu-item-area-prefered" : "";
                return (
                    <li
                        {...props}
                        key={optId}
                        onClick={disabled ? undefined : onClick}
                        className={`${className} ${disabledClass}`}
                    >
                        <div className={preferenceClass}>{option.name}</div>
                    </li>
                );
            }}
        />
    );
}
