import { INotificationSettingsDispatcher, INotificationSettingsDocks } from "./notification";

export interface IDispUser {
    userId: string;
    email: string;
    notifications?: INotificationSettingsDispatcher;
    tel: string;
}

export interface IDocksUser {
    userId: string;
    email: string;
    notifications?: INotificationSettingsDocks;
    tel: string;
}

export const dispUserInit: IDispUser = {
    userId: "",
    email: "",
    tel: "",
};

export const docksUserInit: IDocksUser = {
    userId: "",
    email: "",
    tel: "",
};
