import { Button, Divider, TextField, Autocomplete, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import Messages from "../../../../localization/Messages";
import {
    AddressType,
    dispatcherAddressInit,
    IAddress,
    IDispatcherAddress,
} from "../../../../models/address";
import { showBackendMessage } from "../../../../helpers/messagesHelper";
import { useDispatch } from "react-redux";
import { addAddress, changeAddress } from "../../../../api/dispAddress";
import { AddressDataContainer } from "../AddressDataContainer/AddressDataContainer";
import {
    getCountryNames,
    getCountryDialCode,
} from "../../../../localization/country-names/countryNames";
import { AxiosResponse } from "axios";
import { useAuth } from "../../../../hooks/useAuth";

interface IInternalAddressForm {
    selectedAddress?: IDispatcherAddress;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    callback: (address: IAddress) => void;
}

type AddressErrors = { [Key in keyof Partial<IDispatcherAddress>]: string };

const validationRegex = {
    tel: /^\+?[0-9-/ ]*$/,
};

/**
 * Represents the Internal address form displayed in the sidebar of the Manage Addresses page for the dispatcher.
 * It is used for adding new internal invoice addresses and editing existing ones.
 * In case the address is not internal , the user can only edit additional data for that address and instead of the form, the data is displayed in `AddressDataContainer`.
 */

export const InternalAddressForm = ({
    selectedAddress,
    setSidebarOpen,
    callback,
}: IInternalAddressForm) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [address, setAddress] = useState<IDispatcherAddress>(dispatcherAddressInit);
    const [errors, setErrors] = useState<AddressErrors>({});
    const { user } = useAuth();

    const countries = getCountryNames(user?.locale);

    const onChange = (diff: Partial<IDispatcherAddress>) => {
        setAddress({ ...address, ...diff });
        const clearErrors = Object.fromEntries(Object.entries(diff).map(([k]) => [k, undefined]));
        setErrors({ ...errors, ...clearErrors });
    };

    useEffect(() => {
        if (address.country) {
            const preCode = getCountryDialCode(user.locale, address.country);
            const dialCode = preCode ? (preCode.startsWith("+") ? preCode : `+${preCode}`) : "";
            dialCode &&
                !address.tel?.startsWith(dialCode) &&
                setAddress({
                    ...address,
                    tel: dialCode ?? "",
                });
        }
    }, [address, user.locale]);

    const validateAddress = () => {
        const newErrors: AddressErrors = {};
        if (!address.company) {
            newErrors.company = intl.formatMessage(Messages.companyNameIsRequired);
        }
        if (!address.street) {
            newErrors.street = intl.formatMessage(Messages.streetIsRequired);
        }
        if (!address.streetNo) {
            newErrors.streetNo = intl.formatMessage(Messages.streetNoIsRequired);
        }
        if (!address.city) {
            newErrors.city = intl.formatMessage(Messages.cityIsRequired);
        }
        if (!address.country) {
            newErrors.country = intl.formatMessage(Messages.countryIsRequired);
        }
        return newErrors;
    };

    useEffect(() => {
        setAddress(selectedAddress ? { ...selectedAddress } : dispatcherAddressInit);
    }, [selectedAddress]);

    const handleSubmit = async () => {
        let newErrors = {};
        if (selectedAddress?.internal === true || !selectedAddress?.id) {
            newErrors = validateAddress();
        }
        if (Object.keys(newErrors).length) {
            setErrors(newErrors);
        } else {
            try {
                let apiResponse: AxiosResponse<IAddress, any>;
                if (selectedAddress) {
                    apiResponse = await changeAddress(address);
                    dispatch(showBackendMessage(intl, "success", "updating", Messages.address));
                } else {
                    apiResponse = await addAddress({
                        ...address,
                        type: AddressType.InvoiceAddress,
                    });
                    dispatch(showBackendMessage(intl, "success", "adding", Messages.address));
                }
                setSidebarOpen(false);
                callback(apiResponse.data);
                onChange(dispatcherAddressInit);
            } catch {
                dispatch(
                    showBackendMessage(
                        intl,
                        "error",
                        selectedAddress ? "updating" : "adding",
                        Messages.address,
                    ),
                );
            }
        }
    };

    return (
        <div className="internal-address-form">
            {!selectedAddress || (selectedAddress && selectedAddress.internal === true) ? (
                <Grid container alignItems={"center"} marginBottom={2} spacing={2}>
                    <Grid item sm={6}>
                        <TextField
                            required
                            error={Boolean(errors.company)}
                            value={address.company ?? ""}
                            label={intl.formatMessage(Messages.companyName)}
                            onChange={e => onChange({ company: e.target.value })}
                            helperText={errors.company}
                            variant="filled"
                            InputProps={{ readOnly: Boolean(selectedAddress?.deleted) }}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <TextField
                            value={address.addition ?? ""}
                            label={intl.formatMessage(Messages.addition)}
                            onChange={e => onChange({ addition: e.target.value })}
                            variant="filled"
                            InputProps={{ readOnly: Boolean(selectedAddress?.deleted) }}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <TextField
                            required
                            error={Boolean(errors.street)}
                            value={address.street ?? ""}
                            label={intl.formatMessage(Messages.street)}
                            onChange={e => onChange({ street: e.target.value })}
                            helperText={errors.street}
                            variant="filled"
                            InputProps={{ readOnly: Boolean(selectedAddress?.deleted) }}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <TextField
                            required
                            error={Boolean(errors.streetNo)}
                            value={address.streetNo ?? ""}
                            label={intl.formatMessage(Messages.streetNo)}
                            onChange={e => onChange({ streetNo: e.target.value })}
                            helperText={errors.streetNo}
                            variant="filled"
                            InputProps={{ readOnly: Boolean(selectedAddress?.deleted) }}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <TextField
                            required
                            error={Boolean(errors.zip)}
                            value={address.zip ?? ""}
                            label={intl.formatMessage(Messages.zip)}
                            onChange={e => onChange({ zip: e.target.value })}
                            helperText={errors.zip}
                            variant="filled"
                            InputProps={{ readOnly: Boolean(selectedAddress?.deleted) }}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <TextField
                            required
                            error={Boolean(errors.city)}
                            value={address.city ?? ""}
                            label={intl.formatMessage(Messages.city)}
                            onChange={e => onChange({ city: e.target.value })}
                            helperText={errors.city}
                            variant="filled"
                            InputProps={{ readOnly: Boolean(selectedAddress?.deleted) }}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <Autocomplete
                            id="select-country-field"
                            options={countries.sort((a, b) => {
                                return a.localeCompare(b);
                            })}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    required
                                    variant="filled"
                                    label={intl.formatMessage(Messages.country)}
                                    error={Boolean(errors.country)}
                                    helperText={errors.country}
                                    InputProps={{ readOnly: Boolean(selectedAddress?.deleted) }}
                                />
                            )}
                            renderOption={(props, option) => <li {...props}>{option}</li>}
                            onChange={(_, value: string | null) =>
                                onChange({ country: value ?? undefined })
                            }
                            value={countries.find(co => co === address.country) ?? null}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <TextField
                            error={Boolean(errors.firstName)}
                            value={address.firstName ?? ""}
                            label={intl.formatMessage(Messages.firstName)}
                            onChange={e => onChange({ firstName: e.target.value })}
                            helperText={errors.firstName}
                            variant="filled"
                            InputProps={{ readOnly: Boolean(selectedAddress?.deleted) }}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <TextField
                            error={Boolean(errors.surname)}
                            value={address.surname ?? ""}
                            label={intl.formatMessage(Messages.surname)}
                            onChange={e => onChange({ surname: e.target.value })}
                            helperText={errors.surname}
                            variant="filled"
                            InputProps={{ readOnly: Boolean(selectedAddress?.deleted) }}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <TextField
                            error={Boolean(errors.tel)}
                            value={address.tel ?? ""}
                            label={intl.formatMessage(Messages.telephone)}
                            onChange={e => {
                                validationRegex.tel.test(e.target.value) &&
                                    onChange({ tel: e.target.value });
                            }}
                            helperText={errors.tel}
                            variant="filled"
                            InputProps={{ readOnly: Boolean(selectedAddress?.deleted) }}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <TextField
                            error={Boolean(errors.email)}
                            value={address.email ?? ""}
                            label={intl.formatMessage(Messages.email)}
                            onChange={e => onChange({ email: e.target.value })}
                            helperText={errors.email}
                            variant="filled"
                            InputProps={{ readOnly: Boolean(selectedAddress?.deleted) }}
                        />
                    </Grid>
                </Grid>
            ) : (
                selectedAddress && <AddressDataContainer address={selectedAddress} />
            )}
            <Divider />

            <>
                <h3>{intl.formatMessage(Messages.additionalInformation)}</h3>
                <Grid container alignItems={"center"} marginBottom={2} spacing={2}>
                    <Grid item sm={6}>
                        <TextField
                            value={address.accountRecNumber ?? ""}
                            label={intl.formatMessage(Messages.accountsReceivableNumber)}
                            onChange={e => onChange({ accountRecNumber: e.target.value })}
                            variant="filled"
                            disabled={Boolean(selectedAddress?.deleted)}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <TextField
                            value={address.vatNumber ?? ""}
                            label={intl.formatMessage(Messages.vatNumber)}
                            onChange={e => onChange({ vatNumber: e.target.value })}
                            variant="filled"
                            disabled={Boolean(selectedAddress?.deleted)}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <TextField
                            value={address.accountingInfo}
                            label={intl.formatMessage(Messages.accountingInformation)}
                            onChange={e => onChange({ accountingInfo: e.target.value })}
                            variant="filled"
                            disabled={Boolean(selectedAddress?.deleted)}
                        />
                    </Grid>
                </Grid>
                <Button
                    variant="contained"
                    className="submit-address-form-button button-primary"
                    onClick={() => handleSubmit()}
                    disabled={Boolean(selectedAddress?.deleted)}
                >
                    {selectedAddress
                        ? intl.formatMessage(Messages.save)
                        : intl.formatMessage(Messages.addAddress)}
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => setSidebarOpen(false)}
                    className="cancel-button"
                >
                    {intl.formatMessage(Messages.cancel)}
                </Button>
            </>
        </div>
    );
};
