import { useState } from "react";
import { useIntl } from "react-intl";
import Messages from "../../../../localization/Messages";
import { Box, List, ListItem, Popper, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { TooltipField } from "../BookingDetails/TooltipField";
import { useCachedDocks } from "../../../../hooks/useCachedDocks";

interface IDockPopper {
    dockIds: string[];
}
/**
 * Displays the name of the dock or a "Multiple" sign that when hovered over displays the names of the multiple docks in a popper.
 *
 * @component
 * @param {string[]} dockIds - The array of dock IDs.
 */
export const DockPopper = ({ dockIds }: IDockPopper) => {
    const intl = useIntl();
    const { docks } = useCachedDocks();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            {dockIds.length > 1 ? (
                <Box
                    aria-owns={open ? "mouse-over-popover-docks" : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                >
                    <Box gap={"5px"} display={"flex"} alignContent={"center"}>
                        <Typography display="inline-block" fontSize={"13px"}>
                            {intl.formatMessage(Messages.multiple)}
                        </Typography>
                        <InfoOutlined fontSize={"small"} color="primary" />
                    </Box>

                    <Popper
                        className="z-1202"
                        id="mouse-over-popover-docks"
                        open={open}
                        anchorEl={anchorEl}
                    >
                        <List className="popper-docks-list">
                            {docks
                                .filter(dock => dockIds.includes(dock.id))
                                .map(dock => (
                                    <ListItem key={dock.id}>
                                        <Typography>{dock.name}</Typography>
                                    </ListItem>
                                ))}
                        </List>
                    </Popper>
                </Box>
            ) : (
                <TooltipField title={docks.find(dock => dockIds.includes(dock.id))?.name ?? ""} />
            )}
        </>
    );
};

export default DockPopper;
