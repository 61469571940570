import { Palette } from "@mui/material";
import { Action, TreeNode } from "../utils";

export type Color = "disabled" | "error" | "success" | "warning";

export const ActionColor: Record<Action, Color> = {
    NONE: "disabled",
    DELETE: "error",
    CREATE: "success",
    UPDATE: "warning",
};

export const MIN_DOTS_COUNT = 3;
export const TREE_VIEW_CONTAINER_STYLES = { maxWidth: undefined };
export const TREE_VIEW_STYLES = { overflowX: "hidden" };
export const MONOSPACE_FONT_FAMILY = "'Courier New', Courier, monospace !important";
export const BORDER_RADIUS_STYLE = { borderRadius: "4px" };
export const PADDING_INLINE_STYLE = { paddingInline: "6px" };
export const LINE_THROUGH_COLOR = "#d32f2f";
export const OLD_VALUE_COLOR = "rgba(0, 0, 0, 0.40)";

export const NodeItemBackgroundColors: Record<Action, string> = {
    NONE: "inherit",
    CREATE: "#e0ffe0",
    UPDATE: "#ffedcb",
    DELETE: "#ffe0e0",
};

export const NodeItemValueBackgroundColors: Record<Action, string> = {
    NONE: "inherit",
    CREATE: "#8eff8e",
    UPDATE: "#ffbd43",
    DELETE: "#ffb9b9",
};

export function getActionTextColor(node: TreeNode, palette: Palette) {
    return node.hasChanged
        ? palette.text.primary
        : /*palette.action.disabled*/ palette.text.primary;
}

export function getLatestKey(fullPath: string) {
    return fullPath.substring(fullPath.lastIndexOf(".") + 1);
}
