import { Box } from "@mui/material";
import { IBooking } from "../../../../models/booking";
import { useIntl } from "react-intl";
import Messages from "../../../../localization/Messages";
import { useCallback, useEffect } from "react";
import { IInvoice } from "../../../../models/invoice";
import { getBooking } from "../../../../api/booking";
import { useDispatch } from "react-redux";
import { showBackendMessage } from "../../../../helpers/messagesHelper";
import { displayStatus } from "../../../../helpers/invoiceHelper";
import { useSidebarOwner } from "../../../../contexts/SidebarOwnerContext";

type InvoiceSidebarTitleProps = {
    invoice: IInvoice | undefined;
};

export const InvoiceSidebarTitle = ({ invoice }: InvoiceSidebarTitleProps) => {
    const [booking, setBooking] = useSidebarOwner<IBooking>();
    const dispatch = useDispatch();
    const intl = useIntl();
    //const [commentDialogOpen, setCommentDialogOpen] = useState(false);
    //const [sharedRemarkDialogOpen, setSharedRemarkDialogOpen] = useState(false);

    const fetchBooking = useCallback(async () => {
        if (!invoice?.bookingId) return;
        try {
            const res = await getBooking(String(invoice.bookingId));
            setBooking(res.data);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.booking));
        }
    }, [dispatch, intl, invoice, setBooking]);

    useEffect(() => {
        fetchBooking();
    }, [fetchBooking]);

    /*
    const onClose = () => {
        setCommentDialogOpen(false);
        setSharedRemarkDialogOpen(false);
    };
    */

    return (
        <>
            <Box display="flex" gap={1} alignItems="center" justifyContent={"space-between"}>
                <Box>
                    <span>
                        {booking
                            ? intl.formatMessage(Messages.bookingDetails)
                            : invoice && !invoice.bookingId
                            ? intl.formatMessage(Messages.editInvoice) +
                              " #" +
                              invoice.internalInvoiceNumber +
                              " - " +
                              displayStatus(invoice.invoiceStatus, intl)
                            : intl.formatMessage(Messages.createInvoice)}
                    </span>
                </Box>

                {/*
                Remark icons moved to Booking Details component
                booking && (
                    <Box display={"inline-flex"}>
                        <Tooltip
                            key={booking.comment}
                            title={`${intl.formatMessage(Messages.editDispatcherRemark)}${
                                booking.comment ? `: ${booking.comment}` : ""
                            }`}
                        >
                            <IconButton
                                onClick={() => setCommentDialogOpen(true)}
                                className="comment-icon"
                            >
                                <Comment />
                            </IconButton>
                        </Tooltip>

                        <Tooltip
                            key={booking.sharedRemark}
                            title={`${intl.formatMessage(Messages.editSharedRemark)}${
                                booking.sharedRemark ? `: ${booking.sharedRemark}` : ""
                            }`}
                        >
                            <IconButton
                                onClick={() => setSharedRemarkDialogOpen(true)}
                                className="shared-remark-icon"
                            >
                                <Comment />
                            </IconButton>
                        </Tooltip>
                    </Box>
                ) */}
            </Box>
            {/*
             <RpisDialog
                fullWidth
                size="sm"
                dialogOpen={commentDialogOpen || sharedRemarkDialogOpen}
                title={`${intl.formatMessage(
                    sharedRemarkDialogOpen
                        ? Messages.editSharedRemark
                        : Messages.editDispatcherRemark,
                )} #${booking?.id}`}
                onClose={onClose}
                content={
                    <BookingRemarkDialog
                        onClose={onClose}
                        reloadBookings={fetchBooking}
                        remarkType={
                            sharedRemarkDialogOpen
                                ? BOOKING_REMARK_TYPE.SHARED_REMARK
                                : commentDialogOpen
                                ? BOOKING_REMARK_TYPE.COMMENT
                                : undefined
                        }
                    />
                }
            />
            */}
        </>
    );
};

export default InvoiceSidebarTitle;
