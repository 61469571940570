import { ReactNode } from "react";
import Messages from "../../../localization/Messages";
import { DocumentViewer } from "../components/DocumentViewer";
import FileIcon from "../components/DocumentLabel/icons/FileIcon";
import WordIcon from "../components/DocumentLabel/icons/WordIcon";
import PdfIcon from "../components/DocumentLabel/icons/PdfIcon";
import VideoIcon from "../components/DocumentLabel/icons/VideoIcon";
import ImageIcon from "../components/DocumentLabel/icons/ImageIcon";
import DocumentIcon from "../components/DocumentLabel/icons/DocumentIcon";
import AudioIcon from "../components/DocumentLabel/icons/AudioIcon";

export type MimeTypeMetadataRender = {
    icon: ReactNode;
    labelTranslationKey: keyof typeof Messages;
    preview?: (src: string, mimeType: string) => ReactNode;
};

export function getMimeTypeMetadataRender(mimeType: string): MimeTypeMetadataRender {
    for (const [key, value] of Object.entries(MIME_TYPE_METADATA_RENDER_MAP)) {
        if (new RegExp(key).test(mimeType)) {
            return value;
        }
    }
    return {
        icon: <FileIcon />,
        labelTranslationKey: "other",
    };
}

const REACT_DOC_VIEWER_PREVIEW = (downloadUrl: string, mimeType: string) => (
    <DocumentViewer src={downloadUrl} mimeType={mimeType} />
);

/**
 * A map of MIME types to their respective metadata.
 * @description The key may be a literal value or a regular expression.
 * @see {@link https://www.freeformatter.com/mime-types-list.html}
 */
type MimeTypeMetadataRenderMap = Record<string, MimeTypeMetadataRender>;

const MIME_TYPE_METADATA_RENDER_MAP = {
    "^audio/": {
        labelTranslationKey: "audio",
        icon: <AudioIcon />,
    },
    "^text/plain$": {
        labelTranslationKey: "text",
        icon: <DocumentIcon />,
        preview: REACT_DOC_VIEWER_PREVIEW,
    },
    "^image/": {
        labelTranslationKey: "image",
        icon: <ImageIcon />,
        preview: REACT_DOC_VIEWER_PREVIEW,
    },
    "^video/": {
        labelTranslationKey: "video",
        icon: <VideoIcon />,
        preview: (downloadUrl, mimeType) => (
            <video width="100%" controls>
                <source src={downloadUrl} type={mimeType} />
            </video>
        ),
    },
    "application/pdf": {
        labelTranslationKey: "pdf",
        icon: <PdfIcon />,
        preview: downloadUrl => <iframe src={downloadUrl} width="100%" height="100%"></iframe>,
    },
    "^application/.*(msword|wordprocessingml)": {
        labelTranslationKey: "word",
        icon: <WordIcon />,
        preview: REACT_DOC_VIEWER_PREVIEW,
    },
    "^application/vnd.ms-excel": {
        labelTranslationKey: "excel",
        icon: <DocumentIcon />,
        preview: REACT_DOC_VIEWER_PREVIEW,
    },
    "^application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": {
        labelTranslationKey: "excel",
        icon: <DocumentIcon />,
        preview: REACT_DOC_VIEWER_PREVIEW,
    },
    "^text/csv": {
        labelTranslationKey: "csv",
        icon: <DocumentIcon />,
    },
    ".*": {
        labelTranslationKey: "other",
        icon: <FileIcon />,
    },
} as const satisfies MimeTypeMetadataRenderMap;
