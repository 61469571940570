export const SET_USER_FROM_TOKEN = "SET_USER_FROM_TOKEN";
export const SET_USER_ROLES = "SET_USER_ROLES";

export interface SetUserAction {
    type: typeof SET_USER_FROM_TOKEN;
    token: string;
}

export interface SetUserRolesAction {
    type: typeof SET_USER_ROLES;
    roles: string[];
}

export type AuthentificationActionType = SetUserAction | SetUserRolesAction;

/**
 * Sets the user from the token.
 * @param token - The token to set the user from.
 * @returns The action to execute.
 */
export function setUserFromToken(token: string): AuthentificationActionType {
    return { type: SET_USER_FROM_TOKEN, token };
}

export function setUserRoles(roles: string[]): SetUserRolesAction {
    return { type: SET_USER_ROLES, roles };
}
