import { Button } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import Messages from "../../localization/Messages";
import { berthingInit } from "../../models/berthing";
import { IBooking } from "../../models/booking";
import BerthingInputList from "./components/BerthingInputList";
import ProvDeclineButton from "./components/ProvDeclineButton";
import ProvPlanButton from "./components/ProvPlanButton";
import useBerthingsDateBoundary from "./hooks/useBerthingsDateBoundary";
import useStateBerthings from "./hooks/useBerthingsState";
import "./index.css";
import { BerthingButtonMenuGroup } from "./components/BerthingButtonMenuGroup";
import { Add } from "@mui/icons-material";

export type BerthingsFormProps = {
    booking: IBooking;
    getBookings: (bookingId: number) => Promise<void>;
    sendBookingResponses?: (id?: number) => Promise<void>;
};

/**
*The `BerthingsForm` component is an integral part of the `BookingRespondForm` component.
*It represents a form component for managing berthings and sending booking responses for a particular booking.
*This component allows users to add, remove, and modify berthings for a booking.
*It provides buttons for creating additional berthings, planning berthings, and declining berthings.
*In case that property `sendBookingResponses` is passed down to `BerthingsForm` Plan and Cancel
*berthing buttons are replaced with a button group that includes both
*those actions and an additional action which is to send allows user to send a booking response directly
*from the component.
*The component also displays a list of berthings that also acts as a form with inputs for arrival and
*departure time and a dock input.
*The list handles form validation and submission.

@component
@example
// Example usage of BerthingsForm component
<BerthingsForm
    booking={booking}
    currentDate={currentDate}
    getBookings={getBookings}
    sendBookingResponses={sendBookingResponses}
    />

@param {BerthingsFormProps} props - The props for the BerthingsForm component.
@param {IBooking} props.booking - The booking object for which berthings are managed.
@param {Date} props.currentDate - The current date.
@param {Function} props.getBookings - A callback function to fetch bookings.
@param {Function} [props.sendBookingResponses] - A function to send booking responses.
@returns {JSX.Element} The rendered BerthingsForm component. 
*/

export default function BerthingsForm({
    booking,
    getBookings,
    sendBookingResponses,
}: BerthingsFormProps) {
    const [loading, setLoading] = useState(false);
    const bookingId = booking.id;
    const intl = useIntl();
    const [berthings, setBerthings, constraints, errors, isFormInvalid, plausabilityErrorsList] =
        useStateBerthings(booking);
    const latestDepartureTime = useBerthingsDateBoundary(berthings, "departureTime");

    const generateNewBerthingInput = () => {
        setBerthings(value => [
            ...value,
            {
                ...berthingInit,
                bookingId: booking.id,
                arrivalTime: value.length === 0 ? booking.reqArrivalTime : latestDepartureTime,
                departureTime: value.length === 0 ? booking.reqDepartureTime : latestDepartureTime,
            },
        ]);
    };

    const removeBerthingByIndex = (index: number) => {
        setBerthings(curr =>
            [...curr]
                .map((b, i, arr) =>
                    i === index + 1
                        ? {
                              ...b,
                              arrivalTime: arr[index - 1]?.departureTime ?? b.arrivalTime,
                              dockId: arr[i]?.dockId
                                  ? arr[index - 1]?.dockId === arr[index + 1]?.dockId
                                      ? ""
                                      : b.dockId
                                  : "",
                          }
                        : b,
                )
                .filter((_, i) => i !== index),
        );
    };

    const onDeclineEnd = () => {
        setBerthings(berthings => berthings.map(b => ({ ...b, dockId: "" })));
    };

    return (
        <form className="respond-form">
            <div className="booking-respond-form-container">
                <div className="booking-actions">
                    <Button
                        variant="outlined"
                        color="primary"
                        endIcon={<Add />}
                        onClick={generateNewBerthingInput}
                        disabled={loading}
                    >
                        {intl.formatMessage(Messages.createAdditionalBerthing)}
                    </Button>
                    {sendBookingResponses ? (
                        <BerthingButtonMenuGroup
                            berthings={berthings}
                            isFormInvalid={isFormInvalid}
                            getBookings={getBookings}
                            bookingId={bookingId}
                            bookingStatus={booking.status}
                            setLoading={setLoading}
                            onDeclineEnd={onDeclineEnd}
                            loading={loading}
                            sendBookingResponses={sendBookingResponses}
                        />
                    ) : (
                        <>
                            <ProvPlanButton
                                berthings={berthings}
                                isFormInvalid={isFormInvalid}
                                getBookings={getBookings}
                                bookingId={bookingId}
                                bookingStatus={booking.status}
                            />
                            <ProvDeclineButton
                                bookingId={bookingId}
                                getBookings={getBookings}
                                onDeclineEnd={onDeclineEnd}
                                berthings={berthings}
                                setLoading={setLoading}
                                loading={loading}
                            />
                        </>
                    )}
                </div>
                <BerthingInputList
                    plausabilityErrorsList={plausabilityErrorsList}
                    errorsList={errors}
                    constraints={constraints}
                    berthings={berthings}
                    booking={booking}
                    loading={loading}
                    setBerthings={setBerthings}
                    onRemove={removeBerthingByIndex}
                />
            </div>
        </form>
    );
}
