import { ReactNode, useEffect, useRef, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { searchMessage, addMessage, searchMessageDisp, addMessageDisp } from "../../api/message";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { IMessage } from "../../models/message";
import { searchParamsInit } from "../../models/searchParams";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import InputAdornment from "@mui/material/InputAdornment";
import Messages from "../../localization/Messages";
import ChatMessage from "../ChatMessage/ChatMessage";
import classNames from "classnames";
import { Roles } from "../../models/userData";
import { DateFormat, getDateMoment, getDateText } from "../../helpers/dateHelper";
import { BerthingDockGroup } from "../../containers/LDManageVoyages/components/BerthingsDockName";
import { Box, Tooltip, Typography } from "@mui/material";
import { useSocket } from "../../hooks/useSocket";
import { useAuth } from "../../hooks/useAuth";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import "./Chat.css";
import { SortOrder } from "../../hooks/useTableSort";

interface IChat {
    bookingId?: number | null;
}

/**
 * Renders a chat message component based on the provided message object.
 *
 * @param msg - The message object to render.
 * @param i - The index of the message in the message history.
 * @param intl - The internationalization object for formatting messages.
 * @returns The rendered ChatMessage component.
 */

const renderMessage = (msg: IMessage, i: number | string, intl: IntlShape) => {
    if (!msg) return;
    const sentDate = msg.timeSent ? getDateMoment(msg.timeSent).toDate() : null;
    const { type, text: _text, meta, senderName, direction } = msg;
    let text: ReactNode = _text;
    let eta = meta?.eta || meta?.arrivalTime;
    let etd = meta?.etd || meta?.departureTime;
    const etaChanged = msg.meta?.etaChanged;
    const etdChanged = msg.meta?.etdChanged;
    const action = msg.action;

    const AutomatedUserMessage: Record<string, string> = {
        booking_changed: intl.formatMessage(Messages.bookingChanged),
        changed: intl.formatMessage(Messages.bookingChanged),
        change_booking: intl.formatMessage(Messages.changeBooking),
        request_booking: intl.formatMessage(Messages.bookingRequested),
        booking_confirmed: intl.formatMessage(Messages.bookingConfirmed),
        booking_declined: intl.formatMessage(Messages.bookingDeclined),
        booking_canceled: intl.formatMessage(Messages.bookingCanceled),
        booking_change_requested: intl.formatMessage(Messages.changeRequested),
        booking_requested: intl.formatMessage(Messages.bookingRequested),
        booking_change_confirmed: intl.formatMessage(Messages.bookingChangeConfirmed),
        voyage_data_updated: intl.formatMessage(Messages.voyageDataUpdated),
        booking_ship_update: intl.formatMessage(Messages.shipUpdated),
        ship_updated: intl.formatMessage(Messages.shipUpdated),
    };

    if (msg.type === "TEXT") {
        eta = undefined;
        etd = undefined;
    }

    if (msg.type === "STATUS") {
        text = AutomatedUserMessage[msg.action];
        if (msg.action === "booking_confirmed" || msg.action === "booking_changed") {
            eta = undefined;
            etd = undefined;
            text = (
                <>
                    <p>{AutomatedUserMessage[msg.action]}</p>
                    {!!msg.meta?.shipChanged && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {msg.meta?.oldShipName}
                            <KeyboardDoubleArrowRightIcon />
                            {msg.meta?.shipName}
                        </div>
                    )}
                    {msg.meta?.berthings != null && (
                        <Box display="flex" flexDirection="column" marginY={0.75}>
                            {msg.meta.berthings.map((b: BerthingDockGroup, i: number) => (
                                <Typography variant="body2" key={i}>
                                    <i>
                                        • {getDateText(b.arrivalTime, DateFormat.CLIENT_DATE_TIME)}{" "}
                                        -{" "}
                                        {getDateText(b.departureTime, DateFormat.CLIENT_DATE_TIME)}{" "}
                                        / <strong>{b.dockName}</strong>
                                    </i>
                                </Typography>
                            ))}
                        </Box>
                    )}
                </>
            );
        }
    }

    return (
        <ChatMessage
            direction={direction as "IN" | "OUT"}
            text={text}
            key={i}
            time={sentDate?.getTime()}
            type={type as "STATUS" | "TEXT"}
            eta={eta}
            etd={etd}
            senderName={senderName}
            action={action}
            etaChanged={etaChanged}
            etdChanged={etdChanged}
        />
    );
};

/**
 * Renders a chat component that displays a message history and allows users to send new messages.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number | null} props.bookingId - The ID of the booking associated with the chat.
 * @returns {JSX.Element} The rendered Chat component.
 */

const Chat = ({ bookingId }: IChat) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { user } = useAuth();
    const [messageHistory, setMessageHistory] = useState<IMessage[]>([]);
    const { isOpen, lastMessage } = useSocket("Message");
    const [newMessage, setNewMessage] = useState("");
    const messagesContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!lastMessage || lastMessage.bookingId !== bookingId) return;
        setMessageHistory(prev => [...prev, lastMessage]);
    }, [lastMessage, bookingId]);

    useEffect(() => {
        const fetchMessages = async () => {
            if (!bookingId) return;
            const isDispatcher = user.roles.includes(Roles.PASSENGER_CABIN_CRUISES_DISPATCHER);
            const searchMessages = isDispatcher ? searchMessageDisp : searchMessage;
            try {
                const filter = { bookingId };
                const order: SortOrder = ["timeSent asc"];
                const res = await searchMessages({ ...searchParamsInit, filter, order });
                const messages = res.data.rows;
                setMessageHistory(messages);
            } catch (e) {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.messages));
            }
        };

        fetchMessages();
    }, [bookingId, dispatch, intl, user.roles]);

    const send = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        if (!bookingId || !newMessage) return;
        const isDispatcher = user.roles.includes(Roles.PASSENGER_CABIN_CRUISES_DISPATCHER);
        const saveMessage = isDispatcher ? addMessageDisp : addMessage;
        try {
            await saveMessage({ bookingId, text: newMessage });
            setNewMessage("");
        } catch {
            dispatch(showBackendMessage(intl, "error", "creating", Messages.message));
        }
    };

    useEffect(() => {
        // scroll to the last message when the chat is opened or a new message is received/sent
        if (messagesContainerRef.current && messagesContainerRef.current.scrollTo) {
            messagesContainerRef.current.scrollTo({
                top: messagesContainerRef.current.scrollHeight,
                behavior: "smooth",
            });
        }
    }, [messageHistory]);

    return (
        <div className="booking-container">
            <div className="messages-container" ref={messagesContainerRef}>
                {bookingId && messageHistory.map(m => renderMessage(m, m.id, intl))}
            </div>
            <form onSubmit={send}>
                <Tooltip title={isOpen ? undefined : intl.formatMessage(Messages.chatNotConnected)}>
                    <TextField
                        multiline
                        maxRows={8}
                        disabled={!isOpen}
                        required
                        value={newMessage}
                        onChange={e => setNewMessage(e.target.value)}
                        variant="filled"
                        InputProps={{
                            sx: { padding: 1 },
                            endAdornment: (
                                <InputAdornment
                                    className={classNames({
                                        disabled: !newMessage || !bookingId,
                                    })}
                                    position="end"
                                    onClick={send}
                                >
                                    <button type="submit">
                                        <SendIcon />
                                    </button>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Tooltip>
            </form>
        </div>
    );
};

export default Chat;
