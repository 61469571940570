import { Reducer } from "redux";
import { SidebarOwnerActionType, SET_SIDEBAR_OWNER } from "../actions/sidebarOwnerAction";

export type SidebarOwnerState<T = unknown> = {
    data: T | undefined;
};

const initialState: SidebarOwnerState = { data: undefined };

function setSidebarOwner<T = unknown>(sidebarOwner: T | undefined) {
    return { data: sidebarOwner };
}

export const sidebarOwnerReducer: Reducer<SidebarOwnerState, SidebarOwnerActionType> = (
    state: SidebarOwnerState = initialState,
    action: SidebarOwnerActionType,
): SidebarOwnerState => {
    switch (action.type) {
        case SET_SIDEBAR_OWNER:
            return { ...setSidebarOwner(action.payload) };

        default:
            return state;
    }
};
