export const weekdays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
] as const;

export type WeekdayType = (typeof weekdays)[number];

export type ITimetable = {
    [key in WeekdayType]: TimeInterval[];
};

export interface TimeInterval {
    start: string;
    end: string;
}

export const timetableInit: ITimetable = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
};
