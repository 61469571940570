import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { getDateMoment } from "../helpers/dateHelper";

/**
 * SerializerProps defines the serialization and deserialization functions for a specific type.
 */
export type SerializerProps<T> = {
    serialize: (value: T) => string;
    deserialize: (value: string) => T;
};

/**
 * ISerializers defines a collection of SerializerProps for different types.
 */
export type ISerializers = {
    default: SerializerProps<any>;
    boolean: SerializerProps<boolean>;
    string: SerializerProps<string>;
    number: SerializerProps<number>;
    date: SerializerProps<Date>;
};

/**
 * Serializers is a collection of default serializers for different types.
 */
export const Serializers: ISerializers = {
    default: {
        serialize: v => JSON.stringify(v),
        deserialize: (v: string) => JSON.parse(v),
    },
    boolean: {
        serialize: (v: boolean) => String(v),
        deserialize: (v: string) => v === "true",
    },
    string: {
        serialize: (v: string) => v,
        deserialize: (v: string) => v,
    },
    number: {
        serialize: (v: number) => String(v),
        deserialize: (v: string) => Number(v),
    },
    date: {
        serialize: (v: Date) => String(v.getTime()),
        deserialize: (v: string) => getDateMoment(v).toDate(),
    },
};

/**
 * useUrlState is a custom hook that manages state in the URL query parameters.
 * It returns the current value and a setter function to update the value.
 *
 * @param key - The key used to store the value in the URL query parameters.
 * @param initialValue - The initial value of the state.
 * @param serializerProps - The serialization and deserialization functions for the state type.
 * @returns A tuple containing the current value and a setter function to update the value.
 */
export function useUrlState<T>(
    key: string,
    initialValue: T,
    serializerProps: SerializerProps<T> = Serializers.default,
): [T, (state: T) => void] {
    const { serialize, deserialize } = serializerProps;
    const [searchParams, setSearchParams] = useSearchParams();
    const param = searchParams.get(key);

    const getter = param ? deserialize(param) : initialValue;

    const setter = useCallback(
        (value: T) => {
            if (value == null) {
                setSearchParams({ ...searchParams });
                return;
            }

            setSearchParams({ ...searchParams, [key]: serialize(value) });
        },
        [setSearchParams, key, searchParams, serialize],
    );

    return [getter, setter];
}
