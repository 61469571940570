import axios, { AxiosResponse } from "axios";
import { ISearchParams, searchParamsInit } from "../models/searchParams";
import { ApiSearchResponse } from "../models/common";
import { IExpensePeriod } from "../models/expenseType";

export const addExpensePeriod = async (
    expensePeriod: IExpensePeriod,
): Promise<AxiosResponse<IExpensePeriod>> => {
    return await axios.put("/dispatcher/addExpensePeriod", expensePeriod);
};

export const changeExpensePeriod = async (
    expensePeriod: IExpensePeriod,
): Promise<AxiosResponse<IExpensePeriod>> => {
    return await axios.patch("/dispatcher/changeExpensePeriod", expensePeriod);
};

export const deleteExpensePeriod = async (id: string): Promise<AxiosResponse<string>> => {
    return await axios.delete(`/dispatcher/deleteExpensePeriod/${id}`);
};

export const getExpensePeriod = async (id: string): Promise<AxiosResponse<IExpensePeriod>> => {
    return await axios.get(`/dispatcher/getExpensePeriod/${id}`);
};

export const searchExpensePeriod = async (
    searchParams: ISearchParams = searchParamsInit,
): ApiSearchResponse<IExpensePeriod> => {
    return await axios.post("/dispatcher/searchExpensePeriod", searchParams);
};
