import { TextField } from "@mui/material";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import { Moment } from "moment";
import { useState } from "react";
import TextAction from "../TextAction";
import "./index.css";
import { DateFormat, DateType, getDateMoment } from "../../helpers/dateHelper";

export type DateTimePickerInlineProps = {
    value: DateType;
    format?: string;
    onChange: (value: Date) => void;
    label?: string;
    error?: string;
    minDate?: Moment;
    maxDate?: Moment;
    placeholder?: string;
    className?: string;
    onFocusHandler?: () => void;
    id?: string;
};

/**
 * Renders a customised version of a Material UI date and time picker that
 * is designed to be as small as possible so that it can be used inline.
 *
 * @component
 * @example
 * // Example usage of DateTimePickerInline
 * <DateTimePickerInline
 *    value={new Date()}
 *    onChange={(value) => onChange(value)}
 *    label="Select Date and Time"
 *    format="YYYY-MM-DD HH:mm:ss"
 *    error="Invalid date"
 *    minDate={moment().subtract(1, 'day')}
 *    maxDate={moment().add(1, 'day')}
 *    placeholder="Select a date and time"
 * />
 *
 * @param {DateTimePickerInlineProps} props - The component props.
 * @param {DateType} props.value - The selected date and time value.
 * @param {string} [props.format] - The format of the displayed date and time. Defaults to "YYYY-MM-DD HH:mm:ss".
 * @param {(value: Date) => void} props.onChange - The callback function triggered when the value changes.
 * @param {string} [props.label] - The label for the date and time picker.
 * @param {string} [props.error] - The error message to display when the selected date and time is invalid.
 * @param {Moment} [props.minDate] - The minimum allowed date and time.
 * @param {Moment} [props.maxDate] - The maximum allowed date and time.
 * @param {string} [props.placeholder] - The placeholder text to display when no date and time is selected.
 * @param {boolean} [props.className] - The class name to apply to the date and time picker.
 * @returns {JSX.Element} The rendered DateTimePickerInline component.
 */

export default function DateTimePickerInline({
    id,
    value,
    onChange = () => {},
    label = undefined,
    format = DateFormat.CLIENT_DATE_TIME,
    error = "",
    minDate,
    maxDate,
    placeholder,
    className,
    onFocusHandler,
}: DateTimePickerInlineProps) {
    const computedValue = value ? getDateMoment(value).toDate() : null;

    const [open, setOpen] = useState(false);
    return (
        <DesktopDateTimePicker
            key={id ?? ""}
            label={label}
            inputFormat={format}
            value={computedValue}
            onChange={value => {
                if (value) {
                    //@ts-expect-error TODO : refactor DateTimePicker
                    value._isValid && onChange(getDateMoment(value).toDate());
                } else {
                    //@ts-expect-error TODO : refactor DateTimePicker
                    onChange(null);
                }
            }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => setOpen(false)}
            minDateTime={minDate as unknown as Date}
            maxDateTime={maxDate as unknown as Date}
            InputAdornmentProps={{ position: "start" }}
            renderInput={params => (
                <>
                    <TextAction inputRef={params.inputRef} open={open} error={error}>
                        <TextField
                            onFocus={() => {
                                onFocusHandler && onFocusHandler();
                            }}
                            {...params}
                            placeholder={placeholder}
                            className={`${params.className} custom-inline-date ${className}`}
                            data-open={open}
                            size="small"
                            variant="standard"
                            error={false}
                        />
                    </TextAction>
                </>
            )}
        />
    );
}
