import { useContext } from "react";
import { NotificationsContext, NotificationsContextProps } from "../contexts/NotificationsContext";

/**
 * Custom hook that provides access to the notifications context.
 * @returns The notifications context object.
 * @throws {Error} If used outside of the SocketProvider.
 */
export function useNotifications(): NotificationsContextProps {
    const ctx = useContext(NotificationsContext);
    if (!ctx) throw new Error("useNotifications must be used within SocketProvider");
    return ctx;
}
