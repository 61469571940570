import React from "react";
import { Moment } from "moment";
import { getDateText, DateFormat } from "../../../../helpers/dateHelper";

interface DateTimeTwoRowDisplayProps {
    date: Date | Moment | number;
    timeZone?: string;
}

/**
 * Renders a component that displays a date and time in a two-row format.
 *
 * @component
 * @example
 * // Usage:
 * <DateTimeTwoRowDisplay date={new Date()} timeZone="America/New_York" />
 *
 * @param {DateTimeTwoRowDisplayProps} props - The component props.
 * @param {Date | Moment | number} props.date - The date to be displayed.
 * @param {string} [props.timeZone] - The time zone to be used for formatting the date and time. (Optional)
 *
 * @returns {JSX.Element} The rendered component.
 */
const DateTimeTwoRowDisplay: React.FC<DateTimeTwoRowDisplayProps> = ({ date, timeZone }) => {
    const formattedDate = getDateText(date, DateFormat.CLIENT_DATE, timeZone);
    const formattedTime = getDateText(date, DateFormat.CLIENT_TIME, timeZone);

    return (
        <div className="DateTwoRow">
            <div>{formattedDate}</div>
            <div>{formattedTime}</div>
        </div>
    );
};

export default DateTimeTwoRowDisplay;
