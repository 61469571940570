import { MessageDescriptor } from "react-intl";
import Messages from "../localization/Messages";
import { IBerthing } from "./berthing";
import { IShip, shipInit } from "./ship";
import { MbscEventCalendarInvalidResource } from "../helpers/eventCalendarHelper";
import { IInvoice } from "./invoice";
import { IDocument } from "./document";

export enum BookingStatus {
    UNKNOWN = "UNKNOWN",
    REQUESTED = "REQUESTED",
    MULTIPLE = "MULTIPLE",
    CHANGE_REQUESTED = "CHANGE_REQUESTED",
    PENDING = "PENDING",
    PROVISIONALLY_PLANNED = "PROVISIONALLY_PLANNED",
    PROVISIONALLY_DECLINED = "PROVISIONALLY_DECLINED",
    CHANGED = "CHANGED",
    CONFIRMED = "CONFIRMED",
    DECLINED = "DECLINED",
    CANCELLED = "CANCELLED",
}

export function getBookingDock(booking: IBooking, berthing?: IBerthing): string {
    if (
        [
            BookingStatus.DECLINED,
            BookingStatus.PROVISIONALLY_DECLINED,
            BookingStatus.CANCELLED,
        ].includes(booking.status)
    ) {
        return MbscEventCalendarInvalidResource.DECLINED;
    }
    if ([BookingStatus.CANCELLED].includes(booking.status)) {
        return "";
    }
    if (berthing && berthing.dockId) {
        return berthing.dockId;
    }
    if (booking.dockId) {
        return booking.dockId;
    }
    return MbscEventCalendarInvalidResource.UNALLOCATED;
}

export function getBookingStatusCssColor({ status }: IBooking): string {
    switch (status) {
        case BookingStatus.REQUESTED:
        case BookingStatus.CHANGE_REQUESTED:
            return "var(--event-orange)";
        case BookingStatus.PENDING:
        case BookingStatus.PROVISIONALLY_PLANNED:
            return "var(--event-blue)";
        case BookingStatus.CANCELLED:
        case BookingStatus.PROVISIONALLY_DECLINED:
        case BookingStatus.DECLINED:
            return "var(--event-red)";
        case BookingStatus.CHANGED:
            return "var(--event-green)";
        case BookingStatus.CONFIRMED:
            return "var(--event-green)";
        default:
            return "var(--event-dark-grey)";
    }
}

export function getBookingStatusMessageDescriptor({ status }: IBooking): MessageDescriptor {
    switch (status) {
        case BookingStatus.CANCELLED:
            return Messages.canceled;
        case BookingStatus.REQUESTED:
            return Messages.requested;
        case BookingStatus.CHANGE_REQUESTED:
            return Messages.changeRequested;
        case BookingStatus.PENDING:
            return Messages.pending;
        case BookingStatus.PROVISIONALLY_PLANNED:
            return Messages.provisionallyPlanned;
        case BookingStatus.PROVISIONALLY_DECLINED:
            return Messages.provisionallyDeclined;
        case BookingStatus.CHANGED:
            return Messages.changed;
        case BookingStatus.CONFIRMED:
            return Messages.confirmed;
        case BookingStatus.DECLINED:
            return Messages.declined;
        default:
            return Messages.unavailable;
    }
}

export enum VoyageStatus {
    UNAVAILABLE = "UNAVAILABLE",
    PENDING = "PENDING",
    CONFIRMED = "CONFIRMED",
    CHANGED = "CHANGED",
    DECLINED = "DECLINED",
}

export enum BookingDataSource {
    AIS = "AIS",
    MANUAL = "MANUAL",
}

export interface IBooking {
    id: number;
    areaPreference?: string;
    arrivalTime: number;
    bookingDate?: number;
    confirmTime?: number;
    comment?: string;
    companyId: string;
    createBy: string;
    createTs: number;
    deleted: 0 | 1;
    departureTime: number;
    destinationId: string;
    dockId?: string;
    lastModifyBy: string;
    lastModifyTs: number;
    managingCompany: string;
    reqArrivalTime: number;
    reqDepartureTime: number;
    requestDate: number;
    changeRequestDate?: number;
    ship: IShip;
    shipId: string;
    shipLength: number;
    shipName: string;
    status: BookingStatus;
    berthings?: IBerthing[];
    complete?: boolean;
    actualArrivalTime?: number;
    actualDepartureTime?: number;
    owningCompany?: string;
    managingCompanyName?: string;
    invoices?: IInvoice[];
    invoiceAddressDocksId: string | null;
    contactDetailsDocksId: string | null;
    charterCompanyDocksId: string | null;
    ataSource?: BookingDataSource;
    atdSource?: BookingDataSource;
    finalized?: boolean;
    sharedRemark?: string;
    finalizedBy?: string;
    finalizedOn?: number;
    documents?: IDocument[];
}

export const bookingInit: IBooking = {
    id: 0,
    arrivalTime: 0,
    companyId: "",
    createBy: "",
    createTs: 0,
    deleted: 0,
    departureTime: 0,
    destinationId: "",
    lastModifyBy: "",
    lastModifyTs: 0,
    managingCompany: "",
    reqArrivalTime: 0,
    reqDepartureTime: 0,
    requestDate: 0,
    ship: shipInit,
    shipId: "",
    shipLength: 0,
    shipName: "",
    status: BookingStatus.UNKNOWN,
    invoices: [],
    invoiceAddressDocksId: "",
    charterCompanyDocksId: "",
    contactDetailsDocksId: "",
};

export interface ConfirmBookingParam {
    bookingId: number;
    comment: string;
}

export interface DeclineBookingParam {
    bookingId: number;
    comment: string;
}

export interface PendingBookingParam {
    bookingId: number;
    pending: boolean;
}

export interface IBookingActualTimes {
    actualArrivalTime: number | null;
    actualDepartureTime: number | null;
    bookingId: number;
}

export const bookingActualTimesInit: IBookingActualTimes = {
    actualArrivalTime: null,
    actualDepartureTime: null,
    bookingId: 0,
};
