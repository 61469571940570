import axios, { AxiosResponse } from "axios";
import { ApiSearchResponse } from "../models/common";
import { ISearchParams, searchParamsInit } from "../models/searchParams";
import { IAddEditVoyage, IVoyage } from "../models/voyage";

export const addVoyage = async (dto: IAddEditVoyage): Promise<AxiosResponse<IVoyage>> => {
    return await axios.put("/docks/addVoyage", dto);
};

export const changeVoyage = async (dto: IAddEditVoyage): Promise<AxiosResponse<IVoyage>> => {
    return await axios.patch("/docks/changeVoyage", dto);
};

export const deleteVoyage = async (id: string): Promise<AxiosResponse<string>> => {
    return await axios.delete(`/docks/deleteVoyage/${id}`);
};

export const getVoyage = async (id: string): Promise<AxiosResponse<IVoyage>> => {
    return await axios.get(`/docks/getVoyage/${id}`);
};

export const searchVoyages = async (
    searchParams: ISearchParams = searchParamsInit,
): ApiSearchResponse<IVoyage> => {
    return await axios.post("/docks/searchVoyage", searchParams);
};

export const importVoyageAndStops = async (csv: string): Promise<AxiosResponse<IVoyage[]>> => {
    return await axios.post("/docks/importVoyageAndStops", { csv });
};

export const changeVoyageAvailable = async (voyageId: number): Promise<AxiosResponse<boolean>> => {
    return await axios.get(`/docks/changeVoyageAvailable/${voyageId}`);
};
