import { IconButton, Tooltip } from "@mui/material";
import { Comment } from "@mui/icons-material";
import Messages from "../../../../localization/Messages";
import { useState } from "react";
import { useIntl } from "react-intl";
import RpisDialog from "../../../RpisDialog/RpisDialog";
import { BOOKING_REMARK_TYPE, BookingRemarkDialog } from "../BookingSidebarTitle";
import "./EditSharedRemark.css";
import { useSidebarOwner } from "../../../../contexts/SidebarOwnerContext";
import { CommentOutlined } from "@mui/icons-material";
import { IBooking } from "src/models/booking";

export type EditSharedRemarkProps = {
    onSubmit?: () => Promise<void>;
};

export function EditSharedRemark({ onSubmit = async () => {} }: EditSharedRemarkProps) {
    const [booking] = useSidebarOwner<IBooking>();
    const bookingId = booking?.id;
    const remark = booking?.sharedRemark;
    const intl = useIntl();
    const [open, setOpen] = useState(false);
    const buttonTitleSuffix = remark ? `: ${remark}` : "";

    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Tooltip
                key={remark}
                title={`${intl.formatMessage(Messages.editSharedRemark)}${buttonTitleSuffix}`}
            >
                <IconButton onClick={onOpen} className={"shared-remark-icon"}>
                    {remark ? <Comment /> : <CommentOutlined />}
                </IconButton>
            </Tooltip>

            <RpisDialog
                fullWidth
                size="sm"
                dialogOpen={open}
                title={`${intl.formatMessage(Messages.editSharedRemark)} #${bookingId}`}
                onClose={onClose}
                content={
                    <BookingRemarkDialog
                        onClose={onClose}
                        reloadBookings={onSubmit}
                        remarkType={BOOKING_REMARK_TYPE.SHARED_REMARK}
                    />
                }
            />
        </>
    );
}
