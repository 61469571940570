import { TableRow, TableCell } from "@mui/material";
import { useIntl } from "react-intl";
import Messages from "../../localization/Messages";
import "./index.css";
import { memo } from "react";

export type NoDataRowProps = {
    colSpan: number;
};

/**
 * Represents a component that renders a row with a message when there is no data.
 *
 * @component
 * @param {NoDataRowProps} props - The props for the NoDataRow component.
 * @param {number} props.colSpan - The number of columns the row should span.
 * @returns {JSX.Element} The rendered NoDataRow component.
 */

function NoDataRow({ colSpan }: NoDataRowProps) {
    const intl = useIntl();
    return (
        <TableRow>
            <TableCell className="no-data-wrapper" colSpan={colSpan}>
                {intl.formatMessage(Messages.noData)}
            </TableCell>
        </TableRow>
    );
}

export default memo(NoDataRow);
