import { getMimeTypeMetadataRender } from "../../utils/mimeTypes";
import "./index.css";

export type DocumentPreviewProps = {
    mimeType: string;
    src: string;
};

export function DocumentPreview({ mimeType, src }: DocumentPreviewProps) {
    const mimeTypeMetadataRender = getMimeTypeMetadataRender(mimeType);

    if (!mimeTypeMetadataRender.preview) {
        return <>Preview not supported! TODO</>;
    }

    return <>{mimeTypeMetadataRender.preview(src, mimeType)}</>;
}
