import {
    Box,
    Checkbox,
    FormControlLabel,
    Tooltip,
    TooltipProps,
    Typography,
    styled,
    tooltipClasses,
} from "@mui/material";
import { DateFormat, getDateText } from "../../../../../helpers/dateHelper";
import { BookingStatus, IBooking } from "../../../../../models/booking";
import { useEffect, useState } from "react";
import { setFinalizedBooking } from "../../../../../api/booking";
import { useDispatch } from "react-redux";
import { showBackendMessage } from "../../../../../helpers/messagesHelper";
import { useIntl } from "react-intl";
import Messages from "../../../../../localization/Messages";
import { InvoiceStatus } from "../../../../../models/invoice";
import { searchParamsInit } from "../../../../../models/searchParams";
import React from "react";
import { useValidateServices } from "../../hooks/useValidateServices";
import { useSidebarOwner } from "../../../../../contexts/SidebarOwnerContext";
import { isBookingCompletable } from "../../../../../containers/ManageBookings/shared/utils";
import { useFetchInvoices } from "../../../../../hooks/useFetchInvoices";

type FinalizeBookingSectionProps = {
    onFinalizeComplete?: () => void;
    label?: boolean;
};

export const FinalizedTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#fafafa",
        color: "rgba(0,0,0, 0.87)",
        maxWidth: 250,
        boxShadow: "0px 3px 15px rgba(0,0,0,0.2)",
        padding: "10px",
    },
}));

export const FinalizeBookingSection = ({
    onFinalizeComplete,
    label = true,
}: FinalizeBookingSectionProps) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [selectedBooking, setSelectedBooking] = useSidebarOwner<IBooking>();
    const [disableFinalize, setDisableFinalize] = useState(false);
    const [disabledMessage, setDisabledMessage] = useState("");
    const { data } = useFetchInvoices({
        payload: {
            ...searchParamsInit,
            filter: { $and: [{ bookingId: selectedBooking!.id }] },
        },
    });
    const invoices = React.useMemo(() => data?.invoices ?? [], [data]);

    const selectedBookingId = selectedBooking!.id;
    const bookingComplete = !!selectedBooking!.finalized;

    const servicesIncomplete = useValidateServices(selectedBookingId);

    useEffect(() => {
        const calculateDisableFinalize = () => {
            const bookingNotCompletable = !isBookingCompletable(
                selectedBooking?.status ?? BookingStatus.UNKNOWN,
            );
            const bookingMissingActualTimes =
                !selectedBooking?.actualDepartureTime || !selectedBooking?.actualArrivalTime;
            const invoicedOrReadyForBilling = invoices.some(
                i =>
                    i.invoiceStatus === InvoiceStatus.INVOICED ||
                    i.invoiceStatus === InvoiceStatus.READY_FOR_BILLING,
            );
            const invoiceNotOpen = invoices.some(i => i.invoiceStatus !== InvoiceStatus.OPEN);
            setDisableFinalize(
                bookingNotCompletable ||
                    bookingMissingActualTimes ||
                    invoicedOrReadyForBilling ||
                    (bookingComplete && invoiceNotOpen),
            );
            setDisabledMessage(
                bookingNotCompletable
                    ? intl.formatMessage(Messages.disableFinalizeMessage5)
                    : bookingMissingActualTimes
                    ? intl.formatMessage(Messages.disableFinalizeMessage4)
                    : invoicedOrReadyForBilling
                    ? `${intl.formatMessage(
                          bookingComplete ? Messages.unfinalizeBooking : Messages.finalizeBooking,
                      )} ${intl.formatMessage(Messages.disableFinalizeMessage2)} `
                    : !!bookingComplete && invoiceNotOpen
                    ? intl.formatMessage(Messages.disabledFinalizeMessage)
                    : "",
            );
        };
        calculateDisableFinalize();
    }, [
        bookingComplete,
        intl,
        invoices,
        selectedBooking?.actualArrivalTime,
        selectedBooking?.actualDepartureTime,
        selectedBooking?.status,
    ]);

    const onSetFinalizedBooking = async () => {
        try {
            const res = await setFinalizedBooking(selectedBookingId, !bookingComplete);
            setSelectedBooking({ ...res.data, invoices });
            onFinalizeComplete?.();
            dispatch(showBackendMessage(intl, "success", "updating", Messages.booking));
        } catch {
            dispatch(showBackendMessage(intl, "error", "updating", Messages.booking));
        }
    };

    return (
        <Box alignItems={"center"} display={"flex"} flexDirection={"column"}>
            <FinalizedTooltip
                title={
                    <React.Fragment>
                        <Typography color="inherit">{`${
                            bookingComplete
                                ? intl.formatMessage(Messages.unfinalizeBooking)
                                : intl.formatMessage(Messages.finalizeBooking)
                        }`}</Typography>
                        {bookingComplete && (
                            <Typography sx={{ fontSize: "12px", margin: "5px 0 5px 2px" }}>
                                {`${intl.formatMessage(Messages.finalizedBy)} ${
                                    selectedBooking!.finalizedBy
                                } ${getDateText(
                                    selectedBooking!.finalizedOn,
                                    DateFormat.CLIENT_DATE_TIME,
                                )}`}
                            </Typography>
                        )}
                        {disabledMessage && (
                            <Typography sx={{ fontSize: "12px", margin: "5px 0 5px 2px" }}>
                                {disabledMessage}
                            </Typography>
                        )}
                        {servicesIncomplete && !bookingComplete && (
                            <Typography sx={{ fontSize: "12px", margin: "5px 0 5px 2px" }}>
                                {intl.formatMessage(Messages.disableFinalizeMessage3)}
                            </Typography>
                        )}
                    </React.Fragment>
                }
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={
                                Boolean(disableFinalize) || (servicesIncomplete && !bookingComplete)
                            }
                            checked={bookingComplete}
                            onChange={onSetFinalizedBooking}
                            inputProps={{ "aria-label": "controlled" }}
                        />
                    }
                    labelPlacement="start"
                    label={label ? intl.formatMessage(Messages.finalized) : ""}
                />
            </FinalizedTooltip>
        </Box>
    );
};
