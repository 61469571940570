import { Reducer } from "redux";
import {
    GuiConfsActionType,
    FETCH_INITIAL_FILTERS,
    UPDATE_FILTERS,
} from "../actions/guiConfsActions";
import { DockAllocationPlanFilters } from "../containers/DockAllocationPlan/DockAllocationPlan";
import { VoyagesFilters } from "../containers/LDManageVoyages/LDManageVoyages";
import { addDays, getDateMoment, subtractDays } from "../helpers/dateHelper";
import { setDispatcherGuiConf, setDocksGuiConf } from "../api/guiConf";
import { ChangeLogFilters } from "../containers/ChangeLog/ChangeLog";
import { DispManageAddressesFilters } from "../containers/DispManageAddresses/DispManageAddresses";
import { ManageShipsFilters } from "../containers/ManageShips/ManageShips";
import { LDManageAddressesFilters } from "../containers/LDManageAddresses/LDManageAddresses";
import { LDManageFleetFilters } from "../containers/LDManageFleet/LDManageFleet";
import { debounce } from "lodash";
import { BookingsFilters } from "../containers/ManageBookings/shared/filters";
import { ExpenseFiltersType } from "../containers/ManageExpenses/shared/filters";
import { InvoiceFilters } from "../containers/ManageInvoices/shared/filters";
import { DISP_MANAGE_INTERNAL_BOOKINGS_FILTERS_INIT } from "../containers/ManageInternalBookings/shared/filters";
import { LDExpenseFiltersType } from "../containers/LDManageExpenses/shared/filters";
import { ServiceFiltersType } from "../containers/DispManageServices/shared/filters";

//* Relevant types and constants.

const CURRENT_DATE = getDateMoment().toDate();
export type GuiConfApp = keyof GuiConfState;
export type GuiConfKey = GuiConfDispatcherKey | GuiConfDocksKey;

export type GuiConfDispatcher = typeof GuiConfDispatcherState;
export type GuiConfDispatcherPrefix = "disp";
export type GuiConfDispatcherKey = keyof GuiConfDispatcher;

export type GuiConfDocks = typeof GuiConfDocksState;
export type GuiConfDocksPrefix = "ld";
export type GuiConfDocksKey = keyof GuiConfDocks;

export type GuiConfState = {
    dispatcher: GuiConfDispatcher;
    docks: GuiConfDocks;
};
export type GuiConfsFiltersState<TKey extends GuiConfKey> =
    TKey extends `${GuiConfDispatcherPrefix}${string}`
        ? (typeof GuiConfDispatcherState)[TKey]
        : TKey extends `${GuiConfDocksPrefix}${string}`
        ? (typeof GuiConfDocksState)[TKey]
        : never;

//* Gui page-specific filter initial definitions and values.

/**
 * Initial filter values for the Manage Addresses page.
 */
const DISP_MANAGE_ADDRESSES_FILTERS_INIT: DispManageAddressesFilters = {
    textSearch: "",
    page: 0,
    rowsPerPage: 100,
    order: ["company desc"],
    addressSource: undefined,
    addressType: undefined,
    status: undefined,
};

/**
 * Initial filter values for the Manage Ships page.
 */
const DISP_MANAGE_SHIPS_FILTERS_INIT: ManageShipsFilters = {
    textSearch: "",
    page: 0,
    rowsPerPage: 100,
    order: ["name asc"],
};

/**
 * Initial filter values for the Manage Internal Bookings page.
 */
const DISP_MANAGE_BOOKINGS_FILTERS_INIT: BookingsFilters = {
    dateFrom: null,
    dateTo: null,
    shipName: "",
    docks: [],
    bookingStatuses: [],
    finalized: null,
    page: 0,
    rowsPerPage: 100,
    order: ["requestDate desc"],
};

/**
 * Initial filter values for the Change log page.
 */
const DISP_CHANGE_LOG_FILTERS_INIT: ChangeLogFilters = {
    dateFrom: null,
    dateTo: null,
    user: "",
    entity: "",
    order: ["timestamp desc"],
    rowsPerPage: 10,
    page: 0,
};

/**
 * Initial filter values for the Dock Allocation Plan page.
 */
const DISP_DOCK_ALLOCATION_PLAN_FILTERS_INIT: DockAllocationPlanFilters = {
    currentDate: CURRENT_DATE,
    resolution: 12,
    rangeVal: [subtractDays(CURRENT_DATE, 30), addDays(CURRENT_DATE, 90)],
};

/**
 * Initial filter values for the Manage voyages page.
 */
const LD_MANAGE_VOYAGES_FILTERS_INIT: VoyagesFilters = {
    dateFrom: null,
    dateTo: null,
    shipId: undefined,
    voyageStatuses: [],
    page: 0,
    rowsPerPage: 100,
    order: ["id desc"],
};

/**
 * Initial filter values for the Manage addresses page.
 */
const LD_MANAGE_ADDRESSES_FILTERS_INIT: LDManageAddressesFilters = {
    textSearch: "",
    page: 0,
    rowsPerPage: 100,
    order: ["company desc"],
    addressType: undefined,
};

/**
 * Initial filter values for the Manage fleet page.
 */
const LD_MANAGE_FLEET_FILTERS_INIT: LDManageFleetFilters = {
    textSearch: "",
    page: 0,
    rowsPerPage: 100,
    order: ["name asc"],
};

/**
 * Initial filter values for the Manage expenses page of Locks & Docks.
 */
const LD_MANAGE_EXPENSES_FILTERS_INIT: LDExpenseFiltersType = {
    dateFrom: null,
    dateTo: null,
    internalInvoiceNumber: "",
    shipName: "",
    owningCompany: "",
    name: "",
    page: 0,
    rowsPerPage: 100,
    order: ["expenseDate desc"],
};

/**
 * Initial filter values for the Manage expenses page of Dispatcher.
 */
const DISP_MANAGE_EXPENSES_FILTERS_INIT: ExpenseFiltersType = {
    dateFrom: null,
    dateTo: null,
    internalInvoiceNumber: undefined,
    bookingId: null,
    shipName: "",
    owningCompanyDocksId: undefined,
    managingCompany: undefined,
    expenseTypeId: undefined,
    page: 0,
    rowsPerPage: 100,
    order: ["expenseDate desc"],
    status: undefined,
};

/**
 * Initial filter values for the Manage invoices page.
 */
const DISP_MANAGE_INVOICES_FILTERS_INIT: InvoiceFilters = {
    internalInvoiceNumber: "",
    dateFrom: null,
    dateTo: null,
    shipId: null,
    bookingDateFrom: null,
    bookingDateTo: null,
    invoiceStatus: [],
    invoiceDate: null,
    order: ["invoiceDate desc"],
    rowsPerPage: 100,
    page: 0,
};

const DISP_MANAGE_SERVICES_FILTERS_INIT: ServiceFiltersType = {
    dateFrom: null,
    dateTo: null,
    type: undefined,
    page: 0,
    rowsPerPage: 100,
    order: ["startDateTime desc"],
    status: [],
    shipId: "",
};

//* Cache configuration.

export const GuiConfDispatcherState = {
    dispInternalBookings: DISP_MANAGE_INTERNAL_BOOKINGS_FILTERS_INIT,
    dispBookings: DISP_MANAGE_BOOKINGS_FILTERS_INIT,
    dispDockAllocationPlan: DISP_DOCK_ALLOCATION_PLAN_FILTERS_INIT,
    dispChangeLog: DISP_CHANGE_LOG_FILTERS_INIT,
    dispManageAddresses: DISP_MANAGE_ADDRESSES_FILTERS_INIT,
    dispManageShips: DISP_MANAGE_SHIPS_FILTERS_INIT,
    dispManageExpenses: DISP_MANAGE_EXPENSES_FILTERS_INIT,
    dispManageInvoices: DISP_MANAGE_INVOICES_FILTERS_INIT,
    dispManageServices: DISP_MANAGE_SERVICES_FILTERS_INIT,
} as const;

export const GuiConfDocksState = {
    ldManageVoyages: LD_MANAGE_VOYAGES_FILTERS_INIT,
    ldManageAddresses: LD_MANAGE_ADDRESSES_FILTERS_INIT,
    ldManageFleet: LD_MANAGE_FLEET_FILTERS_INIT,
    ldManageExpenses: LD_MANAGE_EXPENSES_FILTERS_INIT,
} as const;

//* Reducer.

const GUI_CONF_DISPATCHER_KEYS = Object.keys(GuiConfDispatcherState) as GuiConfDispatcherKey[];
const GUI_CONF_DOCKS_KEYS = Object.keys(GuiConfDocksState) as GuiConfDocksKey[];

export function getAppName(guiConfKey: GuiConfKey): GuiConfApp {
    return guiConfKey.startsWith("disp") ? "dispatcher" : "docks";
}

export const initialState: GuiConfState = {
    dispatcher: GuiConfDispatcherState,
    docks: GuiConfDocksState,
};

/**
 * The reducer function for the GUI configuration data.
 * @param state
 * @param action
 */
export const guiConfsReducer: Reducer<GuiConfState, GuiConfsActionType<GuiConfKey>> = (
    state: GuiConfState = initialState,
    action: GuiConfsActionType<GuiConfKey>,
): GuiConfState => {
    switch (action.type) {
        case FETCH_INITIAL_FILTERS:
            return setGuiConfs(action.payload);

        case UPDATE_FILTERS:
            // eslint-disable-next-line no-case-declarations
            const appName = getAppName(action.guiConfsKey);
            return setGuiConfs(
                {
                    ...state,
                    [appName]: {
                        ...state[appName],
                        [action.guiConfsKey]: {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-expect-error
                            ...state[appName][action.guiConfsKey],
                            ...action.filters,
                        },
                    },
                },
                appName,
            );

        default:
            return state;
    }
};

//* Internal handlers.

const saveAppData = debounce((appName: GuiConfApp, payload: GuiConfState) => {
    try {
        appName === "dispatcher" ? setDispatcherGuiConf(payload) : setDocksGuiConf(payload);
    } catch (error) {
        // NOOP
    }
}, 1000);

/**
 * Sets the GUI configuration data.
 * @param payload
 * @param appName
 */
function setGuiConfs(payload: GuiConfState, appName?: GuiConfApp): GuiConfState {
    const payloadSanitized = {
        dispatcher: getSanitizedAppData("dispatcher", payload),
        docks: getSanitizedAppData("docks", payload),
    };

    if (appName) {
        saveAppData(appName, payloadSanitized[appName]);
    }

    return payloadSanitized;
}

/**
 * Returns the sanitized GUI configuration data for the given app.
 * @param appName
 * @param payload
 */
function getSanitizedAppData(appName: GuiConfApp, payload: GuiConfState) {
    const sanitizedAppData: any = {};

    if (appName === "dispatcher") {
        for (const key of GUI_CONF_DISPATCHER_KEYS) {
            sanitizedAppData[key] = payload?.dispatcher?.[key] ?? GuiConfDispatcherState[key];
        }
    }
    if (appName === "docks") {
        for (const key of GUI_CONF_DOCKS_KEYS) {
            sanitizedAppData[key] = {
                ...GuiConfDocksState[key],
                ...(payload?.docks?.[key] ?? {}),
            };
        }
    }
    return sanitizedAppData;
}
