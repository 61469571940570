import { PublishedWithChanges } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { changeBerthings } from "../../../api/berthing";
import { showBackendMessage } from "../../../helpers/messagesHelper";
import Messages from "../../../localization/Messages";
import { IBerthing } from "../../../models/berthing";
import { BookingStatus } from "../../../models/booking";

/**
 * Props for the ProvPlanButton component.
 */
export type ProvPlanButtonProps = {
    isFormInvalid: boolean;
    loading?: boolean;
    getBookings: (bookingId: number) => Promise<void>;
    berthings: IBerthing[];
    bookingId: number;
    bookingStatus: BookingStatus;
};

/**
 * A button component for provisionally planning berthings.
 * @param {ProvPlanButtonProps} props - The component props.
 * @returns {JSX.Element} - The rendered component.
 */
export default function ProvPlanButton({
    isFormInvalid,
    loading = false,
    berthings,
    getBookings,
    bookingId,
    bookingStatus,
}: ProvPlanButtonProps) {
    const dispatch = useDispatch();
    const intl = useIntl();
    const globalErrorMessage = intl.formatMessage(Messages.dataInvalid);

    const disableClear = bookingStatus !== BookingStatus.PROVISIONALLY_PLANNED && !berthings.length;

    const handleSave = async () => {
        try {
            await changeBerthings(bookingId, berthings, true);
            dispatch(showBackendMessage(intl, "success", "updating", Messages.berthings));
        } catch {
            dispatch(showBackendMessage(intl, "error", "updating", Messages.berthings));
        } finally {
            getBookings(bookingId);
        }
    };

    return (
        <>
            <Tooltip title={isFormInvalid ? globalErrorMessage : undefined}>
                <span>
                    <Button
                        variant="outlined"
                        color="warning"
                        endIcon={<PublishedWithChanges />}
                        disabled={loading || isFormInvalid || disableClear}
                        onClick={() => handleSave()}
                    >
                        {berthings.length
                            ? intl.formatMessage(Messages.provisionallyPlan)
                            : intl.formatMessage(Messages.clear)}
                    </Button>
                </span>
            </Tooltip>
        </>
    );
}
