import { memo } from "react";
import BerthingsForm from "../../../../components/BerthingsForm";
import Chat from "../../../../components/Chat/Chat";
import { IBooking } from "../../../../models/booking";
import "./../../ManageBookings.css";
import { BookingDetails } from "../../../ManageExpenses/components/BookingDetails/BookingDetails";

export interface IBookingRespondForm {
    selectedBooking: IBooking;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    getBookings: (bookingId: number) => Promise<void>;
    sendBookingResponses?: (id?: number) => Promise<void>;
}

/**
 * Represents a form for responding to a booking.
 * This component is rendered inside a sidebar in the Manage Bookings page.
 */
const BookingRespondForm = ({
    selectedBooking,
    getBookings,
    sendBookingResponses,
}: IBookingRespondForm) => {
    const onUpdateActualTimes = () => {
        getBookings(selectedBooking.id);
    };

    return (
        <>
            <BookingDetails
                booking={selectedBooking}
                onUpdateActualTimes={onUpdateActualTimes}
                onFinalizeComplete={() => {}}
            />
            <BerthingsForm
                booking={selectedBooking}
                getBookings={getBookings}
                sendBookingResponses={sendBookingResponses}
            />
            {selectedBooking && <Chat bookingId={selectedBooking.id} />}
        </>
    );
};

export default memo(BookingRespondForm);
