import { useState, useEffect } from "react";

const DEFAULT_DELAY_MS = 1000;

/**
 * Debounces a callback function and returns a debounced version of it.
 * The debounced callback will only be invoked after a specified delay has passed without any new invocations.
 *
 * @param implFn - The callback function to be debounced.
 * @param dependencies - An array of dependencies that will trigger the debounced callback to be updated.
 * @param delay - The delay in milliseconds before the debounced callback is invoked. Default is 1000ms.
 * @returns The debounced callback function.
 */
export function useCallbackDebounced(
    implFn: () => Promise<void>,
    dependencies: [any, ...any[]],
    delay: number = DEFAULT_DELAY_MS,
) {
    const [debouncedCallback, setDebouncedCallback] = useState<() => Promise<void>>(() => implFn);

    useEffect(() => {
        const debouncedFnApplier = setTimeout(() => {
            setDebouncedCallback(() => implFn);
        }, delay);

        return () => clearTimeout(debouncedFnApplier);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);

    return debouncedCallback;
}

export default useCallbackDebounced;
