import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { addVoyage, changeVoyage } from "../../api/voyage";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import Messages from "../../localization/Messages";
import { AddressType, IAddress } from "../../models/address";
import { IShip } from "../../models/ship";
import { IAddEditVoyage, addEditVoyageInit } from "../../models/voyage";
import "./VoyageForm.css";
import { TextField, Autocomplete, Button } from "@mui/material";

type VoyageErrors = {
    [Key in keyof Partial<IAddEditVoyage>]: string;
};

export interface IVoyageForm {
    selectedVoyage?: IAddEditVoyage;
    ships: IShip[];
    disableShipInput: boolean;
    addresses: IAddress[];
    hasBookingRequests?: boolean;
}

/**
 * Renders the Voyage form in the Plan Voyage page, used for creating new voyages.
 *
 * @component
 * @example
 * ```tsx
 * <VoyageForm
 *    selectedVoyage={selectedVoyage}
 *    ships={ships}
 *    disableShipInput={disableShipInput}
 *    addresses={addresses}
 *    hasBookingRequests={hasBookingRequests}
 * />
 * ```
 *
 * @param {Object} props - The component props.
 * @param {IAddEditVoyage} props.selectedVoyage - The selected voyage object.
 * @param {IShip[]} props.ships - The array of ships.
 * @param {boolean} props.disableShipInput - Flag to disable the ship input.
 * @param {IAddress[]} props.addresses - The array of addresses.
 * @param {boolean} [props.hasBookingRequests=false] - Flag indicating if there are booking requests.
 * @returns {JSX.Element} The rendered VoyageForm component.
 */
export const VoyageForm = ({
    selectedVoyage,
    ships,
    disableShipInput,
    addresses,
    hasBookingRequests = false,
}: IVoyageForm) => {
    const displayConfirm = !!selectedVoyage || hasBookingRequests;
    const withConfirm = useConfirmDialog();
    const intl = useIntl();
    const dispatch = useDispatch();
    const [voyage, setVoyage] = useState(addEditVoyageInit);
    const [errors, setErrors] = useState<VoyageErrors>({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [invoices, setInvoices] = useState<IAddress[]>([]);
    const [charterCompanies, setCharterCompanies] = useState<IAddress[]>([]);
    const [contacts, setContacts] = useState<IAddress[]>([]);
    const filteredShips = ships.filter(s => s.deleted === 0);
    const selectedDeletedShip = ships
        .filter(s => s.deleted === 1)
        .find(s => selectedVoyage?.shipId === s.id);

    const validateVoyage = () => {
        const newErrors: VoyageErrors = {};
        if (!voyage.shipId && !location.state) {
            newErrors.shipId = intl.formatMessage(Messages.shipNameIsRequired);
        }
        if (!voyage.invoiceAddress) {
            newErrors.invoiceAddress = intl.formatMessage(Messages.invoiceAddressIdIsRequired);
        }
        if (!voyage.contactDetails) {
            newErrors.contactDetails = intl.formatMessage(Messages.contactDetailsAreRequired);
        }
        return newErrors;
    };

    const handleSubmit = async () => {
        const newErrors = validateVoyage();
        if (Object.keys(newErrors).length) {
            setErrors(newErrors);
            return;
        }
        setLoading(true);
        try {
            if (!voyage.shipId && !location.state) {
                return;
            }
            if (selectedVoyage) {
                await changeVoyage(voyage);
                dispatch(showBackendMessage(intl, "success", "updating", Messages.voyage));
                return;
            }
            const newVoyage = await addVoyage(voyage);
            dispatch(showBackendMessage(intl, "success", "creating", Messages.voyage));
            navigate(`/plan-voyage/${newVoyage.data.id}`);
        } catch {
            dispatch(
                showBackendMessage(
                    intl,
                    "error",
                    selectedVoyage ? "updating" : "creating",
                    Messages.voyage,
                ),
            );
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setInvoices([...addresses.filter(add => add.type === AddressType.InvoiceAddress)]);
        setCharterCompanies([...addresses.filter(add => add.type === AddressType.CharterCompany)]);
        setContacts([...addresses.filter(add => add.type === AddressType.ContactDetails)]);
    }, [addresses]);

    useEffect(() => {
        setVoyage(
            selectedVoyage
                ? { ...selectedVoyage }
                : location.state
                ? { ...addEditVoyageInit, shipId: location.state }
                : addEditVoyageInit,
        );
    }, [location.state, selectedVoyage]);

    return (
        <>
            <form className="ld-voyage-form">
                <TextField
                    value={voyage.name}
                    disabled={loading || disableShipInput}
                    label={intl.formatMessage(Messages.name)}
                    onChange={e => setVoyage({ ...voyage, name: e.target.value })}
                    variant="filled"
                />

                <Autocomplete
                    id="select-ship"
                    options={
                        selectedDeletedShip
                            ? [...filteredShips, selectedDeletedShip]
                            : filteredShips
                    }
                    getOptionLabel={o => `${o.name} (${o.eni})`}
                    isOptionEqualToValue={(a, b) => a.id === b.id}
                    disabled={loading || disableShipInput}
                    renderInput={params => (
                        <TextField
                            required
                            variant="filled"
                            {...params}
                            label={intl.formatMessage(Messages.ship)}
                            error={Boolean(errors.shipId)}
                            helperText={
                                errors.shipId ? intl.formatMessage(Messages.shipNameIsRequired) : ""
                            }
                        />
                    )}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.name} ({option.eni})
                            </li>
                        );
                    }}
                    onChange={(_, value: IShip | null) =>
                        setVoyage({ ...voyage, shipId: value?.id ?? "" })
                    }
                    value={
                        filteredShips.find(s => s.id === (location.state ?? voyage.shipId)) ?? null
                    }
                />

                <Autocomplete
                    id="select-invoice"
                    options={invoices}
                    disabled={loading || disableShipInput}
                    getOptionLabel={o =>
                        `${o.company}, ${o.street} ${o.streetNo}, ${o.zip} ${o.city}`
                    }
                    isOptionEqualToValue={(a, b) => a.id === b.id}
                    renderInput={params => (
                        <TextField
                            required
                            variant="filled"
                            {...params}
                            label={intl.formatMessage(Messages.invoiceAddress)}
                            error={Boolean(errors.invoiceAddress)}
                            helperText={errors.invoiceAddress}
                        />
                    )}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.company}, {option.street} {option.streetNo}, {option.zip}{" "}
                                {option.city}
                            </li>
                        );
                    }}
                    onChange={(_, value: IAddress | null) =>
                        setVoyage({ ...voyage, invoiceAddress: value?.id ?? "" })
                    }
                    value={invoices.find(add => add.id === voyage.invoiceAddress) ?? null}
                />

                <Autocomplete
                    id="select-contact"
                    options={contacts}
                    disabled={loading || disableShipInput}
                    getOptionLabel={o => `${o.firstName} ${o.surname}, ${o.tel}`}
                    isOptionEqualToValue={(a, b) => a.id === b.id}
                    renderInput={params => (
                        <TextField
                            required
                            variant="filled"
                            {...params}
                            label={intl.formatMessage(Messages.contactDetails)}
                            error={Boolean(errors.contactDetails)}
                            helperText={errors.contactDetails}
                        />
                    )}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.firstName} {option.surname}, {option.tel}
                            </li>
                        );
                    }}
                    onChange={(_, value: IAddress | null) =>
                        setVoyage({ ...voyage, contactDetails: value?.id ?? "" })
                    }
                    value={contacts.find(add => add.id === voyage.contactDetails) ?? null}
                />

                <Autocomplete
                    id="select-charter-company"
                    options={charterCompanies}
                    disabled={loading || disableShipInput}
                    getOptionLabel={o =>
                        `${o.company}${o.street && ", " + o.street}${
                            o.streetNo && " " + o.streetNo
                        }${o.zip && ", " + o.zip} ${o.city && " " + o.city}`
                    }
                    isOptionEqualToValue={(a, b) => a.id === b.id}
                    renderInput={params => (
                        <TextField
                            variant="filled"
                            {...params}
                            label={intl.formatMessage(Messages.charterCompany)}
                        />
                    )}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {`${option.company}${option.street && ", " + option.street}${
                                    option.streetNo && " " + option.streetNo
                                }${option.zip && ", " + option.zip} ${
                                    option.city && " " + option.city
                                }`}
                            </li>
                        );
                    }}
                    onChange={(_, value: IAddress | null) =>
                        setVoyage({ ...voyage, charterCompany: value?.id ?? "" })
                    }
                    value={charterCompanies.find(add => add.id === voyage.charterCompany) ?? null}
                />

                <Button
                    id="save-changes"
                    disabled={loading || disableShipInput}
                    variant="contained"
                    onClick={() =>
                        displayConfirm
                            ? withConfirm({
                                  severity: "primary",
                                  message: intl.formatMessage(Messages.confirmVoyageData),
                                  onConfirm: handleSubmit,
                              })
                            : handleSubmit()
                    }
                >
                    {intl.formatMessage(Messages.saveChanges)}
                </Button>
            </form>
        </>
    );
};

export default VoyageForm;
