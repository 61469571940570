import { useIntl } from "react-intl";
import Messages from "../../../../localization/Messages";
import { IDispatcherAddress } from "../../../../models/address";
import "./AddressDataContainer.css";

/**
 * Props for the AddressDataContainer component.
 */
interface IAddressDataContainerProps {
    address: IDispatcherAddress;
}

/**
 * A component that displays the address data.
 */

export const AddressDataContainer = ({ address }: IAddressDataContainerProps) => {
    const intl = useIntl();

    return (
        <div className="address-data-container column-form two-column-form">
            {address.company && (
                <div className="address-data-field">
                    <h5>{intl.formatMessage(Messages.companyName)}</h5>
                    <p>{address.company}</p>
                </div>
            )}

            {address.addition && (
                <div className="address-data-field">
                    <h5>{intl.formatMessage(Messages.addition)}</h5>
                    <p>{address.addition}</p>
                </div>
            )}

            {address.street && (
                <div className="address-data-field">
                    <h5>{intl.formatMessage(Messages.street)}</h5>
                    <p>{address.street}</p>
                </div>
            )}

            {address.streetNo && (
                <div className="address-data-field">
                    <h5>{intl.formatMessage(Messages.streetNo)}</h5>
                    <p>{address.streetNo}</p>
                </div>
            )}

            {address.zip && (
                <div className="address-data-field">
                    <h5>{intl.formatMessage(Messages.zip)}</h5>
                    <p>{address.zip}</p>
                </div>
            )}

            {address.city && (
                <div className="address-data-field">
                    <h5>{intl.formatMessage(Messages.city)}</h5>
                    <p>{address.city}</p>
                </div>
            )}

            {address.country && (
                <div className="address-data-field">
                    <h5>{intl.formatMessage(Messages.country)}</h5>
                    <p>{address.country}</p>
                </div>
            )}

            {address.firstName && (
                <div className="address-data-field">
                    <h5>{intl.formatMessage(Messages.firstName)}</h5>
                    <p>{address.firstName}</p>
                </div>
            )}

            {address.surname && (
                <div className="address-data-field">
                    <h5>{intl.formatMessage(Messages.surname)}</h5>
                    <p>{address.surname}</p>
                </div>
            )}

            {address.tel && (
                <div className="address-data-field">
                    <h5>{intl.formatMessage(Messages.telephone)}</h5>
                    <p>{address.tel}</p>
                </div>
            )}

            {address.email && (
                <div className="address-data-field">
                    <h5>{intl.formatMessage(Messages.email)}</h5>
                    <p>{address.email}</p>
                </div>
            )}
        </div>
    );
};
