import axios, { AxiosResponse } from "axios";
import { ApiSearchResponse } from "../models/common";
import { ISearchParams, searchParamsInit } from "../models/searchParams";
import {
    IBooking,
    ConfirmBookingParam,
    DeclineBookingParam,
    PendingBookingParam,
    IBookingActualTimes,
} from "../models/booking";
import { IAllocationPlan } from "../models/allocationPlan";
import { IAllocationPlanParams, allocationPlanParamsInit } from "../models/allocationPlanParams";
import { Roles, UserData } from "../models/userData";

export const confirmBooking = async (
    confirmBooking: ConfirmBookingParam,
): Promise<AxiosResponse<IBooking>> => {
    return await axios.post("/dispatcher/confirmBooking", confirmBooking);
};

export const declineBooking = async (
    declineBooking: DeclineBookingParam,
): Promise<AxiosResponse<IBooking>> => {
    return await axios.post("/dispatcher/declineBooking", declineBooking);
};

export const rebuildBookingSearch = async (searchText: string): Promise<AxiosResponse<string>> => {
    return await axios.post(`/dispatcher/rebuildBookingSearch/`, `"${searchText}"`);
};

export const getBooking = async (id: string): Promise<AxiosResponse<IBooking>> => {
    return await axios.get(`/dispatcher/getBooking/${id}`);
};

export const searchBookings = async (
    searchParams: ISearchParams = searchParamsInit,
): ApiSearchResponse<IBooking> => {
    return await axios.post("/dispatcher/searchBooking", searchParams);
};

export const sendBookingResponse = async (ids: number[]): Promise<AxiosResponse<string>> => {
    return await axios.post("/dispatcher/sendBookingResponse", { bookingIds: ids });
};

export const pendingBooking = async (
    pendingBooking: PendingBookingParam,
): Promise<AxiosResponse<IBooking>> => {
    return await axios.post("/dispatcher/pendingBooking", pendingBooking);
};

const getPublicAllocationPlan = async (
    allocationPlans: IAllocationPlanParams = allocationPlanParamsInit,
): Promise<IAllocationPlan> => {
    const response = await fetch("/api/dispatcher/getAllocationPlan", {
        method: "POST",
        body: JSON.stringify(allocationPlans),
        headers: {
            "Content-Type": "application/json",
        },
    });
    return await response.json();
};

export const updateActualTimes = async (
    actualTimes: IBookingActualTimes,
): Promise<AxiosResponse<IBooking>> => {
    return await axios.post("/dispatcher/updateActualTimes", actualTimes);
};

const getLocksAndDocksAllocationPlan = async (
    allocationPlans: IAllocationPlanParams = allocationPlanParamsInit,
): Promise<IAllocationPlan> => {
    const result = await axios.post("/dispatcher/getAllocationPlan", allocationPlans);
    return result.data;
};

export const getAllocationPlan = (
    allocationPlans: IAllocationPlanParams = allocationPlanParamsInit,
    user: UserData,
) => {
    if (user.roles.includes(Roles.LOCKS_AND_DOCKS_DISPATCHER))
        return getLocksAndDocksAllocationPlan(allocationPlans);
    else return getPublicAllocationPlan(allocationPlans);
};

export const changeBookingComment = async (
    bookingId: number,
    comment: string | null,
): Promise<AxiosResponse<IBooking>> => {
    return await axios.patch("/dispatcher/changeBookingComment", { bookingId, comment });
};

export const updateSharedRemark = async (
    bookingId: number,
    sharedRemark: string | null,
): Promise<AxiosResponse<IBooking>> => {
    return await axios.post("/dispatcher/updateSharedRemark", { bookingId, sharedRemark });
};

export const setFinalizedBooking = async (
    bookingId: number,
    finalized: boolean,
): Promise<AxiosResponse<IBooking>> => {
    return await axios.post("/dispatcher/setFinalizedBooking", { bookingId, finalized });
};
