import { useState, ComponentType } from "react";
import { useMediaQuery, Button, ButtonProps } from "@mui/material";
import RpisDialog from "../containers/RpisDialog/RpisDialog";

export type WithResponsiveDialogProps = {
    dialogTitle: string;
    buttonProps?: Omit<ButtonProps, "onClick">;
    alwaysDisplayButton?: boolean;
};

/**
 * Higher-order component that adds responsive dialog functionality to a wrapped component.
 *
 * @template P - The props type of the wrapped component.
 * @param {ComponentType<P>} WrappedComponent - The component to be wrapped.
 * @param {string} [cssBreakpointWidth="500px"] - The CSS breakpoint width for the media query.
 * @returns {React.FC<P & WithResponsiveDialogProps>} - The wrapped component with responsive dialog functionality.
 */
export const withResponsiveDialog = <P extends object>(
    WrappedComponent: ComponentType<P>,
    cssBreakpointWidth: string = "500px",
) => {
    // eslint-disable-next-line react/display-name
    return ({
        alwaysDisplayButton = false,
        dialogTitle,
        buttonProps = { variant: "contained" },
        ...rest
    }: P & WithResponsiveDialogProps) => {
        const matches = useMediaQuery(`(max-width: ${cssBreakpointWidth})`) || alwaysDisplayButton;
        const { children: buttonPropsChildren, ...buttonPropsOmitted } = buttonProps;
        const [open, setOpen] = useState(false);

        const onOpen = () => setOpen(true);
        const onClose = () => setOpen(false);

        return (
            <>
                {matches ? (
                    <>
                        <Button {...buttonPropsOmitted} onClick={onOpen}>
                            {buttonPropsChildren ?? dialogTitle}
                        </Button>
                        <RpisDialog
                            title={dialogTitle}
                            fullWidth={false}
                            size="md"
                            dialogOpen={open}
                            onClose={onClose}
                            content={<WrappedComponent {...(rest as P)} />}
                        />
                    </>
                ) : (
                    <WrappedComponent {...(rest as P)} />
                )}
            </>
        );
    };
};
