import { BookingStatus } from "../../../models/booking";

export const getBerthingsButtonColor = (bookingStatus: BookingStatus) => {
    switch (bookingStatus) {
        case BookingStatus.PROVISIONALLY_PLANNED:
        case BookingStatus.CONFIRMED:
            return "success";
        case BookingStatus.PROVISIONALLY_DECLINED:
        case BookingStatus.DECLINED:
            return "error";
        case BookingStatus.CHANGE_REQUESTED:
        case BookingStatus.REQUESTED:
            return "warning";
        default:
            return "warning";
    }
};
