import { useDispatch } from "react-redux";
import { getUrlForDownload } from "../../../api/document";
import { IDocument } from "../../../models/document";
import { showBackendMessage } from "../../../helpers/messagesHelper";
import { useIntl } from "react-intl";
import Messages from "../../../localization/Messages";
import { useCallback } from "react";

export function useDownloadDocument() {
    const dispatch = useDispatch();
    const intl = useIntl();

    const download = useCallback(
        async (document: IDocument) => {
            try {
                const res = await getUrlForDownload(document.name, document.path, true);
                window.open(res.data, "_blank");
            } catch {
                dispatch(showBackendMessage(intl, "error", "downloading", Messages.document));
            }
        },
        [dispatch, intl],
    );

    return download;
}
