import { useCallback, useEffect, useState } from "react";
import { ExpenseStatus, IExpense, expenseInit } from "../../models/expense";
import { useIntl } from "react-intl";
import { IExpenseType, expenseCategories, getActivePeriod } from "../../models/expenseType";
import {
    Autocomplete,
    Box,
    Button,
    FormControlLabel,
    Grid,
    InputAdornment,
    TextField,
} from "@mui/material";
import Messages from "../../localization/Messages";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import Switch from "@mui/material/Switch";
import { addExpense, changeExpense, changeInvoiceId } from "../../api/expense";
import { useDispatch, useSelector } from "react-redux";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { IBooking } from "../../models/booking";
import { getBooking, searchBookings } from "../../api/booking";
import BookingDetails from "./components/BookingDetails/BookingDetails";
import { IInvoice, InvoiceStatus } from "../../models/invoice";
import { availableServices } from "../../models/dock";
import { AxiosResponse } from "axios";
import "./ExpenseForm.css";
import { Save } from "@mui/icons-material";
import { AppState } from "../../store/configureStore";
import { addDays, getDateRangeText, subtractDays } from "../../helpers/dateHelper";
import { buildBookingsSearchPayload } from "../ManageBookings/shared/filters";
import { useSidebarOwner } from "../../contexts/SidebarOwnerContext";

type ExpenseErrors = { [Key in keyof Partial<IExpense>]: string };

interface IExpenseForm {
    selectedExpense: IExpense | undefined;
    callback: (expense: IExpense) => void;
    handleCloseSidebar: () => void;
    expenseTypes: IExpenseType[];
    invoices: IInvoice[];
    showBookingDetails?: boolean;
    showTitle?: boolean;
    invoiceId?: number;
    showDeleteButton?: boolean;
    onDeleteExpense?: (id: string) => void;
}

/**
 * Featured in the sidebar of the Manage Expenses page and as a part of the Invoice form , the ExpenseForm component allows users to create new expenses or edit existing ones.
 * If the expense is booking-related, the BookingDetails component is displayed above the form.
 *
 * @component
 * @example
 * ```tsx
 * <ExpenseForm
 *    selectedExpense={selectedExpense}
 *    callback={handleExpenseCallback}
 *    handleCloseSidebar={handleCloseSidebar}
 *    expenseTypes={expenseTypes}
 *    invoices={invoices}
 *    showBookingDetails={true}
 *    showTitle={true}
 *    invoiceId={invoiceId}
 *    showDeleteButton={true}
 *    onDeleteExpense={handleDeleteExpense}
 * />
 * ```
 *
 * @param {IExpense | undefined} selectedExpense - The selected expense to edit, or undefined if creating a new expense.
 * @param {(expense: IExpense) => void} callback - The callback function to handle the created or edited expense.
 * @param {() => void} handleCloseSidebar - The function to handle closing the sidebar.
 * @param {IExpenseType[]} expenseTypes - The list of expense types.
 * @param {IInvoice[]} invoices - The list of invoices.
 * @param {boolean} [showBookingDetails=true] - Whether to show the booking details component.
 * @param {boolean} [showTitle=true] - Whether to show the title of the form.
 * @param {number} [invoiceId] - The ID of the invoice associated with the expense.
 * @param {boolean} [showDeleteButton] - Whether to show the delete button.
 * @param {(id: string) => void} [onDeleteExpense] - The function to handle deleting an expense.
 * @returns {JSX.Element} The ExpenseForm component.
 */
export const ExpenseForm = ({
    selectedExpense,
    callback,
    handleCloseSidebar,
    expenseTypes,
    invoices,
    showBookingDetails = true,
    showTitle = true,
    invoiceId,
    showDeleteButton,
    onDeleteExpense,
}: IExpenseForm) => {
    const [expense, setExpense] = useState<IExpense>(expenseInit);
    const [errors, setErrors] = useState<ExpenseErrors>({});
    const intl = useIntl();
    const [isCustomExpenseType, setIsCustomExpenseType] = useState(false);
    const dispatch = useDispatch();
    const destinationCurrencyMap = useSelector((s: AppState) => s.destinationZones).currencies;
    const [booking, setBooking] = useSidebarOwner<IBooking>();

    const [bookingOptions, setBookingOptions] = useState<IBooking[]>([]);

    useEffect(() => {
        const fetchBookingOptions = async () => {
            try {
                const searchParams = buildBookingsSearchPayload({
                    finalized: null,
                    bookingStatuses: [],
                    docks: [],
                    order: [],
                    shipName: "",
                    page: 0,
                    rowsPerPage: 0,
                    dateFrom: subtractDays(Date.now(), 30),
                    dateTo: addDays(Date.now(), 30),
                });
                const apiResponse = await searchBookings(searchParams);
                const rows = apiResponse.data.rows;
                const data = rows
                    .filter(
                        b =>
                            (b.invoices as IInvoice[])?.length > 0 &&
                            b.invoices?.some(b => b.invoiceStatus === InvoiceStatus.OPEN),
                    )
                    .map(b => {
                        const invoices = [...(b.invoices ?? [])].sort(
                            (a, b) => b.createTs! - a.createTs!,
                        );
                        return { ...b, invoices };
                    });
                setBookingOptions(data);
            } catch (e) {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.bookings));
            }
        };

        fetchBookingOptions();
    }, [dispatch, intl]);

    const fetchBooking = useCallback(async () => {
        if (!selectedExpense?.bookingId) return;
        try {
            const res = await getBooking(selectedExpense.bookingId.toString());
            setBooking(res.data ?? undefined);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.booking));
        }
    }, [dispatch, intl, selectedExpense?.bookingId, setBooking]);

    useEffect(() => {
        // This version of booking is needed only in case of accessing the Expense form from the sidebar in Manage Expenses page.
        // In case of accessing the Expense form in the dialog format, from the Expenses input conponent ,
        // the booking is provided trough the useSidebarOwner hook.
        if (!showBookingDetails) return;
        fetchBooking();
    }, [fetchBooking, showBookingDetails]);

    useEffect(() => {
        setExpense(selectedExpense ? { ...selectedExpense } : expenseInit);
        //Clear booking details if no booking is selected.
        //This evaluation needs to occur only if Expense form is being accessed from the sidebar in Manage Expenses page.
        !selectedExpense?.bookingId && showBookingDetails && setBooking(undefined);
        if (selectedExpense) {
            setIsCustomExpenseType(!!selectedExpense.custom);
        }
    }, [selectedExpense, setBooking, showBookingDetails]);

    const onChange = useCallback((diff: Partial<IExpense>) => {
        setExpense(prev => ({ ...prev, ...diff }));
        const clearErrors = Object.fromEntries(Object.entries(diff).map(([k]) => [k, undefined]));
        setErrors(prev => ({ ...prev, ...clearErrors }));
    }, []);

    const parseNumericOrNull = (input: string, decimal: boolean) => {
        const value = decimal ? Number.parseFloat(input) : Number.parseInt(input);
        return isNaN(value) ? null : decimal ? parseFloat(value.toFixed(2)) : value;
    };

    useEffect(() => {
        if (
            isCustomExpenseType &&
            expenseTypes.find(expenseType => expenseType.id === expense.expenseTypeId)
        ) {
            onChange({
                expenseTypeId: "",
                name: "",
                vat: 0,
                unit: "",
                netPricePerUnit: 0,
                accRef: "",
                category: "",
            });
        }
    }, [expense.expenseTypeId, expenseTypes, isCustomExpenseType, onChange]);

    const validateExpenseForm = () => {
        // eslint-disable-next-line prefer-const
        let newErrors: ExpenseErrors = {};
        if (isCustomExpenseType && !expense.name) {
            newErrors.name = intl.formatMessage(Messages.nameOfExpenseIsRequired);
        }
        if (!isCustomExpenseType && !expense.expenseTypeId) {
            newErrors.expenseTypeId = intl.formatMessage(Messages.expenseTypeIsRequired);
        }
        if (!expense.unit) {
            newErrors.unit = intl.formatMessage(Messages.unitIsRequired);
        }
        if (!expense.netPricePerUnit) {
            newErrors.netPricePerUnit = intl.formatMessage(Messages.netPricePerUnitIsRequired);
        }
        if (expense.vat === null || expense.vat === undefined) {
            newErrors.vat = intl.formatMessage(Messages.vatIsrequired);
        }
        if (!expense.quantity) {
            newErrors.quantity = intl.formatMessage(Messages.quantityIsRequired);
        }
        if (!expense.expenseDate) {
            newErrors.expenseDate = intl.formatMessage(Messages.expenseDateIsRequired);
        }
        if (isNaN(expense.expenseDate)) {
            newErrors.expenseDate = intl.formatMessage(Messages.wrongDateFormat);
        }
        if (!expense.category) {
            newErrors.category = intl.formatMessage(Messages.categoryIsRequired);
        }
        return newErrors;
    };

    const handleSubmit = async () => {
        const newErrors = validateExpenseForm();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        } else {
            try {
                let res: AxiosResponse<IExpense, any>;
                if (selectedExpense && selectedExpense.invoiceId != expense.invoiceId) {
                    await changeInvoiceId(expense.id, expense.invoiceId ?? null);
                }
                const expensePayload = {
                    ...expense,
                    invoiceId: invoiceId ?? expense.invoiceId,
                };
                if (selectedExpense) {
                    res = await changeExpense(expensePayload);
                    dispatch(showBackendMessage(intl, "success", "updating", Messages.expense));
                } else {
                    res = await addExpense(expensePayload);
                    dispatch(showBackendMessage(intl, "success", "adding", Messages.expense));
                }
                callback(res.data);
                setExpense(expenseInit);
                handleCloseSidebar();
            } catch {
                dispatch(showBackendMessage(intl, "error", "adding", Messages.expense));
            }
        }
    };

    return (
        <div className="expense-form">
            {showBookingDetails && booking && (
                <BookingDetails
                    booking={booking}
                    onUpdateActualTimes={() => fetchBooking()}
                    onFinalizeComplete={() => fetchBooking()}
                />
            )}

            {showTitle && (
                <h3>
                    {intl.formatMessage(booking ? Messages.editExpense : Messages.createExpense)}
                </h3>
            )}

            <Grid alignItems="center" container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        fullWidth
                        options={bookingOptions}
                        value={bookingOptions.find(({ id }) => id === expense.bookingId) ?? null}
                        onChange={(_, value) => {
                            const bookingId = value?.id;
                            const invoiceId = value?.invoices?.find(
                                i => i.invoiceStatus === InvoiceStatus.OPEN,
                            )?.id;
                            onChange({ bookingId, invoiceId });
                        }}
                        isOptionEqualToValue={(a, b) => a.id === b.id}
                        getOptionLabel={o =>
                            `${o.shipName}, ${getDateRangeText(
                                o.reqArrivalTime,
                                o.reqDepartureTime,
                            )}`
                        }
                        renderInput={params => (
                            <TextField
                                variant="filled"
                                {...params}
                                label={intl.formatMessage(Messages.booking)}
                            />
                        )}
                        renderOption={(props, o) => (
                            <li {...props} key={o.id}>
                                {o.shipName},{" "}
                                {getDateRangeText(o.reqArrivalTime, o.reqDepartureTime)}
                            </li>
                        )}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        fullWidth
                        id="invoice-company-name"
                        disabled={!!invoiceId}
                        options={invoices}
                        value={
                            invoices.find(
                                invoice =>
                                    invoice.id === (invoiceId ? invoiceId : expense.invoiceId),
                            ) ?? null
                        }
                        onChange={(_, value) => {
                            const invoiceId = value?.id;
                            const bookingId = value?.bookingId;
                            onChange({ invoiceId, bookingId });
                        }}
                        getOptionLabel={o =>
                            `${o.internalInvoiceNumber ?? o.id} - ${o.invoiceCompanyName}`
                        }
                        isOptionEqualToValue={(a, b) => a.id === b.id}
                        renderInput={params => (
                            <TextField
                                variant="filled"
                                {...params}
                                label={intl.formatMessage(Messages.invoice)}
                            />
                        )}
                        renderOption={(props, option) => (
                            <li {...props} key={option.id}>
                                {option.internalInvoiceNumber ?? option.id} -{" "}
                                {option.invoiceCompanyName}
                            </li>
                        )}
                    />
                </Grid>
                <Grid item sm={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isCustomExpenseType}
                                onChange={() => {
                                    setIsCustomExpenseType(prev => !prev);
                                }}
                                inputProps={{ "aria-label": "controlled" }}
                            />
                        }
                        label={intl.formatMessage(Messages.customExpenseType)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    {isCustomExpenseType ? (
                        <TextField
                            fullWidth
                            required
                            variant="filled"
                            label={intl.formatMessage(Messages.expenseType)}
                            error={Boolean(errors.name)}
                            helperText={errors.name}
                            onChange={e => onChange({ name: e.target.value })}
                            value={expense.name}
                        />
                    ) : (
                        <Autocomplete
                            fullWidth
                            id="expense-type"
                            options={expenseTypes}
                            getOptionLabel={option => option.name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={props => (
                                <TextField
                                    required
                                    {...props}
                                    label={intl.formatMessage(Messages.expenseType)}
                                    variant="filled"
                                    error={Boolean(errors.expenseTypeId)}
                                    helperText={errors.expenseTypeId}
                                />
                            )}
                            renderOption={(props, option) => <li {...props}>{option.name}</li>}
                            onChange={(_, value) => {
                                const activePeriod = getActivePeriod(value?.periods);
                                onChange({
                                    expenseTypeId: value?.id,
                                    name: "",
                                    vat: activePeriod?.vat,
                                    accRef: value?.accRef,
                                    unit: value?.unit,
                                    netPricePerUnit: activePeriod?.netPricePerUnit,
                                    category:
                                        expenseCategories.find(ec => ec.value === value?.category)
                                            ?.value ?? "",
                                });
                            }}
                            value={
                                expenseTypes.find(type => type.id === expense.expenseTypeId) ?? null
                            }
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        id="expense-category"
                        options={expenseCategories}
                        getOptionLabel={option => intl.formatMessage(option.label)}
                        isOptionEqualToValue={(a, b) => a.id === b.id}
                        disabled={Boolean(!isCustomExpenseType && expense.expenseTypeId)}
                        renderInput={props => (
                            <TextField
                                required
                                {...props}
                                label={intl.formatMessage(Messages.category)}
                                error={Boolean(errors.category)}
                                helperText={errors.category}
                                variant="filled"
                            />
                        )}
                        renderOption={(props, option) => (
                            <li {...props}>{intl.formatMessage(option.label)}</li>
                        )}
                        onChange={(_, value) => {
                            onChange({ category: value?.value });
                        }}
                        value={
                            expenseCategories.find(cat => cat.value === expense.category) ?? null
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        value={expense.accRef ?? ""}
                        label={intl.formatMessage(Messages.SAPNumber)}
                        error={Boolean(errors.accRef)}
                        helperText={errors.accRef}
                        variant="filled"
                        onChange={e => onChange({ accRef: e.target.value })}
                        disabled={Boolean(!isCustomExpenseType && expense.expenseTypeId)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        required
                        variant="filled"
                        label={intl.formatMessage(Messages.unit)}
                        error={Boolean(errors.unit)}
                        helperText={errors.unit}
                        onChange={e => onChange({ unit: e.target.value })}
                        value={expense.unit ?? ""}
                        disabled={Boolean(!isCustomExpenseType && expense.expenseTypeId)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        required
                        type="number"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {destinationCurrencyMap.entries().next().value[1]}
                                </InputAdornment>
                            ),
                        }}
                        variant="filled"
                        label={intl.formatMessage(Messages.netPricePerUnit)}
                        error={Boolean(errors.netPricePerUnit)}
                        helperText={errors.netPricePerUnit}
                        onKeyDown={e => {
                            ["e", "E", "+"].includes(e.key) && e.preventDefault();
                        }}
                        onChange={e =>
                            onChange({ netPricePerUnit: parseNumericOrNull(e.target.value, true) })
                        }
                        value={expense.netPricePerUnit ?? ""}
                        disabled={Boolean(!isCustomExpenseType && expense.expenseTypeId)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        required
                        variant="filled"
                        type="number"
                        label={intl.formatMessage(Messages.vatNumber)}
                        error={Boolean(errors.vat)}
                        helperText={errors.vat}
                        onKeyDown={e => {
                            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
                        }}
                        onChange={e => {
                            const newValue = e.target.value;

                            if (!newValue) {
                                onChange({ vat: null });
                                return;
                            }
                            if (parseFloat(newValue) <= 100 && parseFloat(newValue) >= 0) {
                                onChange({ vat: parseFloat(newValue) });
                            }
                        }}
                        value={expense.vat ?? ""}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                        disabled={Boolean(!isCustomExpenseType && expense.expenseTypeId)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        required
                        type="number"
                        inputMode="numeric"
                        variant="filled"
                        label={intl.formatMessage(Messages.quantity)}
                        error={Boolean(errors.quantity)}
                        helperText={errors.quantity}
                        onKeyDown={e => {
                            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
                        }}
                        onChange={e =>
                            onChange({ quantity: parseNumericOrNull(e.target.value, true) })
                        }
                        value={expense.quantity ?? ""}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DesktopDatePicker
                        label={intl.formatMessage(Messages.expenseDate)}
                        inputFormat="DD.MM.YYYY"
                        value={expense.expenseDate}
                        onChange={(value: Date | null) => {
                            onChange({ expenseDate: moment(value).valueOf() });
                        }}
                        renderInput={params => (
                            <TextField
                                variant="filled"
                                required
                                {...params}
                                fullWidth
                                helperText={errors.expenseDate}
                                error={Boolean(errors.expenseDate)}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        freeSolo
                        id="ship-autocomplete"
                        options={availableServices}
                        value={expense.purchaseRemarks}
                        onInputChange={(_, value: string) => onChange({ purchaseRemarks: value })}
                        getOptionLabel={o =>
                            o && typeof o === "object" && "label" in o
                                ? intl.formatMessage(o.label)
                                : o
                        }
                        isOptionEqualToValue={(a, b) => a.value === b.value}
                        renderInput={params => (
                            <TextField
                                variant="filled"
                                {...params}
                                label={intl.formatMessage(Messages.purchaseRemarks)}
                            />
                        )}
                        renderOption={(props, option) => (
                            <li {...props} key={option.value}>
                                {intl.formatMessage(option.label)}
                            </li>
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        variant="filled"
                        label={intl.formatMessage(Messages.internalRemarks)}
                        error={Boolean(errors.internalRemarks)}
                        helperText={errors.internalRemarks}
                        onChange={e => onChange({ internalRemarks: e.target.value })}
                        value={expense.internalRemarks}
                    />
                </Grid>
            </Grid>

            <Box className="expense-form-actions">
                {showDeleteButton && onDeleteExpense && (
                    <Button
                        disabled={
                            ![ExpenseStatus.OPEN, ExpenseStatus.NOT_ASSIGNED].includes(
                                expense.status as ExpenseStatus,
                            )
                        }
                        className="delete-button"
                        variant="contained"
                        color="error"
                        onClick={() => onDeleteExpense(expense.id)}
                    >
                        {intl.formatMessage(Messages.delete)}
                    </Button>
                )}

                <Button
                    className="cancel-button"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        handleCloseSidebar();
                    }}
                >
                    {intl.formatMessage(Messages.cancel)}
                </Button>
                <Button
                    startIcon={<Save />}
                    disabled={[
                        ExpenseStatus.DELETED,
                        ExpenseStatus.INVOICED,
                        ExpenseStatus.CANCELLED,
                    ].includes(expense.status as ExpenseStatus)}
                    variant="contained"
                    className="submit-expense-form button-primary"
                    onClick={() => handleSubmit()}
                >
                    {intl.formatMessage(Messages.save)}
                </Button>
            </Box>
        </div>
    );
};

export default ExpenseForm;
