import { useEffect, useState } from "react";
import { searchDestinations } from "../api/destinations";
import { showBackendMessage } from "../helpers/messagesHelper";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import Messages from "../localization/Messages";
import { searchParamsInit } from "../models/searchParams";
import { IDestination } from "../models/destination";

/**
 * Custom hook for fetching destinations.
 * @returns An array of destinations.
 */
export function useFetchDestinations() {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [destinations, setDestinations] = useState<IDestination[]>([]);

    useEffect(() => {
        const fetchDestinations = async () => {
            try {
                const filter = { $and: [{ active: 1 }] };
                const response = await searchDestinations({ ...searchParamsInit, filter });
                const resultSet = response.data.rows ?? [];
                setDestinations(resultSet);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.destinations));
            } finally {
                // NOOP
            }
        };

        fetchDestinations();
    }, [intl, dispatch]);

    return destinations;
}
