import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { searchAddress } from "../api/dispAddress";
import { showBackendMessage } from "../helpers/messagesHelper";
import Messages from "..//localization/Messages";
import { IDispatcherAddress } from "../models/address";
import { FetchFunctionResult, useFetch } from "./useFetch";
import { useState } from "react";
import { DispAddressesPayload } from "../containers/DispManageAddresses/DispManageAddresses";

export function useFetchDispAddresses(
    props: DispAddressesPayload,
): FetchFunctionResult<{ total: number; addresses: IDispatcherAddress[] }> {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [total, setTotal] = useState(0);

    const propsJsonString = JSON.stringify(props);

    const { data, loading, refetch } = useFetch<IDispatcherAddress[]>({
        fetchFn: async () => {
            try {
                const res = await searchAddress(JSON.parse(propsJsonString));
                setTotal(res.data.total);
                return res.data.rows ?? [];
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.addresses));
                return [];
            }
        },
        fetchDeps: [propsJsonString],
    });

    return { data: { total, addresses: data?.length ? data : [] }, loading, refetch };
}
