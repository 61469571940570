import { useState } from "react";
import Button from "@mui/material/Button";
import classNames from "classnames";
import Messages from "../../localization/Messages";
import { useIntl } from "react-intl";
import { useDropzone } from "react-dropzone";
import { displayFileSize } from "../../models/document";
import "./DropzoneForm.css";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DEFAULT_ACCEPTED_TYPES = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "text/plain",
    "audio/mpeg",
    "video/mp4",
    "image/jpeg",
    "text/csv",
    "video/mpeg",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/xml",
];

type DropzoneFormBaseProps = {
    acceptedTypes?: string[];
    maxSize?: number;
};

type DropzoneFormMultiProps = {
    multiple: true;
    onUpload: (files: File[]) => Promise<void>;
};

type DropzoneFormSingleProps = {
    multiple?: false;
    onUpload: (file: File) => Promise<void>;
};

type DropzoneFormProps = DropzoneFormBaseProps & (DropzoneFormSingleProps | DropzoneFormMultiProps);

/**
 * Component for uploading files using react-dropzone package.
 *
 * @component
 * @example
 * // Usage:
 * import DropzoneForm from "./DropzoneForm";
 *
 * const handleFileUpload = async (file: File) => {
 *   // Handle file upload logic here
 * };
 *
 * const App = () => {
 *   return (
 *     <div>
 *       <h1>File Upload</h1>
 *       <DropzoneForm callback={handleFileUpload} />
 *     </div>
 *   );
 * };
 *
 * @param {Object} props - The component props.
 * @param {Function} props.callback - The callback function to be called when a file is uploaded.
 * @returns {JSX.Element} The rendered DropzoneForm component.
 */

export function DropzoneForm({
    multiple,
    onUpload,
    maxSize = 15000000,
}: //acceptedTypes = DEFAULT_ACCEPTED_TYPES,
DropzoneFormProps) {
    const intl = useIntl();
    const [error, setError] = useState("");
    const [dragActive, setDragActive] = useState(false);
    const {
        acceptedFiles: files,
        getRootProps,
        getInputProps,
    } = useDropzone({
        multiple,
    });

    const getErrorMessage = (file: File) => {
        if (file?.size > maxSize) {
            return intl.formatMessage(Messages.fileToBig);
        }
        /*if (!acceptedTypes.includes(file?.type)) {
            return intl.formatMessage(Messages.invalidFileType);
        }*/
        return "";
    };

    const handleUpload = async () => {
        if (!files.length) {
            setError(intl.formatMessage(Messages.noFileSelected));
            return;
        }

        for (const file of files) {
            const errorMessage = getErrorMessage(file);
            if (errorMessage) {
                setError(errorMessage);
                return;
            }
        }

        const resultFiles: any = files;
        onUpload(multiple ? resultFiles : resultFiles[0]);
        files.length = 0;
    };

    return (
        <div className="dropzone-from-container">
            <section id="form-file-upload">
                <div
                    {...getRootProps({
                        className: classNames("dropzone", "dropzone-container", {
                            "drag-active": dragActive,
                            error: error,
                        }),
                        onClick: () => setError(""),
                        onDragOver: () => setDragActive(true),
                        onDragLeave: () => setDragActive(false),
                        onDrop: () => {
                            setError("");
                            setDragActive(false);
                        },
                    })}
                >
                    <input {...getInputProps()} />
                    {error ? <p>{error}</p> : <p>{intl.formatMessage(Messages.dropFileHere)}</p>}
                    {Boolean(files.length) && (
                        <p>
                            {files.length === 1
                                ? `${files[0].name} ${displayFileSize(files[0].size)}`
                                : `${files.length} files selected`}
                        </p>
                    )}
                </div>

                <aside>
                    <Button variant="contained" onClick={handleUpload}>
                        {intl.formatMessage(Messages.upload)}
                    </Button>
                </aside>
            </section>
        </div>
    );
}

export default DropzoneForm;
