import { Reducer } from "redux";
import { IDestination } from "../models/destination";
import { DestinationZonesActionType, SET_DESTINATION_ZONES } from "../actions/destinationsAction";

/**
 * Represents the state of destinations in the Redux store.
 */
export interface DestinationsState {
    data: Map<string, string>;
    names: Map<string, string>;
    currencies: Map<string, string>;
}

/**
 * The initial state of the destinations reducer.
 */
export const initialState: DestinationsState = {
    data: new Map(),
    names: new Map(),
    currencies: new Map(),
};

/**
 * Sets the destinations based on the payload.
 * @param payload - The array of destinations.
 * @returns The updated destinations state.
 */
const setDestinations = (payload: IDestination[]) => {
    const data = new Map();
    const names = new Map();
    const currencies = new Map();
    payload.forEach(({ id, zone, name, currency }) => {
        data.set(id, zone);
        names.set(id, name);
        currencies.set(id, currency);
    });
    return { data, names, currencies };
};

/**
 * The reducer function for destination zones.
 * @param state - The current state of destinations.
 * @param action - The action being dispatched.
 * @returns The updated destinations state.
 */
export const destinationZonesReducer: Reducer<DestinationsState, DestinationZonesActionType> = (
    state: DestinationsState = initialState,
    action: DestinationZonesActionType,
): DestinationsState => {
    switch (action.type) {
        case SET_DESTINATION_ZONES:
            return { ...setDestinations(action.payload) };

        default:
            return state;
    }
};
