export type Holiday = {
    name: string;
    date: string | null;
};

export interface IAddEditDestination {
    id: string;
    address: string;
    active: number;
    city: string;
    community: string;
    contactOnSite: string;
    country: string;
    currency: Currency;
    deleted: 0 | 1;
    email: string;
    accountingEmail: string;
    name: string;
    companyId: string;
    tel: string;
    zip: string;
    zone: string;
    invoiceNoPrefix?: string;
    invoiceNoLength?: number;
    holidays?: Holiday[];
}

export interface IDestination extends IAddEditDestination {
    lastModifyBy: string;
    lastModifyTs: number;
}

export enum Currency {
    EUR = "EUR",
    CHF = "CHF",
}

export const currencies = [Currency.EUR, Currency.CHF];

export const addEditDestinationInit: IAddEditDestination = {
    id: "",
    address: "",
    active: 1,
    city: "",
    community: "",
    contactOnSite: "",
    country: "",
    currency: Currency.EUR,
    deleted: 0,
    email: "",
    accountingEmail: "",
    name: "",
    companyId: "",
    tel: "",
    zip: "",
    zone: "",
    invoiceNoPrefix: "",
    invoiceNoLength: undefined,
};

export const destinationInit: IDestination = {
    ...addEditDestinationInit,
    lastModifyBy: "",
    lastModifyTs: 0,
};

export interface IManagingCompanyResponse {
    id: string;
    name: string;
}
