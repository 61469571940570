export type Action = "NONE" | "DELETE" | "CREATE" | "UPDATE";

export type TreeNode = {
    key: string;
    oldValue: string;
    value: string;
    hasChanged: boolean;
    action?: Action;
    expandedKeys?: string[];
    siblings?: string[];
    children: TreeNode[];
};

export type ChangeLogState = {
    oldValue: string;
    hasChanged: boolean;
    action?: Action;
};

function isNestedObject(object: any) {
    return !Array.isArray(object) && object !== null && typeof object === "object";
}

function equals(obj1: any, obj2: any): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

function isEmpty(value: any): boolean {
    if (value === null || value === undefined) {
        return true;
    }

    if (Array.isArray(value)) {
        return value.length === 0;
    }

    if (typeof value === "string") {
        return value.trim() === "";
    }

    if (typeof value === "object") {
        for (const key in value) {
            // eslint-disable-next-line no-prototype-builtins
            if (value.hasOwnProperty(key) && !isEmpty(value[key])) {
                return false;
            }
        }
        return true;
    }

    return false;
}

function inferAction(previous: any, current: any): Action {
    const isPrevEmpty = isEmpty(previous);
    const isCurrEmpty = isEmpty(current);
    const stringifedJsonPrevious = JSON.stringify(previous);
    const stringifedJsonCurrent = JSON.stringify(current);

    if (stringifedJsonPrevious === stringifedJsonCurrent) {
        return "NONE";
    }

    if (isPrevEmpty && !isCurrEmpty) {
        return "CREATE";
    }

    if (!isPrevEmpty && !isCurrEmpty) {
        return "UPDATE";
    }

    return "DELETE";
}

export default function buildTree(
    entityName: string,
    entityType: string,
    jsonPrevious: object,
    jsonCurrent: object,
    excludeKeys: string[] = [],
): TreeNode {
    const root: TreeNode = {
        key: entityType,
        value: entityName,
        children: [],
        hasChanged: !equals(jsonPrevious, jsonCurrent),
        expandedKeys: [entityType],
        oldValue: entityName,
    };

    function mapArrayToObject(array: any[]) {
        return (array ?? []).reduce((prev, curr, index) => ({ ...prev, [`${index}`]: curr }), {});
    }

    function mergeAndDiff(
        oldValue: Record<string, any>,
        newValue: Record<string, any>,
    ): Record<string, any> {
        // Determine which object has more keys
        const maxKeys = Math.max(Object.keys(oldValue).length, Object.keys(newValue).length);

        const result: Record<string, any> = {};

        for (let i = 0; i < maxKeys; i++) {
            const index = i.toString();

            // If newValue is missing an index, set all keys in oldValue[i] to "undefined"
            // eslint-disable-next-line no-prototype-builtins
            if (!newValue.hasOwnProperty(index) && oldValue.hasOwnProperty(index)) {
                result[index] = {};
                for (const key in oldValue[index]) {
                    result[index][key] = undefined;
                }
            } else {
                // Merge newValue[i] into result[i]
                const isObjectStrategy =
                    typeof oldValue[index] === "object" || typeof newValue[index] === "object";
                if (isObjectStrategy) {
                    result[index] = {
                        ...oldValue[index],
                        ...newValue[index],
                    };
                } else {
                    result[index] = newValue[index];
                }
            }
        }

        return result;
    }

    function excludeKeysFromValue(value: any, excludeKeys: string[]) {
        if (value && typeof value === "object") {
            excludeKeys.forEach(k => delete value[k]);
        }
        return value;
    }

    function traverse(
        previousValue: any,
        currentValue: any,
        parent: TreeNode,
        excludeKeys: string[] = [],
    ) {
        excludeKeysFromValue(previousValue, excludeKeys);
        excludeKeysFromValue(currentValue, excludeKeys);

        const parentKey = parent.key;
        const siblings = Object.keys(currentValue);
        const changedChildren: any[] = [];
        const unchangedChildren: any[] = [];

        for (const childKey of siblings) {
            if (excludeKeys.includes(childKey)) continue;
            const isKeyArrayIndex = /^\d+$/.test(childKey);
            const prevNodeValue = excludeKeysFromValue(previousValue?.[childKey], excludeKeys);
            const currNodeValue = excludeKeysFromValue(currentValue?.[childKey], excludeKeys);
            const propertyPath = `${parentKey}.${childKey}`;
            const hasChanged = !equals(prevNodeValue, currNodeValue);

            // eslint-disable-next-line no-inner-declarations
            function addNode(node: TreeNode): void {
                if (hasChanged || isKeyArrayIndex) {
                    changedChildren.push(node);
                } else {
                    unchangedChildren.push(node);
                }
            }

            if (isNestedObject(currNodeValue)) {
                hasChanged && root.expandedKeys?.push(propertyPath);
                const node: TreeNode = {
                    key: propertyPath,
                    value: childKey,
                    children: [],
                    hasChanged: hasChanged,
                    action: isKeyArrayIndex ? inferAction(prevNodeValue, currNodeValue) : "NONE",
                    oldValue: isKeyArrayIndex ? "" : childKey,
                };
                addNode(node);
                traverse(prevNodeValue, currNodeValue, node, excludeKeys);
            } else if (Array.isArray(currNodeValue)) {
                const prevObject = mapArrayToObject(prevNodeValue);
                const currObject = mapArrayToObject(currNodeValue);
                const mergedNewObject = mergeAndDiff(prevObject, currObject);

                hasChanged && root.expandedKeys?.push(propertyPath);
                const node = {
                    key: propertyPath,
                    value: "",
                    children: [],
                    hasChanged: hasChanged,
                    action: inferAction(prevObject, currObject),
                    oldValue: "",
                };
                addNode(node);
                traverse(prevObject, mergedNewObject, node, excludeKeys);
            } else {
                const valuePrevText = ["null", "undefined"].includes(`${prevNodeValue}`)
                    ? ""
                    : `${prevNodeValue}`;
                const valueCurrText = ["null", "undefined"].includes(`${currNodeValue}`)
                    ? ""
                    : `${currNodeValue}`;
                addNode({
                    key: propertyPath,
                    value: valueCurrText,
                    action: inferAction(valuePrevText, valueCurrText),
                    children: [],
                    hasChanged: hasChanged,
                    oldValue: valuePrevText,
                    siblings,
                });
            }
        }

        // Sort the children with changed nodes first, and unchanged nodes last
        parent.children = [
            ...getSortedNodes(changedChildren),
            ...getSortedNodes(unchangedChildren),
        ];
    }

    function getSortedNodes(nodes: TreeNode[]): TreeNode[] {
        return [...nodes].sort((a, b) => a.key.localeCompare(b.key));
    }

    traverse(jsonPrevious, jsonCurrent, root, excludeKeys);
    return root;
}
