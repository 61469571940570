import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Datatable } from "../../../../components/Datatable/Datatable";
import { IService, ServiceStatus } from "../../../../models/service";
import Messages from "../../../../localization/Messages";
import { availableServices } from "../../../../models/dock";
import { DateFormat, getDateText } from "../../../../helpers/dateHelper";
import { ServiceForm } from "../ServiceForm/ServiceForm";
import { useCallback, useEffect, useState } from "react";
import { searchService } from "../../../../api/service";
import { showBackendMessage } from "../../../../helpers/messagesHelper";
import { Edit } from "@mui/icons-material";
import { TooltipField } from "../../../ManageExpenses/components/BookingDetails/TooltipField";
import { searchParamsInit } from "../../../../models/searchParams";
import { useSidebarOwner } from "../../../../contexts/SidebarOwnerContext";
import { IBooking } from "../../../../models/booking";

export const ServiceSection = () => {
    const intl = useIntl();
    const [selectedService, setSelectedService] = useState<IService>();
    const [serviceFormOpen, setServiceFormOpen] = useState(false);
    const [booking] = useSidebarOwner<IBooking>();
    const dispatch = useDispatch();

    const [services, setServices] = useState<IService[]>([]);

    const fetchServices = useCallback(async () => {
        try {
            const res = await searchService({
                ...searchParamsInit,
                filter: { $and: [{ bookingId: booking!.id }] },
            });
            setServices(res.data.rows ?? []);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.services));
        }
    }, [dispatch, intl, booking]);

    useEffect(() => {
        fetchServices();
    }, [fetchServices]);

    const handleCloseServiceForm = () => {
        setSelectedService(undefined);
        setServiceFormOpen(false);
    };

    const onDeleteService = useCallback(() => {
        setSelectedService(undefined);
        handleCloseServiceForm();
        fetchServices();
    }, [fetchServices]);

    return (
        <Box
            className="service-section-container"
            display="flex"
            justifyContent={"center"}
            flexDirection={"column"}
        >
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Box display={"inline-block"}>
                    <h2>{intl.formatMessage(Messages.services)}</h2>
                </Box>

                <Box maxWidth={"300px"}>
                    <Tooltip
                        sx={{ pointer: "auto" }}
                        title={
                            booking!.finalized &&
                            intl.formatMessage(Messages.addServiceUnavailableMessage)
                        }
                    >
                        <span>
                            <Button
                                disabled={booking!.finalized}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                    setSelectedService(undefined);
                                    setServiceFormOpen(true);
                                }}
                            >
                                {intl.formatMessage(Messages.addService)}
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
            </Box>

            {services.length >= 1 && (
                <Datatable
                    size="small"
                    className="sidebar-services-table"
                    items={services}
                    titleMapper={() => ""}
                    onSortChange={() => {}}
                    sortOrder={[]}
                    columnDefs={[
                        {
                            key: "type",
                            label: intl.formatMessage(Messages.type),
                            sortable: false,
                            content: row =>
                                intl.formatMessage(
                                    availableServices.find(s => s.value === row.type)?.label ??
                                        Messages.unknown,
                                ),
                        },
                        {
                            key: "dateOfService",
                            label: intl.formatMessage(Messages.dateOfService),
                            sortable: false,
                            content: row =>
                                getDateText(
                                    row.data.servicePersonnelData[
                                        row.data.servicePersonnelData.length - 1
                                    ]?.startDateTime,
                                    DateFormat.CLIENT_DATE_TIME,
                                ),
                        },
                        {
                            key: "userName",
                            label: intl.formatMessage(Messages.lastModifiedBy),
                            sortable: false,
                            content: row => (
                                <TooltipField length={20} title={row.lastModifyBy ?? ""} />
                            ),
                        },
                        {
                            key: "actions",
                            label: "Actions",
                            sortable: false,
                            content: row => (
                                <Tooltip
                                    title={
                                        row.finalized
                                            ? intl.formatMessage(
                                                  Messages.serviceEditUnavailableMessage,
                                              )
                                            : row.status !== ServiceStatus.OPEN
                                            ? intl.formatMessage(
                                                  Messages.serviceEditUnavailableMessage2,
                                              )
                                            : ""
                                    }
                                >
                                    <span>
                                        <IconButton
                                            color="primary"
                                            disabled={
                                                !!row.finalized || row.status !== ServiceStatus.OPEN
                                            }
                                            onClick={() =>
                                                !row.finalized &&
                                                (setSelectedService(row), setServiceFormOpen(true))
                                            }
                                        >
                                            <Edit />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            ),
                        },
                    ]}
                    idMapper={row => row.id ?? ""}
                />
            )}

            {serviceFormOpen && (
                <Box marginY={2}>
                    <h2>
                        {intl.formatMessage(
                            selectedService?.id ? Messages.editService : Messages.addService,
                        )}
                    </h2>
                    <ServiceForm
                        bookingId={booking!.id}
                        selectedService={selectedService}
                        onClose={handleCloseServiceForm}
                        onDelete={onDeleteService}
                        onAddService={fetchServices}
                    />
                </Box>
            )}
        </Box>
    );
};
