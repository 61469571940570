import Messages from "../../../../localization/Messages";
import { useIntl } from "react-intl";
import { keycloakBase } from "../../../../Keycloak";
import { Business, ManageAccounts } from "@mui/icons-material";
import {
    Card,
    CardContent,
    MenuList,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import "./CompanyActionsPanel.css";

/**
 * Renders the CompanyActionsPanel component.
 * @returns JSX element representing the CompanyActionsPanel.
 */
export const CompanyActionsPanel = () => {
    const intl = useIntl();

    return (
        <Card sx={{ maxWidth: "100%", width: 300 }}>
            <CardContent sx={{ padding: "1rem !important" }}>
                <Typography variant="h6" color="text.primary">
                    {intl.formatMessage(Messages.companyActions)}
                </Typography>
                <MenuList disablePadding sx={{ paddingTop: 2 }}>
                    <MenuItem
                        component="a"
                        href={`${keycloakBase}/company/my`}
                        sx={{ paddingInline: 1 }}
                    >
                        <ListItemIcon>
                            <Business />
                        </ListItemIcon>
                        <ListItemText>{intl.formatMessage(Messages.editCompanny)}</ListItemText>
                    </MenuItem>
                    <MenuItem
                        sx={{ paddingInline: 1 }}
                        component="a"
                        href={`${keycloakBase}/company/team`}
                    >
                        <ListItemIcon>
                            <ManageAccounts />
                        </ListItemIcon>
                        <ListItemText>{intl.formatMessage(Messages.manageTeam)}</ListItemText>
                    </MenuItem>
                </MenuList>
            </CardContent>
        </Card>
    );
};

export default CompanyActionsPanel;
