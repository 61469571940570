import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showToastMessage } from "../../actions/toastMessageActions";
import { searchShip } from "../../api/ship";
import { importVoyageAndStops, searchVoyages } from "../../api/voyage";
import DropzoneForm from "../../components/DropzoneForm/DropzoneForm";
import StatusDisplay from "../../components/StatusDisplay/StatusDisplay";
import TableCellSorted from "../../components/TableCellSorted";
import { TableSkeleton } from "../../components/TableSkeleton/TableSkeleton";
import { getDateMoment, getDateNumber } from "../../helpers/dateHelper";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { useEnumOptions } from "../../hooks/useEnumOptions";
import { useGuiConfState } from "../../hooks/useGuiConfState";
import { SortOrder, useTableSort } from "../../hooks/useTableSort";
import Messages from "../../localization/Messages";
import { VoyageStatus } from "../../models/booking";
import { ISearchParams, searchParamsInit } from "../../models/searchParams";
import { IShip } from "../../models/ship";
import { IVoyage } from "../../models/voyage";
import DateTimeTwoRowDisplay from "../ManageBookings/components/DateTimeTwoRowDisplay";
import RpisPage from "../RpisPage/RpisPage";
import "./LDManageVoyages.css";
import { ArrowDropDown, Close } from "@mui/icons-material";
import {
    Autocomplete,
    TextField,
    FormControl,
    InputLabel,
    Select,
    SelectChangeEvent,
    IconButton,
    MenuItem,
    Button,
    ButtonGroup,
    Menu,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

export type VoyagesFilters = {
    dateFrom: Date | null;
    dateTo: Date | null;
    shipId: string | undefined;
    voyageStatuses: VoyageStatus[];
    rowsPerPage: number;
    page: number;
    order: SortOrder;
};

type VoyagesApiPayload = ISearchParams;

const buildVoyagesFiltersCriteria = ({
    shipId,
    voyageStatuses,
    dateFrom,
    dateTo,
}: VoyagesFilters) => {
    const filterArray = [];
    if (shipId) {
        filterArray.push({ shipId: shipId });
    }
    if (dateFrom && !dateTo) {
        const startDate = getDateMoment(dateFrom).toDate(),
            endDate = getDateMoment(dateFrom).toDate();
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 59);
        filterArray.push(
            {
                voyageStart: { $gte: startDate.getTime() },
            },
            { voyageStart: { $lte: endDate.getTime() } },
        );
    }
    if (dateTo && !dateFrom) {
        const startDate = getDateMoment(dateTo).toDate(),
            endDate = getDateMoment(dateTo).toDate();
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 59);
        filterArray.push(
            {
                voyageEnd: { $gte: startDate.getTime() },
            },
            { voyageEnd: { $lte: endDate.getTime() } },
        );
    }
    if (dateTo && dateFrom) {
        const startDate = getDateMoment(dateFrom).toDate(),
            endDate = getDateMoment(dateTo).toDate();
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 59);
        filterArray.push(
            {
                voyageStart: { $gte: startDate.getTime() },
            },
            { voyageEnd: { $lte: endDate.getTime() } },
        );
    }
    if (voyageStatuses.length > 0) {
        const bsFilterArray: object[] = [];
        voyageStatuses.forEach(st => {
            bsFilterArray.push({ status: st.toString() });
        });
        filterArray.push({ $or: bsFilterArray });
    }
    return { $and: filterArray };
};

function buildPayload(filters: VoyagesFilters): VoyagesApiPayload {
    return {
        offset: filters.page * filters.rowsPerPage,
        limit: filters.rowsPerPage,
        textSearch: "",
        columns: {},
        order: filters.order,
        filter: buildVoyagesFiltersCriteria(filters),
    };
}

const LD_VOYAGES_SORTABLE_COLUMNS = ["id", "name", "voyageStart", "voyageEnd", "status"] as const;

/**
 * This component represents the Locks & Docks Manage Voyages page where users can view all their existing voyages and the status of those voyages.
 * Users can navigate to the Plan Voyage page by selecting an existing voyage or by clicking on the Add Voyage button.
 *
 * The component includes the following features:
 * - Filtering voyages by ship, date range, and voyage status
 * - Sorting voyages by columns such as ID, name, voyage start, voyage end, and status
 * - Pagination for displaying a limited number of voyages per page
 * - Autocomplete search for selecting a ship
 * - Date pickers for selecting the date range
 * - Multiselect dropdown for selecting voyage statuses
 * - Clearing filters functionality
 *
 *
 */
export const LDManageVoyages = () => {
    const intl = useIntl();
    const voyageStatusOptions = useEnumOptions({ VoyageStatus });
    const dispatch = useDispatch();
    const [voyages, setVoyages] = useState<IVoyage[]>([]);
    const [totalVoyages, setTotalVoyages] = useState(0);

    const [filters, setFilters] = useGuiConfState("ldManageVoyages");
    const registerSort = useTableSort<typeof LD_VOYAGES_SORTABLE_COLUMNS>({
        columns: LD_VOYAGES_SORTABLE_COLUMNS,
        value: filters.order,
        onChange: order => setFilters({ order }),
    });

    const [loading, setLoading] = useState(false);
    const [ships, setShips] = useState<IShip[]>([]);
    const navigate = useNavigate();

    const getVoyages = async () => {
        try {
            setLoading(true);
            const res = await searchVoyages(buildPayload(filters));
            setVoyages(res.data.rows);
            setTotalVoyages(res.data.total ?? 0);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.voyages));
        } finally {
            setLoading(false);
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedGetVoyages = useCallback(
        debounce(() => {
            getVoyages();
        }, 300),
        [filters],
    );

    useEffect(() => {
        debouncedGetVoyages();
        return () => debouncedGetVoyages.cancel();
    }, [debouncedGetVoyages]);

    const handleChangePage = (_event: unknown, newPage: number) => {
        setFilters({ page: newPage });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilters({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
        });
    };

    const getShips = useCallback(async () => {
        try {
            setLoading(true);
            const res = await searchShip({
                ...searchParamsInit,
                showDeleted: true,
            } as ISearchParams);
            setShips(res.data.rows ?? []);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.ships));
        } finally {
            setLoading(false);
        }
    }, [intl, dispatch]);

    useEffect(() => {
        getShips();
    }, [getShips]);

    const clearFilters = () => {
        setFilters({
            shipId: undefined,
            dateFrom: null,
            voyageStatuses: [],
            dateTo: null,
        });
    };

    const [open, setOpen] = useState(false);
    const [importDialogOpen, setImportDialogOpen] = useState(false);
    const menuButtonRef = useRef<HTMLButtonElement>(null);
    const handleToggle = useCallback(() => setOpen(prev => !prev), []);

    return (
        <RpisPage
            title={intl.formatMessage(Messages.manageVoyages)}
            className="ld-manage-voyages-container"
        >
            <div className="manage-voyages-actions-container">
                <Autocomplete
                    id="ship-autocomplete"
                    options={ships}
                    getOptionLabel={o => o.name}
                    isOptionEqualToValue={(a, b) => a.id === b.id}
                    renderInput={params => (
                        <TextField
                            variant="filled"
                            {...params}
                            label={intl.formatMessage(Messages.ship)}
                        />
                    )}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        );
                    }}
                    onChange={(_, value: IShip | null) => setFilters({ shipId: value?.id })}
                    value={ships.find(s => s.id === filters.shipId) ?? null}
                />
                <DesktopDatePicker
                    label={intl.formatMessage(Messages.from)}
                    inputFormat="DD/MM/YYYY"
                    value={filters.dateFrom}
                    onChange={value =>
                        (!isNaN(getDateNumber(value)) || value === null) &&
                        setFilters({ dateFrom: value })
                    }
                    renderInput={params => <TextField variant="filled" {...params} />}
                />
                <DesktopDatePicker
                    label={intl.formatMessage(Messages.to)}
                    inputFormat="DD/MM/YYYY"
                    value={filters.dateTo}
                    onChange={value =>
                        (!isNaN(getDateNumber(value)) || value === null) &&
                        setFilters({ dateTo: value })
                    }
                    renderInput={params => <TextField variant="filled" {...params} />}
                />
                <FormControl className="form-control" variant="filled">
                    <InputLabel id="dbooking-status-label">
                        {intl.formatMessage(Messages.voyageStatus)}
                    </InputLabel>
                    <Select
                        multiple
                        id="booking-status"
                        className="booking-status-multiselect"
                        value={filters.voyageStatuses}
                        label={intl.formatMessage(Messages.voyageStatus)}
                        placeholder={intl.formatMessage(Messages.voyageStatus)}
                        variant="filled"
                        onChange={(event: SelectChangeEvent<VoyageStatus[]>) =>
                            setFilters({
                                voyageStatuses: event.target.value as VoyageStatus[],
                            })
                        }
                        endAdornment={
                            filters.voyageStatuses.length > 0 && (
                                <IconButton onClick={() => setFilters({ voyageStatuses: [] })}>
                                    <Close fontSize="small" />
                                </IconButton>
                            )
                        }
                    >
                        {voyageStatusOptions.map(({ value, label }) => (
                            <MenuItem key={value} value={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div className="action-buttons">
                    {(filters.shipId ||
                        filters.dateFrom ||
                        filters.dateTo ||
                        filters.voyageStatuses.length > 0) && (
                        <Button variant="outlined" onClick={clearFilters}>
                            {intl.formatMessage(Messages.clear)}
                        </Button>
                    )}

                    <ButtonGroup variant="contained">
                        <Button
                            variant={"contained"}
                            onClick={() => navigate(`/plan-voyage/`)}
                            id="addVoyage"
                        >
                            {intl.formatMessage(Messages.addVoyage)}
                        </Button>

                        <Button size="small" ref={menuButtonRef} onClick={handleToggle}>
                            <ArrowDropDown />
                        </Button>
                    </ButtonGroup>

                    <Menu anchorEl={menuButtonRef?.current} open={open} onClose={handleToggle}>
                        <MenuItem
                            onClick={() => {
                                setImportDialogOpen(true);
                                setOpen(false);
                            }}
                        >
                            {intl.formatMessage(Messages.importVoyageAndStopsCsv)}
                        </MenuItem>
                    </Menu>

                    <Dialog open={importDialogOpen}>
                        <DialogTitle>
                            {intl.formatMessage(Messages.importVoyageAndStopsCsv)}
                        </DialogTitle>
                        <DialogContent>
                            <DropzoneForm
                                onUpload={async file => {
                                    const csv = await file.text();
                                    try {
                                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                        const _result = await importVoyageAndStops(csv);
                                        // console.log(result);

                                        dispatch(
                                            showBackendMessage(
                                                intl,
                                                "success",
                                                "creating",
                                                Messages.voyages,
                                            ),
                                        );
                                        setImportDialogOpen(false);
                                        await getVoyages();
                                    } catch (err: any) {
                                        const titleMessage = `${intl.formatMessage(
                                            Messages.errorParsingCsv,
                                        )}: ${err.response.data.message}`;
                                        const html = /*html*/ `
                                        <div>${titleMessage}</div>
                                        <br />
                                        <p>If you don't understand the error message, can't resolve the issue, or believe this is a system error, please contact the system administrator.</p>
                                    `;
                                        dispatch(showToastMessage(html, "error", 0));
                                    }
                                }}
                                acceptedTypes={["text/csv"]}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setImportDialogOpen(false)}>
                                {intl.formatMessage(Messages.cancel)}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
            <TableContainer className="manage-voyages-table-container">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCellSorted
                                align="right"
                                label={intl.formatMessage(Messages.voyageNo)}
                                {...registerSort("id")}
                            />
                            <TableCellSorted
                                label={intl.formatMessage(Messages.name)}
                                {...registerSort("name")}
                            />
                            <TableCell>{intl.formatMessage(Messages.ship)}</TableCell>
                            <TableCell>{intl.formatMessage(Messages.eni)}</TableCell>
                            <TableCellSorted
                                label={intl.formatMessage(Messages.voyageStartTime)}
                                {...registerSort("voyageStart")}
                            />
                            <TableCellSorted
                                label={intl.formatMessage(Messages.voyageEndTime)}
                                {...registerSort("voyageEnd")}
                            />
                            <TableCellSorted
                                label={intl.formatMessage(Messages.statusOfBookings)}
                                {...registerSort("status")}
                            />
                        </TableRow>
                    </TableHead>
                    {!voyages.length && loading ? (
                        <TableSkeleton columns={6} rows={filters.rowsPerPage} />
                    ) : (
                        <TableBody>
                            {voyages.map(row => (
                                <TableRow
                                    key={row.id}
                                    onClick={() => {
                                        navigate(`/plan-voyage/${row.id}`);
                                    }}
                                >
                                    <TableCell align="right">{`#${row.id}`}</TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>
                                        {ships.find(ship => ship.id === row.shipId)?.name}
                                    </TableCell>
                                    <TableCell>
                                        {ships.find(ship => ship.id === row.shipId)?.eni}
                                    </TableCell>
                                    <TableCell>
                                        <DateTimeTwoRowDisplay date={row.voyageStart} />
                                    </TableCell>
                                    <TableCell>
                                        <DateTimeTwoRowDisplay date={row.voyageEnd} />
                                    </TableCell>
                                    <TableCell>
                                        <StatusDisplay voyage={row} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[50, 100, 250, 500]}
                component="div"
                count={totalVoyages}
                rowsPerPage={filters.rowsPerPage}
                page={filters.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </RpisPage>
    );
};

export default LDManageVoyages;
