import { ExpandLess, ExpandMore, RadioButtonChecked } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import { alpha, styled, useTheme } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import { TreeItem, TreeItemProps, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { animated, useSpring } from "@react-spring/web";
import { ReactNode, forwardRef, memo } from "react";
import { TreeNode } from "../utils";
import * as TreeViewUtils from "./utils";
import { IntlShape, useIntl } from "react-intl";
import Messages from "../../../localization/Messages";
import { DateFormat, getDateMoment, getDateText } from "../../../helpers/dateHelper";

function TransitionComponent(props: TransitionProps) {
    const style = useSpring({
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

// eslint-disable-next-line react/display-name
const TransitionedTreeComponent = forwardRef(
    (props: TreeItemProps, ref: React.Ref<HTMLLIElement>) => (
        <TreeItem {...props} TransitionComponent={TransitionComponent} ref={ref} />
    ),
);

const StyledTreeViewNode = styled(TransitionedTreeComponent)(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        "& .close": {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
    [`& .${treeItemClasses.label}`]: {
        whiteSpace: "nowrap",
        fontFamily: TreeViewUtils.MONOSPACE_FONT_FAMILY,
        letterSpacing: "-0.5px",
    },
}));

function normalizeNodeText(nodeText: string): string {
    // Check if the string is a number
    if (/^\d+$/.test(nodeText)) {
        const timestamp = parseInt(nodeText, 10);

        // Add lower limit for what could be a reasonable date
        const lowerLimit =
            getDateMoment("01.01.2000", {
                format: DateFormat.CLIENT_DATE,
            }).unix() * 1000;

        // Validate the date range
        const minDate =
            getDateMoment("01.01.1970", {
                format: DateFormat.CLIENT_DATE,
            }).unix() * 1000;

        const maxDate =
            getDateMoment("31.12.2099", {
                format: DateFormat.CLIENT_DATE,
            }).unix() * 1000;

        if (timestamp >= minDate && timestamp <= maxDate && timestamp > lowerLimit) {
            return getDateText(timestamp, DateFormat.CLIENT_DATE_TIME);
        }
    }

    // Return the original string if conditions aren't met
    return nodeText;
}

function camelCaseToSentenceCase(str: string): string {
    const result = str.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}

function translateKey(intl: IntlShape, propertyPath: string): string {
    const key = TreeViewUtils.getLatestKey(propertyPath);
    const transObj = (Messages as any)[key] ?? {
        id: key,
        defaultMessage: camelCaseToSentenceCase(key),
    };
    return intl.formatMessage(transObj);
}

function NodeItemComponent({ node, render }: { node: TreeNode; render: ChangeLogRender }) {
    const intl = useIntl();
    const fullPath = node.key;
    const latestKey = TreeViewUtils.getLatestKey(fullPath);
    const isChild = !node.children.length;
    const action = node.action!;
    const newValueString = node.value;
    const newValueNormalized =
        latestKey in render ? render[latestKey](newValueString) : normalizeNodeText(newValueString);
    const oldValueNormalized =
        latestKey in render ? render[latestKey](node.oldValue) : normalizeNodeText(node.oldValue);
    const translatedKey = translateKey(intl, latestKey);

    function calculateDotsCount(node: TreeNode, thisText: string): number {
        const allLatestKeysForThisIndentation =
            node.siblings?.map(s => translateKey(intl, s)) ?? [];
        if (!allLatestKeysForThisIndentation.length) return 0;
        const maxLength = Math.max(...allLatestKeysForThisIndentation.map(key => key.length));
        const totalLength = maxLength + TreeViewUtils.MIN_DOTS_COUNT;
        const dotsCount = totalLength - thisText.length;
        return dotsCount < TreeViewUtils.MIN_DOTS_COUNT ? TreeViewUtils.MIN_DOTS_COUNT : dotsCount;
    }

    return (
        <Box display="flex" gap={1}>
            <Box>{translatedKey}</Box>

            {isChild && (
                <Box color={TreeViewUtils.OLD_VALUE_COLOR}>
                    {".".repeat(calculateDotsCount(node, translatedKey))}
                </Box>
            )}

            {isChild && action !== "DELETE" && (
                <Box
                    style={{
                        backgroundColor: TreeViewUtils.NodeItemValueBackgroundColors[action],
                        ...TreeViewUtils.BORDER_RADIUS_STYLE,
                        ...TreeViewUtils.PADDING_INLINE_STYLE,
                    }}
                >
                    {newValueNormalized}
                </Box>
            )}

            {action === "DELETE" && oldValueNormalized && (
                <Box
                    style={{
                        ...TreeViewUtils.BORDER_RADIUS_STYLE,
                        ...TreeViewUtils.PADDING_INLINE_STYLE,
                        textDecoration: "line-through",
                        textDecorationThickness: "1.5px",
                        textDecorationColor: TreeViewUtils.LINE_THROUGH_COLOR,
                        backgroundColor: TreeViewUtils.NodeItemValueBackgroundColors[action],
                    }}
                >
                    {oldValueNormalized}
                </Box>
            )}

            {action === "UPDATE" && oldValueNormalized && (
                <Box color={TreeViewUtils.OLD_VALUE_COLOR}>({oldValueNormalized})</Box>
            )}
        </Box>
    );
}

function TreeViewNode({ node, render }: { node: TreeNode; render: ChangeLogRender }) {
    const { palette } = useTheme();
    const action = node.action ?? "NONE";
    return (
        <StyledTreeViewNode
            key={node.key}
            nodeId={node.key}
            label={<NodeItemComponent node={node} render={render} />}
            style={{ color: TreeViewUtils.getActionTextColor(node, palette) }}
            endIcon={<RadioButtonChecked color={TreeViewUtils.ActionColor[action]} />}
            ContentProps={{
                style: {
                    ...TreeViewUtils.BORDER_RADIUS_STYLE,
                    backgroundColor: TreeViewUtils.NodeItemBackgroundColors[action],
                },
            }}
        >
            {Array.isArray(node.children) &&
                node.children.map(n => <TreeViewNode key={n.key} node={n} render={render} />)}
        </StyledTreeViewNode>
    );
}

export type ChangeLogRender = Record<string, (value: string) => ReactNode>;

/**
 * Represents a tree view component for displaying change logs.
 *
 * This component renders a hierarchical tree structure based on the provided `node` prop.
 * Each node in the tree represents a change log entry and can have child nodes.
 * The `render` prop is used to customize the rendering of specific node values.
 *
 * @component
 * @param {object} props - The component props.
 * @param {TreeNode} props.node - The root node of the tree.
 * @param {ChangeLogRender} [props.render] - An optional object that maps specific node keys to custom rendering functions.
 * @returns {JSX.Element} The rendered tree view component.
 */
const ChangeLogTreeView = ({ node, render = {} }: { node: TreeNode; render: ChangeLogRender }) => {
    return (
        <Box sx={TreeViewUtils.TREE_VIEW_CONTAINER_STYLES}>
            <TreeView
                defaultExpanded={node.expandedKeys}
                defaultCollapseIcon={<ExpandMore />}
                defaultExpandIcon={<ExpandLess />}
                sx={TreeViewUtils.TREE_VIEW_STYLES}
            >
                <TreeViewNode node={node} render={render} />
            </TreeView>
        </Box>
    );
};

export default memo(ChangeLogTreeView);
