import { AlertColor } from "@mui/material";

export const SHOW_TOAST_MESSAGE = "SHOW_TOAST_MESSAGE";

/**
 * Action to show a toast message.
 * @param text - The text of the message.
 * @param severity - The severity of the message.
 * @param life - The duration of the message in milliseconds.
 * @returns The action object.
 */
export const showToastMessage = (text: string, severity: AlertColor = "info", life = 3000) => {
    return { type: SHOW_TOAST_MESSAGE, text, severity, life };
};
