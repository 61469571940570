import { Box, Tooltip, Typography, IconButton } from "@mui/material";
import { BookingDataSource, IBooking, IBookingActualTimes } from "../../../../models/booking";
import "./BookingDetails.css";
import { DateFormat, getDateMoment, getDateText } from "../../../../helpers/dateHelper";
import moment from "moment";
import { useIntl } from "react-intl";
import Messages from "../../../../localization/Messages";
import { useEffect, useState } from "react";
import { updateActualTimes } from "../../../../api/booking";
import { useDispatch, useSelector } from "react-redux";
import { showBackendMessage } from "../../../../helpers/messagesHelper";
import { searchAddress } from "../../../../api/dispAddress";
import { IAddress } from "../../../../models/address";
import DateTimePickerInline from "../../../../components/DateTimePickerInline";
import SaveIcon from "@mui/icons-material/Save";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BusinessIcon from "@mui/icons-material/Business";
import { searchBerthings } from "../../../../api/berthing";
import { searchParamsInit } from "../../../../models/searchParams";
import DockPopper from "../DockPopper/DockPopper";
import { TooltipField } from "./TooltipField";
import { DirectionsBoat } from "@mui/icons-material";
import { AppState } from "../../../../store/configureStore";
import RpisDialog from "../../../RpisDialog/RpisDialog";
import { getShipDisp } from "../../../../api/ship";
import { ViewShipContainer } from "../ViewShipContainer/ViewShipContainer";
import { IShipDispatcher } from "../../../../models/shipDispatcher";
import { FinalizeBookingSection } from "../../../OnCallContainers/OCManageBookings/components/FinalizeBookingSection/FinalizeBookingSection";
import { UploadBookingDocuments } from "../../../OnCallContainers/OCManageBookings/components/UploadBookingDocuments/UploadBookingDocuments";
import { EditSharedRemark } from "../../../ManageBookings/components/EditSharedRemark/EditSharedRemark";
import { EditDispatcherRemark } from "../../../ManageBookings/components/EditDispatcherRemark/EditDispatcherRemark";
import { Roles } from "../../../../models/userData";
import { useAuth } from "../../../../hooks/useAuth";

type BookingDetailsProps = {
    onFinalizeComplete: () => void;
    booking: IBooking;
    onUpdateActualTimes?: () => void;
};

type TimeErrors = { [Key in keyof Partial<IBookingActualTimes>]: string };

/**
 * Renders the booking details section displayed in the sidebar of Manage Invoices and Manage Expenses pages
 * when the Invoice/Expense is related to a booking.
 *
 * This component displays the booking information, including the booking number, ship name, planned arrival time,
 * estimated arrival time, actual arrival time, and actual departure time. It also allows the user to change the
 * actual arrival and departure times of the booking.
 *
 * @component
 * @param {BookingDetailsProps} props - The props for the BookingDetails component.
 * @param {IBooking} props.booking - The booking object containing the booking details.
 * @returns {JSX.Element} The BookingDetails component.
 */
export const BookingDetails = ({
    onFinalizeComplete,
    booking,
    onUpdateActualTimes,
}: BookingDetailsProps) => {
    const managingCompanies = useSelector((s: AppState) => s.managingCompanies).data;
    const intl = useIntl();
    const [bookingActualTimes, setBookingActualTimes] = useState<IBookingActualTimes>({
        actualArrivalTime: booking.actualArrivalTime ?? null,
        actualDepartureTime: booking.actualDepartureTime ?? null,
        bookingId: booking.id,
    });
    const [errors, setErrors] = useState<TimeErrors>({});
    const dispatch = useDispatch();

    const [owningCompany, setOwningCompany] = useState<IAddress | undefined>(undefined);
    const [dockIds, setDockIds] = useState<string[]>([]);
    const [ship, setShip] = useState<IShipDispatcher>();
    const [viewShipData, setViewShipData] = useState(false);

    const { hasRole } = useAuth();
    const isOnCallUser = hasRole(Roles.ON_CALL_DISPATCHER);

    useEffect(() => {
        //clear docks and owningcompany
        setDockIds([]);
        setOwningCompany(undefined);

        const getBerthings = async () => {
            try {
                const res = await searchBerthings({
                    ...searchParamsInit,
                    filter: { $and: [{ bookingId: booking.id }] },
                });
                setDockIds(res.data.rows.map(berthing => berthing.dockId));
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.berthings));
            }
        };
        getBerthings();

        const fetchOwningCompany = async () => {
            if (booking.ship.owningCompanyAddressId) {
                try {
                    const res = await searchAddress({
                        ...searchParamsInit,
                        filter: { $and: [{ docksId: booking.ship.owningCompanyAddressId }] },
                    });
                    setOwningCompany(res.data.rows[0]);
                } catch {
                    dispatch(showBackendMessage(intl, "error", "fetching", Messages.owningCompany));
                }
            } else {
                setOwningCompany(undefined);
            }
        };

        fetchOwningCompany();

        const fetchShip = async () => {
            try {
                const res = await getShipDisp(booking.shipId);
                setShip(res.data);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.ship));
            }
        };
        fetchShip();

        setBookingActualTimes({
            actualArrivalTime: booking.actualArrivalTime ?? null,
            actualDepartureTime: booking.actualDepartureTime ?? null,
            bookingId: booking.id,
        });
    }, [booking, dispatch, intl]);

    const onChange = (diff: Partial<IBookingActualTimes>) => {
        setBookingActualTimes(prev => ({ ...prev, ...diff }));
        const clearErros = Object.fromEntries(Object.entries(diff).map(([k]) => [k, undefined]));
        setErrors({ ...errors, ...clearErros });
    };

    const validateTimes = () => {
        const newErrors: TimeErrors = {};

        if (
            bookingActualTimes.actualArrivalTime &&
            bookingActualTimes.actualDepartureTime &&
            bookingActualTimes.actualArrivalTime >= bookingActualTimes.actualDepartureTime
        ) {
            newErrors.actualDepartureTime = intl.formatMessage(
                Messages.arrivalTimeMustBeBeforeDepartureTime,
            );
        }

        if (
            bookingActualTimes.actualArrivalTime !== null &&
            isNaN(bookingActualTimes.actualArrivalTime)
        ) {
            newErrors.actualArrivalTime = intl.formatMessage(Messages.wrongDateFormat);
        }

        if (
            bookingActualTimes.actualDepartureTime !== null &&
            isNaN(bookingActualTimes.actualDepartureTime)
        ) {
            newErrors.actualDepartureTime = intl.formatMessage(Messages.wrongDateFormat);
        }

        return newErrors;
    };

    const changeBookingActualTimes = async () => {
        const newErrors = validateTimes();
        if (Object.keys(newErrors).length) {
            setErrors(newErrors);
        } else {
            try {
                await updateActualTimes(bookingActualTimes);
                dispatch(showBackendMessage(intl, "success", "updating", Messages.booking));
                onUpdateActualTimes && onUpdateActualTimes();
            } catch {
                dispatch(showBackendMessage(intl, "error", "updating", Messages.booking));
            }
        }
    };

    const onSubmit = async () => {
        onFinalizeComplete();
    };

    return (
        <div className="booking-details-container">
            <Box width={"85%"} my={"10px"} display={"flex"} justifyContent={"space-between"}>
                <Box display={"inline-flex"} alignItems={"center"}>
                    <Typography className="booking-number">{`#${booking.id}`}</Typography>
                    <Typography>
                        <IconButton onClick={() => setViewShipData(prev => !prev)}>
                            <DirectionsBoat color="action" />
                        </IconButton>
                    </Typography>
                    <Typography
                        className="ship-name"
                        display={"inline-block"}
                        style={{ cursor: "pointer" }}
                        onClick={() => setViewShipData(prev => !prev)}
                    >
                        {booking.ship.name}
                    </Typography>
                </Box>
                <Box display={"inline-flex"} alignItems={"center"} gap={0.2}>
                    <FinalizeBookingSection onFinalizeComplete={onFinalizeComplete} />

                    <UploadBookingDocuments />
                    {!isOnCallUser && <EditDispatcherRemark onSubmit={onSubmit} />}

                    <EditSharedRemark onSubmit={onSubmit} />
                </Box>
            </Box>

            <Box display="flex">
                <Box
                    className="box-left"
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    alignItems="flex-end"
                >
                    <Box display="flex">
                        <Typography display={"inline-block"} mr={"2.8rem"}>
                            {intl.formatMessage(Messages.planned)}
                        </Typography>
                        <Tooltip
                            placement="bottom-end"
                            title={intl.formatMessage(Messages.estimatedArrivalTime)}
                        >
                            <Typography alignSelf={"flex-end"}>
                                {getDateText(booking.arrivalTime, DateFormat.CLIENT_DATE_TIME)}
                            </Typography>
                        </Tooltip>
                    </Box>

                    <Box>
                        <Typography display="inline-block">
                            {intl.formatMessage(Messages.actual)}
                        </Typography>
                        <Tooltip
                            placement="bottom-end"
                            title={intl.formatMessage(Messages.actualArrivalTime)}
                        >
                            <Box display={"inline-block"}>
                                <DateTimePickerInline
                                    id={`ATA#${booking.id}`}
                                    value={bookingActualTimes.actualArrivalTime ?? null}
                                    onFocusHandler={() =>
                                        !bookingActualTimes.actualArrivalTime &&
                                        onChange({ actualArrivalTime: booking.arrivalTime })
                                    }
                                    format={DateFormat.CLIENT_DATE_TIME}
                                    onChange={value => {
                                        const newAta = getDateMoment(value).valueOf();

                                        onChange({
                                            actualArrivalTime: isNaN(newAta) ? null : newAta,
                                        });
                                    }}
                                    error={errors.actualArrivalTime}
                                    className={
                                        booking.ataSource === BookingDataSource.AIS
                                            ? "ais-data-source"
                                            : ""
                                    }
                                />
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
                <Box className="box-right" display="flex" flexDirection="column" gap={1}>
                    <Tooltip
                        placement="bottom-end"
                        title={intl.formatMessage(Messages.estimatedDepartureTime)}
                    >
                        <Typography alignSelf={"flex-end"} mr={"12px"}>
                            {getDateText(booking.departureTime, DateFormat.CLIENT_DATE_TIME)}
                        </Typography>
                    </Tooltip>

                    <Tooltip
                        placement="bottom-end"
                        title={intl.formatMessage(Messages.actualDepartureTime)}
                    >
                        <Box>
                            <DateTimePickerInline
                                id={`ATA#${booking.id}`}
                                onFocusHandler={() =>
                                    !bookingActualTimes.actualDepartureTime &&
                                    onChange({ actualDepartureTime: booking.departureTime })
                                }
                                format={DateFormat.CLIENT_DATE_TIME}
                                value={bookingActualTimes.actualDepartureTime ?? null}
                                minDate={moment(bookingActualTimes.actualArrivalTime)}
                                onChange={value => {
                                    const newAtd = getDateMoment(value).valueOf();
                                    onChange({
                                        actualDepartureTime: isNaN(newAtd) ? null : newAtd,
                                    });
                                }}
                                error={errors.actualDepartureTime}
                                className={
                                    booking.atdSource === BookingDataSource.AIS
                                        ? "ais-data-source"
                                        : ""
                                }
                            />
                        </Box>
                    </Tooltip>
                </Box>
            </Box>

            <Tooltip placement="bottom-end" title={intl.formatMessage(Messages.saveActualTimes)}>
                <IconButton
                    color="primary"
                    onClick={() => changeBookingActualTimes()}
                    aria-label={intl.formatMessage(Messages.save)}
                >
                    <SaveIcon />
                </IconButton>
            </Tooltip>

            <Box className="booking-info-container">
                <Box className="booking-data-field">
                    <Tooltip placement="bottom-end" title={intl.formatMessage(Messages.dock)}>
                        <LocationOnIcon fontSize="small" className="icon-gray" />
                    </Tooltip>
                    <DockPopper dockIds={dockIds} />
                </Box>

                <Box className="booking-data-field">
                    <Tooltip
                        placement="bottom-end"
                        title={intl.formatMessage(Messages.managingCompany)}
                    >
                        <BusinessIcon fontSize="small" className="icon-gray" />
                    </Tooltip>
                    <TooltipField title={managingCompanies.get(booking.managingCompany) ?? ""} />
                </Box>

                <Box className="booking-data-field">
                    <Tooltip
                        placement="bottom-end"
                        title={intl.formatMessage(Messages.owningCompany)}
                    >
                        <BusinessIcon fontSize="small" className="icon-gray" />
                    </Tooltip>
                    <TooltipField title={owningCompany?.company ?? ""} />
                </Box>
            </Box>
            {ship && (
                <RpisDialog
                    dialogOpen={viewShipData}
                    onClose={() => setViewShipData(false)}
                    title={intl.formatMessage(Messages.viewShipData)}
                    content={<ViewShipContainer ship={ship} />}
                    fullWidth={false}
                    size={"lg"}
                />
            )}
        </div>
    );
};

export default BookingDetails;
