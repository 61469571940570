import { SortOrder } from "../hooks/useTableSort";

export interface ISearchParams {
    offset: number;
    filter: object;
    limit: number;
    columns: object;
    textSearch: string;
    order: SortOrder;
}

export const searchParamsInit: ISearchParams = {
    offset: 0,
    filter: {},
    limit: 0,
    columns: {},
    textSearch: "",
    order: [],
};
