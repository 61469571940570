import { useIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import {
    Autocomplete,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    Box,
    Button,
    Grid,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import { InvoiceFilters as InvoiceFiltersType } from "../../shared/filters";
import { IAddress } from "../../../../models/address";
import Messages from "../../../../localization/Messages";
import { InvoiceStatus, InvoiceStatuses } from "../../../../models/invoice";
import { SelectChangeEvent } from "@mui/material";
import { memo } from "react";
import { IShipDispatcher } from "../../../../models/shipDispatcher";
import { getDateNumber } from "../../../../helpers/dateHelper";

export const INVOICES_SORTABLE_COLUMNS = [
    "id",
    "internalInvoiceNumber",
    "invoiceDate",
    "invoiceCompanyName",
    "totalCostNet",
];

export type InvoiceFiltersProps = {
    filters: InvoiceFiltersType;
    ships: IShipDispatcher[];
    addresses: IAddress[];
    setFilters: (filters: Partial<InvoiceFiltersType>) => void;
};

/**
 * Represents the filters for the invoices table.
 *
 * @component
 * @param {InvoiceFiltersProps} props - The component props.
 * @param {InvoiceFiltersType} props.filters - The current filters.
 * @param {IAddress[]} props.addresses - The list of addresses to search for.
 * @param {(filters: Partial<InvoiceFiltersType>) => void} props.setFilters - The function to call when the filters change.
 * @returns {JSX.Element} The rendered InvoiceFilters component.
 */
export const InvoiceFilters = ({ filters, ships, addresses, setFilters }: InvoiceFiltersProps) => {
    const intl = useIntl();

    const clearFilters = () => {
        setFilters({
            internalInvoiceNumber: undefined,
            dateFrom: null,
            dateTo: null,
            bookingDateFrom: null,
            bookingDateTo: null,
            shipId: null,
            invoiceStatus: [],
        });
    };

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <Autocomplete
                freeSolo
                id="invoice-company-name"
                options={addresses}
                value={filters.internalInvoiceNumber ?? ""}
                onInputChange={(_, value: string) => {
                    if (value.length === 0 || value.length >= 3) {
                        setFilters({ internalInvoiceNumber: value });
                    }
                }}
                getOptionLabel={o => (typeof o === "object" ? o.company ?? "" : o)}
                isOptionEqualToValue={(a, b) => a.id === b.id}
                renderInput={params => (
                    <TextField
                        variant="filled"
                        {...params}
                        label={intl.formatMessage(Messages.search)}
                    />
                )}
                renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                        {option.company}{" "}
                    </li>
                )}
            />
            <Autocomplete
                id="ship-autocomplete"
                options={ships}
                getOptionLabel={o => o.name}
                isOptionEqualToValue={(a, b) => a.id === b.id}
                renderInput={params => (
                    <TextField
                        variant="filled"
                        {...params}
                        label={intl.formatMessage(Messages.ship)}
                    />
                )}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    );
                }}
                onChange={(_, value: IShipDispatcher | null) => setFilters({ shipId: value?.id })}
                value={ships.find(s => s.id === filters.shipId) ?? null}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <DesktopDatePicker
                        label={intl.formatMessage(Messages.fromInvoiceDate)}
                        inputFormat="DD/MM/YYYY"
                        value={filters.dateFrom}
                        onChange={value =>
                            (!isNaN(getDateNumber(value)) || value === null) &&
                            setFilters({ dateFrom: value })
                        }
                        renderInput={params => <TextField variant="filled" {...params} />}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DesktopDatePicker
                        label={intl.formatMessage(Messages.toInvoiceDate)}
                        inputFormat="DD/MM/YYYY"
                        value={filters.dateTo}
                        onChange={value =>
                            (!isNaN(getDateNumber(value)) || value === null) &&
                            setFilters({ dateTo: value })
                        }
                        renderInput={params => <TextField variant="filled" {...params} />}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <DesktopDatePicker
                        label={intl.formatMessage(Messages.fromBookingDate)}
                        inputFormat="DD/MM/YYYY"
                        value={filters.bookingDateFrom}
                        onChange={value =>
                            (!isNaN(getDateNumber(value)) || value === null) &&
                            setFilters({ bookingDateFrom: value })
                        }
                        renderInput={params => <TextField variant="filled" {...params} />}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DesktopDatePicker
                        label={intl.formatMessage(Messages.toBookingDate)}
                        inputFormat="DD/MM/YYYY"
                        value={filters.bookingDateTo}
                        onChange={value =>
                            (!isNaN(getDateNumber(value)) || value === null) &&
                            setFilters({ bookingDateTo: value })
                        }
                        renderInput={params => <TextField variant="filled" {...params} />}
                    />
                </Grid>
            </Grid>

            <FormControl className="form-control" variant="filled">
                <InputLabel>{intl.formatMessage(Messages.invoiceStatus)}</InputLabel>
                <Select
                    multiple
                    id="booking-status"
                    value={filters.invoiceStatus}
                    label={intl.formatMessage(Messages.invoiceStatus)}
                    placeholder={intl.formatMessage(Messages.invoiceStatus)}
                    variant="filled"
                    onChange={(event: SelectChangeEvent<InvoiceStatus[]>) =>
                        setFilters({
                            invoiceStatus: event.target.value as InvoiceStatus[],
                        })
                    }
                    endAdornment={
                        filters.invoiceStatus.length > 0 && (
                            <IconButton onClick={() => setFilters({ invoiceStatus: [] })}>
                                <Close fontSize="small" />
                            </IconButton>
                        )
                    }
                >
                    {InvoiceStatuses.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                            {intl.formatMessage(label)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Box display="flex" gap={2} justifyContent="space-between" flex="1">
                <Box display="flex" gap={2} flex="1">
                    {(filters.dateTo ||
                        filters.dateFrom ||
                        filters.bookingDateTo ||
                        filters.bookingDateFrom ||
                        filters.invoiceStatus.length !== 0 ||
                        filters.internalInvoiceNumber ||
                        filters.shipId) && (
                        <Button variant="outlined" onClick={clearFilters}>
                            {intl.formatMessage(Messages.clear)}
                        </Button>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default memo(InvoiceFilters);
