import { Button } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import RpisPage from "../RpisPage/RpisPage";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SearchIcon from "@mui/icons-material/Search";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { getShipDisp, searchShipDisp } from "../../api/ship";
import { TableSkeleton } from "../../components/TableSkeleton/TableSkeleton";
import TablePagination from "@mui/material/TablePagination";
import { searchParamsInit } from "../../models/searchParams";
import { useDispatch } from "react-redux";
import Messages from "../../localization/Messages";
import "./ManageShips.css";
import { showBackendMessage } from "../../helpers/messagesHelper";
import classNames from "classnames";
import RpisDialog from "../RpisDialog/RpisDialog";
import ShipDataContainer from "../ShipDataContainer/ShipDataContainer";
import SidebarLayout from "../SidebarLayout/SidebarLayout";
import DockAccessForm from "./DockAccessForm";
import ManageDocuments from "../ManageDocuments/ManageDocuments";
import { IShipDispatcher, shipDispatcherInit } from "../../models/shipDispatcher";
import { useSelector } from "react-redux";
import { AppState } from "../../store/configureStore";
import { searchAddress } from "../../api/dispAddress";
import { AddressType, IAddress } from "../../models/address";
import { Serializers, useUrlState } from "../../hooks/useUrlState";
import { SortOrder, useTableSort } from "../../hooks/useTableSort";
import { useGuiConfState } from "../../hooks/useGuiConfState";
import { debounce } from "lodash";
import TableCellSorted from "../../components/TableCellSorted";

export type ManageShipsFilters = {
    textSearch: string;
    page: number;
    rowsPerPage: number;
    order: SortOrder;
};

function buildPayload(filters: ManageShipsFilters) {
    return {
        ...searchParamsInit,
        offset: filters.page * filters.rowsPerPage,
        limit: filters.rowsPerPage,
        textSearch: filters.textSearch,
        order: filters.order,
        columns: {},
        filter: {},
    };
}

const SHIPS_SORTABLE_COLUMNS = [
    "name",
    "eni",
    "mmsi",
    "inactive",
    "docksCompanyId",
    "passengerBeds",
    "complete",
] as const;

/**
 * This component renders the Dispatcher Manage Ships page. Here, the user can manage ships that have made booking requests to the dispatcher's destination.
 * Ships are listed in a table, and the user has the following actions at their disposal:
 * - View Ship: Displays ship data in a dialog.
 * - Edit Dock Access: Opens the Dock Access form for the ship.
 * - Manage Documents: Opens the Manage Documents component and allows the user to add or manage ship documents.
 */
export const ManageShips = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [viewShipData, setViewShipData] = useState(false);
    const [viewDocuments, setViewDocuments] = useState(false);
    const [ships, setShips] = useState<IShipDispatcher[]>([]);
    const [totalShips, setTotalShips] = useState(0);
    const [selectedShip, setSelectedShip] = useState<IShipDispatcher>(shipDispatcherInit);
    const [selectedShipId, setSelectedShipId] = useUrlState(
        "selectedShipId",
        "",
        Serializers.string,
    );
    const [loading, setLoading] = useState(true);
    const managingCompanies = useSelector((s: AppState) => s.managingCompanies);
    const [owningCompanies, setOwningCompanies] = useState<IAddress[]>([]);
    const [filters, setFilters] = useGuiConfState("dispManageShips");
    const registerSort = useTableSort<typeof SHIPS_SORTABLE_COLUMNS>({
        columns: SHIPS_SORTABLE_COLUMNS,
        value: filters.order,
        onChange: order => setFilters({ order }),
    });

    const getShips = useCallback(async () => {
        setLoading(true);
        try {
            const res = await searchShipDisp(buildPayload(filters));
            setShips(res.data.rows ?? []);
            setTotalShips(res.data.total ?? 0);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.ships));
        } finally {
            setLoading(false);
        }
    }, [intl, filters, dispatch]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedGetShips = useCallback(
        debounce(() => {
            getShips();
        }, 300),
        [filters],
    );

    useEffect(() => {
        debouncedGetShips();
        return () => debouncedGetShips.cancel();
    }, [debouncedGetShips]);

    useEffect(() => {
        const persistInitialShip = async () => {
            try {
                const initialShip = await getShipDisp(selectedShipId);
                setSelectedShip(initialShip.data);
                setViewShipData(true);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.ship));
            }
        };
        if (selectedShipId) {
            persistInitialShip();
        }
    }, [selectedShipId, dispatch, intl]);

    const getOwningCompanies = useCallback(async () => {
        try {
            setLoading(true);
            const res = await searchAddress({
                ...searchParamsInit,
                filter: { $and: [{ type: AddressType.OwningCompany }] },
            });
            setOwningCompanies(res.data.rows ?? []);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.owningCompanies));
        } finally {
            setLoading(false);
        }
    }, [dispatch, intl]);

    useEffect(() => {
        getOwningCompanies();
    }, [getOwningCompanies]);

    const onSearchChange = (textSearch: string) => {
        setFilters({ textSearch });
    };

    const handleChangePage = (_event: unknown, newPage: number) => {
        setFilters({ page: newPage });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilters({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
        });
    };

    return (
        <RpisPage
            title={intl.formatMessage(Messages.manageShips)}
            className="disp-manage-ships-container"
        >
            <RpisDialog
                dialogOpen={viewShipData}
                title={intl.formatMessage(Messages.viewShipData)}
                onClose={() => {
                    setViewShipData(false);
                    setSelectedShipId("");
                }}
                size="md"
                fullWidth={true}
                content={
                    <ShipDataContainer
                        selectedShip={selectedShip}
                        owningCompanyName={
                            owningCompanies.find(
                                add => add.id === selectedShip.data?.owningCompanyAddressId,
                            )?.company
                        }
                    />
                }
            />
            <RpisDialog
                dialogOpen={viewDocuments}
                title={intl.formatMessage(Messages.manageDocuments)}
                onClose={() => setViewDocuments(false)}
                size="md"
                fullWidth={false}
                content={
                    <ManageDocuments
                        shipId={selectedShip.id}
                        shipDocuments={selectedShip.documents}
                    />
                }
            />

            <SidebarLayout
                sidebarTitle={`${intl.formatMessage(Messages.editDockAccess)} ${
                    selectedShip ? `(${selectedShip.name})` : ""
                }`}
                open={sidebarOpen}
                onClose={() => setSidebarOpen(false)}
                sidebarContent={
                    <DockAccessForm
                        selectedShip={selectedShip}
                        callback={() => {
                            setSidebarOpen(false);
                            getShips();
                        }}
                    />
                }
            >
                <div className="title-and-actions-container">
                    <div id="search-bar-form">
                        <FormControl variant="filled">
                            <FilledInput
                                autoFocus
                                value={filters.textSearch}
                                onChange={e => onSearchChange(e.target.value)}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                            />
                            <InputLabel>{intl.formatMessage(Messages.search)}</InputLabel>
                        </FormControl>
                    </div>
                </div>
                <TableContainer className="manage-ships-table-container">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCellSorted
                                    label={intl.formatMessage(Messages.shipName)}
                                    {...registerSort("name")}
                                />
                                <TableCellSorted
                                    align="right"
                                    label="ENI"
                                    {...registerSort("eni")}
                                />
                                <TableCellSorted
                                    align="right"
                                    label={intl.formatMessage(Messages.mmsi)}
                                    {...registerSort("mmsi")}
                                />
                                <TableCell align="right">
                                    {intl.formatMessage(Messages.length)}
                                </TableCell>
                                <TableCellSorted
                                    align="right"
                                    label={`${intl.formatMessage(
                                        Messages.active,
                                    )} / ${intl.formatMessage(Messages.inactive)}`}
                                    {...registerSort("inactive")}
                                />
                                <TableCellSorted
                                    align="right"
                                    label={intl.formatMessage(Messages.managingCompany)}
                                    {...registerSort("docksCompanyId")}
                                />
                                <TableCellSorted
                                    align="right"
                                    label={intl.formatMessage(Messages.noBeds)}
                                    {...registerSort("passengerBeds")}
                                />
                                <TableCellSorted
                                    align="right"
                                    label={intl.formatMessage(Messages.complete)}
                                    {...registerSort("complete")}
                                />
                                <TableCell align="center">
                                    {intl.formatMessage(Messages.actions)}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {loading ? (
                            <TableSkeleton columns={7} rows={filters.rowsPerPage} />
                        ) : (
                            <TableBody>
                                {ships.map(row => (
                                    <TableRow
                                        key={row.id}
                                        className={classNames({
                                            "active-table-row":
                                                selectedShip?.id === row.id && sidebarOpen,
                                        })}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">{row.eni}</TableCell>
                                        <TableCell align="right">{row.data?.mmsi}</TableCell>
                                        <TableCell align="right">{row.data?.length}</TableCell>
                                        <TableCell align="right">
                                            {row.data?.inactive
                                                ? intl.formatMessage(Messages.inactive)
                                                : intl.formatMessage(Messages.active)}
                                        </TableCell>
                                        <TableCell align="right">
                                            {managingCompanies.data.get(row.docksCompanyId) ?? ""}
                                        </TableCell>
                                        <TableCell align="right">{row.passengerBeds}</TableCell>
                                        <TableCell align="right">
                                            {row.complete
                                                ? intl.formatMessage(Messages.yes)
                                                : intl.formatMessage(Messages.no)}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    setSelectedShip(row);
                                                    setSelectedShipId(row.id);
                                                    setViewShipData(true);
                                                }}
                                            >
                                                {intl.formatMessage(Messages.viewShip)}
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    setSelectedShip(row);
                                                    setSidebarOpen(true);
                                                }}
                                            >
                                                {intl.formatMessage(Messages.editDockAccess)}
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    setSelectedShip(row);
                                                    setViewDocuments(true);
                                                }}
                                            >
                                                {intl.formatMessage(Messages.manageDocuments)}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[50, 100, 250, 500]}
                    component="div"
                    count={totalShips}
                    rowsPerPage={filters.rowsPerPage}
                    page={filters.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </SidebarLayout>
        </RpisPage>
    );
};

export default ManageShips;
