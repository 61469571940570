import axios, { AxiosResponse } from "axios";
import { IService } from "../models/service";
import { ISearchParams, searchParamsInit } from "../models/searchParams";
import { ApiSearchResponse } from "../models/common";

export const addService = async (service: IService): Promise<AxiosResponse<IService>> => {
    return await axios.post("/dispatcher/addService", service);
};

export const changeService = async (service: IService): Promise<AxiosResponse<IService>> => {
    return await axios.post("/dispatcher/changeService", service);
};

export const deleteService = async (id: string): Promise<AxiosResponse<string>> => {
    return await axios.delete(`/dispatcher/deleteService/${id}`);
};

export const getService = async (id: string): Promise<AxiosResponse<IService>> => {
    return await axios.get(`/dispatcher/getService/${id}`);
};

export const searchService = async (
    searchParams: ISearchParams = searchParamsInit,
): ApiSearchResponse<IService> => {
    return await axios.post("/dispatcher/searchService", searchParams);
};

export const changeServiceInvoice = async (
    serviceId: string,
    invoiceId: number | null,
): Promise<AxiosResponse<IService>> => {
    return await axios.post(`/dispatcher/changeServiceInvoice/`, {
        id: serviceId,
        invoiceId: `${invoiceId}`,
    });
};
