import axios, { AxiosResponse } from "axios";
import { IAddress } from "../models/address";
import { ApiSearchResponse } from "../models/common";
import { ISearchParams, searchParamsInit } from "../models/searchParams";

export const addAddress = async (dto: IAddress): Promise<AxiosResponse<IAddress>> => {
    return await axios.put("/docks/addAddress", dto);
};

export const changeAddress = async (dto: IAddress): Promise<AxiosResponse<IAddress>> => {
    return await axios.patch("/docks/changeAddress", dto);
};

export const deleteAddress = async (id: string): Promise<AxiosResponse<string>> => {
    return await axios.delete(`/docks/deleteAddress/${id}`);
};

export const getAddress = async (id: string): Promise<AxiosResponse<IAddress>> => {
    return await axios.get(`/docks/getAddress/${id}`);
};

export const searchAddress = async (
    searchParams: ISearchParams = searchParamsInit,
): ApiSearchResponse<IAddress> => {
    return await axios.post("/docks/searchAddress", searchParams);
};

export const getOwningCompanies = async () => {
    return (await axios.get<string[]>("/docks/getOwningCompanies")).data;
};
