import axios, { AxiosResponse } from "axios";
import { ApiSearchResponse } from "../models/common";
import { ISearchParams, searchParamsInit } from "../models/searchParams";
import { IAddEditDestination, IDestination, IManagingCompanyResponse } from "../models/destination";

export const addDestination = async (
    dto: IAddEditDestination,
): Promise<AxiosResponse<IDestination>> => {
    return await axios.put("/dispatcher/addDestination", dto);
};

export const changeDestination = async (
    dto: IAddEditDestination,
): Promise<AxiosResponse<IDestination>> => {
    return await axios.patch("/dispatcher/changeDestination", dto);
};

export const deleteDestination = async (id: string): Promise<AxiosResponse<string>> => {
    return await axios.delete(`/docks/deleteDestination/${id}`);
};

export const getDestination = async (id: string): Promise<AxiosResponse<IDestination>> => {
    return await axios.get(`/dispatcher/getDestination/${id}`);
};

export const searchDestinations = async (
    searchParams: ISearchParams = searchParamsInit,
): ApiSearchResponse<IDestination> => {
    return await axios.post("/docks/searchDestination", searchParams);
};

export const searchDestinationDisp = async (
    searchParams: ISearchParams = searchParamsInit,
): ApiSearchResponse<IDestination> => {
    return await axios.post("/dispatcher/searchDestination", searchParams);
};

export const getManagingCompanies = async (): Promise<
    AxiosResponse<IManagingCompanyResponse[]>
> => {
    return await axios.get("/dispatcher/getManagingCompanies");
};
