import { BookingStatus, IBooking } from "../../../models/booking";
import { IInvoice } from "../../../models/invoice";
import {
    COMPLETABLE_BOOKINGS_STATUSES,
    PENDING_BOOKING_STATUSES,
    PROVISIONAL_BOOKING_STATUSES,
} from "./constants";

export function isBookingStatusPending(status: BookingStatus) {
    return PENDING_BOOKING_STATUSES.includes(status);
}

export function isBookingStatusProvisional(status: BookingStatus) {
    return PROVISIONAL_BOOKING_STATUSES.includes(status);
}

export function getProvisionalBookings(bookings: IBooking[]) {
    return bookings.filter(({ status }) => isBookingStatusProvisional(status));
}

export function getProvisionalBookingIds(bookings: IBooking[]) {
    return getProvisionalBookings(bookings).map(({ id }) => id);
}

export function getLatestInvoiceId(invoices: IInvoice[]) {
    return Math.max(...invoices.map(i => i.id));
}

export function isBookingCompletable(status: BookingStatus) {
    return COMPLETABLE_BOOKINGS_STATUSES.includes(status);
}
