import { Skeleton } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { memo } from "react";

/**
 * Props for the TableSkeleton component.
 */
export interface ITableSkeleton {
    /**
     * The number of columns in the table.
     */
    columns: number;
    /**
     * The number of rows in the table.
     */
    rows?: number;
    /**
     * The height of each table cell.
     */
    cellHeight?: number;
    /**
     * The height of each skeleton element.
     */
    skeletonHeight?: number;
    /**
     * The vertical padding of each table cell.
     */
    paddingY?: string;
}

/**
 * A skeleton loading component for tables.
 * It renders a table body with the specified number of rows and columns,
 * where each cell contains a skeleton element.
 */
// eslint-disable-next-line react/display-name
export const TableSkeleton = memo((props: ITableSkeleton) => {
    return (
        <TableBody>
            {[...Array(props.rows)].map((_, i) => (
                <TableRow key={i}>
                    {[...Array(props.columns)].map((_, i) => (
                        <TableCell
                            key={i}
                            style={{ paddingBlock: props.paddingY }}
                            height={props.cellHeight}
                        >
                            <Skeleton height={props.skeletonHeight} />
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </TableBody>
    );
});
