import { useState, useEffect, useCallback } from "react";

export type FetchFunctionResult<T> = {
    data: T | undefined;
    loading: boolean;
    refetch: () => Promise<void>;
};

export type FetchHookConfig<T> = {
    fetchFn: () => Promise<T>;
    fetchDeps: any[];
};

export function useFetch<T>({ fetchFn, fetchDeps }: FetchHookConfig<T>): FetchFunctionResult<T> {
    const [data, setData] = useState<T>();
    const [loading, setLoading] = useState(false);

    const refetch = useCallback(async () => {
        setLoading(true);
        try {
            const result = await fetchFn();
            setData(result);
        } finally {
            setLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...fetchDeps]);

    useEffect(() => {
        refetch();
    }, [refetch]);

    return { data, loading, refetch };
}
