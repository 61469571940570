import axios, { AxiosResponse } from "axios";
import { ApiSearchResponse } from "../models/common";
import { ISearchParams, searchParamsInit } from "../models/searchParams";
import { IChangeLog } from "../models/changeLog";

export const getChangeLog = async (id: string): Promise<AxiosResponse<IChangeLog>> => {
    return await axios.get(`/dispatcher/getChangeLog/${id}`);
};

export const getChangeLogUsers = async (): Promise<AxiosResponse<string[]>> => {
    return await axios.get("/dispatcher/getChangeLogUsers");
};

export const searchChangeLog = async (
    searchParams: ISearchParams = searchParamsInit,
): ApiSearchResponse<IChangeLog> => {
    return await axios.post("/dispatcher/searchChangeLog", searchParams);
};
