export interface UserData {
    firstName?: string;
    lastName?: string;
    userName?: string;
    token?: string;
    locale?: Locale;
    companyId?: string;
    roles: string[];
    email?: string;
}

export enum Roles {
    PASSENGER_CABIN_CRUISES_DISPATCHER_ADMIN = "PASSENGER_CABIN_CRUISES_DISPATCHER_ADMIN",
    PASSENGER_CABIN_CRUISES_DISPATCHER = "PASSENGER_CABIN_CRUISES_DISPATCHER",
    LOCKS_AND_DOCKS_DISPATCHER = "LOCKS_AND_DOCKS_DISPATCHER",
    COMPANY_ADMIN = "COMPANY_ADMIN",
    ON_CALL_DISPATCHER = "PIKETT",
}

export enum UserRoles {
    PASSENGER_CABIN_CRUISES_DISPATCHER = "DISPATCHER",
    LOCKS_AND_DOCKS_DISPATCHER = "LOCKS_AND_DOCKS",
    COMPANY_ADMIN = "COMPANY_ADMIN",
    PIKETT = "ON_CALL",
}

export enum Locale {
    de = 0,
    en = 1,
    fr = 2,
}

export enum ApiEndpoints {
    dispatcher = "dispatcher",
    docks = "docks",
}

export const getLocaleString = (locale: Locale | undefined) => {
    switch (locale) {
        case 0:
            return "de";
        case 1:
            return "en";
        case 2:
            return "fr";
        default:
            return "de";
    }
};
