import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { searchAddress } from "../../../api/dispAddress";
import { AddressType, IAddress } from "../../../models/address";
import { showBackendMessage } from "../../../helpers/messagesHelper";
import Messages from "../../../localization/Messages";
import { searchParamsInit } from "../../../models/searchParams";

export function findOwningCompanyById(addresses: IAddress[], addressId: string | null | undefined) {
    return addresses.find(({ id }) => id === addressId) ?? null;
}

/**
 * Custom React hook for fetching owning companies.
 *
 * This hook fetches a list of owning companies from the backend API and provides utility functions to find owning companies by ID.
 *
 * @returns An array containing the owning companies, a function to update the owning companies, and an object with utility functions.
 * @example
 * const [owningCompanies, setOwningCompanies, { findById, loading }] = useFetchOwningCompanies();
 */
function useFetchOwningCompanies() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const [owningCompanies, setOwningCompanies] = useState<IAddress[]>([]);

    useEffect(() => {
        const fetchOwningCompanies = async () => {
            setLoading(true);
            try {
                const filter = { $and: [{ type: AddressType.OwningCompany }] };
                const res = await searchAddress({
                    ...searchParamsInit,
                    filter,
                });
                setOwningCompanies(res.data.rows ?? []);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.owningCompanies));
            } finally {
                setLoading(false);
            }
        };

        fetchOwningCompanies();
    }, [dispatch, intl]);

    const findById = useCallback(
        (addressId: string | null | undefined) => {
            return findOwningCompanyById(owningCompanies, addressId);
        },
        [owningCompanies],
    );

    return [
        owningCompanies,
        setOwningCompanies,
        {
            findById,
            loading,
        },
    ] as const;
}

export default useFetchOwningCompanies;
