import { useCallback, useEffect, useState } from "react";
import { IInvoice, InvoiceStatus, invoiceInit } from "../../models/invoice";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { IDispatcherAddress } from "../../models/address";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import Messages from "../../localization/Messages";
import { IShipDispatcher } from "../../models/shipDispatcher";
import { IBooking } from "../../models/booking";
import { showBackendMessage } from "../../helpers/messagesHelper";
import RpisDialog from "../RpisDialog/RpisDialog";
import { InternalAddressForm } from "../DispManageAddresses/components/InternalAddressForm/InternalAddressForm";
import { addInvoice, changeInvoice, changeInvoiceStatus, getInvoice } from "../../api/invoice";
import ExpensesInput from "./components/ExpensesInput/ExpensesInput";
import { getBooking } from "../../api/booking";
import BookingDetails from "../ManageExpenses/components/BookingDetails/BookingDetails";
import { displayStatus } from "../../helpers/invoiceHelper";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Moment } from "moment";
import { DateFormat, getDateText } from "../../helpers/dateHelper";
import { IExpense } from "../../models/expense";
import { reorderExpenses } from "../../api/expense";
import { useSidebarOwner } from "../../contexts/SidebarOwnerContext";

type InvoiceErrors = { [Key in keyof Partial<IInvoice>]: string };

interface IInvoiceForm {
    addresses: IDispatcherAddress[];
    ships: IShipDispatcher[];
    selectedInvoice: IInvoice | undefined;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    fetchAddresses: () => void;
    callback: () => void;
}

/**
 * This component represents the Invoice form placed in the sidebar of the Manage Invoices page.
 * It is used primarily for adding and editing Invoice data.
 *
 * Features:
 * - Ability to quickly add a new internal Invoice address by clicking the "New address" button and filling the address form that pops up in a dialog.
 * - If the invoice is linked to a booking, the Booking Details component is displayed above the form.
 * - The Expenses Input component is displayed below the form, allowing users to manage expenses for that particular invoice conveniently.
 *
 * @component
 * @example
 * return (
 *   <InvoiceForm
 *     addresses={addresses}
 *     ships={ships}
 *     selectedInvoice={selectedInvoice}
 *     setSidebarOpen={setSidebarOpen}
 *     fetchAddresses={fetchAddresses}
 *     callback={callback}
 *   />
 * )
 */
export const InvoiceForm = ({
    addresses,
    ships,
    selectedInvoice,
    setSidebarOpen,
    fetchAddresses,
    callback,
}: IInvoiceForm) => {
    const [invoice, setInvoice] = useState<IInvoice>(invoiceInit);
    const [errors, setErrors] = useState<InvoiceErrors>({});
    const intl = useIntl();
    const dispatch = useDispatch();
    const [booking, setBooking] = useSidebarOwner<IBooking>();
    const [dialogOpen, setDialogOpen] = useState(false);
    const displayShipInput = !invoice.bookingId;

    const fetchBooking = useCallback(async () => {
        //setBooking(undefined);
        if (!selectedInvoice?.bookingId) return;
        try {
            const res = await getBooking(selectedInvoice.bookingId.toString());
            setBooking(res.data ?? undefined);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.booking));
        }
    }, [dispatch, intl, selectedInvoice?.bookingId, setBooking]);

    useEffect(() => {
        fetchBooking();
    }, [fetchBooking]);

    const fetchInvoice = async () => {
        if (selectedInvoice) {
            try {
                const res = await getInvoice(selectedInvoice.id.toString());
                setInvoice(prev => ({ ...prev, invoiceDate: res.data.invoiceDate }));
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.invoice));
            }
        }
    };

    const onChange = (diff: Partial<IInvoice>) => {
        setInvoice(prev => ({ ...prev, ...diff }));
        const clearErrors = Object.fromEntries(Object.entries(diff).map(([k]) => [k, undefined]));
        setErrors({ ...errors, ...clearErrors });
    };

    useEffect(() => {
        setInvoice(selectedInvoice ? { ...selectedInvoice } : invoiceInit);
        !selectedInvoice?.bookingId && setBooking(undefined);
    }, [selectedInvoice, setBooking]);

    const validateForm = () => {
        const newErrors: InvoiceErrors = {};
        if (!invoice.addressId) {
            newErrors.address = intl.formatMessage(Messages.addressIsRequred);
        }

        return newErrors;
    };

    const handleSubmit = async () => {
        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        } else {
            try {
                invoice.booking = undefined;
                if (invoice.id) {
                    const res = await changeInvoice(invoice);
                    dispatch(showBackendMessage(intl, "success", "updating", Messages.invoice));
                    callback();
                    setInvoice(res.data);
                } else {
                    const res = await addInvoice(invoice);
                    dispatch(showBackendMessage(intl, "success", "adding", Messages.invoice));
                    callback();
                    setInvoice(res.data);
                    setSidebarOpen(false);
                }
            } catch (err: any) {
                const { fields } = err.response.data;
                if (fields.bookingId) {
                    setErrors(prev => ({
                        ...prev,
                        bookingId: intl.formatMessage(Messages.referencedIdDoesNotExist),
                    }));
                }
                dispatch(showBackendMessage(intl, "error", "adding", Messages.invoice));
            }
        }
    };

    const setStatusReadyForBilling = async () => {
        try {
            await handleSubmit();
            const newInvoice = await changeInvoiceStatus(
                invoice.id,
                InvoiceStatus.READY_FOR_BILLING,
            );
            callback();
            setInvoice(newInvoice);
            dispatch(showBackendMessage(intl, "success", "updating", Messages.invoiceStatus));
        } catch (e) {
            dispatch(showBackendMessage(intl, "error", "updating", Messages.invoiceStatus));
        }
    };

    const onOrderChange = async (expenses: IExpense[]) => {
        try {
            await reorderExpenses(
                invoice.id,
                expenses.map(e => e.id),
            );
            // dispatch(showBackendMessage(intl, "success", "updating", Messages.ordnum));
        } catch (error) {
            dispatch(showBackendMessage(intl, "error", "updating", Messages.expenses));
        } finally {
            // NOOP
        }
    };

    const [counter, setCounter] = useState(0);
    const forceExpensesInputRerender = useCallback(() => setCounter(prev => prev + 1), []);

    const onFinalizeComplete = () => {
        callback();
        forceExpensesInputRerender();
    };

    return (
        <div className="invoice-form">
            {booking && (
                <BookingDetails
                    onUpdateActualTimes={() => {
                        callback();
                        fetchBooking();
                        fetchInvoice();
                    }}
                    booking={booking}
                    onFinalizeComplete={onFinalizeComplete}
                />
            )}
            {booking && (
                <h3>
                    {intl.formatMessage(Messages.editInvoice) +
                        " #" +
                        (invoice.internalInvoiceNumber ?? invoice.id) +
                        " - " +
                        displayStatus(invoice.invoiceStatus, intl)}
                </h3>
            )}
            <Grid container alignItems={"center"} marginBottom={2} spacing={2}>
                <Grid item sm={6}>
                    <Autocomplete
                        id="address"
                        options={addresses}
                        getOptionLabel={option =>
                            `${option.company} ${
                                option.accountRecNumber ? `(${option.accountRecNumber})` : ""
                            }`
                        }
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={props => (
                            <TextField
                                required
                                {...props}
                                label={intl.formatMessage(Messages.invoiceAddress)}
                                variant="filled"
                                error={Boolean(errors.addressId)}
                                helperText={errors.addressId}
                            />
                        )}
                        renderOption={(props, option) => (
                            <li {...props} key={option.id}>
                                {`${option.company} ${
                                    option.accountRecNumber ? `(${option.accountRecNumber})` : ""
                                } `}
                            </li>
                        )}
                        onChange={(_, value) => {
                            onChange({ addressId: value?.id });
                        }}
                        value={addresses.find(a => a.id === invoice.addressId) ?? null}
                    />
                </Grid>
                <Grid item sm={6}>
                    <Button
                        variant="contained"
                        className="new-address-button"
                        onClick={() => setDialogOpen(true)}
                    >
                        {intl.formatMessage(Messages.newAddress)}
                    </Button>
                </Grid>
                {!(selectedInvoice?.id && selectedInvoice.bookingId) && (
                    <Grid item sm={6}>
                        <TextField
                            type="number"
                            label={intl.formatMessage(Messages.bookingId)}
                            variant="filled"
                            error={Boolean(errors.bookingId)}
                            helperText={errors.bookingId}
                            value={invoice.bookingId ?? ""}
                            onKeyDown={e => {
                                ["e", "E", "+"].includes(e.key) && e.preventDefault();
                            }}
                            onChange={e => {
                                onChange({
                                    bookingId: parseInt(e.target.value) ?? "",
                                });
                            }}
                        />
                    </Grid>
                )}

                {displayShipInput && (
                    <Grid item sm={6}>
                        <Autocomplete
                            id="ship"
                            options={ships}
                            getOptionLabel={option => option.name ?? ""}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={props => (
                                <TextField
                                    {...props}
                                    label={intl.formatMessage(Messages.ship)}
                                    variant="filled"
                                    error={Boolean(errors.shipId)}
                                    helperText={errors.shipId}
                                />
                            )}
                            renderOption={(props, option) => <li {...props}>{option.name}</li>}
                            onChange={(_, value) => {
                                onChange({ shipId: value?.id });
                            }}
                            value={ships.find(s => s.id === invoice.shipId) ?? null}
                        />
                    </Grid>
                )}
                <Grid item sm={6}>
                    <DesktopDatePicker
                        label={intl.formatMessage(Messages.invoiceDate)}
                        inputFormat={DateFormat.CLIENT_DATE}
                        value={
                            invoice.invoiceDate
                                ? getDateText(invoice.invoiceDate, DateFormat.API_DATE)
                                : null
                        }
                        onChange={(value: Moment | null) => {
                            onChange({
                                invoiceDate: value?.valueOf() ?? null,
                            });
                        }}
                        renderInput={params => (
                            <TextField
                                variant="filled"
                                //helperText={errors.invoice.invoiceDate}
                                {...params}
                            />
                        )}
                    />
                </Grid>
                <Grid item sm={6}>
                    <TextField
                        label={intl.formatMessage(Messages.invoiceReference)}
                        variant="filled"
                        value={invoice.invoiceReferenceField ?? ""}
                        onChange={e => onChange({ invoiceReferenceField: e.target.value })}
                    />
                </Grid>
            </Grid>
            <Button
                disabled={false}
                variant="contained"
                className="submit-invoice-form button-primary"
                onClick={() => handleSubmit()}
            >
                {intl.formatMessage(Messages.save)}
            </Button>
            <Button
                className="cancel-button"
                variant="outlined"
                color="primary"
                onClick={() => {
                    setSidebarOpen(false);
                }}
            >
                {intl.formatMessage(Messages.cancel)}
            </Button>

            <Button
                variant="contained"
                color="success"
                onClick={setStatusReadyForBilling}
                disabled={!(invoice.invoiceStatus === InvoiceStatus.OPEN && invoice.invoiceDate)}
            >
                {intl.formatMessage(Messages.readyForBilling)}
            </Button>

            <RpisDialog
                className="dialog-form"
                dialogOpen={dialogOpen}
                size="xl"
                onClose={() => setDialogOpen(false)}
                title={intl.formatMessage(Messages.addAddress)}
                content={
                    <InternalAddressForm
                        callback={({ id: addressId }) => {
                            fetchAddresses();
                            onChange({ addressId });
                        }}
                        setSidebarOpen={setDialogOpen}
                    />
                }
                fullWidth={false}
            />

            <ExpensesInput
                key={`${invoice.id}-${counter}`}
                invoiceId={invoice.id}
                onChange={expenses => onOrderChange(expenses)}
                onExpenseSubmit={callback}
            />
        </div>
    );
};

export default InvoiceForm;
