import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addDock, changeDock, deleteDock, getDock } from "../../api/dock";
import TimetableEditor from "../../components/TimetableEditor/TimetableEditor";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { useAuth } from "../../hooks/useAuth";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import Messages from "../../localization/Messages";
import { getCountryNames } from "../../localization/country-names/countryNames";
import {
    IAddEditDock,
    addEditDockInit,
    availableServices,
    existingInfrastructure,
} from "../../models/dock";
import { ITimetable, timetableInit } from "../../models/timetable";
import RpisPage from "../RpisPage/RpisPage";
import "./ManageDestination.css";
import {
    SelectChangeEvent,
    CircularProgress,
    TextField,
    InputAdornment,
    FormControlLabel,
    Checkbox,
    Autocomplete,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    Box,
    Chip,
    MenuItem,
    Divider,
    Button,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

type DockErrors = {
    [Key in keyof Partial<IAddEditDock>]: string;
};

/**
 * Renders the dock form used for adding or maintaining docks for the dispatcher's destination.
 * This component is accessed on the Manage Destination page.
 */
export const DockForm = () => {
    const withConfirm = useConfirmDialog();
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const [dock, setDock] = useState<IAddEditDock>(addEditDockInit);
    const { guid } = useParams();
    const [errors, setErrors] = useState<DockErrors>({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedEi, setSelectedEi] = useState<string[]>([]);
    const [selectedAs, setSelectedAs] = useState<string[]>([]);
    const { user } = useAuth();

    const countries = getCountryNames(user.locale);

    const fetchDockDetails = useCallback(async () => {
        setLoading(true);
        try {
            if (guid) {
                const res = await getDock(guid);
                setDock(res.data);

                const keysEi = [
                    "powerlock",
                    "electricitySupply",
                    "drinkingWaterAutomatic",
                    "drinkingWaterStandpipe",
                    "drinkingWaterWaterMeter",
                    "wasteDisposal",
                    "faecesDisposal",
                ] as const;
                setSelectedEi(keysEi.filter(k => res.data[k]));
                const keysAs = [
                    "waterMeter",
                    "improperDisposal",
                    "adapteFaecesPowerlock",
                    "extensionCable",
                    "otherExpenditure",
                ] as const;
                setSelectedAs(keysAs.filter(k => res.data[k]));
            }
        } catch (error) {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.dock));
        } finally {
            setLoading(false);
        }
    }, [dispatch, guid, intl]);

    useEffect(() => {
        fetchDockDetails();
    }, [fetchDockDetails]);

    const onChange = (diff: Partial<IAddEditDock>) => {
        setDock({ ...dock, ...diff });
        const clearErros = Object.fromEntries(Object.entries(diff).map(([k]) => [k, undefined]));
        setErrors({ ...errors, ...clearErros });
    };

    const validateDock = () => {
        const newErrors: DockErrors = {};
        if (!dock.name) {
            newErrors.name = intl.formatMessage(Messages.dockNameIsRequired);
        }
        if (!dock.area) {
            newErrors.area = intl.formatMessage(Messages.areaIsRequired);
        }
        if (dock.maxLength < 1) {
            newErrors.maxLength = intl.formatMessage(Messages.numberIncorrect);
        }
        if (dock.maxVessels < 1) {
            newErrors.maxVessels = intl.formatMessage(Messages.numberIncorrect);
        }
        return newErrors;
    };

    const setTimetable = (timeTable: ITimetable) => {
        setDock({ ...dock, timeTable });
    };

    const handleSubmit = async () => {
        const newErrors = validateDock();
        if (Object.keys(newErrors).length) {
            setErrors(newErrors);
        } else {
            setLoading(true);
            try {
                if (guid) {
                    await changeDock(dock);
                    dispatch(showBackendMessage(intl, "success", "updating", Messages.dock));
                } else {
                    await addDock(dock);
                    dispatch(showBackendMessage(intl, "success", "creating", Messages.dock));
                }
                navigate(`/manage-destination`);
            } catch {
                dispatch(
                    showBackendMessage(
                        intl,
                        "error",
                        guid ? "updating" : "creating",
                        Messages.dock,
                    ),
                );
            } finally {
                setLoading(false);
            }
        }
    };

    const handleEiChange = (event: SelectChangeEvent<typeof selectedEi>) => {
        const values = event.target.value as string[];
        setSelectedEi(values);
        setDock({
            ...dock,
            powerlock: values.includes("powerlock"),
            electricitySupply: values.includes("electricitySupply"),
            drinkingWaterAutomatic: values.includes("drinkingWaterAutomatic"),
            drinkingWaterStandpipe: values.includes("drinkingWaterStandpipe"),
            drinkingWaterWaterMeter: values.includes("drinkingWaterWaterMeter"),
            wasteDisposal: values.includes("wasteDisposal"),
            faecesDisposal: values.includes("faecesDisposal"),
        });
    };

    const handleAsChange = (event: SelectChangeEvent<typeof selectedAs>) => {
        const values = event.target.value as string[];
        setSelectedAs(values);
        setDock({
            ...dock,
            waterMeter: values.includes("waterMeter"),
            improperDisposal: values.includes("improperDisposal"),
            adapteFaecesPowerlock: values.includes("adapteFaecesPowerlock"),
            extensionCable: values.includes("extensionCable"),
            otherExpenditure: values.includes("otherExpenditure"),
        });
    };

    const handleDeleteDock = async () => {
        setLoading(true);
        try {
            await deleteDock(dock.id);
            dispatch(showBackendMessage(intl, "success", "deleting", Messages.dock));
        } catch {
            dispatch(showBackendMessage(intl, "error", "deleting", Messages.dock));
        } finally {
            setLoading(true);
            navigate(`/manage-destination`);
        }
    };

    return (
        <RpisPage title={intl.formatMessage(Messages.dock)} className="ld-dock-form-container">
            <div className="title-and-actions-container">
                <ArrowBack fontSize="large" onClick={() => navigate(`/manage-destination`)} />
                <h2>
                    {guid
                        ? intl.formatMessage(Messages.dockEdit)
                        : intl.formatMessage(Messages.dockAdd)}
                </h2>
            </div>
            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    <form className="column-form three-column-form">
                        <TextField
                            required
                            error={Boolean(errors.name)}
                            value={dock.name}
                            label={intl.formatMessage(Messages.name)}
                            onChange={e => onChange({ name: e.target.value })}
                            variant="filled"
                            helperText={errors.name}
                        />
                        <TextField
                            required
                            error={Boolean(errors.area)}
                            value={dock.area}
                            label={intl.formatMessage(Messages.area)}
                            onChange={e => onChange({ area: e.target.value })}
                            variant="filled"
                            helperText={errors.area}
                        />
                        <TextField
                            required
                            type="number"
                            error={Boolean(errors.maxLength)}
                            value={dock.maxLength}
                            label={intl.formatMessage(Messages.maximalLength)}
                            onChange={e =>
                                onChange({
                                    maxLength: Number.parseInt(e.target.value),
                                })
                            }
                            variant="filled"
                            helperText={errors.maxLength}
                            InputProps={{
                                inputProps: { min: 0, max: 9999999 },
                                endAdornment: <InputAdornment position="end">m</InputAdornment>,
                            }} /* TODO - min, max */
                        />
                        <TextField
                            required
                            type="number"
                            error={Boolean(errors.maxVessels)}
                            value={dock.maxVessels}
                            label={intl.formatMessage(Messages.maximalVessels)}
                            onChange={e =>
                                onChange({
                                    maxVessels: Number.parseInt(e.target.value),
                                })
                            }
                            variant="filled"
                            helperText={errors.maxVessels}
                            InputProps={{
                                inputProps: { min: 0, max: 9999999 },
                            }} /* TODO - min, max */
                        />
                        <TextField
                            error={Boolean(errors.remark)}
                            value={dock.remark}
                            label={intl.formatMessage(Messages.remark)}
                            onChange={e => onChange({ remark: e.target.value })}
                            variant="filled"
                            helperText={errors.remark}
                        />
                        <FormControlLabel
                            label={intl.formatMessage(Messages.pubAllocationPlan)}
                            control={
                                <Checkbox
                                    checked={dock.pubAllocationPlan}
                                    onChange={e =>
                                        onChange({
                                            pubAllocationPlan: e.target.checked,
                                        })
                                    }
                                />
                            }
                        />
                        <TextField
                            error={Boolean(errors.address)}
                            value={dock.address}
                            label={intl.formatMessage(Messages.address)}
                            onChange={e => onChange({ address: e.target.value })}
                            variant="filled"
                            helperText={errors.address}
                        />
                        <TextField
                            error={Boolean(errors.city)}
                            value={dock.city}
                            label={intl.formatMessage(Messages.city)}
                            onChange={e => onChange({ city: e.target.value })}
                            variant="filled"
                            helperText={errors.city}
                        />
                        <TextField
                            error={Boolean(errors.zip)}
                            value={dock.zip}
                            label={intl.formatMessage(Messages.zip)}
                            onChange={e => onChange({ zip: e.target.value })}
                            variant="filled"
                            helperText={errors.zip}
                        />
                        <Autocomplete
                            id="select-country-field"
                            options={countries.sort((a, b) => {
                                return a.localeCompare(b);
                            })}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="filled"
                                    label={intl.formatMessage(Messages.country)}
                                    error={Boolean(errors.country)}
                                    helperText={errors.country}
                                />
                            )}
                            renderOption={(props, option) => <li {...props}>{option}</li>}
                            onChange={(_, value: string | null) =>
                                onChange({ country: value ?? undefined })
                            }
                            value={countries.find(co => co === dock.country) ?? null}
                        />
                        <TextField
                            type="number"
                            error={Boolean(errors.lat)}
                            value={dock.lat}
                            label={intl.formatMessage(Messages.latitude)}
                            onChange={e =>
                                onChange({
                                    lat: Number.parseFloat(e.target.value) ?? 0,
                                })
                            }
                            variant="filled"
                            helperText={errors.lat}
                            InputProps={{ inputProps: { min: 0, max: 90, step: "any" } }}
                        />
                        <TextField
                            type="number"
                            error={Boolean(errors.lng)}
                            value={dock.lng}
                            label={intl.formatMessage(Messages.longitude)}
                            onChange={e =>
                                onChange({
                                    lng: Number.parseFloat(e.target.value) ?? 0,
                                })
                            }
                            variant="filled"
                            helperText={errors.lng}
                            InputProps={{ inputProps: { min: 0, max: 90, step: "any" } }}
                        />
                        <TextField
                            type="number"
                            error={Boolean(errors.ordnum)}
                            value={dock.ordnum}
                            label={intl.formatMessage(Messages.ordnum)}
                            onChange={e =>
                                onChange({
                                    ordnum: Number.parseInt(e.target.value),
                                })
                            }
                            variant="filled"
                            helperText={errors.ordnum}
                            InputProps={{ inputProps: { min: 0, max: 100 } }}
                        />
                        <FormControl>
                            <InputLabel id="existing-infrastructure-label">
                                {intl.formatMessage(Messages.existingInfrastructure)}
                            </InputLabel>
                            <Select
                                className="multi-select"
                                multiple
                                value={selectedEi}
                                onChange={handleEiChange}
                                input={
                                    <OutlinedInput
                                        label={intl.formatMessage(Messages.existingInfrastructure)}
                                    />
                                }
                                renderValue={selected => (
                                    <Box>
                                        {selected.map(value => {
                                            const ei = existingInfrastructure.find(
                                                a => a.value === value,
                                            );
                                            return (
                                                ei && (
                                                    <Chip
                                                        key={value}
                                                        label={intl.formatMessage(ei.label)}
                                                    />
                                                )
                                            );
                                        })}
                                    </Box>
                                )}
                            >
                                {existingInfrastructure.map(ei => (
                                    <MenuItem key={ei.value} value={ei.value}>
                                        {intl.formatMessage(ei.label)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <InputLabel id="available-services-label">
                                {intl.formatMessage(Messages.availableServices)}
                            </InputLabel>
                            <Select
                                className="multi-select"
                                multiple
                                value={selectedAs}
                                onChange={handleAsChange}
                                input={
                                    <OutlinedInput
                                        label={intl.formatMessage(Messages.availableServices)}
                                    />
                                }
                                renderValue={selected => (
                                    <Box>
                                        {selected.map(value => {
                                            const as = availableServices.find(
                                                a => a.value === value,
                                            );
                                            return (
                                                as && (
                                                    <Chip
                                                        key={value}
                                                        label={intl.formatMessage(as.label)}
                                                    />
                                                )
                                            );
                                        })}
                                    </Box>
                                )}
                            >
                                {availableServices.map(as => (
                                    <MenuItem key={as.value} value={as.value}>
                                        {intl.formatMessage(as.label)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </form>
                    <TimetableEditor
                        title={intl.formatMessage(Messages.dockTimeAvailability)}
                        timetable={
                            dock.timeTable && Object.keys(dock.timeTable).length
                                ? dock.timeTable
                                : timetableInit
                        }
                        setTimetable={setTimetable}
                    />
                </>
            )}
            <Divider />
            <Button variant="contained" onClick={handleSubmit} className="dock-form-submit-button">
                {intl.formatMessage(Messages.submit)}
            </Button>
            {guid && (
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() =>
                        withConfirm({
                            message: intl.formatMessage(Messages.confirmDockDeleteText),
                            onConfirm: handleDeleteDock,
                        })
                    }
                >
                    {intl.formatMessage(Messages.delete)}
                </Button>
            )}
        </RpisPage>
    );
};

export default DockForm;
