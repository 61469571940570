import { useMemo } from "react";
import { IBerthing } from "../../../models/berthing";

/**
 * Memoized React hook which calculates earliest arrival or latest departure time, depending on the `boundaryKey`.
 * @param berthings Array of booking's berthings.
 * @param boundaryKey Earliest `"arrivalTime"` or latest `"departureTime"`.
 * @param defaultValue A default date value used when `berthings` array is empty.
 * @returns Earliest berthing arrival time if `"arrivalTime"` is supplied, otherwise latest departure time (or `defaultValue` when array is empty).
 */
export default function useBerthingsDateBoundary(
    berthings: IBerthing[],
    boundaryKey: "arrivalTime" | "departureTime",
    defaultValue: number = Date.now(),
): number {
    return useMemo(
        () =>
            berthings.length === 0
                ? defaultValue
                : boundaryKey === "arrivalTime"
                  ? Math.min(...berthings.map(({ arrivalTime }) => arrivalTime))
                  : Math.max(...berthings.map(({ departureTime }) => departureTime)),
        [berthings, boundaryKey, defaultValue],
    );
}
