import prettyBytes from "pretty-bytes";

export interface IDocument {
    mimeType: string;
    lastModify: number | null;
    name: string;
    size: number | null;
    path: string;
}

export const displayFileSize = (size: number) => {
    return prettyBytes(size);
};
