import {
    Autocomplete,
    TextField,
    FormControl,
    InputLabel,
    Select,
    SelectChangeEvent,
    IconButton,
    MenuItem,
    Box,
    Button,
    Grid,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import Messages from "../../../../localization/Messages";
import { BookingStatus } from "../../../../models/booking";
import "./index.css";
import { Close } from "@mui/icons-material";
import { BookingsFilters as BookingsFiltersType } from "../../shared/filters";
import { IShipDispatcher } from "../../../../models/shipDispatcher";
import { useIntl } from "react-intl";
import { memo } from "react";
import { useEnumOptions } from "../../../../hooks/useEnumOptions";
import { getDateNumber } from "../../../../helpers/dateHelper";
import { useCachedDocks } from "../../../../hooks/useCachedDocks";

export const BOOKINGS_SORTABLE_COLUMNS = [
    "shipName",
    "arrivalTime",
    "departureTime",
    "status",
    "requestDate",
    "confirmTime",
    "managingCompanyName",
    "owningCompany",
] as const;

export type BookingFiltersProps = {
    filters: BookingsFiltersType;
    ships: IShipDispatcher[];
    setFilters: (filters: Partial<BookingsFiltersType>) => void;
};

/**
 * Represents the filters section component of the Manage Bookings page.
 *
 * @component
 * @example
 * ```tsx
 * <BookingFilters
 *    filters={filters}
 *    ships={ships}
 *    setFilters={setFilters}
 * />
 * ```
 */
function BookingFilters({ filters, ships, setFilters: setFiltersNative }: BookingFiltersProps) {
    const { docks } = useCachedDocks();
    const intl = useIntl();
    const bookingStatusOptions = useEnumOptions({ BookingStatus });

    const setFilters = (filters: Partial<BookingsFiltersType>) => {
        setFiltersNative({ ...filters, page: 0 });
    };

    const finalizedFilterOptions = [
        { key: 1, label: intl.formatMessage(Messages.finalized), value: true },
        { key: 2, label: intl.formatMessage(Messages.notFinalized), value: false },
        { key: 3, label: intl.formatMessage(Messages.any), value: null },
    ];

    const clearFilters = () => {
        setFilters({
            shipName: undefined,
            docks: [],
            bookingStatuses: [],
            dateFrom: null,
            dateTo: null,
            finalized: null,
        });
    };

    return (
        <Grid container maxWidth={400} spacing={1} padding={1}>
            <Grid item xs={12}>
                <Autocomplete
                    freeSolo
                    id="ship-autocomplete"
                    options={ships}
                    value={filters.shipName}
                    onInputChange={(_, value: string) => {
                        if (value.length === 0 || value.length >= 3) {
                            setFilters({ shipName: value });
                        }
                    }}
                    getOptionLabel={o => (o && typeof o === "object" && "name" in o ? o.name : o)}
                    isOptionEqualToValue={(a, b) => a.id === b.id}
                    renderInput={params => (
                        <TextField
                            variant="filled"
                            {...params}
                            label={intl.formatMessage(Messages.search)}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            {option.name} {option.eni ? `(${option.eni})` : ""}
                        </li>
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl className="form-control" variant="filled" fullWidth>
                    <InputLabel>{intl.formatMessage(Messages.docks)}</InputLabel>
                    <Select
                        multiple
                        id="docks-select"
                        value={filters.docks || []}
                        label={intl.formatMessage(Messages.docks)}
                        placeholder={intl.formatMessage(Messages.docks)}
                        variant="filled"
                        onChange={(event: SelectChangeEvent<string[]>) => {
                            setFilters({
                                docks: event.target.value as string[],
                            });
                        }}
                        endAdornment={
                            filters.docks?.length && (
                                <IconButton onClick={() => setFilters({ docks: [] })}>
                                    <Close fontSize="small" />
                                </IconButton>
                            )
                        }
                    >
                        {docks.map(({ name, id }) => (
                            <MenuItem key={id} value={id}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <FormControl className="form-control" variant="filled" fullWidth>
                    <InputLabel>{intl.formatMessage(Messages.bookingStatus)}</InputLabel>
                    <Select
                        multiple
                        id="booking-status"
                        value={filters.bookingStatuses}
                        label={intl.formatMessage(Messages.bookingStatus)}
                        placeholder={intl.formatMessage(Messages.bookingStatus)}
                        variant="filled"
                        onChange={(event: SelectChangeEvent<BookingStatus[]>) =>
                            setFilters({
                                bookingStatuses: event.target.value as BookingStatus[],
                            })
                        }
                        endAdornment={
                            filters.bookingStatuses.length > 0 && (
                                <IconButton onClick={() => setFilters({ bookingStatuses: [] })}>
                                    <Close fontSize="small" />
                                </IconButton>
                            )
                        }
                    >
                        {bookingStatusOptions.map(({ value, label }) => (
                            <MenuItem key={value} value={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl className="form-control" variant="filled" fullWidth>
                    <InputLabel>{intl.formatMessage(Messages.finalized)}</InputLabel>
                    <Select
                        id="booking-finalized"
                        value={filters.finalized !== null ? filters.finalized : ""} // Display empty string for 'null'
                        label={intl.formatMessage(Messages.finalized)}
                        placeholder={intl.formatMessage(Messages.finalized)}
                        variant="filled"
                        onChange={(event: SelectChangeEvent<string | boolean>) =>
                            setFilters({
                                finalized:
                                    event.target.value === ""
                                        ? null
                                        : event.target.value === "true", // Set 'null' when empty string
                            })
                        }
                        endAdornment={
                            filters.finalized !== null && (
                                <IconButton onClick={() => setFilters({ finalized: null })}>
                                    <Close fontSize="small" />
                                </IconButton>
                            )
                        }
                    >
                        {finalizedFilterOptions.map(({ key, label, value }) => (
                            <MenuItem key={key} value={value !== null ? String(value) : ""}>
                                {" "}
                                {/* Map 'null' to empty string */}
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12}></Grid>

            <Grid item xs={12} sm={6}>
                <DesktopDatePicker
                    InputProps={{ sx: { maxWidth: 200 } }}
                    label={intl.formatMessage(Messages.from)}
                    inputFormat="DD/MM/YYYY"
                    value={filters.dateFrom}
                    onChange={value => {
                        (!isNaN(getDateNumber(value)) || value === null) &&
                            setFilters({ dateFrom: value });
                    }}
                    renderInput={params => <TextField fullWidth variant="filled" {...params} />}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DesktopDatePicker
                    InputProps={{ sx: { maxWidth: 200 } }}
                    label={intl.formatMessage(Messages.to)}
                    inputFormat="DD/MM/YYYY"
                    value={filters.dateTo}
                    onChange={value =>
                        (!isNaN(getDateNumber(value)) || value === null) &&
                        setFilters({ dateTo: value })
                    }
                    renderInput={params => <TextField fullWidth variant="filled" {...params} />}
                />
            </Grid>
            <Box display="flex" gap={2} justifyContent="space-between" flex="1">
                <Box display="flex" gap={2} flex="1" paddingY={2} paddingX={1}>
                    {(filters.shipName ||
                        filters.docks ||
                        filters.bookingStatuses.length !== 0 ||
                        filters.dateFrom ||
                        filters.dateTo) && (
                        <Button variant="outlined" onClick={clearFilters}>
                            {intl.formatMessage(Messages.clear)}
                        </Button>
                    )}
                </Box>
            </Box>
        </Grid>
    );
}

export default memo(BookingFilters);
