import axios, { AxiosResponse } from "axios";
import { ApiSearchResponse } from "../models/common";
import { ISearchParams, searchParamsInit } from "../models/searchParams";
import { IAddEditShip, IShip } from "../models/ship";
import { IShipDispatcher } from "../models/shipDispatcher";

export const addShip = async (dto: IAddEditShip): Promise<AxiosResponse<IShip>> => {
    return await axios.put("/docks/addShip", dto);
};

export const changeShip = async (dto: IAddEditShip): Promise<AxiosResponse<IShip>> => {
    return await axios.patch("/docks/changeShip", dto);
};

export const updateDockAccess = async (dto: IShipDispatcher): Promise<AxiosResponse<IShip>> => {
    return await axios.post("/dispatcher/changeShip", dto);
};

export const deleteShip = async (id: string): Promise<AxiosResponse<string>> => {
    return await axios.delete(`/docks/deleteShip/${id}`);
};

export const getShip = async (id: string): Promise<AxiosResponse<IShip>> => {
    return await axios.get(`/docks/getShip/${id}`);
};

export const getShipDisp = async (id: string): Promise<AxiosResponse<IShipDispatcher>> => {
    return await axios.get(`/dispatcher/getShip/${id}`);
};

export const searchShip = async (
    searchParams: ISearchParams = searchParamsInit,
): ApiSearchResponse<IShip> => {
    return await axios.post("/docks/searchShip", searchParams);
};

export const searchShipDisp = async (
    searchParams: ISearchParams = searchParamsInit,
): ApiSearchResponse<IShipDispatcher> => {
    return await axios.post("/dispatcher/searchShip", searchParams);
};

export const getShipNames = async () => {
    return (await axios.post<string[]>("/docks/getShipNames", {})).data;
};
