import { useState, useEffect } from "react";

/**
 * Custom hook for managing a value in local storage.
 *
 * @template T - The type of the value to be stored in local storage.
 * @param {string} key - The key used to store the value in local storage.
 * @param {T} defaultValue - The default value to be used if no value is found in local storage.
 * @returns {[T, React.Dispatch<React.SetStateAction<T>>]} - A tuple containing the current value and a function to update the value.
 */
export function useLocalStorage<T>(key: string, defaultValue: T) {
    const [value, setValue] = useState<T>(() => {
        let currentValue;

        try {
            currentValue = JSON.parse(localStorage.getItem(key) || String(defaultValue));
        } catch (error) {
            currentValue = defaultValue;
        }

        return currentValue;
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [value, key]);

    return [value, setValue] as const;
}
