import { IDestination } from "../models/destination";

export const SET_DESTINATION_ZONES = "SET_DESTINATION_ZONES";

export interface SetDestinationZones {
    type: typeof SET_DESTINATION_ZONES;
    payload: IDestination[];
}

export type DestinationZonesActionType = SetDestinationZones;

/**
 * Sets the destination zones from the request.
 * @param payload - The destination zones to set.
 * @returns The action to execute.
 */
export function setDestinationZonesFromRequest(
    payload: IDestination[],
): DestinationZonesActionType {
    return { type: SET_DESTINATION_ZONES, payload };
}
