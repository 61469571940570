import axios, { AxiosResponse } from "axios";
import { ApiSearchResponse } from "../models/common";
import { ISearchParams, searchParamsInit } from "../models/searchParams";
import { IAddEditStop, IStop } from "../models/stop";

export const addStop = async (dto: IAddEditStop): Promise<AxiosResponse<IStop>> => {
    return await axios.put("/docks/addStop", dto);
};

export const changeStop = async (dto: IAddEditStop): Promise<AxiosResponse<IStop>> => {
    return await axios.patch("/docks/changeStop", dto);
};

export const deleteStop = async (id: number): Promise<AxiosResponse<string>> => {
    return await axios.delete(`/docks/deleteStop/${id}`);
};

export const getStop = async (id: string): Promise<AxiosResponse<IStop>> => {
    return await axios.get(`/docks/getStop/${id}`);
};

export const searchStops = async (
    searchParams: ISearchParams = searchParamsInit,
): ApiSearchResponse<IStop> => {
    return await axios.post("/docks/searchStop", searchParams);
};

export const sendBookingRequest = async (stopId: number): Promise<AxiosResponse<IStop>> => {
    return await axios.get(`/docks/sendBookingRequest/${stopId}`);
};

export const cancelBooking = async (stopId: number): Promise<AxiosResponse<IStop>> => {
    return await axios.get(`/docks/cancelBooking/${stopId}`);
};

export const updateShipData = async (stopId: number): Promise<AxiosResponse<IStop>> => {
    return await axios.get(`/docks/updateShipData/${stopId}`);
};

export const confirmChangedBooking = async (stopId: number): Promise<AxiosResponse<IStop>> => {
    return await axios.get(`/docks/confirmChangedBooking/${stopId}`);
};
