import { MbscCalendarEvent } from "@mobiscroll/react";
import { IntlShape } from "react-intl";
import Messages from "../localization/Messages";
import { IBerthing } from "../models/berthing";
import {
    IBooking,
    getBookingStatusMessageDescriptor,
    BookingStatus,
    getBookingDock,
    getBookingStatusCssColor,
} from "../models/booking";
import { IInternalBooking, Type } from "../models/internalBooking";
import { DateFormat, getDateRangeText, getDateText } from "./dateHelper";

export const MbscEventCalendarInvalidResource = {
    UNALLOCATED: "Unallocated",
    DECLINED: "Declined",
} as const;

export const MbscEventCalendarInvalidMatcher = [
    {
        recurring: { repeat: "daily" },
        resource: MbscEventCalendarInvalidResource.UNALLOCATED,
    },
    {
        recurring: { repeat: "daily" },
        resource: MbscEventCalendarInvalidResource.DECLINED,
    },
];

/**
 * Function that builds and returns standard calendar events (bookings and berthings) in Dock allocation plan.
 * @param bookingRows
 * @param berthingRows
 * @param intl
 */
export function buildStandardCalendarEvents(
    bookingRows: IBooking[],
    berthingRows: IBerthing[],
    intl: IntlShape,
): MbscCalendarEvent[] {
    return bookingRows
        .map(bookingRow => {
            const bookingId = `#${bookingRow.id}`;
            const status = bookingRow.status;
            const shipName = bookingRow.shipName;
            const isLengthAboveLimit = bookingRow.shipLength > 110;
            const bookingStatusText = intl.formatMessage(
                getBookingStatusMessageDescriptor(bookingRow),
            );
            const title = `${bookingId} ${shipName} - ${bookingStatusText}`;
            const berthingIdentifiers = [
                BookingStatus.PROVISIONALLY_PLANNED,
                BookingStatus.CONFIRMED,
                BookingStatus.CHANGED,
                BookingStatus.CHANGE_REQUESTED,
            ];
            const useBerthings = berthingIdentifiers.includes(status);
            const filterBookingById = ({ bookingId }: IBerthing) => bookingId === bookingRow.id;

            function buildSanitizedCalendarEvent(row: IBooking | IBerthing): MbscCalendarEvent {
                const isBerthing = "bookingId" in row;
                const { arrivalTime, departureTime } = row;
                const bookingId = isBerthing ? row.bookingId : row.id;
                const id = row.id;
                const apiFormat = DateFormat.API_DATE_TIME;
                const clientFormat = DateFormat.CLIENT_DATE_TIME;
                const start = getDateText(arrivalTime, apiFormat);
                const end = getDateText(departureTime, apiFormat);
                const resource = getBookingDock(bookingRow, isBerthing ? row : undefined);
                const color = getBookingStatusCssColor(bookingRow);
                const tooltip = `${title}, ${getDateText(
                    arrivalTime,
                    clientFormat,
                )} - ${getDateText(departureTime, clientFormat)}`;
                return {
                    bookingId,
                    id,
                    start,
                    end,
                    resource,
                    color,
                    tooltip,
                    title,
                    cssClass: isLengthAboveLimit ? "length-exceeded-cell" : "",
                    range: getDateRangeText(start, end, DateFormat.CLIENT_DATE_TIME, true),
                };
            }

            return useBerthings
                ? berthingRows.filter(filterBookingById).map(buildSanitizedCalendarEvent)
                : buildSanitizedCalendarEvent(bookingRow);
        })
        .flat();
}

/**
 * Function that builds and returns internal calendar events (internal bookings) in Dock allocation plan.
 * @param internalBookingRows
 * @param intl
 */
export function buildInternalCalendarEvents(
    internalBookingRows: IInternalBooking[],
    intl: IntlShape,
): MbscCalendarEvent[] {
    return internalBookingRows.map(booking => {
        const clientFormat = DateFormat.CLIENT_DATE_TIME;
        const { id, shipName, eni, arrivalTime, departureTime, dockId: resource } = booking;
        const isTypeBooking = booking.type === Type.BOOKING;
        const bookingTitle = `${shipName}${eni ? ` (${eni})` : ""}`;
        const title = isTypeBooking
            ? bookingTitle
            : booking.reason || intl.formatMessage(Messages.blocking);
        const start = getDateText(arrivalTime, DateFormat.API_DATE_TIME);
        const end = getDateText(departureTime, DateFormat.API_DATE_TIME);
        const tooltip = `${title}, ${getDateText(start, clientFormat)} - ${getDateText(
            end,
            clientFormat,
        )}`;
        const color = isTypeBooking ? "#EEE" : "var(--stripe-pattern)";
        const textColor = "black";
        const bookingId = id;
        return {
            id,
            bookingId,
            textColor,
            start,
            end,
            title,
            resource,
            color,
            tooltip,
            cssClass: "event-internal",
            range: getDateRangeText(start, end, DateFormat.CLIENT_DATE_TIME, true),
        };
    });
}
