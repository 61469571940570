import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { showBackendMessage } from "../helpers/messagesHelper";
import Messages from "../localization/Messages";
import { searchInvoice } from "../api/invoice";
import { IInvoice } from "../models/invoice";
import {
    InvoicesSearchPayload,
    buildInvoicesSearchPayload,
} from "../containers/ManageInvoices/shared/filters";
import { FetchFunctionResult, useFetch } from "./useFetch";
import { Serializers, useUrlState } from "./useUrlState";
import { searchParamsInit } from "../models/searchParams";
import { useFetchInitialInvoice } from "./useFetchInitialInvoice";

/**
 * Custom hook for fetching invoices.
 * @param searchParams - The search parameters.
 * @returns An object containing the loading state, invoices, total invoices, and the fetchInvoices function.
 */
export type useFetchInvoicesProps = {
    payload: InvoicesSearchPayload;
};

export function useFetchInvoices({
    payload,
}: useFetchInvoicesProps): FetchFunctionResult<{ total: number; invoices: IInvoice[] }> {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [total, setTotal] = useState(0);
    const [fromBooking] = useUrlState("fromBooking", false, Serializers.boolean);
    const fromBookingRef = useRef(fromBooking);

    const [selectedInvoiceId] = useUrlState("selectedInvoiceId", "", Serializers.string);
    const { data: initialInvoice } = useFetchInitialInvoice(selectedInvoiceId);

    useEffect(() => {
        fromBookingRef.current = fromBooking;
    }, [fromBooking]);

    const propsJsonString = JSON.stringify(payload);

    const { data, loading, refetch } = useFetch<IInvoice[]>({
        fetchFn: async () => {
            try {
                // Check whether the user is navigated from Manage bookings page to apply filter by invoice number
                if (fromBookingRef.current && initialInvoice?.id) {
                    const res = await searchInvoice(
                        buildInvoicesSearchPayload({
                            ...searchParamsInit,
                            rowsPerPage: 0,
                            page: 0,
                            internalInvoiceNumber: initialInvoice.internalInvoiceNumber ?? "",
                            dateFrom: null,
                            dateTo: null,
                            invoiceStatus: [],
                            bookingDateTo: null,
                            bookingDateFrom: null,
                            invoiceDate: null,
                            shipId: null,
                        }),
                    );
                    return res.data.rows ?? [];
                }
                const res = await searchInvoice(JSON.parse(propsJsonString));
                setTotal(res.data.total);
                return res.data.rows ?? [];
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.invoices));
                return [];
            } finally {
                // NOOP
            }
        },
        fetchDeps: [propsJsonString, initialInvoice],
    });

    return { data: { total, invoices: data?.length ? data : [] }, loading, refetch };
}
