import axios from "axios";
import { IInvoice } from "../models/invoice";
import { ISearchParams, searchParamsInit } from "../models/searchParams";
import { ApiSearchResponse } from "../models/common";

export const getInvoice = async (id: string) => {
    return (await axios.get<IInvoice>(`/docks/getInvoice/${id}`)).data;
};

export const searchInvoice = async (
    searchParams: ISearchParams = searchParamsInit,
): ApiSearchResponse<IInvoice> => {
    return await axios.post("/docks/searchInvoice", searchParams);
};
