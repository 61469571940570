import {
    Autocomplete,
    Box,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from "@mui/material";
import { InternalBookingsFilters } from "../../shared/filters";
import "./InternalBookingFilters.css";
import { useIntl } from "react-intl";
import { SEARCH_FIELD_MIN_LENGTH } from "../../shared/constants";
import { Close } from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useEnumOptions } from "../../../../hooks/useEnumOptions";
import Messages from "../../../../localization/Messages";
import { IDock } from "../../../../models/dock";
import useFetchShips from "../../../ManageBookings/hooks/useFetchShips";
import { Type as InternalBookingType } from "../../../../models/internalBooking";
import { getDateNumber } from "../../../../helpers/dateHelper";
import { useCachedDocks } from "../../../../hooks/useCachedDocks";

export type InternalBookingFiltersProps = {
    value: InternalBookingsFilters;
    onChange: (value: InternalBookingsFilters) => void;
};

/**
 * Represents the filters for the internal bookings table.
 *
 * @component
 * @param {InternalBookingFiltersProps} props - The component props.
 * @param {InternalBookingsFilters} props.value - The current filters.
 * @param {(value: InternalBookingsFilters) => void} props.onChange - The function to call when the filters change.
 * @returns {JSX.Element} The rendered InternalBookingFilters component.
 */
export function InternalBookingFilters({ value, onChange }: InternalBookingFiltersProps) {
    const intl = useIntl();
    const [ships] = useFetchShips();
    const { docks, byId } = useCachedDocks();
    const internalBookingTypes = useEnumOptions({ InternalBookingType });

    const emitChange = (diff: Partial<InternalBookingsFilters>) => {
        onChange({ ...value, ...diff, page: 0 });
    };

    return (
        <Box display="flex" alignItems="center" margin={2} gap={2}>
            {/* Search filter */}
            <Autocomplete
                freeSolo
                sx={{ minWidth: 200 }}
                options={ships}
                value={value.search}
                getOptionLabel={o => (typeof o === "string" ? o : o.name)}
                isOptionEqualToValue={(a, b) => a.id === b.id}
                onInputChange={(_event, value) => {
                    if (value.length === 0 || value.length >= SEARCH_FIELD_MIN_LENGTH) {
                        emitChange({ search: value });
                    }
                }}
                renderInput={params => (
                    <TextField
                        variant="filled"
                        {...params}
                        label={intl.formatMessage(Messages.search)}
                    />
                )}
                renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                        {option.name} {option.eni ? `(${option.eni})` : ""}
                    </li>
                )}
            />

            {/* Docks filter */}
            <Autocomplete
                options={docks}
                sx={{ minWidth: 200 }}
                onChange={(_, value: IDock | null) => emitChange({ dockId: value?.id })}
                value={byId.get(value.dockId ?? "")}
                getOptionLabel={o => o.name}
                isOptionEqualToValue={(a, b) => a.id === b.id}
                renderInput={params => (
                    <TextField
                        variant="filled"
                        {...params}
                        label={intl.formatMessage(Messages.dock)}
                    />
                )}
                renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                        {option.name}
                    </li>
                )}
            />

            {/* Arrival time filter */}
            <DesktopDatePicker
                InputProps={{ sx: { maxWidth: 200 } }}
                label={intl.formatMessage(Messages.from)}
                inputFormat="DD/MM/YYYY"
                value={value.arrivalTime}
                onChange={value =>
                    (!isNaN(getDateNumber(value)) || value === null) &&
                    emitChange({ arrivalTime: value })
                }
                renderInput={params => <TextField variant="filled" {...params} />}
            />

            {/* Departure time filter */}
            <DesktopDatePicker
                InputProps={{ sx: { maxWidth: 200 } }}
                label={intl.formatMessage(Messages.to)}
                inputFormat="DD/MM/YYYY"
                value={value.departureTime}
                onChange={value =>
                    (!isNaN(getDateNumber(value)) || value === null) &&
                    emitChange({ departureTime: value })
                }
                renderInput={params => <TextField variant="filled" {...params} />}
            />

            {/* Type filter */}
            <FormControl variant="filled" sx={{ minWidth: 200 }}>
                <InputLabel>{intl.formatMessage(Messages.type)}</InputLabel>
                <Select
                    multiple
                    value={value.types}
                    label={intl.formatMessage(Messages.type)}
                    placeholder={intl.formatMessage(Messages.type)}
                    variant="filled"
                    onChange={(event: SelectChangeEvent<InternalBookingType[]>) =>
                        emitChange({
                            types: event.target.value as InternalBookingType[],
                        })
                    }
                    endAdornment={
                        value.types.length > 0 && (
                            <IconButton onClick={() => emitChange({ types: [] })}>
                                <Close fontSize="small" />
                            </IconButton>
                        )
                    }
                >
                    {internalBookingTypes.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                            {label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}
