import { ApiSearchResponse } from "../models/common";
import { IDockExpense } from "../models/expense";
import { ISearchParams, searchParamsInit } from "../models/searchParams";
import axios from "axios";

export const getExpense = async (id: string) => {
    return (await axios.get<IDockExpense>(`/docks/getExpense/${id}`)).data;
};

export const searchExpense = async (
    searchParams: ISearchParams = searchParamsInit,
): ApiSearchResponse<IDockExpense> => {
    return await axios.post("/docks/searchExpense", searchParams);
};

export const getInternalInvoiceNumbers = async (searchParams: ISearchParams = searchParamsInit) => {
    return (await axios.post<string[]>("docks/getInternalInvoiceNumbers", searchParams)).data;
};
