import { memo } from "react";
import { Link } from "react-router-dom";
import RpisLogo from "./rpis_logo.png";
import UserCorner from "../UserCorner/UserCorner";
import { useIntl } from "react-intl";
import Messages from "../../localization/Messages";
import { Roles } from "../../models/userData";
import { Tooltip } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import "./RpisHeader.css";

export interface IRpisHeader {
    title?: string;
}

/**
 * Header component for the RPIS application.
 * Displays the RPIS logo, application name, view title, and user corner section.
 * @param {IRpisHeader} props - The component props.
 * @returns {JSX.Element} The rendered RpisHeader component.
 */

const RpisHeader = ({ title }: IRpisHeader): JSX.Element => {
    const { user } = useAuth();
    const intl = useIntl();

    return (
        <header className="rpis-header">
            <Link className="rpis-logo-container" to="/">
                <img id="rpis-logo" src={RpisLogo} alt="RPIS Logo" />
            </Link>
            {user.roles.length !== 0 && (
                <section id="app-name">
                    {user.roles.includes(Roles.LOCKS_AND_DOCKS_DISPATCHER)
                        ? `${intl.formatMessage(Messages.fgksLocksAndDocks)}`
                        : `${intl.formatMessage(Messages.fgksDisptacher)}`}
                </section>
            )}
            {title && (
                <Tooltip title={title}>
                    <section className="view-title truncate">{title}</section>
                </Tooltip>
            )}
            {user.roles.length !== 0 && (
                <section id="settings-section">
                    <UserCorner />
                </section>
            )}
        </header>
    );
};

export default memo(RpisHeader);
