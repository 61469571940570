import axios, { AxiosResponse } from "axios";
import { IExpenseType } from "../models/expenseType";
import { ISearchParams, searchParamsInit } from "../models/searchParams";
import { ApiSearchResponse } from "../models/common";

export const addExpenseType = async (
    expenseType: IExpenseType,
): Promise<AxiosResponse<IExpenseType>> => {
    return await axios.put("/dispatcher/addExpenseType", expenseType);
};

export const changeExpenseType = async (
    expenseType: IExpenseType,
): Promise<AxiosResponse<IExpenseType>> => {
    return await axios.patch("/dispatcher/changeExpenseType", expenseType);
};

export const deleteExpenseType = async (id: string): Promise<AxiosResponse<string>> => {
    return await axios.delete(`/dispatcher/deleteExpenseType/${id}`);
};

export const getExpenseType = async (id: string): Promise<AxiosResponse<IExpenseType>> => {
    return await axios.get(`/dispatcher/getExpenseType/${id}`);
};

export const searchExpenseType = async (
    searchParams: ISearchParams = searchParamsInit,
): ApiSearchResponse<IExpenseType> => {
    return await axios.post("/dispatcher/searchExpenseType", searchParams);
};
