import { BookingStatus } from "../../../models/booking";

export const BOOKINGS_ROWS_PER_PAGE_OPTS = [50, 100, 250, 500, 2000];

export const PROVISIONAL_BOOKING_STATUSES = [
    BookingStatus.PROVISIONALLY_PLANNED,
    BookingStatus.PROVISIONALLY_DECLINED,
];

export const PENDING_BOOKING_STATUSES = [
    BookingStatus.PENDING,
    BookingStatus.CHANGE_REQUESTED,
    BookingStatus.REQUESTED,
];

export const COMPLETABLE_BOOKINGS_STATUSES = [BookingStatus.CHANGED, BookingStatus.CONFIRMED];
