import { useIntl } from "react-intl";
import RpisPage from "../RpisPage/RpisPage";
import Messages from "../../localization/Messages";
import SidebarLayout from "../SidebarLayout/SidebarLayout";
import { useCallback, useEffect, useMemo, useState } from "react";
import "./ManageInvoices.css";
import {
    Button,
    TablePagination,
    Switch,
    Box,
    ButtonGroup,
    Tooltip,
    FormControlLabel,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { AddressType } from "../../models/address";
import InvoiceForm from "./InvoiceForm";
import { IInvoice, InvoiceStatus } from "../../models/invoice";
import { changeInvoiceStatus, copyCancelledInvoice, getInvoiceCsvLink } from "../../api/invoice";
import { useNavigate } from "react-router-dom";
import { useGuiConfState } from "../../hooks/useGuiConfState";
import InvoiceFilters from "./components/InvoiceFilters/InvoiceFilters";
import { getDateText } from "../../helpers/dateHelper";
import { BOOKINGS_ROWS_PER_PAGE_OPTS } from "../ManageBookings/shared/constants";
import { displayStatus } from "../../helpers/invoiceHelper";
import useBatchToggleControl from "../ManageBookings/hooks/useBatchToggleControl";
import { displayAmount } from "../../helpers/moneyHelper";
import { Serializers, useUrlState } from "../../hooks/useUrlState";
import RpisDialog from "../RpisDialog/RpisDialog";
import ViewInvoice from "../../components/ViewInvoice/ViewInvoice";
import { getReadyForBillingInvoiceIds } from "./shared/utils";
import { Add, Clear, Tune } from "@mui/icons-material";
import { Datatable } from "../../components/Datatable/Datatable";
import { invoiceTitleMapper } from "./shared/constants";
import { useInvoiceRenderAction } from "./hooks/useInvoiceRenderActions";
import OnCallStatus from "../OnCallContainers/OCManageBookings/components/OnCallStatus/OnCallStatus";
import InvoiceSidebarTitle from "./components/InvoiceSidebarTitle/InvoiceSidebarTitle";
import useFetchShips from "../ManageBookings/hooks/useFetchShips";
import { useFetchDispAddresses } from "../../hooks/useFetchDispAddresses";
import { useFetchInvoices } from "../../hooks/useFetchInvoices";
import { buildInvoicesSearchPayload } from "./shared/filters";
import { searchParamsInit } from "../../models/searchParams";
import { buildDispAddressesPayload } from "../DispManageAddresses/DispManageAddresses";
import { useFetchInitialInvoice } from "../../hooks/useFetchInitialInvoice";

/**
 * Renders the Dispatcher Manage Invoices page where the user can add new invoices and manage existing ones.
 * Invoices are displayed in a table format with filtering and sorting options available for multiple fields.
 * The user can perform the following actions:
 * - Send to Accounting: Sends the selected invoice(s) to accounting and sets their status as "Invoiced".
 * - Cancel Invoice: Sets the status of the selected invoice to "Cancelled".
 * - Booking Management: Redirects the user to the Manage Bookings page.
 * - Delete Invoice: Sets the status of the selected invoice to "Deleted".
 * - Copy Cancelled Invoice: Copies the selected invoice with the status "Cancelled".
 * - View Invoice: Opens a dialog with the details of the selected invoice.
 * Multiple invoices can be selected and sent to accounting in a batch.
 * The invoice form is placed in the sidebar of the page.
 */
export const ManageInvoices = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [filters, setFilters] = useGuiConfState("dispManageInvoices");
    const [selectedInvoiceId, setSelectedInvoiceId] = useUrlState(
        "selectedInvoiceId",
        "",
        Serializers.string,
    );
    const { data: initialInvoice } = useFetchInitialInvoice(selectedInvoiceId);
    const {
        data: invoiceData,
        refetch: fetchInvoices,
        loading,
    } = useFetchInvoices({ payload: buildInvoicesSearchPayload(filters) });
    const invoices = useMemo(() => invoiceData?.invoices ?? [], [invoiceData]);
    const totalInvoices = invoiceData?.total ?? 0;

    const readyForBillingInvoices = useMemo(
        () => getReadyForBillingInvoiceIds(invoices),
        [invoices],
    );
    const [selectedInvoiceIds, selectedInvoicesHandler] = useBatchToggleControl();

    const allInvoicesSelected =
        selectedInvoicesHandler.count > 0 &&
        selectedInvoicesHandler.count === readyForBillingInvoices.length;

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const [ships] = useFetchShips();

    const { data: addressData, refetch: fetchAddresses } = useFetchDispAddresses(
        buildDispAddressesPayload({
            ...searchParamsInit,
            addressType: AddressType.InvoiceAddress,
            order: ["company asc"],
            addressSource: undefined,
            status: undefined,
            rowsPerPage: 0,
            page: 0,
        }),
    );
    const addresses = useMemo(() => addressData?.addresses ?? [], [addressData]);

    const [selectedInvoice, setSelectedInvoice] = useState<IInvoice>();
    const [dialogOpen, setDialogOpen] = useState(false);
    const navigate = useNavigate();
    const [initialCompanyNameFilter] = useUrlState("companyName", "", Serializers.string);

    const [showFilters, setShowFilters] = useState(false);

    useEffect(() => {
        if (addresses?.find(add => add.company === initialCompanyNameFilter)) {
            setFilters({ internalInvoiceNumber: initialCompanyNameFilter ?? "" });
        }
    }, [initialCompanyNameFilter, addresses, setFilters]);

    useEffect(() => {
        if (!initialInvoice) return;
        setSelectedInvoice(initialInvoice);
        setSidebarOpen(true);
    }, [initialInvoice]);

    const handleChangePage = useCallback(
        (_event: unknown, page: number) => {
            setFilters({ page });
        },
        [setFilters],
    );

    const handleChangeRowsPerPage = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setFilters({
                rowsPerPage: parseInt(event.target.value, 10),
                page: 0,
            });
        },
        [setFilters],
    );

    const handleChangeInvoice = useCallback(
        async (row: IInvoice, status: InvoiceStatus) => {
            try {
                await changeInvoiceStatus(row.id, status);
                dispatch(showBackendMessage(intl, "success", "updating", Messages.invoice));
                fetchInvoices();
            } catch {
                dispatch(showBackendMessage(intl, "error", "updating", Messages.invoice));
            }
        },
        [dispatch, intl, fetchInvoices],
    );

    const handleSelectedInvoiceIdChange = useCallback(
        (id: string) => {
            setSelectedInvoiceId(id);
            setSidebarOpen(id.length > 0);
            if (id.length === 0) {
                setSelectedInvoice(undefined);
            }
        },
        [setSelectedInvoice, setSelectedInvoiceId],
    );

    const handleInvoiceDetails = useCallback(
        (row: IInvoice) => {
            setSelectedInvoice(row);
            handleSelectedInvoiceIdChange(String(row.id));
        },
        [handleSelectedInvoiceIdChange, setSelectedInvoice],
    );

    const handleCopyCancelledInvoice = useCallback(
        async (id: number) => {
            try {
                const res = await copyCancelledInvoice(String(id));
                dispatch(showBackendMessage(intl, "success", "copying", Messages.invoice));
                fetchInvoices();
                handleInvoiceDetails(res);
            } catch {
                dispatch(showBackendMessage(intl, "error", "copying", Messages.invoice));
            }
        },
        [dispatch, intl, handleInvoiceDetails, fetchInvoices],
    );

    const handleOpenInvoiceDialog = useCallback(
        (row: IInvoice) => {
            setSelectedInvoice(row);
            setDialogOpen(true);
        },
        [setSelectedInvoice],
    );

    const downloadInvoiceCsv = useCallback(
        async (id: number) => {
            try {
                const TOKEN = await getInvoiceCsvLink(String(id));
                if (TOKEN) {
                    const url = `/download/invoiceCsv?t=${TOKEN}`;
                    window.open(url, "_blank");
                }
            } catch {
                dispatch(showBackendMessage(intl, "error", "downloading", Messages.invoice));
            }
        },
        [dispatch, intl],
    );

    const invoiceActions = useMemo(
        () => ({
            sendToAccounting: (row: IInvoice) => {
                handleChangeInvoice(row, InvoiceStatus.INVOICED);
                downloadInvoiceCsv(row.id);
            },
            bookingManagement: (row: IInvoice) => {
                navigate(`/manage-bookings?selectedBookingId=${row.bookingId ?? ""}`);
            },
            cancelInvoice: (row: IInvoice) => {
                handleChangeInvoice(row, InvoiceStatus.CANCELLED);
            },
            deleteInvoice: (row: IInvoice) => {
                handleChangeInvoice(row, InvoiceStatus.DELETED);
            },
            copyCancelledInvoice: (row: IInvoice) => {
                handleCopyCancelledInvoice(row.id);
            },
            viewInvoice: (row: IInvoice) => {
                handleOpenInvoiceDialog(row);
            },
        }),
        [
            handleChangeInvoice,
            downloadInvoiceCsv,
            navigate,
            handleCopyCancelledInvoice,
            handleOpenInvoiceDialog,
        ],
    );

    const displayBookingInfo = (row: IInvoice) => {
        if (row.booking) {
            const { arrivalTime, departureTime, actualArrivalTime, actualDepartureTime } =
                row.booking;
            const shipName =
                ships.find(ship => ship.id === row.shipId)?.name ?? row.booking.shipName ?? "";
            const arrivalTimeDisplay = actualArrivalTime ? actualArrivalTime : arrivalTime;
            const departureTimeDisplay = actualDepartureTime ? actualDepartureTime : departureTime;
            return `${shipName}, ${getDateText(arrivalTimeDisplay)} - ${getDateText(
                departureTimeDisplay,
            )}`;
        }
    };

    const toggleSelectedInvoices = () => {
        if (allInvoicesSelected) {
            selectedInvoicesHandler.clearAll();
            return;
        }
        selectedInvoicesHandler.setValue(
            invoices
                .filter(i => i.invoiceStatus === InvoiceStatus.READY_FOR_BILLING)
                .map(({ id }) => id),
        );
    };

    const sendInvoiceResponses = async () => {
        try {
            for (let i = 0; i < selectedInvoiceIds.length; i++) {
                await changeInvoiceStatus(selectedInvoiceIds[i], InvoiceStatus.INVOICED);
            }
            dispatch(showBackendMessage(intl, "success", "updating", Messages.invoices));
            fetchInvoices();
        } catch {
            dispatch(showBackendMessage(intl, "error", "updating", Messages.invoice));
        }
    };

    const onRespondToInvoiceClose = useCallback(() => {
        handleSelectedInvoiceIdChange("");
    }, [handleSelectedInvoiceIdChange]);

    const clearFilters = () => {
        setFilters({
            internalInvoiceNumber: undefined,
            dateFrom: null,
            dateTo: null,
            bookingDateFrom: null,
            bookingDateTo: null,
            invoiceStatus: [],
            shipId: null,
        });
    };

    const renderActions = useInvoiceRenderAction(row => handleInvoiceDetails(row), invoiceActions);

    const onInvoiceActionComplete = () => {
        fetchInvoices();
    };

    return (
        <RpisPage
            title={intl.formatMessage(Messages.manageInvoices)}
            className="manage-invoices-container"
        >
            {selectedInvoice && (
                <RpisDialog
                    className="view-invoice-dialog"
                    title={`${intl.formatMessage(Messages.invoice)} ${
                        selectedInvoice.internalInvoiceNumber
                    }`}
                    fullWidth={true}
                    size="xl"
                    dialogOpen={dialogOpen}
                    onClose={() => {
                        setSelectedInvoice(undefined);
                        setDialogOpen(false);
                    }}
                    content={<ViewInvoice selectedInvoice={selectedInvoice} />}
                />
            )}
            <SidebarLayout
                sidebarTitle={<InvoiceSidebarTitle invoice={selectedInvoice} />}
                open={sidebarOpen}
                onClose={() => onRespondToInvoiceClose()}
                sidebarContent={
                    sidebarOpen ? (
                        <InvoiceForm
                            ships={ships}
                            addresses={addresses?.filter(add => add.deleted === 0) ?? []}
                            setSidebarOpen={setSidebarOpen}
                            fetchAddresses={fetchAddresses}
                            selectedInvoice={selectedInvoice}
                            callback={onInvoiceActionComplete}
                        />
                    ) : undefined
                }
                leftSidebar={{
                    title: intl.formatMessage(Messages.filters),
                    open: showFilters,
                    onClose: () => setShowFilters(false),
                    children: (
                        <InvoiceFilters
                            ships={ships}
                            addresses={addresses ?? []}
                            filters={filters}
                            setFilters={setFilters}
                        />
                    ),
                }}
            >
                <Box display="flex" padding={1} gap={1} justifyContent="space-between">
                    <ButtonGroup color="primary" variant="outlined">
                        <Button
                            variant="contained"
                            startIcon={<Tune />}
                            onClick={() => setShowFilters(true)}
                        >
                            {intl.formatMessage(Messages.filters)}
                        </Button>
                        <Tooltip title={intl.formatMessage(Messages.clearFilters)}>
                            <Box display="flex">
                                <Button
                                    //disabled={disabledFilters}
                                    variant="contained"
                                    size="small"
                                    onClick={clearFilters}
                                >
                                    <Clear />
                                </Button>
                            </Box>
                        </Tooltip>
                    </ButtonGroup>

                    <Button
                        startIcon={<Add />}
                        variant="contained"
                        color="success"
                        onClick={() => {
                            setSelectedInvoice(undefined);
                            setSidebarOpen(true);
                        }}
                    >
                        {intl.formatMessage(Messages.addInvoice)}
                    </Button>
                    {selectedInvoiceIds.length > 0 && (
                        <Button
                            sx={{ marginRight: "2rem" }}
                            variant="contained"
                            onClick={() => sendInvoiceResponses()}
                        >
                            {intl.formatMessage(Messages.sendToAccounting)}
                        </Button>
                    )}
                </Box>

                <Datatable
                    className="manage-invoices-table-container"
                    items={invoices}
                    titleMapper={invoiceTitleMapper}
                    columnDefs={[
                        {
                            key: "",
                            label: (
                                <FormControlLabel
                                    label=""
                                    labelPlacement="top"
                                    control={
                                        <Switch
                                            onChange={toggleSelectedInvoices}
                                            checked={allInvoicesSelected}
                                        />
                                    }
                                />
                            ),
                            sortable: false,
                            content: row => (
                                <Switch
                                    checked={selectedInvoicesHandler.isActive(row.id)}
                                    onChange={() => selectedInvoicesHandler.toggle(row.id)}
                                    disabled={row.invoiceStatus !== InvoiceStatus.READY_FOR_BILLING}
                                />
                            ),
                        },
                        {
                            key: "internalInvoiceNumber",
                            label: intl.formatMessage(Messages.invoiceNumber),
                            sortable: true,
                            content: row => <>{row.internalInvoiceNumber ?? row.id}</>,
                        },
                        {
                            key: "invoiceDate",
                            label: intl.formatMessage(Messages.invoiceDate),
                            sortable: true,
                            content: row => getDateText(row.invoiceDate),
                        },
                        {
                            key: "status",
                            label: intl.formatMessage(Messages.status),
                            sortable: false,
                            content: row => displayStatus(row.invoiceStatus, intl),
                        },
                        {
                            key: "invoiceCompanyName",
                            label: intl.formatMessage(Messages.invoiceCompanyName),
                            sortable: true,
                            content: row => row.address.company ?? "",
                        },
                        {
                            key: "bookingInformation",
                            label: intl.formatMessage(Messages.bookingInformation),
                            sortable: false,
                            content: row => displayBookingInfo(row),
                        },
                        {
                            key: "totalCostNet",
                            label: intl.formatMessage(Messages.totalCostNet),
                            sortable: true,
                            content: row => displayAmount(row.totalCostNet, row.currency),
                        },
                        {
                            key: "onCallStatus",
                            label: `${intl.formatMessage(Messages.remarks)} / ${intl.formatMessage(
                                Messages.onCallStatus,
                            )}`,
                            sortable: false,
                            content: row => <OnCallStatus booking={row.booking} />,
                            align: "center",
                        },
                    ]}
                    renderActions={renderActions}
                    isActiveRow={row => selectedInvoice?.id === row.id && sidebarOpen}
                    sortOrder={filters.order}
                    onSortChange={order => setFilters({ order })}
                    loading={loading}
                    idMapper={row => row.id}
                    renderPagination={() => (
                        <TablePagination
                            component="div"
                            page={totalInvoices === 0 ? 0 : filters.page}
                            count={totalInvoices}
                            rowsPerPage={filters.rowsPerPage}
                            rowsPerPageOptions={BOOKINGS_ROWS_PER_PAGE_OPTS}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                />
            </SidebarLayout>
        </RpisPage>
    );
};

export default ManageInvoices;
