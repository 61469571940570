import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useDownloadDocument } from "../../../../../components/DocumentsTable/hooks/useDownloadDocument";
import Messages from "../../../../../localization/Messages";
import { showBackendMessage } from "../../../../../helpers/messagesHelper";
import {
    addBookingDocument,
    deleteBookingDocument,
    getUrlForUpload,
    uploadFileToStorage,
} from "../../../../../api/document";
import DocumentsTable from "../../../../../components/DocumentsTable/DocumentsTable";
import { IBooking } from "../../../../../models/booking";
import { useSidebarOwner } from "../../../../../contexts/SidebarOwnerContext";
import { IDocument } from "../../../../../models/document";

export const ManageBookingDocuments = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [documents, setDocuments] = useState<IDocument[]>([]);
    const [loading, setLoading] = useState(false);
    const [booking, setBooking] = useSidebarOwner<IBooking>();
    const handleDownload = useDownloadDocument();

    useEffect(() => {
        setDocuments(booking?.documents ?? []);
    }, [booking?.documents]);

    const handleUpload = async (file: File) => {
        if (!file) return;
        if (!booking?.id) return;
        try {
            setLoading(true);
            let res = await getUrlForUpload(file);
            await uploadFileToStorage(file, res.data.uploadUrl);

            const document: IDocument = {
                mimeType: file.type,
                lastModify: file.lastModified,
                name: file.name,
                size: file.size,
                path: res.data.path,
            };

            res = await addBookingDocument(booking.id, document);
            setBooking(res.data);
            setDocuments(res.data.documents ?? []);
        } catch {
            dispatch(showBackendMessage(intl, "error", "adding", Messages.document));
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (document: IDocument) => {
        if (!booking?.id) return;
        try {
            setLoading(true);
            const res = await deleteBookingDocument(booking.id, document.path);
            setBooking(res.data);
            setDocuments(res.data.documents ?? []);
            dispatch(showBackendMessage(intl, "success", "deleting", Messages.document));
        } catch {
            dispatch(showBackendMessage(intl, "error", "deleting", Messages.document));
        } finally {
            setLoading(false);
        }
    };

    return (
        <DocumentsTable
            documents={documents}
            onDownload={handleDownload}
            onDelete={handleDelete}
            onUpload={handleUpload}
            loading={loading}
        />
    );
};

export default ManageBookingDocuments;
