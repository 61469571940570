import { useIntl } from "react-intl";
import RpisPage from "../RpisPage/RpisPage";
import { useDispatch, useSelector } from "react-redux";
import Messages from "../../localization/Messages";
import SidebarLayout from "../SidebarLayout/SidebarLayout";
import { useState, useEffect, useCallback, useMemo } from "react";
import { Button, TablePagination } from "@mui/material";
import { IExpenseType } from "../../models/expenseType";
import { IExpense, displayExpenseStatus, getExpenseCsvMapper } from "../../models/expense";
import { searchExpenseType } from "../../api/expenseType";
import { searchParamsInit } from "../../models/searchParams";
import { showBackendMessage } from "../../helpers/messagesHelper";
import ExpenseForm from "./ExpenseForm";
import { AppState } from "../../store/configureStore";
import { changeExpense, changeInvoiceId, deleteExpense, searchExpense } from "../../api/expense";
import { DateFormat, getDateText } from "../../helpers/dateHelper";
import { ExpenseMenuButtonGroup } from "./components/ExpenseButtonMenuGroup/ExpenseButtonMenuGroup";
import { IInvoice, InvoiceStatus } from "../../models/invoice";
import { getInvoice, searchInvoice } from "../../api/invoice";
import ExpenseFilters from "./components/ExpenseFilters/ExpenseFilters";
import { useGuiConfState } from "../../hooks/useGuiConfState";
import { searchAddress } from "../../api/dispAddress";
import { AddressType, IAddress } from "../../models/address";
import { searchShipDisp } from "../../api/ship";
import { IShipDispatcher } from "../../models/shipDispatcher";
import { buildExpensesSearchPayload } from "./shared/filters";
import { BOOKINGS_ROWS_PER_PAGE_OPTS } from "../ManageBookings/shared/constants";
import { useCsvDownloader } from "../../hooks/useCsvDownloader";
import { displayAmount } from "../../helpers/moneyHelper";
import { Tune } from "@mui/icons-material";
import { Datatable } from "../../components/Datatable/Datatable";
import RpisDialog from "../RpisDialog/RpisDialog";
import ViewInvoice from "../../components/ViewInvoice/ViewInvoice";
import "./ManageExpenses.css";
import { formatDecimalNumber } from "../../helpers/numberDisplayHelper";

/**
 * Renders the Dispatcher Manage expenses page, where the user can create new and manage existing expenses.
 * Expenses are displayed in a table form which supports filtering and sorting by columns.
 * Aside from editing expenses, the user is provided with other actions such as assigning the expense to an invoice,
 * detaching from an invoice, deleting the expense, as well as viewing the invoice associated with a particular expense.
 * The page also provides the option to export the expenses table to a CSV file.
 * The CSV file should contain the following columns: Expense Type, Quantity, Total Cost Net, Expense Date, Status,
 * Owning Company, Managing Company, Ship, Invoice Number, and User Name.
 */
export const ManageExpenses = () => {
    const exportCsv = useCsvDownloader();
    const intl = useIntl();
    const dispatch = useDispatch();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [espenseTypes, setExpenseTypes] = useState<IExpenseType[]>([]);
    const [expenses, setExpenses] = useState<IExpense[]>([]);
    const [selectedExpense, setSelectedExpense] = useState<IExpense>();
    const [loading, setLoading] = useState(false);
    const [invoices, setInvoices] = useState<IInvoice[]>([]);
    const managingCompanies = useSelector((s: AppState) => s.managingCompanies).data;
    const [owningCompanies, setOwningCompanies] = useState<IAddress[]>([]);
    const [ships, setShips] = useState<IShipDispatcher[]>([]);
    const [filters, setFilters] = useGuiConfState("dispManageExpenses");
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [showFilters, setShowFilters] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState<IInvoice>();
    const [dialogOpen, setDialogOpen] = useState(false);

    const getExpenses = useCallback(async () => {
        try {
            setLoading(true);
            const searchParams = buildExpensesSearchPayload(filters);
            const apiResponse = await searchExpense(searchParams);
            setExpenses(apiResponse.data.rows ?? []);
            setTotalExpenses(apiResponse.data.total ?? 0);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.expenses));
        } finally {
            setLoading(false);
        }
    }, [dispatch, intl, filters]);

    useEffect(() => {
        getExpenses();
    }, [getExpenses]);

    useEffect(() => {
        const getExpenseTypes = async () => {
            try {
                const res = await searchExpenseType({
                    ...searchParamsInit,
                    filter: { $and: [{ custom: false }] },
                });
                setExpenseTypes(res.data.rows ?? []);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.expenseTypes));
            }
        };
        getExpenseTypes();
    }, [dispatch, intl]);

    const handleSelectExpense = (row: IExpense) => {
        setSelectedExpense(row);
        setSidebarOpen(true);
    };

    const handleCloseSidebar = () => {
        setSelectedExpense(undefined);
        setSidebarOpen(false);
    };

    useEffect(() => {
        const fetchInvoices = async () => {
            try {
                const res = await searchInvoice({
                    ...searchParamsInit,
                    filter: { $and: [{ $or: [{ invoiceStatus: InvoiceStatus.OPEN }] }] },
                    order: ["internalInvoiceNumber asc"],
                });
                setInvoices(res.data.rows ?? []);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.invoices));
            }
        };
        fetchInvoices();
    }, [dispatch, intl]);

    useEffect(() => {
        const getOwningCompanies = async () => {
            try {
                const res = await searchAddress({
                    ...searchParamsInit,
                    filter: { $and: [{ type: AddressType.OwningCompany }] },
                });
                setOwningCompanies(res.data.rows ?? []);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.owningCompanies));
            }
        };
        getOwningCompanies();
    }, [dispatch, intl]);

    useEffect(() => {
        const fetchShips = async () => {
            try {
                const res = await searchShipDisp();
                setShips(res.data.rows ?? []);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.ships));
            }
        };
        fetchShips();
    }, [dispatch, intl]);

    const assignToInvoice = useCallback(
        async (expense: IExpense, invoiceId: number | null) => {
            try {
                await changeInvoiceId(expense.id, invoiceId);
                expense.invoiceId = invoiceId;
                await changeExpense({ ...expense, internalInvoiceNumber: undefined });
                getExpenses();
            } catch {
                dispatch(showBackendMessage(intl, "error", "updating", Messages.expense));
            }
        },
        [dispatch, getExpenses, intl],
    );

    const handleDeleteExpense = useCallback(
        async (expenseId: string) => {
            try {
                await deleteExpense(expenseId);
                dispatch(showBackendMessage(intl, "success", "deleting", Messages.expense));
                getExpenses();
            } catch {
                dispatch(showBackendMessage(intl, "error", "deleting", Messages.expense));
            }
        },
        [dispatch, getExpenses, intl],
    );

    const handleOpenInvoiceDialog = useCallback(
        async (expense: IExpense) => {
            try {
                const res = await getInvoice(String(expense.invoiceId));
                setSelectedInvoice(res.data);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.invoice));
            }
        },
        [dispatch, intl],
    );

    useEffect(() => {
        if (selectedInvoice?.id) {
            setDialogOpen(true);
        }
    }, [selectedInvoice]);

    const expenseActions = useMemo(
        () => ({
            assignToInvoice: (expense: IExpense, invoiceId: number) =>
                assignToInvoice(expense, invoiceId),
            deleteExpense: (expenseId: string) => handleDeleteExpense(expenseId),
            detatchExpense: (expense: IExpense) => {
                assignToInvoice(expense, null);
            },
            viewInvoice: (expense: IExpense) => {
                handleOpenInvoiceDialog(expense);
            },
        }),
        [assignToInvoice, handleDeleteExpense, handleOpenInvoiceDialog],
    );

    const handleChangePage = useCallback(
        (_event: unknown, page: number) => {
            setFilters({ page });
        },
        [setFilters],
    );

    const handleChangeRowsPerPage = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setFilters({
                rowsPerPage: parseInt(event.target.value, 10),
                page: 0,
            });
        },
        [setFilters],
    );

    return (
        <RpisPage
            title={intl.formatMessage(Messages.manageExpenses)}
            className="manage-expenses-container"
        >
            {selectedInvoice && (
                <RpisDialog
                    className="view-invoice-dialog"
                    title={`${intl.formatMessage(Messages.invoice)} ${
                        selectedInvoice.internalInvoiceNumber
                    }`}
                    fullWidth={true}
                    size="xl"
                    dialogOpen={dialogOpen}
                    onClose={() => {
                        setSelectedInvoice(undefined);
                        setDialogOpen(false);
                    }}
                    content={<ViewInvoice selectedInvoice={selectedInvoice} />}
                />
            )}
            <SidebarLayout
                sidebarTitle={
                    selectedExpense?.bookingId
                        ? intl.formatMessage(Messages.bookingDetails)
                        : selectedExpense && !selectedExpense.bookingId
                        ? intl.formatMessage(Messages.editExpense)
                        : intl.formatMessage(Messages.createExpense)
                }
                open={sidebarOpen}
                onClose={() => handleCloseSidebar()}
                sidebarContent={
                    sidebarOpen ? (
                        <ExpenseForm
                            expenseTypes={espenseTypes}
                            handleCloseSidebar={handleCloseSidebar}
                            callback={getExpenses}
                            selectedExpense={selectedExpense}
                            invoices={invoices}
                        />
                    ) : undefined
                }
                leftSidebar={{
                    title: intl.formatMessage(Messages.filters),
                    open: showFilters,
                    onClose: () => setShowFilters(false),
                    children: (
                        <ExpenseFilters
                            expenseTypes={espenseTypes}
                            owningCompanies={owningCompanies}
                            setFilters={setFilters}
                            filters={filters}
                            ships={ships}
                            invoices={invoices}
                        />
                    ),
                }}
            >
                <div className="manage-expenses-actions-container">
                    <Button
                        startIcon={<Tune />}
                        variant="outlined"
                        onClick={() => setShowFilters(true)}
                    >
                        Filters
                    </Button>
                    <Button
                        className="whitespace-nowrap"
                        variant="contained"
                        color="success"
                        onClick={() =>
                            exportCsv(expenses, getExpenseCsvMapper(intl, managingCompanies))
                        }
                    >
                        {intl.formatMessage(Messages.exportCsv)}
                    </Button>
                    <Button
                        className="add-expense-button whitespace-nowrap"
                        variant="contained"
                        onClick={() => {
                            setSelectedExpense(undefined);
                            setSidebarOpen(true);
                        }}
                    >
                        {intl.formatMessage(Messages.newExpense)}
                    </Button>
                </div>

                <Datatable
                    items={expenses}
                    titleMapper={row => (
                        <strong>{`${row.name} ${getDateText(
                            row.expenseDate,
                            DateFormat.CLIENT_DATE_TIME,
                        )}${row.shipName ? ` (${row.shipName})` : ""}`}</strong>
                    )}
                    columnDefs={[
                        {
                            key: "name",
                            label: intl.formatMessage(Messages.expenseType),
                            sortable: true,
                            content: row => row.name,
                        },
                        {
                            key: "quantity",
                            label: intl.formatMessage(Messages.quantity),
                            sortable: false,
                            content: row => formatDecimalNumber(row.quantity),
                        },
                        {
                            key: "costNet",
                            label: intl.formatMessage(Messages.totalCostNet),
                            sortable: true,
                            content: row => displayAmount(row.costNet, row.currency),
                        },
                        {
                            key: "expenseDate",
                            label: intl.formatMessage(Messages.expenseDate),
                            sortable: true,
                            content: row => getDateText(row.expenseDate),
                        },
                        {
                            key: "status",
                            label: intl.formatMessage(Messages.status),
                            sortable: false,
                            content: row => displayExpenseStatus(intl, row.status),
                        },
                        {
                            key: "owningCompany",
                            label: intl.formatMessage(Messages.owningCompany),
                            sortable: true,
                            content: row => row?.owningCompany,
                        },
                        {
                            key: "managingCompany",
                            label: intl.formatMessage(Messages.managingCompany),
                            sortable: true,
                            content: row => managingCompanies.get(row.managingCompany) ?? "",
                        },
                        {
                            key: "shipName",
                            label: intl.formatMessage(Messages.ship),
                            sortable: true,
                            content: row => row.shipName ?? "",
                        },
                        {
                            key: "invoiceId",
                            label: intl.formatMessage(Messages.invoiceNumber),
                            sortable: true,
                            content: row => row.internalInvoiceNumber ?? row.invoiceId ?? "",
                        },
                        {
                            key: "createByName",
                            label: intl.formatMessage(Messages.userName),
                            sortable: false,
                            content: row => row.createByName ?? row.lastModifyBy ?? "",
                        },
                    ]}
                    className="manage-expenses-table-container"
                    isActiveRow={row => selectedExpense?.id === row.id && sidebarOpen}
                    sortOrder={filters.order}
                    onSortChange={order => setFilters({ order })}
                    loading={loading}
                    idMapper={row => row.id}
                    renderActions={row => (
                        <ExpenseMenuButtonGroup
                            invoices={invoices}
                            onClick={() => handleSelectExpense(row)}
                            row={row}
                            invoiceId={row.invoiceId ?? undefined}
                            menuActions={expenseActions}
                        />
                    )}
                    renderPagination={() => (
                        <TablePagination
                            component="div"
                            page={totalExpenses === 0 ? 0 : filters.page}
                            count={totalExpenses}
                            rowsPerPage={filters.rowsPerPage}
                            rowsPerPageOptions={BOOKINGS_ROWS_PER_PAGE_OPTS}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                />
            </SidebarLayout>
        </RpisPage>
    );
};
