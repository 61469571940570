import axios, { AxiosResponse } from "axios";
import { IExpense } from "../models/expense";
import { ISearchParams, searchParamsInit } from "../models/searchParams";
import { ApiSearchResponse } from "../models/common";

export const addExpense = async (expenseType: IExpense): Promise<AxiosResponse<IExpense>> => {
    return await axios.put("/dispatcher/addExpense", expenseType);
};

export const changeExpense = async (expenseType: IExpense): Promise<AxiosResponse<IExpense>> => {
    return await axios.patch("/dispatcher/changeExpense", expenseType);
};

export const deleteExpense = async (id: string): Promise<AxiosResponse<string>> => {
    return await axios.delete(`/dispatcher/deleteExpense/${id}`);
};

export const getExpense = async (id: string): Promise<AxiosResponse<IExpense>> => {
    return await axios.get(`/dispatcher/getExpense/${id}`);
};

export const searchExpense = async (
    searchParams: ISearchParams = searchParamsInit,
): ApiSearchResponse<IExpense> => {
    const result = await axios.post("/dispatcher/searchExpense", searchParams);
    //result.data.rows.sort((a: IExpense, b: IExpense) => a.ordnum - b.ordnum);
    return result;
};

export const changeInvoiceId = async (
    expenseId: string,
    invoiceId: number | null,
): Promise<AxiosResponse<IExpense>> => {
    return await axios.patch("/dispatcher/changeInvoiceId", {
        expenseId: expenseId,
        invoiceId: invoiceId,
    });
};

export const reorderExpenses = async (
    invoiceId: number,
    expenseIds: string[],
): Promise<AxiosResponse<void>> => {
    return await axios.patch("/dispatcher/reorderExpense", {
        invoiceId: invoiceId,
        items: expenseIds.map((id, index) => ({ id, ordnum: index + 1 })),
    });
};
