import { memo } from "react";
import "./index.css";

export type DataFieldProps = {
    title: string;
    value: string | number | null | undefined;
    hidden?: boolean;
    className?: string;
};

/**
 * Renders a data field component used in various components that display certain data of certain entity.
 * @param title - The title of the data field.
 * @param value - The value of the data field.
 * @param hidden - Determines whether the data field should be hidden.
 * @returns The rendered data field component.
 */
function DataField({ title, value, hidden, className }: DataFieldProps) {
    return (
        <>
            {!hidden && (
                <div className={`data-field ${className ?? ""}`}>
                    <h5>{title}</h5>
                    <p>{value ? value : "-"}</p>
                </div>
            )}
        </>
    );
}

export default memo(DataField);
