import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import {
    addDestination,
    changeDestination,
    getDestination,
    searchDestinationDisp,
} from "../../api/destinations";
import { getFieldRequiredMessage, showBackendMessage } from "../../helpers/messagesHelper";
import Messages from "../../localization/Messages";
import {
    addEditDestinationInit,
    currencies,
    Currency,
    IAddEditDestination,
} from "../../models/destination";
import { searchParamsInit } from "../../models/searchParams";
import Progress from "../Progress/Progress";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { getPortCommunities } from "../../api/portCommunity";
import { IPortCommunity } from "../../models/portCommunity";
import TimeZonesInput from "../../components/TimeZonesInput";
import { EditHolidaysButton } from "./components/EditHolidaysButton/EditHolidaysButton";

type DestinationErrors = {
    [Key in keyof Partial<IAddEditDestination>]: string;
};
const validationRegex = {
    tel: /^\+?[0-9\-/ ]*$/,
};

export interface IDestinationForm {
    setIsExistingUser?: React.Dispatch<React.SetStateAction<boolean | null>>;
}

/**
 * Renders the Destination form displayed on the Manage Destination page.
 * This component allows users to add or edit destination information.
 */
export const DestinationForm = ({ setIsExistingUser }: IDestinationForm) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [destination, setDestination] = useState(addEditDestinationInit);
    const [errors, setErrors] = useState<DestinationErrors>({});
    const [loading, setLoading] = useState(false);
    const [portCommunities, setPortCommunities] = useState<IPortCommunity[]>([]);

    const fetchDestination = useCallback(async () => {
        setLoading(true);
        try {
            const destinations = await searchDestinationDisp(searchParamsInit);
            // only one destination is returned for user, zero for new user
            if (destinations.data.rows.length > 0) {
                // existing user
                const res = await getDestination(destinations.data.rows[0].id);
                setDestination(res.data);
            } else {
                // new user
                setDestination(addEditDestinationInit);
            }
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.destination));
        } finally {
            setLoading(false);
        }
    }, [dispatch, intl]);

    useEffect(() => {
        fetchDestination();
    }, [fetchDestination]);

    const fetchPortCommunities = useCallback(async () => {
        setLoading(true);
        try {
            const res = await getPortCommunities();
            setPortCommunities(res.data ?? []);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.portCommunities));
        } finally {
            setLoading(false);
        }
    }, [dispatch, intl]);

    useEffect(() => {
        fetchPortCommunities();
    }, [fetchPortCommunities]);

    const validateDestination = () => {
        // TODO: add validation regex
        const newErrors: DestinationErrors = {};
        if (!destination.name) newErrors.name = getFieldRequiredMessage(intl, Messages.name);
        if (!destination.address)
            newErrors.address = getFieldRequiredMessage(intl, Messages.address);
        if (!destination.zip) newErrors.zip = getFieldRequiredMessage(intl, Messages.zip);
        if (!destination.city) newErrors.city = getFieldRequiredMessage(intl, Messages.city);
        if (!destination.community)
            newErrors.community = getFieldRequiredMessage(intl, Messages.community);
        if (!destination.tel)
            newErrors.tel = getFieldRequiredMessage(intl, Messages.contactTelAdministration);
        if (!destination.email)
            newErrors.email = getFieldRequiredMessage(intl, Messages.contactMail);
        if (!destination.accountingEmail)
            newErrors.accountingEmail = getFieldRequiredMessage(intl, Messages.accountingEmail);
        if (!destination.currency)
            newErrors.currency = intl.formatMessage(Messages.currencyIsRequired);
        if (!destination.zone) newErrors.zone = intl.formatMessage(Messages.timeZoneIsRequired);
        if ((destination.invoiceNoPrefix?.length ?? 0) > 12)
            newErrors.invoiceNoPrefix = intl.formatMessage(Messages.invoiceNoPrefixTooLong);
        if (
            typeof destination.invoiceNoLength === "number" &&
            (destination.invoiceNoLength < 4 || destination.invoiceNoLength > 9)
        )
            newErrors.invoiceNoLength = intl.formatMessage(Messages.invoiceNoLengthInvalid);
        return newErrors;
    };

    const handleSubmit = async () => {
        const newErrors = validateDestination();
        if (Object.keys(newErrors).length) {
            setErrors(newErrors);
        } else {
            try {
                if (!destination.companyId) {
                    await addDestination(destination);
                    dispatch(showBackendMessage(intl, "success", "creating", Messages.destination));
                    setIsExistingUser?.(true);
                } else {
                    await changeDestination(destination);
                    dispatch(showBackendMessage(intl, "success", "updating", Messages.destination));
                }
            } catch {
                dispatch(showBackendMessage(intl, "error", "updating", Messages.destination));
            }
        }
    };

    const onChange = (diff: Partial<IAddEditDestination>) => {
        setDestination(prev => ({ ...prev, ...diff }));
        const clearErros = Object.fromEntries(Object.entries(diff).map(([k]) => [k, undefined]));
        setErrors({ ...errors, ...clearErros });
    };

    const CopyLinkToClipboardButton = ({
        buttonText,
        endpoint,
    }: {
        buttonText: string;
        endpoint: string;
    }) => {
        const [buttonTextState, setButtonTextState] = useState(buttonText);
        const linkCopyText = window.location.origin + endpoint;

        const copyToClipboard = () => {
            navigator.clipboard
                .writeText(linkCopyText)
                .then(() => {
                    setButtonTextState(intl.formatMessage(Messages.copyAllocationPlanLinkSuccess));
                    setTimeout(() => setButtonTextState(buttonText), 2000);
                })
                .catch(err => console.error("Failed to copy: ", err));
        };

        return (
            <Button variant="contained" onClick={copyToClipboard}>
                {buttonTextState}
            </Button>
        );
    };

    return (
        <>
            {loading ? (
                <Progress />
            ) : (
                <div className="destination-form-container">
                    {!destination.companyId && (
                        <Alert severity="warning" className="destination-alert">
                            You have to enter destination data to start using the Dispatcher FGKS
                            module.
                        </Alert>
                    )}
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <h2>{intl.formatMessage(Messages.editDestination)}</h2>
                        <Box display="flex" gap={1.5} paddingRight={2}>
                            <EditHolidaysButton
                                destination={destination}
                                holidays={destination.holidays}
                                onSaveSuccess={holidays => onChange({ holidays })}
                            />
                            <CopyLinkToClipboardButton
                                endpoint={`/plan/public/${destination?.id}`}
                                buttonText={intl.formatMessage(Messages.copyAllocationPlanLink)}
                            />
                            <CopyLinkToClipboardButton
                                endpoint={`/plan/public/${destination?.id}?plus=true`}
                                buttonText={intl.formatMessage(Messages.copyAllocationPlanPlusLink)}
                            />
                            <Button variant="contained" onClick={handleSubmit}>
                                {intl.formatMessage(Messages.saveChanges)}
                            </Button>
                        </Box>
                    </Box>
                    <form className="column-form three-column-form">
                        <TextField
                            required
                            error={Boolean(errors.name)}
                            value={destination.name}
                            label={intl.formatMessage(Messages.name)}
                            onChange={e => onChange({ name: e.target.value })}
                            helperText={errors.name}
                            variant="filled"
                        />
                        <TextField
                            required
                            error={Boolean(errors.address)}
                            value={destination.address}
                            label={intl.formatMessage(Messages.address)}
                            onChange={e => onChange({ address: e.target.value })}
                            helperText={errors.address}
                            variant="filled"
                        />
                        <TextField
                            required
                            error={Boolean(errors.zip)}
                            value={destination.zip}
                            label={intl.formatMessage(Messages.zip)}
                            onChange={e => onChange({ zip: e.target.value })}
                            helperText={errors.zip}
                            variant="filled"
                        />
                        <TextField
                            required
                            error={Boolean(errors.city)}
                            value={destination.city}
                            label={intl.formatMessage(Messages.city)}
                            onChange={e => onChange({ city: e.target.value })}
                            helperText={errors.city}
                            variant="filled"
                        />
                        <TimeZonesInput
                            onChange={zone => onChange({ zone })}
                            value={destination.zone}
                            error={errors.zone}
                        />
                        <Autocomplete
                            options={currencies}
                            disableClearable
                            renderInput={params => (
                                <TextField
                                    variant="filled"
                                    {...params}
                                    label={intl.formatMessage(Messages.currency)}
                                    error={Boolean(errors.currency)}
                                    helperText={errors.currency}
                                />
                            )}
                            value={currencies.find(c => destination.currency === c)}
                            onChange={(_, value) => onChange({ currency: value ?? Currency.EUR })}
                        />
                        <Autocomplete
                            options={portCommunities}
                            getOptionLabel={o => o.name}
                            renderInput={params => (
                                <TextField
                                    variant="filled"
                                    {...params}
                                    required
                                    error={Boolean(errors.community)}
                                    value={destination.community}
                                    label={intl.formatMessage(Messages.community)}
                                    onChange={e => onChange({ community: e.target.value })}
                                    helperText={errors.community}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option.id}>
                                    {option.name}
                                </li>
                            )}
                            onChange={(_, value: IPortCommunity | null) =>
                                onChange({ community: value?.name })
                            }
                            value={
                                portCommunities.find(
                                    community => community.name === destination.community,
                                ) ?? null
                            }
                        />
                        <TextField
                            required
                            error={Boolean(errors.tel)}
                            value={destination.tel}
                            label={intl.formatMessage(Messages.contactTelAdministration)}
                            onChange={e => {
                                validationRegex.tel.test(e.target.value) &&
                                    onChange({ tel: e.target.value });
                            }}
                            helperText={errors.tel}
                            variant="filled"
                        />
                        <TextField
                            required
                            error={Boolean(errors.email)}
                            value={destination.email}
                            label={intl.formatMessage(Messages.contactMail)}
                            onChange={e => onChange({ email: e.target.value })}
                            helperText={errors.email}
                            variant="filled"
                        />
                        <Autocomplete
                            freeSolo
                            multiple
                            options={[]}
                            value={
                                destination.accountingEmail
                                    ? destination.accountingEmail.split(",")
                                    : []
                            }
                            onChange={(_, values) =>
                                onChange({ accountingEmail: values.join(",") })
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="filled"
                                    label={intl.formatMessage(Messages.accountingEmail)}
                                    helperText={errors.accountingEmail}
                                    error={Boolean(errors.accountingEmail)}
                                />
                            )}
                        />
                        <TextField
                            error={Boolean(errors.contactOnSite)}
                            value={destination.contactOnSite}
                            label={intl.formatMessage(Messages.contactOnSite)}
                            onChange={e => onChange({ contactOnSite: e.target.value })}
                            helperText={errors.contactOnSite}
                            variant="filled"
                        />
                        <TextField
                            error={Boolean(errors.invoiceNoPrefix)}
                            value={destination.invoiceNoPrefix}
                            label={intl.formatMessage(Messages.invoiceNoPrefix)}
                            onChange={e => onChange({ invoiceNoPrefix: e.target.value })}
                            helperText={errors.invoiceNoPrefix}
                            variant="filled"
                        />
                        <TextField
                            error={Boolean(errors.invoiceNoLength)}
                            value={destination.invoiceNoLength}
                            label={intl.formatMessage(Messages.invoiceNoLength)}
                            onChange={e => onChange({ invoiceNoLength: Number(e.target.value) })}
                            helperText={errors.invoiceNoLength}
                            variant="filled"
                        />
                        <FormControlLabel
                            label={intl.formatMessage(Messages.active)}
                            control={
                                <Checkbox
                                    checked={Boolean(destination.active)}
                                    onChange={e =>
                                        onChange({
                                            active: e.target.checked ? 1 : 0,
                                        })
                                    }
                                />
                            }
                        />
                    </form>
                </div>
            )}
        </>
    );
};

export default DestinationForm;
