import { useIntl } from "react-intl";
import { IInvoice } from "../../../../models/invoice";
import { useCallback, useRef, useState } from "react";
import {
    Autocomplete,
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Menu,
    MenuItem,
    TextField,
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import Messages from "../../../../localization/Messages";
import { ExpenseStatus, IExpense } from "../../../../models/expense";
import "./ExpenseButtonMenuGroup.css";

type ExpenseMenuButtonGroupProps = {
    row: IExpense;
    invoices: IInvoice[];
    invoiceId?: number;
    onClick: () => void;
    menuActions: {
        assignToInvoice: (row: IExpense, invoiceId: number) => void;
        deleteExpense: (id: string) => void;
        detatchExpense: (row: IExpense) => void;
        viewInvoice: (row: IExpense) => void;
    };
};

/**
 * Represents the button group in the expenses table of the Manage Expenses page.
 * This component is a part of every row in the table and provides actions for the user.
 *
 * @component
 * @example
 * ```tsx
 * <ExpenseMenuButtonGroup
 *    row={expenseRow}
 *    onClick={handleClick}
 *    menuActions={{
 *        assignToInvoice: handleAssignToInvoice,
 *        deleteExpense: handleDeleteExpense,
 *        detatchExpense: handleDetatchExpense,
 *        viewInvoice: handleViewInvoice,
 *    }}
 *    invoiceId={invoiceId}
 *    invoices={invoices}
 * />
 * ```
 */
export const ExpenseMenuButtonGroup = ({
    row,
    onClick,
    menuActions,
    invoiceId,
    invoices,
}: ExpenseMenuButtonGroupProps) => {
    const intl = useIntl();
    const [open, setOpen] = useState(false);
    const menuButtonRef = useRef<HTMLButtonElement>(null);
    const [assignMenuDialog, setAssignMenuDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [invoice, setInvoice] = useState<IInvoice>();

    const handleToggle = useCallback(() => {
        setOpen(prev => !prev);
    }, []);
    const handleAssignInvoice = () => {
        invoice?.id && menuActions.assignToInvoice(row, invoice.id);
    };

    const handleDeleteExpense = () => {
        menuActions.deleteExpense(row.id);
    };

    const handleCloseDialog = () => {
        setAssignMenuDialog(false);
        setDeleteDialog(false);
    };

    return (
        <>
            <ButtonGroup variant="contained" style={{ background: "blue" }}>
                <Button onClick={onClick}>{intl.formatMessage(Messages.edit)}</Button>

                <Button size="small" ref={menuButtonRef} onClick={handleToggle}>
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>

            <Menu anchorEl={menuButtonRef?.current} open={open} onClose={handleToggle}>
                <MenuItem
                    disabled={Boolean(row.invoiceId)}
                    onClick={() => setAssignMenuDialog(true)}
                >
                    {intl.formatMessage(Messages.assignToInvoice)}
                </MenuItem>

                <MenuItem
                    disabled={
                        !invoiceId ||
                        [
                            ExpenseStatus.DELETED,
                            ExpenseStatus.INVOICED,
                            ExpenseStatus.CANCELLED,
                        ].includes(row.status as ExpenseStatus)
                    }
                    onClick={() => invoiceId && menuActions.detatchExpense(row)}
                >
                    {intl.formatMessage(Messages.detatchFromInvoice)}
                </MenuItem>
                <MenuItem
                    disabled={
                        ![ExpenseStatus.OPEN, ExpenseStatus.NOT_ASSIGNED].includes(
                            row.status as ExpenseStatus,
                        )
                    }
                    onClick={() => {
                        setDeleteDialog(true);
                    }}
                >
                    {intl.formatMessage(Messages.delete)}
                </MenuItem>
                <MenuItem
                    disabled={!row.invoiceId}
                    onClick={() => {
                        menuActions.viewInvoice(row);
                    }}
                >
                    {intl.formatMessage(Messages.viewInvoice)}
                </MenuItem>
            </Menu>

            <Dialog
                open={assignMenuDialog || deleteDialog}
                className="expense-actions-dialog"
                onClose={() => {
                    handleCloseDialog();
                    setInvoice(undefined);
                }}
            >
                <DialogTitle>
                    {assignMenuDialog
                        ? intl.formatMessage(Messages.assignToInvoice)
                        : deleteDialog && intl.formatMessage(Messages.deleteExpense)}
                </DialogTitle>
                <DialogContent>
                    {assignMenuDialog ? (
                        <>
                            <span>{intl.formatMessage(Messages.assignToInvoiceMessage)}</span>
                            <Autocomplete
                                id="invoice-company-name"
                                options={invoices}
                                value={invoices.find(inv => inv.id === invoice?.id) ?? null}
                                onChange={(_, value) => setInvoice(value ?? undefined)}
                                getOptionLabel={o =>
                                    `${o.internalInvoiceNumber} - ${o.invoiceCompanyName}`
                                }
                                isOptionEqualToValue={(a, b) => a.id === b.id}
                                renderInput={params => (
                                    <TextField
                                        variant="filled"
                                        {...params}
                                        label={intl.formatMessage(Messages.invoice)}
                                    />
                                )}
                                renderOption={(props, o) => (
                                    <li {...props} key={o.id}>
                                        {o.internalInvoiceNumber} {o.invoiceCompanyName}
                                    </li>
                                )}
                            />
                        </>
                    ) : (
                        deleteDialog && (
                            <span>{intl.formatMessage(Messages.deleteExpenseMessage)}</span>
                        )
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            handleCloseDialog();
                        }}
                    >
                        {intl.formatMessage(Messages.cancel)}
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            assignMenuDialog
                                ? handleAssignInvoice()
                                : deleteDialog && handleDeleteExpense();
                            handleCloseDialog();
                        }}
                    >
                        {intl.formatMessage(Messages.confirm)}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
