import axios, { AxiosResponse } from "axios";
import { ISearchParams, searchParamsInit } from "../models/searchParams";

export const searchNotifications = async (
    searchParams: ISearchParams = searchParamsInit,
    endpoint: string,
): Promise<AxiosResponse> => {
    return await axios.post(`/${endpoint}/searchNotification`, searchParams);
};

export const markReadNotification = async (
    endpoint: string,
    id: string,
): Promise<AxiosResponse> => {
    return await axios.get(`/${endpoint}/markReadNotification/${id}`);
};

export const markAllNotficationsAsRead = async (
    endpoint: string,
    userId: string,
): Promise<AxiosResponse> => {
    return await axios.get(`/${endpoint}/markAllRead/${userId}`);
};
