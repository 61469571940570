import { useIntl } from "react-intl";
import DataField from "../../../../../components/DataField";
import { IAddress } from "../../../../../models/address";
import Messages from "../../../../../localization/Messages";

type AddressContainerProps = {
    address: IAddress;
};

/**
 * Represents a segment of the ViewAddress component that displays data for one address.
 *
 * @component
 * @example
 * // Usage
 * <AddressContainer address={addressData} />
 *
 * @param {Object} props - The component props.
 * @param {IAddress} props.address - The address data to be displayed.
 *
 * @returns {JSX.Element} The rendered AddressContainer component.
 */
export const AddressContainer = ({ address }: AddressContainerProps) => {
    const intl = useIntl();

    return (
        <div className="column-form four-column-form">
            <DataField
                value={address.company}
                title={intl.formatMessage(Messages.companyName)}
                hidden={!address.company}
            />
            <DataField
                value={address.country}
                title={intl.formatMessage(Messages.country)}
                hidden={!address.country}
            />
            <DataField
                value={address.city}
                title={intl.formatMessage(Messages.city)}
                hidden={!address.city}
            />
            <DataField
                value={address.street}
                title={intl.formatMessage(Messages.street)}
                hidden={!address.street}
            />
            <DataField
                value={address.streetNo}
                title={intl.formatMessage(Messages.streetNo)}
                hidden={!address.streetNo}
            />
            <DataField
                value={address.zip}
                title={intl.formatMessage(Messages.zip)}
                hidden={!address.zip}
            />
            <DataField
                value={address.firstName}
                title={intl.formatMessage(Messages.firstName)}
                hidden={!address.firstName}
            />
            <DataField
                value={address.surname}
                title={intl.formatMessage(Messages.surname)}
                hidden={!address.surname}
            />
            <DataField
                value={address.tel}
                title={intl.formatMessage(Messages.telephone)}
                hidden={!address.tel}
            />
            <DataField
                value={address.email}
                title={intl.formatMessage(Messages.email)}
                hidden={!address.email}
            />
            <DataField
                value={address.addition}
                title={intl.formatMessage(Messages.addition)}
                hidden={!address.email}
            />
        </div>
    );
};
export default AddressContainer;
