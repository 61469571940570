import { IAddress } from "./address";
import { IDocument } from "./document";

export interface IAddEditShip {
    id: string;
    name: string;
    eni?: string;
    mmsi?: string;
    flag?: string;
    regPort?: string;
    onBoardSewage?: string;
    certExpireDate?: string | null;
    lastInspection?: string | null;
    validFrom?: string | null;
    validTo?: string | null;
    tel1?: string;
    tel2?: string;
    email: string;
    companyId: string;
    owningCompanyAddressId: string;
    passengerBeds?: number | null;
    length: number | null;
    width?: number | null;
    portalWidth?: number | null;
    entranceHeight?: number | null;
    entranceHeight2?: number | null;
    inspectionCount?: number | null;
    currentAmps?: number | null;
    inactive: 0 | 1;
    shipCertificate: IDocument | null;
    deleted?: number;
}

export interface IShip extends IAddEditShip {
    lastUpdated: string;
    keyNumber?: string;
    keyNumberActive?: boolean;
    electricityCardNumber?: string;
    electricityCardNumberActive?: boolean;
    electricityCardDeliveryDate?: string | null;
    keyDeliveryDate?: string | null;
    dateIssueCurPluginCard?: string | null;
    lastModShipData?: string | null;
    remarks?: string;
    remarksTechnical?: string;
    portRelCustomerNumber?: string;
    complete: boolean;
    invoiceAddress?: IAddress;
    contactDetails?: IAddress;
    charterCompany?: IAddress;
    owningCompany?: IAddress;
}

export const addEditShipInit: IAddEditShip = {
    id: "",
    name: "",
    email: "",
    companyId: "",
    owningCompanyAddressId: "",
    length: null,
    inactive: 0,
    eni: "",
    mmsi: "",
    flag: "",
    regPort: "",
    onBoardSewage: "NO",
    certExpireDate: "",
    lastInspection: "",
    validFrom: "",
    validTo: "",
    tel1: "",
    tel2: "",
    width: null,
    portalWidth: null,
    entranceHeight: null,
    inspectionCount: null,
    currentAmps: null,
    shipCertificate: null,
};

export const shipInit: IShip = {
    ...addEditShipInit,
    lastUpdated: "",
    complete: false,
};
