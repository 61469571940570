import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./HomePageTemplate.css";
import UserCorner from "../../components/UserCorner/UserCorner";

export interface NavigationButton {
    label: string;
    icon?: React.ReactNode;
    link?: string;
    action?: () => void;
    url?: string;
}

export interface IHomePageTemplate {
    title: string;
    items: NavigationButton[];
    itemsRow2?: NavigationButton[];
}

/**
 * Represents the template for the home page.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the home page.
 * @param {NavigationButton[]} props.items - The navigation buttons for the first row.
 * @param {NavigationButton[]} [props.itemsRow2] - The navigation buttons for the second row (optional).
 * @returns {JSX.Element} The rendered component.
 */
export const HomePageTemplate = ({
    title,
    items: itemsRow1,
    itemsRow2 = [],
}: IHomePageTemplate) => {
    const navigate = useNavigate();

    return (
        <main className="home-page-container">
            <section id="settings-section">
                <UserCorner />
            </section>
            <h1 className="home-page-title">{title}</h1>
            <BottomNavigation showLabels>
                {itemsRow1.map((item, i) => (
                    <BottomNavigationAction
                        key={i}
                        label={item.label}
                        icon={item.icon}
                        onClick={() => {
                            if (item.action) item.action();
                            else if (item.link) navigate(item.link);
                            else if (item.url) window.open(item.url, "_blank");
                        }}
                    />
                ))}
            </BottomNavigation>
            <BottomNavigation showLabels>
                {itemsRow2.map((item, i) => (
                    <BottomNavigationAction
                        key={i}
                        label={item.label}
                        icon={item.icon}
                        onClick={() => {
                            if (item.action) item.action();
                            else if (item.link) navigate(item.link);
                            else if (item.url) window.open(item.url, "_blank");
                        }}
                    />
                ))}
            </BottomNavigation>
        </main>
    );
};
