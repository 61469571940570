import { useState, useEffect, useCallback } from "react";
import RpisPage from "../RpisPage/RpisPage";
import Messages from "../../localization/Messages";
import { useIntl } from "react-intl";
import "./LDManageAddresses.css";
import SidebarLayout from "../SidebarLayout/SidebarLayout";
import {
    Autocomplete,
    Button,
    FilledInput,
    FormControl,
    InputAdornment,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
} from "@mui/material";
import { TableSkeleton } from "../../components/TableSkeleton/TableSkeleton";
import AddressForm from "./AddressForm";
import { AddressType, AddressTypes, IAddress } from "../../models/address";
import { deleteAddress, searchAddress } from "../../api/address";
import { searchParamsInit } from "../../models/searchParams";
import { useDispatch } from "react-redux";
import { showBackendMessage } from "../../helpers/messagesHelper";
import EditIcon from "@mui/icons-material/Edit";
import classNames from "classnames";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { SortOrder, useTableSort } from "../../hooks/useTableSort";
import { DatatablePaginationFilters, useGuiConfState } from "../../hooks/useGuiConfState";
import TableCellSorted from "../../components/TableCellSorted";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import { showToastMessage } from "../../actions/toastMessageActions";

export type LDManageAddressesFilters = DatatablePaginationFilters & {
    addressType?: AddressType;
    textSearch: string;
    order: SortOrder;
};

function buildFilters(filters: LDManageAddressesFilters) {
    const filterArray: object[] = [];
    if (filters.addressType) {
        filterArray.push({ type: filters.addressType });
    }
    return { $and: filterArray };
}

function buildPayload(filters: LDManageAddressesFilters) {
    return {
        ...searchParamsInit,
        offset: filters.page * filters.rowsPerPage,
        limit: filters.rowsPerPage,
        order: filters.order,
        textSearch: filters.textSearch,
        filter: buildFilters(filters),
    };
}

const LD_ADDRESSES_SORTABLE_COLUMNS = [
    "type",
    "company",
    "street",
    "firstName",
    "tel",
    "email",
] as const;

export const errorMessages = [
    {
        id: 0,
        messageCode: "invoiceAddressInUseVoyages",
        message: Messages.invoiceAddressInUseVoyages,
    },
    {
        id: 1,
        messageCode: "invoiceAddressInUseActiveBooking",
        message: Messages.invoiceAddressInUseActiveBooking,
    },
];

/**
 * Represents the Locks & Docks Manage addresses page.
 * This page allows users to search, add, edit, and delete addresses.
 * It displays a table of addresses with sortable columns and provides filtering options.
 * It features a sidebar with an address form for adding or editing addresses.
 *
 * @returns The `ManageAddresses` component.
 */
export const ManageAddresses = () => {
    const withConfirm = useConfirmDialog();
    const dispatch = useDispatch();
    const intl = useIntl();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [addresses, setAddresses] = useState<IAddress[]>([]);
    const [selectedAddress, setSelectedAddress] = useState<IAddress>();
    const [totalAddresses, setTotalAddresses] = useState(0);
    const [filters, setFilters] = useGuiConfState("ldManageAddresses");
    const registerSort = useTableSort<typeof LD_ADDRESSES_SORTABLE_COLUMNS>({
        columns: LD_ADDRESSES_SORTABLE_COLUMNS,
        value: filters.order,
        onChange: order => setFilters({ order }),
    });

    const getAddresses = useCallback(async () => {
        try {
            setLoading(true);
            const res = await searchAddress(buildPayload(filters));
            setAddresses(res.data.rows ?? []);
            setTotalAddresses(res.data.total ?? 0);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.addresses));
        } finally {
            setLoading(false);
        }
    }, [intl, filters, dispatch]);

    useEffect(() => {
        getAddresses();
    }, [getAddresses]);

    const onAddAddress = () => {
        setSidebarOpen(false);
        getAddresses();
    };

    const handleSelectAddress = (row: IAddress) => {
        setSelectedAddress(row);
        setSidebarOpen(true);
    };

    const handleDeleteAddress = async (id: string) => {
        try {
            setLoading(true);
            await deleteAddress(id);
            dispatch(showBackendMessage(intl, "success", "deleting", Messages.address));
        } catch (err: any) {
            const msg = err.response.data.message as string;
            if (msg) {
                const message = errorMessages.find(message => message.messageCode === msg)?.message;
                dispatch(
                    showToastMessage(
                        intl.formatMessage(message ?? Messages.errorDeletingAddress),
                        "error",
                        10000,
                    ),
                );
            } else {
                dispatch(showBackendMessage(intl, "error", "deleting", Messages.address));
            }
        } finally {
            setSelectedAddress(undefined);
            getAddresses();
        }
    };

    const handleChangePage = (_event: unknown, newPage: number) => {
        setFilters({ page: newPage });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilters({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
        });
    };

    const clearFilters = () => {
        setFilters({
            addressType: undefined,
            textSearch: "",
            page: 0,
        });
    };

    return (
        <RpisPage
            title={intl.formatMessage(Messages.manageAddresses)}
            className="ld-manage-addresses-container"
        >
            <SidebarLayout
                sidebarTitle={intl.formatMessage(Messages.manageAddresses)}
                open={sidebarOpen}
                onClose={() => setSidebarOpen(false)}
                sidebarContent={
                    <AddressForm
                        selectedAddress={selectedAddress}
                        callback={onAddAddress}
                        setSidebarOpen={setSidebarOpen}
                    />
                }
            >
                <div className="title-and-actions-container">
                    <form id="search-form">
                        <Autocomplete
                            id="address-type-autocomplete"
                            options={AddressTypes}
                            getOptionLabel={o => intl.formatMessage(o.label)}
                            renderInput={params => (
                                <TextField
                                    variant="filled"
                                    {...params}
                                    label={intl.formatMessage(Messages.addressType)}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option.id}>
                                    {intl.formatMessage(option.label)}
                                </li>
                            )}
                            onChange={(_, value) => setFilters({ addressType: value?.type })}
                            value={
                                AddressTypes.find(add => add.type === filters.addressType) ?? null
                            }
                        />
                        <FormControl variant="filled">
                            <FilledInput
                                onChange={e => setFilters({ textSearch: e.target.value })}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                                value={filters.textSearch}
                                autoFocus={true}
                            />
                            <InputLabel>{intl.formatMessage(Messages.search)}</InputLabel>
                        </FormControl>
                        {(filters.addressType || filters.textSearch) && (
                            <Button variant="outlined" onClick={clearFilters}>
                                {intl.formatMessage(Messages.clear)}
                            </Button>
                        )}
                    </form>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setSidebarOpen(true);
                            setSelectedAddress(undefined);
                        }}
                    >
                        {intl.formatMessage(Messages.addAddress)}
                    </Button>
                </div>

                <TableContainer className="manage-addresses-table-container">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCellSorted
                                    label={intl.formatMessage(Messages.addressType)}
                                    {...registerSort("type")}
                                />
                                <TableCellSorted
                                    align="right"
                                    label={intl.formatMessage(Messages.companyName)}
                                    {...registerSort("company")}
                                />
                                <TableCellSorted
                                    align="right"
                                    label={intl.formatMessage(Messages.address)}
                                    {...registerSort("street")}
                                />
                                <TableCellSorted
                                    align="right"
                                    label={intl.formatMessage(Messages.contactName)}
                                    {...registerSort("firstName")}
                                />
                                <TableCellSorted
                                    align="right"
                                    label={intl.formatMessage(Messages.telephone)}
                                    {...registerSort("tel")}
                                />
                                <TableCellSorted
                                    align="right"
                                    label={intl.formatMessage(Messages.email)}
                                    {...registerSort("email")}
                                />
                                <TableCell align="center">
                                    {intl.formatMessage(Messages.action)}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {loading ? (
                            <TableSkeleton columns={7} rows={10} />
                        ) : (
                            <TableBody>
                                {addresses.map(row => (
                                    <TableRow
                                        key={row.id}
                                        className={classNames({
                                            "active-table-row":
                                                selectedAddress?.id === row.id && sidebarOpen,
                                        })}
                                    >
                                        <TableCell>
                                            {intl.formatMessage(
                                                AddressTypes.find(add => add.type === row.type)
                                                    ?.label ?? Messages.unavailable,
                                            )}
                                        </TableCell>
                                        <TableCell align="right">{row.company}</TableCell>
                                        <TableCell align="right">{`${row.street ?? ""} ${
                                            row.streetNo ?? ""
                                        }, ${row.zip ?? ""} ${row.city ?? ""}`}</TableCell>
                                        <TableCell align="right">{`${row.firstName ?? ""} ${
                                            row.surname ?? ""
                                        }`}</TableCell>
                                        <TableCell align="right">{row.tel ?? ""}</TableCell>
                                        <TableCell align="right">{row.email}</TableCell>
                                        <TableCell align="right">
                                            {row.type !== AddressType.InvoiceAddress && (
                                                <Button
                                                    startIcon={<EditIcon />}
                                                    variant="outlined"
                                                    onClick={() => handleSelectAddress(row)}
                                                >
                                                    {intl.formatMessage(Messages.edit)}
                                                </Button>
                                            )}
                                            <Button
                                                variant="outlined"
                                                className="delete-address-button"
                                                color="error"
                                                startIcon={<DeleteIcon />}
                                                onClick={() => {
                                                    withConfirm({
                                                        message: intl.formatMessage(
                                                            Messages.confirmAddressDelete,
                                                        ),
                                                        onConfirm: async () =>
                                                            await handleDeleteAddress(row.id),
                                                    });
                                                }}
                                            >
                                                {intl.formatMessage(Messages.delete)}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[50, 100, 250, 500]}
                    component="div"
                    count={totalAddresses}
                    rowsPerPage={filters.rowsPerPage}
                    page={filters.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </SidebarLayout>
        </RpisPage>
    );
};

export default ManageAddresses;
