import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { changeBerthing, searchBerthings } from "../../api/berthing";
import { searchBookings, sendBookingResponse } from "../../api/booking";
import { searchDocks } from "../../api/dock";
import {
    changeInternalBooking,
    deleteInternalBooking,
    searchInternalBooking,
} from "../../api/internalBooking";
import { DateFormat, getEventCalendarViewProps } from "../../helpers/dateHelper";
import {
    MbscEventCalendarInvalidMatcher,
    MbscEventCalendarInvalidResource,
    buildInternalCalendarEvents,
    buildStandardCalendarEvents,
} from "../../helpers/eventCalendarHelper";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { useGuiConfState } from "../../hooks/useGuiConfState";
import { useTrackCurrentCalendarPosition } from "../../hooks/useTrackCurrentCalendarPosition";
import Messages from "../../localization/Messages";
import { IBerthing } from "../../models/berthing";
import { IBooking, bookingInit } from "../../models/booking";
import {
    IAddEditInternalBooking,
    IInternalBooking,
    internalBookingInit,
} from "../../models/internalBooking";
import { Roles, getLocaleString } from "../../models/userData";
import BookingRespondForm from "../ManageBookings/components/BookingRespondForm";
import InternalBookingForm from "../ManageBookings/components/InternalBookingForm";
import Progress from "../Progress/Progress";
import RpisPage from "../RpisPage/RpisPage";
import SidebarLayout from "../SidebarLayout/SidebarLayout";
import { Serializers, useUrlState } from "../../hooks/useUrlState";
import EventCalendarFilters from "./components/EventCalendarFilters/EventCalendarFilters";
import { useAuth } from "../../hooks/useAuth";
import "@mobiscroll/react/css/mobiscroll.react.min.css";
import "./DockAllocationPlan.css";
import { OCBookingMenu } from "../OnCallContainers/OCManageBookings/components/OCBookingMenu/OCBookingMenu";
import BookingSidebarTitle from "../ManageBookings/components/BookingSidebarTitle";
import { useSidebarOwner } from "../../contexts/SidebarOwnerContext";

import {
    Eventcalendar,
    MbscCalendarEvent,
    MbscEventClickEvent,
    MbscEventCreateEvent,
    MbscEventCreatedEvent,
    MbscEventUpdateEvent,
    MbscEventUpdatedEvent,
    MbscResource,
    locale,
} from "@mobiscroll/react";

export type DockAllocationPlanFilters = {
    rangeVal: [Date, Date];
    currentDate: Date;
    resolution: number;
};

/**
 * The `DockAllocationPlan` component is responsible for displaying and managing the Dispatcher dock allocation plan.
 * Dock allocation plan page provides a calendar view of all bookings, berthings and internal bookings, allowing users to allocate docks and manage bookings.
 * The user can also create new bookings or respond to existing ones directly from dock allocation plan.
 * This page features a sidebar containing `BookingRespondForm` where user can respond to bookings, `InternalBookingForm` where user can create or edit internal bookings,
 * and `BookingDetails` where user can view booking info and add expenses to a booking.
 * @returns The `DockAllocationPlan` component.
 */
export const DockAllocationPlan = () => {
    const [calendarReady, setCalendarReady] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [updateConfirmationDialogOpen, setUpdateConfirmationDialogOpen] = useState(false);
    const [internalBookingFormOpen, setInternalBookingFormOpen] = useState(false);
    const [internalEditMode, setInternalEditMode] = useState(false);

    const [selectedBooking, setSelectedBooking] = useSidebarOwner<IBooking>();
    //const [selectedBooking, setSelectedBooking] = useState<IBooking>(bookingInit);

    const [docks, setDocks] = useState<MbscResource[]>([]);
    const [bookingEvents, setBookingEvents] = useState<MbscCalendarEvent[]>([]);
    const [internalBookings, setInternalBookings] = useState<IInternalBooking[]>([]);
    const [berthings, setBerthings] = useState<IBerthing[]>([]);
    const [normalBookings, setNormalBookings] = useState<IBooking[]>([]);
    const [updatedEvent, setUpdatedEvent] = useState<MbscEventUpdatedEvent>();
    const [oldEvent, setOldEvent] = useState<MbscCalendarEvent>();
    const [filters, setFilters] = useGuiConfState("dispDockAllocationPlan");
    const [selectedDate, setSelectedDate] = useState(filters.currentDate);
    const onFiltersChange = useCallback(
        (diff: Partial<DockAllocationPlanFilters>) => setFilters({ ...filters, ...diff }),
        [filters, setFilters],
    );
    const onCurrentCalendarPositionChange = useCallback(
        (pos: Date) => onFiltersChange({ currentDate: pos }),
        [onFiltersChange],
    );
    const [calendarRef, calendarInit] = useTrackCurrentCalendarPosition(
        onCurrentCalendarPositionChange,
    );
    const [selectedBookingId, setSelectedBookingId] = useUrlState(
        "selectedBookingId",
        "",
        Serializers.string,
    );

    const [counter, setCounter] = useState(0);
    const forceInternalBookingFormRerender = useCallback(() => setCounter(prev => prev + 1), []);

    const [newInternalBooking, setNewInternalBookingNaitive] =
        useState<IAddEditInternalBooking>(internalBookingInit);
    const setNewInternalBooking = useCallback(
        (row: IAddEditInternalBooking) => {
            setNewInternalBookingNaitive(row);
            forceInternalBookingFormRerender();
        },
        [forceInternalBookingFormRerender],
    );

    const { user, hasRole } = useAuth();
    const dispatch = useDispatch();
    const intl = useIntl();
    const isOnCallUser = hasRole(Roles.ON_CALL_DISPATCHER);
    const eventsExist = bookingEvents.length > 0;
    const selectedBookingIdText = `#${selectedBooking?.id}`;

    useEffect(() => {
        /**
         * Persists the initial booking by updating the filters and triggering an event click that opens that booking in the sidebar.
         * @returns {Promise<void>} A promise that resolves when the initial booking is persisted.
         */
        const persistInitialBooking = async (): Promise<void> => {
            try {
                const clickEvent = bookingEvents.find(
                    e => String(e.bookingId) === selectedBookingId,
                )!;
                const startString = clickEvent.start! as string;
                const endString = clickEvent.end! as string;
                const startDate = new Date(startString);
                startDate.setHours(0, 0, 0, 0);
                const endDate = new Date(endString);
                if (startDate.getTime() < filters.rangeVal[0].getTime()) {
                    onFiltersChange({
                        rangeVal: [startDate, filters.rangeVal[1]],
                        currentDate: startDate,
                    });
                } else if (endDate.getTime() > filters.rangeVal[1].getTime()) {
                    onFiltersChange({
                        rangeVal: [filters.rangeVal[0], endDate],
                        currentDate: startDate,
                    });
                }
                setTimeout(() => {
                    onFiltersChange({ currentDate: startDate });
                    setSelectedDate(startDate);
                }, 100);
                onEventClick({ event: clickEvent } as MbscEventClickEvent);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.booking));
            }
        };

        if (selectedBookingId && calendarReady) {
            persistInitialBooking();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBookingId, calendarReady]);

    const fetchData = useCallback(
        async (bookingId?: number) => {
            try {
                const apiResult = await searchDocks();
                const filteredDocks = apiResult.data.rows;
                const sortedDocks = filteredDocks.slice().sort((a, b) => a.ordnum - b.ordnum);
                const unallocatedDock = {
                    id: MbscEventCalendarInvalidResource.UNALLOCATED,
                    name: intl.formatMessage(Messages.unallocated),
                };
                const declinedDock = {
                    id: MbscEventCalendarInvalidResource.DECLINED,
                    name: `${intl.formatMessage(Messages.declined)} / ${intl.formatMessage(
                        Messages.canceled,
                    )}`,
                };
                const docks = isOnCallUser
                    ? [...sortedDocks, declinedDock]
                    : [unallocatedDock, ...sortedDocks, declinedDock];
                const apiPromises = [
                    searchBookings(),
                    searchBerthings(),
                    searchInternalBooking(),
                ] as const;
                const [bookingsRes, berthingsRes, internalBookingsRes] = await Promise.all(
                    apiPromises,
                );
                const normalBookings = bookingsRes.data.rows;
                const berthings = berthingsRes.data.rows;
                const internalBookings = internalBookingsRes.data.rows;

                const standardEvents = buildStandardCalendarEvents(normalBookings, berthings, intl);
                const internalEvents = buildInternalCalendarEvents(internalBookings, intl);
                const bookingEvents = [...standardEvents, ...internalEvents];

                setDocks(docks);
                setNormalBookings(normalBookings);
                setBerthings(berthings);
                setInternalBookings(internalBookings);
                setBookingEvents(bookingEvents);
                if (bookingId) {
                    setSelectedBooking(
                        normalBookings.find(({ id }) => id === bookingId) as IBooking,
                    );
                }
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.data));
            }
        },
        [dispatch, intl, isOnCallUser, setSelectedBooking],
    );

    const sendBookingResponses = useCallback(
        async (id?: number) => {
            if (!id) return;
            try {
                await sendBookingResponse([id]);
                dispatch(
                    showBackendMessage(intl, "success", "updating", Messages.bookingResponses),
                );
                fetchData(id);
            } catch {
                dispatch(showBackendMessage(intl, "error", "updating", Messages.bookingResponses));
            }
        },
        [fetchData, dispatch, intl],
    );

    /**
     * A higher-order function that takes a function as an optional parameter and returns a new function that calls the original function and then fetches data.
     * @param fn - An optional function to be called before fetching data.
     * @returns A new function that calls the original function and then fetches data.
     */
    const withReload = useCallback(
        (fn?: () => any) => {
            fn?.();
            fetchData();
        },
        [fetchData],
    );

    useEffect(() => {
        withReload();
    }, [withReload]);

    const onEventUpdated = useCallback<any>(
        async (event: any) => {
            try {
                setUpdateConfirmationDialogOpen(false);
                const selectedDock = docks.find(
                    dock => dock.id === event.event.resource?.toString(),
                );
                if (!event.event.title.toString().startsWith("#")) {
                    const internalBooking = internalBookings?.find(
                        internalBooking => internalBooking.id === event.event.id,
                    );
                    if (selectedDock && internalBooking) {
                        const modifiedInternalBooking = internalBooking as IInternalBooking;
                        modifiedInternalBooking.dockId = selectedDock?.id as string;
                        modifiedInternalBooking.arrivalTime = (event.event.start as Date).getTime();
                        modifiedInternalBooking.departureTime = (event.event.end as Date).getTime();
                        await changeInternalBooking(modifiedInternalBooking);
                        dispatch(
                            showBackendMessage(
                                intl,
                                "success",
                                "updating",
                                Messages.internalBooking,
                            ),
                        );
                    }
                } else {
                    const berthing = berthings?.find(berthing => berthing.id === event.event.id);
                    if (selectedDock && berthing) {
                        const modifiedBerthing = berthing as IBerthing;
                        modifiedBerthing.dockId = selectedDock?.id as string;
                        modifiedBerthing.arrivalTime = (event.event.start as Date).getTime();
                        modifiedBerthing.departureTime = (event.event.end as Date).getTime();
                        await changeBerthing(modifiedBerthing);
                        dispatch(
                            showBackendMessage(intl, "success", "updating", Messages.berthing),
                        );
                    }
                }
            } catch {
                dispatch(showBackendMessage(intl, "error", "updating", Messages.booking));
            } finally {
                setSidebarOpen(false);
            }
        },
        [dispatch, intl, docks, internalBookings, berthings],
    );

    const onEventUpdateCancel = () => {
        if (oldEvent) {
            setUpdateConfirmationDialogOpen(false);
            setBookingEvents([
                ...bookingEvents.filter(event => event.id !== oldEvent?.id),
                oldEvent,
            ]);
        }
    };

    const handleEventHighlights = useCallback(
        (event: MbscCalendarEvent, reset: boolean = false, selectId?: string) => {
            const eventId = selectId ? selectId : event.event.title.match(/#(\d+)/);
            if (!eventId) return;
            const matchingEventId = selectId ? selectId : eventId[0];
            setBookingEvents(events =>
                events.map(({ cssClass, ...event }) => {
                    const eventTitleIdMatch = event.title!.match(/#(\d+)/);
                    const matchesByTitleId =
                        eventTitleIdMatch && eventTitleIdMatch[0] === matchingEventId;
                    const existsInNormalBookings = !!normalBookings?.find(
                        ({ id }) => id === parseInt(matchingEventId.substring(1)),
                    );
                    const completeMatch =
                        matchesByTitleId && normalBookings && existsInNormalBookings;
                    let classNames: string[] = (cssClass ?? "").split(" ");
                    classNames = classNames.filter(className => className !== "matching-event");
                    if (completeMatch) classNames.push("matching-event");
                    if (reset)
                        classNames = classNames.filter(className => className !== "matching-event");
                    return { ...event, cssClass: classNames.join(" ") };
                }),
            );
        },
        [normalBookings],
    );

    const cancelBookingCreating = useCallback(() => {
        setSidebarOpen(false);
        setBookingEvents(bookingEvents =>
            bookingEvents.filter(bookingEvent => bookingEvent.id !== ""),
        );
        setInternalBookingFormOpen(false);
        setInternalEditMode(false);
        handleEventHighlights(selectedBooking ?? bookingInit, true, selectedBookingIdText);
        setSelectedBookingId("");
    }, [selectedBooking, selectedBookingIdText, setSelectedBookingId, handleEventHighlights]);

    const handleDeleteInternalBooking = useCallback(
        async (bookingGuid: string) => {
            if (!bookingGuid) return;
            try {
                await deleteInternalBooking(bookingGuid);
                dispatch(showBackendMessage(intl, "success", "deleting", Messages.internalBooking));
            } catch {
                dispatch(showBackendMessage(intl, "error", "deleting", Messages.internalBooking));
            } finally {
                withReload(() => {
                    setSidebarOpen(false);
                    setInternalBookingFormOpen(false);
                    setInternalEditMode(false);
                });
            }
        },
        [intl, dispatch, withReload],
    );

    const onEventUpdate = (event: MbscEventUpdateEvent) => {
        setOldEvent(event?.oldEvent);
        const selectedDock = docks.find(dock => dock.id === event.oldEvent?.resource?.toString());
        const dockId = selectedDock?.id;
        const preventBubbling =
            dockId === MbscEventCalendarInvalidResource.DECLINED ||
            dockId === MbscEventCalendarInvalidResource.UNALLOCATED;
        if (preventBubbling) return false; //* Prevents further event bubbling when dock is declined or unallocated
        setUpdateConfirmationDialogOpen(true);
    };

    const onEventClick = (event: MbscEventClickEvent) => {
        if (internalBookingFormOpen && !internalEditMode) return;

        const bookingEvent = bookingEvents.find(
            bookingEvent => bookingEvent.bookingId === event.event.bookingId,
        );
        if (!bookingEvent) return;
        let newSelectedBooking = bookingInit;

        const isInternal = !bookingEvent?.title?.toString().startsWith("#");

        if (isInternal) {
            setSelectedBooking(bookingInit);
            setInternalBookingFormOpen(true);
            setInternalEditMode(true);
            setNewInternalBooking(
                internalBookings.find(({ id }) => id === bookingEvent.id) as IInternalBooking,
            );
        } else {
            const selectedDock = docks.find(dock => dock.id === event.event.resource?.toString());
            const dockId = selectedDock?.id;
            const berthing = berthings.find(
                ({ bookingId }) => bookingId === bookingEvent.bookingId,
            );
            const isNormalEvent =
                dockId === MbscEventCalendarInvalidResource.DECLINED ||
                dockId === MbscEventCalendarInvalidResource.UNALLOCATED;
            if (isNormalEvent)
                newSelectedBooking = normalBookings.find(
                    ({ id }) => id === bookingEvent.bookingId,
                ) as IBooking;
            else if (berthing)
                newSelectedBooking = normalBookings.find(
                    ({ id }) => id === berthing?.bookingId,
                ) as IBooking;

            setInternalBookingFormOpen(false);
            setInternalEditMode(false);
        }

        setSelectedBooking({ ...newSelectedBooking });
        setSelectedBookingId(
            isInternal ? String(event.event.id ?? "") : String(event.event.bookingId),
        );
        setSidebarOpen(true);
        handleEventHighlights(event, false, `#${newSelectedBooking.id}`);
    };

    const onEventCreate = (event: MbscEventCreateEvent) => {
        const selectedDock = docks.find(dock => dock.id === event.event.resource?.toString());
        const dockId = selectedDock?.id;
        return dockId === MbscEventCalendarInvalidResource.DECLINED ||
            dockId === MbscEventCalendarInvalidResource.UNALLOCATED
            ? false //* Prevents further event bubbling when dock is declined or unallocated
            : undefined;
    };

    const onEventCreated = (event: MbscEventCreatedEvent) => {
        const selectedDock = docks.find(dock => dock.id === event.event.resource?.toString());
        const dockId = selectedDock?.id as string;
        const arrivalTime = (event.event.start as Date).getTime();
        const departureTime = (event.event.end as Date).getTime();
        const newInternalBooking = {
            ...internalBookingInit,
            dockId,
            arrivalTime,
            departureTime,
        };

        setSidebarOpen(true);

        setInternalBookingFormOpen(true);
        setNewInternalBooking(newInternalBooking);
    };

    const onFinalizeBooking = () => {
        fetchData();
    };

    // const selectedInvoiceId = selectedBooking?.invoices?.[0]?.id;
    // const disabledAddTooltip = isInvoiceEditable(selectedBooking?.invoices?.[0]?.invoiceStatus)
    //     ? ""
    //     : intl.formatMessage(Messages.invoiceIsNotEditable);

    const SidebarContent = !sidebarOpen ? undefined : (
        <>
            {isOnCallUser && <OCBookingMenu onAfterFinalize={onFinalizeBooking} />}
            {!isOnCallUser && internalBookingFormOpen && (
                <InternalBookingForm
                    key={counter}
                    selectedBooking={newInternalBooking}
                    internalEditMode={internalEditMode}
                    cancelBookingCreating={cancelBookingCreating}
                    handleDeleteInternalBooking={handleDeleteInternalBooking}
                    getBookings={fetchData}
                />
            )}
            {!isOnCallUser && !internalBookingFormOpen && (
                <BookingRespondForm
                    selectedBooking={selectedBooking!}
                    setSidebarOpen={setSidebarOpen}
                    getBookings={async (bookingId: number) => fetchData(bookingId)}
                    sendBookingResponses={sendBookingResponses}
                />
            )}
        </>
    );

    return (
        <RpisPage
            title={intl.formatMessage(Messages.dockAllocationPlan)}
            className="dock-allocation-plan-container"
        >
            <SidebarLayout
                open={sidebarOpen}
                onClose={cancelBookingCreating}
                sidebarContent={SidebarContent}
                sidebarTitle={
                    sidebarOpen ? (
                        internalBookingFormOpen ? (
                            internalEditMode ? (
                                `${intl.formatMessage(Messages.editInternalBooking)}`
                            ) : (
                                intl.formatMessage(Messages.createInternalBooking)
                            )
                        ) : (
                            <BookingSidebarTitle
                                reloadBookings={fetchData}
                                onFinalizeBooking={!isOnCallUser ? onFinalizeBooking : undefined}
                            />
                        )
                    ) : (
                        ""
                    )
                }
            >
                <div className="title-and-actions-container"></div>
                {eventsExist ? (
                    <Eventcalendar
                        ref={calendarRef}
                        view={getEventCalendarViewProps(
                            filters.rangeVal[0],
                            filters.rangeVal[1],
                            filters.resolution,
                        )}
                        selectedDate={selectedDate}
                        refDate={filters.rangeVal[0]}
                        onInit={() => {
                            calendarInit();
                            setCalendarReady(true);
                        }}
                        locale={locale[getLocaleString(user.locale)]}
                        dateFormat={DateFormat.CLIENT_DATE}
                        timeFormat={DateFormat.API_TIME}
                        theme="material"
                        themeVariant="light"
                        data={bookingEvents}
                        renderHeader={() => (
                            <EventCalendarFilters
                                dialogTitle={intl.formatMessage(Messages.filters)}
                                value={filters}
                                onChange={filters => {
                                    onFiltersChange(filters);
                                    setSelectedDate(filters.currentDate);
                                }}
                            />
                        )}
                        resources={docks}
                        cssClass="md-event-listing"
                        onEventHoverIn={event => !sidebarOpen && handleEventHighlights(event)}
                        onEventHoverOut={event =>
                            !sidebarOpen && handleEventHighlights(event, true)
                        }
                        dragToMove={!internalBookingFormOpen}
                        dragToResize={false}
                        dragInTime={false}
                        onEventUpdate={onEventUpdate}
                        onEventUpdated={event => setUpdatedEvent(event)}
                        invalid={MbscEventCalendarInvalidMatcher}
                        onEventClick={onEventClick}
                        dragToCreate={!sidebarOpen && !isOnCallUser}
                        onEventCreate={onEventCreate}
                        onEventCreated={onEventCreated}
                    />
                ) : (
                    <Progress />
                )}
            </SidebarLayout>
            <Dialog
                open={updateConfirmationDialogOpen}
                onClose={() => withReload(() => setUpdateConfirmationDialogOpen(false))}
            >
                <DialogTitle>
                    {intl.formatMessage(Messages.updateBookingsQuestion)}
                    <div className="update-dialog-subtitle">{updatedEvent?.event.title}</div>
                </DialogTitle>
                <DialogActions>
                    <Button variant="contained" onClick={() => onEventUpdated(updatedEvent)}>
                        {intl.formatMessage(Messages.confirm)}
                    </Button>
                    <Button
                        onClick={() => {
                            onEventUpdateCancel();
                        }}
                        variant="outlined"
                        color="primary"
                    >
                        {intl.formatMessage(Messages.cancel)}
                    </Button>
                </DialogActions>
            </Dialog>
        </RpisPage>
    );
};

export default DockAllocationPlan;
