import { useCallback } from "react";
import { useIntl } from "react-intl";
import { IService } from "../../../models/service";
import {
    ServiceButtonMenuGroup,
    ServiceMenuButtonGroupProps,
} from "../components/ServiceButtonMenuGroup/ServiceButtonMenuGroup";
import { IInvoice } from "../../../models/invoice";

/**
 * Hook that provides a renderer for the action button in the expenses table.
 * The action button is used to edit the expense.
 * @param handler The handler function that is called when the action button is clicked.
 * @returns The renderer for the action button.
 */
export function useServiceRenderAction(
    handler: (row: IService) => void,
    menuActions: ServiceMenuButtonGroupProps["menuActions"],
    invoices: IInvoice[],
) {
    const intl = useIntl();

    const renderer = useCallback(
        (row: IService) => (
            <ServiceButtonMenuGroup
                row={row}
                onClick={() => handler(row)}
                menuActions={menuActions}
                invoices={invoices}
            />
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [intl, invoices],
    );

    return renderer;
}
