export interface IAddEditInternalBooking {
    id: string;
    arrivalTime: number;
    companyId: string;
    departureTime: number;
    destinationId: string;
    dockId: string;
    eni?: string;
    reason?: string;
    shipName: string;
    type: string;
    public: boolean;
}

export enum Type {
    BOOKING = "BOOKING",
    BLOCKING = "BLOCKING",
}

export const internalBookingInit: IAddEditInternalBooking = {
    id: "",
    arrivalTime: 0,
    companyId: "",
    dockId: "",
    destinationId: "",
    departureTime: 0,
    shipName: "",
    reason: "",
    eni: "",
    type: Type.BOOKING,
    public: false,
};

export interface IInternalBooking extends IAddEditInternalBooking {
    createBy: string;
    createTs: number;
    lastModifyBy: string;
    lastModifyTs: number;
}
