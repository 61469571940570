import { MbscEventcalendarView } from "@mobiscroll/react";
import moment from "moment";
import momentTimezone from "moment-timezone";

export type DateType = moment.Moment | Date | string | number | null;

type ZoneType = string | null | undefined;

export type DateFormatSignature = (typeof DateFormat)[keyof typeof DateFormat];

export type DateMomentConfig = {
    format?: DateFormatSignature;
    timeZone?: ZoneType;
};

/**
 * Defines date and/or time formats used throughout the application.
 * @description Use API-prefixed formats for those used in API requests and CLIENT for formats displayed to the user.
 * @remarks The formats are based on the `moment.js` library.
 */
export const DateFormat = {
    API_DATE_TIME: "YYYY-MM-DDTHH:mm",
    API_DATE: "YYYY-MM-DD",
    API_TIME: "HH:mm",
    CLIENT_DATE_TIME: "DD.MM.YYYY HH:mm",
    CLIENT_DATE: "DD.MM.YYYY",
    CLIENT_TIME: "HH:mm",
} as const;

/**
 * Number of milliseconds in a day.
 */
const DAY_MS = 24 * 60 * 60 * 1000;

/**
 * Retrieves a list of time zones in Europe.
 * @returns An array of time zones in Europe, sorted alphabetically by name.
 */
export const TimeZones = momentTimezone.tz
    .names()
    .map(timeZone => ({
        id: timeZone,
        name: timeZone.replace(/_/g, " ").replace(/\//g, ", "),
    }))
    .filter(zone => zone.id.startsWith("Europe"))
    .sort((a, b) => a.name.localeCompare(b.name));

/**
 * Retrieves the time zone data for a given time zone.
 * @param timeZone The time zone identifier.
 * @returns The time zone data if found, otherwise null.
 */
export function getTimeZoneData(timeZone: string) {
    return TimeZones.find(zone => zone.id === timeZone) ?? null;
}

/**
 * Retrieves the time zone name for a given time zone identifier.
 * @param timeZone The time zone identifier.
 * @returns The time zone name if found, otherwise null.
 */
export function getDateMoment(date?: DateType, config?: DateMomentConfig): moment.Moment {
    const timeZone = config?.timeZone;
    const fallbackFormat = config?.format ?? DateFormat.CLIENT_DATE;
    const format =
        typeof date === "string" && new Date(date).toString() === "Invalid Date"
            ? fallbackFormat
            : undefined;
    return timeZone ? momentTimezone(date, format).tz(timeZone) : moment(date, format);
}

export function getDateNumber(date?: DateType): number {
    return getDateMoment(date).get("ms");
}

export function clampDateWithinRange(
    minStart: DateType,
    preferred: DateType,
    maxEnd: DateType,
): moment.Moment {
    const minStartMoment = getDateMoment(minStart);
    const preferredMoment = getDateMoment(preferred);
    const maxEndMoment = getDateMoment(maxEnd);

    if (preferredMoment.isBefore(minStartMoment)) {
        return minStartMoment;
    } else if (preferredMoment.isAfter(maxEndMoment)) {
        return maxEndMoment;
    }
    return preferredMoment;
}

/**
 * Subtracts a given number of days from a date.
 * @param date - The date to subtract days from.
 * @param days - The number of days to subtract.
 * @returns A new Date object with the specified number of days subtracted.
 */
export function subtractDays(date: DateType, days: number): Date {
    return moment(date).subtract(days, "days").toDate();
}

/**
 * Adds a specified number of days to a given date.
 * @param date - The date to which the days will be added.
 * @param days - The number of days to add.
 * @returns A new Date object with the added days.
 */
export function addDays(date: DateType, days: number): Date {
    return moment(date).add(days, "days").toDate();
}

/**
 * Returns the formatted date string based on the provided date, format, and time zone.
 * If the date is null or undefined, an empty string is returned.
 * @param date - The date to be formatted.
 * @param format - The desired format of the date string. Defaults to DateFormat.CLIENT_DATE.
 * @param timeZone - The time zone to be used for formatting the date. Optional.
 * @returns The formatted date string.
 */
export function getDateText(
    date: DateType | null | undefined,
    format: DateFormatSignature = DateFormat.CLIENT_DATE,
    timeZone?: ZoneType,
): string {
    return date ? getDateMoment(date, { timeZone, format }).format(format) : "";
}

/**
 * Calculates the difference in days between two dates.
 * @param start The start date.
 * @param end The end date.
 * @returns The number of days between the two dates (end-day inclusive).
 */
export function getDayDifference(
    start: DateType | null | undefined,
    end: DateType | null | undefined,
): number {
    const startDate = getDateMoment(start).toDate();
    const endDate = getDateMoment(end).toDate();
    const msDiff = endDate.setHours(0) - startDate.setHours(0);
    const absDayDiff = Math.abs(msDiff / DAY_MS);
    const dayDiff = Math.round(absDayDiff) + 1;
    return dayDiff;
}

/**
 * Returns a formatted date range text based on the start and end dates.
 * @param start - The start date of the range.
 * @param end - The end date of the range.
 * @returns A string with the formatted date range text.
 */
export function getDateRangeText(
    start: DateType | null | undefined,
    end: DateType | null | undefined,
    format?: DateFormatSignature,
    ignoreDayDiff: boolean = false,
): string {
    const formattedStartDate = getDateText(start, format);
    const formattedEndDate = getDateText(end, format);
    const endDateDefined = end && (getDayDifference(start, end) > 1 || ignoreDayDiff);
    const dateSeparator = " - ";
    return `${formattedStartDate} ${endDateDefined ? dateSeparator + formattedEndDate : ""}`;
}

/**
 * Returns the event calendar view properties.
 * @param start The start date.
 * @param end The end date.
 * @param resolution The resolution in minutes.
 * @returns The event calendar (`@mobiscroll/react`) view properties.
 */
export function getEventCalendarViewProps(
    start: Date,
    end: Date,
    resolution: number,
    currentTimeIndicator: boolean = true,
): MbscEventcalendarView {
    return {
        timeline: {
            type: "day",
            size: getDayDifference(start, end),
            timeLabelStep: resolution * 60,
            timeCellStep: resolution * 60,
            currentTimeIndicator,
        },
    };
}
