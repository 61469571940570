import Snackbar from "@mui/material/Snackbar";
import React from "react";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";
import Slide, { SlideProps } from "@mui/material/Slide";
import sanitizeHtml from "sanitize-html";

export interface ISnackbarMessage {
    title: string;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    severity: AlertColor;
    autoHideDuration?: number;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type TransitionProps = Omit<SlideProps, "direction">;

const TransitionDown = (props: TransitionProps) => {
    return <Slide {...props} direction="down" />;
};

/**
 * Represents a snackbar message component that displays a message with a severity level to the user.
 *
 * @component
 * @example
 * ```tsx
 * <SnackbarMessage
 *    title="Success!"
 *    open={true}
 *    setOpen={setOpen}
 *    severity="success"
 *    autoHideDuration={3000}
 * />
 * ```
 */

export const SnackbarMessage = ({
    title,
    open,
    setOpen,
    severity,
    autoHideDuration,
}: ISnackbarMessage) => {
    const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration === 0 ? null : autoHideDuration}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            TransitionComponent={TransitionDown}
        >
            <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: "100%" }}>
                <div className="" dangerouslySetInnerHTML={{ __html: sanitizeHtml(title) }} />
            </Alert>
        </Snackbar>
    );
};

export default SnackbarMessage;
