import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { ApiEndpoints, Roles } from "../models/userData";
import { useIntl } from "react-intl";
import {
    markAllNotficationsAsRead,
    markReadNotification,
    searchNotifications,
} from "../api/notifications";
import { showBackendMessage } from "../helpers/messagesHelper";
import { useSocket } from "../hooks/useSocket";
import Messages from "../localization/Messages";
import { INotification } from "../models/notification";
import { searchParamsInit } from "../models/searchParams";
import { useAuth } from "../hooks/useAuth";
import { SortOrder } from "../hooks/useTableSort";

/**
 * NotificationsContextProps interface represents the shape of the notifications context.
 */

export type NotificationsContextProps = {
    notifications: INotification[];
    unread: number;
    markAsRead: (notificationId: string) => Promise<void>;
    markAllAsRead: () => Promise<void>;
};

/**
 * NotificationsContext is a React context that provides notifications data and functionality.
 */
export const NotificationsContext = React.createContext<NotificationsContextProps | undefined>(
    undefined,
);

/**
 * NotificationsProvider is a React component that wraps its children with the NotificationsContext.Provider.
 * It manages the state of notifications and provides methods to mark notifications as read.
 *
 * @param children - The child components to be wrapped by the NotificationsProvider.
 */
export function NotificationsProvider({ children }: { children: ReactNode }) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [notifications, setNotifications] = useState<INotification[]>([]);
    const { lastMessage: lastNotification } = useSocket("Notification");
    const { user } = useAuth();
    const userRoles = user.roles;
    const unread = notifications.filter(({ read }) => !read).length;

    const isDispatcher = userRoles.includes(Roles.PASSENGER_CABIN_CRUISES_DISPATCHER);
    const isDocks = userRoles.includes(Roles.LOCKS_AND_DOCKS_DISPATCHER);

    const apiEndpoint = useMemo(() => {
        const endpoint = isDispatcher ? ApiEndpoints.dispatcher : isDocks ? ApiEndpoints.docks : "";
        return endpoint;
    }, [isDispatcher, isDocks]);

    useEffect(() => {
        if (!lastNotification) return;
        setNotifications(prev => [...prev, lastNotification]);
    }, [lastNotification]);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const order: SortOrder = ["createTs desc"];
                const filter = {}; // TODO
                const params = { ...searchParamsInit, order, filter };
                const res = await searchNotifications(params, apiEndpoint);
                setNotifications(res.data.rows ?? []);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.notifications));
            } finally {
                // NOOP
            }
        };

        if (apiEndpoint) {
            fetchNotifications();
        }
    }, [apiEndpoint, dispatch, intl]);

    const markAsRead = useCallback(
        async (notificationId: string) => {
            try {
                await markReadNotification(apiEndpoint, notificationId);
                setNotifications(prev =>
                    prev.map(n => (n.id === notificationId ? { ...n, read: true } : n)),
                );
            } catch {
                dispatch(showBackendMessage(intl, "error", "updating", Messages.notifications));
            } finally {
                // NOOP
            }
        },
        [apiEndpoint, dispatch, intl, setNotifications],
    );

    const markAllAsRead = useCallback(async () => {
        try {
            await markAllNotficationsAsRead(apiEndpoint, user.userName ?? "");

            setNotifications(prev => prev.map(n => ({ ...n, read: true })));
        } catch {
            dispatch(showBackendMessage(intl, "error", "updating", Messages.notifications));
        } finally {
            // NOOP
        }
    }, [apiEndpoint, dispatch, intl, user.userName]);

    return (
        <NotificationsContext.Provider value={{ notifications, unread, markAsRead, markAllAsRead }}>
            {children}
        </NotificationsContext.Provider>
    );
}
