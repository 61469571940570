import { AlertColor } from "@mui/material/Alert";
import { Reducer } from "redux";
import { SHOW_TOAST_MESSAGE } from "../actions/toastMessageActions";

/**
 * Represents the state of the toast message.
 */
export interface ToastMessageState {
    text: string; // The text content of the toast message.
    severity: AlertColor; // The severity level of the toast message.
    life: number; // The duration (in milliseconds) the toast message should be displayed.
    timestamp: number; // The timestamp when the toast message was created.
}

/**
 * The initial state of the toast message.
 */
export const initialState: ToastMessageState = {
    text: "",
    severity: "success",
    life: 3000,
    timestamp: 0,
};

/**
 * The reducer function for the toast message state.
 * @param state The current state of the toast message.
 * @param action The action object dispatched to update the toast message state.
 * @returns The updated state of the toast message.
 */
export const toastMessageReducer: Reducer<ToastMessageState> = (
    state: ToastMessageState = initialState,
    action: any,
): ToastMessageState => {
    switch (action.type) {
        case SHOW_TOAST_MESSAGE:
            return {
                text: action.text,
                severity: action.severity,
                life: action.life,
                timestamp: Date.now(),
            };
        default:
            return state;
    }
};
