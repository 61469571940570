import { Box } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { IBerthing } from "../../../models/berthing";
import { IBooking } from "../../../models/booking";
import { BerthingConstraintList, BerthingErrorsList } from "../hooks/useBerthingsState";
import BerthingInputRow from "./BerthingInputRow";

/**
 * Props for the BerthingInputList component.
 */
export type BerthingInputListProps = {
    berthings: IBerthing[];
    onRemove: (index: number) => void;
    booking: IBooking;
    loading?: boolean;
    setBerthings: Dispatch<SetStateAction<IBerthing[]>>;
    constraints: BerthingConstraintList;
    errorsList: BerthingErrorsList;
    plausabilityErrorsList: string[][];
};

/**
 * A list of berthing inputs used in Berthing respond form.
 *
 * @param berthings - The list of berthings.
 * @param onRemove - The function to remove a berthing input.
 * @param booking - The booking object.
 * @param loading - Indicates if the component is in a loading state.
 * @param setBerthings - The function to set the list of berthings.
 * @param constraints - The list of berthing constraints.
 * @param errorsList - The list of berthing errors.
 * @param plausabilityErrorsList - The list of plausability errors.
 * @returns The BerthingInputList component.
 */
export default function BerthingInputList({
    berthings,
    onRemove,
    booking,
    loading = false,
    setBerthings,
    constraints,
    errorsList,
    plausabilityErrorsList,
}: BerthingInputListProps) {
    return (
        <Box display="flex" flexDirection="column" className="berthing-list">
            {berthings.map((berthing, i) => (
                <BerthingInputRow
                    key={i}
                    index={i}
                    errors={errorsList[i]}
                    constraint={constraints[i]}
                    booking={booking}
                    berthing={berthing}
                    onRemove={onRemove}
                    loading={loading}
                    setBerthings={setBerthings}
                    plausabilityErrors={plausabilityErrorsList[i]}
                    hideDeparture={i !== berthings.length - 1}
                />
            ))}
        </Box>
    );
}
