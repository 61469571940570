import { getDateText, DateFormat } from "../../../../helpers/dateHelper";
import { AddressType } from "../../../../models/address";
import { IExpense } from "../../../../models/expense";
import { InvoiceStatus } from "../../../../models/invoice";
import { ISearchParams } from "../../../../models/searchParams";

export const expenseTypesSearchParams: Partial<ISearchParams> = {
    filter: { $and: [{ custom: false }] },
};

export function getInvoicesSearchParams(invoiceId?: number): Partial<ISearchParams> {
    const $or: any[] = [{ invoiceStatus: InvoiceStatus.OPEN }];
    if (invoiceId) $or.push({ id: invoiceId });
    return { filter: { $and: [{ $or }] } };
}

export const owningCompaniesSearchParams: Partial<ISearchParams> = {
    filter: { $and: [{ type: AddressType.OwningCompany }] },
};

export const expenseTitleMapper = (row: IExpense) => (
    <strong>{`${row.name} ${getDateText(row.expenseDate, DateFormat.CLIENT_DATE_TIME)}${
        row.shipName ? ` (${row.shipName})` : ""
    }`}</strong>
);
