import { useIntl } from "react-intl";
import { LDExpenseFiltersType } from "../../shared/filters";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import Messages from "../../../../localization/Messages";
import "./ExpenseFIlters.css";
import { memo } from "react";
import { getDateNumber } from "../../../../helpers/dateHelper";

export const DOCK_EXPENSES_SORTABLE_COLUMNS = [
    "expenseDate",
    "name",
    "shipName",
    "costNet",
    "owningCompany",
    "invoiceId",
] as const;

export type LDExpenseFiltersProps = {
    filters: LDExpenseFiltersType;
    ships: string[];
    owningCompanies: string[];
    expenseTypeNames: string[];
    internalInvoiceNumbers: string[];
    setFilters: (filters: Partial<LDExpenseFiltersType>) => void;
};

/**
 * Represents the ExpenseFilters component.
 * This component is responsible for rendering the filters used to filter expenses.
 *
 * @component
 * @param {LDExpenseFiltersProps} props - The component props.
 * @param {LDExpenseFiltersType} props.filters - The current filters applied.
 * @param {string[]} props.ships - The list of ship names.
 * @param {string[]} props.owningCompanies - The list of owning company names.
 * @param {string[]} props.expenseTypeNames - The list of expense type names.
 * @param {string[]} props.internalInvoiceNumbers - The list of internal invoice numbers.
 * @param {(filters: Partial<LDExpenseFiltersType>) => void} props.setFilters - The function to set the filters.
 * @returns {JSX.Element} The rendered ExpenseFilters component.
 */
export const ExpenseFilters = ({
    filters,
    ships,
    owningCompanies,
    expenseTypeNames,
    internalInvoiceNumbers,
    setFilters: setFiltersNaitive,
}: LDExpenseFiltersProps) => {
    const intl = useIntl();

    const setFilters = (filters: Partial<LDExpenseFiltersType>) => {
        setFiltersNaitive({ ...filters, page: 0 });
    };
    const expenseTypes = expenseTypeNames.map((name, key) => ({ name, key }));
    const shipNames = ships.map((name, key) => ({ name, key }));
    const owningCompanyNames = owningCompanies.map((name, key) => ({ name, key }));
    const invoices = internalInvoiceNumbers.map((name, key) => ({ name, key }));

    const clearFilters = () => {
        setFilters({
            dateFrom: null,
            dateTo: null,
            owningCompany: "",
            name: "",
            internalInvoiceNumber: "",
            shipName: "",
        });
    };
    return (
        <div className="manage-expenses-filters">
            <DesktopDatePicker
                InputProps={{ sx: { maxWidth: 200 } }}
                label={intl.formatMessage(Messages.from)}
                inputFormat="DD/MM/YYYY"
                value={filters.dateFrom}
                onChange={value =>
                    (!isNaN(getDateNumber(value)) || value === null) &&
                    setFilters({ dateFrom: value })
                }
                renderInput={params => <TextField variant="filled" {...params} />}
            />
            <DesktopDatePicker
                InputProps={{ sx: { maxWidth: 200 } }}
                label={intl.formatMessage(Messages.to)}
                inputFormat="DD/MM/YYYY"
                value={filters.dateTo}
                onChange={value =>
                    (!isNaN(getDateNumber(value)) || value === null) &&
                    setFilters({ dateTo: value })
                }
                renderInput={params => <TextField variant="filled" {...params} />}
            />

            <Autocomplete
                id="expense-owning-company"
                options={owningCompanyNames}
                getOptionLabel={option => option.name ?? ""}
                isOptionEqualToValue={(option, value) => option.key === value.key}
                renderInput={props => (
                    <TextField
                        {...props}
                        label={intl.formatMessage(Messages.owningCompany)}
                        variant="filled"
                    />
                )}
                renderOption={(props, option) => (
                    <li key={option.key} {...props}>
                        {option.name}
                    </li>
                )}
                onChange={(_, value) => {
                    setFilters({ owningCompany: value?.name ?? "" });
                }}
                value={
                    owningCompanyNames.find(company => company.name === filters.owningCompany) ??
                    null
                }
            />
            <Autocomplete
                id="expense-internal-invoice-number"
                options={invoices}
                getOptionLabel={option => option.name}
                isOptionEqualToValue={(option, value) => option.key === value.key}
                renderInput={props => (
                    <TextField
                        {...props}
                        label={intl.formatMessage(Messages.invoiceNumber)}
                        variant="filled"
                    />
                )}
                renderOption={(props, option) => (
                    <li key={option.key} {...props}>
                        {option.name}
                    </li>
                )}
                onChange={(_, value) => {
                    setFilters({ internalInvoiceNumber: value?.name ?? "" });
                }}
                value={
                    invoices.find(company => company.name === filters.internalInvoiceNumber) ?? null
                }
            />
            <Autocomplete
                id="expense-type"
                options={expenseTypes}
                getOptionLabel={option => option.name}
                isOptionEqualToValue={(option, value) => option.key === value.key}
                renderInput={props => (
                    <TextField
                        {...props}
                        label={intl.formatMessage(Messages.expenseType)}
                        variant="filled"
                    />
                )}
                renderOption={(props, option) => (
                    <li key={option.key} {...props}>
                        {option.name}
                    </li>
                )}
                onChange={(_, value) => {
                    setFilters({ name: value?.name ?? "" });
                }}
                value={expenseTypes.find(company => company.name === filters.name) ?? null}
            />

            <Autocomplete
                freeSolo
                id="ship-autocomplete"
                options={shipNames}
                value={filters.shipName}
                onInputChange={(_, value: string) => setFilters({ shipName: value })}
                getOptionLabel={o => (o && typeof o === "object" && "name" in o ? o.name : o)}
                isOptionEqualToValue={(a, b) => a.key === b.key}
                renderInput={params => (
                    <TextField
                        variant="filled"
                        {...params}
                        label={intl.formatMessage(Messages.ship)}
                    />
                )}
                renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                        {option.name}
                    </li>
                )}
            />
            <Box display="flex" gap={2} justifyContent="space-between" flex="1">
                <Box display="flex" gap={2} flex="1">
                    {(filters.dateTo ||
                        filters.dateFrom ||
                        filters.name ||
                        filters.internalInvoiceNumber ||
                        filters.shipName ||
                        filters.owningCompany) && (
                        <Button variant="outlined" onClick={clearFilters}>
                            {intl.formatMessage(Messages.clear)}
                        </Button>
                    )}
                </Box>
            </Box>
        </div>
    );
};

export default memo(ExpenseFilters);
