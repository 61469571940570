import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import Messages from "../../../../localization/Messages";
import { getMimeTypeMetadataRender } from "../../utils/mimeTypes";
import { useMemo } from "react";
import "./index.css";

export type DocumentLabelProps = {
    mimeType: string;
};

export function DocumentLabel({ mimeType }: DocumentLabelProps) {
    const intl = useIntl();
    const { icon, labelTranslationKey } = useMemo(
        () => getMimeTypeMetadataRender(mimeType),
        [mimeType],
    );

    return (
        <Box display="flex" alignItems="center" gap={1}>
            {icon}
            <Typography variant="body2">
                {intl.formatMessage(Messages[labelTranslationKey])}
            </Typography>
        </Box>
    );
}
