import axios, { AxiosResponse } from "axios";
import { IAddEditInternalBooking, IInternalBooking } from "../models/internalBooking";
import { ISearchParams, searchParamsInit } from "../models/searchParams";
import { ApiSearchResponse } from "../models/common";

export const changeInternalBooking = async (
    dto: IAddEditInternalBooking,
): Promise<AxiosResponse<IInternalBooking>> => {
    return await axios.patch("/dispatcher/changeInternalBooking", dto);
};

export const addInternalBooking = async (
    dto: IAddEditInternalBooking,
): Promise<AxiosResponse<IInternalBooking>> => {
    return await axios.put("/dispatcher/addInternalBooking", dto);
};

export const searchInternalBooking = async (
    searchParams: ISearchParams = searchParamsInit,
): ApiSearchResponse<IInternalBooking> => {
    return await axios.post("/dispatcher/searchInternalBooking", searchParams);
};

export const deleteInternalBooking = async (guid: string): Promise<AxiosResponse<string>> => {
    return await axios.delete(`/dispatcher/deleteInternalBooking/${guid}`);
};
