import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { IDocument } from "../../models/document";
import {
    deleteShipDocument,
    addShipDocument,
    getUrlForUpload,
    uploadFileToStorage,
} from "../../api/document";
import Messages from "../../localization/Messages";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { getShipDisp } from "../../api/ship";
import DocumentsTable from "../../components/DocumentsTable/DocumentsTable";
import { useDownloadDocument } from "../../components/DocumentsTable/hooks/useDownloadDocument";

export interface IManageDocuments {
    shipId: string;
    shipDocuments?: IDocument[];
}

/**
 * ManageDocuments component is responsible for managing ship documents.
 * It is rendered as a dialog in the Dispatcher Manage Ships page.
 * This component allows users to upload, download, and delete ship documents.
 *
 * @component
 * @example
 * return (
 *   <ManageDocuments
 *     shipId="123"
 *     shipDocuments={shipDocuments}
 *   />
 * )
 */
export const ManageDocuments = ({ shipId, shipDocuments }: IManageDocuments) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [documents, setDocuments] = useState<IDocument[]>([]);
    const [loading, setLoading] = useState(false);
    const handleDownload = useDownloadDocument();

    useEffect(() => {
        setDocuments(shipDocuments ?? []);
    }, [shipDocuments]);

    const fetchDocuments = useCallback(async () => {
        try {
            setLoading(true);
            const res = await getShipDisp(shipId);
            setDocuments(res.data.documents);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.ship));
        } finally {
            setLoading(false);
        }
    }, [shipId, dispatch, intl]);

    useEffect(() => {
        fetchDocuments();
    }, [fetchDocuments]);

    const handleUpload = async (file: File) => {
        if (!file) return;
        try {
            setLoading(true);
            let res = await getUrlForUpload(file);
            await uploadFileToStorage(file, res.data.uploadUrl);

            const document: IDocument = {
                mimeType: file.type,
                lastModify: file.lastModified,
                name: file.name,
                size: file.size,
                path: res.data.path,
            };

            res = await addShipDocument(shipId, document);
            setDocuments(res.data.documents);
        } catch {
            dispatch(showBackendMessage(intl, "error", "adding", Messages.document));
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (document: IDocument) => {
        try {
            setLoading(true);
            const res = await deleteShipDocument(shipId, document.path);
            setDocuments(res.data.documents);
            dispatch(showBackendMessage(intl, "success", "deleting", Messages.document));
        } catch {
            dispatch(showBackendMessage(intl, "error", "deleting", Messages.document));
        } finally {
            setLoading(false);
        }
    };

    return (
        <DocumentsTable
            documents={documents}
            onDownload={handleDownload}
            onDelete={handleDelete}
            onUpload={handleUpload}
            loading={loading}
        />
    );
};

export default ManageDocuments;
