import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { addEditShipInit, shipInit } from "../../models/ship";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Moment } from "moment";
import Button from "@mui/material/Button";
import { updateDockAccess } from "../../api/ship";
import { useDispatch } from "react-redux";
import Messages from "../../localization/Messages";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { IShipDispatcher } from "../../models/shipDispatcher";
import { Box, InputAdornment } from "@mui/material";
import "./ManageShips.css";
import { DateFormat, getDateMoment, getDateText } from "../../helpers/dateHelper";
import { TextFieldActivityAdornment } from "./components/TextFieldActivityAdornment";

type ShipErrors = {
    [Key in keyof Partial<IShipDispatcher>]: string;
};

const validationRegex = {
    digit3: /^\d{3}$/,
    digit6: /^\d{6}$/,
};

const MinPassengerBeds = 10;
const MaxPassengerBeds = 999;

export interface IDockAccessForm {
    selectedShip: IShipDispatcher;
    callback: () => void;
    isDisabled?: boolean;
}

/**
 * Represents a form for managing dock access for a ship.
 *
 * @component
 * @param {IDockAccessForm} props - The component props.
 * @param {IShipDispatcher} props.selectedShip - The selected ship object.
 * @param {() => void} props.callback - The callback function to be called after form submission.
 * @param {boolean} [props.isDisabled=false] - Indicates whether the form is disabled or not.
 * @returns {JSX.Element} The rendered component.
 */
export const DockAccessForm = ({ selectedShip, callback, isDisabled = false }: IDockAccessForm) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [ship, setShip] = useState(selectedShip);
    const [errors, setErrors] = useState<ShipErrors>({});

    const onChange = (diff: Partial<IShipDispatcher>) => {
        setShip({ ...ship, ...diff });
        const clearErros = Object.fromEntries(Object.entries(diff).map(([k]) => [k, undefined]));
        setErrors({ ...errors, ...clearErros });
    };

    const parseNumericOrNull = (input: string, decimal: boolean) => {
        const value = decimal ? Number.parseFloat(input) : Number.parseInt(input);
        return isNaN(value) ? null : decimal ? parseFloat(value.toFixed(2)) : value;
    };

    const validateShip = () => {
        const newErrors: ShipErrors = {};
        if (ship.keyNumber && !validationRegex.digit3.test(ship.keyNumber)) {
            newErrors.keyNumber = intl.formatMessage(Messages.numberIncorrect3Digit);
        }
        if (
            ship.electricityCardNumber &&
            !validationRegex.digit3.test(ship.electricityCardNumber)
        ) {
            newErrors.electricityCardNumber = intl.formatMessage(Messages.numberIncorrect3Digit);
        }
        if (
            ship.portRelCustomerNumber &&
            !validationRegex.digit6.test(ship.portRelCustomerNumber)
        ) {
            newErrors.portRelCustomerNumber = intl.formatMessage(Messages.numberIncorrect6Digit);
        }
        if (ship.keyDeliveryDate && isNaN(parseInt(ship.keyDeliveryDate))) {
            newErrors.keyDeliveryDate = intl.formatMessage(Messages.wrongDateFormat);
        }
        /*if (ship.dateIssueCurPluginCard && isNaN(parseInt(ship.dateIssueCurPluginCard))) {
            newErrors.dateIssueCurPluginCard = intl.formatMessage(Messages.wrongDateFormat);
        }*/
        if (ship.lastModShipData && isNaN(parseInt(ship.lastModShipData))) {
            newErrors.lastModShipData = intl.formatMessage(Messages.wrongDateFormat);
        }
        if (ship.electricityCardDeliveryDate && isNaN(parseInt(ship.electricityCardDeliveryDate))) {
            newErrors.electricityCardDeliveryDate = intl.formatMessage(Messages.wrongDateFormat);
        }
        if (ship.lastInspection && isNaN(parseInt(ship.lastInspection))) {
            newErrors.lastInspection = intl.formatMessage(Messages.wrongDateFormat);
        }
        if (
            ship.passengerBeds &&
            (ship.passengerBeds < MinPassengerBeds || ship.passengerBeds > MaxPassengerBeds)
        ) {
            newErrors.passengerBeds = intl.formatMessage(Messages.passengerBedsIncorrect);
        }

        return newErrors;
    };

    const handleSubmit = async () => {
        const newErrors = validateShip();
        if (Object.keys(newErrors).length) {
            setErrors(newErrors);
        } else {
            try {
                await updateDockAccess(ship);
                dispatch(showBackendMessage(intl, "success", "updating", Messages.ship));
                callback();
                onChange(addEditShipInit);
            } catch {
                dispatch(showBackendMessage(intl, "error", "updating", Messages.ship));
            }
        }
    };

    useEffect(() => {
        setShip(selectedShip ?? shipInit);
        setErrors({});
    }, [selectedShip]);

    return (
        <Box>
            <p className="last-modified-date-label">
                {`${intl.formatMessage(Messages.lastModifyDate)}: ${
                    ship.lastModifyTs
                        ? getDateText(ship.lastModifyTs, DateFormat.CLIENT_DATE_TIME)
                        : ""
                }`}
            </p>
            <form className="column-form">
                <TextField
                    error={Boolean(errors.keyNumber)}
                    value={ship.keyNumber ?? ""}
                    label={intl.formatMessage(Messages.keyNumber)}
                    onChange={e => onChange({ keyNumber: e.target.value })}
                    variant="filled"
                    helperText={errors.keyNumber}
                    InputProps={{
                        endAdornment: (
                            <TextFieldActivityAdornment
                                value={!!ship.keyNumberActive}
                                onChange={v => onChange({ keyNumberActive: v })}
                            />
                        ),
                    }}
                    inputProps={{
                        inputMode: "numeric",
                        maxLength: 3,
                        pattern: "[0-9]*",
                        readOnly: isDisabled,
                    }}
                />
                <DesktopDatePicker
                    label={intl.formatMessage(Messages.keyDeliveryDate)}
                    inputFormat={DateFormat.CLIENT_DATE}
                    value={
                        ship.keyDeliveryDate
                            ? getDateMoment(ship.keyDeliveryDate, {
                                  format: DateFormat.API_DATE,
                              })
                            : null
                    }
                    onChange={(value: Moment | null) => {
                        onChange({
                            keyDeliveryDate: value?.format(DateFormat.API_DATE) ?? null,
                        });
                    }}
                    renderInput={params => (
                        <TextField
                            variant="filled"
                            {...params}
                            helperText={errors.keyDeliveryDate}
                        />
                    )}
                    InputProps={{ readOnly: isDisabled }}
                />
                <TextField
                    error={Boolean(errors.electricityCardNumber)}
                    value={ship.electricityCardNumber ?? ""}
                    label={intl.formatMessage(Messages.electricityCardNumber)}
                    onChange={e => onChange({ electricityCardNumber: e.target.value })}
                    variant="filled"
                    helperText={errors.electricityCardNumber}
                    InputProps={{
                        endAdornment: (
                            <TextFieldActivityAdornment
                                value={!!ship.electricityCardNumberActive}
                                onChange={v => onChange({ electricityCardNumberActive: v })}
                            />
                        ),
                    }}
                    inputProps={{
                        inputMode: "numeric",
                        maxLength: 3,
                        pattern: "[0-9]*",
                        readOnly: isDisabled,
                    }}
                />
                <DesktopDatePicker
                    label={intl.formatMessage(Messages.electricityCardDeliveryDate)}
                    inputFormat={DateFormat.CLIENT_DATE}
                    value={
                        ship.electricityCardDeliveryDate
                            ? getDateMoment(ship.electricityCardDeliveryDate, {
                                  format: DateFormat.API_DATE,
                              })
                            : null
                    }
                    onChange={(value: Moment | null) => {
                        onChange({
                            electricityCardDeliveryDate: value?.format(DateFormat.API_DATE) ?? null,
                        });
                    }}
                    renderInput={params => (
                        <TextField
                            variant="filled"
                            {...params}
                            helperText={errors.electricityCardDeliveryDate}
                        />
                    )}
                    InputProps={{ readOnly: isDisabled }}
                />
                <DesktopDatePicker
                    label={intl.formatMessage(Messages.lastModShipData)}
                    inputFormat={DateFormat.CLIENT_DATE}
                    value={
                        ship.lastModShipData
                            ? getDateMoment(ship.lastModShipData, {
                                  format: DateFormat.API_DATE,
                              })
                            : null
                    }
                    onChange={(value: Moment | null) => {
                        onChange({
                            lastModShipData: value?.format(DateFormat.API_DATE) ?? null,
                        });
                    }}
                    renderInput={params => (
                        <TextField
                            variant="filled"
                            {...params}
                            helperText={errors.lastModShipData}
                        />
                    )}
                    InputProps={{ readOnly: isDisabled }}
                />
                <TextField
                    value={ship.remarks ?? ""}
                    label={intl.formatMessage(Messages.remarksFgksDispatcher)}
                    onChange={e => onChange({ remarks: e.target.value })}
                    variant="filled"
                    multiline
                    rows={3}
                    InputProps={{
                        readOnly: isDisabled,
                        style: { resize: "vertical" },
                    }}
                />
                <TextField
                    value={ship.remarksTechnical ?? ""}
                    label={intl.formatMessage(Messages.remarksTechnicalInformation)}
                    onChange={e => onChange({ remarksTechnical: e.target.value })}
                    variant="filled"
                    InputProps={{ readOnly: isDisabled }}
                />
                <TextField
                    error={Boolean(errors.portRelCustomerNumber)}
                    value={ship.portRelCustomerNumber ?? ""}
                    label={intl.formatMessage(Messages.portRelatedCustomerNumber)}
                    onChange={e => onChange({ portRelCustomerNumber: e.target.value })}
                    variant="filled"
                    helperText={errors.portRelCustomerNumber}
                    inputProps={{
                        inputMode: "numeric",
                        maxLength: 6,
                        pattern: "[0-9]*",
                        readOnly: isDisabled,
                    }}
                />
                <DesktopDatePicker
                    className="input-adorement"
                    label={intl.formatMessage(Messages.lastInspection)}
                    inputFormat={DateFormat.CLIENT_DATE}
                    value={
                        ship.lastInspection
                            ? getDateMoment(ship.lastInspection, {
                                  format: DateFormat.API_DATE,
                              })
                            : null
                    }
                    onChange={(value: Moment | null) => {
                        onChange({
                            lastInspection: value?.format(DateFormat.API_DATE) ?? null,
                        });
                    }}
                    renderInput={params => <TextField variant="filled" {...params} />}
                    InputProps={{ readOnly: isDisabled }}
                />
                <TextField
                    type="number"
                    value={ship.inspectionCount ?? ""}
                    label={intl.formatMessage(Messages.inspectionCount)}
                    onChange={e =>
                        onChange({
                            inspectionCount: parseNumericOrNull(e.target.value, false),
                        })
                    }
                    variant="filled"
                    InputProps={{
                        inputProps: { min: 0, max: 999, readOnly: isDisabled },
                    }}
                />
                <TextField
                    type="number"
                    value={ship.currentAmps ?? ""}
                    label={intl.formatMessage(Messages.currentAmps)}
                    onChange={e =>
                        onChange({
                            currentAmps: parseNumericOrNull(e.target.value, false),
                        })
                    }
                    variant="filled"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">A</InputAdornment>,
                        readOnly: isDisabled,
                    }}
                />
                <TextField
                    type="number"
                    error={Boolean(errors.passengerBeds)}
                    value={ship.passengerBeds ?? ""}
                    label={intl.formatMessage(Messages.numberOfPassengerBeds)}
                    onChange={e =>
                        onChange({
                            passengerBeds: parseNumericOrNull(e.target.value, false),
                        })
                    }
                    variant="filled"
                    helperText={errors.passengerBeds}
                    InputProps={{
                        inputProps: {
                            min: MinPassengerBeds,
                            max: MaxPassengerBeds,
                            readOnly: isDisabled,
                        },
                    }}
                />
                <div className="dock-aceess-save-container">
                    <FormControlLabel
                        label={intl.formatMessage(Messages.complete)}
                        control={
                            <Checkbox
                                checked={ship.complete ?? false}
                                onChange={e => onChange({ complete: e.target.checked })}
                                disabled={isDisabled}
                            />
                        }
                    />
                    {!isDisabled && (
                        <Button
                            variant="contained"
                            className="submit-vessel-form-button button-primary dock-save-button"
                            onClick={handleSubmit}
                        >
                            {intl.formatMessage(Messages.save)}
                        </Button>
                    )}
                </div>
            </form>
        </Box>
    );
};

export default DockAccessForm;
