import { Edit } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useCallback } from "react";
import Messages from "../../../../localization/Messages";
import { useIntl } from "react-intl";
import { BookingStatus, IBooking } from "../../../../models/booking";

/**
 * Hook that provides a renderer for the action button in the expenses table.
 * The action button is used to edit the expense.
 * @param handler The handler function that is called when the action button is clicked.
 * @returns The renderer for the action button.
 */
export function useBookingRenderAction(handler: (row: IBooking) => void) {
    const intl = useIntl();

    const renderer = useCallback(
        (row: IBooking) => (
            <Button
                startIcon={<Edit />}
                variant="contained"
                color="primary"
                onClick={() => handler(row)}
                disabled={[
                    BookingStatus.CANCELLED,
                    BookingStatus.DECLINED,
                    BookingStatus.PROVISIONALLY_DECLINED,
                ].includes(row.status)}
            >
                {intl.formatMessage(Messages.details)}
            </Button>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [intl],
    );

    return renderer;
}
