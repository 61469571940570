import { Autocomplete, TextField } from "@mui/material";
import { useIntl } from "react-intl";
import Messages from "../../localization/Messages";
import * as DateUtils from "../../helpers/dateHelper";
import "./index.css";
import { memo } from "react";

export type TimeZonesInputProps = {
    value: string;
    onChange: (value: string) => void;
    error?: string;
};

/**
 * A component that allows users to select a time zone from a list of options.
 *
 * @component
 * @example
 * ```tsx
 * import TimeZonesInput from "./components/TimeZonesInput";
 *
 * function App() {
 *   const handleTimeZoneChange = (value: string) => {
 *     // Handle time zone change
 *   };
 *
 *   return (
 *     <div>
 *       <h1>Time Zone Selection</h1>
 *       <TimeZonesInput value="America/New_York" onChange={handleTimeZoneChange} />
 *     </div>
 *   );
 * }
 * ```
 *
 * @param {object} props - The component props.
 * @param {string} props.value - The currently selected time zone value.
 * @param {(value: string) => void} props.onChange - The callback function called when the time zone value changes.
 * @param {string} [props.error] - The error message to display, if any.
 * @returns {JSX.Element} The rendered `TimeZonesInput` component.
 */

function TimeZonesInput({ value, onChange, error }: TimeZonesInputProps) {
    const intl = useIntl();

    return (
        <Autocomplete
            options={DateUtils.TimeZones}
            getOptionLabel={data => data.id}
            value={DateUtils.getTimeZoneData(value)}
            renderInput={params => (
                <TextField
                    {...params}
                    variant="filled"
                    label={intl.formatMessage(Messages.timeZone)}
                    error={Boolean(error)}
                    helperText={error}
                />
            )}
            renderOption={(props, timeZoneData) => <li {...props}>{timeZoneData.id}</li>}
            onChange={(_, data) => onChange(data?.id ?? "")}
        />
    );
}

export default memo(TimeZonesInput);
