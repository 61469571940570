import { useSelector } from "react-redux";
import { Roles, UserData } from "../models/userData";
import { AppState } from "../store/configureStore";
import { useCallback } from "react";

/**
 * Represents the authentication data returned by the `useAuth` hook.
 */
export type AuthenticationData = {
    /**
     * The user data.
     */
    user: UserData;
    /**
     * Indicates whether the user is authenticated.
     */
    isAuthenticated: boolean;
    /**
     * Checks if the user has any of the specified roles.
     * @param roles - The roles to check.
     * @returns `true` if the user has any of the specified roles, `false` otherwise.
     */
    hasRole: (...roles: Roles[]) => boolean;
};

/**
 * A custom hook that provides authentication data.
 * @returns The authentication data.
 */
export function useAuth(): AuthenticationData {
    const user = useSelector((s: AppState) => s.user);

    const hasRole = useCallback(
        (...roles: Roles[]) => roles.some(r => user.roles.includes(r)),
        [user],
    );

    const isAuthenticated = user.roles.length > 0;

    return {
        user,
        isAuthenticated,
        hasRole,
    };
}
