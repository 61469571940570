import {
    TableCell,
    TableRow,
    Button,
    IconButton,
    Collapse,
    Box,
    Table,
    TableHead,
    TableBody,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    ButtonGroup,
    Menu,
    MenuItem,
} from "@mui/material";
import {
    AutoCalculationType,
    IExpensePeriod,
    IExpenseType,
    expenseCategories,
} from "../../../models/expenseType";
import { useIntl } from "react-intl";
import Messages from "../../../localization/Messages";
import moment from "moment";
import React, { useCallback, useRef, useState } from "react";
import classNames from "classnames";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboarArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DockPopper from "./DockPopper/DockPopper";
import { getDateText } from "../../../helpers/dateHelper";
import { useEnumOptions } from "../../../hooks/useEnumOptions";
import { OrdNumDialog } from "./OrdNumDialog/OrdNumDialog";
import { ArrowDropDown } from "@mui/icons-material";
import { useCachedDocks } from "../../../hooks/useCachedDocks";

interface IExpenseRow {
    row: IExpenseType;
    activePeriod: IExpensePeriod | null;
    handleClickAddExpensePeriod: (expenseType: IExpenseType) => void;
    expanded: boolean;
    handleSetExpandableRowState: (id: string) => void;
    active: boolean;
    handleArchivePeriod: (expenseType: IExpenseType) => void;
    refreshTable?: () => Promise<void>;
}

/**
 * Represents a row in the Expense Types table.
 */
export const ExpenseRow = ({
    row,
    activePeriod,
    active,
    handleClickAddExpensePeriod,
    expanded,
    handleSetExpandableRowState,
    handleArchivePeriod,
    refreshTable,
}: IExpenseRow) => {
    const intl = useIntl();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const autoCalculationTypeOptions = useEnumOptions({ AutoCalculationType });
    const { byId } = useCachedDocks();

    const [ordNumDialogOpen, setOrdNumDialogOpen] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const menuButtonRef = useRef<HTMLButtonElement>(null);
    const handleToggle = useCallback(() => {
        setOpen(prev => !prev);
    }, []);

    return (
        <React.Fragment key={row.id}>
            <TableRow
                className={classNames("main-row", {
                    expanded: expanded,
                    "active-table-row": active,
                })}
            >
                <TableCell scope="row">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            handleSetExpandableRowState(row.id);
                        }}
                    >
                        {expanded ? <KeyboardArrowUpIcon /> : <KeyboarArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell>{row.ordNum ?? 1}</TableCell>
                <TableCell>{row.name ?? ""}</TableCell>
                <TableCell>
                    {intl.formatMessage(
                        expenseCategories.find(cat => cat.value === row.category)?.label ??
                            Messages.unknown,
                    )}
                </TableCell>
                <TableCell>{row.accRef ?? ""}</TableCell>
                <TableCell>{row.unit ?? ""}</TableCell>
                <TableCell>{activePeriod?.netPricePerUnit ?? ""}</TableCell>
                <TableCell>{activePeriod?.vat ?? ""}</TableCell>
                <TableCell>{getDateText(activePeriod?.validFrom)}</TableCell>
                <TableCell>{getDateText(activePeriod?.validTo)}</TableCell>
                <TableCell>
                    {autoCalculationTypeOptions.find(o => o.value === row.autoCalculationType)
                        ?.label ?? ""}
                </TableCell>
                <TableCell align="center">
                    <Box>
                        <ButtonGroup variant="contained">
                            <Button onClick={() => handleClickAddExpensePeriod(row)}>
                                {intl.formatMessage(Messages.addExpensePeriod)}
                            </Button>

                            <Button size="small" ref={menuButtonRef} onClick={handleToggle}>
                                <ArrowDropDown />
                            </Button>
                        </ButtonGroup>

                        <Menu anchorEl={menuButtonRef?.current} open={open} onClose={handleToggle}>
                            <MenuItem onClick={() => setDialogOpen(true)}>
                                {intl.formatMessage(Messages.archiveExpenseType)}
                            </MenuItem>

                            <MenuItem onClick={() => setOrdNumDialogOpen(true)}>
                                {intl.formatMessage(Messages.ordnum)}
                            </MenuItem>
                        </Menu>
                    </Box>
                </TableCell>
            </TableRow>
            {/* Expanded part of the row*/}
            <TableRow
                className={classNames("expanded-table-row", {
                    "active-table-row": active,
                })}
            >
                <TableCell colSpan={active ? 6 : 6} scope="row">
                    <Collapse timeout="auto" in={expanded} unmountOnExit>
                        <Box className="periods-wrapper">
                            <h3>{intl.formatMessage(Messages.expensePeriods)}</h3>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{intl.formatMessage(Messages.name)}</TableCell>
                                        <TableCell>
                                            {intl.formatMessage(Messages.netPricePerUnit)}
                                        </TableCell>
                                        <TableCell>
                                            {intl.formatMessage(Messages.validFrom)}
                                        </TableCell>
                                        <TableCell>
                                            {intl.formatMessage(Messages.validTo)}
                                        </TableCell>
                                        <TableCell>
                                            {intl.formatMessage(Messages.vatNumber)}
                                        </TableCell>
                                        <TableCell>{intl.formatMessage(Messages.docks)}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row?.periods
                                        ?.sort((a, b) =>
                                            moment(b.validFrom).diff(moment(a.validFrom)),
                                        )
                                        .map((period, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{period.name}</TableCell>
                                                <TableCell>{period.netPricePerUnit}</TableCell>
                                                <TableCell>
                                                    {getDateText(period.validFrom)}
                                                </TableCell>
                                                <TableCell>{getDateText(period.validTo)}</TableCell>
                                                <TableCell>{period.vat}</TableCell>
                                                <TableCell>
                                                    {period.docks.length > 1 ? (
                                                        <DockPopper period={period} />
                                                    ) : (
                                                        byId.get(period.docks[0])?.name
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
                {/* Empty cell to fill the row */}
                <TableCell colSpan={12}></TableCell>
            </TableRow>

            <OrdNumDialog
                open={ordNumDialogOpen}
                expenseType={row}
                onClose={() => {
                    setOrdNumDialogOpen(false);
                    refreshTable?.();
                }}
            />

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>{intl.formatMessage(Messages.archiveExpenseType)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {intl.formatMessage(Messages.archiveExpenseTypeMessage)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} color="primary">
                        {intl.formatMessage(Messages.cancel)}
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            handleArchivePeriod(row);
                            setDialogOpen(false);
                        }}
                        color="primary"
                        autoFocus
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default ExpenseRow;
