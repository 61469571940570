import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import "./index.css";

export type DocumentViewerProps = {
    src: string;
    mimeType: string;
};

export function DocumentViewer({ src, mimeType }: DocumentViewerProps) {
    return (
        <DocViewer
            className="document-viewer"
            style={{ width: "100%", height: "100%" }}
            pluginRenderers={DocViewerRenderers}
            documents={[{ uri: src, fileType: mimeType }]}
        />
    );
}
