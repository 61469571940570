import { useIntl } from "react-intl";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Messages from "../../localization/Messages";
import DropzoneForm from "../../components/DropzoneForm/DropzoneForm";
import { TableSkeleton } from "../../components/TableSkeleton/TableSkeleton";
import { displayFileSize, IDocument } from "../../models/document";
import { DateFormat, getDateText } from "../../helpers/dateHelper";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import { LineClamp } from "./components/LineClamp";
import { DocumentLabel } from "./components/DocumentLabel";
import { DocumentPreviewButton } from "./components/DocumentPreviewButton";
import "./DocumentsTable.css";
import { ButtonGroup, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { ArrowDropDown, Delete, Download } from "@mui/icons-material";
import { useState } from "react";

export interface IDocumentsTable {
    documents: IDocument[];
    onUpload: (file: File) => Promise<void>;
    onDownload?: (document: IDocument) => Promise<void>;
    onDelete?: (document: IDocument) => Promise<void>;
    loading?: boolean;
    preventConfirmation?: boolean;
}

/**
 * DocumentsTable component
 *
 * This component is responsible for rendering a table of documents, used within a dialog.
 *
 * Props:
 * - `shipId`: The ID of the ship for which the documents are being managed.
 * - `documents`: An array of `IDocument` objects representing the documents to be displayed in the table.
 * - `handleDownloadDocument`: A function that is called when the user requests to download a document. It receives the `IDocument` object representing the document to be downloaded.
 * - `callback`: A function that is called when a new document is added. It receives the `File` object representing the new document.
 * - `handleDeleteDocument`: A function that is called when the user requests to delete a document. It receives the `IDocument` object representing the document to be deleted and the shipId.
 * - `handleDeleteCertificate`: A function that is called when the user requests to delete a certificate.
 * - `loading`: A boolean indicating whether the component is currently loading data.
 *
 * The table provides options to add or remove documents, as well as view or download them.
 *
 * Each row in the table represents a single document, with columns for the document's properties.
 *
 * The table also includes a "Download" button for each document, which calls the `handleDownloadDocument` function when clicked,
 * and a "Delete" button, which calls the `handleDeleteDocument` function when clicked.
 */

export const DocumentsTable = ({
    documents,
    onUpload,
    onDelete,
    onDownload,
    preventConfirmation,
    loading,
}: IDocumentsTable) => {
    const intl = useIntl();
    const withConfirm = useConfirmDialog();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = (document: IDocument) => {
        if (preventConfirmation) {
            onDelete?.(document);
            handleClose();
            return;
        }

        withConfirm({
            message: intl.formatMessage(Messages.confirmDocumentDeleteText),
            onConfirm: async () => {
                onDelete?.(document);
                handleClose();
            },
        });
    };

    const handleDownload = (document: IDocument) => {
        onDownload?.(document);
        handleClose();
    };

    return (
        <div className="manage-documents-container">
            {documents ? (
                <TableContainer className="manage-documents-table">
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {`${intl.formatMessage(Messages.document)} ${intl.formatMessage(
                                        Messages.name,
                                    )}`}
                                </TableCell>

                                <TableCell align="right">
                                    {intl.formatMessage(Messages.size)}
                                </TableCell>
                                <TableCell>{`${intl.formatMessage(
                                    Messages.document,
                                )} ${intl.formatMessage(Messages.type)}`}</TableCell>
                                <TableCell>{intl.formatMessage(Messages.lastModifyDate)}</TableCell>
                                <TableCell align="center" />
                            </TableRow>
                        </TableHead>
                        {loading ? (
                            <TableSkeleton columns={6} rows={documents.length} />
                        ) : (
                            <TableBody>
                                {documents.map((doc, key) => (
                                    <TableRow key={key}>
                                        <TableCell>
                                            <LineClamp text={doc.name} clamp={2} maxWidth={250} />
                                        </TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap" }} align="right">
                                            {displayFileSize(doc.size ?? 0)}
                                        </TableCell>
                                        <TableCell align="center">
                                            <DocumentLabel mimeType={doc.mimeType} />
                                        </TableCell>
                                        <TableCell>
                                            <LineClamp
                                                clamp={2}
                                                hideTooltip
                                                maxWidth={200}
                                                text={getDateText(
                                                    doc.lastModify,
                                                    DateFormat.CLIENT_DATE_TIME,
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell className="actions-column" align="left">
                                            <ButtonGroup variant="contained">
                                                <DocumentPreviewButton
                                                    mimeType={doc.mimeType}
                                                    name={doc.name}
                                                    path={doc.path}
                                                />

                                                <Button size="small" onClick={handleClick}>
                                                    <ArrowDropDown />
                                                </Button>
                                            </ButtonGroup>

                                            <Menu
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                            >
                                                {onDownload && (
                                                    <MenuItem onClick={() => handleDownload(doc)}>
                                                        <ListItemIcon>
                                                            <Download
                                                                color="success"
                                                                fontSize="small"
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText>
                                                            {intl.formatMessage(Messages.download)}
                                                        </ListItemText>
                                                    </MenuItem>
                                                )}
                                                <MenuItem
                                                    onClick={() => handleDelete(doc)}
                                                    /*withConfirm({
                                                            message: intl.formatMessage(
                                                                Messages.confirmDocumentDeleteText,
                                                            ),
                                                            onConfirm: async () => {
                                                                shipId
                                                                    ? handleDeleteDocument?.(
                                                                          row,
                                                                          shipId,
                                                                      )
                                                                    : handleDeleteCertificate?.();
                                                                handleClose();
                                                            },
                                                        })*/
                                                >
                                                    <ListItemIcon>
                                                        <Delete color="error" fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        {intl.formatMessage(Messages.delete)}
                                                    </ListItemText>
                                                </MenuItem>
                                            </Menu>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            ) : (
                <h5>{intl.formatMessage(Messages.shipDocumentsUnavailable)}</h5>
            )}
            <h3>{intl.formatMessage(Messages.uploadDocuments)}:</h3>
            <div className="upload-document-area">
                <DropzoneForm onUpload={onUpload} />
            </div>
        </div>
    );
};

export default DocumentsTable;
