import NotificationsContainer from "../../../NotificationsContainer/NotificationsContainer";
import { IconButton, Tooltip } from "@mui/material";
import Switch from "@mui/material/Switch";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useIntl } from "react-intl";
import Messages from "../../../../localization/Messages";
import { useNotifications } from "../../../../hooks/useNotifications";
import "./NotificationsPanel.css";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";

/**
 * Represents a panel that displays notifications.
 */
export const NotificationsPanel = () => {
    const intl = useIntl();
    const { notifications } = useNotifications();
    const [showOnlyUnread, setShowOnlyUnread] = useLocalStorage(
        "showOnlyUnreadNotifications",
        true,
    );

    return (
        <div className="notifications-panel-container">
            <div className="title-and-actions">
                <div className="title-section">
                    <h3>{intl.formatMessage(Messages.notifications)}</h3>
                </div>
                <div className="actions-section">
                    <span className="switch-label">
                        {intl.formatMessage(Messages.onlyShowUnread)}
                    </span>
                    <Switch
                        size="small"
                        checked={showOnlyUnread}
                        onChange={() => setShowOnlyUnread(current => !current)}
                    />
                    <Tooltip
                        title={intl.formatMessage(Messages.openInNewTab)}
                        placement="bottom-end"
                    >
                        <IconButton
                            className="open-notifications-button"
                            onClick={() =>
                                window.open("/notifications", "_blank ,'rel=noopener noreferrer'")
                            }
                        >
                            <OpenInNewIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <NotificationsContainer
                notifications={
                    showOnlyUnread ? notifications.filter(({ read }) => !read) : notifications
                }
            />
        </div>
    );
};

export default NotificationsPanel;
