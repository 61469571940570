import { Reducer } from "redux";
import { IManagingCompany } from "../models/managingCompany";
import {
    ManagingCompaniesActionType,
    SET_MANAGING_COMPANIES,
} from "../actions/managingCompaniesActon";

/**
 * Represents the state of managing companies.
 */
export interface ManagingCompaniesState {
    data: Map<any, any>;
}

/**
 * The initial state of managing companies.
 */
export const initialState: ManagingCompaniesState = { data: new Map() };

/**
 * Sets the managing companies in the state.
 * @param payload - The array of managing companies.
 * @returns The updated state with managing companies.
 */
const setManagingCompanies = (payload: IManagingCompany[]) => {
    const managingCompanies = new Map();
    payload.forEach(({ id, name }: IManagingCompany) => managingCompanies.set(id, name));
    return { data: managingCompanies };
};

/**
 * The reducer function for managing companies.
 * @param state - The current state of managing companies.
 * @param action - The action to be performed on managing companies.
 * @returns The updated state of managing companies.
 */
export const managingCompaniesReducer: Reducer<
    ManagingCompaniesState,
    ManagingCompaniesActionType
> = (
    state: ManagingCompaniesState = initialState,
    action: ManagingCompaniesActionType,
): ManagingCompaniesState => {
    switch (action.type) {
        case SET_MANAGING_COMPANIES:
            return { ...setManagingCompanies(action.payload) };

        default:
            return state;
    }
};
