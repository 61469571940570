import { CheckCircleOutline, HighlightOffOutlined } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, Typography } from "@mui/material";
import Messages from "../../../localization/Messages";
import { useIntl } from "react-intl";

export type TextFieldActivityAdornmentProps = {
    value: boolean;
    onChange: (value: boolean) => void;
};

export function TextFieldActivityAdornment({ value, onChange }: TextFieldActivityAdornmentProps) {
    const intl = useIntl();
    const isActive = value;

    const handleToggleActive = () => {
        onChange(!isActive);
    };

    return (
        <InputAdornment position="end">
            <IconButton onClick={handleToggleActive}>
                <Box display="flex" flexDirection="column" alignItems="center" width="50px">
                    {isActive ? (
                        <CheckCircleOutline color="success" />
                    ) : (
                        <HighlightOffOutlined color="error" />
                    )}
                    <Typography variant="caption">
                        {intl.formatMessage(isActive ? Messages.active : Messages.inactive)}
                    </Typography>
                </Box>
            </IconButton>
        </InputAdornment>
    );
}
